import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M13.0728 3.0636C12.973 2.86454 12.8198 2.69716 12.6304 2.58019C12.4409 2.46322 12.2226 2.40128 12 2.40128C11.7773 2.40128 11.559 2.46322 11.3696 2.58019C11.1801 2.69716 11.0269 2.86454 10.9272 3.0636L2.52717 19.8636C2.42294 20.0719 2.38159 20.306 2.40816 20.5374C2.43473 20.7688 2.52807 20.9875 2.6768 21.1668C2.82553 21.346 3.02321 21.4781 3.24574 21.547C3.46826 21.6158 3.70601 21.6184 3.92997 21.5544L9.92997 19.8396C10.1807 19.7679 10.4013 19.6164 10.5583 19.4081C10.7153 19.1998 10.8001 18.946 10.8 18.6852V13.2C10.8 12.8817 10.9264 12.5765 11.1514 12.3515C11.3765 12.1264 11.6817 12 12 12C12.3182 12 12.6235 12.1264 12.8485 12.3515C13.0735 12.5765 13.2 12.8817 13.2 13.2V18.6852C13.1998 18.946 13.2847 19.1998 13.4416 19.4081C13.5986 19.6164 13.8192 19.7679 14.07 19.8396L20.07 21.5532C20.2939 21.6173 20.5316 21.6149 20.7541 21.5462C20.9767 21.4776 21.1744 21.3456 21.3233 21.1665C21.4721 20.9874 21.5656 20.7688 21.5924 20.5375C21.6191 20.3061 21.578 20.072 21.474 19.8636L13.074 3.0636H13.0728Z" })
  ]))
}