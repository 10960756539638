<script setup lang="ts">
import { initDropdowns, initPopovers } from 'flowbite'
import { onMounted } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { Season } from '@ankor-io/common/vessel/types'
import { OutlinePlus } from '@ankor-io/icons/outline'
import { SolidInformationCircle } from '@ankor-io/icons/solid'

import ButtonVue from '@/components/Button.vue'

import SeasonVue from './Season.vue'

type Props = {
  seasons: Season[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:seasons', value: Season[]): void
}>()

const HELP_TEXT =
  'This section is where you can add seasonal information to your yacht. Retail agents viewing your yacht profile will be able to see the period that marks the various seasons on your yacht’s calendar. '

onMounted(() => {
  initPopovers()
  initDropdowns()
})

/**
 * Add a new season to the seasons array
 */
const addSeason = () => {
  const newSeason: Season = {
    label: 'High',
    description: '',
    intervals: [],
  }

  emit('update:seasons', [...(props.seasons || []), newSeason])
}

const removeSeason = (index: number) => {
  const seasons: Season[] = ObjectUtil.deepCopy(props.seasons)
  seasons.splice(index, 1)
  emit('update:seasons', seasons)
}

const updateSeason = (season: Season, index: number) => {
  const seasons: Season[] = ObjectUtil.deepCopy(props.seasons)
  seasons[index] = season
  emit('update:seasons', seasons)
}
</script>
<template>
  <div class="relative flex flex-col text-primary gap-y-4">
    <div class="flex gap-x-2.5 items-end mb-4">
      <h3 class="text-2xl font-semibold leading-none text-black dark:text-white">Seasons</h3>
      <button type="button" data-popover-placement="right" data-popover-target="seasons-popover">
        <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
      </button>
      <div
        data-popover
        role="tooltip"
        id="seasons-popover"
        class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
      >
        <div class="max-w-[25rem] p-2">{{ HELP_TEXT }}</div>
        <div data-popper-arrow></div>
      </div>
    </div>

    <SeasonVue
      v-for="(season, seasonIndex) in seasons"
      :season="season"
      :seasonIndex="seasonIndex"
      :key="seasonIndex"
      @remove:season="removeSeason($event)"
      @update:season="updateSeason($event, seasonIndex)"
    >
    </SeasonVue>

    <!-- Add another Season button -->
    <ButtonVue
      class="w-min"
      id="add-season"
      position="prefix"
      :hasBorder="true"
      :isPrimary="false"
      :name="`Add ${props.seasons.length ? 'Another' : ''} Season`"
      @click="addSeason"
    >
      <OutlinePlus class="w-4 h-4" />
    </ButtonVue>
  </div>
</template>
