import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/Dashboard.vue'
import Loader from '@/components/Loader.vue'
import ClaimVessel from '@/pages/ClaimVessel.vue'
import EditVessel from '@/pages/EditVessel.vue'
import ViewVessel from '@/pages/ViewVessel.vue'
import Forbidden from '@/views/Forbidden.vue'
import NotFound from '@/views/NotFound.vue'
import Settings from '@/views/Settings.vue'
import Unauthorized from '@/views/Unauthorized.vue'
import Yachts from '@/views/Yachts.vue'
import CharterRequests from '@/views/feed/CharterRequests.vue'
import MyCompanyPosts from '@/views/feed/MyCompanyPosts.vue'
import MyPosts from '@/views/feed/MyPosts.vue'
import Specials from '@/views/feed/Specials.vue'

import Search from './views/search/Search.vue'

// import Search from '@/views/search/Search.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        main: Dashboard,
      },
      children: [
        {
          path: '',
          name: 'yachts',
          components: {
            content: Yachts,
          },
        },
        {
          path: 'search',
          name: 'search',
          components: {
            content: Search,
          },
        },
        {
          path: 'claim-vessel/:step',
          name: 'claim-vessel',
          components: {
            content: ClaimVessel,
          },
          props: true,
        },
        {
          path: 'edit-vessel/:uri',
          name: 'edit-vessel',
          components: {
            content: EditVessel,
          },
          props: true,
        },
        {
          path: 'view-vessel/:uri',
          name: 'view-vessel',
          components: {
            content: ViewVessel,
          },
          props: true,
        },
        {
          path: 'feed/specials',
          name: 'specials',
          components: {
            content: Specials,
          },
        },
        {
          path: 'feed/charter-requests',
          name: 'charter-requests',
          components: {
            content: CharterRequests,
          },
        },
        {
          path: 'feed/my-posts',
          name: 'my-posts',
          components: {
            content: MyPosts,
          },
        },
        {
          path: 'feed/company-posts',
          name: 'company-posts',
          components: {
            content: MyCompanyPosts,
          },
        },
        {
          path: 'settings',
          name: 'settings',
          components: {
            content: Settings,
          },
        },
        {
          path: 'auth/callback',
          name: 'auth-callback',
          components: {
            content: Loader,
          },
        },
      ],
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      components: {
        main: Unauthorized,
      },
      meta: {
        skipAuthentication: true,
      },
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      components: {
        main: Forbidden,
      },
      meta: {
        skipAuthentication: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      components: {
        main: NotFound,
      },
      meta: {
        skipAuthentication: true,
      },
    },
  ],
  scrollBehavior(_to, _from, _savedPosition) {
    return {
      // always scroll to top on any route change.
      top: 0,
      behavior: 'smooth',
    }
  },
})

export default router
