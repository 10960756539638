import { Ref, readonly, ref } from 'vue'

const isFacetPanelOpen: Ref<boolean> = ref(false)

export const useFacetPanel = () => {
  const updateFacetPanelState = (value: boolean) => {
    isFacetPanelOpen.value = value
  }

  return {
    isFacetPanelOpen: readonly(isFacetPanelOpen), // avoid the state to be mutated from a component
    updateFacetPanelState,
  }
}
