import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M11.3916 15.2325L17.3636 21.2045C18.4243 22.2652 20.1439 22.2652 21.2045 21.2045C22.2652 20.1439 22.2652 18.4243 21.2045 17.3636L15.1853 11.3444M11.3916 15.2325L13.9483 12.1281C14.2719 11.735 14.7049 11.4877 15.1853 11.3444M11.3916 15.2325L6.62375 21.0221C6.12807 21.624 5.38918 21.9727 4.60944 21.9727C3.16829 21.9727 2 20.8044 2 19.3633C2 18.5835 2.3487 17.8446 2.9506 17.349L9.95284 11.5824M15.1853 11.3444C15.7481 11.1766 16.376 11.1514 16.9702 11.2016C17.0999 11.2126 17.2311 11.2182 17.3636 11.2182C19.9091 11.2182 21.9727 9.15461 21.9727 6.60908C21.9727 5.93281 21.8271 5.29056 21.5654 4.71197L18.2098 8.06757C17.0671 7.80525 16.1676 6.90574 15.9053 5.76303L19.2609 2.40738C18.6823 2.14568 18.04 2 17.3636 2C14.8181 2 12.7545 4.06356 12.7545 6.60908C12.7545 6.74161 12.7601 6.87282 12.7711 7.00251C12.8642 8.10402 12.698 9.3217 11.8447 10.0244L11.7401 10.1105M9.95284 11.5824L5.74771 7.37727H4.30454L2 3.53636L3.53636 2L7.37727 4.30454V5.74771L11.7401 10.1105M9.95284 11.5824L11.7401 10.1105M18.5159 18.5159L15.8273 15.8273M4.68068 19.2841H4.68836V19.2918H4.68068V19.2841Z"
    })
  ]))
}