<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'
import { LocationQueryRaw, RouteLocationNormalized, Router, onBeforeRouteUpdate } from 'vue-router'

import ClaimStepper from '@/components/ClaimStepper.vue'
import { ClaimedVesselProps } from '@/components/ClaimedVesselCard.vue'
import { navToError } from '@/utils/nav-to-error'
import { getRouterQuery } from '@/utils/router-query'
import SearchView from '@/views/claim-vessel/Search.vue'
import SuccessView from '@/views/claim-vessel/Success.vue'
import UploadView from '@/views/claim-vessel/Upload.vue'
import { AttributesToClaimVessel } from '@/views/claim-vessel/types'

type Props = {
  step: string
}

const props = defineProps<Props>()

const steps = ['find', 'upload', 'success']

/**
 * Step 1 data: Find your vessel
 */

// The vesselDetails can be either the value from "claim", or manual vessel details
const vesselDetails: Ref<ClaimedVesselProps> = ref({
  vessel_name: '',
  vessel_length: '',
  vessel_type: '',
  vessel_existing_uri: '',
  vessel_hero: '',
})

/**
 * reset vessel details when navigating back to find
 */
onBeforeRouteUpdate(($router: RouteLocationNormalized) => {
  if ($router.params.step === 'find') {
    vesselDetails.value.vessel_existing_uri = ''
    vesselDetails.value.vessel_name = ''
    vesselDetails.value.vessel_length = ''
    vesselDetails.value.vessel_built_year = ''
    vesselDetails.value.vessel_builder = ''
    vesselDetails.value.vessel_hero = ''
  }
})

/**
 * Responsible for deserialising the URLSearchParams
 */
onMounted(() => {
  vesselDetails.value.vessel_existing_uri = getRouterQuery('vessel_existing_uri') || ''
  vesselDetails.value.vessel_name = getRouterQuery('vessel_name') || ''
  vesselDetails.value.vessel_length = getRouterQuery('vessel_length') || ''
  vesselDetails.value.vessel_built_year = getRouterQuery('vessel_built_year') || ''
  vesselDetails.value.vessel_builder = getRouterQuery('vessel_builder') || ''
  vesselDetails.value.vessel_hero = getRouterQuery('vessel_hero') || ''
})

/**
 * Updates the vessel details
 * @param event Contains the field from above to update and the Event value
 */
const updateVesselAttributes = (event: { field: string; _event: Event }): void => {
  const target = event._event.target as HTMLInputElement
  const value = target.value
  // Update vessel name
  if (event.field === 'vessel_name') {
    vesselDetails.value.vessel_name = value
  }

  // Update vessel flag
  if (event.field === 'vessel_length') {
    vesselDetails.value.vessel_length = value
  }

  // Update vessel built year
  if (event.field === 'vessel_built_year') {
    vesselDetails.value.vessel_built_year = value
  }

  // Update vessel builder
  if (event.field === 'vessel_builder') {
    vesselDetails.value.vessel_builder = value
  }
}

/**
 * Pushes the vessel attributes as route params to the next step
 * HS form feeds required properties from the route params into hidden input fields
 * Also enables to retain attributes on page reload
 * @param attributesToClaimVessel The attributes required to claim a vessel
 * @param claimed Bool if the vessel was claimed from search, or manually by form
 */
const submitVesselAttributes = (attributesToClaimVessel: AttributesToClaimVessel, $router: Router): void => {
  const urlQuery: LocationQueryRaw = {}
  if (attributesToClaimVessel.vessel_existing_uri) {
    vesselDetails.value.vessel_existing_uri = attributesToClaimVessel.vessel_existing_uri
    vesselDetails.value.vessel_name = attributesToClaimVessel.vessel_name
    vesselDetails.value.vessel_length = attributesToClaimVessel.vessel_length
    vesselDetails.value.vessel_built_year = attributesToClaimVessel.vessel_built_year
    vesselDetails.value.vessel_builder = attributesToClaimVessel.vessel_builder
    vesselDetails.value.vessel_hero = attributesToClaimVessel.vessel_hero

    urlQuery['vessel_existing_uri'] = attributesToClaimVessel.vessel_existing_uri
    urlQuery['vessel_name'] = attributesToClaimVessel.vessel_name
    urlQuery['vessel_length'] = attributesToClaimVessel.vessel_length
    urlQuery['vessel_built_year'] = attributesToClaimVessel.vessel_built_year
    urlQuery['vessel_builder'] = attributesToClaimVessel.vessel_builder
    urlQuery['vessel_hero'] = attributesToClaimVessel.vessel_hero
  }

  urlQuery['vessel_id'] = attributesToClaimVessel.vessel_id
  urlQuery['email'] = attributesToClaimVessel.email
  urlQuery['user_id'] = attributesToClaimVessel.user_id

  $router?.push({ params: { step: 'upload' }, query: urlQuery })
}
const noop = () => null
</script>
<template>
  <div class="max-w-8xl mx-auto w-full">
    <!-- Routes to not found page when route not found, should be handled in router.ts -->
    {{ !steps.includes(props.step) ? navToError($router) : noop() }}

    <ClaimStepper :current-step="props.step" />

    <SearchView
      v-if="props.step === 'find'"
      @update:value="updateVesselAttributes"
      @claim:vessel="submitVesselAttributes($event, $router)"
    />

    <UploadView v-if="props.step === 'upload'" :vessel-details="vesselDetails" />

    <SuccessView v-if="props.step === 'success'" :vessel-details="vesselDetails" />
  </div>
</template>
