import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M5.99998 10.8V8.39999C5.99998 6.8087 6.63212 5.28257 7.75733 4.15735C8.88255 3.03213 10.4087 2.39999 12 2.39999C13.5913 2.39999 15.1174 3.03213 16.2426 4.15735C17.3678 5.28257 18 6.8087 18 8.39999V10.8C18.6365 10.8 19.2469 11.0529 19.697 11.5029C20.1471 11.953 20.4 12.5635 20.4 13.2V19.2C20.4 19.8365 20.1471 20.447 19.697 20.8971C19.2469 21.3471 18.6365 21.6 18 21.6H5.99998C5.36346 21.6 4.75301 21.3471 4.30292 20.8971C3.85283 20.447 3.59998 19.8365 3.59998 19.2V13.2C3.59998 12.5635 3.85283 11.953 4.30292 11.5029C4.75301 11.0529 5.36346 10.8 5.99998 10.8V10.8ZM15.6 8.39999V10.8H8.39998V8.39999C8.39998 7.44521 8.77926 6.52954 9.45439 5.85441C10.1295 5.17928 11.0452 4.79999 12 4.79999C12.9548 4.79999 13.8704 5.17928 14.5456 5.85441C15.2207 6.52954 15.6 7.44521 15.6 8.39999V8.39999Z",
      "clip-rule": "evenodd"
    })
  ]))
}