import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "M5 11V8C5 6.89543 5.89543 6 7 6H17C18.1046 6 19 6.89543 19 8V10.875"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "M11.625 17.9975C9.825 17.9243 9.45 16.1687 7.8 16.3882C7.10004 16.4564 6.53074 16.7796 6.0616 17.1048C5.33788 17.6066 4.62501 17.2751 4.36903 16.4325L3.57017 13.8029C3.2535 12.7605 3.82939 11.6564 4.86545 11.3195L11.3816 9.20104C11.7835 9.07038 12.2165 9.07038 12.6184 9.20104L19.1345 11.3195C20.1706 11.6564 20.7469 12.7606 20.4415 13.8064C20.384 14.0035 20.3267 14.2004 20.2695 14.3972"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "M3 20.778C4.875 21.3009 6.525 20.5688 8.1 19C8.7 19.5229 10.05 20.6734 12.075 20.778"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "M9 5V5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V5"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M14 17.5H21M17.5 14V21"
    })
  ]))
}