<script setup lang="ts">
import CharacterCount from '@tiptap/extension-character-count'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import { OnClickOutside } from '@vueuse/components'
import { Ref, computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { isValidURL } from '@ankor-io/common/url/isValidURL'
import {
  OutlineAlignCenter,
  OutlineAlignJustify,
  OutlineAlignLeft,
  OutlineAlignRight,
  OutlineBold,
  OutlineClearFormatting,
  OutlineItalic,
  OutlineLink,
  OutlineOrderedList,
  OutlineUnderline,
} from '@ankor-io/icons/outline'
import { SolidUnorderedList } from '@ankor-io/icons/solid'

export interface Props {
  value: string
  placeholder: string
  isResizable: boolean
  isEditable: boolean
  isInvalid: boolean
  hasLinking: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isResizable: true,
  isEditable: true,
  hasLinking: true,
})

const emit = defineEmits<{
  (e: 'update:value', value: string): void
}>()

const editor: Ref<Editor | undefined> = ref(undefined)
const href: Ref<string> = ref('')
const setLinkDropdown: Ref<HTMLElement | null> = ref(null)
const textAlignDropdown: Ref<HTMLElement | null> = ref(null)

onMounted(() => {
  const extensions = [
    StarterKit,
    TextStyle,
    Underline,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
      alignments: ['left', 'center', 'right', 'justify'],
      defaultAlignment: 'left',
    }),
    Placeholder.configure({
      placeholder: props.placeholder, // Use a placeholder
    }),
    CharacterCount.configure({
      limit: 1000,
    }),
  ]

  if (props.hasLinking) {
    extensions.push(
      Link.configure({
        openOnClick: false,
      }),
    )
  }

  editor.value = new Editor({
    content: props.value,
    extensions,
    editorProps: {
      attributes: {
        class: props.isResizable ? 'resize-y overflow-y-auto ' : '',
      },
      transformPastedHTML(html) {
        const htmlElement = document.createElement('span')
        htmlElement.innerHTML = html
        return htmlElement.textContent || htmlElement.innerText
      },
    },
    editable: props.isEditable,
    onBlur: () => {
      if (editor.value) {
        const text = editor.value.isEmpty ? '' : editor.value.getHTML()
        emit('update:value', text)
      }
    },
  })
})

onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy()
  }
})

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue && editor.value && newValue !== editor.value.getHTML()) {
      editor.value.commands.setContent(newValue)
    }
  },
)

const openLinkDropdown = () => {
  const previousUrl = editor.value?.getAttributes('link').href
  href.value = previousUrl
  setLinkDropdown.value?.classList.toggle('hidden')
}

const closeLinkDropdown = () => {
  setLinkDropdown.value?.classList.add('hidden')
}

const setLink = () => {
  // cancelled
  if (href.value === null) {
    editor.value?.commands.blur()
    setLinkDropdown.value?.classList.add('hidden')
    return
  }

  // empty
  if (href.value === '' || href.value === undefined) {
    editor.value?.commands.unsetLink()
    editor.value?.commands.blur()
    setLinkDropdown.value?.classList.add('hidden')
    return
  }

  if (!isValidURL(href.value)) {
    href.value = 'http://' + href.value
  }

  // update link
  editor.value?.chain().focus().setLink({ href: href.value }).run()
  editor.value?.commands.blur()
  setLinkDropdown.value?.classList.add('hidden')
}

const textAlignIcon = computed(() => {
  if (editor.value?.isActive({ textAlign: 'justify' })) return OutlineAlignJustify
  if (editor.value?.isActive({ textAlign: 'center' })) return OutlineAlignCenter
  if (editor.value?.isActive({ textAlign: 'right' })) return OutlineAlignRight
  return OutlineAlignLeft
})

const setAlignment = (align: string): void => {
  editor.value?.chain().focus().setTextAlign(align).run()
  textAlignDropdown.value?.classList.toggle('hidden')
}
</script>
<template>
  <div
    class="border rounded-lg overflow-hidden"
    :class="[props.isInvalid ? 'border-red-500 dark:border-red-400' : 'border-gray-300 dark:border-gray-600']"
  >
    <div
      v-if="editor"
      class="flex flex-col sm:flex-row sm:items-center sm:justify-between px-4 py-3 gap-y-2 bg-gray-50 dark:bg-gray-700"
    >
      <div class="flex items-center gap-2 sm:gap-4">
        <!-- Text bold -->
        <button
          class="option flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
          @click="editor?.chain().focus().toggleBold().run()"
        >
          <OutlineBold
            class="w-full"
            :class="editor.isActive('bold') ? 'fill-primary-600' : 'fill-gray-500 dark:fill-gray-400'"
          />
        </button>
        <!-- Text italic -->
        <button
          class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300 italic"
          @click="editor?.chain().focus().toggleItalic().run()"
        >
          <OutlineItalic
            class="w-full"
            :class="editor.isActive('italic') ? 'fill-primary-600' : 'fill-gray-500 dark:fill-gray-400'"
          />
        </button>
        <!-- Text underline -->
        <button
          class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300 italic"
          @click="editor?.chain().focus().toggleUnderline().run()"
        >
          <OutlineUnderline
            class="w-full"
            :class="
              editor.isActive('underline')
                ? 'text-primary-600 fill-primary-600'
                : 'fill-gray-500 text-gray-500 dark:fill-gray-400 dark:text-gray-400'
            "
          />
        </button>
        <!-- Hyperlink -->
        <div v-if="props.hasLinking" class="relative">
          <button
            class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
            @click="openLinkDropdown"
          >
            <OutlineLink
              class="w-full"
              :class="editor.isActive('link') ? 'text-primary-600' : 'text-gray-500 dark:text-gray-400'"
            />
          </button>
          <!-- Set link dropdown -->
          <div ref="setLinkDropdown" class="z-10 hidden absolute top-10 -left-20 w-max">
            <OnClickOutside @trigger="closeLinkDropdown">
              <div class="flex items-center gap-2 bg-white dark:bg-gray-700 rounded-lg shadow p-2.5">
                <div class="relative">
                  <input
                    type="text"
                    class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 dark:border-gray-600 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-700"
                    placeholder="Insert link here"
                    :value="editor.getAttributes('link').href"
                    @input="href = ($event.target as HTMLInputElement).value"
                    @click.stop="null"
                  />
                </div>
                <button
                  class="text-white bg-primary-700 hover:text-white border border-primary-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  @click="setLink"
                >
                  Save
                </button>
              </div>
            </OnClickOutside>
          </div>
        </div>
        <!-- Text alignment -->
        <div class="relative">
          <button
            class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
            @click="textAlignDropdown?.classList.toggle('hidden')"
          >
            <Component class="w-full" :is="textAlignIcon" />
          </button>
          <!-- Text align dropdown -->
          <div
            ref="textAlignDropdown"
            class="z-10 hidden absolute top-9 bg-white dark:bg-gray-700 divide-y dark:divide-gray-500 rounded-sm shadow"
          >
            <!-- Align left -->
            <button
              class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
              @click="setAlignment('left')"
            >
              <OutlineAlignLeft
                class="w-full"
                :class="
                  editor.isActive({ textAlign: 'left' }) ? 'text-primary-600' : 'text-gray-500  dark:text-gray-400'
                "
              />
            </button>
            <!-- Align justify -->
            <button
              class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
              @click="setAlignment('justify')"
            >
              <OutlineAlignJustify
                class="w-full"
                :class="
                  editor.isActive({ textAlign: 'justify' }) ? 'text-primary-600' : 'text-gray-500 dark:text-gray-400'
                "
              />
            </button>
            <!-- Align right -->
            <button
              class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
              @click="setAlignment('right')"
            >
              <OutlineAlignRight
                class="w-full"
                :class="
                  editor.isActive({ textAlign: 'right' }) ? 'text-primary-600' : 'text-gray-500 dark:text-gray-400'
                "
              />
            </button>
            <!-- Align center -->
            <button
              class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
              @click="setAlignment('center')"
            >
              <OutlineAlignCenter
                class="w-full"
                :class="
                  editor.isActive({ textAlign: 'center' }) ? 'text-primary-600' : 'text-gray-500 dark:text-gray-400'
                "
              />
            </button>
          </div>
        </div>
        <!-- Clear formatting -->
        <button
          class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
          @click="editor?.chain().focus().clearNodes().unsetAllMarks().run()"
        >
          <OutlineClearFormatting class="w-full text-gray-500 dark:text-gray-400" />
        </button>
        <!-- Bullet list -->
        <button
          class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
          @click="editor?.chain().focus().toggleBulletList().run()"
        >
          <SolidUnorderedList
            class="w-full"
            :class="editor.isActive('bulletList') ? 'text-primary-600' : 'text-gray-500 dark:text-gray-400'"
          />
        </button>
        <!-- Ordered list -->
        <button
          class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
          @click="editor?.chain().focus().toggleOrderedList().run()"
        >
          <OutlineOrderedList
            class="w-full"
            :class="editor.isActive('orderedList') ? 'text-primary-600' : 'text-gray-500 dark:text-gray-400'"
          />
        </button>
      </div>

      <span class="text-xs text-gray-500 dark:text-gray-400">
        Characters remaining: {{ 1000 - editor.storage.characterCount.characters() }}
      </span>
    </div>
    <EditorContent :editor="editor" class="multiline-editor" />
  </div>
</template>
