import { RouteLocationNormalizedLoaded, Router, useRoute } from 'vue-router'

// Workaround to not mock vue-router in vitest.
// TODO: Create a router plugin and implement this fn inside of that
export const getRouterQuery = (q: string): string => {
  const route = useRoute()
  return route.query[q]?.toString() || ''
}

/**
 * Add a query parameter to the current route if it's not already set to the same value
 * @param router
 * @param route
 * @param param
 * @param value
 * @returns
 */
export const addQueryParam = (
  router: Router,
  route: RouteLocationNormalizedLoaded,
  param: string,
  value: string,
): void => {
  if (route.query[param]?.toString() === value) {
    return
  }
  const queryParams = { ...route.query }
  queryParams[param] = value
  router.replace({ query: queryParams })
}

/**
 * Remove a query parameter from the current route if it's set
 * @param router
 * @param route
 * @param param
 */
export const removeQueryParam = (router: Router, route: RouteLocationNormalizedLoaded, param: string): void => {
  if (!route.query[param]) {
    return
  }
  const queryParams = { ...route.query }
  delete queryParams[param]
  router.replace({ query: queryParams })
}
