import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M21.6 12C21.6 14.5461 20.5886 16.9879 18.7883 18.7883C16.9879 20.5886 14.5461 21.6 12 21.6C9.45395 21.6 7.01215 20.5886 5.2118 18.7883C3.41145 16.9879 2.40002 14.5461 2.40002 12C2.40002 9.45395 3.41145 7.01215 5.2118 5.2118C7.01215 3.41145 9.45395 2.40002 12 2.40002C14.5461 2.40002 16.9879 3.41145 18.7883 5.2118C20.5886 7.01215 21.6 9.45395 21.6 12V12ZM13.2 7.20002C13.2 7.51828 13.0736 7.82351 12.8486 8.04855C12.6235 8.2736 12.3183 8.40002 12 8.40002C11.6818 8.40002 11.3765 8.2736 11.1515 8.04855C10.9265 7.82351 10.8 7.51828 10.8 7.20002C10.8 6.88176 10.9265 6.57654 11.1515 6.3515C11.3765 6.12645 11.6818 6.00002 12 6.00002C12.3183 6.00002 12.6235 6.12645 12.8486 6.3515C13.0736 6.57654 13.2 6.88176 13.2 7.20002ZM10.8 10.8C10.4818 10.8 10.1765 10.9265 9.9515 11.1515C9.72645 11.3765 9.60002 11.6818 9.60002 12C9.60002 12.3183 9.72645 12.6235 9.9515 12.8486C10.1765 13.0736 10.4818 13.2 10.8 13.2V16.8C10.8 17.1183 10.9265 17.4235 11.1515 17.6486C11.3765 17.8736 11.6818 18 12 18H13.2C13.5183 18 13.8235 17.8736 14.0486 17.6486C14.2736 17.4235 14.4 17.1183 14.4 16.8C14.4 16.4818 14.2736 16.1765 14.0486 15.9515C13.8235 15.7265 13.5183 15.6 13.2 15.6V12C13.2 11.6818 13.0736 11.3765 12.8486 11.1515C12.6235 10.9265 12.3183 10.8 12 10.8H10.8Z",
      "clip-rule": "evenodd"
    })
  ]))
}