<script setup lang="ts">
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import { DateTime } from 'luxon'
import sanitizeHtml from 'sanitize-html'
import { Ref, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue'

import { fromIntervalString } from '@ankor-io/common/date/ISO8601'
import { timeAgo } from '@ankor-io/common/date/format'
import { getInitialsFromName } from '@ankor-io/common/utils/getInitials'
import { Place } from '@ankor-io/common/vessel/types'
import { OutlineMail } from '@ankor-io/icons/outline'
import { SolidArrowNarrowRight, SolidCalendar, SolidLocationMarker } from '@ankor-io/icons/solid'

type Props = {
  /**
   * The URI of the post
   */
  uri: string
  /**
   * Title of the post
   */
  title: string
  /**
   * The description of the post
   */
  description: string
  /**
   * Interval string -> refer to @ankor-io/common/date/ISO8601
   */
  eventInterval: string
  /**
   * The location of the vessel on special or charter enquiry
   */
  location?: Place
  /**
   * iso timestamp of when the feed was created
   */
  createdAt: string
  /**
   * iso timestamp of when the feed was created
   */
  updatedAt?: string
  /**
   *  name of the user that created the post
   */
  userName: string
  /**
   * Name of the company that the post belongs to
   */
  companyName: string
  /**
   * correspondence email(s) for the feed - used by the CTA
   */
  correspondenceEmail: string
}

const props = defineProps<Props>()

const descriptionRef: Ref<any> = ref(null)
const showClampButton: Ref<boolean> = ref(false)
const isClamped: Ref<boolean> = ref(true)
const editor: Ref<Editor | undefined> = ref(undefined)

onMounted(() => {
  editor.value = new Editor({
    content: props.description,
    extensions: [
      StarterKit,
      TextStyle,
      Underline,
      Link.configure({
        openOnClick: true,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right', 'justify'],
        defaultAlignment: 'left',
      }),
    ],
    editable: false,
  })

  // To update clamp state after the editor is initialized and dom has been updated
  setTimeout(() => {
    checkDescriptionClampable()
  }, 0)

  window.addEventListener('resize', checkDescriptionClampable)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkDescriptionClampable)
})

onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy()
  }
})

const checkDescriptionClampable = () => {
  if (!descriptionRef.value?.rootEl) {
    return
  }

  const height = descriptionRef.value.rootEl.scrollHeight
  const offsetHeight = descriptionRef.value.rootEl.offsetHeight
  const heightRatio = height / offsetHeight

  if (heightRatio > 1 || isClamped.value === false) {
    showClampButton.value = true
  } else {
    showClampButton.value = false
  }
}

const checkFeedExpired = (interval: string) => {
  const { end } = fromIntervalString(interval, { setZone: true })
  const currentDateTime = DateTime.now().toUTC()
  return currentDateTime.toMillis() > end!.toMillis()
}

const getMailtoUrl = () => {
  const subject = 'Response to Charter Request'

  const body = `
Hi ${props.userName},

I may have a Yacht available suitable for your requested period. Would you kindly get in contact with me at your earliest convenience.

Kind regards,


----------------------------------------------------------


Post Heading
${props.title}

Post Body
${sanitizeHtml(props.description.replace(/<\/p><p>/g, '\n'), {
  allowedTags: [],
  allowedAttributes: {},
})}

Start Date
${fromIntervalString(props.eventInterval, { setZone: true }).start!.toFormat('dd MMMM yyyy')}

End Date
${fromIntervalString(props.eventInterval, { setZone: true }).end!.toFormat('dd MMMM yyyy')}

${props.location ? 'Location' : ''}
${props.location ? props.location.name : ''}
`
  const url = `mailto:${encodeURIComponent(props.correspondenceEmail)}?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`
  return url
}

const handleEmailClick = () => {
  const mailtoUrl = getMailtoUrl()
  window.location.href = mailtoUrl
}
</script>
<template>
  <div
    class="flex flex-col bg-white dark:bg-gray-800 border-t border-b sm:border border-gray-200 dark:border-gray-600 sm:rounded-lg shadow"
  >
    <div class="flex justify-between border-b border-gray-200 dark:border-gray-600 p-4">
      <div class="flex items-center">
        <div
          class="inline-flex items-center justify-center w-12 h-12 shrink-0 overflow-hidden font-medium text-gray-600 bg-gray-100 rounded-full dark:text-gray-50 dark:bg-gray-600"
        >
          {{ getInitialsFromName(props.userName) }}
        </div>
        <div class="mx-2">
          <p class="font-bold text-gray-900 dark:text-white">{{ props.userName }}</p>
          <p class="overflow-hidden whitespace-nowrap text-ellipsis text-gray-500 dark:text-gray-400">
            {{ props.companyName }}
          </p>
        </div>
      </div>
      <div class="flex flex-col items-end min-w-8.5rem]">
        <div class="flex items-center gap-x-1 min-h-8 mb-1.5">
          <slot name="actionsDropdown"></slot>
          <span
            v-if="checkFeedExpired(props.eventInterval)"
            class="text-sm font-medium px-3 py-0.5 rounded bg-gray-100 text-gray-900"
          >
            Expired
          </span>
          <span
            v-else
            class="text-sm font-medium px-3 py-0.5 rounded bg-blue-100 text-blue-800 dark:bg-gray-700 dark:text-blue-400 dark:border dark:border-blue-400"
          >
            Active Request
          </span>
        </div>
        <p class="post-time text-xs text-gray-500 dark:text-gray-400">
          Posted {{ timeAgo(new Date(props.createdAt)) }}
          <span v-if="props.updatedAt !== props.createdAt"> - Edited</span>
        </p>
      </div>
    </div>
    <div class="m-4">
      <p class="text-2xl font-bold break-words text-gray-700 mb-4 dark:text-gray-50">
        {{ props.title }}
      </p>

      <!-- readonly text editor -->
      <EditorContent
        ref="descriptionRef"
        class="read-only-multiline-editor text-gray-500 dark:text-gray-400 leading-6"
        :editor="editor"
        :class="isClamped ? 'line-clamp-2' : 'line-clamp-none'"
      />

      <span v-if="showClampButton" class="mt-1 cursor-pointer text-primary-600" @click="isClamped = !isClamped">
        {{ isClamped ? 'Show more' : 'Show less' }}
      </span>
    </div>
    <div
      class="flex flex-col md:flex-row justify-between md:items-center px-4 py-5 border-t border-gray-200 dark:border-gray-600"
    >
      <div class="flex flex-col text-gray-700 dark:text-gray-50">
        <div class="flex flex-wrap items-center gap-x-2 text-sm md:text-base md:font-medium">
          <SolidCalendar class="w-4 h-4 shrink-0" />
          <span>{{ fromIntervalString(props.eventInterval, { setZone: true }).start?.toFormat('dd MMMM yyyy') }}</span>
          <SolidArrowNarrowRight class="w-4 h-4 shrink-0" />
          <span>{{ fromIntervalString(props.eventInterval, { setZone: true }).end?.toFormat('dd MMMM yyyy') }}</span>
        </div>
        <div v-if="props.location" class="location flex flex-wrap items-center gap-2">
          <SolidLocationMarker class="w-4 h-4 shrink-0" />
          <span class="text-sm md:text-base md:font-medium">
            {{ props.location.name + (props.location.country ? ', ' + props.location.country : '') }}
          </span>
        </div>
      </div>
      <button
        name="button"
        type="button"
        class="post-email-cta self-end flex items-center w-24 md:w-32 gap-x-2 mt-3 md:mt-0 outline-none rounded-lg py-2 md:py-2.5 px-3 md:px-5 focus:ring-2 whitespace-nowrap border text-white bg-primary-600 hover:bg-primary-800 active:bg-primary-600 focus:bg-primary-600 focus:ring-primary-300 dark:focus:ring-blue-800 border-primary-600 hover:border-primary-800"
        @click="handleEmailClick()"
      >
        <span class="text-xs md:text-sm font-medium w-full">Respond</span>
        <OutlineMail class="w-4 h-4 shrink-0 text-gray-50" />
      </button>
    </div>
  </div>
</template>
