<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

const emit = defineEmits(['file-dropped'])

const activeRef = ref(false)

function setActive() {
  activeRef.value = true
}
function setInactive() {
  activeRef.value = false
}

function onDrop(event: DragEvent) {
  setInactive()
  const { dataTransfer } = event as any
  emit('file-dropped', [...dataTransfer.files])
}

function preventDefaults(event: Event) {
  event.preventDefault()
}

const events = [/*'dragenter', 'dragover', 'dragleave', */ 'drop']

onMounted(() => {
  // add listeners to prevent default on mount
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, preventDefaults)
  })
})

onUnmounted(() => {
  // remove listeners to prevent default on unmount
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, preventDefaults)
  })
})
</script>

<template>
  <div
    :data-active="activeRef"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
    @drop.prevent="onDrop"
  >
    <slot :dropZoneActive="activeRef"></slot>
  </div>
</template>
