<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { SearchClient } from 'algoliasearch/lite'
import { Ref, computed, inject, ref } from 'vue'

import { Runnable } from '@ankor-io/common/lang/functional.types'
import { GeoFilter } from '@ankor-io/common/vessel/types'
import { OutlineXMark } from '@ankor-io/icons/outline'
import { SolidCircleX } from '@ankor-io/icons/solid'

type Props = {
  geoTags: GeoFilter[]
  operationType: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:geo', value: GeoFilter[]): void
}>()

const geoSearchClient: SearchClient = inject('geoSearchClient')!

const GEO_INDEX_NAME = 'geo'
const highlightedResultIndex: Ref<number> = ref(-1)
const showResults: Ref<boolean> = ref(false)
const geoRefinementText: Ref<string> = ref('')

/**
 * Description: Variable that contains the filtered geoTags based on the operationType.
 *
 * @type {ComputedRef<Object[]>}
 */
const filteredGeoTags = computed(() => {
  return props.geoTags.filter((tag) => tag.operation === props.operationType)
})

const highlightedTagIndex: Ref<number> = ref(filteredGeoTags.value?.length || 0)

/**
 * Highlight previous result
 */
const highlightPreviousResult = () => {
  if (highlightedResultIndex.value > 0) {
    highlightedResultIndex.value--
  }
}

/**
 * Highlight next result
 * @param resultsCount - number of results
 */
const highlightNextResult = (resultsCount: number) => {
  if (highlightedResultIndex.value < resultsCount - 1) {
    highlightedResultIndex.value++
  }
}

/**
 * Highlight previous tag
 */
const highlightPreviousTag = () => {
  if (highlightedTagIndex.value > 0) {
    highlightedTagIndex.value--
  }
}

/**
 * Highlight next tag
 */
const highlightNextTag = () => {
  if (!filteredGeoTags.value || highlightedTagIndex.value === filteredGeoTags.value.length) {
    return
  }

  highlightedTagIndex.value++
}

/**
 * On escape, the user can delete a tag
 * @param event KeyboardEvent escape key press
 */
const adjustTagByBackspace = (event: KeyboardEvent) => {
  const target = event.currentTarget as HTMLInputElement
  if (target.value) {
    return
  }

  if (highlightedTagIndex.value <= (filteredGeoTags.value?.length || 0) - 1) {
    removeGeoTag(filteredGeoTags.value[highlightedTagIndex.value].uri)
    highlightedTagIndex.value = (filteredGeoTags.value?.length || 0) - 1
  } else {
    highlightPreviousTag()
  }
}

/**
 * Add geo tag
 * @param indices - indices
 */
const addGeoTag = (indices: any) => {
  if (indices[0].hits.length === 0) {
    return
  }

  const hit = indices[0].hits[highlightedResultIndex.value]
  const updatedGeoTags = [
    ...filteredGeoTags.value,
    ...props.geoTags.filter((tag) => tag.operation !== props.operationType),
    {
      label: hit.label,
      uri: hit.objectID,
      operation: props.operationType,
    },
  ]
  const uniqueGeoTags = [...new Set(updatedGeoTags.map((obj: GeoFilter) => JSON.stringify(obj)))].map(
    (str: string): GeoFilter => JSON.parse(str),
  )

  emit('update:geo', uniqueGeoTags)

  // update geo tags and resetUI
  showResults.value = false
  geoRefinementText.value = ''
  highlightedResultIndex.value = -1
  highlightedTagIndex.value += 1
}

/**
 * Remove geo tag
 * @param geoUri - geo uri
 */
const removeGeoTag = (geoUri: string) => {
  const updatedGeoTags = [
    ...filteredGeoTags.value.filter((tag) => tag.uri !== geoUri),
    ...props.geoTags.filter((tag) => tag.operation !== props.operationType),
  ]

  const uniqueGeoTags = [...new Set(updatedGeoTags.map((obj: GeoFilter) => JSON.stringify(obj)))].map(
    (str: string): GeoFilter => JSON.parse(str),
  )

  emit('update:geo', uniqueGeoTags)
}

/**
 * Refine geo search
 * @param refine - function to refine results
 * @param value - text to refine results
 * @param indices - holds the results
 */
const refineGeoSearch = (refine: Runnable<string>, value: string, indices: any) => {
  geoRefinementText.value = value
  showResults.value = true
  refine(value)
  if (indices[0].hits.length > 0) {
    highlightedResultIndex.value = 0
  }
}
</script>

<template>
  <!-- Service Geo location -->
  <ais-instant-search class="flex flex-col gap-y-4" :index-name="GEO_INDEX_NAME" :search-client="geoSearchClient">
    <ais-configure :hits-per-page.camel="4" :distinct="true" :analytics="true" />
    <OnClickOutside @trigger="showResults = false">
      <ais-autocomplete :autofocus="true">
        <template v-slot="{ currentRefinement, indices, refine }">
          <div
            class="relative flex flex-wrap bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 focus:shadow-none focus:ring-0"
          >
            <div class="flex flex-row flex-grow gap-2 flex-wrap items-center">
              <span
                v-for="(geoTag, index) in filteredGeoTags"
                id="badge-dismiss-default"
                class="flex items-center px-3 py-1 rounded-md font-medium text-xs text-black dark:text-white bg-gray-200 dark:bg-blue-600 h-6"
                :key="geoTag.uri"
                :class="
                  highlightedTagIndex === index
                    ? 'border border-gray-400 dark:border-primary-400 bg-gray-300 dark:bg-primary-700'
                    : 'bg-gray-200 dark:bg-primary-600'
                "
              >
                {{ geoTag.label }}
                <button
                  type="button"
                  aria-label="Remove"
                  class="flex items-center"
                  data-dismiss-target="#badge-dismiss-default"
                  @click.stop="removeGeoTag(geoTag.uri)"
                >
                  <OutlineXMark class="w-2 h-2 ml-2" />
                  <span class="sr-only">{{ `Remove ${geoTag.label}` }}</span>
                </button>
              </span>
              <input
                type="search"
                ref="geoSearchInput"
                :placeholder="
                  operationType === 'union' ? 'Show in search for region(s)' : 'Exclude from search for region(s)'
                "
                autocomplete="off"
                class="flex-grow bg-gray-50 text-gray-900 border-none text-sm rounded-lg focus:border-none focus:outline-none block py-1 px-1 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white focus:shadow-none focus:ring-0"
                :value="geoRefinementText"
                @focus="showResults = true"
                @click="highlightedTagIndex = filteredGeoTags?.length || 0"
                @input="
                  refineGeoSearch(refine, (($event as InputEvent).currentTarget as HTMLInputElement).value, indices)
                "
                @keyup.up.prevent="highlightPreviousResult"
                @keyup.down.prevent="highlightNextResult(indices[0].hits.length)"
                @keyup.enter.prevent="addGeoTag(indices)"
                @keyup.right.prevent="highlightNextTag()"
                @keyup.left.prevent="highlightPreviousTag()"
                @keyup.backspace.prevent="adjustTagByBackspace($event)"
              />
              <button
                v-if="geoRefinementText"
                type="reset"
                class="relative right-1"
                title="Clear the query"
                aria-label="Clear the query"
                @click.stop="geoRefinementText = ''"
              >
                <SolidCircleX class="w-6 h-6 fill-gray-300" />
              </button>
            </div>
          </div>
          <div v-show="currentRefinement.length && showResults" class="relative">
            <div
              v-if="indices[0].hits.length && geoRefinementText"
              class="absolute top-0.5 z-10 text-base list-none bg-white rounded-lg shadow w-full dark:bg-gray-700 overflow-hidden"
            >
              <ul
                v-for="section in indices"
                :key="section.objectID"
                class="divide-y divide-gray-100 dark:divide-gray-600"
              >
                <li
                  v-for="(hit, hitIndex) in section.hits"
                  class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white transition ease-in-out duration-150"
                  :key="hit.objectID"
                  @mouseover="highlightedResultIndex = hitIndex"
                  @click="addGeoTag(indices)"
                  :class="{ 'bg-gray-100 dark:bg-gray-600': highlightedResultIndex === hitIndex }"
                >
                  <div class="flex">
                    <ais-highlight attribute="label" class="block font-medium px-2 cursor-pointer" :hit="hit" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </ais-autocomplete>
    </OnClickOutside>
  </ais-instant-search>
</template>
<style lang="scss" scoped>
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration,
[type='search']::-webkit-search-results-button,
[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>
