<script setup lang="ts">
import { DateTime } from 'luxon'

import {
  getDatesInMonth,
  getPartialDaysOfMonthBefore,
  getPartialNumDaysOfMonthAfter,
} from '@ankor-io/common/calendar/calendar-view'
import { CalendarEvent, Vessel } from '@ankor-io/common/vessel/types'

const props = defineProps<{
  year: number
  vessel: Vessel
  events: CalendarEvent[]
}>()

const dateTime = DateTime.now()
</script>
<template>
  <!-- TailwindCSS implentation -->
  <div class="mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 py-4 max-w-3xl xl:max-w-none">
    <section v-for="month in 12" class="text-center" :key="`${props.year}-${month}`">
      <h2 class="text-sm font-semibold text-gray-900 dark:text-white">
        {{ DateTime.fromObject({ year: props.year, month }).toFormat('MMMM') }}
      </h2>

      <!-- Days of the week -->
      <div class="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500 dark:text-gray-300">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>

      <div
        class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg overflow-hidden text-sm shadow ring-1 ring-gray-200 bg-gray-200 dark:bg-gray-600 dark:ring-gray-600"
      >
        <!-- Pre filling the days of the month of the previous month -->
        <button
          v-for="date in getPartialDaysOfMonthBefore(props.year, month)"
          type="button"
          class="cursor-default p-2.5 bg-gray-50 text-gray-400 dark:bg-gray-700 dark:text-gray-200"
          :key="`${month}-before-${date.date}`"
        >
          {{ date.day }}
        </button>

        <!-- Fill in the calendar with the months days -->
        <button
          v-for="day in getDatesInMonth(props.year, month)"
          type="button"
          class="p-2.5 bg-white text-gray-900 hover:bg-gray-100 dark:bg-gray-900 hover:dark:bg-gray-800 dark:text-white"
          :key="`${day.date}-${month}`"
        >
          <time
            class="mx-auto flex w-5 items-center justify-center rounded-full"
            :datetime="day.date"
            :class="{
              'font-semibold bg-primary-600 text-white dark:bg-primary-700':
                day.date === dateTime.toFormat('dd-MM-yyyy'),
            }"
          >
            {{ day.day }}
          </time>
        </button>

        <!-- Days in the next month to fill in the rest of the calendar -->
        <button
          v-for="day in getPartialNumDaysOfMonthAfter(props.year, month)"
          type="button"
          class="cursor-default p-2.5 bg-gray-50 text-gray-400 dark:bg-gray-700 dark:text-gray-200"
          :key="`${month}-after-${day}`"
        >
          {{ day }}
        </button>
      </div>
    </section>
  </div>
</template>
