<script setup lang="ts">
import { Ref, ref, watch } from 'vue'

import { OutlineExclamationCircle } from '@ankor-io/icons/outline'

import Spinner from '@/components/Spinner.vue'
import { useModal } from '@/hooks/modal/useModal'

export type DeleteConfirmationProps = {
  message: string
  labelCancel?: string
  labelConfirm?: string
  isDeleteLoading?: boolean
}

export type DeleteConfirmationModalData = DeleteConfirmationProps & {
  id: string
}

const props = withDefaults(defineProps<DeleteConfirmationProps>(), {
  isDeleteLoading: false,
})
const emit = defineEmits<{
  (e: 'close:modal'): void
  (e: 'confirm:modal'): void
}>()

const { updateCloseButtonState, updateCanCloseOnBackdropState } = useModal()

const isLoading: Ref<boolean> = ref(false)

watch(
  () => props.isDeleteLoading,
  (value: boolean) => {
    if (!value) {
      isLoading.value = false
    }
  },
)

const confirmDelete = () => {
  isLoading.value = true
  updateCloseButtonState(false)
  updateCanCloseOnBackdropState(false)
  emit('confirm:modal')
}
</script>
<template>
  <div class="w-full flex flex-col items-center justify-center" :class="{ 'pointer-events-none': isLoading }">
    <OutlineExclamationCircle class="w-8 h-8 stroke-gray-400" />
    <p class="my-5 mb-10 max-w-sm text-center text-gray-500">{{ props.message }}</p>
    <div class="w-full flex justify-center gap-x-4">
      <button
        type="button"
        name="cancel"
        class="text-gray-800 border border-gray-200 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-50 font-medium rounded-lg text-sm leading-9 px-3 dark:hover:bg-gray-300 dark:bg-gray-50 dark:border-gray-300"
        @click="emit('close:modal')"
      >
        {{ props.labelCancel || 'Cancel' }}
      </button>
      <button
        type="button"
        name="confirm"
        class="text-white border border-red-500 focus:outline-none bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm leading-9 px-3"
        @click="confirmDelete()"
      >
        <Spinner v-if="isLoading" class="w-4 h-4" />
        <p v-else>{{ props.labelConfirm || 'Confirm' }}</p>
      </button>
    </div>
  </div>
</template>
