import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 26 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M12.6047 3C13.9884 3 15.7674 3.31844 16.7558 3.53073C19.2266 4.59218 21.7963 5.65361 24.2672 6.82121C24.366 6.92736 24.7614 7.35191 24.9591 7.98879C25.0579 8.51951 24.959 9.05029 24.8602 9.36873C24.2672 11.4916 23.4765 13.6145 23.3777 14.0391C23.3777 14.7821 23.3777 15.5252 23.3777 16.2682C23.4765 16.4805 23.6742 16.6928 23.773 17.1173C23.8719 17.7542 23.4765 18.1788 23.3777 18.3911C22.2905 19.9833 18.8313 21.0447 18.8313 21.0447C17.4476 21.4693 15.2732 22 13 22C10.6279 22 8.45359 21.4693 7.16874 21.0447C7.16874 21.0447 3.70947 19.9833 2.62228 18.3911C2.52345 18.1788 2.12814 17.7542 2.22698 17.1173C2.22698 16.6928 2.42461 16.3743 2.62228 16.2682C2.62228 15.5252 2.62228 14.7821 2.62228 14.0391C2.52345 13.7207 1.8316 11.4916 1.13975 9.36873C1.04092 9.05029 0.942109 8.62566 1.04094 7.98879C1.23861 7.35191 1.63397 6.92736 1.7328 6.82121C4.20368 5.75976 6.77337 4.69833 9.24425 3.53073C9.9361 3.21229 11.1221 3 12.6047 3Z"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M3 14H19"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M21 14H22"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M3 16H22"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M13 7C13.5523 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5C12.4477 5 12 5.44772 12 6C12 6.55228 12.4477 7 13 7Z"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M13 7V12"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M16 10C15.7895 10.0909 15.5789 10.1818 15.2631 10.1818C15.2631 10.3636 15.3684 10.7273 15.1579 11C14.5263 11.8182 13.4737 12 13.1579 12C12.7368 12 11.4737 11.8182 10.8422 11C10.6316 10.7273 10.6316 10.3636 10.7369 10.1818C10.5263 10.0909 10.3158 10 10 10"
    }),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      d: "M12 8H14"
    })
  ]))
}