<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import { DiscountType, DiscountTypeValues } from '@ankor-io/common/vessel/types'

import Button from '@/components/Button.vue'

export type DiscountProps = {
  discountType: DiscountType
  discountValue: string
}

const props = defineProps<DiscountProps>()
const emit = defineEmits<{
  (e: 'close:modal'): void
  (e: 'confirm:modal', value: DiscountProps): void
}>()

const refDiscountType: Ref<DiscountType> = ref('PERCENTAGE')
const refDiscountValue: Ref<string> = ref('')

onMounted(() => {
  refDiscountType.value = props.discountType || DiscountTypeValues.PERCENTAGE
  refDiscountValue.value =
    props.discountType === DiscountTypeValues.AMOUNT
      ? props.discountValue
      : (Math.round(Number(props.discountValue) * 100 * 100) / 100).toString()
})

const onConfirm = () => {
  emit('confirm:modal', {
    discountType: refDiscountType.value,
    discountValue:
      refDiscountType.value === DiscountTypeValues.AMOUNT
        ? (Math.round(Number(refDiscountValue.value) * 100 * 100) / 100).toString()
        : refDiscountValue.value,
  })
}
</script>
<template>
  <div class="h-auto w-auto flex flex-col print:hidden dark:text-gray-200">
    <!-- Modal header -->
    <h3 class="text-lg text-left font-semibold">Configure Discount:</h3>
    <!-- Modal body -->
    <div class="flex flex-col space-y-4 mt-4">
      <ul class="flex items-center text-xs font-medium space-x-4">
        <li>Discount Type:</li>
        <li>
          <input
            type="radio"
            id="percentage"
            name="discountType"
            v-model="refDiscountType"
            class="w-4 h-4 focus:ring-blue-500 focus:ring-2"
            value="PERCENTAGE"
          />
          <label for="percentage" class="py-3 ml-2">PERCENTAGE</label>
        </li>
        <li>
          <input
            type="radio"
            id="amount"
            name="discountType"
            v-model="refDiscountType"
            class="w-4 h-4 focus:ring-blue-500 focus:ring-2"
            value="AMOUNT"
          />
          <label for="amount" class="py-3 ml-2">AMOUNT</label>
        </li>
      </ul>

      <div class="w-80 relative">
        <input
          type="number"
          id="floating_outlined_discount"
          class="block px-2.5 pb-2 pt-2.5 w-full text-sm bg-transparent rounded-lg border-1 appearance-none focus:outline-none focus:ring-0 peer"
          placeholder=" "
          v-model="refDiscountValue"
        />
        <label
          for="floating_outlined_discount"
          class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-800 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
        >
          Discount Value {{ refDiscountType === DiscountTypeValues.PERCENTAGE ? '(%)' : '' }}
        </label>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="flex justify-end items-center space-x-2 rounded-b pt-5">
      <Button name="Cancel" :isPrimary="false" @click="emit('close:modal')"></Button>
      <Button name="Save" isPrimary @click="onConfirm" :disabled="refDiscountValue === ''"></Button>
    </div>
  </div>
</template>
