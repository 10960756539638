<script setup lang="ts">
import { computed } from 'vue'

import AccordionComponent from '@ankor-io/blocks/components/Accordion/Accordion.vue'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import {
  Blueprint,
  Discount,
  DiscountTypeValues,
  type InputAmountsTaxed,
  InputAmountsTaxedValues,
  type TaxRate,
  type Variant,
} from '@ankor-io/common/vessel/types'
import { SolidArrowNarrowRight, SolidCalendar } from '@ankor-io/icons/solid'

type Props = {
  variants: Variant[]
  blueprint?: Blueprint
}

const props = defineProps<Props>()

const currencyOptions = [
  { label: 'USD', value: '&dollar;' },
  { label: 'EUR', value: '&euro;' },
  { label: 'GBP', value: '&pound;' },
  { label: 'AUD', value: '&dollar;' },
  { label: 'AED', value: '&#x62f;&#x2e;&#x625;' },
  { label: 'SGD', value: '&dollar;' },
  { label: 'HKD', value: '&dollar;' },
  { label: 'BTC', value: '&#8383;' },
  { label: 'ETH', value: '&#926' },
]

const activeVariants = computed(() => props.variants.filter((variant) => variant.active))

/**
 * A lesser used currency refers to a currency that the Intl.NumberFormat does not use
 * @param currency A currency value
 */
const isLesserUsedCurrencyCheck = (currency: string): boolean => {
  const lesserUsedCurrencies = ['AED', 'SGD', 'BTC', 'ETH']
  return lesserUsedCurrencies.includes(currency)
}

/**
 * Displays a given number to currency format
 * @param number A number value
 * @param currency A currency value
 */
const displayNumberInCurrency = (number: number, currency: string): string => {
  if (!number) {
    return '-'
  }

  if (!currency) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number / 100)
  }

  if (isLesserUsedCurrencyCheck(currency)) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
      .format(number / 100)
      .substring(1)
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number / 100)
}

/**
 * Displays a currency codes html entity
 * @param currency A currency value
 */
const displayCurrencyCode = (currency: string) => {
  if (!isLesserUsedCurrencyCheck(currency)) {
    return ''
  }

  const option = currencyOptions.filter((opt) => opt.label === currency)[0]
  return option.value
}

const displayQuantity = (quantity: number) => {
  const suffix = quantity >= 1 ? '' : '%'
  const quantityToDisplay = suffix === '%' ? (quantity || 0) * 100 : quantity
  return quantity ? `${Math.round(quantityToDisplay * 100) / 100}${suffix}` : '-'
}

const displayTaxOption = (option: InputAmountsTaxed) => {
  switch (option) {
    case InputAmountsTaxedValues.INCLUSIVE:
      return 'Tax Inclusive'
    case InputAmountsTaxedValues.EXCLUSIVE:
      return 'Tax Exclusive'
    case InputAmountsTaxedValues.NONE:
      return 'No Tax'
  }
}

const displayDiscountValue = (discount?: Discount) => {
  if (!discount) {
    return '-'
  }

  return discount.type === DiscountTypeValues.PERCENTAGE
    ? `${Math.round(discount.value * 100 * 100) / 100}%`
    : discount.value
}

const displayTaxValue = (taxRate?: TaxRate) => {
  if (!taxRate) {
    return '-'
  }

  return `${taxRate?.label} ${Math.round(taxRate.value * 100 * 100) / 100}%`
}
</script>
<template>
  <div
    v-for="(variant, variantIndex) of activeVariants"
    class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    :key="`variant-${variantIndex}`"
  >
    <AccordionComponent data-accordion="collapse" :id="`accordion-variant-${variantIndex}`">
      <template #title>{{ variant.label || variantIndex + 1 }}</template>
      <template #content>
        <div class="flex flex-col gap-y-4">
          <!-- Label -->
          <div class="flex flex-col gap-y-2">
            <h5 class="text-gray-600 dark:text-gray-400 font-semibold text-sm">Label</h5>
            <p class="text-gray-500 text-xs whitespace-pre-wrap">{{ variant.label }}</p>
          </div>

          <!-- Description -->
          <div class="flex flex-col gap-y-2">
            <h5 class="text-gray-600 dark:text-gray-400 font-semibold text-sm">Description</h5>
            <p class="text-gray-500 text-xs whitespace-pre-wrap">{{ variant.description }}</p>
          </div>

          <div class="grid grid-cols-2 gap-5 text-sm text-gray-900 dark:text-gray-300 font-normal">
            <div>
              <div class="text-gray-500 font-semibold">Active</div>
              {{ variant.active ? 'Yes' : 'No' }}
            </div>
            <div>
              <div class="text-gray-500 font-semibold">Pets allowed</div>
              {{ variant.petsAllowed ? 'Yes' : 'No' }}
            </div>
          </div>

          <!-- Geo filters -->
          <div v-if="variant.geoFilters?.length" class="flex flex-col gap-y-3">
            <h5 class="text-gray-600 dark:text-gray-400 font-semibold text-sm">Operating area</h5>
            <div class="flex flex-wrap gap-3 text-sm text-gray-900 dark:text-gray-300 font-normal">
              <div class="flex flex-col gap-y-2 text-gray-600 dark:text-gray-400 font-medium text-xs">
                <p>Inclusion Zone</p>
                <div class="flex flex-wrap gap-2.5">
                  <template
                    v-for="(geoFilter, geoFilterIndex) of variant.geoFilters"
                    :key="`${geoFilterIndex}-${geoFilter.label}`"
                  >
                    <span
                      v-if="geoFilter.operation === 'union'"
                      class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
                      :key="`${geoFilterIndex}-${geoFilter.label}`"
                    >
                      {{ geoFilter.label }}
                    </span>
                  </template>
                </div>
              </div>
              <div class="flex flex-col gap-y-2 text-gray-600 dark:text-gray-400 font-medium text-xs">
                <p>Exclusion Zone</p>
                <div class="flex flex-wrap gap-2.5">
                  <template
                    v-for="(geoFilter, geoFilterIndex) of variant.geoFilters"
                    :key="`${geoFilterIndex}-${geoFilter.label}`"
                  >
                    <span
                      v-if="geoFilter.operation === 'intersection'"
                      class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
                      :key="`${geoFilterIndex}-${geoFilter.label}`"
                    >
                      {{ geoFilter.label }}
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <hr class="h-px bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Availability period -->
          <div class="flex flex-col gap-y-2">
            <h5 class="text-gray-600 dark:text-gray-400 font-semibold text-sm">Availability Period</h5>
            <div
              v-for="(interval, intervalIndex) of variant.intervals"
              class="flex flex-wrap items-center gap-x-3 text-sm"
              :key="`${intervalIndex}-${interval}`"
            >
              <SolidCalendar class="w-4 h-4 shrink-0" />
              <span>{{ ISO8601.fromIntervalString(interval, { setZone: true }).start?.toFormat('dd MMMM yyyy') }}</span>
              <SolidArrowNarrowRight class="w-6 h-6 shrink-0" />
              <span>{{ ISO8601.fromIntervalString(interval, { setZone: true }).end?.toFormat('dd MMMM yyyy') }}</span>
              <div v-if="interval[0] === 'R'" class="flex gap-x-1">
                <span>(Repeats</span>
                <span class="text-gray-700 dark:text-gray-300 font-bold">Every Year)</span>
              </div>
            </div>
          </div>

          <hr class="h-px bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Pricing -->
          <div v-if="variant.pricing?.lineItems.length" class="flex flex-col gap-y-2">
            <h5 class="text-gray-600 dark:text-gray-400 font-semibold text-sm">Pricing</h5>
            <div v-if="variant.pricing?.note" class="flex gap-x-2 text-sm">
              <span class="text-gray-600 dark:text-gray-300">Note:</span>
              <span class="text-gray-500 dark:text-gray-400">{{ variant.pricing?.note }}</span>
            </div>

            <!-- Tax amounts -->
            <span class="text-sm text-right whitespace-nowrap text-black dark:text-white">
              Amounts are {{ displayTaxOption(variant.pricing.inputAmountTaxed) }}
            </span>

            <!-- Pricing table -->
            <div class="overflow-x-auto p-0.5">
              <table class="min-w-[49.5rem] w-full shadow rounded-lg overflow-hidden text-gray-500 dark:text-gray-400">
                <thead class="text-xs font-semibold text-left bg-gray-50 dark:bg-gray-700">
                  <th class="uppercase py-4 px-2">ITEM</th>
                  <th class="uppercase py-4 px-2">CONDITIONS</th>
                  <th class="uppercase py-4 px-2">QUANTITY</th>
                  <th class="uppercase py-4 px-2">PRICE</th>
                  <th class="uppercase py-4 px-2">DISCOUNT</th>
                  <th class="uppercase py-4 px-2">TAX RATE</th>
                  <th class="uppercase py-4 px-2">AMOUNT</th>
                </thead>
                <tbody
                  v-for="(item, itemIndex) of variant.pricing.lineItems"
                  :key="itemIndex"
                  class="text-sm"
                  :class="{ 'border-t border-t-gray-200 dark:border-t-gray-700': itemIndex !== 0 }"
                >
                  <td class="py-4 px-2">{{ item.item }}</td>
                  <td class="py-4 px-2">{{ item.conditions || '-' }}</td>
                  <td class="font-semibold py-4 px-2">{{ displayQuantity(item.quantity) }}</td>
                  <td class="font-semibold py-4 px-2">
                    <span
                      v-if="variant.pricing.currency && displayCurrencyCode(variant.pricing.currency)"
                      v-html="displayCurrencyCode(variant.pricing.currency)"
                    ></span>
                    <span>{{ displayNumberInCurrency(item.unitPrice, variant.pricing.currency) }}</span>
                  </td>
                  <td class="font-semibold py-4 px-2">{{ displayDiscountValue(item.discount) }}</td>
                  <!-- Tax rate -->
                  <td class="font-semibold py-4 px-2">
                    <div class="font-semibold">
                      {{ displayTaxValue(item.taxRate!) || '-' }}
                    </div>
                  </td>
                  <td class="font-black py-4 px-2">
                    <span
                      v-if="variant.pricing.currency && displayCurrencyCode(variant.pricing.currency)"
                      v-html="displayCurrencyCode(variant.pricing.currency)"
                    ></span>
                    <span>{{ displayNumberInCurrency(item.amount, variant.pricing.currency) }}</span>
                  </td>
                </tbody>
              </table>
            </div>

            <!-- Pricing total -->
            <div class="flex justify-end">
              <div class="grid gap-x-4 gap-y-1 grid-cols-2 text-right text-sm">
                <template v-if="variant.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
                  <span class="text-gray-400">Subtotal:</span>
                  <span class="font-semibold">
                    {{
                      variant.pricing.subTotal
                        ? (variant.pricing.subTotal / 100).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0
                    }}
                    {{ variant.pricing.currency }}
                  </span>
                </template>

                <span
                  v-if="variant.pricing.inputAmountTaxed === InputAmountsTaxedValues.INCLUSIVE"
                  class="text-gray-400"
                >
                  Includes Tax:
                </span>
                <span
                  v-if="variant.pricing.inputAmountTaxed === InputAmountsTaxedValues.EXCLUSIVE"
                  class="text-gray-400"
                >
                  Total Tax:
                </span>
                <span class="font-semibold" v-if="variant.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
                  {{
                    variant.pricing.totalTax
                      ? (variant.pricing.totalTax / 100).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0
                  }}
                  {{ variant.pricing.currency }}
                </span>

                <span class="text-gray-400">Total:</span>
                <span class="font-semibold" :class="{ 'border-t': variant.pricing.inputAmountTaxed !== 'NONE' }">
                  {{
                    variant.pricing.total
                      ? (variant.pricing.total / 100).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0
                  }}
                  {{ variant.pricing.currency }}
                </span>
              </div>
            </div>
          </div>

          <hr class="h-px bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- All toys, amenities, entertainment and tenders -->
          <AccordionComponent
            data-accordion="collapse"
            :id="`accordion-variant-${variantIndex}-toys-amenities`"
            :is-nested="true"
          >
            <template #title>All Toys & Amenities</template>
            <template #content>
              <div class="flex flex-col gap-y-4">
                <!-- Toys -->
                <template
                  v-if="
                    variant.isUsingDefaultToysAmenities || variant.isUsingDefaultToysAmenities === undefined
                      ? props.blueprint?.toys?.length
                      : variant.toys?.length
                  "
                >
                  <h6 class="text-gray-500 dark:text-gray-400 text-xs font-bold">Toys:</h6>
                  <div class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-200 font-medium text-xs">
                    <span
                      v-for="(toy, toyIndex) of variant.isUsingDefaultToysAmenities ||
                      variant.isUsingDefaultToysAmenities === undefined
                        ? props.blueprint?.toys
                        : variant.toys"
                      class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
                      :key="`${toyIndex}-${toy.label}`"
                    >
                      {{ toy.label }} <span v-if="toy.quantity">x {{ toy.quantity }}</span>
                    </span>
                  </div>
                </template>
                <!-- Amenities -->
                <template
                  v-if="
                    variant.isUsingDefaultToysAmenities || variant.isUsingDefaultToysAmenities === undefined
                      ? props.blueprint?.amenities?.length
                      : variant.amenities?.length
                  "
                >
                  <h6 class="text-gray-500 dark:text-gray-400 text-xs font-bold">Amenities:</h6>
                  <div class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-200 font-medium text-xs">
                    <span
                      v-for="(amenity, amenityIndex) of variant.isUsingDefaultToysAmenities ||
                      variant.isUsingDefaultToysAmenities === undefined
                        ? props.blueprint?.amenities
                        : variant.amenities"
                      class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
                      :key="`${amenityIndex}-${amenity.label}`"
                    >
                      {{ amenity.label }} <span v-if="amenity.quantity">x {{ amenity.quantity }}</span>
                    </span>
                  </div>
                </template>
                <!-- Entertainment -->
                <template
                  v-if="
                    variant.isUsingDefaultToysAmenities || variant.isUsingDefaultToysAmenities === undefined
                      ? props.blueprint?.entertainment?.length
                      : variant.entertainment?.length
                  "
                >
                  <h6 class="text-gray-500 dark:text-gray-400 text-xs font-bold">Entertainment:</h6>
                  <div class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-200 font-medium text-xs">
                    <span
                      v-for="(entertainment, entertainmentIndex) of variant.isUsingDefaultToysAmenities ||
                      variant.isUsingDefaultToysAmenities === undefined
                        ? props.blueprint?.entertainment
                        : variant.entertainment"
                      class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
                      :key="`${entertainmentIndex}-${entertainment}`"
                    >
                      {{ entertainment }}
                    </span>
                  </div>
                </template>
                <!-- Tenders -->
                <template
                  v-if="
                    variant.isUsingDefaultToysAmenities || variant.isUsingDefaultToysAmenities === undefined
                      ? props.blueprint?.tenders?.length
                      : variant.tenders?.length
                  "
                >
                  <h6 class="text-gray-500 dark:text-gray-400 text-xs font-bold">Tenders:</h6>
                  <div class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-200 font-medium text-xs">
                    <span
                      v-for="(tender, tendersIndex) of variant.isUsingDefaultToysAmenities ||
                      variant.isUsingDefaultToysAmenities === undefined
                        ? props.blueprint?.tenders
                        : variant.tenders"
                      class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
                      :key="`${tendersIndex}-${tender}`"
                    >
                      {{ tender }}
                    </span>
                  </div>
                </template>
              </div>
            </template>
          </AccordionComponent>
        </div>
      </template>
    </AccordionComponent>
  </div>
</template>
