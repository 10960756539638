<script setup lang="ts">
import { Dismiss, DismissInterface, DismissOptions } from 'flowbite'
import { Ref, onMounted, ref } from 'vue'

export interface ToastProps {
  level: 'info' | 'danger' | 'success' | 'warning' | 'dark'
  message: string
  targetElementId: string
  triggerElementId: string
  duration: number
}

const props = defineProps<ToastProps>()

const toastRef: Ref<DismissInterface | null> = ref(null)

const options: DismissOptions = {
  transition: 'transition-opacity',
  duration: props.duration,
  timing: 'ease-out',

  // callback functions
  onHide: () => {},
}

onMounted(() => {
  toastRef.value = new Dismiss(
    document.getElementById(props.targetElementId),
    document.getElementById(props.triggerElementId),
    options,
  )
})

const hide = () => {
  toastRef.value?.hide()
}

defineExpose({ hide })
</script>
<template>
  <div :id="targetElementId" class="z-10 absolute bottom-5 right-5">
    <div
      v-if="level === 'info'"
      class="p-4 mb-4 text-sm text-blue-800 border-2 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-2"
      role="alert"
    >
      <span class="font-bold">Info:</span> {{ message }}
    </div>
    <div
      v-if="level === 'danger'"
      class="p-4 mb-4 text-sm text-red-800 border-2 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-2"
      role="alert"
    >
      <span class="font-bold">Danger:</span> {{ message }}
    </div>
    <div
      v-if="level === 'success'"
      class="p-4 mb-4 text-sm text-green-800 border-2 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-2"
      role="alert"
    >
      <span class="font-bold">Success:</span> {{ message }}
    </div>
    <div
      v-if="level === 'warning'"
      class="p-4 mb-4 text-sm text-yellow-800 border-2 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-2"
      role="alert"
    >
      <span class="font-bold">Warning:</span> {{ message }}
    </div>
    <div
      v-if="level === 'dark'"
      class="p-4 text-sm text-gray-800 border-2 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-2"
      role="alert"
    >
      <span class="font-bold">Message:</span> {{ message }}
    </div>
  </div>
</template>
