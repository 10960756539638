<script setup lang="ts">
import algoliasearch from 'algoliasearch/lite'
import { Ref, inject, ref } from 'vue'

import { PostIndexItem } from '@ankor-io/algolia-conflux/src/mapper.post'
import PostCardEnquiry from '@ankor-io/blocks/components/feed/PostCardEnquiry.vue'
import PostCardSpecial from '@ankor-io/blocks/components/feed/PostCardSpecial.vue'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { Person, Vessel } from '@ankor-io/common/vessel/types'
import { Post } from '@ankor-io/feed-endpoint/src/types'
import { OutlineBell } from '@ankor-io/icons/outline/index'
import { SolidPaperAirplane, SolidX } from '@ankor-io/icons/solid'

import VisibleObserver from '@/components/VisibleObserver.vue'
import ActionMenu from '@/components/feed/ActionMenu.vue'
import EnquiryModal, {
  ContactPersonsType,
  EnquiryType,
} from '@/components/modal-content/enquiry-modal/EnquiryModal.vue'
import { ToastEnquiryEnums } from '@/components/modal-content/enquiry-modal/toastTypes'
import { Config, ConfigKey } from '@/config/types'
import { useCompany } from '@/hooks/useCompany'
import { AuthenticationContext } from '@/iam/types'
import { COMMODORE_20240717_CA_SPECIALS_ENQUIRY_MODAL } from '@/utils/growthbook/constants'

type Props = {
  facetFilters: string
  postSearchText: string
  eventInterval: string
  geoPolygons?: number[][]
  postedBy: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'open:post:modal', post: Post): void
  (e: 'delete:post', uri: string): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const { company, getCompany } = useCompany()

const config: Config = inject(ConfigKey)!
const postSearchClient = algoliasearch(config.ALGOLIA.app_id, config.ALGOLIA.search_key)

const enquiryData: Ref<{
  specialsUri: string
  vesselUri: string
  vesselName: string
} | null> = ref(null)
const showEnquireModal: Ref<boolean> = ref(false)
const isLoadingCompany: Ref<boolean> = ref(false)
const isLoadingContacts: Ref<boolean> = ref(false)
const isSendingEnquiry: Ref<boolean> = ref(false)
const vessel: Ref<Vessel | null> = ref(null)
const toContactPersons: Ref<ContactPersonsType[]> = ref([])
const toastEnquiryMessage: Ref<ToastEnquiryEnums | null> = ref(null)

/**
 * filters the search results by eventInterval, postedBy and geoloc
 * @param items
 */
const filterHits = (items: PostIndexItem[]) => {
  let filteredItems = [...items]

  if (props.eventInterval) {
    filteredItems = filteredItems.filter((item: PostIndexItem) => {
      if (item.line_8) {
        return ISO8601.overlapsAny(props.eventInterval, [item.line_8])
      }
      return false
    })
  }

  // if postedBy is not empty, filter the items by postedBy
  if (props.postedBy) {
    filteredItems = filteredItems.filter(
      (item: PostIndexItem) =>
        item.line_1!.toLowerCase().includes(props.postedBy.toLowerCase()) ||
        item.line_2!.toLowerCase().includes(props.postedBy.toLowerCase()),
    )
  }

  return filteredItems
}

const setToastMessage = (message: ToastEnquiryEnums | null) => {
  if (message !== null) {
    setTimeout(() => {
      toastEnquiryMessage.value = null
    }, 15 * 1000 /* 15secs */)
  }
  toastEnquiryMessage.value = message
}

const loadContacts = async (vesselUri: string, correspondenceEmail: string) => {
  isLoadingContacts.value = true
  const response = await fetch(`/api/vessel/${decodeURIComponent(vesselUri)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    vessel.value = (await response.json()) as Vessel
  } else {
    vessel.value = null
  }

  if (!vessel.value) {
    toContactPersons.value = [{ email: correspondenceEmail }]
  } else if (
    vessel.value.contacts &&
    isCorrespondenceEmailInVesselContacts(correspondenceEmail, vessel.value.contacts)
  ) {
    toContactPersons.value = vessel.value.contacts.map((contact) => ({
      email: contact.email,
      name: contact.name,
      companyName: contact.companyName,
    }))
  } else {
    toContactPersons.value = [{ email: correspondenceEmail }, ...(vessel.value.contacts || [])]
  }

  isLoadingContacts.value = false
}

const isCorrespondenceEmailInVesselContacts = (email: string, vesselContacts?: Person[]): boolean => {
  if (vesselContacts?.length) {
    return vesselContacts.some((contact) => contact.email?.toLowerCase() === email.toLowerCase())
  }
  return false
}

const openEnquiryModal = async (
  specialsUri: string,
  vesselUri: string,
  vesselName: string,
  correspondenceEmail: string,
) => {
  if (!(specialsUri || vesselUri || vesselName || correspondenceEmail)) {
    return
  }

  if (vessel.value?.uri !== vesselUri) {
    loadContacts(vesselUri, correspondenceEmail)
  }
  if (company.value === null) {
    isLoadingCompany.value = true
    getCompany((await authenticationContext.getToken())!).then(() => {
      isLoadingCompany.value = false
    })
  }

  setToastMessage(null)
  enquiryData.value = { specialsUri, vesselUri, vesselName }
  showEnquireModal.value = true
}

const submitEnquiry = async (enquiry: EnquiryType, vesselUri: string, specialsUri: string) => {
  isSendingEnquiry.value = true

  const resp = await fetch('/api/enquiry/specials', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await authenticationContext.getToken())!}`,
    },
    body: JSON.stringify({ ...enquiry, vesselUri, specialsUri }),
  })
  isSendingEnquiry.value = false
  if (resp.status === 200) {
    setToastMessage(ToastEnquiryEnums.ENQUIRY_SUCCESSFUL)
  } else {
    setToastMessage(ToastEnquiryEnums.ENQUIRY_FAILED)
  }
  showEnquireModal.value = false
}

const closeEnquiryModal = () => {
  showEnquireModal.value = false
}

const generateMediaUri = (indexItem: PostIndexItem): string => {
  if (indexItem.hero && indexItem.line_10) {
    // line_10 is vessel uri
    return replacePathToMediaUris(indexItem.line_10, indexItem.hero)[0]
  }

  return ''
}
</script>
<template>
  <div>
    <ais-instant-search index-name="commodore" :search-client="postSearchClient">
      <ais-configure
        filters="type:post"
        :hits-per-page.camel="20"
        :distinct="true"
        :analytics="true"
        :facetFilters="facetFilters"
        :insidePolygon="geoPolygons?.length ? geoPolygons.flat(1) : undefined"
        :query.camel="postSearchText"
      />
      <div class="flex relative h-full">
        <!-- Search results -->
        <ais-infinite-hits id="ais-infinite-hits" class="w-full h-full">
          <template v-slot="{ items, refineNext, isLastPage }">
            <!-- Search results -->
            <div class="flex flex-col gap-y-4">
              <div v-for="item in filterHits(items)" :key="`item-${item.uri}`">
                <PostCardSpecial
                  v-if="item.line_3 === 'special'"
                  :uri="item.uri"
                  :title="item.line_6 || ''"
                  :description="item.line_7 || ''"
                  :event-interval="item.line_8 || ''"
                  :vessel-name="item.line_9 || ''"
                  :location="item.post.location"
                  :hero="generateMediaUri(item)"
                  :created-at="item.line_4 || ''"
                  :updated-at="item.line_5 || ''"
                  :user-name="item.line_1 || ''"
                  :company-name="item.line_2 || ''"
                  :correspondence-email="item.line_11 || ''"
                  :enquiryEmailEnabled="$growthbook.isOn(COMMODORE_20240717_CA_SPECIALS_ENQUIRY_MODAL)"
                  @on:enquire="
                    openEnquiryModal(item.uri || '', item.line_10 || '', item.line_9 || '', item.line_11 || '')
                  "
                >
                  <template #actionsDropdown v-if="item.post.uriCreatedBy === `u::${$principalIdentity.id}`">
                    <ActionMenu
                      @open:post:modal="emit('open:post:modal', item.post)"
                      @delete:post="emit('delete:post', item.uri)"
                    />
                  </template>
                </PostCardSpecial>
                <PostCardEnquiry
                  v-if="item.line_3 === 'enquiry'"
                  :uri="item.uri"
                  :title="item.line_6 || ''"
                  :description="item.line_7 || ''"
                  :event-interval="item.line_8 || ''"
                  :location="item.post.location"
                  :created-at="item.line_4 || ''"
                  :updated-at="item.line_5 || ''"
                  :user-name="item.line_1 || ''"
                  :company-name="item.line_2 || ''"
                  :correspondence-email="item.line_11 || ''"
                />
              </div>
              <div v-if="items.length === 0">
                <slot name="no-results"></slot>
              </div>
            </div>

            <template v-if="!isLastPage">
              <VisibleObserver @visible="refineNext" />
            </template>

            <div
              v-if="isLastPage && items.length !== 0"
              class="border-t border-gray-200 dark:border-gray-600 text-gray-500 dark:text-gray-400 text-center text-sm mt-4 py-2.5"
            >
              No more posts
            </div>
            <div v-else class="h-4"></div>
          </template>
        </ais-infinite-hits>
      </div>
    </ais-instant-search>

    <Teleport v-if="showEnquireModal && enquiryData" to="body">
      <EnquiryModal
        :vessel-name="enquiryData.vesselName"
        :vessel-contact-persons="toContactPersons"
        :company-name="company?.name"
        :is-loading-contacts="isLoadingContacts"
        :is-loading-company="isLoadingCompany"
        :is-sending-enquiry="isSendingEnquiry"
        @on:close="closeEnquiryModal"
        @on:submit="submitEnquiry($event, enquiryData.vesselUri, enquiryData.specialsUri)"
      />
    </Teleport>

    <!-- Toast message for enquiry -->
    <Teleport v-if="toastEnquiryMessage" to="body">
      <div
        class="z-50 absolute top-20 right-5 flex w-full max-w-xs p-4 rounded-lg shadow text-gray-500 bg-white divide-gray-200 dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800"
      >
        <div class="flex justify-between w-full">
          <div class="flex items-center gap-x-4">
            <SolidPaperAirplane
              v-if="toastEnquiryMessage === ToastEnquiryEnums.ENQUIRY_SUCCESSFUL"
              class="w-6 h-6 shrink-0 rounded-lg text-primary-600 rotate-[24deg]"
            />
            <div
              v-else-if="toastEnquiryMessage === ToastEnquiryEnums.ENQUIRY_FAILED"
              class="bg-red-300 dark:bg-red-400 rounded-md p-1"
            >
              <OutlineBell class="w-6 h-6 shrink-0 rounded-lg text-red-600" />
            </div>
            <div class="text-sm">
              <p>{{ toastEnquiryMessage }}</p>
            </div>
          </div>
          <SolidX
            class="w-4 text-gray-500 dark:text-gray-400 cursor-pointer shrink-0"
            @click.stop="toastEnquiryMessage = null"
          />
        </div>
      </div>
    </Teleport>
  </div>
</template>
