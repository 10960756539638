// *
// * Perform hash digests in a Cloudflare Worker safe way.
// *
import { md5 as js_md5 } from 'js-md5'

type Checksums = {
  md5: string
  sha1: string
  sha256: string
  sha512: string
}

/**
 * Calculate multiple hash digests for the provided content
 *
 * @param content the content to encode
 * @returns the md5, sha1, and sha256 digests
 */
export const checksums = async (content: string | ArrayBuffer): Promise<Checksums> => {
  // create some checksums on the content.
  let enc: Uint8Array
  if (content instanceof ArrayBuffer) {
    enc = new Uint8Array(content)
  } else {
    enc = new TextEncoder().encode(content)
  }

  const [md5Hex, sha1Hex, sha256Hex, sha512Hex] = await Promise.all([md5(enc), sha1(enc), sha256(enc), sha512(enc)])
  return {
    md5: md5Hex,
    sha1: sha1Hex,
    sha256: sha256Hex,
    sha512: sha512Hex,
  }
}

/**
 * Calculate a md5 hash digest for the provided content
 * @param content
 * @returns the md5
 */
export const md5 = async (content: string | Uint8Array): Promise<string> => {
  const hash = js_md5.create()
  hash.update(content)
  return hash.hex()
}

/**
 * Calculate a sha1 hash digest for the provided content
 * @param content
 * @returns the sha1
 */
export const sha1 = async (content: string | Uint8Array): Promise<string> => {
  return digest('SHA-1', content)
}

/**
 * Calculate a sha256 hash digest for the provided content
 * @param content
 * @returns the sha256
 */
export const sha256 = async (content: string | Uint8Array): Promise<string> => {
  return digest('SHA-256', content)
}

/**
 * Calculate a sha512 hash digest for the provided content
 * @param content
 * @returns the sha512
 */
export const sha512 = async (content: string | Uint8Array): Promise<string> => {
  return digest('SHA-512', content)
}

//
// Perform the digest calculation
const digest = async (algorithm: string, content: string | Uint8Array): Promise<string> => {
  let enc: Uint8Array = typeof content === 'string' ? strToUint8Array(content) : content
  const digest = await crypto.subtle.digest(algorithm, enc)
  return digestAsHex(digest)
}

/**
 * Convert a digest buffer to a hex string
 *
 * @param digest the digest
 * @returns the hex encoded string
 */
export const digestAsHex = (digest: ArrayBuffer) =>
  [...new Uint8Array(digest)].map((b) => b.toString(16).padStart(2, '0')).join('')

/**
 * Convert a string to a Uint8Array
 *
 * @param str the string to convert
 * @returns a Uint8Array of the string
 */
const strToUint8Array = (str: string) => new TextEncoder().encode(str)
