import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M2.59922 5.99879C6.07339 5.9556 9.4138 4.65295 12 2.33279C14.5861 4.65338 17.9265 5.95646 21.4008 5.99999C21.5328 6.77999 21.6 7.58399 21.6 8.40119C21.6 14.6712 17.592 20.0052 12 21.9816C6.40802 20.004 2.40002 14.67 2.40002 8.39999C2.40002 7.58159 2.46842 6.77999 2.59922 5.99879V5.99879ZM16.4484 10.4484C16.667 10.2221 16.788 9.91895 16.7852 9.60431C16.7825 9.28968 16.6563 8.9887 16.4338 8.76621C16.2113 8.54372 15.9103 8.41752 15.5957 8.41479C15.2811 8.41205 14.9779 8.533 14.7516 8.75159L10.8 12.7032L9.24842 11.1516C9.0221 10.933 8.71898 10.8121 8.40434 10.8148C8.08971 10.8175 7.78873 10.9437 7.56624 11.1662C7.34375 11.3887 7.21755 11.6897 7.21482 12.0043C7.21208 12.3189 7.33304 12.6221 7.55162 12.8484L9.95162 15.2484C10.1767 15.4734 10.4818 15.5997 10.8 15.5997C11.1182 15.5997 11.4234 15.4734 11.6484 15.2484L16.4484 10.4484V10.4484Z",
      "clip-rule": "evenodd"
    })
  ]))
}