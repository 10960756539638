import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M7.20002 7.20002V6.00002C7.20002 5.04524 7.57931 4.12957 8.25444 3.45444C8.92957 2.77931 9.84525 2.40002 10.8 2.40002H13.2C14.1548 2.40002 15.0705 2.77931 15.7456 3.45444C16.4207 4.12957 16.8 5.04524 16.8 6.00002V7.20002H19.2C19.8365 7.20002 20.447 7.45288 20.8971 7.90297C21.3472 8.35305 21.6 8.9635 21.6 9.60002V13.884C18.5276 15.0225 15.2766 15.6036 12 15.6C8.72342 15.6037 5.47246 15.0226 2.40002 13.884V9.60002C2.40002 8.9635 2.65288 8.35305 3.10297 7.90297C3.55306 7.45288 4.1635 7.20002 4.80002 7.20002H7.20002ZM9.60002 6.00002C9.60002 5.68176 9.72645 5.37654 9.9515 5.1515C10.1765 4.92645 10.4818 4.80002 10.8 4.80002H13.2C13.5183 4.80002 13.8235 4.92645 14.0486 5.1515C14.2736 5.37654 14.4 5.68176 14.4 6.00002V7.20002H9.60002V6.00002ZM10.8 12C10.8 11.6818 10.9265 11.3765 11.1515 11.1515C11.3765 10.9265 11.6818 10.8 12 10.8H12.012C12.3303 10.8 12.6355 10.9265 12.8606 11.1515C13.0856 11.3765 13.212 11.6818 13.212 12C13.212 12.3183 13.0856 12.6235 12.8606 12.8486C12.6355 13.0736 12.3303 13.2 12.012 13.2H12C11.6818 13.2 11.3765 13.0736 11.1515 12.8486C10.9265 12.6235 10.8 12.3183 10.8 12Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", { d: "M2.40002 16.4304V19.2C2.40002 19.8365 2.65288 20.447 3.10297 20.8971C3.55306 21.3472 4.1635 21.6 4.80002 21.6H19.2C19.8365 21.6 20.447 21.3472 20.8971 20.8971C21.3472 20.447 21.6 19.8365 21.6 19.2V16.4304C18.5069 17.4729 15.2641 18.0031 12 18C8.64482 18 5.41562 17.448 2.40002 16.4304Z" })
  ]))
}