<script setup lang="ts">
import jss, { Rule } from 'jss'
import { inject, onMounted, ref } from 'vue'

import ClaimedVesselCard, { ClaimedVesselProps } from '@/components/ClaimedVesselCard.vue'
import { Config } from '@/config/types'

type Props = {
  vesselDetails: ClaimedVesselProps
}

const props = defineProps<Props>()
const config: Config = inject('config')!
const hbsptFormWrapper = ref<HTMLDivElement>()

// for style creation
jss.use({
  onCreateRule(name, _decl, options): Rule {
    options.selector = name
    return null as unknown as Rule
  },
})

const commonStyles = {
  // css class applied to submit button
  '.submit-documents': {
    cursor: 'pointer',
    color: 'rgb(255, 255, 255)',
    'grid-column': 'span 2 / span 2',
    'background-color': 'rgb(28 100 242)',
    'font-weight': 500,
    'font-size': '0.875rem',
    'line-height': '1.25rem',
    padding: '0.625rem 1.25rem',
    border: 'none',
    'border-radius': '0.5rem',
  },

  '.hs_error_rollup': {
    'grid-column': 'span 2 / span 2',
  },

  // actions is the wrapper for the submit button
  '.actions': {
    padding: 0,
  },

  '.hs-form-private': {
    display: 'grid',
    'column-gap': '1rem',
    'grid-template-columns': 'repeat(2, minmax(0, 1fr))',
  },

  '.error': {
    border: '1px solid rgb(224, 36, 36) !important',
    'background-color': 'rgb(253, 242, 242) !important',
  },

  '.error::placeholder': {
    color: 'rgb(224, 36, 36) !important',
  },

  '.hs-fieldtype-text .hs-error-msg': {
    display: 'none',
  },

  '.hs-form-field': {
    'grid-column': 'span 2 / span 2',
  },

  '.legal-consent-container': {
    'grid-column': 'span 2 / span 2',
  },

  '@media (min-width: 640px)': {
    '.hs-fieldtype-text': {
      'grid-column': 'span 1 / span 1',
    },

    '.hs-fieldtype-number': {
      'grid-column': 'span 1 / span 1',
    },
  },

  '.hs-richtext p': {
    'margin-top': '0',
  },

  'div:has(.hs-richtext)': {
    'grid-column': 'span 2 / span 2',
  },

  '.hs-fieldtype-booleancheckbox': {
    'margin-bottom': '0',
  },

  '.hs-fieldtype-booleancheckbox ul': {
    margin: '0',
    padding: '0 !important',
  },

  '.hs-form-booleancheckbox-display': {
    display: 'flex',
    'align-items': 'center',
  },

  '.hs-form-booleancheckbox-display > span': {
    display: 'flex',
    'align-items': 'center',
  },

  '.hs_recaptcha': {
    'margin-top': '12px',
  },

  '.hs-fieldtype-text > label': {
    'font-size': '14px',
    'font-weight': '500',
    'line-height': '21px',
    'margin-bottom': '8px',
  },

  '.hs-fieldtype-number > label': {
    'font-size': '14px',
    'font-weight': '500',
    'line-height': '21px',
    'margin-bottom': '8px',
  },
}

const styleSheetLight = jss.createStyleSheet({
  ...commonStyles,
  '.hs-input': {
    width: '100%',
    'max-width': '100%',
  },

  '::placeholder': {
    color: 'rgb(107, 114, 128) !important',
  },

  '.hs-input[type=file]': {
    display: 'block',
    width: '100%',
    color: 'rgb(17 24 39)',
    border: '1px solid rgb(209 213 219)',
    'border-radius': '0.5rem',
    'background-color': 'rgb(249 250 251)',
    cursor: 'pointer',
  },

  '.hs-input[type=file]::file-selector-button': {
    color: 'rgb(255, 255, 255)',
    background: '#1F2937',
    border: 0,
    'font-size': '0.875rem',
    cursor: 'pointer',
    height: '100%',
    padding: '0.625rem 1rem 0.625rem 2rem',
    '-webkit-margin-start': '-1rem',
    'margin-inline-start': '-1rem',
    '-webkit-margin-end': '1rem',
    'margin-inline-end': '1rem',
  },

  '.hs-input[type=file]::file-selector-button:hover': {
    background: '#374151',
  },

  '.hs-fieldtype-text > .input > input': {
    color: 'rgb(17 24 39)',
    'background-color': 'rgb(249, 250, 251)',
    border: '1px solid rgb(209 213 219)',
    'border-radius': '0.5rem',
  },

  '.hs-fieldtype-number > .input > input': {
    color: 'rgb(17 24 39)',
    'background-color': 'rgb(249, 250, 251)',
    border: '1px solid rgb(209 213 219)',
    'border-radius': '0.5rem',
  },

  '.hs-fieldtype-text > label': {
    color: 'rgb(17, 25, 40)',
  },

  '.hs-fieldtype-number > label': {
    color: 'rgb(17, 25, 40)',
  },

  '.hs-input.hs-fieldtype-textarea': {
    color: 'rgb(17 24 39)',
    'background-color': 'rgb(249 250 251)',
    border: '1px solid rgb(209 213 219)',
    'border-radius': '0.5rem',
    padding: '0.625rem',
  },
})

const styleSheetDark = jss.createStyleSheet({
  ...commonStyles,
  '.hs-form-field label:not(.hs-error-msg)': {
    color: 'rgb(255, 255, 255) !important',
  },

  '::placeholder': {
    color: 'rgb(156, 163, 175) !important',
  },

  '.hs-input': {
    'font-family': 'ui-sans-serif, system-ui',
    color: 'rgb(255, 255, 255)',
    width: '100%',
    'max-width': '100%',
  },

  '.hs-input[type=file]': {
    display: 'block',
    width: '100%',
    color: 'rgb(156 163 175)',
    border: '1px solid rgb(75 85 99)',
    'border-radius': '0.5rem',
    'background-color': 'rgb(55 65 81)',
    cursor: 'pointer',
  },

  '.hs-input[type=file]::file-selector-button': {
    color: 'rgb(255, 255, 255)',
    background: '#4B5563',
    border: 0,
    'font-size': '0.875rem',
    cursor: 'pointer',
    height: '100%',
    padding: '0.625rem 1rem 0.625rem 2rem',
    '-webkit-margin-start': '-1rem',
    'margin-inline-start': '-1rem',
    '-webkit-margin-end': '1rem',
    'margin-inline-end': '1rem',
  },

  '.hs-input[type=file]::file-selector-button:hover': {
    background: '#6B7280',
  },

  '.hs-fieldtype-text > .input > input': {
    color: 'color: rgb(75 85 99)',
    'background-color': 'rgb(55 65 81)',
    border: '1px solid rgb(75 85 99)',
    'border-radius': '0.5rem',
  },

  '.hs-fieldtype-number > .input > input': {
    color: 'color: rgb(75 85 99)',
    'background-color': 'rgb(55 65 81)',
    border: '1px solid rgb(75 85 99)',
    'border-radius': '0.5rem',
  },

  '.hs-fieldtype-text > label': {
    color: 'color: rgb(249 250 251)',
  },

  '.hs-fieldtype-number > label': {
    color: 'color: rgb(249 250 251)',
  },

  '.hs-input.hs-fieldtype-textarea': {
    color: 'rgb(255, 255, 255)',
    'background-color': 'rgb(55 65 81)',
    border: '1px solid rgb(75 85 99)',
    'border-radius': '0.5rem',
    padding: '0.625rem',
  },
})

// for style insertion
const options = {
  region: config.HS_REGION,
  portalId: config.HS_PORTAL_ID,
  formId: config.HS_CLAIM_FORM_ID,
  cssRequired:
    sessionStorage.getItem('prefers-color-scheme') === 'dark' ? styleSheetDark.toString() : styleSheetLight.toString(),
  submitButtonClass: 'submit-documents',
}

const onError = () => {
  console.debug('Failed to load HubSpot form')
  if (hbsptFormWrapper.value) {
    hbsptFormWrapper.value.hidden = true
  }
}

onMounted(() => {
  if (!hbsptFormWrapper.value) {
    return onError()
  }

  hbsptFormWrapper.value.hidden = true

  const hbsptForm = window.hbspt.forms.create({
    ...options,
    target: '#claim-form',
    redirectUrl: '/claim-vessel/success',
    onFormError: function (...args: any) {
      console.debug('=============================> onFormError', args)
    },
    onFormSubmitted: function (...args: any) {
      console.debug('=============================> onFormSubmitted', args)
    },
  })

  if (!hbsptForm) {
    return onError()
  }

  hbsptForm.onReady(() => {
    if (!hbsptFormWrapper.value) {
      return
    }

    const iframe = hbsptFormWrapper.value.querySelector<HTMLIFrameElement>(':scope iframe') ?? undefined
    const iframeDocument = iframe?.contentDocument ?? undefined
    const html = iframeDocument?.documentElement

    if (!html || !iframe) {
      return onError()
    }

    hbsptFormWrapper.value.hidden = false
  })
})
</script>
<template>
  <div class="p-4">
    <div
      class="max-w-5xl w-full mx-auto flex flex-col justify-items-center gap-y-4 p-8 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700"
    >
      <div class="flex flex-col gap-y-2">
        <h4 class="font-bold text-base sm:text-2xl text-gray-900 dark:text-gray-50">Verification</h4>
        <p class="text-sm sm:text-base text-gray-500 dark:text-gray-400">
          Help us to verify that you are the official Central Agent for this yacht by attaching a copy of the CA
          Agreement.
        </p>
      </div>

      <ClaimedVesselCard
        v-if="
          props.vesselDetails.vessel_name ||
          props.vesselDetails.vessel_length ||
          props.vesselDetails.vessel_builder ||
          props.vesselDetails.vessel_built_year
        "
        :vessel_name="props.vesselDetails.vessel_name"
        :vessel_length="props.vesselDetails.vessel_length"
        :vessel_builder="props.vesselDetails.vessel_builder"
        :vessel_built_year="props.vesselDetails.vessel_built_year"
        :vessel_existing_uri="props.vesselDetails.vessel_existing_uri"
        :vessel_hero="props.vesselDetails.vessel_hero"
      />

      <div ref="hbsptFormWrapper" hidden id="claim-form"></div>
    </div>
  </div>
</template>
