import ls from 'localstorage-slim'
import { defineStore } from 'pinia'
import { Ref, ref, watch } from 'vue'

type PreferredViewType = 'table' | 'card' | 'timeline'

// define the store
export const usePreferredViewStore = defineStore('preferred-view-commodore', () => {
  const preferredView: Ref<PreferredViewType> = ref(ls.get('preferred-view-commodore') || 'table' || 'timeline')

  watch(preferredView, () => ls.set('preferred-view-commodore', preferredView.value))

  return {
    preferredView,
  }
})
