<script setup lang="ts">
import { DateTime, Duration, Interval } from 'luxon'
import { Ref, ref } from 'vue'

import DateRangePicker from '@ankor-io/blocks/components/DateRangePicker/DateRangePicker.vue'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { Season } from '@ankor-io/common/vessel/types'
import { OutlineDelete, OutlinePencil, OutlineTrash } from '@ankor-io/icons/outline'

import ButtonVue from '@/components/Button.vue'
import Dropdown from '@/components/Dropdown.vue'

type Props = {
  season: Season
  seasonIndex: number
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:season', value: Season): void
  (e: 'remove:season', index: number): void
}>()

const isSeasonInfoOpen: Ref<boolean> = ref(false)

const seasonLabelOptions = [
  { label: 'High', value: 'High' },
  { label: 'Shoulder', value: 'Shoulder' },
  { label: 'Low', value: 'Low' },
  { label: 'Special', value: 'Special' },
]

/**
 * Check if the checkbox should be disabled
 * @param seasonIndex
 * @param intervalIndex
 */
const isCheckboxDisabled = (intervalIndex: number) => {
  const season: Season = ObjectUtil.deepCopy(props.season)
  if (!season.intervals[intervalIndex]) {
    return true
  }

  try {
    const interval: Interval = ISO8601.fromIntervalString(season.intervals[intervalIndex])
    return !interval.start || !interval.end
  } catch (e) {
    return true
  }
}

/**
 * Check if the checkbox should be checked
 * @param interval
 */
const isChecked = (interval: string) => {
  if (!interval || interval === '') {
    return false
  }
  try {
    ISO8601.fromIntervalString(interval)
    return interval.startsWith('R')
  } catch (e) {
    return false
  }
}

/**
 * Get the start date from an interval string
 * @param intervalString
 */
const getStartDate = (intervalString: string | undefined) => {
  if (!intervalString || intervalString === '') {
    return ''
  }
  try {
    const interval: Interval = ISO8601.fromIntervalString(intervalString)
    return interval.start?.toUTC().toFormat('yyyy-MM-dd') || ''
  } catch (e) {
    return ''
  }
}

/**
 * Get the end date from an interval string
 * @param intervalString
 */
const getEndDate = (intervalString: string) => {
  if (!intervalString || intervalString === '') {
    return ''
  }
  try {
    const interval: Interval = ISO8601.fromIntervalString(intervalString)
    return interval.end?.toUTC().toFormat('yyyy-MM-dd') || ''
  } catch (e) {
    return ''
  }
}

/**
 * Add a new interval to the intervals array
 * @param index
 */
const addInterval = () => {
  const season: Season = ObjectUtil.deepCopy(props.season)
  season.intervals.push('')
  emit('update:season', season)
}

/**
 * Delete an interval from the intervals array
 * @param seasonIndex
 * @param intervalIndex
 */
const deleteInterval = (intervalIndex: number) => {
  const season: Season = ObjectUtil.deepCopy(props.season)
  season.intervals.splice(intervalIndex, 1)
  emit('update:season', season)
}

/**
 * Update the season label
 * @param index
 * @param event
 */
const updateSeasonLabel = (event: string) => {
  const value: string = event
  const season: Season = ObjectUtil.deepCopy(props.season)
  season.label = value
  emit('update:season', season)
}

/**
 * Update the season description
 * @param index
 * @param event
 */
const updateSeasonDescription = (event: Event) => {
  const value: string = (event.target as HTMLInputElement).value
  const season: Season = ObjectUtil.deepCopy(props.season)
  season.description = value
  emit('update:season', season)
}

/**
 * Update the season interval from start and end date
 * @param seasonIndex
 * @param intervalIndex
 * @param event
 */
const updateSeasonInterval = (intervalIndex: number, event: { startDate: string; endDate: string }) => {
  const { startDate, endDate } = event
  if (!startDate || !endDate) {
    return
  }
  const season: Season = ObjectUtil.deepCopy(props.season)
  season.intervals[intervalIndex] = ISO8601.toIntervalString(
    DateTime.fromISO(startDate, { zone: 'utc' }).startOf('day'),
    DateTime.fromISO(endDate, { zone: 'utc' }).endOf('day'),
    'utc',
  )
  emit('update:season', season)
}

/**
 * Update the annual recurrence of an interval
 * @param seasonIndex
 * @param intervalIndex
 * @param event
 */
const updateAnnualRecurrence = (intervalIndex: number, event: Event) => {
  const checked: boolean = (event.target as HTMLInputElement).checked
  const season: Season = ObjectUtil.deepCopy(props.season)
  try {
    const intervalString: string = season.intervals[intervalIndex]
    const interval: Interval = ISO8601.fromIntervalString(intervalString)
    season.intervals[intervalIndex] = checked
      ? ISO8601.toRecurringIntervalString(
          interval.start!.toUTC().startOf('day'),
          interval.end!.toUTC().endOf('day'),
          Duration.fromISO('P1Y'),
          Infinity,
          'utc',
        )
      : ISO8601.toIntervalString(interval.start!.toUTC().startOf('day'), interval.end!.toUTC().endOf('day'), 'utc')
    emit('update:season', season)
  } catch (e) {
    return
  }
}
</script>
<template>
  <div class="flex flex-col gap-y-4 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
    <!-- Season -->
    <div v-if="!isSeasonInfoOpen" class="flex flex-col">
      <div class="flex items-center justify-between">
        <div class="flex gap-x-1 text-gray-900 dark:text-white">
          <span class="font-semibold">Season:</span>
          {{ season.label || seasonIndex + 1 }}
        </div>

        <div class="flex gap-x-4">
          <OutlinePencil
            id="edit-season"
            class="w-4 h-4 shrink-0 cursor-pointer transition-all text-gray-900 dark:text-white hover:text-gray-500 hover:dark:text-gray-300"
            @click="isSeasonInfoOpen = true"
          />
          <OutlineTrash
            id="delete-season"
            class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
            @click="emit('remove:season', seasonIndex)"
          />
        </div>
      </div>
      <div v-if="season.intervals.length" class="flex gap-x-1 text-sm text-gray-900 dark:text-white">
        <div class="flex flex-wrap gap-x-4">
          <template v-for="(interval, intervalIndex) in season.intervals" :key="`${interval}-${intervalIndex}`">
            <div v-if="interval" class="flex gap-x-1">
              <span v-if="intervalIndex === 0" class="font-semibold">Active</span>
              <span class="font-semibold">Period {{ intervalIndex + 1 }}:</span>
              {{ ISO8601.fromIntervalString(interval, { setZone: true }).toFormat('MMM dd') }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Open -->
    <template v-else>
      <div class="cursor-pointer flex justify-between items-start bg-transparent">
        <!-- Season dropdown -->
        <div class="flex flex-col gap-y-2 max-w-[20rem] w-full">
          <h6 class="flex text-sm font-medium text-gray-900 dark:text-white">Season</h6>
          <Dropdown
            id="seasonLabel"
            :value="season.label || ''"
            :options="seasonLabelOptions"
            @select:value="updateSeasonLabel($event)"
          />
        </div>
        <!-- Finish button -->
        <a
          type="button"
          class="flex h-min cursor-pointer items-center py-2 px-3 text-xs font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 focus:dark:ring-gray-500 dark:hover:text-white dark:hover:bg-gray-700"
          @click.once="isSeasonInfoOpen = false"
        >
          Finish
        </a>
      </div>

      <!-- Season Description -->
      <div class="flex flex-col gap-y-2">
        <label for="season-description" class="flex text-sm font-medium text-gray-900 dark:text-white">
          Description
        </label>
        <textarea
          rows="3"
          id="season-description"
          placeholder="Enter the Description"
          class="block py-3 px-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          :value="season.description || ''"
          @blur.stop="updateSeasonDescription($event)"
        ></textarea>
      </div>

      <!-- Season Intervals -->
      <div class="flex flex-col gap-y-2">
        <h6 class="flex text-sm font-medium text-gray-900 dark:text-white">Active Period</h6>

        <div class="flex flex-col gap-y-4">
          <div
            v-for="(interval, intervalIndex) of season.intervals"
            class="flex flex-col gap-y-2"
            :key="`${seasonIndex}-interval-${intervalIndex}`"
          >
            <div class="flex items-center">
              <DateRangePicker
                :show-to-text="true"
                :start-date="getStartDate(interval)"
                :end-date="getEndDate(interval)"
                @update:start:and:end="updateSeasonInterval(intervalIndex, $event)"
              />
              <OutlineDelete
                class="ml-2 w-8 h-8 p-2 cursor-pointer stroke-red-700 hover:bg-red-100 rounded-xl bg-opacity-20 transition-all duration-500"
                @click.stop="deleteInterval(intervalIndex)"
              />
            </div>

            <!-- Checkbox to repeat annually -->
            <div class="flex items-center">
              <input
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                :checked="isChecked(interval)"
                :id="`repeat-annually-checkbox-${intervalIndex}`"
                :disabled="isCheckboxDisabled(intervalIndex)"
                :class="{ 'opacity-50 cursor-not-allowed': isCheckboxDisabled(intervalIndex) }"
                @click.stop="updateAnnualRecurrence(intervalIndex, $event)"
              />
              <label
                class="ml-2 text-sm text-gray-500 dark:text-gray-300"
                :for="`repeat-annually-checkbox-${intervalIndex}`"
              >
                Repeats Annually
              </label>
            </div>
          </div>

          <!-- Add another duration interval -->
          <ButtonVue
            class="h-fit w-min"
            name="+ Add another duration"
            :isPrimary="false"
            :has-border="true"
            @click.stop="addInterval()"
          ></ButtonVue>
        </div>
      </div>
    </template>
  </div>
</template>
