<template>
  <section class="bg-gray-50 dark:bg-gray-900 h-[calc(100vh-2rem)] flex items-center">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center">
        <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
          403
        </h1>
        <p class="text-center text-gray-500 font-medium">
          <span class="font-bold">Forbidden!</span> Contact Administrator.
        </p>
        <RouterLink
          to="/"
          class="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
          aria-current="page"
        >
          Back to Homepage
        </RouterLink>
      </div>
    </div>
  </section>
</template>
