<script setup lang="ts">
import * as turf from '@turf/turf'
import { useWindowSize } from '@vueuse/core'
import algoliasearch from 'algoliasearch/lite'
import { Ref, inject, onBeforeMount, onMounted, provide, ref } from 'vue'

import DateRangePicker from '@ankor-io/blocks/components/DateRangePicker/DateRangePicker.vue'
import PostsEmpty from '@ankor-io/blocks/components/feed/PostsEmpty.vue'
import SearchEmpty from '@ankor-io/blocks/components/feed/SearchEmpty.vue'
import { getEndDate, getStartDate, updateInterval } from '@ankor-io/common/date/helper'
import { Place as AnkorPlace } from '@ankor-io/common/itinerary/Itinerary'
import { Geo, GeoFilter } from '@ankor-io/common/vessel/types'
import { HiOutlineMagnifyingGlass } from '@ankor-io/icons/hi_outline'
import { OutlineBarsArrowLeft, OutlineBarsArrowRight, OutlineRefresh } from '@ankor-io/icons/outline'
import { SolidX } from '@ankor-io/icons/solid'
import { Config, ConfigKey } from '@ankor-io/radar/src/config/types'
import { AuthenticationContext } from '@ankor-io/radar/src/iam/types'

import GeoPlaceSearchInput from '@/components/GeoPlaceSearchInput.vue'
import FeedHits from '@/components/feed/FeedHits.vue'
import { useFacetPanel } from '@/facet-panel/useFacetPanel'

const facetPanelState = useFacetPanel()

// use window width to determine if the facet panel should be open on default / mobile vs desktop
const { width: windowWidth } = useWindowSize()
const MOBILE_BREAKPOINT = 768
const isMobile = windowWidth.value <= MOBILE_BREAKPOINT

const postSearchText: Ref<string> = ref('')
const eventInterval: Ref<string> = ref('')
const postedByText: Ref<string> = ref('')

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const facetFilters = '[["line_3:enquiry"]]'
const loadingGeoData: Ref<boolean> = ref(false)
const geoPolygons: Ref<number[][]> = ref([])
const geoFilters: Ref<GeoFilter[]> = ref([])
const showResults: Ref<boolean> = ref(false)

onBeforeMount((): void => {
  const config: Config = inject(ConfigKey)!
  const geoSearchClient = algoliasearch(config.ALGOLIA.app_id, config.ALGOLIA.search_key)
  provide('geoSearchClient', geoSearchClient)
})

onMounted(async () => {
  if (!isMobile) {
    facetPanelState.updateFacetPanelState(true)
  }
})

const clearFilters = () => {
  postSearchText.value = ''
  eventInterval.value = ''
  geoFilters.value = []
  geoPolygons.value = []
  postedByText.value = ''
}

/**
 * Update the tags for the geoFilters and query stowage for the new tag
 * @param geoFiltersEvent The geo filters tags on the autocomplete component
 */
const onGeoFilterChange = async (geoFiltersEvent: GeoFilter[]): Promise<void> => {
  geoFilters.value = geoFiltersEvent
  showResults.value = false
  const geoFiltersForPlace = geoFiltersEvent.filter((tag) => tag.uri.includes('place'))
  const geoFiltersForGeo = geoFiltersEvent.filter((tag) => !tag.uri.includes('place'))
  if (geoFiltersForPlace.length > 0) {
    await loadPlaceData(geoFiltersForPlace[geoFiltersForPlace.length - 1].uri)
  }
  if (geoFiltersForGeo.length > 0) {
    await loadGeoData(geoFiltersForGeo[geoFiltersForGeo.length - 1].uri)
  }
  showResults.value = true
}

/**
 * Convert the geo JSON to a number[][] that is compatible with algolia
 * @param geoJson
 */
const convertGeoJsonToPolygons = (geoJson: any) => {
  if (geoJson.type === 'FeatureCollection') {
    const poly = turf.polygon(geoJson.features[0].geometry.coordinates)
    const coords = turf.getCoords(poly)
    // geoJson has coordinates in [longitude, latitude] but algolia expects [latitude, longitude]
    const reversedPolygons = coords.map((coord) => coord.flat(1).reverse())
    return [...geoPolygons.value, reversedPolygons]
  } else {
    if (geoJson.geometry.type === 'Polygon') {
      const poly = turf.polygon(geoJson.geometry.coordinates)
      const coords = turf.getCoords(poly)
      // geoJson has coordinates in [longitude, latitude] but algolia expects [latitude, longitude]
      const reversedPolygons = coords.map((coord) => coord.flat(1).reverse())
      return [...geoPolygons.value, reversedPolygons]
    } else {
      const poly = turf.multiPolygon(geoJson.geometry.coordinates)
      const coords = turf.getCoords(poly)
      // geoJson has coordinates in [longitude, latitude] but algolia expects [latitude, longitude]
      const reversedPolygons = coords.flat(1).map((coord) => coord.flat(1).reverse())
      return [...geoPolygons.value, reversedPolygons]
    }
  }
}

/**
 * Removes a tag from the geoFilters and the geoPolygons - ais-configure is reactive to the change
 * @param geoUri The geo uri
 *
 */
const removeGeoTag = (geoUri: string): void => {
  const index = geoFilters.value.findIndex((tag) => tag.uri === geoUri)
  geoFilters.value.splice(index, 1)
  geoPolygons.value.splice(index, 1)

  onGeoFilterChange(geoFilters.value)
}

/**
 * Convert the coordinates to a polygon
 *
 * @param place
 */
const convertPlaceToPolygon = (place: AnkorPlace) => {
  const center = [place.location.coordinates.longitude, place.location.coordinates.latitude]
  const radius = 50 // FIXME: Check what the radius should be
  const options = {}
  const circle = turf.circle(center, radius, options)

  const poly = turf.multiPolygon([circle.geometry.coordinates])
  const coords = turf.getCoords(poly)
  const reversedPolygons = coords.flat(1).map((coord) => coord.flat(1).reverse())
  return [...geoPolygons.value, reversedPolygons]
}

/**
 * Gets the geodata from stowage with the geo uri then stores the polygon data in geoPolygons, passed into ais-configure
 * @param geoUri The geo uri
 */
const loadGeoData = async (geoUri: string) => {
  loadingGeoData.value = true

  const response = await fetch(`/api/vessel/${decodeURIComponent(geoUri)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    const resp = await response.json()
    geoPolygons.value = convertGeoJsonToPolygons((resp as Geo).geojson)
  }

  loadingGeoData.value = false
}

const loadPlaceData = async (placeUri: string) => {
  loadingGeoData.value = true

  const response = await fetch(`/api/place/${decodeURIComponent(placeUri)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    const place: AnkorPlace = await response.json()
    geoPolygons.value = convertPlaceToPolygon(place)
  }

  loadingGeoData.value = false
}
</script>
<template>
  <div class="flex flex-col sm:flex-row sm:justify-center gap-2 sm:gap-4 h-[calc(100vh-3.75rem)] overflow-hidden">
    <div class="sm:basis-[17rem] px-4 sm:px-0 flex flex-col gap-y-2 sm:gap-y-4">
      <!-- heading -->
      <h4>Charter Requests</h4>

      <!-- search-box -->
      <div class="flex w-full">
        <button
          v-show="true"
          id="show-filter-button"
          class="flex-shrink-0 inline-flex items-center py-2 px-5 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-0 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white dark:border-gray-600"
          type="button"
          @click="facetPanelState.updateFacetPanelState(!facetPanelState.isFacetPanelOpen.value)"
        >
          <OutlineBarsArrowLeft
            v-if="facetPanelState.isFacetPanelOpen.value"
            class="w-6 h-6 shrink-0 stroke-gray-500 dark:stroke-gray-400"
          />
          <OutlineBarsArrowRight v-else class="w-6 h-6 shrink-0 stroke-gray-500 dark:stroke-gray-400" />
        </button>

        <div class="relative w-full">
          <input
            type="search"
            id="search-posts"
            class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
            placeholder="Search keyword..."
            autocomplete="off"
            v-model="postSearchText"
          />
          <button
            class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <HiOutlineMagnifyingGlass class="w-4 h-4 stroke-white stroke-2" />
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>

      <!-- filters -->
      <div :class="facetPanelState.isFacetPanelOpen.value ? 'z-40 md:flex md:relative absolute inset-0' : ''">
        <div
          class="z-50 absolute top-4 right-4 cursor-pointer p-1 bg-gray-50 dark:bg-gray-900"
          :class="facetPanelState.isFacetPanelOpen.value ? 'flex md:hidden' : 'hidden'"
          @click="facetPanelState.updateFacetPanelState(false)"
        >
          <SolidX class="w-4 h-4 text-gray-900 dark:text-white" />
        </div>
        <div
          tabindex="-1"
          class="scrollbar-hide filters-panel transition-[opacity,width] duration-300 ease-in-out text-gray-900 dark:text-white whitespace-nowrap flex flex-col gap-y-4"
          :class="
            facetPanelState.isFacetPanelOpen.value
              ? 'px-4 py-12 md:px-0 md:py-0 w-full h-full bg-gray-50 dark:bg-gray-900 opacity-100'
              : 'h-0 min-w-0 w-0 md:max-w-full opacity-0 overflow-hidden'
          "
        >
          <!-- Clear Filter -->
          <div class="flex gap-x-4 items-center justify-between">
            <h6>Filters</h6>
            <div class="self-end flex items-center gap-x-1 cursor-pointer text-xs" @click.stop="clearFilters">
              <OutlineRefresh class="w-4 h-4 text-primary-600" />
              <span class="text-sm text-primary-600">Clear filters</span>
            </div>
          </div>

          <!-- Charter Period -->
          <div>
            <label class="block mb-2" for="startDate">Charter period</label>
            <DateRangePicker
              end-date-placeholder="End date"
              start-date-placeholder="Start date"
              :showToText="false"
              :end-date="getEndDate(eventInterval)"
              :start-date="getStartDate(eventInterval)"
              @update:start:and:end="eventInterval = updateInterval($event)!"
            />
          </div>

          <!-- location -->
          <div>
            <label class="block mb-2" for="location">Location</label>
            <GeoPlaceSearchInput
              operation-type="union"
              :geo-tags="geoFilters || []"
              :loading="loadingGeoData"
              @update:geo="(value:GeoFilter[]) => onGeoFilterChange(value)"
              @remove:geo="(value:string) => removeGeoTag(value)"
            />
          </div>

          <!-- Posted By -->
          <div>
            <label class="block mb-2" for="postedBy">Posted by</label>
            <div class="relative">
              <!-- postedByText input -->
              <div class="relative h-fit">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <HiOutlineMagnifyingGlass class="w-4 stroke-primary-600 dark:stroke-white" />
                </div>
                <input
                  type="text"
                  id="postedBy"
                  autocomplete="off"
                  class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-10 py-2.5 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 dark:border-gray-600 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-700"
                  placeholder="Search for a contributor"
                  v-model="postedByText"
                />
                <div
                  v-if="postedByText"
                  class="absolute inset-y-0 right-0 flex items-center pr-3.5 cursor-pointer"
                  @click="postedByText = ''"
                >
                  <SolidX class="w-4 text-gray-500 dark:text-gray-400 cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sm:flex-1 flex items-center flex-col gap-y-4">
      <div class="h-10 flex justify-center items-center text-gray-500">
        You have view only permissions for this board
      </div>

      <!-- separator -->
      <hr class="hidden sm:block w-full max-w-2xl border-t border-gray-200 dark:border-gray-600" />

      <!-- FeedHits -->
      <div
        class="h-[calc(100vh-14.5rem)] sm:h-[calc(100vh-9.6rem)] w-full overflow-y-auto overflow-hidden flex justify-center"
      >
        <FeedHits
          class="w-full max-w-2xl sm:ml-4"
          :facetFilters="facetFilters"
          :postSearchText="postSearchText"
          :eventInterval="eventInterval"
          :geoPolygons="geoPolygons"
          :postedBy="postedByText"
        >
          <template #no-results>
            <SearchEmpty v-if="postSearchText" />
            <PostsEmpty v-else />
          </template>
        </FeedHits>
      </div>
    </div>
  </div>
</template>
