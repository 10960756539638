<script setup lang="ts">
import { initAccordions } from 'flowbite'
import { Ref, onMounted, ref } from 'vue'

import { OutlineChevronDown } from '@ankor-io/icons/outline'

type Props = {
  id: string
  dataAccordion: 'collapse' | 'open'
  isNested?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isNested: false,
})

const isOpen: Ref<boolean> = ref(true)

onMounted(() => {
  initAccordions()
})
</script>
<template>
  <div class="w-full" :data-accordion="props.dataAccordion">
    <div
      data-test="accordion-container"
      class="cursor-pointer flex justify-between items-center bg-transparent"
      :aria-controls="props.id"
      :aria-expanded="!props.isNested"
      :data-accordion-target="`#${props.id}`"
      @click="isOpen = !isOpen"
    >
      <h3
        data-test="title-heading"
        class="font-bold tracking-wider text-gray-700 dark:text-gray-300"
        :class="{ 'text-sm': props.isNested }"
      >
        <slot name="title"></slot>
      </h3>
      <OutlineChevronDown
        data-test="accordion-chevron"
        class="w-4 h-4 shrink-0 transition-all"
        :class="{ 'rotate-180': isOpen }"
      />
    </div>
    <div class="hidden" :id="props.id">
      <hr
        v-if="!props.isNested"
        class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0"
        data-test="accordion-separator"
      />
      <slot name="content"></slot>
    </div>
  </div>
</template>
