import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
import VueTippy from 'vue-tippy'

import { iamPredicates } from '@ankor-io/common/auth/client/predicates'
import { sentryVueInit } from '@ankor-io/sentry-conflux/src/sentry'

import App from '@/AppV2.vue'
import { fetchConfig } from '@/config/fetch'
import { createAuthentication } from '@/iam/authentication'
import { AuthenticationContext } from '@/iam/types'
import router from '@/router'
import { createSearchClient } from '@/search/client'
import '@/style.css'
import { growthbookInstance } from '@/utils/growthbook'
import { initializeGtm } from '@/utils/gtm'

// First load the config
const config = await fetchConfig()

/**
 * Track application errors
 */
window.onerror = function (msg, _url, _line, _col, error) {
  //@ts-ignore
  window.heap?.track('applicationError', {
    message: msg,
    stack: error?.stack,
  })
  console.log(error)
}

/**
 * Track application promises rejections
 */
window.addEventListener('unhandledrejection', function (event) {
  //@ts-ignore
  window.heap?.track('applicationError', {
    message: event.reason.message,
    stack: event.reason.stack,
  })
  console.log(event)
})

const app = sentryVueInit(createApp(App), config.SENTRY)
app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'auto-end',
      allowHTML: true,
    }, // => Global default options * see all props
  },
)

const authenticationContext: AuthenticationContext = await createAuthentication(config.AUTHENTICATION_CLIENT_OPTIONS)

// Install the navigation guards
router.beforeEach(async (to) =>
  iamPredicates(authenticationContext, config.AUTHENTICATION_CLIENT_OPTIONS).beforeEach(to),
)

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.href = to.fullPath
  }
})

app.use(config)
app.use(router)

//
app.use(initializeGtm(config, router))
app.use(authenticationContext)

//
app.use(VueObserveVisibility)

// Get a token for one-time-use mechanisms, e.g. build search key.
const _token = await authenticationContext.getToken()
if (_token) {
  const searchClient = await createSearchClient(_token, config.ALGOLIA.app_id)
  app.use(searchClient)

  // Check if user is entitled to use the application
  const { isEntitled, verified } = await fetch('/api/entitled', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${_token}`,
    },
  })
    .then(async (res: any) => await res.json())
    .catch((err: any) => {
      console.error(err)
      return { isEntitled: false, type: 'error' }
    })

  if (!isEntitled) {
    router.push({ name: 'payment' })
  } else if (!verified) {
    router.push({ name: 'request-access' })
  }
}

/**
 * Track vue application errors
 */
app.config.errorHandler = (err: any, vm: any, info: any) => {
  //@ts-ignore
  window.heap?.track('applicationError', {
    error: err.message,
    stack: err.stack,
    info: info,
    vm: vm,
  })
  console.log(err)
}

const growthbook = await growthbookInstance.createGrowthbookClient(
  {
    clientKey: config.GROWTHBOOK.clientKey,
    enableDevMode: true,
  },
  authenticationContext,
)
app.use(growthbook)

const pinia = createPinia()
app.use(pinia)

app.mount('#app')
