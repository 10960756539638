<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { Ref, ref } from 'vue'

import { HiSolidEllipsisHorizontal } from '@ankor-io/icons/hi_solid'
import { OutlineDelete, OutlineEdit } from '@ankor-io/icons/outline'

const emit = defineEmits<{
  (e: 'open:post:modal'): void
  (e: 'delete:post'): void
}>()

const isMenuOpen: Ref<boolean> = ref(false)

const openPostModal = () => {
  isMenuOpen.value = false
  emit('open:post:modal')
}

const deletePost = () => {
  isMenuOpen.value = false
  emit('delete:post')
}
</script>
<template>
  <div class="relative flex">
    <button
      class="post-dropdown rounded-lg hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-white focus:ring-gray-50 dark:hover:bg-gray-700 dark:focus:bg-gray-700 dark:focus:ring-gray-600"
      type="button"
      @click.stop="isMenuOpen = !isMenuOpen"
    >
      <HiSolidEllipsisHorizontal class="w-8 h-8 shrink-0 fill-gray-500 dark:fill-gray-300" />
    </button>

    <!-- Dropdown menu -->
    <OnClickOutside @trigger="isMenuOpen = false">
      <div
        v-if="isMenuOpen"
        class="w-40 h-24 absolute -left-32 top-11 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
      >
        <ul class="py-2" aria-labelledby="dropdownDefaultButton">
          <li>
            <button
              class="post-dropdown-edit flex items-center gap-x-3 w-full px-6 py-2 text-sm text-gray-900 hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              @click="openPostModal"
            >
              <OutlineEdit class="w-3 h-3 shrink-0 text-black dark:text-gray-50" />
              Edit Post
            </button>
          </li>
          <li>
            <button
              class="post-dropdown-delete flex items-center gap-x-3 w-full px-6 py-2 text-sm text-gray-900 hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              @click="deletePost"
            >
              <OutlineDelete class="w-3 h-3 shrink-0 text-red-600 dark:text-red-400" />
              Delete Post
            </button>
          </li>
        </ul>
      </div>
    </OnClickOutside>
  </div>
</template>
