<script setup lang="ts">
import { initPopovers } from 'flowbite'
import { twMerge } from 'tailwind-merge'
import { onMounted } from 'vue'

import { OutlineEyeOff } from '@ankor-io/icons/outline'

const props = defineProps<{ id: string; iconStyleClass?: string }>()

const getIconStyles = (): string => {
  return twMerge(`w-4 ${props.iconStyleClass}`)
}

onMounted(() => {
  initPopovers()
})
</script>

<template>
  <div
    data-popover
    :id="props.id"
    role="tooltip"
    class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
  >
    <div class="px-3 py-2">
      <p>This field will not be visible on the external calendar. It is only visible to you and your internal team.</p>
    </div>
    <div data-popper-arrow></div>
  </div>
  <div class="flex justify-center" :data-popover-target="props.id">
    <OutlineEyeOff :class="getIconStyles()" />
  </div>
</template>
