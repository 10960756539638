<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { Ref, computed, ref } from 'vue'

import { OutlineXMark } from '@ankor-io/icons/outline'

type Props = {
  tags?: string[]
  placeholder?: string
  disabled?: boolean
  options: { label: string; value: string }[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'add:tag', value: string): void
  (e: 'remove:tag', index: number): void
}>()

const newTag: Ref<string> = ref('')
const highlightedResultIndex: Ref<number> = ref(-1)
const showResults: Ref<boolean> = ref(false)
const highlightedTagIndex: Ref<number> = ref(props.tags?.length || 0)
const items = computed(() => {
  return props.options
    .filter((item) => !props.tags?.includes(item.value))
    .filter((item) => item.value.toLowerCase().includes(newTag.value.toLowerCase()))
})

/**
 * Highlight previous tag
 */
const highlightPreviousTag = () => {
  if (highlightedTagIndex.value > 0) {
    highlightedTagIndex.value--
  }
}

/**
 * Highlight next tag
 */
const highlightNextTag = () => {
  if (!props.tags || highlightedTagIndex.value === props.tags.length) {
    return
  }

  highlightedTagIndex.value++
}

/**
 * On escape, the user can delete a tag
 * @param event KeyboardEvent escape key press
 */
const adjustTagByBackspace = (event: KeyboardEvent) => {
  const target = event.currentTarget as HTMLInputElement
  if (target.value) {
    return
  }

  if (highlightedTagIndex.value <= (props.tags?.length || 0) - 1) {
    removeTag(highlightedTagIndex.value)
    highlightedTagIndex.value = (props.tags?.length || 0) - 1
  } else {
    highlightPreviousTag()
  }
}

/**
 * Updating the newTag var and resetting highlightedTagIndex
 * @param event Event
 */
const updateNewTag = (event: Event): void => {
  const target = event.currentTarget as HTMLInputElement
  newTag.value = target.value
  showResults.value = true

  // Reset highlighting if user started to highlight existing tags
  highlightedTagIndex.value = (props.tags?.length || 0) + 1
}

/**
 * Emit a tag to add
 */
const addTag = (): void => {
  if (props.disabled) {
    return
  }
  // Don't submit nothing
  if (!newTag.value) {
    return
  }

  emit('add:tag', newTag.value)

  // Reset the tag
  newTag.value = ''
  showResults.value = false
  highlightedTagIndex.value = (props.tags?.length || 0) + 1
}

const highlightText = (text: string, search: string) => {
  return text.replace(new RegExp(search, 'gi'), (match) => `<mark>${match}</mark>`)
}

const addRoleTag = () => {
  if (highlightedResultIndex.value === -1) {
    addTag()
    return
  } else {
    const item = items.value[highlightedResultIndex.value]
    if (item) {
      newTag.value = item.value
      addTag()
    }
  }
}

/**
 * Emit a tag to remove
 * @param index The position in the tags array
 */
const removeTag = (index: number): void | boolean => {
  if (props.disabled) {
    return
  }
  emit('remove:tag', index)
}
</script>
<template>
  <OnClickOutside
    @trigger="showResults = false"
    class="flex flex-wrap gap-2 items-center border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-3 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 focus:shadow-none focus:ring-0"
    :class="props.disabled ? 'bg-gray-200 dark:bg-gray-400' : 'bg-gray-50 dark:bg-gray-700'"
  >
    <span
      v-for="(tag, index) in props.tags"
      class="flex items-center px-1.5 py-0.5 rounded-md font-medium text-xs h-6 text-gray-600 dark:text-white"
      :class="[
        highlightedTagIndex === index
          ? 'border border-gray-400 dark:border-primary-400 bg-gray-300 dark:bg-primary-700'
          : 'bg-gray-200 dark:bg-primary-600',
        { 'bg-gray-300 dark:bg-primary-400': props.disabled },
      ]"
      :key="`${tag}-${index}`"
      :id="`${index}-${tag}-badge-dismiss-default`"
    >
      {{ tag }}
      <button
        v-if="!props.disabled"
        type="button"
        class="flex items-center"
        aria-label="Remove"
        :data-dismiss-target="`#${index}-${tag}-badge-dismiss-default`"
        @click.stop="removeTag(index)"
      >
        <OutlineXMark class="w-2 h-2 ml-2" />
        <p class="sr-only">{{ `Remove ${tag}` }}</p>
      </button>
    </span>
    <input
      type="text"
      class="flex-grow text-gray-900 border-none text-sm rounded-lg focus:border-none focus:outline-none block py-1 px-1 dark:placeholder-gray-400 dark:text-white focus:shadow-none focus:ring-0"
      :class="props.disabled ? 'bg-gray-200 dark:bg-gray-400' : 'bg-gray-50 dark:bg-gray-700'"
      :value="newTag"
      :placeholder="props.placeholder"
      @click="
        () => {
          highlightedTagIndex = props.tags?.length || 0
          showResults = true
        }
      "
      @input="updateNewTag($event)"
      @keyup.enter.prevent="addRoleTag"
      @keyup.right.prevent="highlightNextTag()"
      @keyup.left.prevent="highlightPreviousTag()"
      @keyup.backspace.prevent="adjustTagByBackspace($event)"
      :disabled="props.disabled"
    />
    <!-- search results -->
    <div class="relative w-full">
      <div
        v-if="showResults && items.length"
        class="-ml-2 absolute z-10 list-none bg-white rounded-lg shadow w-full dark:bg-gray-700 max-h-36 overflow-auto border border-gray-100 dark:border-gray-600"
      >
        <ul class="divide-y divide-gray-100 dark:divide-gray-600">
          <li
            v-for="(item, itemIndex) of items"
            class="cursor-pointer block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white transition ease-in-out duration-150"
            :key="itemIndex"
            v-html="highlightText(item.label, newTag)"
            @mouseover="highlightedResultIndex = itemIndex"
            @mouseleave="highlightedResultIndex = -1"
            :class="{ 'bg-gray-100 dark:bg-gray-600': highlightedResultIndex === itemIndex }"
            @click="addRoleTag"
          />
        </ul>
      </div>
    </div>
  </OnClickOutside>
</template>
