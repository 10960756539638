<script setup lang="ts">
import { initPopovers } from 'flowbite'
import { onMounted } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import type { Vessel } from '@ankor-io/common/vessel/types'
import { OutlineTrash } from '@ankor-io/icons/outline'
import { SolidInformationCircle, SolidPlus } from '@ankor-io/icons/solid'

import Button from '@/components/Button.vue'
import StandardInput from '@/components/input/StandardInput.vue'

export type ToysAmenitiesFields = 'toys' | 'amenities' | 'tenders' | 'entertainment'
type Props = {
  vessel: Vessel
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:vessel', value: Vessel): void
}>()

const HELP_TEXT =
  'In this section, you can establish a comprehensive list of Toys & Amenities, serving as the default for every pricing option you create. Customize the offerings for each pricing option in the dedicated pricing section.'

onMounted(() => {
  initPopovers()
})

/**
 * Update an item
 * @param index The position of the field array
 * @param field The field of the blueprint
 * @param key The key of the field
 * @param event The update event
 */
const updateItem = (index: number, field: ToysAmenitiesFields, key: 'label' | 'quantity' | null, event: Event) => {
  const value: string = (event.target as HTMLInputElement).value
  const vessel = ObjectUtil.deepCopy(props.vessel)
  if (key) {
    vessel.blueprint[field][index][key] = value
  } else {
    vessel.blueprint[field][index] = value
  }

  emit('update:vessel', vessel)
}

/**
 * Remove an item
 * @param index
 * @param field The field of the blueprint
 */
const removeItem = (index: number, field: ToysAmenitiesFields) => {
  const vessel = ObjectUtil.deepCopy(props.vessel)
  vessel.blueprint[field].splice(index, 1)
  emit('update:vessel', vessel)
}

/**
 * Add a new item
 */
const addNewItem = (field: ToysAmenitiesFields) => {
  const vessel = ObjectUtil.deepCopy(props.vessel)
  if (!vessel.blueprint[field]) {
    vessel.blueprint[field] = []
  }

  if (field === 'tenders' || field === 'entertainment') {
    vessel.blueprint[field].push('')
  } else {
    vessel.blueprint[field].push({ label: '' })
  }

  emit('update:vessel', vessel)
}
</script>
<template>
  <div class="flex flex-col text-primary gap-y-5">
    <div class="flex gap-x-2.5 items-end">
      <h3 class="text-2xl font-semibold leading-none text-black dark:text-white">Toys & Amenities</h3>
      <button type="button" data-popover-placement="right" data-popover-target="seasons-popover">
        <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
      </button>
      <div
        data-popover
        role="tooltip"
        id="seasons-popover"
        class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
      >
        <div class="max-w-[25rem] p-2">{{ HELP_TEXT }}</div>
        <div data-popper-arrow></div>
      </div>
    </div>

    <!-- Toys -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Toys</h5>
      <div class="flex">
        <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Toy name</p>
        <p class="w-28 block text-sm font-medium text-gray-900 dark:text-white">Quantity</p>
      </div>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="(toy, index) in props.vessel.blueprint.toys"
          class="flex gap-x-4 items-center"
          :key="`${index}-${toy.label}-${toy.quantity}`"
        >
          <StandardInput
            type="search"
            class="w-full"
            :value="toy.label"
            :id="`vessel-toy-${index}-${toy.label}-${toy.quantity}`"
            @update:input="updateItem(index, 'toys', 'label', $event)"
          />
          <StandardInput
            class="w-20"
            type="number"
            :step="1"
            :value="Number(toy.quantity)"
            :id="`vessel-toy-qty-${index}-${toy.label}-${toy.quantity}`"
            @update:input="updateItem(index, 'toys', 'quantity', $event)"
          />
          <OutlineTrash
            class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
            @click.stop="removeItem(index, 'toys')"
          />
        </div>
        <Button
          class="w-min"
          position="prefix"
          :is-primary="false"
          :has-border="true"
          :name="`Add ${props.vessel.blueprint.toys?.length ? 'Another' : ''} Toy`"
          @click.stop="addNewItem('toys')"
        >
          <SolidPlus class="w-4 h-4" />
        </Button>
      </div>
    </div>

    <!-- Amenities -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Amenities</h5>
      <div class="flex">
        <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Amenity name</p>
        <p class="w-28 block text-sm font-medium text-gray-900 dark:text-white">Quantity</p>
      </div>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="(amenity, index) in props.vessel.blueprint.amenities"
          class="flex gap-x-4 items-center"
          :key="`${index}-${amenity.label}-${amenity.quantity}`"
        >
          <StandardInput
            type="search"
            class="w-full"
            :value="amenity.label"
            :id="`vessel-amenity-${index}-${amenity.label}-${amenity.quantity}`"
            @update:input="updateItem(index, 'amenities', 'label', $event)"
          />
          <StandardInput
            class="w-20"
            type="number"
            :step="1"
            :value="Number(amenity.quantity)"
            :id="`vessel-amenity-qty-${index}-${amenity.label}-${amenity.quantity}`"
            @update:input="updateItem(index, 'amenities', 'quantity', $event)"
          />
          <OutlineTrash
            class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
            @click.stop="removeItem(index, 'amenities')"
          />
        </div>
        <Button
          class="w-min"
          position="prefix"
          :is-primary="false"
          :has-border="true"
          :name="`Add ${props.vessel.blueprint.amenities?.length ? 'Another' : ''} Amenity`"
          @click.stop="addNewItem('amenities')"
        >
          <SolidPlus class="w-4 h-4" />
        </Button>
      </div>
    </div>

    <!-- Tenders -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Tenders</h5>
      <div class="flex">
        <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Tender name</p>
      </div>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="(tender, index) in props.vessel.blueprint.tenders"
          class="flex gap-x-4 items-center"
          :key="`${index}-${tender}`"
        >
          <StandardInput
            type="search"
            class="w-full"
            :value="tender"
            :id="`vessel-tender-${index}-${tender}`"
            @update:input="updateItem(index, 'tenders', null, $event)"
          />
          <OutlineTrash
            class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
            @click.stop="removeItem(index, 'tenders')"
          />
        </div>
        <Button
          class="w-min"
          position="prefix"
          :is-primary="false"
          :has-border="true"
          :name="`Add ${props.vessel.blueprint.tenders?.length ? 'Another' : ''} Tender`"
          @click.stop="addNewItem('tenders')"
        >
          <SolidPlus class="w-4 h-4" />
        </Button>
      </div>
    </div>

    <!-- Entertainment -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Entertainment</h5>
      <div class="flex">
        <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Entertainment name</p>
      </div>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="(entertainment, index) in props.vessel.blueprint.entertainment"
          class="flex gap-x-4 items-center"
          :key="`${index}-${entertainment}`"
        >
          <StandardInput
            type="search"
            class="w-full"
            :value="entertainment"
            :id="`vessel-entertainment-${index}-${entertainment}`"
            @update:input="updateItem(index, 'entertainment', null, $event)"
          />
          <OutlineTrash
            class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
            @click.stop="removeItem(index, 'entertainment')"
          />
        </div>
        <Button
          class="w-min"
          position="prefix"
          :is-primary="false"
          :has-border="true"
          :name="`Add ${props.vessel.blueprint.entertainment?.length ? 'Another' : ''} Entertainment`"
          @click.stop="addNewItem('entertainment')"
        >
          <SolidPlus class="w-4 h-4" />
        </Button>
      </div>
    </div>
  </div>
</template>
