<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { initPopovers } from 'flowbite'
import { Ref, onMounted, ref } from 'vue'

type Props = {
  id: string
  placement: 'top' | 'bottom' | 'left' | 'right'
}

const props = defineProps<Props>()

const showPopover: Ref<boolean> = ref(false)

onMounted(() => {
  initPopovers()
})
</script>
<template>
  <OnClickOutside @trigger="showPopover = false">
    <button
      type="button"
      data-popover-trigger="click"
      class="absolute cursor-pointer bottom-0 w-3/4 left-1/2 -translate-x-1/2 text-center rounded-lg transition-all duration-150 md:hover:bg-gray-200 md:hover:dark:bg-gray-600"
      :data-popover-target="props.id"
      :data-popover-placement="props.placement"
      @click="showPopover = !showPopover"
    >
      <slot name="buttonText"></slot>
    </button>

    <div
      v-show="showPopover"
      data-popover
      role="tooltip"
      class="absolute z-10 inline-block w-48 text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
      :id="props.id"
    >
      <slot name="popoverContent"></slot>
    </div>
  </OnClickOutside>
</template>
