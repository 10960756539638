<script setup lang="ts">
import { MenuItem } from '@headlessui/vue'
import { useWindowSize } from '@vueuse/core'
import { SearchClient, SearchIndex } from 'algoliasearch/lite'
import { Ref, computed, inject, onMounted, ref } from 'vue'

import { VesselIndexItem } from '@ankor-io/algolia-conflux/src/mapper.vessel'
import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import TwoColumnWithHeader from '@ankor-io/blocks/layouts/TwoColumnWithHeader.vue'
import { useAppDispatcher } from '@ankor-io/common/lang/events'
import { CalendarEvent } from '@ankor-io/common/vessel/types'
import { OutlineCalendar, OutlineDocumentText, OutlineNewspaper } from '@ankor-io/icons/outline'
import { SolidCog, SolidContracts, SolidPlus, SolidRadar, SolidShip, SolidTrips } from '@ankor-io/icons/solid'

import EventModal, { VesselLabelUri } from '@/components/modal-content/event-modal-content/EventModal.vue'

import { DispatcherEventEnum } from './utils/dispatcher-events'

const authenticationContext: any = inject('authenticationContext')!
const searchClient: SearchClient = inject('searchClient') as SearchClient
const claimedVessels: Ref<VesselLabelUri[]> = ref([])
const commodoreIndex: SearchIndex = searchClient.initIndex('commodore')
const dispatcher = useAppDispatcher().get()

const bannerOpen: Ref<boolean> = ref(false)
const { width: windowWidth } = useWindowSize()
const MOBILE_BREAKPOINT = 768
const isMobile = computed(() => windowWidth.value <= MOBILE_BREAKPOINT)
// Modal helpers
const addEventModalHelper = modalHelper()

onMounted(async () => {
  await getListOfVessels()
})

const getListOfVessels = async () => {
  const claimedVesselNameIds = await commodoreIndex
    .search('')
    .then(({ hits }) =>
      (hits as VesselIndexItem[]).map((hit) => {
        return { label: `${hit.line_1} - ${hit.line_2}m`, uri: hit.uri }
      }),
    )
    .catch((err) => {
      console.error(err)
      return []
    })
  claimedVessels.value = claimedVesselNameIds.length > 0 ? claimedVesselNameIds : []
}

const [env, tld, _appName, branchName] = location.hostname.split('.').reverse()
const apps = [
  {
    name: 'Trips',
    icon: SolidTrips,
    appName: 'app',
    // onClick: () => mixPanel?.track(SELECT_ANKOR_APP, { app: 'Trips' }),
  },
  {
    name: 'Yacht Listings',
    icon: SolidRadar,
    appName: 'radar',
    // onClick: () => mixPanel.track(SELECT_ANKOR_APP, { app: 'Radar' }),
  },
  {
    name: 'Contracts',
    icon: SolidContracts,
    appName: 'contracts',
    // onClick: () => mixPanel.track(SELECT_ANKOR_APP, { app: 'Contracts' }),
  },
].map((app) => ({
  ...app,
  href: `https://${[branchName, app.appName, tld, env].filter((part) => !!part).join('.')}`,
}))

const navBottom = [
  {
    name: 'Settings',
    href: '/settings',
  },
]

/**
 * Add calendar event to vessel
 * @param event event to add to vessel
 */
const addEvent = async (vesselUri: string, event: CalendarEvent) => {
  const response = await fetch(`/api/vessel/calendar/events/${decodeURIComponent(vesselUri)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify(event),
  })

  if (response.status === 200) {
    // TODO: make an app level state to maintain claimed vessels and their calendar events.
    // In the meantime, dispatch events to let other listening components know that a new event has been added (ie: calendar and timeline)
    dispatcher.dispatchEvent(DispatcherEventEnum.CALENDAR_EVENT_CREATED, vesselUri)
  }

  addEventModalHelper.hide()
}
</script>
<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false"></Banner>
    <TwoColumnWithHeader
      :navigation="[
        {
          name: 'Yachts',
          href: '/',
          icon: SolidShip,
          active: $route.path === '/',
        },
        {
          name: 'Noticeboard',
          icon: OutlineNewspaper,
          active:
            $route.path === '/feed/specials' ||
            $route.path === '/feed/charter-requests' ||
            $route.path === '/feed/my-posts' ||
            $route.path === '/feed/company-posts',
          subLinks: [
            {
              name: 'Specials',
              href: '/feed/specials',
              active: $route.path === '/feed/specials',
            },
            {
              name: 'Charter Requests',
              href: '/feed/charter-requests',
              active: $route.path === '/feed/charter-requests',
            },
            {
              name: 'My Posts',
              href: '/feed/my-posts',
              active: $route.path === '/feed/my-posts',
            },
            {
              name: 'My Company Posts',
              href: '/feed/company-posts',
              active: $route.path === '/feed/company-posts',
            },
          ],
        },
      ]"
      :navBottom="navBottom"
      :apps="apps"
      :class-names="
        bannerOpen
          ? {
              sidebar: 'lg:top-[2.75rem]',
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #appGroupTitle>
        <div class="text-xs/6 ml-2 font-semibold text-gray-900 dark:text-gray-50">Apps</div>
      </template>
      <template #header>
        <div class="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <template v-if="$route.name === 'yachts'">
              <!-- Claim vessel -->
              <div class="flex gap-x-2">
                <RouterLink
                  type="button"
                  class="flex transition-colors items-center font-medium rounded-lg text-sm px-5 py-2.5 text-center border border-gray-200 dark:border-gray-600 focus:ring-4 focus:outline-none text-gray-900 dark:text-white bg-white dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 focus:bg-gray-300 dark:focus:bg-gray-700"
                  :to="{ name: 'claim-vessel', params: { step: 'find' } }"
                >
                  <SolidPlus class="w-4 h-4" />
                  <span v-if="!isMobile">Claim a Yacht</span>
                </RouterLink>
                <button
                  class="inline-flex w-fit sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border text-sm px-5 py-2.5 text-center items-center"
                  :disabled="claimedVessels.length === 0"
                  :class="
                    claimedVessels.length === 0
                      ? 'pointer-events-none border-spacing-0 text-gray-200 dark:text-gray-600 border-gray-200 dark:border-gray-600 dark:bg-gray-800'
                      : 'border-primary-600 dark:border-primary-500 hover:border-primary-800 dark:hover:border-primary-800 text-primary-600 dark:text-primary-500 hover:text-white dark:hover:text-white focus:text-white dark:focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300'
                  "
                  @click="addEventModalHelper.show({})"
                >
                  <OutlineCalendar class="w-5 mr-1" />
                  Add Event
                </button>
              </div>

              <!-- Add event -->
              <div
                v-show="claimedVessels.length === 0"
                data-popover
                id="add-event"
                role="tooltip"
                class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
              >
                <div class="px-3 py-2">
                  <p>Please claim at least one yacht before adding an event.</p>
                </div>
                <div data-popper-arrow></div>
              </div>
            </template>

            <!-- Profile dropdown -->
            <AccountMenu :name="authenticationContext.getUser()?.given_name" @logout="$authenticationContext.logout()">
              <template #contextualLinks>
                <MenuItem v-slot="{ active }" class="border-b border-b-gray-200 dark:border-b-gray-600">
                  <li
                    class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                    :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                  >
                    <OutlineDocumentText
                      class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                    />
                    <a href="https://ankor.io/terms-conditions" target="_blank"> Terms &amp; Conditions </a>
                  </li>
                </MenuItem>
              </template>
            </AccountMenu>
          </div>
        </div>
      </template>
      <template #default>
        <RouterView name="content" />
      </template>
      <template #navBottomItem>
        <RouterLink :to="{ name: 'settings' }">
          <span
            :class="[
              $route.name === 'settings'
                ? 'bg-gray-100 dark:bg-gray-700'
                : 'transition-colors hover:bg-gray-100 hover:dark:bg-gray-700 hover:text-gray-800 dark:hover:text-gray-200',
              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 dark:text-gray-300',
            ]"
          >
            <SolidCog
              aria-hidden="true"
              :class="[
                $route.name === 'settings'
                  ? 'text-gray-900 dark:text-white'
                  : 'text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200',
                'transition-colors size-6 shrink-0',
              ]"
            />
            Settings
          </span>
        </RouterLink>
      </template>
    </TwoColumnWithHeader>

    <!-- Add event modal -->
    <Teleport to="body">
      <EventModal
        v-if="addEventModalHelper.isVisible()"
        :vessel-label-values="claimedVessels"
        @add:event="addEvent"
        @close:modal="addEventModalHelper.hide()"
      />
    </Teleport>
  </div>
</template>
