/**
 * Format the date to time ago up to the week
 *
 * @param date The date to format
 * @returns a time ago string representation of the date
 */
export const timeAgo = (date: Date): string => {
  const now = new Date()
  const diff = now.getTime() - date.getTime()
  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  if (weeks > 0) {
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`
  } else if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
  } else {
    return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`
  }
}

/**
 * Generate an "inverse" timestamp. Useful for storing items in R2 in reverse order.
 *
 * Algorithm:
 *   - what time is it ~now in epoch seconds? 1708050963
 *   - we can basically replace every digit there with a 9.  which is ... 9999999999
 *   - then we just subtract, 9999999999 - 1708050963 = 8291949036
 *   - as time goes up, the computed/result number goes down.
 *   - then when it is lexigraphically sorted, it would be descending.
 *
 * @param dateTime a string parseable by 'new Date()'; YYYY-MM-DDTHH:mm:ss.sssZ, default now
 * @returns the "inverse" of the timestamp
 */
export const inverseTimestamp = (dateTime?: string): number => {
  let epochSec
  if (dateTime) {
    const date = new Date(dateTime)
    epochSec = Math.floor(date.getTime() / 1000)
  } else {
    epochSec = Math.floor(Date.now() / 1000)
  }

  //
  return inverseEpoch(epochSec)
}

/**
 * Generate an inverse timestamp using epoch seconds.
 *
 * @param epochSec The seconds since the epoch
 * @returns the inverse timestemp
 */
export const inverseEpoch = (epochSec: number): number => {
  return 9999999999 - epochSec
}
