<script setup lang="ts">
import { initPopovers } from 'flowbite'
import { Ref, defineComponent, onMounted, ref, watch } from 'vue'
import draggable from 'vuedraggable'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import type { CrewMember } from '@ankor-io/common/vessel/types'
import { OutlinePlus } from '@ankor-io/icons/outline'
import { SolidDrag, SolidInformationCircle } from '@ankor-io/icons/solid'

import ButtonVue from '@/components/Button.vue'

import CrewVue from './Crew.vue'

type CrewProps = {
  uri: string
  crew: CrewMember[]
}

defineComponent(draggable)

const props = defineProps<CrewProps>()
const emit = defineEmits<{
  (e: 'update:crew', value: CrewMember[]): void
}>()

const HELP_TEXT =
  'This section is where you add the crew details aboard the yacht. Adding images and experience to the descriptions go along way when retail agents are considering your yacht over others. '
const isDragging: Ref<boolean> = ref(false)
const hoveredCrewIndex: Ref<number | null> = ref(null)
/** To prevent props mutation on dragging */
const crewMembers: Ref<CrewMember[]> = ref([])

onMounted(() => {
  initPopovers()
  setCrewMembers()
})

watch(
  () => props.crew,
  (newValue, oldValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      setCrewMembers()
    }
  },
)

/**
 * Set the crew members object to avoid props mutation
 */
const setCrewMembers = () => {
  crewMembers.value = ObjectUtil.deepCopy(props.crew)
}

/**
 * Add a new crew member
 */
const addCrew = (): void => {
  crewMembers.value.push({ name: '', role: 'Captain' })
  emit('update:crew', crewMembers.value)
}

/**
 * Delete a crew member
 * @param index Index of crew member array
 */
const deleteCrew = (index: number): void => {
  crewMembers.value.splice(index, 1)
  emit('update:crew', crewMembers.value)
}

/**
 * Update crew member
 * @param index Index of crew member array
 * @param key Field from a Crew type
 * @param event Event from the html element
 */
const updateCrew = (index: number, event: CrewMember): void => {
  crewMembers.value[index] = event
  emit('update:crew', crewMembers.value)
}

/**
 * Add crew member avatar
 * @param index - index of crew member
 * @param path
 */
const addCrewAvatar = (index: number, path: string): void => {
  crewMembers.value[index].avatar = path
  emit('update:crew', crewMembers.value)
}

/**
 * Delete crew member avatar
 * @param index - index of crew member
 */
const deleteCrewAvatar = (index: number): void => {
  delete crewMembers.value[index].avatar
  emit('update:crew', crewMembers.value)
}

/**
 * Update the order of crew by dragging and only update it if a change has occurred
 */
const dragEnd = () => {
  if (JSON.stringify(crewMembers.value) !== JSON.stringify(props.crew)) {
    emit('update:crew', crewMembers.value)
  }
}
</script>
<template>
  <div class="flex flex-col text-primary w-full gap-y-8">
    <div class="flex gap-x-2.5 items-end">
      <h3 class="text-2xl font-semibold leading-none text-black dark:text-white">Crew</h3>
      <button type="button" data-popover-placement="right" data-popover-target="seasons-popover">
        <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
      </button>
      <div
        data-popover
        role="tooltip"
        id="seasons-popover"
        class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
      >
        <div class="max-w-[25rem] p-2">{{ HELP_TEXT }}</div>
        <div data-popper-arrow></div>
      </div>
    </div>

    <draggable
      v-if="crewMembers.length"
      tag="ul"
      item-key="path"
      draggable=".crew-draggable"
      class="flex flex-col gap-y-4"
      :list="crewMembers"
      :disabled="false"
      @dragend="dragEnd()"
      @start="isDragging = true"
      @end="isDragging = false"
    >
      <template #item="{ element, index }">
        <li
          class="relative crew-draggable cursor-grab"
          @mouseenter="hoveredCrewIndex = index"
          @mouseleave="hoveredCrewIndex = null"
        >
          <!-- drag icon -->
          <SolidDrag
            v-if="hoveredCrewIndex === index && !isDragging"
            class="absolute h-8 -left-8 top-[calc(50%-1rem)] fill-gray-500"
          />

          <CrewVue
            :crew="element"
            :uri="props.uri"
            :crew-index="index"
            :key="`crew-container-${index}`"
            @delete:crew="deleteCrew(index)"
            @delete:avatar="deleteCrewAvatar(index)"
            @update:crew="updateCrew(index, $event)"
            @update:avatar="addCrewAvatar(index, $event)"
          />
        </li>
      </template>
    </draggable>

    <!-- Add another crew member button -->
    <ButtonVue
      class="w-min"
      id="add-crew-member"
      position="prefix"
      :hasBorder="true"
      :isPrimary="false"
      :name="`Add ${props.crew.length ? 'Another' : ''} Crew Member`"
      @click="addCrew()"
    >
      <OutlinePlus class="w-4 h-4" />
    </ButtonVue>
  </div>
</template>
