/**
 * Get initials from givenName and familyName
 * @param givenName string
 * @param familyName string
 * @returns string
 */
export const getInitials = (givenName: string | null, familyName: string | null): string => {
  if (givenName && familyName) {
    return `${givenName.trim().charAt(0)}${familyName.trim().charAt(0)}`.toUpperCase()
  }

  // when family name is missing, use the first letter of the given name
  if (givenName) {
    return `${givenName.trim().charAt(0)}`.toUpperCase()
  }

  // when given name is missing, use the first letter of the family name
  if (familyName) {
    return `${familyName.trim().charAt(0)}`.toUpperCase()
  }

  return `??`
}

/**
 * Get initials from a given name
 * @param name string
 * @returns string
 */
export const getInitialsFromName = (name: string): string => {
  if (!name) {
    return '??'
  }

  const firstName = name.trim().split(' ').slice(0, -1)
  const familyName = name.trim().split(' ').slice(-1)

  if (firstName && familyName) {
    return `${firstName.join(' ').trim().charAt(0)}${familyName.join(' ').trim().charAt(0)}`.toUpperCase()
  }

  // when family name is missing, use the first letter of the given name
  if (firstName) {
    return `${firstName.join(' ').trim().charAt(0)}`.toUpperCase()
  }

  return '??'
}
