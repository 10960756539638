<script setup lang="ts">
import { initPopovers } from 'flowbite'
import { onMounted } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { Person } from '@ankor-io/common/vessel/types'
import { OutlinePlus } from '@ankor-io/icons/outline'
import { SolidInformationCircle } from '@ankor-io/icons/solid'

import ButtonVue from '@/components/Button.vue'

import ContactInfoVue from './ContactInfo.vue'

type Props = {
  contacts: Person[]
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:contacts', contacts: Person[]): void
}>()

const HELP_TEXT =
  'This section is designed for adding information that you want retail agents to use when making inquiries or bookings for this yacht.'

onMounted(() => initPopovers())

/**
 * Add contact entry to the list of contacts
 */
const addContactEntry = () => {
  const contact: Person = { name: '', companyName: '', email: '', country: '', phoneNumbers: [''], mobileNumber: '' }
  emit('update:contacts', [...props.contacts, contact])
}

/**
 * Delete contact entry from the list of contacts
 * @param index
 */
const deleteContact = (index: number) => {
  const contacts: Person[] = ObjectUtil.deepCopy(props.contacts)
  contacts.splice(index, 1)
  emit('update:contacts', contacts)
}

/**
 * Update contact
 * @param index
 * @param event
 */
const updateContact = (index: number, event: Person) => {
  const contacts: Person[] = ObjectUtil.deepCopy(props.contacts)
  contacts[index] = event
  emit('update:contacts', contacts)
}
</script>
<template>
  <div class="flex flex-col text-primary w-full gap-y-8">
    <div class="flex gap-x-2.5 items-end">
      <h3 class="text-2xl font-semibold leading-none text-black dark:text-white">Contact</h3>
      <button type="button" data-popover-placement="right" data-popover-target="seasons-popover">
        <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
      </button>
      <div
        data-popover
        role="tooltip"
        id="seasons-popover"
        class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
      >
        <div class="max-w-[25rem] p-2">{{ HELP_TEXT }}</div>
        <div data-popper-arrow></div>
      </div>
    </div>

    <div v-if="props.contacts.length" class="flex flex-col gap-y-4">
      <ContactInfoVue
        v-for="(contact, contactIndex) of props.contacts"
        :contact="contact"
        :contact-index="contactIndex"
        :key="`contact-${contactIndex}`"
        @delete:contact="deleteContact(contactIndex)"
        @update:contact="updateContact(contactIndex, $event)"
      >
      </ContactInfoVue>
    </div>

    <!-- Add another contact button -->
    <ButtonVue
      class="h-fit w-min"
      id="add-contact"
      position="prefix"
      :hasBorder="true"
      :isPrimary="false"
      :name="`Add ${props.contacts.length ? 'Another' : ''} Contact`"
      @click.stop="addContactEntry"
    >
      <OutlinePlus class="w-4 h-4" />
    </ButtonVue>
  </div>
</template>
