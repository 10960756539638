<script setup lang="ts">
import { Ref, ref } from 'vue'

import { isEmailValid } from '@ankor-io/common/input-validation/validation'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { Person } from '@ankor-io/common/vessel/types'
import { OutlinePencil, OutlineTrash } from '@ankor-io/icons/outline'

import StandardInput from '@/components/input/StandardInput.vue'

type Fields = 'name' | 'companyName' | 'website' | 'email' | 'country' | 'mobileNumber'

type Props = {
  contact: Person
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'delete:contact'): void
  (e: 'update:contact', contact: Person): void
}>()

const emailValid: Ref<boolean> = ref(true)
const isContactInfoOpen: Ref<boolean> = ref(false)

/**
 * Update contact field
 * @param field A dynamic Person field
 * @param event The input event value
 */
const updateContactField = (field: Fields, event: Event) => {
  const value = (event.target as HTMLInputElement).value
  emailValid.value = true
  // Input validation
  if (field === 'email' && value && !isEmailValid(value)) {
    emailValid.value = false
    return
  }

  const contact: Person = ObjectUtil.deepCopy(props.contact)
  contact[field] = value
  emit('update:contact', contact)
}

const updateContactPhone = (event: Event) => {
  const value = (event.target as HTMLInputElement).value
  const contact: Person = ObjectUtil.deepCopy(props.contact)
  contact.phoneNumbers![0] = value
  emit('update:contact', contact)
}
</script>
<template>
  <div class="flex flex-col gap-y-4 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
    <!-- CA Contact Info -->
    <div v-if="!isContactInfoOpen" class="flex items-center justify-between">
      <div
        v-if="
          props.contact.name ||
          props.contact.phoneNumbers?.[0] ||
          props.contact.email ||
          props.contact.country ||
          props.contact.companyName ||
          props.contact.website
        "
        class="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-2 text-sm text-gray-900 dark:text-white"
      >
        <div v-if="props.contact.name" class="flex flex-wrap gap-1">
          <span class="font-semibold">Contact full name:</span>
          {{ props.contact.name }}
        </div>
        <div v-if="props.contact.country" class="flex flex-wrap gap-1">
          <span class="font-semibold">Country:</span>
          {{ props.contact.country }}
        </div>
        <div v-if="props.contact.companyName" class="flex flex-wrap gap-1">
          <span class="font-semibold">Company name:</span>
          {{ props.contact.companyName }}
        </div>
        <div v-if="props.contact.phoneNumbers?.[0]" class="flex flex-wrap gap-1">
          <span class="font-semibold">Phone number:</span>
          {{ props.contact.phoneNumbers?.[0] }}
        </div>
        <div v-if="props.contact.mobileNumber" class="flex flex-wrap gap-1">
          <span class="font-semibold">Mobile:</span>
          {{ props.contact.mobileNumber }}
        </div>
        <div v-if="props.contact.email" class="flex flex-wrap gap-1">
          <span class="font-semibold">Email:</span>
          {{ props.contact.email }}
        </div>
        <div v-if="props.contact.website" class="flex flex-wrap gap-1">
          <span class="font-semibold">Website:</span>
          {{ props.contact.website }}
        </div>
      </div>
      <span v-else class="text-sm text-gray-400">Enter contact details</span>

      <div class="flex gap-x-4">
        <OutlinePencil
          id="edit-contact"
          class="w-4 h-4 shrink-0 cursor-pointer transition-all text-gray-900 dark:text-white hover:text-gray-500 hover:dark:text-gray-300"
          @click="isContactInfoOpen = true"
        />
        <OutlineTrash
          id="delete-contact"
          class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
          @click="emit('delete:contact')"
        />
      </div>
    </div>

    <template v-else>
      <div class="flex justify-between items-center">
        <h4 class="text-xl font-semibold text-gray-900 dark:text-white">Contact information</h4>
        <!-- Finish button -->
        <a
          type="button"
          class="flex h-min self-end w-min cursor-pointer items-center py-2 px-3 text-xs font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 focus:dark:ring-gray-500 dark:hover:text-white dark:hover:bg-gray-700"
          @click.once="isContactInfoOpen = false"
        >
          Finish
        </a>
      </div>
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        <StandardInput
          type="text"
          class="w-full"
          label="Contact Name"
          placeholder="Add contact name"
          :value="props.contact.name"
          :id="`vessel-contact-name-${props.contact.name}`"
          @update:input="updateContactField('name', $event)"
        />
        <StandardInput
          type="text"
          class="w-full"
          label="Country"
          placeholder="Add country"
          :value="props.contact.country"
          :id="`vessel-contact-country-${props.contact.country}`"
          @update:input="updateContactField('country', $event)"
        />
        <StandardInput
          type="text"
          class="w-full"
          label="Company Name"
          placeholder="Add company name"
          :value="props.contact.companyName"
          :id="`vessel-contact-company-${props.contact.companyName}`"
          @update:input="updateContactField('companyName', $event)"
        />
        <StandardInput
          type="tel"
          class="w-full"
          label="Phone Number"
          placeholder="Add phone number"
          :value="props.contact.phoneNumbers?.[0]"
          :id="`vessel-contact-phone-${props.contact.phoneNumbers?.[0]}`"
          @update:input="updateContactPhone($event)"
        />
        <StandardInput
          type="tel"
          class="w-full"
          label="Mobile"
          placeholder="Add mobile number"
          :value="props.contact.mobileNumber"
          :id="`vessel-mobile-${props.contact.mobileNumber}`"
          @update:input="updateContactField('mobileNumber', $event)"
        />
        <StandardInput
          type="text"
          class="w-full"
          label="Email"
          placeholder="Add email"
          :is-valid="emailValid"
          :value="props.contact.email"
          :id="`vessel-contact-email-${props.contact.email}`"
          @update:input="updateContactField('email', $event)"
        />
        <StandardInput
          type="text"
          class="w-full"
          label="Website"
          placeholder="Add website"
          :value="props.contact.website"
          :id="`vessel-contact-website-${props.contact.website}`"
          @update:input="updateContactField('website', $event)"
        />
      </div>
    </template>
  </div>
</template>
