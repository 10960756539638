<script setup lang="ts">
import * as GeoTZ from 'browser-geo-tz'
import { DateTime } from 'luxon'
import { Ref, computed, inject, ref, watch } from 'vue'

import DatePickerInterval from '@ankor-io/blocks/components/DatePickerInterval/DatePickerInterval.vue'
import TextEditor from '@ankor-io/blocks/components/TextEditor/TextEditor.vue'
import { toIntervalString } from '@ankor-io/common/date/ISO8601'
import { isEmailValid } from '@ankor-io/common/input-validation/validation'
import type { Place } from '@ankor-io/common/vessel/types'
import { OutlineXMark } from '@ankor-io/icons/outline/index'
import { SolidArrowNarrowRight } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import Dropdown from '@/components/modal-content/Dropdown.vue'
import InputCcEmails from '@/components/modal-content/enquiry-modal/InputCcEmails.vue'
import LocationSearchInput from '@/components/modal-content/enquiry-modal/LocationSearchInput.vue'
import { getListOfTime } from '@/components/modal-content/enquiry-modal/util/event-modal'
import { removeHtmlTagsFromString } from '@/components/modal-content/enquiry-modal/util/stringManipulations'
import { AuthenticationContext } from '@/iam/types'

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

export type EnquiryType = {
  cc: string[]
  message: string
  interval: string
  allDay?: boolean
  embark?: Place
  disembark?: Place
}

export type ContactPersonsType = {
  name?: string
  email?: string
  companyName?: string
}

type PreviewProps = {
  vesselName: string
  vesselContactPersons: ContactPersonsType[]
  companyName?: string
  isLoadingContacts?: boolean
  isLoadingCompany: boolean
  isSendingEnquiry: boolean
}

const props = defineProps<PreviewProps>()

const emit = defineEmits<{
  (e: 'on:close'): void
  (e: 'on:submit', value: EnquiryType): void
}>()

/**
 * Enquire modal states
 */
const enquiryEmbarkation: Ref<{
  date: string
  time: string
  timeZone: string
}> = ref({
  date: '',
  time: '',
  timeZone: 'Etc/UTC',
})

const enquiryDisembarkation: Ref<{
  date: string
  time: string
  timeZone: string
}> = ref({
  date: '',
  time: '',
  timeZone: 'Etc/UTC',
})

const enquiryToSend: Ref<EnquiryType> = ref({
  cc: authenticationContext.getUser().email ? [authenticationContext.getUser().email!] : [],
  message: '',
  interval: '',
})

const isValidating: Ref<boolean> = ref(false)
const isCcValid = computed(() => enquiryToSend.value.cc.every((email) => isEmailValid(email)))

// mandatory checks are that the cc list should not contain any of the email in to list
// otherwise the email will not be sent from sendgrid
const mandatoryChecksPassed = computed(() => {
  return !props.vesselContactPersons?.some((contact) => enquiryToSend.value.cc.includes(contact.email!))
})

const mandatoryFieldsFilled = computed(() => {
  return removeHtmlTagsFromString(enquiryToSend.value.message) || false
})

const startTimeComputed = computed(() => {
  if (enquiryEmbarkation.value.time && !enquiryToSend.value.allDay) {
    return DateTime.fromFormat(enquiryEmbarkation.value.time, 'hh:mm a').toFormat('HH:mm')
  }
  return '00:00'
})

const endTimeComputed = computed(() => {
  if (enquiryDisembarkation.value.time && !enquiryToSend.value.allDay) {
    return DateTime.fromFormat(enquiryDisembarkation.value.time, 'hh:mm a').toFormat('HH:mm')
  }
  return '23:59'
})

const startDateTime = computed(() =>
  DateTime.fromISO(`${enquiryEmbarkation.value.date}T${startTimeComputed.value}`, {
    zone: enquiryEmbarkation.value.timeZone || 'Etc/UTC',
    setZone: true,
  }),
)

const endDateTime = computed(() =>
  DateTime.fromISO(`${enquiryDisembarkation.value.date}T${endTimeComputed.value}`, {
    zone: enquiryDisembarkation.value.timeZone || 'Etc/UTC',
    setZone: true,
  }),
)

const embarkationTimeOffset = computed(() => {
  if (!enquiryEmbarkation.value.date || !enquiryToSend.value.embark) {
    return ''
  }
  const offset = startDateTime.value.toFormat('ZZ')
  return `UTC${offset}`
})

const disembarkationTimeOffset = computed(() => {
  if (!enquiryDisembarkation.value.date || !enquiryToSend.value.disembark) {
    return ''
  }
  const offset = endDateTime.value.toFormat('ZZ')
  return `UTC${offset}`
})

// embark disembark interval
const intervalRef = computed(() => {
  if (!enquiryEmbarkation.value.date || !enquiryDisembarkation.value.date) {
    return ''
  }

  try {
    if (isStartAfterEndDateTime()) {
      return ''
    }

    // Pass '' to toIntervalString so that it doesnt default to etc/UTC
    return toIntervalString(startDateTime.value, endDateTime.value, '')
  } catch (error) {
    return ''
  }
})

/**
 * Enquire modal actions
 */

const generateRecipient = (contactPerson: ContactPersonsType) => {
  if (contactPerson.name && contactPerson.companyName) {
    return `${contactPerson.name} (${contactPerson.companyName})`
  }
  if (contactPerson.name) {
    return contactPerson.name
  }
  return contactPerson.email
}

/**
 * Add an email to cc list
 * @param value The email value to add to cc list
 */
const addToCc = (value: string): void => {
  if (enquiryToSend.value.cc.includes(value)) {
    return
  }

  enquiryToSend.value.cc = [...enquiryToSend.value.cc, value]
}

/**
 * Remove an email from cc list
 * @param index The position in the cc list
 */
const removeFromCc = (index: number): void => {
  enquiryToSend.value.cc.splice(index, 1)
  //
}

const updateEmbarkationDate = (date: string) => {
  enquiryEmbarkation.value.date = date
  if (
    enquiryDisembarkation.value.date === '' ||
    DateTime.fromISO(enquiryEmbarkation.value.date) > DateTime.fromISO(enquiryDisembarkation.value.date)
  ) {
    enquiryDisembarkation.value.date = date
  }
}

const isStartAfterEndDateTime = (): boolean => {
  if (startDateTime.value.startOf('millisecond') > endDateTime.value.startOf('millisecond')) {
    return true
  }
  return false
}

const getLocationTimeZone = async (location?: Place) => {
  const { latitude, longitude } = location?.coordinates || {}
  if (latitude && longitude) {
    const timeZones = await GeoTZ.find(latitude, longitude)
    return timeZones[0]
  }
  return 'Etc/UTC'
}

const onEmbarkationLocationChange = async ($event?: Place) => {
  enquiryToSend.value.embark = $event
  enquiryEmbarkation.value.timeZone = await getLocationTimeZone($event)
}

const onDisembarkationLocationChange = async ($event?: Place) => {
  enquiryToSend.value.disembark = $event
  enquiryDisembarkation.value.timeZone = await getLocationTimeZone($event)
}

const toggleAllDay = (checked: boolean) => {
  enquiryToSend.value.allDay = checked
}

const resetEnquiry = () => {
  enquiryToSend.value = {
    cc: authenticationContext.getUser().email ? [authenticationContext.getUser().email!] : [],
    message: '',
    interval: '',
  }
  enquiryEmbarkation.value = {
    date: '',
    time: '',
    timeZone: 'Etc/UTC',
  }
  enquiryDisembarkation.value = {
    date: '',
    time: '',
    timeZone: 'Etc/UTC',
  }
}

const onClose = () => {
  resetEnquiry()
  emit('on:close')
}

const onSubmit = () => {
  if (!isCcValid.value || !mandatoryFieldsFilled.value || !mandatoryChecksPassed.value) {
    isValidating.value = true
    return
  }

  enquiryToSend.value.interval = intervalRef.value
  emit('on:submit', enquiryToSend.value)
}

// Uncheck 'all day' if embark, disembark, or interval is undefined.
watch([enquiryToSend.value.embark, enquiryToSend.value.disembark, intervalRef], () => {
  if (!enquiryToSend.value.embark || !enquiryToSend.value.disembark || !intervalRef.value) {
    enquiryToSend.value.allDay = false
  }
})
</script>
<template>
  <div
    tabindex="1"
    aria-hidden="true"
    class="fixed z-50 inset-0 backdrop-blur-[0.125rem] flex justify-center items-center"
    @click.stop
  >
    <div
      class="w-full md:max-w-4xl max-h-dvh overflow-auto relative bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded-lg shadow-md text-sm text-gray-900 dark:text-white"
    >
      <div class="grid grid-row-3 divide-y dark:divide-gray-600">
        <!-- header -->
        <div class="p-6 flex gap-x-2 justify-between overflow-y-auto">
          <p class="text-lg text-gray-900 dark:text-white overflow-hidden whitespace-nowrap text-ellipsis">
            Enquiry - {{ props.vesselName }}
          </p>
          <!-- close icon -->
          <div class="p-2">
            <OutlineXMark
              class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400"
              @click.stop="onClose"
            />
          </div>
        </div>

        <!-- body -->
        <div class="p-6 flex flex-col gap-y-3">
          <div class="flex flex-col divide-y dark:divide-gray-600">
            <div class="flex items-center gap-x-4">
              <p class="py-2">To:</p>
              <div class="flex flex-wrap">
                <div v-if="isLoadingContacts" role="status" class="animate-pulse flex items-center h-5">
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                </div>
                <template v-else>
                  <div
                    v-for="(contact, index) in props.vesselContactPersons?.filter((contact) => contact.email)"
                    :key="index"
                    class="after:content-[',\00a0'] after:last:content-[''] after:text-gray-500 after:dark:text-gray-400"
                  >
                    <span class="text-xs text-gray-500 dark:text-gray-400">
                      {{ generateRecipient(contact) }}
                    </span>
                  </div></template
                >
              </div>
            </div>
            <div class="flex flex-wrap items-center py-2 gap-x-3">
              <span>Cc:</span>
              <InputCcEmails
                class="flex-grow"
                :tags="enquiryToSend.cc"
                @add:tag="addToCc($event)"
                @remove:tag="removeFromCc($event)"
              />
              <span class="basis-full h-0"></span>
              <p v-if="isValidating && !mandatoryChecksPassed" class="w-full text-red-500 dark:text-red-400 pt-1">
                You can't send enquiries to same email in To and Cc
              </p>
              <p v-if="isValidating && !isCcValid" class="text-red-500 dark:text-red-400 pt-1">
                You can't send enquiries to invalid emails
              </p>
            </div>
            <p class="py-2">
              Subject:
              <span class="text-xs text-gray-500 dark:text-gray-400 pl-4"> New Enquiry for {{ vesselName }} </span>
            </p>
          </div>
          <TextEditor
            id="enquiry-message"
            placeholder="Write your enquiry here..."
            :value="enquiryToSend.message"
            :isResizable="false"
            :isInvalid="isValidating && !removeHtmlTagsFromString(enquiryToSend.message)"
            :has-linking="false"
            @update:value="enquiryToSend.message = $event"
          />
          <p
            v-if="isValidating && !removeHtmlTagsFromString(enquiryToSend.message)"
            class="text-red-500 dark:text-red-400"
          >
            You must write a message in the enquiry body before you can submit it.
          </p>

          <div>
            <p class="font-semibold">Optional</p>
            <div class="grid grid-row-3 lg:grid-cols-11">
              <!-- Embarkation -->
              <div class="lg:col-span-5 gap-x-4 mt-2">
                <p class="block text-sm font-medium">Embarkation</p>
                <div class="mt-2 grid grid-flow-col grid-cols-2 gap-2">
                  <!-- Datepicker -->
                  <DatePickerInterval
                    placeholder="Start Date"
                    id="embarkation-date-enquiry"
                    :date="enquiryEmbarkation.date"
                    @update:date="updateEmbarkationDate"
                  />
                  <!-- Select embarkment time -->
                  <div class="grid grid-cols-2">
                    <Dropdown
                      v-if="!enquiryToSend.allDay"
                      placeholder="Time"
                      :options="getListOfTime(30)"
                      :selected="enquiryEmbarkation.time"
                      :disabled="!!enquiryToSend.allDay"
                      @select:item="enquiryEmbarkation.time = $event.value!"
                    />
                    <div class="px-2 my-auto">
                      <p class="text-xs">Timezone</p>
                      <p class="text-gray-500 dark:text-gray-400 text-sm">
                        {{ embarkationTimeOffset ? embarkationTimeOffset : 'Unspecified' }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Location -->
                <LocationSearchInput
                  class="mt-2 w-full"
                  placeholder="Start Location"
                  :place="enquiryToSend.embark"
                  @update:place="onEmbarkationLocationChange"
                />
              </div>

              <!-- Divider arrow -->
              <div class="flex justify-center align-middle mt-3 lg:mt-2">
                <SolidArrowNarrowRight class="rotate-90 lg:rotate-0 w-5 text-gray-900 dark:text-gray-400" />
              </div>

              <!-- Disembarkation -->
              <div class="lg:col-span-5 gap-x-4 gap-y-2 mt-3 lg:mt-2">
                <p class="block text-sm font-medium">Disembarkation</p>
                <div class="mt-2 grid grid-flow-col grid-cols-2 gap-2">
                  <!-- Datepicker -->
                  <DatePickerInterval
                    placeholder="End Date"
                    id="disembarkation-date-enquiry"
                    :date="enquiryDisembarkation.date"
                    @update:date="enquiryDisembarkation.date = $event"
                  />
                  <!-- Select disembarkment time -->
                  <div class="grid grid-cols-2">
                    <Dropdown
                      v-if="!enquiryToSend.allDay"
                      placeholder="Time"
                      :options="getListOfTime(30)"
                      :selected="enquiryDisembarkation.time"
                      :disabled="!!enquiryToSend.allDay"
                      @select:item="enquiryDisembarkation.time = $event.value!"
                    />
                    <div class="px-2 my-auto">
                      <p class="text-xs">Timezone</p>
                      <p class="text-gray-500 dark:text-gray-400 text-sm">
                        {{ disembarkationTimeOffset ? disembarkationTimeOffset : 'Unspecified' }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Location -->
                <LocationSearchInput
                  class="mt-2 w-full"
                  placeholder="End Location"
                  :place="enquiryToSend.disembark"
                  @update:place="onDisembarkationLocationChange"
                />
              </div>
            </div>

            <p v-if="isStartAfterEndDateTime()" class="mt-1 text-sm text-red-500 dark:text-red-400">
              Start time can't be after the end time.
            </p>

            <!-- All day event -->
            <div class="flex items-center mt-3">
              <input
                type="checkbox"
                id="allDay-checkbox-enquiry"
                :checked="enquiryToSend.allDay"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @change="toggleAllDay(($event.target as HTMLInputElement)?.checked)"
                :disabled="!enquiryToSend.embark || !enquiryToSend.disembark || !intervalRef"
              />
              <label
                for="allDay-checkbox"
                class="ml-2 text-xs font-medium"
                :class="
                  !enquiryToSend.embark || !enquiryToSend.disembark || !intervalRef
                    ? 'text-gray-300 dark:text-gray-600'
                    : 'text-gray-500 dark:text-gray-400'
                "
              >
                All day event
              </label>
            </div>
            <p class="mt-5 text-xs text-gray-500 dark:text-gray-400">
              Time Zone determined by embarkation and disembarkation locations.
            </p>
          </div>
          <div class="flex flex-col gap-y-4 mt-2">
            <p class="font-semibold text-base">
              {{ $principalIdentity.given_name }} {{ $principalIdentity.family_name }}
            </p>
            <div>
              <!-- TODO: enable once this data is available -->
              <!-- <p class="text-gray-500 dark:text-gray-400">role goes here</p> -->
              <div v-if="isLoadingCompany" role="status" class="animate-pulse flex items-center h-5">
                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              </div>
              <p v-else-if="props.companyName" class="text-gray-500 dark:text-gray-400">{{ props.companyName }}</p>
              <!-- TODO: enable once this data is available -->
              <!-- <p class="text-gray-500 dark:text-gray-400">phone no goes here</p> -->
              <p v-if="$principalIdentity.email" class="text-gray-500 dark:text-gray-400">
                {{ $principalIdentity.email }}
              </p>
              <!-- TODO: enable once this data is available -->
              <!-- <p class="text-gray-500 dark:text-gray-400">website goes here</p> -->
            </div>
          </div>
        </div>

        <!-- footer -->
        <div class="p-6 flex justify-end gap-4">
          <button
            type="button"
            id="cancel-enquiry-button"
            class="text-gray-500 dark:text-gray-200 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-500 dark:hover:bg-gray-600 focus:outline-none dark:focus:ring-gray-600 border border-gray-200 dark:border-gray-400"
            @click.stop="onClose"
          >
            Cancel
          </button>
          <button
            type="button"
            id="send-enquiry-button"
            class="flex gap-x-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-blue-400 disabled:dark:bg-blue-500"
            @click.stop="onSubmit"
          >
            <p>Send</p>
            <div v-if="props.isSendingEnquiry" class="my-auto">
              <Spinner class="w-4 h-4" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
