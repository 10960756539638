<script setup lang="ts">
import { DateTime } from 'luxon'

import { isToday } from '@ankor-io/common/calendar/calendar'
import { CalendarEvent, Vessel } from '@ankor-io/common/vessel/types'

const props = defineProps<{
  year: number
  month: number
  week: number
  vessel: Vessel
  events: CalendarEvent[]
}>()

const days = [
  { long: 'Mon', short: 'M' },
  { long: 'Tue', short: 'T' },
  { long: 'Wed', short: 'W' },
  { long: 'Thu', short: 'T' },
  { long: 'Fri', short: 'F' },
  { long: 'Sat', short: 'S' },
  { long: 'Sun', short: 'S' },
]

const hours = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM',
]
</script>

<template>
  <div class="flex h-full flex-col isolate flex-auto overflow-auto bg-white max-w-full sm:max-w-none md:max-w-full">
    <div class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
      <div class="grid sm:hidden grid-cols-7 text-sm leading-6 text-gray-500">
        <button
          v-for="(day, index) in days"
          :key="`${day.long}-mobile`"
          type="button"
          class="flex flex-col items-center pb-3 pt-2"
        >
          {{ day.short }}
          <span
            class="mt-1 flex h-8 w-8 items-center justify-center font-semibold"
            :class="
              isToday(
                DateTime.fromObject({
                  weekNumber: props.week,
                  weekYear: props.year,
                }).plus({ day: index }).day,
                DateTime.fromObject({
                  weekNumber: props.week,
                  weekYear: props.year,
                }).plus({ day: index }).month,
                DateTime.fromObject({
                  weekNumber: props.week,
                  weekYear: props.year,
                }).plus({ day: index }).year,
              )
                ? 'rounded-full bg-indigo-600 text-white'
                : 'text-gray-900'
            "
          >
            {{
              DateTime.fromObject({
                weekNumber: props.week,
                weekYear: props.year,
              }).plus({ day: index }).day
            }}
          </span>
        </button>
      </div>

      <div
        class="-mr-px hidden sm:grid grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500"
      >
        <div class="col-end-1 w-14"></div>
        <div v-for="(day, index) in days" :key="`${day.long}-desktop`" class="flex items-center justify-center py-3">
          <div class="flex items-baseline gap-1.5 flex-wrap justify-center">
            {{ day.long }}
            <span
              class="flex items-center justify-center font-semibold h-8"
              :class="
                isToday(
                  DateTime.fromObject({
                    weekNumber: props.week,
                    weekYear: props.year,
                  }).plus({ day: index }).day,
                  DateTime.fromObject({
                    weekNumber: props.week,
                    weekYear: props.year,
                  }).plus({ day: index }).month,
                  DateTime.fromObject({
                    weekNumber: props.week,
                    weekYear: props.year,
                  }).plus({ day: index }).year,
                )
                  ? 'h-8 w-8 rounded-full bg-indigo-600 text-white'
                  : 'text-gray-900'
              "
            >
              {{
                DateTime.fromObject({
                  weekNumber: props.week,
                  weekYear: props.year,
                }).plus({ day: index }).day
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-auto">
      <div class="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100"></div>
      <div class="grid flex-auto grid-cols-1 grid-rows-1">
        <!-- Horizontal lines -->
        <div
          class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
          style="grid-template-rows: repeat(24, minmax(3.5rem, 1fr))"
        >
          <div class="row-end-1 h-7"></div>
          <div
            v-for="hour in hours"
            :key="hour"
            class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
          >
            {{ hour }}
          </div>
        </div>

        <!-- Vertical lines -->
        <div class="hidden sm:grid col-start-1 col-end-2 row-start-1 grid-cols-7 grid-rows-1 divide-x divide-gray-100">
          <div class="col-start-1 row-span-full"></div>
          <div class="col-start-2 row-span-full"></div>
          <div class="col-start-3 row-span-full"></div>
          <div class="col-start-4 row-span-full"></div>
          <div class="col-start-5 row-span-full"></div>
          <div class="col-start-6 row-span-full"></div>
          <div class="col-start-7 row-span-full"></div>
          <div class="col-start-8 row-span-full w-8"></div>
        </div>

        <!-- Sample Events -->
        <ol
          class="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
          style="grid-template-rows: 1.75rem repeat(288, minmax(0, 1fr)) auto"
        >
          <li class="relative mt-px flex sm:col-start-3" style="grid-row: 74 / span 12">
            <a
              href="#"
              class="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100"
            >
              <p class="order-1 font-semibold text-blue-700">Breakfast</p>
              <p class="text-blue-500 group-hover:text-blue-700">
                <time datetime="2022-01-12T06:00">6:00 AM</time>
              </p>
            </a>
          </li>
          <li class="relative mt-px flex sm:col-start-3" style="grid-row: 92 / span 30">
            <a
              href="#"
              class="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-pink-50 p-2 text-xs leading-5 hover:bg-pink-100"
            >
              <p class="order-1 font-semibold text-pink-700">Flight to Paris</p>
              <p class="text-pink-500 group-hover:text-pink-700">
                <time datetime="2022-01-12T07:30">7:30 AM</time>
              </p>
            </a>
          </li>
          <li class="relative mt-px hidden sm:flex sm:col-start-6" style="grid-row: 122 / span 24">
            <a
              href="#"
              class="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-gray-100 p-2 text-xs leading-5 hover:bg-gray-200"
            >
              <p class="order-1 font-semibold text-gray-700">Meeting with design team at Disney</p>
              <p class="text-gray-500 group-hover:text-gray-700">
                <time datetime="2022-01-15T10:00">10:00 AM</time>
              </p>
            </a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
