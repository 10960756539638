import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M7.20002 2.40002C6.88176 2.40002 6.57654 2.52645 6.3515 2.7515C6.12645 2.97654 6.00002 3.28176 6.00002 3.60002V4.80002H4.80002C4.1635 4.80002 3.55306 5.05288 3.10297 5.50297C2.65288 5.95306 2.40002 6.5635 2.40002 7.20002V19.2C2.40002 19.8365 2.65288 20.447 3.10297 20.8971C3.55306 21.3472 4.1635 21.6 4.80002 21.6H19.2C19.8365 21.6 20.447 21.3472 20.8971 20.8971C21.3472 20.447 21.6 19.8365 21.6 19.2V7.20002C21.6 6.5635 21.3472 5.95306 20.8971 5.50297C20.447 5.05288 19.8365 4.80002 19.2 4.80002H18V3.60002C18 3.28176 17.8736 2.97654 17.6486 2.7515C17.4235 2.52645 17.1183 2.40002 16.8 2.40002C16.4818 2.40002 16.1765 2.52645 15.9515 2.7515C15.7265 2.97654 15.6 3.28176 15.6 3.60002V4.80002H8.40002V3.60002C8.40002 3.28176 8.2736 2.97654 8.04855 2.7515C7.82351 2.52645 7.51828 2.40002 7.20002 2.40002V2.40002ZM7.20002 8.40002C6.88176 8.40002 6.57654 8.52645 6.3515 8.7515C6.12645 8.97654 6.00002 9.28176 6.00002 9.60002C6.00002 9.91828 6.12645 10.2235 6.3515 10.4486C6.57654 10.6736 6.88176 10.8 7.20002 10.8H16.8C17.1183 10.8 17.4235 10.6736 17.6486 10.4486C17.8736 10.2235 18 9.91828 18 9.60002C18 9.28176 17.8736 8.97654 17.6486 8.7515C17.4235 8.52645 17.1183 8.40002 16.8 8.40002H7.20002Z",
      "clip-rule": "evenodd"
    })
  ]))
}