<script setup lang="ts">
import DateRangePicker from 'flowbite-datepicker/DateRangePicker'
import { Ref, onMounted, ref, watch } from 'vue'

import { reverseDateFormat } from '@ankor-io/common/date/helper'
import { SolidCalendar } from '@ankor-io/icons/solid'

type Props = {
  startDate: string | null
  endDate: string | null
  showToText: boolean
  startDatePlaceholder?: string
  endDatePlaceholder?: string
  hasError?: boolean
  orientation?: string
}

const props = withDefaults(defineProps<Props>(), {
  startDatePlaceholder: 'Select date start',
  endDatePlaceholder: 'Select date end',
  hasError: false,
  orientation: 'bottom',
})

const datePickerElementRef: Ref<any> = ref(null)
const datePickerRef: Ref<any> = ref(null)
const startDateInputRef: Ref<any> = ref(null)
const endDateInputRef: Ref<any> = ref(null)

const emit = defineEmits<{
  (e: 'update:start:and:end', value: { startDate: string; endDate: string }): void
}>()

onMounted(() => {
  const datepickerEl = datePickerElementRef.value
  if (!datepickerEl) {
    return
  }

  datePickerRef.value = new DateRangePicker(datepickerEl, {
    format: 'dd-mm-yyyy',
    orientation: props.orientation,
    clearBtn: true,
  })
})

/**
 * Update start and end dates
 */
const updateStartAndEndDates = () => {
  const startFromInput = reverseDateFormat((startDateInputRef.value as HTMLInputElement).value, 2)
  const endFromInput = reverseDateFormat((endDateInputRef.value as HTMLInputElement).value, 2)

  // Dont emit the same values
  if (startFromInput === props.startDate && endFromInput === props.endDate) {
    return
  }

  const startDate = startFromInput
  const endDate = endFromInput
  if (!startDate && !endDate) {
    emit('update:start:and:end', { startDate: '', endDate: '' })
  }

  if (!startDate || !endDate) {
    return
  }

  emit('update:start:and:end', { startDate, endDate })
}

/**
 * When both dates are reset to null, need to re-create a new date picker to reset the date picker values
 */
watch(
  () => [props.startDate, props.endDate],
  ([newStartDate, newEndDate]) => {
    // The values should only be null through clearing the filters
    if (newStartDate === null && newEndDate === null) {
      const datepickerEl = datePickerElementRef.value
      if (!datepickerEl) {
        return
      }

      // Need to reset the state of the date picker before reinitialising a new one
      datePickerRef.value = null
      setTimeout(() => {
        datePickerRef.value = new DateRangePicker(datepickerEl, {
          format: 'yyyy-mm-dd',
          orientation: props.orientation,
          allowOneSidedRange: true,
          clearBtn: true,
        })
      }, 0)
    }
  },
)
</script>
<template>
  <div date-rangepicker class="flex items-center justify-between gap-x-2" :ref="(el) => (datePickerElementRef = el)">
    <div class="relative w-full">
      <div class="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
        <SolidCalendar class="w-4 h-4 text-gray-500 dark:text-gray-400" />
      </div>
      <input
        name="start"
        type="text"
        autocomplete="off"
        class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-7 p-2.5 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :class="
          props.hasError
            ? 'border-red-500 dark:border-red-400 bg-red-600 bg-opacity-10'
            : 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700'
        "
        :ref="(el) => (startDateInputRef = el)"
        :placeholder="props.startDatePlaceholder"
        :value="reverseDateFormat(props.startDate, 4)"
        @focusout="updateStartAndEndDates()"
      />
    </div>
    <span v-if="props.showToText" class="text-gray-500 dark:text-gray-400" data-test="to-text">to</span>
    <div class="relative w-full">
      <div class="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
        <SolidCalendar class="w-4 h-4 text-gray-500 dark:text-gray-400" />
      </div>
      <input
        name="end"
        type="text"
        autocomplete="off"
        class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-7 p-2.5 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :class="
          props.hasError
            ? 'border-red-500 dark:border-red-400 bg-red-600 bg-opacity-10'
            : 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700'
        "
        :ref="(el) => (endDateInputRef = el)"
        :placeholder="props.endDatePlaceholder"
        :value="reverseDateFormat(props.endDate, 4)"
        @focusout="updateStartAndEndDates()"
      />
    </div>
  </div>
</template>
<style scoped>
@tailwind components;
@layer components {
  .datepicker-cell {
    @apply leading-9 bg-white dark:bg-gray-700;
  }

  .range-start {
    @apply rounded-tl-lg rounded-bl-none bg-gray-100 hover:bg-gray-200 dark:bg-gray-600 dark:hover:bg-gray-500;

    &.selected {
      @apply bg-primary-600 hover:bg-primary-700;
    }
  }

  .range-end {
    @apply rounded-br-lg rounded-tr-none bg-gray-100 hover:bg-gray-200 dark:bg-gray-600 dark:hover:bg-gray-500;

    &.selected {
      @apply bg-primary-600 hover:bg-primary-700;
    }
  }

  .range {
    @apply bg-gray-100 hover:bg-gray-200 dark:bg-gray-600 dark:hover:bg-gray-500;
  }

  .datepicker-footer {
    .datepicker-controls {
      @apply justify-end;
    }
  }

  .datepicker-dropdown {
    @apply z-50;
  }
}
</style>
