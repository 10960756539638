<script setup lang="ts">
import { Ref, ref } from 'vue'

import { OutlineXMark } from '@ankor-io/icons/outline'

type Props = {
  tags?: string[]
  placeholder?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'add:tag', value: string): void
  (e: 'remove:tag', index: number): void
}>()

const newTag: Ref<string> = ref('')
const highlightedTagIndex: Ref<number> = ref(props.tags?.length || 0)

/**
 * Highlight previous tag
 */
const highlightPreviousTag = () => {
  if (highlightedTagIndex.value > 0) {
    highlightedTagIndex.value--
  }
}

/**
 * Highlight next tag
 */
const highlightNextTag = () => {
  if (!props.tags || highlightedTagIndex.value === props.tags.length) {
    return
  }

  highlightedTagIndex.value++
}

/**
 * On escape, the user can delete a tag
 * @param event KeyboardEvent escape key press
 */
const adjustTagByBackspace = (event: KeyboardEvent) => {
  const target = event.currentTarget as HTMLInputElement
  if (target.value) {
    return
  }

  if (highlightedTagIndex.value <= (props.tags?.length || 0) - 1) {
    removeTag(highlightedTagIndex.value)
    highlightedTagIndex.value = (props.tags?.length || 0) - 1
  } else {
    highlightPreviousTag()
  }
}

/**
 * Updating the newTag var and resetting highlightedTagIndex
 * @param event Event
 */
const updateNewTag = (event: Event): void => {
  const target = event.currentTarget as HTMLInputElement
  newTag.value = target.value

  // Reset highlighting if user started to highlight existing tags
  highlightedTagIndex.value = (props.tags?.length || 0) + 1
}

/**
 * Emit a tag to add
 */
const addTag = (): void => {
  // Don't submit nothing
  if (!newTag.value) {
    return
  }

  emit('add:tag', newTag.value)

  // Reset the tag
  newTag.value = ''
  highlightedTagIndex.value = (props.tags?.length || 0) + 1
}

/**
 * Emit a tag to remove
 * @param index The position in the tags array
 */
const removeTag = (index: number): void | boolean => {
  emit('remove:tag', index)
}
</script>
<template>
  <div
    class="flex items-center gap-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 focus:shadow-none focus:ring-0"
  >
    <span
      v-for="(tag, index) in props.tags"
      class="flex items-center px-1.5 py-0.5 rounded-md font-medium text-xs h-6 text-gray-600 dark:text-white"
      :class="
        highlightedTagIndex === index
          ? 'border border-gray-400 dark:border-primary-400 bg-gray-300 dark:bg-primary-700'
          : 'bg-gray-200 dark:bg-primary-600'
      "
      :key="`${tag}-${index}`"
      :id="`${index}-${tag}-badge-dismiss-default`"
    >
      {{ tag }}
      <button
        type="button"
        class="flex items-center"
        aria-label="Remove"
        :data-dismiss-target="`#${index}-${tag}-badge-dismiss-default`"
        @click.stop="removeTag(index)"
      >
        <OutlineXMark class="w-2 h-2 ml-2" />
        <p class="sr-only">{{ `Remove ${tag}` }}</p>
      </button>
    </span>
    <input
      type="text"
      class="flex-grow bg-gray-50 text-gray-900 border-none text-sm rounded-lg focus:border-none focus:outline-none block py-1 px-1 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white focus:shadow-none focus:ring-0"
      :value="newTag"
      :placeholder="props.placeholder"
      @click="highlightedTagIndex = props.tags?.length || 0"
      @input="updateNewTag($event)"
      @keyup.enter.prevent="addTag()"
      @keyup.right.prevent="highlightNextTag()"
      @keyup.left.prevent="highlightPreviousTag()"
      @keyup.backspace.prevent="adjustTagByBackspace($event)"
      @blur="addTag()"
    />
  </div>
</template>
