<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { useWindowSize } from '@vueuse/core'
import { DateTime, Duration } from 'luxon'
import { Ref, computed, inject, ref, watch } from 'vue'

import PostCardSpecial from '@ankor-io/blocks/components/feed/PostCardSpecial.vue'
import { buildCalendar } from '@ankor-io/common/calendar/calendar'
import {
  eventBackgroundColour,
  eventEdgeColour,
  eventTextColour,
  specialBorderColour,
} from '@ankor-io/common/calendar/event-colours'
import { isSpecialActive, isSpecialExpired } from '@ankor-io/common/calendar/specials'
import {
  Calendar,
  CalendarEventBlock,
  CalendarMixedBlock,
  CalendarSpecialBlock,
  CalendarWeekBlock,
} from '@ankor-io/common/calendar/types'
import { fromIntervalString } from '@ankor-io/common/date/ISO8601'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { CalendarEvent, EventSourceEnums, Person, Vessel } from '@ankor-io/common/vessel/types'
import { HiSolidCube } from '@ankor-io/icons/hi_solid'
import { OutlinePencil, OutlineTrash } from '@ankor-io/icons/outline'
import { OutlineBell, OutlineXMark } from '@ankor-io/icons/outline'
import {
  SolidCalendar,
  SolidFire,
  SolidLocationMarker,
  SolidPaperAirplane,
  SolidShip,
  SolidX,
} from '@ankor-io/icons/solid'

import EnquiryModal, {
  ContactPersonsType,
  EnquiryType,
} from '@/components/modal-content/enquiry-modal/EnquiryModal.vue'
import { ToastEnquiryEnums } from '@/components/modal-content/enquiry-modal/toastTypes'
import ClickPopover from '@/components/popover/ClickPopover.vue'
import { useCompany } from '@/hooks/useCompany'
import { AuthenticationContext } from '@/iam/types'
import { COMMODORE_20240717_CA_SPECIALS_ENQUIRY_MODAL } from '@/utils/growthbook/constants'

const props = defineProps<{
  year: number
  month: number
  vessel: Vessel
  events: CalendarEvent[]
  specials: any[] // hits
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const { company, getCompany } = useCompany()

const emit = defineEmits<{
  (e: 'edit:event', event: CalendarEventBlock): void
  (e: 'delete:event', id: string): void
}>()

// use window width to determine the height of modal
const { width: windowWidth } = useWindowSize()
const MOBILE_BREAKPOINT = 768
const isMobile = computed(() => windowWidth.value <= MOBILE_BREAKPOINT)

const dateTime = DateTime.now()

const modalOpen: Ref<boolean> = ref(false)
const eventOverview: Ref<CalendarEventBlock | null> = ref(null)

const calendar: Ref<Calendar> = ref(buildCalendar(props.year, props.events, props.specials))

const specialsModalContent: Ref<CalendarSpecialBlock | null> = ref(null)

const enquiryData: Ref<{
  specialsUri: string
  vesselUri: string
  vesselName: string
  toContactPerson: ContactPersonsType[]
} | null> = ref(null)
const showEnquireModal: Ref<boolean> = ref(false)
const isLoadingCompany: Ref<boolean> = ref(false)
const isSendingEnquiry: Ref<boolean> = ref(false)
const toastEnquiryMessage: Ref<ToastEnquiryEnums | null> = ref(null)

watch(
  () => [props.events, props.specials, props.year, props.month],
  () => {
    calendar.value.table[props.year] = buildCalendar(props.year, props.events, props.specials).table[props.year]
  },
)

const hasHiddenBlocks = (weekIndex: number, date: string, mobile: boolean): CalendarMixedBlock[] => {
  const PRIORITY_MAX = mobile ? 2 : 3
  const blockWeek = (calendar.value.table?.[props.year][props.month].blocks[weekIndex] as CalendarMixedBlock[]) || []
  return blockWeek?.filter(
    (block) => block.position.spanningDates.includes(date) && block.position.priority > PRIORITY_MAX,
  )
}

const openEventOverview = (event: CalendarEventBlock): void => {
  modalOpen.value = true
  eventOverview.value = event
}

const closeEventOverview = (): void => {
  modalOpen.value = false
  eventOverview.value = null
}

const editEvent = (): void => {
  emit('edit:event', eventOverview.value!)
  modalOpen.value = false
  eventOverview.value = null
}

const deleteEvent = (): void => {
  emit('delete:event', eventOverview.value!.id)
  modalOpen.value = false
  eventOverview.value = null
}

// Below functions are for getting the absolute positioning for the events

/**
 * Returns to the template the top position for an event
 * @param order The order number of an event in a cell
 */
const getEventAbsoluteTop = (order: number): string => {
  if (order === 1) {
    return 'top-6 md:top-8'
  } else if (order === 2) {
    return 'top-11 md:top-14'
  } else if (order === 3) {
    return 'invisible md:visible md:top-[5rem]'
  }

  // Don't show more than n events in a single cell
  return 'invisible'
}

/**
 * Returns a % value for style to the template the left position for an event
 * @param firstDate The first date for an event of a week
 * @param lastDate The last date for an event of a week
 * @param startDate The starting date for an event
 * @param week The week block of a calendar
 */
const getEventAbsoluteX = (firstDate: string, startDate: string, week: CalendarWeekBlock): number => {
  const startCell = week.days.findIndex((day) => day.date === firstDate)
  const isStart = week.days.some((day) => day.date === startDate)

  const baseLeft = 15.3
  const incrementingLeft = 14.3
  const fromBorder = 0.1
  const startPosition = 1.3
  if (isStart) {
    if (startCell === 0) {
      return startPosition
    } else {
      return startCell === 1 ? baseLeft : baseLeft + incrementingLeft * (startCell - 1)
    }
  }

  return fromBorder
}

/**
 * Returns a % value for style to the template the width of an event
 * @param startCell The starting cell for the event
 * @param endCell The ending cell for the event
 * @param numCells The number of numCells this event takes up on this row
 * @param isStart Notation if the event begins on this row
 * @param isEnd Notation if the event ends on this row
 */
const getEventAbsoluteWidth = (
  firstDate: string,
  lastDate: string,
  numCells: number,
  startDate: string,
  endDate: string,
  week: CalendarWeekBlock,
): number => {
  // isStart && !isEnd is handled in the style tag alone as it returns a right value
  const startCell = week.days.findIndex((day) => day.date === firstDate)
  const endCell = week.days.findIndex((day) => day.date === lastDate)
  const isStart = week.days.some((day) => day.date === startDate)
  const isEnd = week.days.some((day) => day.date === endDate)

  const baseWidthType1 = 12.2
  const incrementingWidth = 14.3
  const endWidth = 0.9
  const singleCellTrim = 0.3
  const endToEnd = 99.8

  // Starts and ends in the same row with at least 1 cell apart
  // Cannot have numCells === 7 as this only fires if the event begins from the 2nd day onwards
  if (numCells && isStart && isEnd) {
    // Ends and starts in the same cell
    if (numCells === 1) {
      return baseWidthType1 - singleCellTrim
    } else {
      return baseWidthType1 + incrementingWidth * (numCells - 1)
    }
  }

  // Ends in the first cell or starts in the last cell
  if (endCell === 0 || startCell === 6) {
    return baseWidthType1 + endWidth
  }

  if (numCells === 7) {
    const withEnd = baseWidthType1 + endWidth + incrementingWidth * (numCells - 1)
    if (isEnd && !isStart) {
      return withEnd
    }

    return endToEnd
  }

  return baseWidthType1 + endWidth + incrementingWidth * (numCells - 1)
}

const getEventStartEndDateTime = (eventInterval: string, eventAllDay?: boolean): { start: string; end: string } => {
  const { start, end } = fromIntervalString(eventInterval, { setZone: true })

  return {
    start: `${start!.toFormat('dd-MM-yyyy')}${eventAllDay ? '' : ` ${start!.toFormat('hh:mm a')}`} (${
      start!.zoneName
    })`,
    end: `${end!.toFormat('dd-MM-yyyy')}${eventAllDay ? '' : ` ${end!.toFormat('hh:mm a')}`} (${end!.zoneName})`,
  }
}

const setToastMessage = (message: ToastEnquiryEnums | null) => {
  if (message !== null) {
    setTimeout(() => {
      toastEnquiryMessage.value = null
    }, 15 * 1000 /* 15secs */)
  }
  toastEnquiryMessage.value = message
}

const isCorrespondenceEmailInVesselContacts = (email: string, vesselContacts?: Person[]): boolean => {
  if (vesselContacts?.length) {
    return vesselContacts.some((contact) => contact.email?.toLowerCase() === email.toLowerCase())
  }
  return false
}

const openEnquiryModal = async (
  specialsUri: string,
  vesselUri: string,
  vesselName: string,
  correspondenceEmail: string,
) => {
  if (!specialsUri || !vesselUri || !vesselName || !correspondenceEmail) {
    return
  }

  const toContactPerson = isCorrespondenceEmailInVesselContacts(correspondenceEmail, props.vessel.contacts)
    ? props.vessel.contacts
    : [{ email: correspondenceEmail }, ...(props.vessel.contacts || [])]

  if (!toContactPerson) {
    return
  }

  setToastMessage(null)
  enquiryData.value = { specialsUri, vesselUri, vesselName, toContactPerson }
  showEnquireModal.value = true
  if (company.value === null) {
    isLoadingCompany.value = true
    getCompany((await authenticationContext.getToken())!).then(() => {
      isLoadingCompany.value = false
    })
  }
}

const submitEnquiry = async (enquiry: EnquiryType, vesselUri: string, specialsUri: string) => {
  isSendingEnquiry.value = true

  const resp = await fetch('/api/enquiry/specials', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await authenticationContext.getToken())!}`,
    },
    body: JSON.stringify({ ...enquiry, vesselUri, specialsUri }),
  })
  isSendingEnquiry.value = false
  if (resp.status === 200) {
    setToastMessage(ToastEnquiryEnums.ENQUIRY_SUCCESSFUL)
  } else {
    setToastMessage(ToastEnquiryEnums.ENQUIRY_FAILED)
  }
  showEnquireModal.value = false
}

const closeEnquiryModal = () => {
  showEnquireModal.value = false
}

const generateMediaUri = (): string => {
  if (specialsModalContent.value?.hero && specialsModalContent.value?.vesselUri) {
    return replacePathToMediaUris(specialsModalContent.value.vesselUri, specialsModalContent.value.hero)[0]
  }

  return ''
}
</script>
<template>
  <div
    class="grid lg:flex-none grid-cols-7 gap-px text-center text-xs font-semibold leading-6 bg-white text-gray-900 dark:bg-gray-900 dark:text-white"
  >
    <div class="py-2">M<span class="sr-only sm:not-sr-only">on</span></div>
    <div class="py-2">T<span class="sr-only sm:not-sr-only">ue</span></div>
    <div class="py-2">W<span class="sr-only sm:not-sr-only">ed</span></div>
    <div class="py-2">T<span class="sr-only sm:not-sr-only">hu</span></div>
    <div class="py-2">F<span class="sr-only sm:not-sr-only">ri</span></div>
    <div class="py-2">S<span class="sr-only sm:not-sr-only">at</span></div>
    <div class="py-2">S<span class="sr-only sm:not-sr-only">un</span></div>
  </div>
  <div class="select-none flex text-xs leading-6 relative">
    <!-- Modal -->
    <OnClickOutside v-show="modalOpen" tabindex="-1" aria-hidden="true" @trigger="closeEventOverview">
      <div
        class="p-4 min-w-full md:min-w-[30.125rem] md:max-w-3xl max-h-full overflow-y-auto overflow-x-hidden absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-50 justify-center items-center"
      >
        <!-- Modal content -->
        <div class="relative flex flex-col p-8 bg-white rounded-lg shadow dark:bg-gray-700 gap-y-2">
          <!-- Modal header -->
          <div class="flex items-center justify-between">
            <h3 v-if="eventOverview?.position.startDate" class="font-bold text-base text-gray-900 dark:text-white">
              {{ DateTime.fromFormat(eventOverview?.position.startDate, 'dd-MM-yyyy').toFormat('ccc, d MMM') }}
              <span class="font-normal"> {{ eventOverview?.title }}</span>
            </h3>
            <div class="flex gap-x-2">
              <template v-if="eventOverview?.source === EventSourceEnums.CALENDARS">
                <button
                  type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 p-1 rounded-lg text-sm ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  @click="editEvent"
                >
                  <OutlinePencil class="w-4 h-4" />
                  <span class="sr-only">Edit Event</span>
                </button>
                <button
                  type="button"
                  class="text-red-500 bg-transparent hover:bg-gray-200 hover:text-red-700 p-1 rounded-lg text-sm ms-auto inline-flex justify-center items-center hover:dark:text-red-400 dark:hover:bg-gray-600 dark:hover:text-white"
                  @click="deleteEvent"
                >
                  <OutlineTrash class="w-4 h-4" />
                  <span class="sr-only">Delete Event</span>
                </button>
              </template>
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 p-1 rounded-lg text-sm ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                @click="closeEventOverview"
              >
                <SolidX class="w-4 h-4" />
                <span class="sr-only">Close modal</span>
              </button>
            </div>
          </div>
          <!-- Modal body -->
          <div class="grid grid-cols-2 gap-x-4 text-sm">
            <div class="flex flex-col gap-y-2 leading-relaxed text-gray-500 dark:text-gray-400">
              <div
                class="w-fit py-0.5 px-3 rounded-md uppercase"
                :class="[eventBackgroundColour(eventOverview?.eventType!, false), eventTextColour(eventOverview?.eventType!, false)]"
              >
                {{ eventOverview?.eventType }}
              </div>
              <div class="flex flex-col gap-y-4">
                <div class="flex flex-col gap-y-1">
                  <p class="font-semibold text-black dark:text-white">Embarkation</p>
                  <div v-if="eventOverview?.embark?.name" class="flex items-center gap-x-3">
                    <SolidLocationMarker class="w-4 h-4 shrink-0" />
                    <p>
                      {{ eventOverview?.embark?.name }}
                    </p>
                  </div>

                  <div v-if="eventOverview?.position.startDate" class="flex items-center gap-x-3">
                    <SolidCalendar class="w-4 h-4 shrink-0" />
                    <p>
                      {{ getEventStartEndDateTime(eventOverview.interval, eventOverview.allDay).start }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-col gap-y-1">
                  <p class="font-semibold text-black dark:text-white">Disembarkation</p>
                  <div v-if="eventOverview?.disembark?.name" class="flex items-center gap-x-3">
                    <SolidLocationMarker class="w-4 h-4 shrink-0" />
                    <p>
                      {{ eventOverview?.disembark?.name }}
                    </p>
                  </div>
                  <div v-if="eventOverview?.position.endDate" class="flex items-center gap-x-3">
                    <SolidCalendar class="w-4 h-4 shrink-0" />
                    <p>
                      {{ getEventStartEndDateTime(eventOverview.interval, eventOverview.allDay).end }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-4 leading-relaxed text-gray-500 dark:text-gray-400">
              <div class="flex flex-col gap-y-1">
                <p class="font-semibold text-black dark:text-white">Details</p>
                <div v-if="props.vessel?.blueprint.name" class="flex items-center gap-x-2">
                  <SolidShip class="w-4 h-4 shrink-0" />
                  <p>{{ props.vessel.blueprint.name }}</p>
                </div>
                <div v-if="eventOverview?.variantLabel" class="flex items-center gap-x-2">
                  <HiSolidCube class="w-4 h-4 fill-current shrink-0" />
                  <p>{{ eventOverview?.variantLabel }}</p>
                </div>
                <p>{{ eventOverview?.reference }}</p>
                <p
                  v-if="
                    eventOverview?.turnaroundDuration &&
                    (eventOverview.turnaroundDuration.endsWith('D') || eventOverview.turnaroundDuration.endsWith('H'))
                  "
                >
                  {{
                    eventOverview.turnaroundDuration.endsWith('D')
                      ? `${Duration.fromISO(eventOverview.turnaroundDuration).days} ${
                          Duration.fromISO(eventOverview.turnaroundDuration).days === 1 ? 'day' : 'days'
                        }`
                      : `${Duration.fromISO(eventOverview.turnaroundDuration).hours} ${
                          Duration.fromISO(eventOverview.turnaroundDuration).hours === 1 ? 'hour' : 'hours'
                        }`
                  }}
                  turnaround
                </p>
              </div>
              <div v-if="eventOverview?.notes" class="flex flex-col gap-y-1">
                <p class="font-semibold text-black dark:text-white">Notes</p>
                <p>{{ eventOverview?.notes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OnClickOutside>

    <!-- Desktop view -->
    <div v-if="!isMobile" class="w-full">
      <div
        v-for="(week, weekIndex) of (calendar.table[props.year][props.month].week as Record<string, CalendarWeekBlock>)"
        class="row relative w-full flex border-b border-gray-100 dark:border-gray-600"
        :key="`desktop-${props.month}-${weekIndex}`"
      >
        <!-- Days -->
        <div
          v-for="(day, dayIndex) in week.days"
          class="column relative w-full h-32 border transition-all border-gray-100 dark:border-gray-600"
          :key="`desktop-${props.month}-${day.date}-${weekIndex}-${dayIndex}`"
          :class="
            day.isCurrentMonth
              ? 'bg-white text-gray-900 dark:bg-gray-900 dark:text-white'
              : 'bg-gray-50 text-gray-400 dark:bg-gray-700 dark:text-gray-200'
          "
        >
          <!-- Day numbers and show more events -->
          <div class="absolute px-2 py-1">
            <time
              :datetime="day.date"
              :class="{
                'flex h-6 w-6 items-center justify-center rounded-full font-semibold bg-primary-600 text-white dark:bg-primary-700':
                  day.date === dateTime.toFormat('dd-MM-yyyy'),
              }"
            >
              {{ day.day }}
            </time>
          </div>

          <ClickPopover
            v-if="hasHiddenBlocks(Number(weekIndex), day.date, false).length"
            trigger="click"
            placement="left"
            :id="`popover-click-more-desktop-${day.date}`"
          >
            <template #buttonText> {{ hasHiddenBlocks(Number(weekIndex), day.date, false).length }} more </template>
            <template #popoverContent>
              <div
                class="px-3 py-2 border-b rounded-t-lg text-center font-semibold text-gray-900 dark:text-white bg-gray-100 border-gray-200 dark:border-gray-600 dark:bg-gray-700"
              >
                <h3 v-if="dayIndex === 0">Mon {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 1">Tue {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 2">Wed {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 3">Thur {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 4">Fri {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 5">Sat {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 6">Sun {{ day.day }}</h3>
              </div>

              <!-- Show more inline blocks (specials & events) -->
              <div class="flex flex-col gap-y-1 px-3 py-2">
                <div
                  v-for="block of hasHiddenBlocks(Number(weekIndex), day.date, false)"
                  class="relative cursor-pointer group"
                  :key="block.id"
                  @click="block.uri ? (specialsModalContent = block) : openEventOverview(block)"
                >
                  <div
                    v-if="day.date === block.position.startDate"
                    class="absolute h-5 w-1.5 rounded-tl-full rounded-bl-full transition-all duration-150"
                    :class="!block.uri ? eventEdgeColour(block.eventType, true) : ''"
                  ></div>
                  <div
                    class="px-2 h-5 flex items-center justify-center text-black transition-all duration-150"
                    :class="[
                      eventBackgroundColour(block.uri
                        ? isSpecialActive(block.eventInterval!, DateTime.now())
                          ? 'Active special'
                          : 'Expired special'
                        : block.eventType, true),
                      block.uri ? specialBorderColour(isSpecialActive(block.eventInterval!, DateTime.now()), true) : '',
                      {
                        'rounded-tl-lg rounded-bl-lg': week.days.some((day) => day.date === block.position.startDate),
                      },
                      { 'rounded-tr-lg rounded-br-lg': week.days.some((day) => day.date === block.position.endDate) },
                    ]"
                  >
                    <div
                      class="line-clamp-1 truncate font-semibold flex items-center gap-x-1"
                      :class="eventTextColour(block.uri
                        ? isSpecialActive(block.eventInterval!, DateTime.now())
                          ? 'Active special'
                          : 'Expired special'
                        : block.eventType, true)"
                    >
                      <span
                        v-if="block.eventInterval && isSpecialActive(block.eventInterval, DateTime.now())"
                        class="flex items-center gap-x-1"
                      >
                        <SolidFire
                          class="w-3 h-3 shrink-0 fill-purple-800 dark:fill-purple-400 dark:group-hover:fill-purple-500 dark:group-focus:fill-purple-500 dark:group-active:fill-purple-500"
                        />
                        Active Special -
                      </span>
                      <span v-if="block.eventInterval && isSpecialExpired(block.eventInterval, DateTime.now())">
                        Expired Special -
                      </span>
                      <div class="truncate">
                        {{ block.title || '' }}
                        <span v-if="block.embark?.name && block.disembark?.name">
                          {{ block.title ? ' - ' : '' }}
                          {{ block.embark.name }}
                          &#8594;
                          {{ block.disembark.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="day.date === block.position.endDate"
                    class="absolute top-0 right-0 h-5 w-1.5 rounded-tr-full rounded-br-full transition-all duration-150"
                    :class="!block.uri ? eventEdgeColour(block.eventType, true) : ''"
                  ></div>
                </div>
              </div>
            </template>
          </ClickPopover>
        </div>

        <!-- Blocks (Specials & Events) -->
        <ol>
          <li
            v-for="block of (calendar.table?.[props.year][props.month].blocks[weekIndex] as CalendarMixedBlock[])"
            class="absolute group cursor-pointer"
            :class="getEventAbsoluteTop(block.position.priority)"
            :style="{
              left: `${getEventAbsoluteX(block.position.spanningDates[0], block.position.startDate, week)}%`,
              right:
                week.days.some((day) => day.date === block.position.startDate) &&
                !week.days.some((day) => day.date === block.position.endDate)
                  ? '0.1%'
                  : '',
              width: `${getEventAbsoluteWidth(
                block.position.spanningDates[0],
                block.position.spanningDates[block.position.spanningDates.length - 1],
                block.position.spanningDates.length,
                block.position.startDate,
                block.position.endDate,
                week,
              )}%`,
            }"
            :key="block.id"
            @click="block.uri ? (specialsModalContent = block) : openEventOverview(block)"
          >
            <div
              v-if="week.days.some((day) => day.date === block.position.startDate)"
              class="absolute h-5 w-1.5 rounded-tl-full rounded-bl-full transition-all duration-150"
              :class="!block.uri ? eventEdgeColour(block.eventType, true) : ''"
            ></div>
            <div
              class="px-2 h-5 flex items-center justify-center text-black transition-all duration-150"
              :class="[
                eventBackgroundColour(block.uri
                  ? isSpecialActive(block.eventInterval!, DateTime.now())
                    ? 'Active special'
                    : 'Expired special'
                  : block.eventType, true),
                block.uri ? specialBorderColour(isSpecialActive(block.eventInterval!, DateTime.now()), true) : '',
                { 'rounded-tl-lg rounded-bl-lg': week.days.some((day) => day.date === block.position.startDate) },
                { 'rounded-tr-lg rounded-br-lg': week.days.some((day) => day.date === block.position.endDate) },
              ]"
            >
              <div
                class="line-clamp-1 truncate font-semibold flex items-center gap-x-1"
                :class="eventTextColour(block.uri
                  ? isSpecialActive(block.eventInterval!, DateTime.now())
                    ? 'Active special'
                    : 'Expired special'
                  : block.eventType, true)"
              >
                <span
                  v-if="block.eventInterval && isSpecialActive(block.eventInterval, DateTime.now())"
                  class="flex items-center gap-x-1"
                >
                  <SolidFire
                    class="w-3 h-3 shrink-0 fill-purple-800 dark:fill-purple-400 dark:group-hover:fill-purple-500 dark:group-focus:fill-purple-500 dark:group-active:fill-purple-500"
                  />
                  Active Special -
                </span>
                <span v-if="block.eventInterval && isSpecialExpired(block.eventInterval, DateTime.now())">
                  Expired Special -
                </span>
                <div class="truncate">
                  {{ block.title || '' }}
                  <span v-if="block.embark?.name && block.disembark?.name">
                    {{ block.title ? ' - ' : '' }}
                    {{ block.embark.name }}
                    &#8594;
                    {{ block.disembark.name }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="week.days.some((day) => day.date === block.position.endDate)"
              class="absolute top-0 right-0 h-5 w-1.5 rounded-tr-full rounded-br-full transition-all duration-150"
              :class="!block.uri ? eventEdgeColour(block.eventType, true) : ''"
            ></div>
          </li>
        </ol>
      </div>
    </div>

    <!-- Mobile view -->
    <div v-else class="w-full">
      <div
        v-for="(week, weekIndex) of (calendar.table?.[props.year][props.month].week as Record<string, CalendarWeekBlock>)"
        class="row relative w-full flex border-y border-gray-100 dark:border-gray-600"
        :key="`mobile-${props.month}-${weekIndex}`"
      >
        <!-- Days -->
        <div
          v-for="(day, dayIndex) in week.days"
          class="relative column w-full h-[5.275rem] border-x border-gray-100 dark:border-gray-600"
          :key="`desktop-${props.month}-${day.date}-${weekIndex}-${dayIndex}`"
          :class="
            day.isCurrentMonth
              ? 'bg-white text-gray-900 dark:bg-gray-900 dark:text-white'
              : 'bg-gray-50 text-gray-400 dark:bg-gray-700 dark:text-gray-200'
          "
        >
          <!-- Day numbers and show more events -->
          <div class="absolute px-2 py-1">
            <time
              :datetime="day.date"
              :class="{
                'flex h-5 w-6 items-center justify-center rounded-full font-semibold bg-primary-600 text-white dark:bg-primary-700':
                  day.date === dateTime.toFormat('dd-MM-yyyy'),
              }"
            >
              {{ day.day }}
            </time>
          </div>

          <ClickPopover
            v-if="hasHiddenBlocks(Number(weekIndex), day.date, true).length"
            trigger="click"
            placement="bottom"
            :id="`popover-click-more-mobile-${day.date}`"
          >
            <template #buttonText> +{{ hasHiddenBlocks(Number(weekIndex), day.date, true).length }} </template>
            <template #popoverContent>
              <div
                class="px-3 py-2 border-b rounded-t-lg text-center font-semibold text-gray-900 dark:text-white bg-gray-100 border-gray-200 dark:border-gray-600 dark:bg-gray-700"
              >
                <h3 v-if="dayIndex === 0">Mon {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 1">Tue {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 2">Wed {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 3">Thur {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 4">Fri {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 5">Sat {{ day.day }}</h3>
                <h3 v-else-if="dayIndex === 6">Sun {{ day.day }}</h3>
              </div>

              <!-- Show more inline blocks (specials & events) -->
              <div class="flex flex-col gap-y-1 px-3 py-2">
                <div
                  v-for="block of hasHiddenBlocks(Number(weekIndex), day.date, true)"
                  class="relative cursor-pointer group"
                  :key="block.id"
                  @click="block.uri ? (specialsModalContent = block) : openEventOverview(block)"
                >
                  <div
                    v-if="day.date === block.position.startDate"
                    class="absolute h-5 w-1.5 rounded-tl-full rounded-bl-full group-hover:opacity-50"
                    :class="!block.uri ? eventEdgeColour(block.eventType, true) : ''"
                  ></div>
                  <div
                    class="px-2 h-5 flex items-center justify-center text-black transition-all duration-150"
                    :class="[
                      eventBackgroundColour(block.uri
                        ? isSpecialActive(block.eventInterval!, DateTime.now())
                          ? 'Active special'
                          : 'Expired special'
                        : block.eventType, true),
                      block.uri ? specialBorderColour(isSpecialActive(block.eventInterval!, DateTime.now()), true) : '',
                      {
                        'rounded-tl-lg rounded-bl-lg': week.days.some((day) => day.date === block.position.startDate),
                      },
                      { 'rounded-tr-lg rounded-br-lg': week.days.some((day) => day.date === block.position.endDate) },
                    ]"
                  >
                    <div
                      class="line-clamp-1 truncate font-semibold flex items-center gap-x-1"
                      :class="eventTextColour(block.uri
                        ? isSpecialActive(block.eventInterval!, DateTime.now())
                          ? 'Active special'
                          : 'Expired special'
                        : block.eventType, true)"
                    >
                      <span
                        v-if="block.eventInterval && isSpecialActive(block.eventInterval, DateTime.now())"
                        class="flex items-center gap-x-1"
                      >
                        <SolidFire
                          class="w-3 h-3 shrink-0 fill-purple-800 dark:fill-purple-400 dark:group-hover:fill-purple-500 dark:group-focus:fill-purple-500 dark:group-active:fill-purple-500"
                        />
                        Active Special -
                      </span>
                      <span v-if="block.eventInterval && isSpecialExpired(block.eventInterval, DateTime.now())">
                        Expired Special -
                      </span>
                      <div class="truncate">
                        {{ block.title }}
                        <span v-if="block.embark?.name && block.disembark?.name">
                          {{ block.title ? ' - ' : '' }}
                          {{ block.embark.name }}
                          &#8594;
                          {{ block.disembark.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="day.date === block.position.endDate"
                    class="absolute top-0 right-0 h-5 w-1.5 rounded-tr-full rounded-br-full group-hover:opacity-50"
                    :class="!block.uri ? eventEdgeColour(block.eventType, true) : ''"
                  ></div>
                </div>
              </div>
            </template>
          </ClickPopover>
        </div>

        <!-- Blocks (specials & events) -->
        <ol>
          <li
            v-for="block of (calendar.table?.[props.year][props.month].blocks[weekIndex] as CalendarMixedBlock[])"
            class="absolute group cursor-pointer"
            :class="getEventAbsoluteTop(block.position.priority)"
            :style="{
              left: `${getEventAbsoluteX(block.position.spanningDates[0], block.position.startDate, week)}%`,
              right:
                week.days.some((day) => day.date === block.position.startDate) &&
                !week.days.some((day) => day.date === block.position.endDate)
                  ? '0.1%'
                  : '',
              width: `${getEventAbsoluteWidth(
                block.position.spanningDates[0],
                block.position.spanningDates[block.position.spanningDates.length - 1],
                block.position.spanningDates.length,
                block.position.startDate,
                block.position.endDate,
                week,
              )}%`,
            }"
            :key="block.id"
            @click="block.uri ? (specialsModalContent = block) : openEventOverview(block)"
          >
            <div
              v-if="week.days.some((day) => day.date === block.position.startDate)"
              class="absolute h-4 w-1.5 rounded-tl-full rounded-bl-full group-hover:opacity-50 transition-all duration-150"
              :class="!block.uri ? eventEdgeColour(block.eventType, true) : ''"
            ></div>
            <div
              class="px-2 h-4 flex items-center justify-center text-black transition-all duration-150"
              :class="[
                eventBackgroundColour(block.uri
                  ? isSpecialActive(block.eventInterval!, DateTime.now())
                    ? 'Active special'
                    : 'Expired special'
                  : block.eventType, true),
                block.uri ? specialBorderColour(isSpecialActive(block.eventInterval!, DateTime.now()), true) : '',
                { 'rounded-tl-lg rounded-bl-lg': week.days.some((day) => day.date === block.position.startDate) },
                { 'rounded-tr-lg rounded-br-lg': week.days.some((day) => day.date === block.position.endDate) },
              ]"
            >
              <div
                class="line-clamp-1 truncate font-semibold flex items-center gap-x-1"
                :class="eventTextColour(block.uri
                  ? isSpecialActive(block.eventInterval!, DateTime.now())
                    ? 'Active special'
                    : 'Expired special'
                  : block.eventType, true)"
              >
                <span
                  v-if="block.eventInterval && isSpecialActive(block.eventInterval, DateTime.now())"
                  class="flex items-center gap-x-1"
                >
                  <SolidFire
                    class="w-3 h-3 shrink-0 fill-purple-800 dark:fill-purple-400 dark:group-hover:fill-purple-500 dark:group-focus:fill-purple-500 dark:group-active:fill-purple-500"
                  />
                  Active Special -
                </span>
                <span v-if="block.eventInterval && isSpecialExpired(block.eventInterval, DateTime.now())">
                  Expired Special -
                </span>
                <div class="truncate">
                  <span class="font-semibold">{{ block.title || '' }}</span>
                  <span v-if="block.embark?.name && block.disembark?.name">
                    {{ block.title ? ' - ' : '' }}
                    {{ block.embark.name }}
                    &#8594;
                    {{ block.disembark.name }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="week.days.some((day) => day.date === block.position.endDate)"
              class="absolute top-0 right-0 h-4 w-1.5 rounded-tr-full rounded-br-full group-hover:opacity-50 transition-all duration-150"
              :class="!block.uri ? eventEdgeColour(block.eventType, true) : ''"
            ></div>
          </li>
        </ol>
      </div>
    </div>

    <!-- TODO: Update when refactoring: https://app.asana.com/0/1204484386903582/1207119688432643/f -->
    <Teleport v-if="specialsModalContent" to="body">
      <div class="fixed z-50 inset-0 backdrop-blur-[0.125rem] flex justify-center items-center p-2">
        <OnClickOutside
          class="relative w-full max-w-2xl max-h-screen overflow-auto border sm:border-0 border-gray-200 dark:border-gray-600 rounded-lg shadow-md"
          tabindex="-1"
          aria-hidden="true"
          @trigger="specialsModalContent = null"
        >
          <div class="absolute right-4 top-4 flex justify-end">
            <OutlineXMark
              class="cursor-pointer p-1.5 w-6 h-6 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400"
              @click="specialsModalContent = null"
            />
          </div>

          <!-- Modal content -->
          <PostCardSpecial
            class="pt-8"
            :uri="specialsModalContent.uri"
            :title="specialsModalContent.title"
            :description="specialsModalContent.description"
            :event-interval="specialsModalContent.eventInterval!"
            :vessel-name="specialsModalContent.vesselName!"
            :location="specialsModalContent.location"
            :hero="generateMediaUri()"
            :created-at="specialsModalContent.createdAt"
            :updated-at="specialsModalContent.updatedAt!"
            :user-name="props.specials.filter((special) => special.uri === specialsModalContent!.uri)[0].line_1"
            :company-name="props.specials.filter((special) => special.uri === specialsModalContent!.uri)[0].line_2"
            :correspondence-email="specialsModalContent.correspondenceEmail"
            :enquiryEmailEnabled="$growthbook.isOn(COMMODORE_20240717_CA_SPECIALS_ENQUIRY_MODAL)"
            @on:enquire="
              openEnquiryModal(
                specialsModalContent.uri || '',
                vessel.uri || '',
                specialsModalContent.vesselName || '',
                specialsModalContent.correspondenceEmail || '',
              )
            "
          />
        </OnClickOutside>
      </div>
    </Teleport>

    <Teleport v-if="showEnquireModal && enquiryData" to="body">
      <EnquiryModal
        :vessel-name="enquiryData.vesselName"
        :vessel-contact-persons="enquiryData.toContactPerson"
        :company-name="company?.name"
        :is-loading-company="isLoadingCompany"
        :is-sending-enquiry="isSendingEnquiry"
        @on:close="closeEnquiryModal"
        @on:submit="submitEnquiry($event, enquiryData.vesselUri, enquiryData.specialsUri)"
      />
    </Teleport>

    <!-- Toast message for enquiry -->
    <Teleport v-if="toastEnquiryMessage" to="body">
      <div
        class="z-50 absolute top-20 right-5 flex w-full max-w-xs p-4 rounded-lg shadow text-gray-500 bg-white divide-gray-200 dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800"
      >
        <div class="flex justify-between w-full">
          <div class="flex items-center gap-x-4">
            <SolidPaperAirplane
              v-if="toastEnquiryMessage === ToastEnquiryEnums.ENQUIRY_SUCCESSFUL"
              class="w-6 h-6 shrink-0 rounded-lg text-primary-600 rotate-[24deg]"
            />
            <div
              v-else-if="toastEnquiryMessage === ToastEnquiryEnums.ENQUIRY_FAILED"
              class="bg-red-300 dark:bg-red-400 rounded-md p-1"
            >
              <OutlineBell class="w-6 h-6 shrink-0 rounded-lg text-red-600" />
            </div>
            <div class="text-sm">
              <p>{{ toastEnquiryMessage }}</p>
            </div>
          </div>
          <SolidX
            class="w-4 text-gray-500 dark:text-gray-400 cursor-pointer shrink-0"
            @click.stop="toastEnquiryMessage = null"
          />
        </div>
      </div>
    </Teleport>
  </div>
</template>
