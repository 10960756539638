<script setup lang="ts">
import { Ref, ref } from 'vue'

type Props = {
  id: string
  type?: string
  step?: number
  label?: string
  placeholder?: string
  value?: string | number
  isValid?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  isValid: true,
})
const emit = defineEmits<{
  (e: 'update:input', event: Event): void
}>()

const inputValue: Ref<string | number | undefined> = ref(props.value)

const updateInput = (event: FocusEvent) => {
  const val = (event.target as HTMLInputElement).value
  if (val === inputValue.value) {
    return // Don't emit the same value
  }

  inputValue.value = val
  emit('update:input', event)
}
</script>
<template>
  <div>
    <label v-if="props.label" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" :for="props.id">
      {{ props.label }}
    </label>
    <input
      class="border text-sm rounded-lg text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-4 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:text-gray-900 dark:focus:text-white"
      :class="
        !props.isValid
          ? 'border-red-500 dark:border-red-400 bg-red-600 bg-opacity-10'
          : 'invalid:border-red-500 invalid:dark:border-red-400 invalid:text-red-500 invalid:dark:text-red-400 valid:border-gray-300 valid:dark:border-gray-600 bg-gray-50 dark:bg-gray-700'
      "
      :id="props.id"
      :type="props.type"
      :step="props.step"
      :value="inputValue"
      :placeholder="props.placeholder"
      @blur="updateInput($event)"
    />
  </div>
</template>
