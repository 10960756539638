<script setup lang="ts">
import { Duration } from 'luxon'
import { Ref, ref } from 'vue'

import { eventBackgroundColour, eventTextColour } from '@ankor-io/common/calendar/event-colours'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import { CalendarEvent } from '@ankor-io/common/vessel/types'
import { HiSolidCube } from '@ankor-io/icons/hi_solid'
import { OutlinePencil, OutlineTrash } from '@ankor-io/icons/outline'
import {
  SolidArrowNarrowRight,
  SolidCalendar,
  SolidChevronDown,
  SolidLocationMarker,
  SolidShip,
} from '@ankor-io/icons/solid'

type Props = {
  event: CalendarEvent
  vesselName?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'delete:event', id: string): void
  (e: 'open:update:modal', event: CalendarEvent): void
}>()

const isOpen: Ref<boolean> = ref(false)

const interval = ISO8601.fromIntervalString(props.event.interval)

const getEventStartEndDateTime = (): { start: string; end: string } => {
  const { start, end } = interval
  return {
    start: `${start!.toFormat('dd-MM-yyyy')}${props.event.allDay ? '' : ` ${start!.toFormat('hh:mm a')}`} (${
      start?.zoneName
    })`,
    end: `${end!.toFormat('dd-MM-yyyy')}${props.event.allDay ? '' : ` ${end!.toFormat('hh:mm a')}`} (${end?.zoneName})`,
  }
}

const convertToStartEndDate = (interval: string) => {
  const { start, end } = ISO8601.fromIntervalString(interval)
  if (start?.year === end?.year) {
    return {
      start: `${start?.toLocaleString({ weekday: 'short', day: 'numeric' })}`,
      end: ` ${end?.toLocaleString({
        weekday: 'short',
        day: 'numeric',
      })}`,
    }
  }

  return {
    start: `${start?.toLocaleString({
      weekday: 'short',
      day: 'numeric',
    })}`,
    end: `${end?.toLocaleString({
      weekday: 'short',
      day: 'numeric',
    })}`,
  }
}
</script>
<template>
  <div class="flex flex-col w-full">
    <div
      class="flex flex-col gap-y-2 cursor-pointer self-start w-full p-2 md:p-4"
      :class="{ 'border-b border-b-gray-300 dark:border-b-gray-400 bg-gray-50 dark:bg-gray-800': isOpen }"
      @click="isOpen = !isOpen"
    >
      <div class="flex w-full justify-between">
        <div class="flex gap-x-5 max-w-[90%] md:max-w-full w-full">
          <span
            class="min-w-[6.5rem] md:min-w-[7.5rem] text-center text-xs md:text-sm font-medium px-2.5 py-0.5 uppercase rounded"
            :class="[
              eventBackgroundColour(props.event.eventType, false),
              eventTextColour(props.event.eventType, false),
            ]"
          >
            {{ props.event.eventType }}
          </span>
          <span class="hidden sm:block md:w-[14rem] truncate text-sm font-bold text-gray-900 dark:text-white">
            {{ props.event.title }}
          </span>
        </div>
        <div class="flex gap-x-2 justify-end md:justify-between text-sm w-full overflow-hidden">
          <div class="hidden md:flex gap-x-2 truncate">
            <p class="font-bold text-gray-900 dark:text-white">
              {{ convertToStartEndDate(props.event.interval).start }}
            </p>
            <p class="text-gray-400 dark:text-gray-500">{{ props.event.embark?.name }}</p>
            <SolidArrowNarrowRight class="w-4 shrink-0 text-gray-900 dark:text-gray-400" />
            <p class="font-bold text-gray-900 dark:text-white">{{ convertToStartEndDate(props.event.interval).end }}</p>
            <p class="text-gray-400 dark:text-gray-500">{{ props.event.disembark?.name }}</p>
          </div>
          <SolidChevronDown
            class="w-5 h-5 shrink-0 transition-all duration-150"
            :class="isOpen ? 'fill-gray-900 dark:fill-white rotate-180' : 'fill-gray-500 dark:fill-gray-400'"
          />
        </div>
      </div>
      <div class="flex md:hidden gap-x-2 text-sm truncate">
        <p class="font-bold text-gray-900 dark:text-white">
          {{ convertToStartEndDate(props.event.interval).start }}
        </p>
        <p class="text-gray-400 dark:text-gray-500">{{ props.event.embark?.name }}</p>
        <SolidArrowNarrowRight class="w-4 shrink-0 text-gray-900 dark:text-gray-400" />
        <p>{{ convertToStartEndDate(props.event.interval).end }}</p>
        <p class="text-gray-400 dark:text-gray-500">{{ props.event.disembark?.name }}</p>
      </div>
    </div>

    <div
      v-if="isOpen"
      class="flex flex-col flex-grow gap-y-2 sm:gap-y-4 p-2 sm:p-4 border-b border-b-gray-300 dark:border-b-gray-500 hover:bg-gray-100 dark:hover:bg-gray-900"
    >
      <div class="flex justify-end gap-x-2">
        <button
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 p-1 rounded-lg text-sm inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          @click.stop="emit('open:update:modal', props.event)"
        >
          <OutlinePencil class="w-4 h-4" />
          <span class="sr-only">Edit Event</span>
        </button>
        <button
          type="button"
          class="text-red-500 bg-transparent hover:bg-gray-200 hover:text-red-700 p-1 rounded-lg text-sm inline-flex justify-center items-center hover:dark:text-red-400 dark:hover:bg-gray-600 dark:hover:text-white"
          @click.stop="emit('delete:event', props.event.id)"
        >
          <OutlineTrash class="w-4 h-4" />
          <span class="sr-only">Delete Event</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="grid grid-cols-2 gap-x-4 text-sm">
        <div class="flex flex-col gap-y-2 leading-relaxed text-gray-500 dark:text-gray-400">
          <div
            v-if="props.event.eventType"
            class="w-fit py-0.5 px-3 rounded-md uppercase"
            :class="[eventBackgroundColour(props.event?.eventType!, false), eventTextColour(props.event?.eventType!, false)]"
          >
            {{ props.event.eventType }}
          </div>
          <div class="flex flex-col gap-y-4">
            <div class="flex flex-col gap-y-1">
              <p class="font-semibold text-black dark:text-white">Embarkation</p>
              <div v-if="props.event?.embark?.name" class="flex items-center gap-x-3">
                <SolidLocationMarker class="w-4 h-4 shrink-0" />
                <p>
                  {{ props.event.embark.name }}
                </p>
              </div>

              <div v-if="interval.start" class="flex items-center gap-x-3">
                <SolidCalendar class="w-4 h-4 shrink-0" />
                <p>
                  {{ getEventStartEndDateTime().start }}
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-y-1">
              <p class="font-semibold text-black dark:text-white">Disembarkation</p>
              <div v-if="props.event?.disembark?.name" class="flex items-center gap-x-3">
                <SolidLocationMarker class="w-4 h-4 shrink-0" />
                <p>
                  {{ props.event.disembark.name }}
                </p>
              </div>
              <div v-if="interval.end" class="flex items-center gap-x-3">
                <SolidCalendar class="w-4 h-4 shrink-0" />
                <p>
                  {{ getEventStartEndDateTime().end }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4 leading-relaxed text-gray-500 dark:text-gray-400">
          <div class="flex flex-col gap-y-1">
            <p class="font-semibold text-black dark:text-white">Details</p>
            <div v-if="props.vesselName" class="flex items-center gap-x-2">
              <SolidShip class="w-4 h-4 shrink-0" />
              <p>{{ props.vesselName }}</p>
            </div>
            <div v-if="props.event?.variantLabel" class="flex items-center gap-x-2">
              <HiSolidCube class="w-4 h-4 fill-current shrink-0" />
              <p>{{ props.event?.variantLabel }}</p>
            </div>
            <p>{{ props.event?.reference }}</p>
            <p
              v-if="
                props.event?.turnaroundDuration &&
                (props.event.turnaroundDuration.endsWith('D') || props.event.turnaroundDuration.endsWith('H'))
              "
            >
              {{
                props.event.turnaroundDuration.endsWith('D')
                  ? `${Duration.fromISO(props.event.turnaroundDuration).days} ${
                      Duration.fromISO(props.event.turnaroundDuration).days === 1 ? 'day' : 'days'
                    }`
                  : `${Duration.fromISO(props.event.turnaroundDuration).hours} ${
                      Duration.fromISO(props.event.turnaroundDuration).hours === 1 ? 'hour' : 'hours'
                    }`
              }}
            </p>
          </div>
          <div v-if="props.event?.notes" class="flex flex-col gap-y-1">
            <p class="font-semibold text-black dark:text-white">Notes</p>
            <p>{{ props.event?.notes }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
