<script setup lang="ts">
import { initPopovers } from 'flowbite'
import { Ref, onMounted, ref, watch } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { URIContext } from '@ankor-io/common/uri/Uri'
import { MediaUriBuilder } from '@ankor-io/common/uri/uri.builder'
import { formatLength } from '@ankor-io/common/vessel/Formatter'
import { PricingItem, getLowestPricingItem, intlCurrencyFormatter } from '@ankor-io/common/vessel/lowest-price-variant'
import { Variant, Vessel } from '@ankor-io/common/vessel/types'
import { OutlineClipboardCopy } from '@ankor-io/icons/outline'

type Props = {
  vessel: Vessel
  rates: { [key: string]: string }
  categoryOptions: readonly { key: string; label: string; value: boolean }[]
  index: number
  // shortlistAll: boolean
  // isShortlisted: boolean
  company?: string[]
  generatingBrochureForUri: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'shortlist:vessel', uri: string): void
  (e: 'copy:brochure', value: string): void
}>()

// const isShortlisted: Ref<boolean> = ref(false)
const lowestPricingDay: Ref<PricingItem | null> = ref(null)
const lowestPricingTerm: Ref<PricingItem | null> = ref(null)
const lowestPricingHour: Ref<PricingItem | null> = ref(null)
const imgPath: Ref<string | null> = ref(null)

onMounted(() => {
  initPopovers()
  setLowestPricedVariant()
  initImagePath()
})

const initImagePath = () => {
  if (props.vessel.blueprint?.images?.length && props.vessel.blueprint.images[0]) {
    const hero = props.vessel.blueprint.images[0]
    if (hero.startsWith(`${URIContext.MEDIA}::`)) {
      imgPath.value = `/media/${hero}`
    } else {
      imgPath.value = `/media/${new MediaUriBuilder().build(props.vessel.uri, hero)}`
    }
  }
}

/**
 * Sets the day, hour and term lowest variant price to display in the row
 */
const setLowestPricedVariant = () => {
  const validVariants = props.vessel.variants.filter(
    (variant: Variant) =>
      variant.active && variant.pricing?.total && variant.pricing.total > 0 && variant.pricing?.currency,
  )

  const dayVariants = validVariants.filter((variant: Variant) => variant.pricing?.unit === 'DAY')
  const hourVariants = validVariants.filter((variant: Variant) => variant.pricing?.unit === 'HOUR')
  const termVariants = validVariants.filter((variant: Variant) => variant.pricing?.unit === 'WEEK')

  lowestPricingDay.value = getLowestPricingItem(dayVariants, props.rates)
  lowestPricingHour.value = getLowestPricingItem(hourVariants, props.rates)
  lowestPricingTerm.value = getLowestPricingItem(termVariants, props.rates)
}

// Global checkbox value gets checked which forces the local one to be true (sync)
// watch(
//   () => props.shortlistAll,
//   (value) => {
//     isShortlisted.value = value
//   },
// )

/**
 * The pricing is all computed in here, not part of the data so when the vessel data structure changes, the pricing needs to be re-computed
 */
watch(
  () => props.vessel,
  (newValue, oldValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      setLowestPricedVariant()
    }
  },
  { deep: true },
)
</script>
<template>
  <RouterLink
    role="row"
    aria-expanded="false"
    class="group flex items-center border-b text-sm h-[4.5rem] transition whitespace-nowrap border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 hover:bg-gray-100 hover:dark:bg-gray-900"
    :class="!props.generatingBrochureForUri ? 'cursor-pointer' : 'pointer-events-none'"
    :id="`vessel-item-body-${props.index}`"
    :to="{ name: 'view-vessel', params: { uri: encodeURIComponent(props.vessel.uri) } }"
  >
    <!-- Shortlist vessel -->
    <!-- Vessel image -->
    <!-- Vessel name -->
    <div
      class="z-10 mt-px text-base w-[23rem] min-w-[23rem] h-[4.5rem] md:sticky md:left-0 flex items-center border-b border-r transition font-semibold whitespace-nowrap cursor-pointer border-gray-200 dark:border-gray-600 text-gray-900 dark:text-white bg-white dark:bg-gray-800 group-hover:bg-gray-100 group-hover:dark:bg-gray-900"
    >
      <!-- Checkbox - compare/shortlist -->
      <div class="p-4" @click.stop>
        <!-- <div class="flex items-center gap-x-2">
          <input
            type="checkbox"
            class="w-4 h-4 cursor-pointer rounded text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            :id="props.vessel.blueprint.name"
            :checked="props.isShortlisted"
            @change="emit('shortlist:vessel', props.vessel.uri)"
          />
        </div> -->
      </div>
      <!-- TODO: Remove below pl-4 when the above checkbox is added back in -->
      <!-- Vessel name -->
      <div class="pl-4 pr-4 py-3 flex gap-x-3 items-center truncate">
        <div class="h-12 w-16">
          <AssetViewer
            v-if="imgPath"
            class="object-cover rounded-[0.25rem]"
            :url="imgPath"
            :widthDescriptors="['320w']"
          />
        </div>
        <span class="truncate">{{ props.vessel.blueprint.name }}</span>
      </div>
    </div>
    <!-- Company name -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'line_7' && category.value)"
      role="cell"
      class="z-0 w-[16.5rem] min-w-[16.5rem] px-4 py-3 truncate"
    >
      {{ Array.isArray(props.company) ? props.company.join(', ') : props.company || '-' }}
    </div>
    <!-- Term price min -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'termPrice' && category.value)"
      role="cell"
      class="w-40 min-w-[10rem] px-4 py-3 truncate"
    >
      <span v-if="lowestPricingTerm?.displayCurrency && lowestPricingTerm?.displayPrice">
        From {{ intlCurrencyFormatter(lowestPricingTerm!.displayCurrency, lowestPricingTerm!.displayPrice) }}
      </span>
      <span v-else>-</span>
    </div>
    <!-- Day price min -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'dayPrice' && category.value)"
      role="cell"
      class="w-40 min-w-[10rem] px-4 py-3 truncate"
    >
      <span v-if="lowestPricingDay?.displayCurrency && lowestPricingDay?.displayPrice">
        From {{ intlCurrencyFormatter(lowestPricingDay!.displayCurrency, lowestPricingDay!.displayPrice) }}
      </span>
      <span v-else>-</span>
    </div>
    <!-- Hourly price min -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'hourlyPrice' && category.value)"
      role="cell"
      class="w-40 min-w-[10rem] px-4 py-3 truncate"
    >
      <span v-if="lowestPricingHour?.displayCurrency && lowestPricingHour?.displayPrice">
        From {{ intlCurrencyFormatter(lowestPricingHour!.displayCurrency, lowestPricingHour!.displayPrice) }}
      </span>
      <span v-else>-</span>
    </div>
    <!-- Vessel length -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'line_2' && category.value)"
      role="cell"
      class="w-32 min-w-[8rem] px-4 py-3 truncate"
    >
      {{ formatLength(props.vessel.blueprint.length) }}
    </div>
    <!-- Vessel category -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'category' && category.value)"
      role="cell"
      class="w-32 min-w-[8rem] px-4 py-3 truncate"
    >
      {{ props.vessel.category || '-' }}
    </div>
    <!-- Vessel num cabins -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'cabins' && category.value)"
      role="cell"
      class="w-32 min-w-[8rem] px-4 py-3 truncate"
    >
      {{ props.vessel.blueprint.cabins || '-' }}
    </div>
    <!-- Vessel cruising speed -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'cruiseSpeed' && category.value)"
      role="cell"
      class="w-44 min-w-[11rem] px-4 py-3 truncate"
    >
      {{ props.vessel.blueprint.cruiseSpeed || '-' }}
    </div>
    <!-- Vessel num bathrooms -->
    <!-- TODO: When reinstating brochure, add w-36 -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'bathrooms' && category.value)"
      role="cell"
      class="min-w-[9rem] px-4 py-3 truncate"
    >
      {{ props.vessel.blueprint.bathrooms || '-' }}
    </div>
    <!-- Vessel brochure link -->
    <div
      v-if="props.categoryOptions.find((category) => category.key === 'brochure' && category.value)"
      role="cell"
      class="min-w-[8.5rem] px-4 py-3"
    >
      <div v-if="props.generatingBrochureForUri === props.vessel.uri" role="status" class="w-4 h-4 shrink-0 mx-auto">
        <svg
          aria-hidden="true"
          class="animate-spin text-gray-200 fill-primary-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
      <div
        v-else
        :data-popover-target="`copy-popover-${index}`"
        data-popover-placement="left"
        class="w-min mx-auto p-1 hover:bg-gray-200 hover:dark:bg-gray-800 rounded-md"
      >
        <OutlineClipboardCopy
          class="w-4 h-4 shrink-0"
          :class="
            !props.generatingBrochureForUri
              ? 'text-gray-500 dark:text-gray-400 cursor-pointer'
              : 'text-gray-300 dark:text-gray-600 pointer-events-none'
          "
          @click.prevent="emit('copy:brochure', props.vessel.uri)"
        />
      </div>
      <div
        data-popover
        :id="`copy-popover-${index}`"
        role="tooltip"
        class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
      >
        <div class="px-3 py-2">
          <p>Copy Link</p>
        </div>
        <div data-popper-arrow></div>
      </div>
    </div>
  </RouterLink>
</template>
