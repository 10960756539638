<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { DateTime } from 'luxon'
import { Ref, computed, onMounted, ref, watch } from 'vue'

import DateRangePicker from '@ankor-io/blocks/components/DateRangePicker/DateRangePicker.vue'
import TextEditor from '@ankor-io/blocks/components/TextEditor/TextEditor.vue'
import { getEndDate, getStartDate, updateInterval } from '@ankor-io/common/date/helper'
import { isEmailValid } from '@ankor-io/common/input-validation/validation'
import { getInitials } from '@ankor-io/common/utils/getInitials'
import { Place } from '@ankor-io/common/vessel/types'
import { Post } from '@ankor-io/feed-endpoint/src/types'
import { SolidChevronDown, SolidMail, SolidPin, SolidShip, SolidX } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'

type Props = {
  userId: string
  givenName: string
  familyName: string
  companyName: string
  locationSearchResults: Place[]
  vesselList: { name: string; uri: string }[]
  post: Post | null
  isConfirmLoading: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'create:post', value: { post: Post; userName: string }): void
  (e: 'update:post', value: { postUri: string; post: Post; userName: string }): void
  (e: 'fetch:places', searchText: string): void
  (e: 'close:modal'): void
}>()

const isLoading: Ref<boolean> = ref(false)

const showErrors: Ref<boolean> = ref(false)
const showVesselList: Ref<boolean> = ref(false)
const locationSearchText: Ref<string> = ref('')

const emailValid: Ref<boolean> = ref(true)
const emailInput: Ref<string | undefined> = ref()

const postToUpdate: Ref<Post> = ref({
  uri: '',
  layout: 'special',
  publishedToFeeds: [],
  title: '',
  description: '',
  vesselUri: '',
  hero: '',
  vesselName: '',
  eventInterval: '',
  location: undefined,
  createdAt: '',
  uriCreatedBy: '',
  correspondenceEmail: '',
  tags: [],
})

/**
 * Show location search results
 * when there is text in the search input and there are results to show
 */
const showLocationSearchResults = computed(() => {
  if (!locationSearchText.value) {
    return false
  }

  if (props.locationSearchResults.length === 0) {
    return false
  }

  const locationText =
    postToUpdate.value.location?.name +
    (postToUpdate.value.location?.country ? ', ' + postToUpdate.value.location.country : '')

  return locationSearchText.value !== locationText
})

onMounted(() => {
  if (props.post) {
    postToUpdate.value = props.post
    emailInput.value = postToUpdate.value.correspondenceEmail
  }

  if (props.post?.location) {
    locationSearchText.value =
      props.post.location.name + (props.post.location.country ? ', ' + props.post.location.country : '')
  }
})

watch(
  () => props.isConfirmLoading,
  (value: boolean) => {
    if (!value) {
      isLoading.value = false
    }
  },
)

/**
 * update vessel details upon selection
 * @param vessel
 */
const onSelectVessel = (vessel: { name: string; uri: string }): void => {
  showVesselList.value = false
  postToUpdate.value.vesselName = vessel.name
  postToUpdate.value.vesselUri = vessel.uri
}

const updateEmail = (event: Event) => {
  const value = (event.target as HTMLInputElement).value
  if (emailInput.value === value) {
    return
  }

  emailInput.value = value
  emailValid.value = true
  // Input validation
  if (value && !isEmailValid(value)) {
    emailValid.value = false
    return
  }

  postToUpdate.value.correspondenceEmail = value
}

/**
 * Add location to post
 * @param place
 */
const updateLocation = async (place: Place) => {
  locationSearchText.value = place.name + (place.country ? ', ' + place.country : '')
  postToUpdate.value.location = place
}

/**
 * Remove location from post
 */
const removeLocation = () => {
  locationSearchText.value = ''
  postToUpdate.value.location = undefined
}

/**
 * Check if post is valid
 */
const isPostValid = (): boolean => {
  if (
    !postToUpdate.value.title ||
    !postToUpdate.value.description ||
    !postToUpdate.value.eventInterval ||
    !postToUpdate.value.correspondenceEmail ||
    !postToUpdate.value.vesselUri
  ) {
    return false
  }

  return true
}

/**
 * add new post
 */
const createPost = () => {
  if (!isPostValid()) {
    showErrors.value = true
    return
  }

  isLoading.value = true
  postToUpdate.value.uriCreatedBy = `u::${props.userId}`
  postToUpdate.value.createdAt = DateTime.now().toUTC().toISO() as string
  emit('create:post', { post: postToUpdate.value, userName: `${props.givenName} ${props.familyName}` })
}

/**
 * update existing post
 */
const updatePost = () => {
  if (!isPostValid()) {
    showErrors.value = true
    return
  }

  isLoading.value = true
  postToUpdate.value.uriCreatedBy = `u::${props.userId}`
  postToUpdate.value.updatedAt = DateTime.now().toUTC().toISO() as string
  emit('update:post', {
    postUri: postToUpdate.value.uri,
    post: postToUpdate.value,
    userName: `${props.givenName} ${props.familyName}`,
  })
}
</script>
<template>
  <div
    tabindex="1"
    aria-hidden="true"
    class="fixed z-50 inset-0 backdrop-blur-[0.125rem] flex justify-center items-center"
    :class="{ 'pointer-events-none': isLoading }"
    @click.stop
  >
    <div
      class="w-full md:max-w-4xl max-h-screen overflow-auto relative bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded-lg shadow-md grid grid-row-3 divide-y dark:divide-gray-600 text-sm text-gray-900 dark:text-white"
    >
      <!-- Modal Header -->
      <div class="flex items-center gap-x-2 p-6">
        <!-- close icon -->
        <div class="absolute right-8 top-8 flex justify-end">
          <SolidX
            class="cursor-pointer w-5 h-5 self-center text-gray-900 hover:text-primary-600 dark:text-white dark:hover:text-primary-600"
            @click.stop="emit('close:modal')"
          />
        </div>

        <div
          class="inline-flex items-center justify-center w-12 h-12 shrink-0 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
        >
          <span class="font-medium text-gray-600 dark:text-gray-300">
            {{ getInitials(props.givenName, props.familyName) }}
          </span>
        </div>
        <div class="flex flex-col gap-y-1.5">
          <p class="leading-none text-lg font-semibold">{{ props.givenName }} {{ props.familyName }}</p>
          <p class="leading-none text-gray-500">{{ props.companyName }}</p>
        </div>
      </div>

      <!-- Modal Body -->
      <div class="p-6 flex flex-col gap-y-4">
        <div class="relative z-0 w-full">
          <!-- Headline -->
          <input
            type="text"
            maxlength="100"
            name="title"
            id="title"
            placeholder=" "
            class="block pb-2 pt-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            :class="[
              showErrors && !postToUpdate.title
                ? 'border-red-500 dark:border-red-400'
                : 'border-gray-300 dark:border-gray-600',
            ]"
            :value="postToUpdate.title"
            @blur="postToUpdate.title = ($event.target as HTMLInputElement).value"
          />
          <label
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 bg-white dark:bg-gray-800"
            for="title"
            :class="[
              showErrors && !postToUpdate.title ? 'text-red-500 dark:text-red-400' : 'text-gray-500 dark:text-gray-400',
            ]"
          >
            Headline (Character max. 100)<span class="text-red-500 dark:text-red-400">*</span>
          </label>
        </div>

        <div class="grid grid-row-2 lg:grid-cols-[repeat(2,minmax(0,1fr))] gap-4">
          <!-- Description -->
          <div class="lg:col-span-2">
            <label class="block mb-2" for="description">
              Post body <span class="text-red-500 dark:text-red-400">*</span>
            </label>
            <TextEditor
              id="description"
              placeholder="Write text here ..."
              :value="postToUpdate.description"
              :isInvalid="showErrors && !postToUpdate.description"
              @update:value="postToUpdate.description = $event"
            />
          </div>
          <!-- Vessel -->
          <div>
            <label class="block mb-2" for="vessel-selector">
              Select a yacht from your fleet <span class="text-red-500 dark:text-red-400">*</span>
            </label>
            <OnClickOutside @trigger="showVesselList = false" class="relative">
              <SolidShip
                class="absolute top-3.5 left-3 w-4"
                :class="postToUpdate.vesselName ? 'text-gray-900 dark:text-white' : 'text-gray-500 dark:text-gray-400'"
              />
              <button
                id="vessel-selector"
                type="button"
                class="border w-full justify-between focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm pl-10 pr-1 py-2.5 text-center inline-flex items-center"
                :class="
                  showErrors && !postToUpdate.vesselUri
                    ? 'bg-red-600 bg-opacity-10 border-red-500 dark:border-red-400'
                    : 'bg-gray-50 dark:bg-gray-700 border-gray-300 dark:border-gray-600'
                "
                @click.stop="showVesselList = !showVesselList"
              >
                <span
                  class="overflow-hidden whitespace-nowrap text-ellipsis block"
                  :class="
                    postToUpdate.vesselName ? 'text-gray-900 dark:text-white' : 'text-gray-500 dark:text-gray-400'
                  "
                >
                  {{ postToUpdate.vesselName || 'Select a yacht' }}
                </span>
                <SolidChevronDown class="w-5 text-gray-500 dark:text-gray-400" />
              </button>

              <!-- Dropdown menu -->
              <div
                v-show="showVesselList"
                class="absolute mt-1 cursor-pointer z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
              >
                <ul class="py-2 text-sm text-gray-900 dark:text-white max-h-48 overflow-auto">
                  <li v-for="(vessel, index) in props.vesselList" :key="`${index}-${vessel.uri}`">
                    <a
                      class="whitespace-nowrap block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                      @click="onSelectVessel(vessel)"
                    >
                      {{ vessel.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </OnClickOutside>
          </div>
          <!-- location -->
          <div>
            <label class="block mb-2" for="location">Special location</label>
            <div class="relative">
              <!-- search input -->
              <div class="relative h-fit">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <SolidPin
                    class="w-4"
                    :class="locationSearchText ? 'text-gray-900 dark:text-white' : 'text-gray-500 dark:text-gray-400'"
                  />
                </div>
                <input
                  type="text"
                  id="location"
                  autocomplete="off"
                  class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-10 py-2.5 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 dark:border-gray-600 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-700"
                  placeholder="Special location"
                  v-model="locationSearchText"
                  @input="emit('fetch:places', ($event.target as HTMLInputElement).value)"
                />
                <div
                  v-if="locationSearchText"
                  class="absolute inset-y-0 right-0 flex items-center pr-3.5 cursor-pointer"
                  @click="removeLocation"
                >
                  <SolidX class="w-4 text-gray-500 dark:text-gray-400 cursor-pointer" />
                </div>
              </div>
              <!-- search results -->
              <div
                v-if="showLocationSearchResults"
                class="w-full absolute bg-white dark:bg-gray-700 min-w-[16rem] rounded-xl shadow-xl mt-1 max-h-48 overflow-y-auto py-4 z-10"
              >
                <ul>
                  <li
                    v-for="locationResult of props.locationSearchResults"
                    :key="`${locationResult.coordinates.latitude}${locationResult.coordinates.longitude}`"
                    class="py-1 pr-4 flex cursor-pointer transition-opacity items-center gap-x-1 hover:bg-gray-200 hover:dark:bg-gray-500"
                    :class="locationResult.uri ? 'pl-1' : 'pl-7'"
                    @click.stop="updateLocation(locationResult)"
                  >
                    <span
                      v-if="locationResult.uri"
                      class="w-3 h-3 bg-primary-600 border-2 border-white rounded-full mx-1"
                    ></span>
                    <span class="w-full leading-tight font-semibold line-clamp-1 text-start">
                      {{ locationResult.name }}
                      <span v-if="locationResult.country" class="font-normal">, {{ locationResult.country }} </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- Special period -->
          <div>
            <label class="block mb-2" for="startDate">
              Special period
              <span class="text-red-500 dark:text-red-400">*</span>
            </label>
            <!-- Datepicker -->
            <DateRangePicker
              end-date-placeholder="End date"
              start-date-placeholder="Start date"
              orientation="top"
              :show-to-text="true"
              :end-date="getEndDate(postToUpdate.eventInterval)"
              :start-date="getStartDate(postToUpdate.eventInterval)"
              :hasError="showErrors && !postToUpdate.eventInterval"
              @update:start:and:end="postToUpdate.eventInterval = updateInterval($event)"
            />
          </div>

          <!-- Forwarding mail -->
          <div>
            <label class="block mb-2" for="email">
              Forwarding responses to: <span class="text-red-500 dark:text-red-400">*</span>
            </label>
            <div class="relative h-fit">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <SolidMail
                  class="w-4"
                  :class="emailInput ? 'text-gray-900 dark:text-white' : 'text-gray-500 dark:text-gray-400'"
                />
              </div>

              <input
                id="email"
                type="text"
                class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-10 py-2.5 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                :class="
                  (showErrors && !emailInput) || !emailValid
                    ? 'border-red-500 dark:border-red-400 bg-red-600 bg-opacity-10'
                    : 'border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700'
                "
                placeholder="Add email address"
                :value="emailInput"
                @blur="updateEmail($event)"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Modal actions -->
      <div class="p-6 inline-flex justify-end gap-x-2">
        <button
          type="button"
          id="cancel-modal-button"
          class="text-gray-500 dark:text-gray-200 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-500 dark:hover:bg-gray-600 focus:outline-none dark:focus:ring-gray-600 border border-gray-200 dark:border-gray-400"
          @click="emit('close:modal')"
        >
          Cancel
        </button>
        <button
          v-if="props.post"
          type="button"
          id="update-modal-button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-blue-400 disabled:dark:bg-blue-500"
          @click.stop="updatePost"
        >
          <Spinner v-if="isLoading" class="w-4 h-4" />
          <p v-else>Update Post</p>
        </button>
        <button
          v-else
          type="button"
          id="add-modal-button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-blue-400 disabled:dark:bg-blue-500"
          @click.stop="createPost"
        >
          <Spinner v-if="isLoading" class="w-4 h-4" />
          <p v-else>Post</p>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.dark .datepicker .datepicker-picker {
  border: 1px solid rgb(75 85 99); // border-gray-600
}

.datepicker-dropdown {
  @apply pb-2; // symetry with padding top + is required for when orientation is top
}

.datepicker {
  z-index: 50;

  .datepicker-picker {
    border: 1px solid rgb(209 213 219); // border-gray-300
  }

  .datepicker-cell {
    @apply leading-9;
  }

  .range-start {
    @apply rounded-tl-lg rounded-bl-none;
  }

  .range-end {
    @apply rounded-br-lg rounded-tr-none;
  }
}

/* for ios mobile to avoid bottom navbar */
@supports (-webkit-touch-callout: none) {
  .max-h-screen {
    max-height: -webkit-fill-available;
  }
}
</style>
