<script setup lang="ts">
import { GrowthBook } from '@growthbook/growthbook'
import algoliasearch from 'algoliasearch/lite'
import debounce from 'lodash.debounce'
import { Ref, inject, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import ListTable from '@ankor-io/blocks/layouts/ListTable.vue'
import ListTableAccordion from '@ankor-io/blocks/layouts/ListTableAccordion.vue'
import { BookingManagerVesselPairing, MMKYacht } from '@ankor-io/common/booking-manager/types'
import { VesselIndexItem } from '@ankor-io/common/index/VesselIndexItem'
import { UUID } from '@ankor-io/common/lang/uuid'
import { URIBuilder } from '@ankor-io/common/uri/Uri'
import { HiSolidMagnifyingGlass } from '@ankor-io/icons/hi_solid'
import { AuthenticationContext } from '@ankor-io/radar/src/iam/types'

import Spinner from '@/components/Spinner.vue'
import VesselCard from '@/components/VesselCard.vue'
import VisibleObserver from '@/components/VisibleObserver.vue'
import { COMMODORE_20250218_MMK_INTEGRATION } from '@/utils/growthbook/constants'
import { AttributesToClaimVessel } from '@/views/claim-vessel/types'

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const growthbook: GrowthBook = inject('growthbook')!
const searchClientCredentials = ref<{ key: string; appId: string }>({ key: '', appId: 'placeholder' })
const loadingBookingManagerVessels: Ref<boolean> = ref(false)
const bookingManagerVessels: Ref<MMKYacht[]> = ref([])

onBeforeMount(async () => {
  const searchKey = await getSearchKey(authenticationContext!)
  if (searchKey.ok) {
    searchClientCredentials.value = await searchKey.json()
  } else {
    console.error('Failed to get search key')
  }

  searchValue.value = route.query.q?.toString() || undefined
})

onMounted(async () => {
  if (growthbook.isOn(COMMODORE_20250218_MMK_INTEGRATION)) {
    // Mock data
    loadingBookingManagerVessels.value = true
    const resp = await fetch('/api/booking-manager/vessel/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${await authenticationContext.getToken()}`,
      },
    })
    if (resp.ok) {
      const response = await resp.json()
      bookingManagerVessels.value = Array.isArray(response) ? response.filter((item) => !item.paired) : []
    }
    loadingBookingManagerVessels.value = false
  }
})

async function getSearchKey(authenticationContext: AuthenticationContext): Promise<Response> {
  return fetch('/api/search/claim', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })
}

const emit = defineEmits<{
  (e: 'claim:vessel', attributesToClaimVessel: AttributesToClaimVessel): void
  (e: 'update:value', event: { field: string; _event: Event }): void
}>()

const route = useRoute()
const searchValue: Ref<string | undefined> = ref(undefined)

// Pass in from the search box, the ais slot function refine and value value for the sole purpose of debouncing the search
const debounceRefiner = debounce((refine: any, value: any, $router) => {
  refine(value)
  searchValue.value = value
  $router.push({ query: { q: value } })
}, 400)

const claimExistingVessel = (item: VesselIndexItem) => {
  const vessel_id = URIBuilder.fromString(item.uri).entityId

  emit('claim:vessel', {
    vessel_id,
    vessel_existing_uri: item.uri,
    vessel_name: item.line_1,
    vessel_length: item.line_2,
    vessel_built_year: String(item.line_5),
    vessel_builder: String(item.line_6),
    vessel_hero: item.hero,
    email: authenticationContext?.getUser().email ?? '',
    user_id: authenticationContext?.getUser().id ?? '',
  })
}

const claimBookingManagerVessel = (vessel: BookingManagerVesselPairing) => {
  console.log({ vessel })
  const vessel_claim = {
    vessel_id: vessel.uuid,
    vessel_existing_uri: vessel.bookingManagerUri,
    vessel_name: vessel.name,
    vessel_length: vessel.length ? String(vessel.length) : '',
    vessel_built_year: vessel.year ? String(vessel.year) : '',
    vessel_builder: vessel.model ? vessel.model : '',
    vessel_hero: vessel.images && Array.isArray(vessel.images) ? vessel.images[0]?.url : '',
    email: authenticationContext?.getUser().email ?? '',
    user_id: authenticationContext?.getUser().id ?? '',
  }
  console.log({ vessel_claim })
  emit('claim:vessel', vessel_claim)
}
</script>
<template>
  <div v-if="growthbook.isOn(COMMODORE_20250218_MMK_INTEGRATION)" class="py-6">
    <Spinner v-if="loadingBookingManagerVessels" class="size-7 mr-auto ml-auto" />
    <template v-else>
      <div v-if="bookingManagerVessels.length" class="max-h-96 overflow-y-auto">
        <ListTable
          rowKey="id"
          class="hidden md:table w-full table-fixed"
          :columns="[
            {
              label: 'Name',
              field: 'name',
              type: 'string',
              classes: { th: 'w-32' },
            },
            {
              label: 'Length',
              field: 'length',
              type: 'number',
              classes: { th: 'w-24', td: 'whitespace-nowrap' },
            },
            {
              label: 'Built Year',
              field: 'year',
              type: 'number',
              classes: { th: 'w-24', td: 'whitespace-nowrap' },
            },
            {
              label: 'Cabins',
              field: 'cabins',
              type: 'number',
              classes: { th: 'w-24', td: 'whitespace-nowrap' },
            },
            {
              label: '',
              field: 'claim',
              type: 'claim',
              classes: { th: 'w-24' },
            },
          ]"
          :rows="bookingManagerVessels"
          :classes="{ thead: 'top-0', tr: 'cursor-pointer' }"
          @rowClick=""
        >
          <!-- column: Claim -->
          <template #claim="{ row }: { row: BookingManagerVesselPairing }">
            <button
              type="button"
              class="mr-auto ml-auto flex transition-colors items-center font-medium rounded-lg text-sm px-5 py-2.5 text-center border border-gray-200 dark:border-gray-600 focus:ring-4 focus:outline-none text-gray-900 dark:text-white bg-white dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 focus:bg-gray-300 dark:focus:bg-gray-700"
              @click.stop="claimBookingManagerVessel(row)"
            >
              Claim
            </button>
          </template>
        </ListTable>
        <ListTableAccordion
          rowKey="id"
          class="block md:hidden"
          :columns="[
            {
              label: 'Name',
              field: 'name',
              type: 'string',
              classes: {
                th: 'w-full',
                td: 'w-[calc(100vw-16.5rem)] sm:w-[calc(100vw-17.7rem)] font-semibold truncate',
              },
            },
            {
              label: 'Length',
              field: 'length',
              type: 'number',
              classes: { th: 'w-24', td: 'w-24' },
            },
            {
              label: '',
              field: 'claim',
              type: 'claim',
              classes: { th: 'w-32' },
            },
          ]"
          :rows="bookingManagerVessels"
          :classes="{ thead: 'top-0', tr: 'cursor-pointer' }"
          @rowClick="console.log('TODO: what should a row click do?')"
        >
          <template #length="{ row }">
            <span>{{ row.length ? `${row.length}m` : '' }}</span>
          </template>
          <template #claim="{ row }: { row: MMKYacht }">
            <button
              type="button"
              class="flex transition-colors items-center font-medium rounded-lg text-sm p-2.5 text-center border border-gray-200 dark:border-gray-600 focus:ring-4 focus:outline-none text-gray-900 dark:text-white bg-white dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 focus:bg-gray-300 dark:focus:bg-gray-700"
              @click.stop="console.log('TODO: claim vessel', row.name, 'with ID', row.id)"
            >
              Claim
            </button>
          </template>
          <!-- Accordion content -->
          <template #accordion-content="{ row }">
            <template
              v-for="col of [
                {
                  label: 'Name',
                  field: 'name',
                  type: 'string',
                  classes: { th: 'w-32' },
                },
                {
                  label: 'Length',
                  field: 'length',
                  type: 'number',
                  classes: { th: 'w-24', td: 'whitespace-nowrap' },
                },
                {
                  label: 'Built Year',
                  field: 'year',
                  type: 'number',
                  classes: { th: 'w-24', td: 'whitespace-nowrap' },
                },
                {
                  label: 'Cabins',
                  field: 'cabins',
                  type: 'number',
                  classes: { th: 'w-24', td: 'whitespace-nowrap' },
                },
              ]"
              :key="col.label"
            >
              <div v-if="row[col.field]" class="flex flex-col gap-y-1">
                <span class="text-xs font-semibold text-gray-700 dark:text-gray-300">
                  {{ col.label }}
                </span>
                <span class="text-sm text-gray-500 dark:text-gray-400">
                  {{ row[col.field] }}
                </span>
              </div>
            </template>
          </template>
        </ListTableAccordion>
      </div>
    </template>
  </div>

  <ais-instant-search
    v-if="searchClientCredentials.key !== ''"
    index-name="commodore_claim"
    class="flex flex-col gap-y-2.5 sm:gap-y-8 p-4"
    :search-client="algoliasearch(searchClientCredentials.appId, searchClientCredentials.key)"
  >
    <ais-search-box
      :value="searchValue"
      :class-names="{
        'ais-SearchBox': 'w-full flex',
      }"
    >
      <template v-slot="{ isSearchStalled, refine }">
        <div class="flex flex-col sm:flex-row items-center mx-auto gap-2 sm:gap-x-4 max-w-4xl w-full">
          <div class="relative flex-1 w-full sm:w-auto">
            <div class="absolute inset-y-0 flex items-center pl-3.5 pointer-events-none">
              <HiSolidMagnifyingGlass class="w-5 h-5 fill-primary-600 stroke-2" />
            </div>
            <input
              type="search"
              id="default-search"
              class="px-2.5 py-2 sm:py-3.5 w-full text-gray-900 bg-transparent rounded-lg border-1 pl-10 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              :value="searchValue"
              @input="debounceRefiner(refine, ($event as any).currentTarget.value, $router)"
            />
            <label
              for="default-search"
              class="absolute text-sm cursor-text text-gray-500 dark:text-gray-400 duration-300 transform left-10 -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 peer-focus:left-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              :class="{ '!left-1': searchValue }"
            >
              Search for your Yacht...
            </label>
          </div>

          <button
            type="button"
            class="self-start text-xs sm:text-base text-gray-900 bg-transparent border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg p-3 sm:px-6 sm:py-3.5 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            @click="
              emit('claim:vessel', {
                vessel_id: UUID.timeBased(),
                email: authenticationContext?.getUser().email ?? '',
                user_id: authenticationContext?.getUser().id ?? '',
              })
            "
          >
            Your Yacht is not listed?
          </button>
          <span class="text-gray-900 dark:text-white" :hidden="!isSearchStalled">Loading...</span>
        </div>
      </template>
    </ais-search-box>

    <ais-infinite-hits>
      <template v-slot="{ items, refineNext, isLastPage, results }">
        <p v-if="searchValue" class="mb-2 text-gray-500 dark:text-gray-400">{{ results.nbHits }} Results</p>
        <div
          v-if="searchValue"
          class="dark:bg-gray-900 flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-2.5 sm:gap-4"
        >
          <template v-for="item in (items as VesselIndexItem[])" :key="`yacht-card-${item.uri}`">
            <VesselCard
              card-type="claim"
              :hero="item.hero"
              :uri="item.uri"
              :line_1="item.line_1"
              :line_2="item.line_2"
              :line_3="item.line_3"
              :line_4="item.line_4"
              :line_5="String(item.line_5)"
              :line_6="String(item.line_6)"
              @vessel:claim="claimExistingVessel(item)"
            />
          </template>
        </div>
        <p v-else class="text-gray-500 dark:text-gray-400 text-center">Start searching to find your yacht</p>

        <template v-if="!isLastPage && $route.query.q">
          <VisibleObserver @visible="refineNext" />
        </template>
        <div v-if="isLastPage && $route.query.q" class="mt-8 text-center text-sm">
          You have reached the end of the results!
        </div>
      </template>
    </ais-infinite-hits>
  </ais-instant-search>
</template>
