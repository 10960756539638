<script setup lang="ts">
import { Ref, inject, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import VesselTableView from '@ankor-io/blocks/components/VesselTableView/VesselTableView.vue'
import { VesselIndexItem } from '@ankor-io/common/index/VesselIndexItem'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { Trip } from '@ankor-io/common/trip/Trip'
import { formatLengthWithSpaceSeparator } from '@ankor-io/common/vessel/Formatter'
import { SortableFields } from '@ankor-io/common/vessel/sort'
import { Variant } from '@ankor-io/common/vessel/types'
import { VesselVisibility } from '@ankor-io/common/vessel/types'
import { LineArtMeasuringtape } from '@ankor-io/icons/line_art'
import {
  OutlineAddYacht,
  OutlineCabinDoorIcon,
  OutlineCalendarThin,
  OutlinePlus,
  OutlineUserGroupThin,
} from '@ankor-io/icons/outline'

import ButtonVue from '@/components/Button.vue'
import PricingInCard from '@/components/PricingInCard.vue'
import VesselCard from '@/components/VesselCard.vue'
import VisibleObserver from '@/components/VisibleObserver.vue'
import BrochureGeneratedModalContent from '@/components/modal-content/BrochureGeneratedModalContent.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { useModal } from '@/hooks/modal/useModal'
import { useTable } from '@/hooks/table/useTable'
import { AuthenticationContext } from '@/iam/types'
import { getRouterQuery } from '@/utils/router-query'
import Timeline from '@/views/Timeline.vue'
import { SearchView } from '@/views/search/types'

const props = defineProps<{
  items: VesselIndexItem[]
  refineNext: () => void
  isLastPage: boolean
  query: string
  rates: { [key: string]: string }
}>()

const emit = defineEmits<{
  (e: 'sort:vessel:by', field: SortableFields): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const { tableColumnOptions, setTableColumnOptions } = useTable()

const hitsElementRef: Ref<any> = ref(null)
const hitsElementScrollPosition: Ref<number> = ref(0)
const timelineRows: Ref<Record<string, number>> = ref({})
const renderTimeline: Ref<boolean> = ref(true)
const trip: Ref<Trip | null> = ref(null)
const generatingBrochureForUri: Ref<string> = ref('')

const { updateModalState } = useModal()
const isModalOpen: Ref<boolean> = ref(false)

const onScrollTimeline = (scrollEvent: Event) => {
  if (hitsElementRef.value) {
    hitsElementRef.value.scrollTop = (scrollEvent.target as HTMLElement).scrollTop
  }
}

const onScrollHits = (scrollEvent: Event) => {
  hitsElementScrollPosition.value = (scrollEvent.target as HTMLElement).scrollTop
}

const mapItemsToVesselProps = (items: any) =>
  items.map((hit: any) => {
    return {
      uri: hit.uri,
      label: hit.vessel.blueprint.name,
      variants: hit.vessel.variants.map((variant: Variant) => {
        return { label: variant.label, id: variant.id, turnaround: variant.turnaround }
      }),
    }
  })

/**
 * Filter out variants that are invalid for pricing
 * @param variants
 */
const getValidVariants = (variants: Variant[]) => {
  return variants.filter(
    (variant: Variant) =>
      variant.active && variant.pricing?.total && variant.pricing.total > 0 && variant.pricing?.currency,
  )
}

/**
 * Format the managed by company string
 * @param companyNames
 */
const formatManagedBy = (companyNames: string[] = []) => {
  if (companyNames.length === 0) {
    return ''
  }
  if (companyNames.length === 1) {
    return `${companyNames[0]}`
  }
  return `${companyNames.slice(0, -1).join(', ')} and ${companyNames.slice(-1)}`
}

const getStringLabel = (attribute: string | string[]): string => {
  if (Array.isArray(attribute)) {
    return attribute.join(', ')
  }
  return attribute
}

const updateCategoryOption = (index: number): void => {
  setTableColumnOptions(
    tableColumnOptions.value.map((option, mapIndex) =>
      mapIndex === index ? { ...option, value: !option.value } : option,
    ),
  )
}

const openGeneratedBrochureModal = () => {
  isModalOpen.value = true
  updateModalState(true)
}

const generateBrochure = async (vesselUri: string) => {
  generatingBrochureForUri.value = vesselUri
  const token = await authenticationContext.getToken()

  const res = await fetch(`/api/vessel/brochure/${vesselUri}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    trip.value = await res.json()
    openGeneratedBrochureModal()
  }
  generatingBrochureForUri.value = ''
}
</script>
<template>
  <!-- Cards View -->
  <div v-show="getRouterQuery('view') === SearchView.CARDS">
    <!-- This empty state also accounts for the when no results are found from search -->
    <div v-if="!items.length" class="flex flex-col items-center mt-7 gap-y-2.5">
      <OutlineAddYacht class="w-12 h-12 text-gray-500 dark:text-gray-400" />
      <h4 class="font-bold text-black dark:text-white">No Yachts</h4>
      <p class="w-64 text-center">Get started by adding any yacht in your CA fleet.</p>
      <!-- claim  -->
      <RouterLink :to="{ name: 'claim-vessel', params: { step: 'find' } }">
        <ButtonVue id="claim-a-yacht-empty-state" name="Claim a yacht" position="prefix" isPrimary>
          <OutlinePlus class="w-4 h-4" />
        </ButtonVue>
      </RouterLink>
    </div>

    <!-- Search results -->
    <div
      v-else
      class="dark:bg-gray-900 flex-1 grid grid-cols-1 @md:grid-cols-2 @lg:grid-cols-3 @xl:grid-cols-4 gap-5 pb-4 pr-4 max-w-8xl mx-auto"
    >
      <RouterLink
        v-for="item in props.items"
        class="cursor-pointer transition-all duration-300 hover:scale-105"
        :to="{ name: 'view-vessel', params: { uri: encodeURIComponent(item.uri) } }"
        :key="`yacht-card-${item.uri}`"
      >
        <VesselCard
          card-type="view"
          :hero="item.hero"
          :uri="item.uri"
          :is-inactive="item.vessel.visibility === VesselVisibility.PRIVATE"
          :line_1="item.line_1"
          :line_2="item.line_2"
          :line_3="item.line_3"
          :line_4="item.line_4"
          :line_5="String(item.line_5)"
          :line_6="String(item.line_6)"
          :line_7="item.line_7"
          :variants="item.vessel.variants"
          :rates="props.rates"
        />
      </RouterLink>

      <template v-if="!props.isLastPage && getRouterQuery('view') === SearchView.CARDS">
        <VisibleObserver @visible="props.refineNext" />
      </template>
      <div v-else class="mt-8 text-center text-sm">You have reached the end of the results!</div>
    </div>
  </div>

  <!-- Timeline View -->
  <div v-show="getRouterQuery('view') !== SearchView.CARDS && getRouterQuery('view') !== SearchView.TABLE">
    <!-- This empty state also accounts for the when no results are found from search -->
    <div v-if="!items.length" class="flex flex-col items-center mt-7 gap-y-2.5">
      <OutlineAddYacht class="w-12 h-12 text-gray-500 dark:text-gray-400" />
      <h4 class="font-bold text-black dark:text-white">No Yachts</h4>
      <p class="w-64 text-center">Get started by adding any yacht in your CA fleet.</p>
      <!-- claim  -->
      <RouterLink :to="{ name: 'claim-vessel', params: { step: 'find' } }">
        <ButtonVue id="claim-a-yacht-empty-state" name="Claim a yacht" position="prefix" isPrimary>
          <OutlinePlus class="w-4 h-4" />
        </ButtonVue>
      </RouterLink>
    </div>

    <div v-else class="relative flex overflow-hidden h-full border-t border-gray-200 dark:border-gray-700">
      <!-- hits and actions -->
      <div
        class="sticky left-0 pb-3.5 z-20 h-[calc(100vh-10.4rem)] overflow-y-auto overflow-x-hidden scrollbar-hide bg-white dark:bg-gray-900 border-l border-gray-200 dark:border-gray-700 min-w-[11rem] @md:min-w-[32rem]"
        id="vessel-list"
        :ref="(el) => (hitsElementRef = el)"
        @scroll="onScrollHits"
      >
        <!-- Placeholder for actions -->
        <div
          class="h-28 sticky snap-y top-0 grid border-b border-r z-10 border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900"
        ></div>

        <!-- hits -->
        <ul>
          <li
            v-for="hit in items"
            class="group relative w-full items-center p-4 min-h-[15rem] @md:min-h-[11.25rem] transition-all duration-300 border-b border-r border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900"
            :key="hit.objectID"
            :style="{ height: `${timelineRows[hit.uri]}px` }"
          >
            <RouterLink
              class="relative w-full flex flex-col @md:flex-row gap-y-4 @md:gap-y-0 flex-nowrap group-hover:scale-105 duration-300 cursor-pointer"
              :to="{ name: 'view-vessel', params: { uri: encodeURIComponent(hit.uri) } }"
            >
              <div class="flex flex-col w-full @md:w-52 @md:min-w-[13rem] h-[5.75rem] @md:h-[9.25rem]">
                <AssetViewer
                  v-if="hit.hero"
                  class="object-cover h-full rounded-lg"
                  :url="`/media/${replacePathToMediaUris(hit.uri, hit.hero)[0]}`"
                  :width-descriptors="['320w']"
                />
              </div>
              <div class="flex flex-col items-start @md:pl-4 leading-normal overflow-hidden">
                <div class="w-full flex justify-between gap-x-2">
                  <h3 class="uppercase leading-tight line-clamp-1 font-bold">{{ hit.line_1 }}</h3>
                  <!-- The requirements is to only show the badge for inactive vessels -->
                  <span
                    v-if="hit.vessel.visibility === VesselVisibility.PRIVATE"
                    class="h-fit text-xs font-medium px-2.5 py-0.5 rounded bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300"
                  >
                    Inactive
                  </span>
                </div>
                <div class="line-clamp-1 text-[0.625rem] text-gray-500 dark:text-gray-400 sm:text-xs">
                  {{ formatManagedBy(hit.line_7) }}
                </div>
                <div
                  v-if="hit.line_2 || hit.line_3 || hit.line_4 || hit.line_5"
                  class="flex flex-wrap gap-x-2 text-[0.625rem] sm:text-xs mt-2 text-gray-500 dark:text-gray-400 stroke-gray-500 dark:stroke-gray-400"
                >
                  <div v-if="hit.line_2" class="flex items-center gap-x-2">
                    <LineArtMeasuringtape class="h-4 w-4 shrink-0" />
                    {{ formatLengthWithSpaceSeparator(hit.line_2) }}
                  </div>
                  <div v-if="hit.line_3" class="flex items-center gap-x-2">
                    <OutlineCabinDoorIcon class="h-4 w-4 shrink-0" />
                    {{ getStringLabel(hit.line_3) }}
                  </div>
                  <div v-if="hit.line_5" class="flex items-center gap-x-2">
                    <OutlineCalendarThin class="h-4 w-4 shrink-0" />
                    {{ getStringLabel(String(hit.line_5)) }}
                  </div>
                  <div v-if="hit.line_4" class="flex items-center gap-x-2">
                    <OutlineUserGroupThin class="h-4 w-4 shrink-0 stroke-1" />
                    {{ getStringLabel(hit.line_4) }}
                  </div>
                </div>

                <PricingInCard
                  v-if="hit.vessel.variants?.length"
                  class="max-w-[11rem] flex-grow mt-2 @md:mt-4"
                  :rates="props.rates"
                  :isTimeline="true"
                  :variants="getValidVariants(hit.vessel.variants)"
                />
              </div>
            </RouterLink>
          </li>
        </ul>

        <!-- claim  -->
        <div class="h-20 flex justify-center items-center border-b border-r border-gray-200 dark:border-gray-600">
          <RouterLink
            class="py-2.5 px-5 me-2 inline-flex items-center gap-x-1 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            :to="{ name: 'claim-vessel', params: { step: 'find' } }"
          >
            <OutlinePlus class="w-4 h-4" />
            Claim Yacht
          </RouterLink>
        </div>

        <template
          v-if="
            !props.isLastPage &&
            getRouterQuery('view') !== SearchView.CARDS &&
            getRouterQuery('view') !== SearchView.TABLE
          "
        >
          <VisibleObserver @visible="props.refineNext" />
        </template>
        <div v-else class="mt-8 text-center text-sm">You have reached the end of the results!</div>
      </div>

      <!-- event timeline -->
      <Timeline
        v-if="renderTimeline"
        :vessels="mapItemsToVesselProps(items)"
        :scrollPosition="hitsElementScrollPosition"
        @scroll="onScrollTimeline"
        @update:timeline:rows:height="(ev: Record<string, number>) => timelineRows = ev"
      />
    </div>
  </div>

  <!-- Table View -->
  <div v-show="getRouterQuery('view') === SearchView.TABLE">
    <!-- This empty state also accounts for the when no results are found from search -->
    <div v-if="!items.length" class="flex flex-col items-center mt-7 gap-y-2.5">
      <OutlineAddYacht class="w-12 h-12 text-gray-500 dark:text-gray-400" />
      <h4 class="font-bold text-black dark:text-white">No Yachts</h4>
      <p class="w-64 text-center">Get started by adding any yacht in your CA fleet.</p>
      <!-- claim  -->
      <RouterLink :to="{ name: 'claim-vessel', params: { step: 'find' } }">
        <ButtonVue id="claim-a-yacht-empty-state" name="Claim a yacht" position="prefix" isPrimary>
          <OutlinePlus class="w-4 h-4" />
        </ButtonVue>
      </RouterLink>
    </div>

    <VesselTableView
      v-else
      v-show="getRouterQuery('view') === SearchView.TABLE"
      :vessels="props.items"
      :rates="props.rates"
      :categoryOptions="tableColumnOptions"
      :generating-brochure-for-uri="generatingBrochureForUri"
      @sort:by="emit('sort:vessel:by', $event)"
      @update:category:show="updateCategoryOption($event)"
      @copy:brochure="generateBrochure($event)"
    >
      <template #visibility-observer>
        <template v-if="!isLastPage">
          <VisibleObserver @visible="refineNext" />
        </template>
        <div v-else class="mt-8 text-center text-sm">You have reached the end of the results!</div>
      </template>
    </VesselTableView>

    <ModalContentWrapper v-if="trip">
      <BrochureGeneratedModalContent :trip="trip"></BrochureGeneratedModalContent>
    </ModalContentWrapper>
  </div>
</template>
