<script setup lang="ts">
import debounce from 'lodash.debounce'
import { Ref, inject, ref } from 'vue'

import { Place } from '@ankor-io/common/vessel/types'
import { SolidPin, SolidX } from '@ankor-io/icons/solid'

import { AuthenticationContext } from '@/iam/types'

const props = defineProps<{
  placeholder: string
  place?: Place
  validationError?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:place', value: Place | undefined): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const searchText: Ref<string> = ref(props.place?.name || '')
const locationSearchResults: Ref<Place[]> = ref([])

/**
 * fetch places from the server
 * @param searchText text to search for
 * @returns list of places
 */
const fetchPlaces = async (searchText: string): Promise<Place[]> => {
  if (!searchText) {
    return Promise.resolve([])
  }

  const response = await fetch('/api/geocode/forward', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify({ searchText }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error('Error fetching places', err)
      return Promise.resolve([])
    })

  return (response as { places: Place[] }).places
}

/**
 * Update search text and fetch places
 * @param $event input event
 */
const debounceSearchResults = debounce(async () => {
  locationSearchResults.value = await fetchPlaces(searchText.value)
}, 400)

const removePlace = () => {
  searchText.value = ''
  locationSearchResults.value = []
  emit('update:place', undefined)
}

const updatePlace = (place: Place) => {
  searchText.value = place.name
  locationSearchResults.value = []
  emit('update:place', place)
}
</script>

<template>
  <div class="relative">
    <!-- search input -->
    <div class="relative h-fit">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
        <SolidPin
          class="w-4"
          :class="props.validationError ? 'text-red-500 dark:text-red-400' : 'text-gray-500 dark:text-gray-400'"
        />
      </div>
      <input
        type="text"
        class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-10 py-2.5 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :class="
          props.validationError
            ? 'border-red-500 dark:border-red-400 placeholder-red-500 dark:placeholder-red-400 bg-red-600 bg-opacity-10'
            : 'border-gray-300 dark:border-gray-500 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-600'
        "
        :placeholder="props.placeholder"
        :value="searchText"
        @input="($event) => {
          searchText = ($event.target as HTMLInputElement).value
          debounceSearchResults()
          }"
      />
      <div
        v-if="searchText"
        class="absolute inset-y-0 right-0 flex items-center pr-3.5 cursor-pointer"
        @click="removePlace"
      >
        <SolidX class="w-4 text-gray-500 dark:text-gray-400 cursor-pointer" />
      </div>
    </div>
    <!-- search results -->
    <div
      v-if="locationSearchResults.length > 0"
      class="w-full absolute bg-white dark:bg-gray-700 min-w-[16rem] rounded-xl shadow-xl max-h-48 overflow-y-auto py-4 z-10"
    >
      <ul>
        <li
          v-for="locationResult of locationSearchResults"
          :key="`${locationResult.coordinates.latitude}${locationResult.coordinates.longitude}`"
          class="py-1 pr-4 flex cursor-pointer transition-opacity items-center gap-x-1 hover:bg-gray-200 hover:dark:bg-gray-500 hover:cursor-pointer"
          :class="locationResult.uri ? 'pl-1' : 'pl-7'"
          @click.stop="updatePlace(locationResult)"
        >
          <span v-if="locationResult.uri" class="w-3 h-3 bg-primary-600 border-2 border-white rounded-full mx-1"></span>
          <span class="w-full leading-tight font-semibold line-clamp-1 text-start">
            {{ locationResult.name }}
            <span v-if="locationResult.country" class="font-normal">, {{ locationResult.country }} </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
