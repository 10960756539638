import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#clip0_8296_3675)" }, [
      _createElementVNode("path", {
        fill: "currentColor",
        "fill-rule": "evenodd",
        d: "M12 21V22H14V21H18.5C20.433 21 22 19.433 22 17.5C22 15.567 20.433 14 18.5 14H14V10H15.5C17.433 10 19 8.433 19 6.5C19 4.567 17.433 3 15.5 3H14V2H12V3V5V8V10V14V16V19V21ZM14 19H18.5C19.3284 19 20 18.3284 20 17.5C20 16.6716 19.3284 16 18.5 16H14V19ZM14 8H15.5C16.3284 8 17 7.32843 17 6.5C17 5.67157 16.3284 5 15.5 5H14V8ZM10 5H5.5C4.67157 5 4 5.67157 4 6.5C4 7.32843 4.67157 8 5.5 8H10V10H5.5C3.567 10 2 8.433 2 6.5C2 4.567 3.567 3 5.5 3H10V5ZM10 16H8.5C7.67157 16 7 16.6716 7 17.5C7 18.3284 7.67157 19 8.5 19H10V21H8.5C6.567 21 5 19.433 5 17.5C5 15.567 6.567 14 8.5 14H10V16Z",
        "clip-rule": "evenodd"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "clip0_8296_3675" }, [
        _createElementVNode("rect", {
          width: "24",
          height: "24",
          fill: "white"
        })
      ])
    ])
  ]))
}