import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M8.75161 17.6484C8.52665 17.4234 8.40027 17.1182 8.40027 16.8C8.40027 16.4818 8.52665 16.1767 8.75161 15.9516L12.7032 12L8.75161 8.04842C8.53302 7.8221 8.41207 7.51898 8.4148 7.20434C8.41754 6.88971 8.54374 6.58873 8.76623 6.36624C8.98872 6.14375 9.2897 6.01755 9.60433 6.01482C9.91897 6.01208 10.2221 6.13304 10.4484 6.35163L15.2484 11.1516C15.4734 11.3767 15.5998 11.6818 15.5998 12C15.5998 12.3182 15.4734 12.6234 15.2484 12.8484L10.4484 17.6484C10.2234 17.8734 9.91821 17.9998 9.60001 17.9998C9.28182 17.9998 8.97665 17.8734 8.75161 17.6484Z",
      "clip-rule": "evenodd"
    })
  ]))
}