<script setup lang="ts">
import algoliasearch from 'algoliasearch/lite'
import { initDropdowns, initPopovers } from 'flowbite'
import { DateTime } from 'luxon'
import { inject, onBeforeMount, onMounted, provide } from 'vue'

import { toIntervalString } from '@ankor-io/common/date/ISO8601'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { UUID } from '@ankor-io/common/lang/uuid'
import { type Blueprint, InputAmountsTaxedValues, type Variant } from '@ankor-io/common/vessel/types'
import { OutlinePlus } from '@ankor-io/icons/outline'
import { SolidInformationCircle } from '@ankor-io/icons/solid'

import ButtonVue from '@/components/Button.vue'
import VariantVue from '@/components/edit-vessel/Variant.vue'
import { Config, ConfigKey } from '@/config/types'

type Props = {
  variants: Variant[]
  blueprint: Blueprint
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:variants', value: Variant[]): void
}>()

const HELP_TEXT =
  'This section is where you can add different pricing options for retail agents wanting to book with you. Customise the charter type, the description, pricing, operating area and what toys and amenities are included in the option. '

onBeforeMount(() => {
  const config: Config = inject(ConfigKey)!
  const geoSearchClient = algoliasearch(config.ALGOLIA.app_id, config.ALGOLIA.geo_key)
  provide('geoSearchClient', geoSearchClient)
})

onMounted(() => {
  initPopovers()
  initDropdowns()
})

/**
 * Add a vessel variant
 */
const addVariantItem = () => {
  const variants = ObjectUtil.deepCopy(props.variants)
  const defaultVariant: Variant = {
    id: UUID.timeBased(),
    label: 'New Package',
    description: '',
    maxPassengers: 0,
    active: true,
    petsAllowed: false,
    isUsingDefaultToysAmenities: true,
    turnaround: 'P0D',
    intervals: [`R/P1Y/${toIntervalString(DateTime.utc().startOf('year'), DateTime.utc().endOf('year'), 'utc')}`],
    pricing: {
      currency: 'EUR',
      charterFee: 0,
      inputAmountTaxed: InputAmountsTaxedValues.NONE,
      note: '',
      unit: 'WEEK',
      lineItems: [{ item: 'Charter Fee', quantity: 0, unitPrice: 0, amount: 0 }],
      total: 0,
    },
  }
  variants.push(defaultVariant)
  emit('update:variants', variants)
}

/**
 * Update a variant inside the variants array
 * @index The index position of the variants
 * @variant The new variant value to update to
 */
const updateVariantItem = (index: number, variant: Variant) => {
  const variants: Variant[] = ObjectUtil.deepCopy(props.variants)
  variants[index] = variant
  emit('update:variants', variants)
}

/**
 * Remove a variant from the variants array
 * @index The index position of the variants
 */
const removeVariantItem = (index: number) => {
  const variants: Variant[] = ObjectUtil.deepCopy(props.variants)
  variants.splice(index, 1)
  emit('update:variants', variants)
}
</script>
<template>
  <div class="flex flex-col text-primary gap-y-5">
    <div class="flex gap-x-2.5 items-end">
      <h3 class="text-2xl font-semibold leading-none text-black dark:text-white">Pricing</h3>
      <button type="button" data-popover-placement="right" data-popover-target="seasons-popover">
        <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
      </button>
      <div
        data-popover
        role="tooltip"
        id="seasons-popover"
        class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
      >
        <div class="max-w-[25rem] p-2">{{ HELP_TEXT }}</div>
        <div data-popper-arrow></div>
      </div>
    </div>

    <VariantVue
      v-for="(variant, variantIndex) in props.variants"
      :key="variantIndex"
      :variant="variant"
      :index="variantIndex"
      :blueprint="props.blueprint"
      @delete:variant="removeVariantItem(variantIndex)"
      @update:variant="updateVariantItem(variantIndex, $event)"
    />

    <!-- Add another variant button -->
    <ButtonVue
      class="h-fit w-min"
      id="add-pricing"
      position="prefix"
      :class="props.variants.length ? 'mt-4' : ''"
      :hasBorder="true"
      :isPrimary="false"
      :name="`Add ${props.variants.length ? 'Another' : ''} Pricing`"
      @click.stop="addVariantItem"
    >
      <OutlinePlus class="w-4 h-4" />
    </ButtonVue>
  </div>
</template>
