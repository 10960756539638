<script setup lang="ts">
import { initDropdowns } from 'flowbite'
import debounce from 'lodash.debounce'
import {
  Ref,
  inject,
  onBeforeMount,
  onMounted,
  /*, computed, ComputedRef*/
  ref,
} from 'vue'
import { RouteLocationNormalizedLoaded, Router } from 'vue-router'

import Tabs, { Tab } from '@ankor-io/blocks/components/Tabs/Tabs.vue'
import { VesselIndexItem } from '@ankor-io/common/index/VesselIndexItem'
import { useTypewriterEffect } from '@ankor-io/common/utils/useTypewriterEffect'
import { SortableFields, sortVessels } from '@ankor-io/common/vessel/sort'
import { Company } from '@ankor-io/iam/types'
import { HiOutlineMagnifyingGlass } from '@ankor-io/icons/hi_outline'
// import { KVPair } from '@ankor-io/common/vessel/types'
import { LineArtTimeline } from '@ankor-io/icons/line_art'
import { OutlineBarsArrowLeft, OutlineBarsArrowRight, OutlineLink, OutlineRefresh } from '@ankor-io/icons/outline'
import { SolidTable, SolidViewGrid, SolidX } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import AlgoliaCheckbox from '@/components/ais/Checkbox.vue'
import AlgoliaNumberRangeInput from '@/components/ais/NumberRangeInput.vue'
// import DateRangePicker from '@/components/DateRangePicker.vue'
import AlgoliaRefinementList from '@/components/ais/RefinementList.vue'
import { useFacetPanel } from '@/facet-panel/useFacetPanel'
import { AuthenticationContext } from '@/iam/types'
import { useAccountService } from '@/services/account/account.service'
import { addQueryParam, getRouterQuery, removeQueryParam } from '@/utils/router-query'
import { SearchView } from '@/views/search/types'

import Hits from './Hits.vue'

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const { initTypewriter, typeValue } = useTypewriterEffect()

const tabs: Ref<Tab[]> = ref([
  {
    name: 'Timeline',
    active: false,
    icon: LineArtTimeline,
  },
  {
    name: 'Cards',
    active: false,
    icon: SolidViewGrid,
  },
  {
    name: 'Table',
    active: false,
    icon: SolidTable,
  },
])

const ratesRef: Ref<{ [key: string]: string }> = ref({})

const calendarsLinkCopied: Ref<boolean> = ref(false)
let linkCopiedTimeout: ReturnType<typeof setTimeout> | null = null

const facetPanelState = useFacetPanel()

const showAdvancedFilters: Ref<boolean> = ref(false)
const company: Ref<Company | null> = ref(null)
const companyLoaded: Ref<boolean> = ref(false)

const vessels: Ref<VesselIndexItem[]> = ref([])
const previousQueriedHits: Ref<VesselIndexItem[]> = ref([])
const hitsComponentKey: Ref<number> = ref(0)

// Allows to toggle sort asc/desc
const sortOrder: Ref<{ field: SortableFields; order: number }> = ref({
  field: '',
  order: 1,
})
const sortItem: Ref<SortableFields | null> = ref(null)

// const areFlexibleDates: Ref<boolean> = ref(true)
// const calendarStartDate: Ref<string | null> = ref(null)
// const calendarEndDate: Ref<string | null> = ref(null)

// const showMinHirePeriod: Ref<boolean> = ref(false)
// const minimumHirePeriod: Ref<string> = ref('1 day')
// const minimumHirePeriodOptions: Ref<KVPair[]> = ref([{ label: '1 day', value: '1 day' }]) // Because I don't want to have a 100 line mock

// const eventFilters: ComputedRef<{ start: string | null; end: string | null; minHirePeriod: string }> = computed(() => {
//   return {
//     start: calendarStartDate.value,
//     end: calendarEndDate.value,
//     minHirePeriod: minimumHirePeriod.value,
//   }
// })

onBeforeMount(() => {
  const view = getRouterQuery('view')
  if (view === 'cards') {
    tabs.value[1].active = true
  } else if (view === 'table') {
    tabs.value[2].active = true
  } else {
    tabs.value[0].active = true
  }
})

onMounted(async () => {
  initTypewriter(['anything...', 'yachts...', 'locations...', 'events...', 'features...'])
  initDropdowns()
  getRates()
  // for (let i = 1; i < 30; i++) {
  //   const val = `${i + 1} days`
  //   minimumHirePeriodOptions.value.push({ label: val, value: val })
  // }

  const accountService = useAccountService()
  try {
    company.value = await accountService.getCompany()
  } catch (error) {
    console.error(error)
  }
  companyLoaded.value = true
})

const onTabClick = (tab: string, router: Router, route: RouteLocationNormalizedLoaded) => {
  for (let i = 0; i < tabs.value.length; i++) {
    if (tabs.value[i].name === tab) {
      tabs.value[i].active = true
    } else {
      tabs.value[i].active = false
    }
  }

  switch (tab) {
    case 'Timeline':
      removeQueryParam(router, route, 'view')
      break
    case 'Cards':
      addQueryParam(router, route, 'view', SearchView.CARDS)
      break
    case 'Table':
      addQueryParam(router, route, 'view', SearchView.TABLE)
      break
  }
}

// Pass in from the search box, the ais slot function refine and value value for the sole purpose of debouncing the search
const debounceRefiner = debounce((refine: any, value: any) => {
  refine(value)
}, 200)

// const updateCalendarDateRange = (event: { startDate: string; endDate: string }) => {
//   calendarStartDate.value = event.startDate
//   calendarEndDate.value = event.endDate
// }

// const setMinimumHirePeriod = (value: string) => {
//   minimumHirePeriod.value = value
//   showMinHirePeriod.value = false
// }

const shareCalendars = () => {
  const url = `${window.location.origin.replace(/calendars|commodore/, 'radar')}?line_7=${encodeURIComponent(
    company.value?.name || '',
  )}`
  calendarsLinkCopied.value = false
  clearTimeout(linkCopiedTimeout!)
  navigator.clipboard.writeText(url)
  calendarsLinkCopied.value = true
  // reset the calendar link copied value so the users know they can copy the link again
  linkCopiedTimeout = setTimeout(() => {
    calendarsLinkCopied.value = false
  }, 3000)
}

/**
 * Fetches the exchange rates from the backend
 */
const getRates = async () => {
  const response = await fetch('/currency/fx?reciprocal=1', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    response
      .json()
      .then((data: any) => {
        ratesRef.value = data.rates
      })
      .catch((error: any) => {
        console.error('Unable to fetch exchange rates', error)
      })
  }
}

const initiateSortOnVessels = (field: SortableFields) => {
  // no operation
  if (!field) {
    return
  }
  // if (field) {
  //   // Sort will always default to asc if it isn't currently sorted
  //   const order = sortOrder.value.field === field && sortOrder.value.order === 1 ? -1 : 1
  //   // Store toggled sort state for asc/desc
  //   sortOrder.value.field = field
  //   sortOrder.value.order = order
  //   sortItem.value = field
  //   hitsComponentKey.value = hitsComponentKey.value + 1
  // }
}

/**
 * Filter out vessels that are unavailable or out of price range
 * @param items
 * @param results
 */
const getItems = (items: VesselIndexItem[], { results }: any) => {
  if (results.page === 0) {
    // reset vessels to empty array if it's the first page
    vessels.value = []
  }

  // Control how often the transform hits is called
  if (JSON.stringify(previousQueriedHits.value) === JSON.stringify(items)) {
    return {}
  }

  items.forEach((item) => {
    if (!vessels.value.some((vessel) => vessel.objectID === item.objectID)) {
      vessels.value.push(item)
    }
  })

  if (sortItem.value) {
    vessels.value = sortVessels(vessels.value, { field: sortItem.value, order: sortOrder.value.order }, ratesRef.value)
  }

  // ais-infinite-hits expects transform-items to return something
  return {}
}
</script>
<template>
  <div class="flex flex-col gap-y-4 h-[calc(100vh-3.75rem)] overflow-hidden">
    <ais-configure :hits-per-page.camel="20" :distinct="true" :analytics="true" />

    <h4 class="sm:h-10" data-testId="page-title">My Fleet</h4>
    <div class="px-4 flex gap-x-4 gap-y-2 flex-col md:flex-row">
      <!-- Search bar -->
      <ais-search-box class="w-full max-w-sm self-center sm:self-auto">
        <template v-slot="{ refine }">
          <div class="flex flex-grow max-w-sm h-max w-full self-center md:self-auto">
            <button
              v-show="facetPanelState.updateFacetPanelState"
              id="show-filter-button"
              class="flex-shrink-0 inline-flex items-center py-2 px-5 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-0 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white dark:border-gray-600"
              type="button"
              @click="facetPanelState.updateFacetPanelState(!facetPanelState.isFacetPanelOpen.value)"
            >
              <OutlineBarsArrowLeft
                v-if="facetPanelState.isFacetPanelOpen.value"
                class="w-6 h-6 shrink-0 stroke-gray-500 dark:stroke-gray-400"
              />
              <OutlineBarsArrowRight v-else class="w-6 h-6 shrink-0 stroke-gray-500 dark:stroke-gray-400" />
            </button>

            <div class="relative w-full">
              <input
                type="search"
                id="search-yachts"
                class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:placeholder-transparent dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                :placeholder="`Search for ${typeValue}`"
                @input="debounceRefiner(refine, ($event as any).currentTarget.value)"
              />
              <button
                class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <HiOutlineMagnifyingGlass class="w-4 h-4 stroke-white stroke-2" />
                <span class="sr-only">Search</span>
              </button>
            </div>
          </div>
        </template>
      </ais-search-box>

      <div class="w-full flex flex-col sm:flex-row gap-x-4 gap-y-2 justify-between">
        <!-- Search view tabs -->
        <Tabs :tabs="tabs" @on:click="onTabClick($event, $router, $route)" />

        <!-- Share fleet -->
        <button
          type="button"
          class="w-min self-center text-white font-medium rounded-lg text-sm px-5 py-2.5 mt-2 md:mt-0 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          :disabled="!company"
          :class="[
            !company
              ? 'cursor-not-allowed bg-blue-400'
              : 'hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 bg-blue-700',
          ]"
          @click.stop="shareCalendars"
        >
          <Spinner v-if="!companyLoaded" class="w-5 h-5 mr-1"></Spinner>
          <span v-else class="flex justify-center items-center w-max">
            <OutlineLink class="w-5 h-5 mr-1"></OutlineLink>
            <span v-if="!calendarsLinkCopied">Share Fleet</span>
            <span v-else>Link Copied</span>
          </span>
        </button>
      </div>
    </div>

    <div
      class="h-full justify-center"
      :class="
        facetPanelState.isFacetPanelOpen.value ? 'md:flex md:relative w-full md:w-auto absolute top-0' : 'flex relative'
      "
    >
      <!-- Left side facet filtering -->
      <div
        class="z-50 absolute top-4 right-4 cursor-pointer p-1"
        :class="facetPanelState.isFacetPanelOpen.value ? 'flex md:hidden' : 'hidden'"
        @click="facetPanelState.updateFacetPanelState(false)"
      >
        <SolidX class="w-4 h-4 text-gray-900 dark:text-white" />
      </div>
      <div
        tabindex="-1"
        class="scrollbar-hide left-panel transition-all duration-300 ease-in-out text-gray-900 dark:text-white overflow-hidden whitespace-nowrap"
        :class="
          facetPanelState.isFacetPanelOpen.value
            ? 'pl-4 pr-2 py-12 md:pt-0 h-full md:h-[calc(100vh-6.8rem)] min-w-full md:min-w-0 md:flex-[0_0_16.75rem] overflow-y-auto bg-gray-50 dark:bg-gray-900 z-20 md:z-10 relative'
            : 'min-w-0 w-0 md:max-w-full md:flex-[0_0_0] opacity-0'
        "
      >
        <div class="flex gap-x-4 items-center mb-4 justify-between">
          <h6>Filters</h6>
          <ais-clear-refinements data-layout="desktop" class="self-end">
            <template #resetLabel>
              <div class="flex items-center gap-x-1 cursor-pointer">
                <OutlineRefresh class="w-4 h-4 text-primary-500" />
                <span class="text-xs text-primary-500">Clear filters</span>
              </div>
            </template>
          </ais-clear-refinements>
        </div>

        <!-- Facet filters -->
        <ais-dynamic-widgets class="flex flex-col" :facets="['*']">
          <!-- Charter type -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Charter type</h2>
            </template>
            <template #default="panel">
              <ais-refinement-list
                v-show="panel?.hasRefinements"
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.variants.pricing.unit"
                searchable
                show-more
                :limit="3"
              >
                <template v-slot="{ items, isFromSearch, refine, createURL }">
                  <p v-if="isFromSearch && !items.length" class="text-xs">No results.</p>
                  <ul v-else class="flex flex-col flex-wrap gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox
                        :item="item"
                        :refine="refine"
                        :create-url="createURL"
                        :text="item.value === 'HOUR' ? 'Day Charter' : 'Term Charter'"
                      />
                    </li>
                  </ul>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- This panel is for start and end calendar events -->
          <!-- It requires a "fake" algolia facet filter to even render in this dynamic widgets -->
          <!-- It does not actually refine or communicate to the algolia filters other than displaying it with `attribute` -->
          <!-- <ais-panel attribute="vessel.events">
            <template #header>
              <h2 class="mb-2 text-sm font-semibold text-black dark:text-white">Has availability</h2>
            </template>
            <template #default>
              <DateRangePicker
                start-date-placeholder="Start date"
                end-date-placeholder="End date"
                :start-date="calendarStartDate!"
                :end-date="calendarEndDate!"
                @update:start:and:end="updateCalendarDateRange"
              />
              <div
                class="flex items-center gap-x-2 mt-2 cursor-pointer"
                @click.stop="areFlexibleDates = !areFlexibleDates"
              >
                <input
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  :checked="areFlexibleDates"
                />
                <span class="text-gray-600 dark:text-gray-400 text-xs"> Flexible dates </span>
              </div>
              <h2 class="my-2 text-sm font-semibold text-black dark:text-white">Minimum hire period</h2>

              <button
                type="button"
                class="md:w-1/2 p-2.5 flex justify-between items-center h-min text-sm text-gray-500 bg-gray-50 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
                :class="showMinHirePeriod ? 'mb-1' : 'mb-4'"
                @click="showMinHirePeriod = !showMinHirePeriod"
              >
                {{ minimumHirePeriod }}
                <OutlineChevronDown class="w-4 h-4 text-gray-500 dark:text-gray-500" />
              </button>
              <OnClickOutside
                v-show="showMinHirePeriod"
                class="overflow-hidden md:w-1/2 z-10 bg-white rounded-lg shadow dark:bg-gray-700"
                :class="{ 'mb-4': showMinHirePeriod }"
                @trigger="showMinHirePeriod = false"
              >
                <ul class="h-44 flex flex-col gap-y-1 py-1.5 overflow-y-auto text-gray-700 dark:text-gray-200">
                  <li
                    v-for="option of minimumHirePeriodOptions"
                    class="px-2 text-sm cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-500"
                    :class="{ 'bg-gray-100 dark:bg-gray-600': minimumHirePeriod === option.value }"
                    :key="`filters-minimum-hire-period-${option.value}`"
                    :value="option.value"
                    @click="setMinimumHirePeriod(option.value)"
                  >
                    {{ option.label }}
                  </li>
                </ul>
              </OnClickOutside>
            </template>
          </ais-panel> -->

          <!-- TODO: Currently showing flag. What is a location linked to? -->
          <!-- Location -->
          <!-- <ais-panel class="mb-4">
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Location</h2>
            </template>
            <template #default="panel">
              <ais-refinement-list
                v-show="panel?.hasRefinements"
                class="flex flex-col gap-y-2"
                attribute="vessel.blueprint.flag"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{
                    items,
                    isShowingMore,
                    isFromSearch,
                    canToggleShowMore,
                    refine,
                    createURL,
                    toggleShowMore,
                    searchForItems,
                  }"
                >
                  <AlgoliaRefinementList
                    placeholder="Search for Location"
                    :items="items"
                    :is-showing-more="isShowingMore"
                    :is-from-search="isFromSearch"
                    :can-toggle-show-more="canToggleShowMore"
                    :refine="refine"
                    :create-u-r-l="createURL"
                    :toggle-show-more="toggleShowMore"
                    :search-for-items="searchForItems"
                  />
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel> -->

          <!-- Central agent / line_7 -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Central agent</h2>
            </template>
            <template #default="panel">
              <ais-refinement-list
                v-show="panel?.hasRefinements"
                class="flex flex-col gap-y-2 mb-4"
                attribute="line_7"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{
                    items,
                    isShowingMore,
                    isFromSearch,
                    canToggleShowMore,
                    refine,
                    createURL,
                    toggleShowMore,
                    searchForItems,
                  }"
                >
                  <AlgoliaRefinementList
                    placeholder="Central Agents"
                    :items="items"
                    :is-showing-more="isShowingMore"
                    :is-from-search="isFromSearch"
                    :can-toggle-show-more="canToggleShowMore"
                    :refine="refine"
                    :create-u-r-l="createURL"
                    :toggle-show-more="toggleShowMore"
                    :search-for-items="searchForItems"
                  />
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Yacht Type -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Yacht Type</h2>
            </template>
            <template #default="panel">
              <ais-refinement-list
                v-show="panel?.hasRefinements"
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.yachtType"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{ items, isShowingMore, isFromSearch, canToggleShowMore, refine, createURL, toggleShowMore }"
                >
                  <p v-if="isFromSearch && !items.length" class="text-xs">No results.</p>
                  <ul v-else class="flex flex-col gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox :item="item" :refine="refine" :create-url="createURL" />
                    </li>
                  </ul>
                  <button
                    v-if="canToggleShowMore"
                    class="text-xs text-blue-600 dark:text-blue-400 text-left"
                    :disabled="!canToggleShowMore"
                    @click="toggleShowMore"
                  >
                    {{ !isShowingMore ? 'Show more' : 'Show less' }}
                  </button>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Charter Type -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">
                Crewed / Bareboat
              </h2>
            </template>
            <template #default="panel">
              <ais-refinement-list
                v-show="panel?.hasRefinements"
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.charterType"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{ items, isShowingMore, isFromSearch, canToggleShowMore, refine, createURL, toggleShowMore }"
                >
                  <p v-if="isFromSearch && !items.length" class="text-xs">No results.</p>
                  <ul v-else class="flex flex-col gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox :item="item" :refine="refine" :create-url="createURL" />
                    </li>
                  </ul>
                  <button
                    v-if="canToggleShowMore"
                    class="text-xs text-blue-600 dark:text-blue-400 text-left"
                    :disabled="!canToggleShowMore"
                    @click="toggleShowMore"
                  >
                    {{ !isShowingMore ? 'Show more' : 'Show less' }}
                  </button>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Category -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Category</h2>
            </template>
            <template #default="panel">
              <ais-refinement-list
                v-show="panel?.hasRefinements"
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.category"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{ items, isShowingMore, isFromSearch, canToggleShowMore, refine, createURL, toggleShowMore }"
                >
                  <p v-if="isFromSearch && !items.length" class="text-xs">No results.</p>
                  <ul v-else class="flex flex-col gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox :item="item" :refine="refine" :create-url="createURL" />
                    </li>
                  </ul>
                  <button
                    v-if="canToggleShowMore"
                    class="text-xs text-blue-600 dark:text-blue-400 text-left"
                    :disabled="!canToggleShowMore"
                    @click="toggleShowMore"
                  >
                    {{ !isShowingMore ? 'Show more' : 'Show less' }}
                  </button>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Length range -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Length (m)</h2>
            </template>
            <template #default="panel">
              <ais-range-input
                v-show="panel?.hasRefinements"
                class="md:w-full mb-4"
                attribute="vessel.blueprint.length"
              >
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Cabins range -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Cabins</h2>
            </template>
            <template #default="panel">
              <ais-range-input
                v-show="panel?.hasRefinements"
                class="md:w-full mb-4"
                attribute="vessel.blueprint.cabins"
              >
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Bathrooms range -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Bathrooms</h2>
            </template>
            <template #default="panel">
              <ais-range-input
                v-show="panel?.hasRefinements"
                class="md:w-full mb-4"
                attribute="vessel.blueprint.bathrooms"
              >
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Decks range -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Decks</h2>
            </template>
            <template #default="panel">
              <ais-range-input v-show="panel?.hasRefinements" class="md:w-full mb-4" attribute="vessel.blueprint.decks">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Guests/sleep range -->
          <ais-panel>
            <template #header="{ hasRefinements }">
              <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Sleeps</h2>
            </template>
            <template #default="panel">
              <ais-range-input
                v-show="panel?.hasRefinements"
                class="md:w-full mb-4"
                attribute="vessel.blueprint.sleeps"
              >
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>

              <span
                v-if="!showAdvancedFilters"
                class="text-xs cursor-pointer text-primary-600 dark:text-primary-500"
                @click="showAdvancedFilters = true"
              >
                More advanced filters
              </span>
            </template>
          </ais-panel>

          <!-- Start Advanced Filters -->
          <ais-panel v-show="showAdvancedFilters">
            <!-- Cruising Capacity -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">
                  Cruising Capacity
                </h2>
              </template>
              <template #default="panel">
                <ais-range-input
                  v-show="panel?.hasRefinements"
                  class="md:w-full mb-4"
                  attribute="vessel.blueprint.cruisingCapacity"
                >
                  <template v-slot="{ currentRefinement, range, canRefine, refine }">
                    <AlgoliaNumberRangeInput
                      :current-refinement="currentRefinement"
                      :range="range"
                      :can-refine="canRefine"
                      :refine="refine"
                    />
                  </template>
                </ais-range-input>
              </template>
            </ais-panel>

            <!-- Static Capacity -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">
                  Static Capacity
                </h2>
              </template>
              <template #default="panel">
                <ais-range-input
                  v-show="panel?.hasRefinements"
                  class="md:w-full mb-4"
                  attribute="vessel.blueprint.staticCapacity"
                >
                  <template v-slot="{ currentRefinement, range, canRefine, refine }">
                    <AlgoliaNumberRangeInput
                      :current-refinement="currentRefinement"
                      :range="range"
                      :can-refine="canRefine"
                      :refine="refine"
                    />
                  </template>
                </ais-range-input>
              </template>
            </ais-panel>

            <!-- Draft range -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Draft</h2>
              </template>
              <template #default="panel">
                <ais-range-input
                  v-show="panel?.hasRefinements"
                  class="md:w-full mb-4"
                  attribute="vessel.blueprint.draft"
                >
                  <template v-slot="{ currentRefinement, range, canRefine, refine }">
                    <AlgoliaNumberRangeInput
                      :current-refinement="currentRefinement"
                      :range="range"
                      :can-refine="canRefine"
                      :refine="refine"
                    />
                  </template>
                </ais-range-input>
              </template>
            </ais-panel>

            <!-- Beam range -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Beam</h2>
              </template>
              <template #default="panel">
                <ais-range-input
                  v-show="panel?.hasRefinements"
                  class="md:w-full mb-4"
                  attribute="vessel.blueprint.beam"
                >
                  <template v-slot="{ currentRefinement, range, canRefine, refine }">
                    <AlgoliaNumberRangeInput
                      :current-refinement="currentRefinement"
                      :range="range"
                      :can-refine="canRefine"
                      :refine="refine"
                    />
                  </template>
                </ais-range-input>
              </template>
            </ais-panel>

            <!-- Cruising Speed range -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">
                  Cruising speed
                </h2>
              </template>
              <template #default="panel">
                <ais-range-input
                  v-show="panel?.hasRefinements"
                  class="md:w-full mb-4"
                  attribute="vessel.blueprint.cruiseSpeed"
                >
                  <template v-slot="{ currentRefinement, range, canRefine, refine }">
                    <AlgoliaNumberRangeInput
                      :current-refinement="currentRefinement"
                      :range="range"
                      :can-refine="canRefine"
                      :refine="refine"
                    />
                  </template>
                </ais-range-input>
              </template>
            </ais-panel>

            <!-- Crew range -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Crew</h2>
              </template>
              <template #default="panel">
                <ais-range-input
                  v-show="panel?.hasRefinements"
                  class="md:w-full mb-4"
                  attribute="vessel.blueprint.maxCrew"
                >
                  <template v-slot="{ currentRefinement, range, canRefine, refine }">
                    <AlgoliaNumberRangeInput
                      :current-refinement="currentRefinement"
                      :range="range"
                      :can-refine="canRefine"
                      :refine="refine"
                    />
                  </template>
                </ais-range-input>
              </template>
            </ais-panel>

            <!-- Build year -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Build Year</h2>
              </template>
              <template #default="panel">
                <ais-range-input
                  v-show="panel?.hasRefinements"
                  class="md:w-full mb-4"
                  attribute="vessel.blueprint.builtYear"
                >
                  <template v-slot="{ currentRefinement, range, canRefine, refine }">
                    <AlgoliaNumberRangeInput
                      :current-refinement="currentRefinement"
                      :range="range"
                      :can-refine="canRefine"
                      :refine="refine"
                    />
                  </template>
                </ais-range-input>
              </template>
            </ais-panel>

            <!-- Refit year -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Refit Year</h2>
              </template>
              <template #default="panel">
                <ais-range-input
                  v-show="panel?.hasRefinements"
                  class="md:w-full mb-4"
                  attribute="vessel.blueprint.refitYear"
                >
                  <template v-slot="{ currentRefinement, range, canRefine, refine }">
                    <AlgoliaNumberRangeInput
                      :current-refinement="currentRefinement"
                      :range="range"
                      :can-refine="canRefine"
                      :refine="refine"
                    />
                  </template>
                </ais-range-input>
              </template>
            </ais-panel>

            <!-- Toys -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Toys</h2>
              </template>
              <template #default="panel">
                <ais-refinement-list
                  v-show="panel?.hasRefinements"
                  class="flex flex-col gap-y-2 mb-4"
                  attribute="vessel.variants.toys.label"
                  searchable
                  show-more
                  :limit="3"
                  :class-names="{
                    'ais-SearchBox-input': 'text-black rounded-md',
                    'ais-SearchBox-submit': 'hidden',
                    'ais-SearchBox-reset': 'hidden',
                  }"
                >
                  <template
                    v-slot="{
                      items,
                      isShowingMore,
                      isFromSearch,
                      canToggleShowMore,
                      refine,
                      createURL,
                      toggleShowMore,
                      searchForItems,
                    }"
                  >
                    <AlgoliaRefinementList
                      placeholder="Search for Toys"
                      :items="items"
                      :is-showing-more="isShowingMore"
                      :is-from-search="isFromSearch"
                      :can-toggle-show-more="canToggleShowMore"
                      :refine="refine"
                      :create-u-r-l="createURL"
                      :toggle-show-more="toggleShowMore"
                      :search-for-items="searchForItems"
                    />
                  </template>
                </ais-refinement-list>
              </template>
            </ais-panel>

            <!-- Amenities -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Amenities</h2>
              </template>
              <template #default="panel">
                <ais-refinement-list
                  v-show="panel?.hasRefinements"
                  class="flex flex-col gap-y-2 mb-4"
                  attribute="vessel.variants.amenities.label"
                  searchable
                  show-more
                  :limit="3"
                  :class-names="{
                    'ais-SearchBox-input': 'text-black rounded-md',
                    'ais-SearchBox-submit': 'hidden',
                    'ais-SearchBox-reset': 'hidden',
                  }"
                >
                  <template
                    v-slot="{
                      items,
                      isShowingMore,
                      isFromSearch,
                      canToggleShowMore,
                      refine,
                      createURL,
                      toggleShowMore,
                      searchForItems,
                    }"
                  >
                    <AlgoliaRefinementList
                      placeholder="Search for Amenities"
                      :items="items"
                      :is-showing-more="isShowingMore"
                      :is-from-search="isFromSearch"
                      :can-toggle-show-more="canToggleShowMore"
                      :refine="refine"
                      :create-u-r-l="createURL"
                      :toggle-show-more="toggleShowMore"
                      :search-for-items="searchForItems"
                    />
                  </template>
                </ais-refinement-list>
              </template>
            </ais-panel>

            <!-- Tenders -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Tenders</h2>
              </template>
              <template #default="panel">
                <ais-refinement-list
                  v-show="panel?.hasRefinements"
                  class="flex flex-col gap-y-2 mb-4"
                  attribute="vessel.variants.tenders.label"
                  searchable
                  show-more
                  :limit="3"
                  :class-names="{
                    'ais-SearchBox-input': 'text-black rounded-md',
                    'ais-SearchBox-submit': 'hidden',
                    'ais-SearchBox-reset': 'hidden',
                  }"
                >
                  <template
                    v-slot="{
                      items,
                      isShowingMore,
                      isFromSearch,
                      canToggleShowMore,
                      refine,
                      createURL,
                      toggleShowMore,
                      searchForItems,
                    }"
                  >
                    <AlgoliaRefinementList
                      placeholder="Search for Tenders"
                      :items="items"
                      :is-showing-more="isShowingMore"
                      :is-from-search="isFromSearch"
                      :can-toggle-show-more="canToggleShowMore"
                      :refine="refine"
                      :create-u-r-l="createURL"
                      :toggle-show-more="toggleShowMore"
                      :search-for-items="searchForItems"
                    />
                  </template>
                </ais-refinement-list>
              </template>
            </ais-panel>

            <!-- Entertainment -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">
                  Entertainment
                </h2>
              </template>
              <template #default="panel">
                <ais-refinement-list
                  v-show="panel?.hasRefinements"
                  class="flex flex-col gap-y-2 mb-4"
                  attribute="vessel.variants.entertainment"
                  searchable
                  show-more
                  :limit="3"
                  :class-names="{
                    'ais-SearchBox-input': 'text-black rounded-md',
                    'ais-SearchBox-submit': 'hidden',
                    'ais-SearchBox-reset': 'hidden',
                  }"
                >
                  <template
                    v-slot="{
                      items,
                      isShowingMore,
                      isFromSearch,
                      canToggleShowMore,
                      refine,
                      createURL,
                      toggleShowMore,
                      searchForItems,
                    }"
                  >
                    <AlgoliaRefinementList
                      placeholder="Search for Entertainment"
                      :items="items"
                      :is-showing-more="isShowingMore"
                      :is-from-search="isFromSearch"
                      :can-toggle-show-more="canToggleShowMore"
                      :refine="refine"
                      :create-u-r-l="createURL"
                      :toggle-show-more="toggleShowMore"
                      :search-for-items="searchForItems"
                    />
                  </template>
                </ais-refinement-list>
              </template>
            </ais-panel>

            <!-- Builder -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Builder</h2>
              </template>
              <template #default="panel">
                <ais-refinement-list
                  v-show="panel?.hasRefinements"
                  class="flex flex-col gap-y-2 mb-4"
                  attribute="vessel.blueprint.make"
                  searchable
                  show-more
                  :limit="3"
                  :class-names="{
                    'ais-SearchBox-input': 'text-black rounded-md',
                    'ais-SearchBox-submit': 'hidden',
                    'ais-SearchBox-reset': 'hidden',
                  }"
                >
                  <template
                    v-slot="{
                      items,
                      isShowingMore,
                      isFromSearch,
                      canToggleShowMore,
                      refine,
                      createURL,
                      toggleShowMore,
                      searchForItems,
                    }"
                  >
                    <AlgoliaRefinementList
                      placeholder="Search for Builder"
                      :items="items"
                      :is-showing-more="isShowingMore"
                      :is-from-search="isFromSearch"
                      :can-toggle-show-more="canToggleShowMore"
                      :refine="refine"
                      :create-u-r-l="createURL"
                      :toggle-show-more="toggleShowMore"
                      :search-for-items="searchForItems"
                    />
                  </template>
                </ais-refinement-list>
              </template>
            </ais-panel>

            <!-- Allows pets -->
            <ais-panel>
              <template #header="{ hasRefinements }">
                <h2 v-if="hasRefinements" class="mb-2 text-sm font-semibold text-black dark:text-white">Pets</h2>
              </template>
              <template #default="panel">
                <ais-refinement-list
                  v-show="panel?.hasRefinements"
                  class="flex flex-col gap-y-2 mb-4"
                  attribute="vessel.variants.petsAllowed"
                  searchable
                  show-more
                  :limit="3"
                >
                  <template v-slot="{ items, refine, createURL }">
                    <ul class="flex flex-wrap gap-2">
                      <li v-for="item in items" :key="item.value">
                        <AlgoliaCheckbox
                          :item="item"
                          :refine="refine"
                          :create-url="createURL"
                          :text="item.value === 'true' ? 'Allows Pets' : 'Does not allow Pets'"
                        />
                      </li>
                    </ul>
                  </template>
                </ais-refinement-list>

                <span
                  v-if="showAdvancedFilters"
                  class="text-xs cursor-pointer text-primary-600 dark:text-primary-500"
                  @click="showAdvancedFilters = false"
                >
                  Less advanced filters
                </span>
              </template>
            </ais-panel>
          </ais-panel>
        </ais-dynamic-widgets>
      </div>

      <!-- Search results -->
      <ais-infinite-hits
        id="ais-infinite-hits"
        class="@container w-full overflow-auto h-[calc(100vh-11rem)] sm:h-[calc(100vh-9rem)]"
        :class="{
          'hidden sm:block': facetPanelState.isFacetPanelOpen.value,
          'ml-4': !facetPanelState.isFacetPanelOpen.value,
        }"
        :transform-items="getItems"
        :key="hitsComponentKey"
      >
        <template v-slot="{ refineNext, isLastPage, results: { query } }">
          <Hits
            :items="vessels"
            :refineNext="refineNext"
            :isLastPage="isLastPage"
            :query="query"
            :rates="ratesRef"
            @sort:vessel:by="initiateSortOnVessels"
          />
        </template>
      </ais-infinite-hits>
    </div>
  </div>
</template>
<style lang="scss" scoped>
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration,
[type='search']::-webkit-search-results-button,
[type='search']::-webkit-search-results-decoration {
  @apply hidden;
}
</style>
