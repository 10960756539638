import { DateTime, Interval } from 'luxon'
import { CalendarDateCell } from './types'

const daysInCalendar = 42 // 6 rows, 7 days per row

/**
 * Returns a range of all the dates in a given month, in a given year
 * @param year A given year
 * @param month A given month index
 */
export const getMonthRange = (year: number, month: number) => {
  const start = DateTime.fromObject({ year, month })
  const end = start.endOf('month')
  return Interval.fromDateTimes(start, end).splitBy({ days: 1 })
}

/**
 * Returns the dates of a given month, in a given year along with which day of week it is
 * @param year A given year
 * @param month A given month index
 */
export const getDatesInMonth = (year: number, month: number): CalendarDateCell[] => {
  const monthRange = getMonthRange(year, month)
  const formattedDays = monthRange.map((day) => {
    return {
      day: Number(day.start!.toFormat('d')),
      date: day.start!.toFormat('dd-MM-yyyy'),
      dayOfWeek: DateTime.fromISO(day.start!.toString()).weekday - 1,
      isCurrentMonth: true,
    }
  })

  return formattedDays
}

/**
 * Returns an array of the previous months few days required to fill the beginning of the calendar
 * @param givenMonth The given month givenMonth
 */
export const getPartialDaysOfMonthBefore = (givenYear: number, givenMonth: number): CalendarDateCell[] => {
  // Get year, which could be the previous year if the current month is January
  const year = givenMonth === 1 ? givenYear - 1 : givenYear
  // Gets the number of days needed to prefill the beginning of the month
  const numDaysPrevMonthOverlap = getDatesInMonth(givenYear, givenMonth)[0].dayOfWeek! - 1
  // Get month, which could be december if the current month is January
  const month = givenMonth - 1 === 0 ? 12 : givenMonth - 1

  const monthRange = getMonthRange(year, month)
  const filledArr = []
  // Store the days of the previous month that overlaps into this month
  for (let i = monthRange.length - numDaysPrevMonthOverlap; i <= monthRange.length; i++) {
    const formattedMonth = month < 10 ? `0${month}` : month
    filledArr.push({ day: i, date: `${i}-${formattedMonth}-${year}`, isCurrentMonth: false })
  }

  return filledArr
}

/**
 * Returns the number of days in the following month required to fill the rest of the calendar
 * Which is done by knowing:
 * - Each calendar fills 42 days
 * - How many days are in the current month
 * - How many days have been pre-filled in the overlap before the month starts
 * @param givenMonth The given month givenMonth
 */
export const getPartialNumDaysOfMonthAfter = (givenYear: number, givenMonth: number): number => {
  return (
    daysInCalendar -
    getDatesInMonth(givenYear, givenMonth).length -
    getPartialDaysOfMonthBefore(givenYear, givenMonth).length
  )
}
