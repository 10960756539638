<script setup lang="ts">
import { initPopovers } from 'flowbite'
import { Ref, defineProps, inject, onMounted, ref } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { KVPair } from '@ankor-io/common/vessel/types'
import { OutlineDelete, OutlineDownload, OutlineLink, OutlinePlus } from '@ankor-io/icons/outline'
import { SolidInformationCircle } from '@ankor-io/icons/solid'

import ButtonVue from '@/components/Button.vue'
import AssetManager from '@/components/edit-vessel/AssetManager.vue'
import { AuthenticationContext } from '@/iam/types'
import { upload } from '@/services/assets/upload'

type Props = {
  uri: string
  images: string[]
  hero?: string
  links: KVPair[]
  attachments: KVPair[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'delete:hero'): void
  (e: 'update:hero', value: number): void
  (e: 'update:vessel:assets', assets: string[]): void
  (e: 'update:vessel:attachments', attachments: KVPair[]): void
  (e: 'update:vessel:links', links: KVPair[]): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const HELP_TEXT =
  'This section is where you add any images, media, attachments and links for retail agents to see, click or download. Customise the the link or download button tiles as well.'

const uploadedFileSrc: Ref<string> = ref('')
const uploadedFile: Ref<boolean> = ref(false)

onMounted(() => initPopovers())

/**
 * Update the vessel assets
 * @param value
 */
const updateVesselAssets = (value: { assets: string[] }) => {
  emit('update:vessel:assets', value.assets)
}

/**
 * Add an attachment to the attachments array
 */
const addAttachment = () => {
  const attachments = ObjectUtil.deepCopy(props.attachments || [])
  attachments.push({ label: '', value: '' })
  emit('update:vessel:attachments', attachments)
}

/**
 * Remove an attachment from the attachments array
 * @param index
 */
const removeAttachment = (index: number) => {
  const attachments = ObjectUtil.deepCopy(props.attachments || [])
  attachments.splice(index, 1)
  emit('update:vessel:attachments', attachments)
}

const downloadAttachment = async (attachment: KVPair) => {
  const { label, value } = attachment

  await fetch(`/api/asset/download/${props.uri}?filepath=${value}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${(await authenticationContext.getToken())!}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = label
      // we need to append the element to the dom -> otherwise it will not work in firefox
      document.body.appendChild(a)
      a.click()
      a.remove() //afterwards we remove the element again
    })
}

/**
 * Add a link to the link array
 */
const addLink = () => {
  const links = ObjectUtil.deepCopy(props.links || [])
  links.push({ label: '', value: '' })
  emit('update:vessel:links', links)
}

/**
 * Remove a link from the link array
 * @param index
 */
const removeLink = (index: number) => {
  const links = ObjectUtil.deepCopy(props.links || [])
  links.splice(index, 1)
  emit('update:vessel:links', links)
}

/**
 * Update the link label
 * @param event
 * @param index
 */
const updateLinkLabel = (event: Event, index: number) => {
  const target = event.target as HTMLInputElement
  const links = ObjectUtil.deepCopy(props.links || [])
  links[index].label = target.value
  emit('update:vessel:links', links)
}

/**
 * Update the link value
 * @param event
 * @param index
 */
const updateLinkValue = (event: Event, index: number) => {
  const target = event.target as HTMLInputElement
  const links = ObjectUtil.deepCopy(props.links || [])
  links[index].value = target.value
  emit('update:vessel:links', links)
}

/**
 * Attach a file to the vessel
 * @param index
 * @param file
 */
const attachFile = async (index: number, file: File) => {
  // Upload the file to the server calling stowage service
  const token: string = (await authenticationContext.getToken())!

  const relativePath = `attachments/${encodeURIComponent(file.name)}`
  const uploaded: boolean = await upload(relativePath, props.uri, token, file)

  const fileReader = new FileReader()
  fileReader.onload = () => {
    uploadedFile.value = true
    uploadedFileSrc.value = fileReader.result as string
  }
  fileReader.readAsDataURL(file)
  const attachments = ObjectUtil.deepCopy(props.attachments || [])
  if (uploaded) {
    attachments[index] = { label: file.name, value: relativePath }
  } else {
    attachments.splice(index, 1)
  }
  emit('update:vessel:attachments', attachments)
}

/**
 * Add attachment label
 * @param event
 * @param attachmentIndex
 */
const updateAttachmentLabel = (event: Event, attachmentIndex: number) => {
  const target = event.target as HTMLInputElement
  const attachments = ObjectUtil.deepCopy(props.attachments || [])
  attachments[attachmentIndex].label = target.value
  emit('update:vessel:attachments', attachments)
}

/**
 * Add a file from the input
 * @param event
 * @param attachmentIndex
 */
const addFileFromInput = async (event: Event, attachmentIndex: number) => {
  const target = event.target as HTMLInputElement
  const attachments = ObjectUtil.deepCopy(props.attachments || [])
  attachments[attachmentIndex].value = `attachments/${target.files!.item(0)!.name}`
  await attachFile(attachmentIndex, target.files!.item(0)!)
}
</script>
<template>
  <div class="flex flex-col text-primary w-full gap-y-8">
    <div class="flex gap-x-2.5 items-end">
      <h3 class="text-2xl font-semibold leading-none text-black dark:text-white">Media</h3>
      <button type="button" data-popover-placement="right" data-popover-target="seasons-popover">
        <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
      </button>
      <div
        data-popover
        role="tooltip"
        id="seasons-popover"
        class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
      >
        <div class="max-w-[25rem] p-2">{{ HELP_TEXT }}</div>
        <div data-popper-arrow></div>
      </div>
    </div>

    <!-- Images & Videos -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h4 class="font-semibold text-black dark:text-white">Images</h4>
      <AssetManager
        :uri="props.uri"
        :images="replacePathToMediaUris(props.uri, ...(images || [])) || []"
        :hero="replacePathToMediaUris(props.uri, hero || images[0] || '')[0]"
        @update:vessel:assets="updateVesselAssets"
        @delete:hero="emit('delete:hero')"
        @update:hero="emit('update:hero', $event)"
      />
    </div>

    <!-- Attachments -->
    <div class="flex flex-col gap-y-4 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <div class="flex flex-col">
        <h4 class="font-bold text-black dark:text-white">Attachments</h4>
        <span class="text-sm text-gray-400">Accepted file types: PDF, PNG, JPEG, MP4, CSV</span>
      </div>
      <div v-if="props.attachments.length" class="flex gap-x-2 text-sm font-medium">
        <span class="w-60"> Attachment name </span>
        Upload file
      </div>
      <div
        v-for="(attachment, attachmentIndex) of props.attachments"
        :key="`attachment-${attachmentIndex}`"
        class="flex items-center gap-x-2"
      >
        <input
          type="text"
          placeholder="Enter attachment name"
          :id="`attachment-label-${attachmentIndex}`"
          :value="attachment.label"
          class="flex min-w-[15rem] w-60 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @blur.stop="updateAttachmentLabel($event, attachmentIndex)"
        />

        <input
          v-if="attachment.value"
          type="text"
          class="pointer-events-none w-full flex bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          readonly
          :value="attachment.value"
        />
        <input
          v-else
          type="file"
          class="flex grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          :id="`file_input-${attachmentIndex}`"
          @change="addFileFromInput($event, attachmentIndex)"
        />
        <div class="flex gap-x-2">
          <OutlineDownload
            v-show="attachment.label && attachment.value"
            class="w-8 h-8 p-2 cursor-pointer stroke-gray-700 dark:stroke-gray-400 hover:bg-gray-100 dark:hover:bg-gray-300 dark:hover:stroke-gray-700 bg-opacity-20 rounded-full transition-all duration-500"
            @click.stop="downloadAttachment(attachment)"
          ></OutlineDownload>
          <OutlineDelete
            class="w-8 h-8 p-2 cursor-pointer stroke-red-600 dark:stroke-red-700 hover:bg-red-100 dark:hover:bg-red-300 bg-opacity-20 rounded-full transition-all duration-500"
            @click.stop="removeAttachment(attachmentIndex)"
          ></OutlineDelete>
        </div>
      </div>

      <!-- Add another attachment button -->
      <ButtonVue
        class="h-fit w-min"
        id="add-attachment"
        position="prefix"
        :hasBorder="true"
        :isPrimary="false"
        :name="`Add ${props.attachments.length ? 'Another' : ''} Attachment`"
        @click.stop="addAttachment"
      >
        <OutlinePlus class="w-4 h-4" />
      </ButtonVue>
    </div>

    <!-- Links -->
    <div class="flex flex-col gap-y-4 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h4 class="font-bold text-black dark:text-white">Additional Links</h4>
      <div v-if="props.links.length" class="flex gap-x-2 text-sm font-medium">
        <span class="w-60"> Link name </span>
        Link URL
      </div>
      <template v-for="(link, linkIndex) of props.links" :key="`link-${linkIndex}`">
        <div class="flex items-center gap-x-2">
          <input
            type="text"
            placeholder="Enter link name"
            :value="link.label"
            :id="`link-name-${linkIndex}`"
            class="flex w-60 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            @blur.stop="updateLinkLabel($event, linkIndex)"
          />
          <div class="flex grow">
            <span
              class="inline-flex items-center px-2 text-sm text-gray-900 bg-gray-200 border rounded-tl-lg rounded-bl-lg border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600"
            >
              <OutlineLink class="w-4 h-4 text-gray-500 dark:text-gray-400" />
            </span>
            <input
              type="text"
              placeholder="Enter link URL"
              :value="link.value"
              :id="`link-url-${linkIndex}`"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-tr-lg rounded-br-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @blur.stop="updateLinkValue($event, linkIndex)"
            />
          </div>
          <div class="w-8">
            <OutlineDelete
              class="w-8 h-8 p-2 cursor-pointer stroke-red-700 hover:bg-red-100 bg-opacity-20 rounded-full transition-all duration-500"
              @click.stop="removeLink(linkIndex)"
            ></OutlineDelete>
          </div>
        </div>
      </template>

      <!-- Add another link button -->
      <ButtonVue
        class="h-fit w-min"
        id="add-link"
        position="prefix"
        :hasBorder="true"
        :isPrimary="false"
        :name="`Add ${props.links.length ? 'Another' : ''} Link`"
        @click.stop="addLink"
      >
        <OutlinePlus class="w-4 h-4" />
      </ButtonVue>
    </div>
  </div>
</template>
