<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import { PricingItem, getLowestPricingItem, intlCurrencyFormatter } from '@ankor-io/common/vessel/lowest-price-variant'
import { Variant } from '@ankor-io/common/vessel/types'

export type Props = {
  /**
   * The valid variants for a vessel
   */
  variants: Variant[]
  /**
   * The exchange rates for the vessel
   */
  rates: { [key: string]: string }
  isTimeline: boolean
}

const props = defineProps<Props>()

const lowestPricingDayRef: Ref<PricingItem | null> = ref(null)
const lowestPricingTermRef: Ref<PricingItem | null> = ref(null)

onMounted(() => {
  const dayVariants = props.variants.filter(
    (variant: Variant) => variant.pricing?.unit === 'DAY' || variant.pricing?.unit === 'HOUR',
  )

  const termVariants = props.variants.filter((variant: Variant) => variant.pricing?.unit === 'WEEK')

  lowestPricingDayRef.value = getLowestPricingItem(dayVariants, props.rates)
  lowestPricingTermRef.value = getLowestPricingItem(termVariants, props.rates)
})
</script>
<template>
  <div v-if="lowestPricingDayRef || lowestPricingTermRef" class="flex flex-col gap-y-1 @md:gap-y-2">
    <div v-if="lowestPricingDayRef" class="flex items-center gap-x-1 @md:gap-x-2">
      <span
        class="h-fit text-center font-medium py-0.5 rounded bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300"
        :class="isTimeline ? 'w-10 sm:w-12 px-1.5 sm:px-2.5 text-[0.625rem] sm:text-xs' : 'w-12 px-2.5 text-xs'"
      >
        Day
      </span>
      <span class="text-gray-500 dark:text-gray-400" :class="{ 'text-[0.625rem] sm:text-xs': props.isTimeline }">
        From {{ intlCurrencyFormatter(lowestPricingDayRef.displayCurrency, lowestPricingDayRef.displayPrice) }}
      </span>
    </div>
    <div v-if="lowestPricingTermRef" class="flex items-center gap-x-1 @md:gap-x-2">
      <span
        class="h-fit text-center font-medium py-0.5 rounded bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300"
        :class="isTimeline ? 'w-10 sm:w-12 px-1.5 sm:px-2.5 text-[0.625rem] sm:text-xs' : 'w-12 px-2.5 text-xs'"
      >
        Term
      </span>
      <span class="text-gray-500 dark:text-gray-400" :class="{ 'text-[0.625rem] sm:text-xs': props.isTimeline }">
        From {{ intlCurrencyFormatter(lowestPricingTermRef.displayCurrency, lowestPricingTermRef.displayPrice) }}
      </span>
    </div>
  </div>
</template>
