<script setup lang="ts">
import debounce from 'lodash.debounce'

type Props = {
  currentRefinement: any
  range: any
  canRefine: any
  refine: any
}

const props = defineProps<Props>()

// Pass in from the search box, the ais slot function refine and value value for the sole purpose of debouncing the search
const debounceRefiner = debounce((refine: any, value: any) => {
  refine(value)
}, 400)

const formatMinValue = (minValue: number, minRange: number) => {
  return minValue !== null && minValue !== minRange ? minValue : ''
}

const formatMaxValue = (maxValue: number, maxRange: number) => {
  return maxValue !== null && maxValue !== maxRange ? maxValue : ''
}
</script>
<template>
  <div class="flex items-center gap-x-2">
    <input
      type="number"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      :min="props.range.min"
      :max="props.range.max"
      :placeholder="props.range.min"
      :disabled="!props.canRefine"
      :value="formatMinValue(props.currentRefinement.min, props.range.min)"
      @input="
        debounceRefiner(props.refine, {
          min: ($event as any).currentTarget.value,
          max: formatMaxValue(props.currentRefinement.max, props.range.max),
        })
      "
    />
    →
    <input
      type="number"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      :min="props.range.min"
      :max="props.range.max"
      :placeholder="props.range.max"
      :disabled="!props.canRefine"
      :value="formatMaxValue(props.currentRefinement.max, props.range.max)"
      @input="
        debounceRefiner(props.refine, {
          min: formatMinValue(props.currentRefinement.min, props.range.min),
          max: ($event as any).currentTarget.value,
        })
      "
    />
  </div>
</template>
