<script setup lang="ts">
import { Ref, defineComponent, inject, onMounted, ref, watch } from 'vue'
import draggable from 'vuedraggable'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { getMimeTypeFromArrayBuffer, isValidMimeType } from '@ankor-io/common/assets/valid'
import { OutlineCloudUpload, OutlineTrash } from '@ankor-io/icons/outline'
import { SolidCheckMark, SolidExclamationCircle, SolidPhotograph, SolidPlus } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import DropZone from '@/components/asset-uploader/DropZone.vue'
import { AuthenticationContext } from '@/iam/types'
import { _requestSignedUrl, uploadFile } from '@/services/assets/upload'
import { assetUploaderValidityStyling } from '@/utils/asset-uploader-validity-styling'

type Asset = {
  uri: string
  path: string
}

type Props = {
  uri: string
  hero?: string
  images: string[]
}

defineComponent(draggable)

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'delete:hero'): void
  (e: 'update:hero', value: number): void
  (e: 'update:vessel:assets', value: { assets: string[] }): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const assetsRef = ref([] as Asset[])
const dragging: Ref<boolean> = ref(false)
// Additional validation for styling
const isUploading: Ref<boolean> = ref(false)
const uploadSuccessful: Ref<boolean> = ref(false) // if the file upload is successful
const isFileTypeSupported: Ref<boolean> = ref(true) // if the file extension is valid or not

onMounted(() => {
  setAssetsList()
})

watch(
  () => props.images,
  () => {
    setAssetsList()
  },
)

const setAssetsList = () => {
  assetsRef.value = props.images.map((image: string) => {
    return { uri: props.uri, path: image }
  })
}

/**
 * Update Assets
 */
const updateAssets = () => {
  const images = assetsRef.value?.map((asset: Asset) => asset.path)
  emit('update:vessel:assets', { assets: images })
}

/**
 * Disassociate asset
 * @param index
 */
const deleteImage = (index: number) => {
  if (index === 0) {
    emit('delete:hero')
  } else {
    assetsRef.value?.splice(index, 1)
    updateAssets()
  }
}

const addFilesWhenDropped = async (files: File[]) => {
  const filesToUpload = await getValidFiles(files)
  if (filesToUpload.length) {
    handleUploadFile(filesToUpload)
  }
}

const addFileFromInput = async (event: Event) => {
  const target = event.target as HTMLInputElement

  const filesToUpload: File[] = await getValidFiles(target.files || [])

  if (filesToUpload.length) {
    handleUploadFile(filesToUpload)
  }
}

const getValidFiles = async (files: File[] | FileList): Promise<File[]> => {
  const parser = new DOMParser()
  const bufferPromises: Promise<any>[] = []
  const filesToUpload: File[] = []

  if (Array.isArray(files)) {
    files.forEach((file) => {
      bufferPromises.push(file.arrayBuffer())
    })
  } else {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)!
      bufferPromises.push(file.arrayBuffer())
    }
  }

  try {
    const arrayBuffers = await Promise.all(bufferPromises)
    arrayBuffers.forEach((arrayBuffer, index) => {
      if (isValidMimeType(files[index].type) && isValidMimeType(getMimeTypeFromArrayBuffer(arrayBuffer, parser))) {
        filesToUpload.push(files[index])
        isFileTypeSupported.value = true
      } else {
        isFileTypeSupported.value = false

        setTimeout(() => {
          isFileTypeSupported.value = true
        }, 3000)
      }
    })
  } catch (error) {
    console.error('Error reading files:', error)
  }

  return filesToUpload
}

/**
 * Handle asset file upload
 * @param file
 */
const handleUploadFile = async (file: File | File[]) => {
  isUploading.value = true
  const token: string | undefined = await authenticationContext.getToken()
  const filesToUpload = Array.isArray(file) ? file : [file]

  const fileUploadPromises = filesToUpload.map(async (fileToUpload: File) => {
    const { mediaUri, signedUrl } = await _requestSignedUrl(props.uri, token!)
    const uploaded: boolean = await uploadFile(signedUrl, fileToUpload)

    assetsRef.value.push({ uri: props.uri, path: mediaUri })

    return uploaded
  })

  const fileUploadResponses = await Promise.all(fileUploadPromises)
  isUploading.value = false
  if (fileUploadResponses.includes(false)) {
    // TODO error message when upload is unsuccessful
  } else {
    updateAssets()
    uploadSuccessful.value = true
    setTimeout(() => {
      uploadSuccessful.value = false
    }, 3000)
  }
}
</script>

<template>
  <div class="flex flex-col text-primary gap-y-5">
    <!-- Assets -->
    <div class="w-full flex flex-col gap-2.5">
      <draggable
        tag="ul"
        item-key="path"
        draggable=".asset-draggable"
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2"
        :list="assetsRef"
        :disabled="false"
        @dragend="updateAssets"
        @start="dragging = true"
        @end="dragging = false"
      >
        <template #item="{ element, index }">
          <li
            class="relative group flex items-center"
            :class="index === 0 ? 'col-span-5' : 'asset-draggable cursor-grab'"
          >
            <div v-if="index === 0" class="h-full w-full max-h-[29rem]">
              <AssetViewerPlaceholder class="flex grow rounded-lg object-cover" :url="`/media/${props.hero}`" />
            </div>
            <div v-else class="w-full h-24">
              <AssetViewerPlaceholder
                class="flex grow rounded-lg object-cover"
                :url="`/media/${element.path}`"
                :widthDescriptors="['320w']"
              />
            </div>
            <!-- On hover backdrop -->
            <div class="absolute top-0 w-full h-full transition-all duration-300 invisible group-hover:visible">
              <div
                class="z-10 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-50 hover:opacity-50"
              ></div>
            </div>
            <!-- On hover add -->
            <button
              v-if="index !== 0 && !dragging"
              type="button"
              class="z-20 absolute flex justify-around items-center top-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:top-1/4 group-hover:-translate-y-1/4 border border-white bg-white w-16 px-2 py-1 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
              @click="emit('update:hero', index)"
            >
              <SolidPlus class="w-3 h-3 fill-white shrink-0" />
              <span class="mr-1 text-white font-medium text-[0.625rem]">Add</span>
            </button>
            <!-- On hover delete -->
            <button
              v-if="!dragging"
              type="button"
              class="z-20 absolute flex justify-around items-center cursor-pointer bottom-0 left-1/2 -translate-x-1/2 border border-red-600 bg-red-300 rounded-md transition-all duration-300 opacity-0 group-hover:opacity-100"
              :class="
                index === 0
                  ? 'w-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 px-3 py-2'
                  : 'w-16 group-hover:bottom-[20%] group-hover:translate-y-[20%] px-2 py-1'
              "
              @click="deleteImage(index)"
            >
              <OutlineTrash
                class="stroke-red-600 dark:stroke-red-700 shrink-0"
                :class="index === 0 ? 'w-4 h-4' : 'w-3 h-3'"
              />
              <span
                class="text-red-600 dark:text-red-600 font-medium"
                :class="index === 0 ? 'text-xs' : 'text-[0.625rem]'"
              >
                Delete
              </span>
            </button>
          </li>
        </template>
      </draggable>
      <DropZone v-if="!dragging" @file-dropped="addFilesWhenDropped($event)" #default="{ dropZoneActive }">
        <div class="flex items-center justify-center w-full">
          <label
            for="dropzone-file"
            class="flex flex-col items-center justify-center w-full h-32 transition-all border-2 border-dashed rounded-lg cursor-pointer"
            :class="[
              assetUploaderValidityStyling(uploadSuccessful, isFileTypeSupported),
              dropZoneActive && 'bg-primary-50 dark:bg-gray-800 border-primary-600 border-dashed',
            ]"
          >
            <Spinner v-if="isUploading" class="w-10 h-10 shrink-0" />
            <template v-else-if="dropZoneActive">
              <SolidPhotograph class="fill-primary-600 w-10 h-10" />
              <p class="text-primary-600 font-semibold text-sm text-center">Drop image here</p>
            </template>
            <template v-else-if="!isFileTypeSupported">
              <SolidExclamationCircle class="w-10 h-10 fill-red-600" />
              <p class="text-sm font-bold text-red-600 mb-2 text-center">File type or size error</p>
              <p class="text-xs font-semibold text-red-600 text-center">JPG, PNG, SVG and GIF supported.</p>
            </template>
            <template v-else-if="uploadSuccessful">
              <SolidCheckMark class="w-10 h-10 fill-green-400 mb-3" />
              <p class="text-sm font-semibold mb-4 text-center text-gray-500 dark:text-gray-400">Upload complete!</p>
            </template>
            <template v-else>
              <OutlineCloudUpload class="w-8 stroke-gray-400 mt-6 first-letter:mb-2 shrink-0" />
              <p class="mb-2 text-sm text-gray-500 text-center">
                <span class="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p class="text-xs text-gray-500 mb-5 font-semibold text-center">JPG, PNG, SVG and GIF supported.</p>
              <input id="dropzone-file" type="file" class="hidden" multiple @change="addFileFromInput" />
            </template>
          </label>
        </div>
      </DropZone>
    </div>
  </div>
</template>
