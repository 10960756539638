<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import * as GeoTZ from 'browser-geo-tz'
import { initPopovers } from 'flowbite'
import { DateTime, Duration } from 'luxon'
import { ComputedRef, Ref, computed, inject, onMounted, ref, watch } from 'vue'

import DatePickerInterval from '@ankor-io/blocks/components/DatePickerInterval/DatePickerInterval.vue'
import { eventRepresentationalBackgroundColour } from '@ankor-io/common/calendar/event-colours'
import { fromIntervalString, toIntervalString } from '@ankor-io/common/date/ISO8601'
import { UUID } from '@ankor-io/common/lang/uuid'
import { CalendarEvent, EventType, EventTypeEnums, Place, Vessel } from '@ankor-io/common/vessel/types'
import { OutlineXMark } from '@ankor-io/icons/outline'
import { OutlineChevronDown } from '@ankor-io/icons/outline'
import { SolidArrowNarrowRight, SolidClock } from '@ankor-io/icons/solid'
import { AuthenticationContext } from '@ankor-io/radar/src/iam/types'

import { getListOfTime } from '@/components/calendar/util/event-modal'
import FloatingInput from '@/components/input/FloatingInput.vue'
import Dropdown from '@/components/modal-content/Dropdown.vue'
import LocationSearchInput from '@/components/modal-content/event-modal-content/LocationSearchInput.vue'
import NotVisibleIconWithPopover from '@/components/modal-content/event-modal-content/NotVisibleIconWithPopover.vue'

export type VesselLabelUri = { label: string; uri: string }
export type VesselVariantLabelId = { label: string; id?: string; turnaround?: string }

type EventModalProps = {
  vesselLabelValue?: VesselLabelUri
  vesselLabelValues?: VesselLabelUri[]
  vesselVariants?: VesselVariantLabelId[]
  calendarEvent?: CalendarEvent
}

const props = defineProps<EventModalProps>()

const emit = defineEmits<{
  (e: 'add:event', vesselUri: string, event: CalendarEvent): void
  (e: 'update:event', vesselUri: string, event: CalendarEvent): void
  (e: 'close:modal'): void
}>()

// Algolia search client and Commodore index
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const listOfClaimedVessels: Ref<VesselLabelUri[]> = ref([])
const validateFieldsRef: Ref<boolean> = ref(false)
const preselectedUnitLabel = 'Select unit'
const preselectedPricingLabel = 'Select a pricing'
const embarkationRef: Ref<{ date: string; time: string; timeZone: string }> = ref({
  date: '',
  time: '',
  timeZone: 'Etc/UTC',
})
const disembarkationRef: Ref<{ date: string; time: string; timeZone: string }> = ref({
  date: '',
  time: '',
  timeZone: 'Etc/UTC',
})
const vesselToUpdate: Ref<VesselLabelUri> = ref(
  props.vesselLabelValue || {
    label: '',
    uri: '',
  },
)
const vesselsLoading: Ref<boolean> = ref(false)
const vesselVariants: Ref<VesselVariantLabelId[]> = ref(props.vesselVariants || [])
const vesselVariantsLoading: Ref<boolean> = ref(false)
const isEventTypeOptionsOpen: Ref<boolean> = ref(false)

const isCalendarEventType = (
  event: { eventType: EventType } & Omit<CalendarEvent, 'eventType'>,
): event is CalendarEvent => {
  return (
    event.eventType === EventTypeEnums.BOOKED ||
    event.eventType === EventTypeEnums.TENTATIVE ||
    event.eventType === EventTypeEnums.MAINTENANCE ||
    event.eventType === EventTypeEnums.IN_TRANSIT ||
    event.eventType === EventTypeEnums.FLEXIBLE_USE ||
    event.eventType === EventTypeEnums.BOAT_SHOW ||
    event.eventType === EventTypeEnums.OTHER
  )
}

const newEvent: { eventType: EventType } & Omit<CalendarEvent, 'eventType'> = {
  id: UUID.timeBased(),
  title: '',
  interval: '',
  allDay: false,
  eventType: EventTypeEnums.BOOKED,
  reference: '',
  variantId: '',
  variantLabel: '',
  turnaroundDuration: '',
  notes: '',
  showTitle: false,
}

const eventToUpdate: Ref<{ eventType: EventType } & Omit<CalendarEvent, 'eventType'>> = ref(
  props.calendarEvent || newEvent,
)

const eventTypesOptions: { label: EventType }[] = [
  { label: EventTypeEnums.BOOKED },
  { label: EventTypeEnums.TENTATIVE },
  { label: EventTypeEnums.FLEXIBLE_USE },
  { label: EventTypeEnums.IN_TRANSIT },
  { label: EventTypeEnums.MAINTENANCE },
  { label: EventTypeEnums.BOAT_SHOW },
  { label: EventTypeEnums.OTHER },
]

// TURNAROUND DURATION Start
/**
 * Convert duration to human readable time (FIXME: hardcoded to be for "Days".)
 * @param duration the ISO8601 format duration
 */
const parseDuration = (duration: string): { period: number | null; unit: string | null } => {
  if (!duration) {
    return {
      period: null,
      unit: null,
    }
  }

  const durationObject = Duration.fromISO(duration).toObject()
  return {
    period: Object.values(durationObject)[0],
    unit: Object.keys(durationObject)[0],
  }
}

const turnaroundRef: Ref<{ period: number | null; unit: string | null }> = ref(
  parseDuration(props.calendarEvent?.turnaroundDuration ?? ''),
)

const updateTurnAroundPeriod = (value: number) => {
  turnaroundRef.value.period = value

  if (!turnaroundRef.value.unit) {
    return
  }

  const duration = Duration.fromObject({
    [turnaroundRef.value.unit]: turnaroundRef.value.period,
  }).toString()

  eventToUpdate.value.turnaroundDuration = duration || undefined
}

const updateTurnAroundUnit = (value: string) => {
  turnaroundRef.value.unit = value

  if (turnaroundRef.value.period === null || value === '') {
    return
  }

  const duration = Duration.fromObject({
    [turnaroundRef.value.unit]: turnaroundRef.value.period,
  }).toString()

  eventToUpdate.value.turnaroundDuration = duration || undefined
}
// TURNAROUND DURATION End

const getLocationTimeZone = async (location?: Place) => {
  const latitude = location?.coordinates.latitude
  const longitude = location?.coordinates.longitude
  if (latitude && longitude) {
    const timeZones = await GeoTZ.find(latitude, longitude)
    return timeZones[0]
  }
  return 'Etc/UTC'
}

const getDateTime = async (key: 'start' | 'end', interval?: string) => {
  if (!interval) {
    return {
      date: '',
      time: '',
      timeZone: 'Etc/UTC',
    }
  }

  const { start, end } = fromIntervalString(interval, { setZone: true })
  if (key === 'start') {
    return {
      date: start!.toFormat('yyyy-MM-dd'),
      time: start!.toFormat('hh:mm a') ?? '00:00 AM',
      timeZone: await getLocationTimeZone(eventToUpdate.value.embark),
    }
  }

  return {
    date: end!.toFormat('yyyy-MM-dd'),
    time: end!.toFormat('hh:mm a') ?? '00:00 AM',
    timeZone: await getLocationTimeZone(eventToUpdate.value.disembark),
  }
}

const startTimeComputed = computed(() => {
  if (embarkationRef.value.time && !eventToUpdate.value.allDay) {
    return DateTime.fromFormat(embarkationRef.value.time, 'hh:mm a').toFormat('HH:mm')
  }
  return '00:00'
})

const endTimeComputed = computed(() => {
  if (disembarkationRef.value.time && !eventToUpdate.value.allDay) {
    return DateTime.fromFormat(disembarkationRef.value.time, 'hh:mm a').toFormat('HH:mm')
  }
  return '23:59'
})

const startDateTime = computed(() =>
  DateTime.fromISO(`${embarkationRef.value.date}T${startTimeComputed.value}`, {
    zone: embarkationRef.value.timeZone || 'Etc/UTC',
    setZone: true,
  }),
)

const endDateTime = computed(() =>
  DateTime.fromISO(`${disembarkationRef.value.date}T${endTimeComputed.value}`, {
    zone: disembarkationRef.value.timeZone || 'Etc/UTC',
    setZone: true,
  }),
)

const isStartAfterEndDateTime = (): boolean => {
  if (startDateTime.value.startOf('millisecond') > endDateTime.value.startOf('millisecond')) {
    return true
  }
  return false
}

// embark disembark interval
const intervalRef = computed(() => {
  if (!embarkationRef.value.date || !disembarkationRef.value.date) {
    return ''
  }

  try {
    if (isStartAfterEndDateTime()) {
      return ''
    }

    // Pass '' to toIntervalString so that it doesnt default to etc/UTC
    return toIntervalString(startDateTime.value, endDateTime.value, '')
  } catch (error) {
    return ''
  }
})

const embarkationTimeOffset = computed(() => {
  if (!embarkationRef.value.date || !eventToUpdate.value.embark) {
    return ''
  }
  const offset = startDateTime.value.toFormat('ZZ')
  return `UTC${offset}`
})

const disembarkationTimeOffset = computed(() => {
  if (!disembarkationRef.value.date || !eventToUpdate.value.disembark) {
    return ''
  }
  const offset = endDateTime.value.toFormat('ZZ')
  return `UTC${offset}`
})

/**
 * Update embark disdembark values when all day is toggled
 * @param checked
 */
const toggleAllDay = async (checked: boolean) => {
  eventToUpdate.value.allDay = checked
}

/**
 * add new event
 */
const addEvent = () => {
  eventToUpdate.value.interval = intervalRef.value
  if (isCalendarEventType(eventToUpdate.value)) {
    emit('add:event', vesselToUpdate.value.uri, eventToUpdate.value)
  }
  close()
}

/**
 * update existing event
 */
const updateEvent = () => {
  eventToUpdate.value.interval = intervalRef.value
  if (isCalendarEventType(eventToUpdate.value)) {
    emit('update:event', vesselToUpdate.value.uri, eventToUpdate.value)
  }
  close()
}

const getPricingOptions = () => {
  const options = vesselVariants.value.map((variant) => ({
    label: variant.label,
    value: variant.id,
  }))
  options.unshift({ label: preselectedPricingLabel, value: '' })
  return options
}

const isAddUpdateDisabled: ComputedRef<boolean> = computed(
  () =>
    !vesselToUpdate.value.uri || !intervalRef.value || !eventToUpdate.value.embark || !eventToUpdate.value.disembark,
)

const areMandatoryFieldsFilled = () => {
  if (isAddUpdateDisabled.value) {
    validateFieldsRef.value = true
    return false
  }
  validateFieldsRef.value = false
  return true
}

const onEmbarkationLocationChange = async ($event?: Place) => {
  eventToUpdate.value.embark = $event
  embarkationRef.value.timeZone = await getLocationTimeZone($event)
}

const onDisembarkationLocationChange = async ($event?: Place) => {
  eventToUpdate.value.disembark = $event
  disembarkationRef.value.timeZone = await getLocationTimeZone($event)
}

/**
 * Close modal
 */
const close = () => {
  emit('close:modal')
}

const onEventTypeChange = (eventType: { label: EventType }) => {
  eventToUpdate.value.eventType = eventType.label
  isEventTypeOptionsOpen.value = false
}

const onPricingChange = ($event: { label: string; value?: string }) => {
  eventToUpdate.value.variantLabel = $event.label === preselectedPricingLabel ? '' : $event.label
  eventToUpdate.value.variantId = $event.value
  const turnaround = vesselVariants.value.find((variant) => variant.label === $event.label)?.turnaround
  turnaroundRef.value = parseDuration(turnaround || '')
  if (turnaroundRef.value.period) {
    updateTurnAroundPeriod(turnaroundRef.value.period)
  }
  if (turnaroundRef.value.unit) {
    updateTurnAroundUnit(turnaroundRef.value.unit)
  }
}

onMounted(async () => {
  initPopovers()
  if (!props.vesselLabelValue) {
    listOfClaimedVessels.value = props.vesselLabelValues!
  } else {
    listOfClaimedVessels.value = [{ label: props.vesselLabelValue.label, uri: props.vesselLabelValue.uri }]
  }

  embarkationRef.value = await getDateTime('start', props.calendarEvent?.interval)
  disembarkationRef.value = await getDateTime('end', props.calendarEvent?.interval)
})

watch(vesselToUpdate, async (newVesselToUpdate) => {
  vesselVariantsLoading.value = true
  const response = await fetch(`/api/vessel/${decodeURIComponent(newVesselToUpdate.uri)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    const responseFromServer: Vessel = await response.json()
    vesselVariants.value = responseFromServer.variants.map((variant) => ({
      label: variant.label,
      id: variant.id,
      turnaround: variant.turnaround || '',
    }))
  }
  vesselVariantsLoading.value = false
})

const updateEmbarkationDate = (date: string) => {
  embarkationRef.value.date = date
  if (
    disembarkationRef.value.date === '' ||
    DateTime.fromISO(embarkationRef.value.date) > DateTime.fromISO(disembarkationRef.value.date)
  ) {
    disembarkationRef.value.date = date
  }
}
</script>
<template>
  <div
    tabindex="1"
    aria-hidden="true"
    class="fixed z-50 inset-0 backdrop-blur-[0.125rem] flex justify-center items-center"
    @click.stop
  >
    <div
      class="w-full md:max-w-6xl max-h-screen overflow-auto relative bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded-lg shadow-md grid grid-row-3 divide-y dark:divide-gray-600 text-sm text-gray-900 dark:text-white"
    >
      <!-- close icon -->
      <div class="absolute right-8 top-8 flex justify-end">
        <OutlineXMark
          class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400"
          @click.stop="close"
        />
      </div>

      <!-- Modal Header -->
      <div class="p-6 text-lg font-semibold text-gray-900 dark:text-gray-200">Event:</div>

      <!-- Modal Body -->
      <div class="p-6 flex flex-col text-gray-900 dark:text-white">
        <div class="flex gap-x-5 gap-y-2 flex-col md:flex-row">
          <!-- Event type -->
          <div class="flex-shrink-0 w-40">
            <p class="block text-sm mb-2 font-medium text-gray-900 dark:text-gray-50">Event type</p>
            <button
              type="button"
              class="inline-flex items-center font-medium justify-between gap-x-2 p-1 w-full rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              @click="isEventTypeOptionsOpen = true"
            >
              <span
                class="flex-shrink-0 w-4 h-4 rounded-full"
                :class="[eventRepresentationalBackgroundColour(eventToUpdate.eventType)]"
              ></span>
              <p class="text-ellipsis overflow-hidden">
                {{ eventToUpdate.eventType ? eventToUpdate.eventType : 'Select' }}
              </p>
              <OutlineChevronDown class="w-5" />
            </button>
            <!-- Dropdown -->
            <OnClickOutside @trigger="isEventTypeOptionsOpen = false">
              <div
                class="z-50 absolute w-40 my-2 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
                v-show="isEventTypeOptionsOpen"
              >
                <ul class="py-2 font-medium" role="none">
                  <li
                    v-for="eventType of eventTypesOptions"
                    :key="`${eventType}`"
                    class="block px-4 py-2 text-sm cursor-pointer text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    @click.stop="onEventTypeChange(eventType)"
                  >
                    <div class="inline-flex items-center gap-x-2">
                      <span
                        class="flex-shrink-0 w-4 h-4 rounded-full"
                        :class="[eventRepresentationalBackgroundColour(eventType.label)]"
                      ></span>
                      {{ eventType.label }}
                    </div>
                  </li>
                </ul>
              </div>
            </OnClickOutside>
          </div>

          <div class="w-full">
            <!-- Event title -->
            <FloatingInput
              type="text"
              name="title"
              id="title"
              placeholder=" "
              :value="eventToUpdate.title"
              @update:input="eventToUpdate.title = ($event.target as HTMLInputElement).value"
              label="Event Title"
            >
            </FloatingInput>

            <!-- Show event title -->
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                id="show-title-checkbox"
                :checked="eventToUpdate.showTitle"
                class="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @change="eventToUpdate.showTitle = ($event.target as HTMLInputElement)?.checked"
              />
              <label for="show-title-checkbox" class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300">
                Show Event title on the external Calendar
              </label>
            </div>
          </div>
        </div>

        <!-- Embarkation and Disembarkation -->
        <div class="grid grid-row-3 lg:grid-cols-11">
          <!-- Embarkation -->
          <div class="lg:col-span-5 gap-x-4 mt-6">
            <p class="block text-sm font-medium">Embarkation</p>
            <div class="mt-2 grid grid-flow-col grid-cols-2 gap-2">
              <!-- Datepicker -->
              <DatePickerInterval
                placeholder="Start Date *"
                id="embarkationDate"
                :date="embarkationRef.date"
                :validation-error="(validateFieldsRef && !embarkationRef.date) || isStartAfterEndDateTime()"
                @update:date="updateEmbarkationDate"
              />
              <!-- Select embarkment time -->
              <div class="grid grid-cols-2">
                <Dropdown
                  v-if="!eventToUpdate.allDay"
                  placeholder="Time"
                  :options="getListOfTime(30)"
                  :selected="embarkationRef.time"
                  :disabled="!!eventToUpdate.allDay"
                  :validation-error="!!embarkationRef.time && isStartAfterEndDateTime()"
                  @select:item="embarkationRef.time = $event.value!"
                />
                <div class="px-2 my-auto">
                  <p class="text-xs">Timezone</p>
                  <p class="text-gray-500 dark:text-gray-400 text-sm">
                    {{ embarkationTimeOffset ? embarkationTimeOffset : 'Unspecified' }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Location -->
            <LocationSearchInput
              class="mt-2 w-full"
              placeholder="Start Location *"
              :place="eventToUpdate.embark"
              :validation-error="validateFieldsRef && !eventToUpdate.embark"
              @update:place="onEmbarkationLocationChange"
            />
          </div>

          <!-- Divider arrow -->
          <div class="flex justify-center align-middle mt-6">
            <SolidArrowNarrowRight class="rotate-90 lg:rotate-0 w-5 text-gray-900 dark:text-gray-400" />
          </div>

          <!-- Disembarkation -->
          <div class="lg:col-span-5 gap-x-4 gap-y-2 mt-6">
            <p class="block text-sm font-medium">Disembarkation</p>
            <div class="mt-2 grid grid-flow-col grid-cols-2 gap-2">
              <!-- Datepicker -->
              <DatePickerInterval
                placeholder="End Date *"
                id="disembarkationDate"
                :date="disembarkationRef.date"
                :validation-error="(validateFieldsRef && !disembarkationRef.date) || isStartAfterEndDateTime()"
                @update:date="disembarkationRef.date = $event"
              />
              <!-- Select disembarkment time -->
              <div class="grid grid-cols-2">
                <Dropdown
                  v-if="!eventToUpdate.allDay"
                  placeholder="Time"
                  :options="getListOfTime(30)"
                  :selected="disembarkationRef.time"
                  :disabled="!!eventToUpdate.allDay"
                  :validation-error="!!disembarkationRef.time && isStartAfterEndDateTime()"
                  @select:item="disembarkationRef.time = $event.value!"
                />
                <div class="px-2 my-auto">
                  <p class="text-xs">Timezone</p>
                  <p class="text-gray-500 dark:text-gray-400 text-sm">
                    {{ disembarkationTimeOffset ? disembarkationTimeOffset : 'Unspecified' }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Location -->
            <LocationSearchInput
              class="mt-2 w-full"
              placeholder="End Location *"
              :place="eventToUpdate.disembark"
              :validation-error="validateFieldsRef && !eventToUpdate.disembark"
              @update:place="onDisembarkationLocationChange"
            />
          </div>
        </div>

        <p v-if="isStartAfterEndDateTime()" class="mt-1 text-sm text-red-500 dark:text-red-400">
          Start time can't be after the end time.
        </p>

        <!-- All day event -->
        <div class="flex items-center mt-4">
          <input
            type="checkbox"
            id="allDay-checkbox"
            :checked="eventToUpdate.allDay"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            @change="toggleAllDay(($event.target as HTMLInputElement)?.checked)"
          />
          <label for="allDay-checkbox" class="ml-2 text-xs font-medium text-gray-500 dark:text-gray-400">
            All day event
          </label>
        </div>

        <p class="mt-3 text-xs text-gray-500 dark:text-gray-400">
          Time Zone determined by embarkation and disembarkation locations.
        </p>

        <div class="mt-6">
          <!-- Select vessel -->
          <Dropdown
            class="w-full md:w-1/2"
            label="Yacht"
            placeholder="Select yacht*"
            :options="listOfClaimedVessels?.map((vessel) => ({ label: vessel.label, value: vessel.uri }))"
            :selected="vesselToUpdate.label"
            :disabled="!!props.vesselLabelValue || vesselsLoading"
            :loading="vesselsLoading"
            :validation-error="validateFieldsRef && !vesselToUpdate.uri"
            @select:item="vesselToUpdate = { label: $event.label, uri: $event.value! }"
          />
        </div>

        <div class="flex flex-col lg:flex-row gap-x-2 gap-y-4 mt-6">
          <div class="flex flex-wrap gap-x-2 gap-y-4">
            <!-- Select variant (for pricing) -->
            <div class="flex-grow lg:w-[19.375rem]">
              <div class="flex mb-2 text-sm font-medium gap-x-2">
                Pricing
                <NotVisibleIconWithPopover id="pricing-not-visible-popover" />
              </div>
              <Dropdown
                :placeholder="preselectedPricingLabel"
                :options="getPricingOptions()"
                :selected="eventToUpdate.variantLabel"
                :disabled="vesselVariantsLoading || !vesselToUpdate.uri"
                :loading="vesselVariantsLoading"
                @select:item="onPricingChange"
              />
            </div>
            <!-- Turn around duration -->
            <div class="flex gap-x-4 items-end justify-start lg:justify-center">
              <!-- Turn Around -->
              <div class="w-28">
                <FloatingInput
                  type="number"
                  name="period"
                  id="period"
                  placeholder=" "
                  :value="turnaroundRef.period?.toString()"
                  @update:input="updateTurnAroundPeriod(Number(($event.target as HTMLInputElement).value))"
                  label="Turn Around"
                >
                  <template #labelIcon>
                    <SolidClock class="w-4" />
                  </template>
                </FloatingInput>
              </div>
              <!-- Turn around unit -->
              <Dropdown
                :options="[
                  { label: preselectedUnitLabel, value: '' },
                  { label: 'Hours', value: 'hours' },
                  { label: 'Days', value: 'days' },
                ]"
                class="min-w-[6.875rem]"
                :selected="
                  turnaroundRef.unit === 'hours'
                    ? 'Hours'
                    : turnaroundRef.unit === 'days'
                    ? 'Days'
                    : preselectedUnitLabel
                "
                @select:item="updateTurnAroundUnit($event.value || '')"
              />
            </div>
          </div>

          <!-- Reference -->
          <div class="flex-grow">
            <label for="reference" class="flex mb-2 text-sm font-medium gap-x-2">
              Booking Reference
              <NotVisibleIconWithPopover id="booking-ref-not-visible-popover" />
            </label>
            <input
              type="text"
              id="reference"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Client name, Reference no, External contractor ..."
              autocomplete="off"
              :value="eventToUpdate.reference"
              @blur="eventToUpdate.reference = ($event.target as HTMLInputElement).value"
            />
          </div>
        </div>

        <!-- Notes -->
        <div class="mt-6">
          <label for="event-notes" class="flex mb-2 text-sm font-medium gap-x-2">
            Event Notes
            <NotVisibleIconWithPopover id="event-notes-not-visible-popover" />
          </label>
          <textarea
            id="event-notes"
            rows="4"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Write text here..."
            @input="eventToUpdate.notes = ($event.target as HTMLTextAreaElement)?.value"
            :value="eventToUpdate.notes"
          ></textarea>
        </div>

        <!-- Privacy Policy -->
        <div class="text-xs mt-1 text-gray-500 dark:text-gray-400">
          See our <a class="font-semibold" href="https://ankor.io/privacy-policy" target="_blank">Privacy Policy</a>
        </div>
      </div>

      <!-- Modal actions -->
      <div class="p-6 inline-flex justify-center gap-x-4">
        <button
          type="button"
          id="cancel-modal-button"
          class="text-gray-500 dark:text-gray-200 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-500 dark:hover:bg-gray-600 focus:outline-none dark:focus:ring-gray-600 border border-gray-200 dark:border-gray-400"
          @click="close()"
        >
          Cancel
        </button>
        <button
          v-if="props.calendarEvent"
          type="button"
          id="update-modal-button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-blue-400 disabled:dark:bg-blue-500"
          @click.stop="
            () => {
              if (areMandatoryFieldsFilled()) {
                updateEvent()
              }
            }
          "
        >
          Update Event
        </button>
        <button
          v-else
          type="button"
          id="add-modal-button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-blue-400 disabled:dark:bg-blue-500"
          @click.stop="
            () => {
              if (areMandatoryFieldsFilled()) {
                addEvent()
              }
            }
          "
        >
          Add Event
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
/* for ios mobile to avoid bottom navbar */
@supports (-webkit-touch-callout: none) {
  .max-h-screen {
    max-height: -webkit-fill-available;
  }
}
</style>
