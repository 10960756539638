<script setup lang="ts">
import { initAccordions, initDropdowns, initPopovers } from 'flowbite'
import { onMounted } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { type Vessel } from '@ankor-io/common/vessel/types'
import { OutlinePlus, OutlineTrash } from '@ankor-io/icons/outline'
import { SolidInformationCircle } from '@ankor-io/icons/solid'

import Button from '@/components/Button.vue'
import FloatingInput from '@/components/input/FloatingInput.vue'
import InputWithTags from '@/components/input/InputWithTags.vue'
import StandardInput from '@/components/input/StandardInput.vue'

type Props = {
  vessel: Vessel
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:vessel', value: Vessel): void
}>()

const HELP_TEXT =
  'In this section, you can add all the specification, dimensions, cabin layout, performance, license and registration details and more about your yacht. Details here do not change no matter the client that books with you. '

const ADDITIONAL_NOTES_TEXT =
  'This optional section is for additional details you might want to include like general conditions, payment terms, policies, and more.'

onMounted(() => {
  initPopovers()
  initDropdowns()
  initAccordions()
})

const updateVesselBlueprintItemAsString = (key: string, event: Event) => {
  const value: string = (event.target as HTMLInputElement).value
  const vessel = ObjectUtil.deepCopy(props.vessel)
  vessel.blueprint[key] = value.toString()
  emit('update:vessel', vessel)
}

const updateVesselBlueprintItemAsNumber = (key: string, event: Event) => {
  const targetValue: string = (event.target as HTMLInputElement).value
  const value: number = isNaN(Number(targetValue)) ? 0 : Number(targetValue)

  if (isNaN(Number(targetValue))) {
    console.debug(`Error parsing number for ${key}: ${value}`)
    return
  }

  const vessel = ObjectUtil.deepCopy(props.vessel)
  vessel.blueprint[key] = value
  emit('update:vessel', vessel)
}

/**
 * Update vessel length and category
 * @param key
 * @param event
 */
const updateVesselLength = (event: Event) => {
  const targetValue: string = (event.target as HTMLInputElement).value
  const value: number = Number(targetValue)
  const vessel: Vessel = ObjectUtil.deepCopy(props.vessel)
  vessel.blueprint.length = value

  if (value < 10) {
    vessel.category = 'Mass market'
  } else if (value < 24) {
    vessel.category = 'Luxury yacht'
  } else if (value < 70) {
    vessel.category = 'Superyacht'
  } else {
    vessel.category = 'Megayacht'
  }

  emit('update:vessel', vessel)
}

const updateVesselDescription = (event: Event): void => {
  const value: string = (event.target as HTMLInputElement).value
  const vessel: Vessel = ObjectUtil.deepCopy(props.vessel)
  vessel.description = value
  emit('update:vessel', vessel)
}

const updateYachtType = (option: string, event: Event) => {
  const isChecked = (event.target as HTMLInputElement).checked
  const vessel: Vessel = ObjectUtil.deepCopy(props.vessel)
  if (!vessel.yachtType) {
    vessel.yachtType = []
  }
  if (isChecked) {
    vessel.yachtType.push(option)
  } else {
    const index = vessel.yachtType.indexOf(option)
    index >= 0 ? vessel.yachtType.splice(index, 1) : vessel.yachtType
  }
  emit('update:vessel', vessel)
}

const updateCharterType = (option: string, event: Event) => {
  const isChecked = (event.target as HTMLInputElement).checked
  const vessel: Vessel = ObjectUtil.deepCopy(props.vessel)
  if (!vessel.charterType) {
    vessel.charterType = []
  }
  if (isChecked) {
    vessel.charterType.push(option)
  } else {
    const index = vessel.charterType.indexOf(option)
    index >= 0 ? vessel.charterType.splice(index, 1) : vessel.charterType
  }
  emit('update:vessel', vessel)
}

/**
 * Add an item to a vessel array, or a blueprint array
 * @param value The value to add to an array
 * @param key The field to update
 * @param isBlueprint If the update is happening on a blueprint object
 */
const addToArray = (value: string, key: string, isBlueprint: boolean): void => {
  const vessel = ObjectUtil.deepCopy(props.vessel)
  if (isBlueprint) {
    if (vessel.blueprint[key]?.length) {
      vessel.blueprint[key].push(value)
    } else vessel.blueprint[key] = [value]
  } else {
    if (vessel[key]?.length) {
      vessel[key].push(value)
    } else vessel[key] = [value]
  }

  emit('update:vessel', vessel)
}

/**
 * Remove a type from a vessel
 * @param index The position in the type array
 * @param key The field to remove from
 * @param isBlueprint If the removal is happening on a blueprint object
 */
const removeFromArray = (index: number, key: string, isBlueprint: boolean): void => {
  const vessel = ObjectUtil.deepCopy(props.vessel)
  if (isBlueprint) {
    vessel.blueprint[key].splice(index, 1)
  } else {
    vessel[key].splice(index, 1)
  }

  emit('update:vessel', vessel)
}

/**
 * Update cabin layout
 * @param index
 * @param key
 * @param event
 */
const updateCabinLayout = (index: number, key: 'label' | 'value', event: Event) => {
  const value: string = (event.target as HTMLInputElement).value
  const vessel: Vessel = ObjectUtil.deepCopy(props.vessel)
  if (!vessel.blueprint.cabinLayout) {
    vessel.blueprint.cabinLayout = []
  }
  vessel.blueprint.cabinLayout[index][key] = value
  emit('update:vessel', vessel)
}

/**
 * Add a cabin layout
 */
const addCabinLayoutItem = () => {
  const vessel: Vessel = ObjectUtil.deepCopy(props.vessel)
  if (!vessel.blueprint.cabinLayout) {
    vessel.blueprint.cabinLayout = []
  }

  vessel.blueprint.cabinLayout.push({ label: '', value: '' })
  emit('update:vessel', vessel)
}

/**
 * Remove a cabin layout
 * @param index
 */
const removeCabinLayoutItem = (index: number) => {
  const vessel: Vessel = ObjectUtil.deepCopy(props.vessel)
  if (vessel.blueprint.cabinLayout) {
    vessel.blueprint.cabinLayout.splice(index, 1)
    emit('update:vessel', vessel)
  }
}

/**
 * Update additional info
 * @param index
 * @param key
 * @param event
 */
const updateAdditionalInfo = (index: number, key: 'label' | 'value', event: Event) => {
  const value: string = (event.target as HTMLInputElement).value
  const vessel: Vessel = ObjectUtil.deepCopy(props.vessel)
  if (!vessel.additionalInfo) {
    vessel.additionalInfo = []
  }
  vessel.additionalInfo[index][key] = value
  emit('update:vessel', vessel)
}

/**
 * Add an additional info
 */
const addAdditionalInfo = () => {
  const vessel = ObjectUtil.deepCopy(props.vessel)
  if (!vessel.additionalInfo) {
    vessel.additionalInfo = []
  }

  vessel.additionalInfo.push({ label: '', value: '' })
  emit('update:vessel', vessel)
}
</script>
<template>
  <div class="flex flex-col text-primary w-full gap-y-8">
    <div class="flex gap-x-2.5 items-end">
      <h3 class="text-2xl font-semibold leading-none text-black dark:text-white">Specifications</h3>
      <button type="button" data-popover-placement="right" data-popover-target="seasons-popover">
        <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
      </button>
      <div
        data-popover
        role="tooltip"
        id="seasons-popover"
        class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
      >
        <div class="max-w-[25rem] p-2">{{ HELP_TEXT }}</div>
        <div data-popper-arrow></div>
      </div>
    </div>

    <!-- Basic Information -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Basic Information</h5>
      <div class="grid sm:grid-cols-2 gap-x-2 gap-y-4">
        <!-- Yacht Name -->
        <FloatingInput
          class="mt-2"
          type="text"
          name="yachtName"
          id="yachtName"
          placeholder=" "
          label="Yacht Name"
          :readonly="true"
          :value="props.vessel.blueprint.name"
          @update:input="updateVesselBlueprintItemAsString('name', $event)"
        />

        <!-- MMSI -->
        <FloatingInput
          class="mt-2"
          type="text"
          name="mmsi"
          id="mmsi"
          placeholder=" "
          label="MMSI"
          :readonly="true"
          :value="props.vessel.blueprint.mmsi"
          @update:input="updateVesselBlueprintItemAsString('mmsi', $event)"
        />

        <!-- Yacht Type -->
        <div class="col-span-2">
          <p class="text-sm font-medium mb-2 text-gray-900 dark:text-white">Yacht Type</p>
          <div class="flex flex-wrap gap-x-5 gap-y-2">
            <div
              v-for="option in [
                'Gulet',
                'Sailing',
                'Motor',
                'Catamaran',
                'Power Catamaran',
                'Classic',
                'Expedition',
                'Sport fishing',
              ]"
              :key="option"
              class="flex items-center gap-x-2"
            >
              <input
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                :checked="props.vessel.yachtType?.includes(option)"
                @click.stop="updateYachtType(option, $event)"
                :id="`label-${option}`"
              />
              <label :for="`label-${option}`" class="text-gray-500 dark:text-gray-400 text-sm">{{ option }}</label>
            </div>
          </div>
        </div>

        <!-- charterType -->
        <div class="col-span-2">
          <p class="text-sm font-medium mb-2 text-gray-900 dark:text-white">Crewed / Bareboat</p>
          <div class="flex flex-wrap gap-x-5 gap-y-2">
            <div v-for="option in ['Bareboat', 'Crewed']" :key="option" class="flex items-center gap-x-2">
              <input
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                :checked="props.vessel.charterType?.includes(option)"
                @click.stop="updateCharterType(option, $event)"
                :id="`label-${option}`"
              />
              <label :for="`label-${option}`" class="text-gray-500 dark:text-gray-400 text-sm">{{ option }}</label>
            </div>
          </div>
        </div>

        <!-- Searchable Tags -->
        <div class="col-span-2">
          <p class="text-sm font-medium mb-2 text-gray-900 dark:text-white">Searchable Tags</p>
          <InputWithTags
            placeholder="Add a new tag"
            :tags="props.vessel.tags"
            @add:tag="addToArray($event, 'tags', false)"
            @remove:tag="removeFromArray($event, 'tags', false)"
          />
        </div>
        <!-- Model -->
        <StandardInput
          id="vessel-model"
          label="Model"
          type="search"
          placeholder="Enter the Model"
          :value="props.vessel.blueprint.model"
          @update:input="updateVesselBlueprintItemAsString('model', $event)"
        />
        <!-- Crew -->
        <StandardInput
          id="vessel-max-crew"
          label="Crew"
          type="number"
          placeholder="Enter the number of Crew"
          :step="1"
          :value="props.vessel.blueprint.maxCrew"
          @update:input="updateVesselBlueprintItemAsNumber('maxCrew', $event)"
        />
        <!-- Sleeps -->
        <StandardInput
          id="vessel-sleeps"
          label="Sleeps"
          type="number"
          placeholder="Enter the number of Sleeps"
          :step="1"
          :value="props.vessel.blueprint.sleeps"
          @update:input="updateVesselBlueprintItemAsNumber('sleeps', $event)"
        />
        <!-- Cruising Capacity -->
        <StandardInput
          id="vessel-cruising-capacity"
          label="Cruising Capacity"
          type="number"
          placeholder="Enter the cruising capacity"
          :step="1"
          :value="props.vessel.blueprint.cruisingCapacity"
          @update:input="updateVesselBlueprintItemAsNumber('cruisingCapacity', $event)"
        />
        <!-- Static Capacity -->
        <StandardInput
          id="vessel-static-capacity"
          label="Static Capacity"
          type="number"
          placeholder="Enter the static capacity"
          :step="1"
          :value="props.vessel.blueprint.staticCapacity"
          @update:input="updateVesselBlueprintItemAsNumber('staticCapacity', $event)"
        />
      </div>
    </div>

    <!-- Design & Builder -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Design & Builder</h5>
      <div class="grid sm:grid-cols-2 gap-x-2 gap-y-4">
        <!-- Build Year -->
        <StandardInput
          id="vessel-build-year"
          label="Build Year (YYYY)"
          type="number"
          placeholder="Enter the Build Year"
          :value="props.vessel.blueprint.builtYear"
          @update:input="updateVesselBlueprintItemAsNumber('builtYear', $event)"
        />
        <!-- Builder -->
        <StandardInput
          id="vessel-builder"
          label="Builder"
          type="search"
          placeholder="Enter the Builder"
          :value="props.vessel.blueprint.make"
          @update:input="updateVesselBlueprintItemAsString('make', $event)"
        />
        <!-- Architect -->
        <StandardInput
          id="vessel-architect"
          label="Architect"
          type="search"
          placeholder="Enter the Architect"
          :value="props.vessel.blueprint.architect"
          @update:input="updateVesselBlueprintItemAsString('architect', $event)"
        />
        <!-- Interior Designer -->
        <StandardInput
          id="vessel-interior-designer"
          label="Interior Designer"
          type="search"
          placeholder="Enter the Interior Designer"
          :value="props.vessel.blueprint.interiorDesigner"
          @update:input="updateVesselBlueprintItemAsString('interiorDesigner', $event)"
        />
        <!-- Refit Year -->
        <StandardInput
          id="vessel-refit-year"
          label="Refit Year"
          type="number"
          placeholder="Enter the Refit Year"
          :value="props.vessel.blueprint.refitYear"
          @update:input="updateVesselBlueprintItemAsNumber('refitYear', $event)"
        />
      </div>
    </div>

    <!-- Yacht Description -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <label for="yacht-description" class="font-bold text-black dark:text-white">Yacht Description</label>
      <textarea
        rows="3"
        id="yacht-description"
        placeholder="Enter the Description"
        class="block py-3 px-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :value="props.vessel.description"
        @blur="updateVesselDescription($event)"
      ></textarea>
    </div>

    <!-- License & Registration -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">License & Registration</h5>
      <div class="grid sm:grid-cols-2 gap-x-2 gap-y-4">
        <!-- License -->
        <StandardInput
          id="vessel-license"
          label="License"
          type="search"
          placeholder="Enter the License"
          :value="props.vessel.blueprint.flag"
          @update:input="updateVesselBlueprintItemAsString('flag', $event)"
        />
        <!-- Registration -->
        <StandardInput
          id="vessel-registration"
          label="Registration"
          type="search"
          placeholder="Enter the Registration"
          :value="props.vessel.blueprint.registryPort"
          @update:input="updateVesselBlueprintItemAsString('registryPort', $event)"
        />
      </div>
    </div>

    <!-- Performance -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Performance</h5>
      <div class="grid sm:grid-cols-2 gap-x-2 gap-y-4">
        <!-- Top Speed (kn) -->
        <StandardInput
          id="vessel-top-speed"
          label="Top Speed (kn)"
          type="number"
          placeholder="Enter the Top Speed"
          :step="0.01"
          :value="props.vessel.blueprint.topSpeed"
          @update:input="updateVesselBlueprintItemAsNumber('topSpeed', $event)"
        />
        <!-- Cruising Speed (kn) -->
        <StandardInput
          id="vessel-cruising-speed"
          label="Cruising Speed (kn)"
          type="number"
          placeholder="Enter the Cruising Speed"
          :step="0.01"
          :value="props.vessel.blueprint.cruiseSpeed"
          @update:input="updateVesselBlueprintItemAsNumber('cruiseSpeed', $event)"
        />
        <!-- Fuel Capacity (L) -->
        <StandardInput
          id="vessel-fuel-capacity"
          label="Fuel Capacity (L)"
          type="number"
          placeholder="Enter the Fuel Capacity"
          :value="props.vessel.blueprint.fuelCapacity"
          @update:input="updateVesselBlueprintItemAsNumber('fuelCapacity', $event)"
        />
        <!-- Engines -->
        <StandardInput
          id="vessel-engines"
          label="Engines"
          type="search"
          placeholder="Enter the Engines"
          :value="props.vessel.blueprint.engines"
          @update:input="updateVesselBlueprintItemAsString('engines', $event)"
        />
      </div>
    </div>

    <!-- Cabin Layout -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Cabin Layout</h5>
      <div class="flex">
        <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Cabin Name</p>
        <p class="w-28 block text-sm font-medium text-gray-900 dark:text-white">Quantity</p>
      </div>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="(cabinLayout, index) in props.vessel.blueprint.cabinLayout"
          class="flex gap-x-4 items-center"
          :key="`${index}-${cabinLayout.label}-${cabinLayout.value}`"
        >
          <StandardInput
            type="search"
            class="w-full"
            :value="cabinLayout.label"
            :id="`vessel-cabin-layout-${index}-${cabinLayout.label}-${cabinLayout.value}`"
            @update:input="updateCabinLayout(index, 'label', $event)"
          />
          <StandardInput
            class="w-20"
            type="number"
            :step="1"
            :value="Number(cabinLayout.value)"
            :id="`vessel-cabin-layout-qty-${index}-${cabinLayout.label}-${cabinLayout.value}`"
            @update:input="updateCabinLayout(index, 'value', $event)"
          />
          <OutlineTrash
            class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
            @click.stop="removeCabinLayoutItem(index)"
          />
        </div>

        <!-- Add another cabin layout -->
        <Button
          class="h-fit w-min"
          id="add-cabin-layout"
          position="prefix"
          :hasBorder="true"
          :isPrimary="false"
          :name="`Add ${props.vessel.blueprint.cabinLayout?.length ? 'Another' : ''} Layout`"
          @click.stop="addCabinLayoutItem"
        >
          <OutlinePlus class="w-4 h-4" />
        </Button>
      </div>
    </div>

    <!-- Configuration -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Configuration</h5>
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
        <!-- Total no. of cabins -->
        <StandardInput
          id="vessel-cabins"
          label="Total no. of cabins"
          type="number"
          placeholder="Number of Cabins"
          :step="1"
          :value="props.vessel.blueprint.cabins"
          @update:input="updateVesselBlueprintItemAsNumber('cabins', $event)"
        />
        <!-- Bathrooms -->
        <StandardInput
          id="vessel-bathrooms"
          label="Bathrooms"
          type="number"
          placeholder="Number of Bathrooms"
          :step="1"
          :value="props.vessel.blueprint.bathrooms"
          @update:input="updateVesselBlueprintItemAsNumber('bathrooms', $event)"
        />
        <!-- Decks -->
        <StandardInput
          id="vessel-decks"
          label="Decks"
          type="number"
          placeholder="Number of Decks"
          :step="1"
          :value="props.vessel.blueprint.decks"
          @update:input="updateVesselBlueprintItemAsNumber('decks', $event)"
        />
      </div>
    </div>

    <!-- Dimensions -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <h5 class="font-bold text-black dark:text-white">Dimensions</h5>
      <div class="grid sm:grid-cols-2 gap-x-2 gap-y-4">
        <!-- Length -->
        <div class="flex justify-between gap-x-4">
          <div class="flex-grow">
            <StandardInput
              id="vessel-length"
              label="Length (m)"
              type="number"
              placeholder="Enter the Length"
              :step="0.01"
              :value="(props.vessel.blueprint.length || 0).toFixed(2)"
              @update:input="updateVesselLength($event)"
            />
          </div>
          <div v-if="props.vessel.category" class="flex flex-col w-1/3">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Category </label>
            <div class="flex items-center h-full">
              <span
                class="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
              >
                {{ props.vessel.category }}
              </span>
            </div>
          </div>
        </div>
        <!-- Beam -->
        <StandardInput
          id="vessel-beam"
          label="Beam (m)"
          type="number"
          placeholder="Enter the Beam"
          :step="0.01"
          :value="(props.vessel.blueprint.beam || 0).toFixed(2)"
          @update:input="updateVesselBlueprintItemAsNumber('beam', $event)"
        />
        <!-- Draft -->
        <StandardInput
          id="vessel-draft"
          label="Draft (m)"
          type="number"
          placeholder="Enter the Draft"
          :step="0.01"
          :value="(props.vessel.blueprint.draft || 0).toFixed(2)"
          @update:input="updateVesselBlueprintItemAsNumber('draft', $event)"
        />
        <!-- Hull Type -->
        <StandardInput
          id="vessel-hull-type"
          label="Hull Type"
          type="search"
          placeholder="Enter the Hull Type"
          :value="props.vessel.blueprint.hullType"
          @update:input="updateVesselBlueprintItemAsString('hullType', $event)"
        />
        <!-- Hull Construction -->
        <StandardInput
          id="vessel-hull-construction"
          label="Hull Construction"
          type="search"
          placeholder="Enter the Hull Construction"
          :value="props.vessel.blueprint.hullConstruction"
          @update:input="updateVesselBlueprintItemAsString('hullConstruction', $event)"
        />
        <!-- Tonnage (gt) -->
        <StandardInput
          id="vessel-tonnage"
          label="Tonnage (gt)"
          type="number"
          placeholder="Enter the Tonnage"
          :step="0.01"
          :value="props.vessel.blueprint.tonnage"
          @update:input="updateVesselBlueprintItemAsNumber('tonnage', $event)"
        />
        <!-- Super Structure -->
        <div>
          <p class="text-sm font-medium mb-2 text-gray-900 dark:text-white">Super Structure</p>
          <InputWithTags
            placeholder="Add a Super Structure"
            :tags="props.vessel.blueprint.superStructure"
            @add:tag="addToArray($event, 'superStructure', true)"
            @remove:tag="removeFromArray($event, 'superStructure', true)"
          />
        </div>
        <!-- Main Sail Types -->
        <div>
          <p class="text-sm font-medium mb-2 text-gray-900 dark:text-white">Main Sail Types</p>
          <InputWithTags
            placeholder="Add a Main Sail Type"
            :tags="props.vessel.blueprint.mainsailTypes"
            @add:tag="addToArray($event, 'mainsailTypes', true)"
            @remove:tag="removeFromArray($event, 'mainsailTypes', true)"
          />
        </div>
        <!-- Genoa Types -->
        <div>
          <p class="text-sm font-medium mb-2 text-gray-900 dark:text-white">Genoa Types</p>
          <InputWithTags
            placeholder="Add a Genoa Type"
            :tags="props.vessel.blueprint.genoaTypes"
            @add:tag="addToArray($event, 'genoaTypes', true)"
            @remove:tag="removeFromArray($event, 'genoaTypes', true)"
          />
        </div>
      </div>
    </div>

    <!-- Additional info -->
    <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
      <div class="flex gap-x-2.5">
        <h5 class="font-bold text-black dark:text-white">Additional Information</h5>
        <button type="button" data-popover-placement="right" data-popover-target="additional-info-popover">
          <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
        </button>
        <div
          data-popover
          role="tooltip"
          id="additional-info-popover"
          class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
        >
          <div class="max-w-[25rem] p-2">{{ ADDITIONAL_NOTES_TEXT }}</div>
          <div data-popper-arrow></div>
        </div>
      </div>

      <div
        v-if="props.vessel.additionalInfo?.length"
        class="flex flex-col gap-y-4 divide-y divide-dashed divide-gray-200 dark:divide-gray-600"
      >
        <div
          v-for="(info, index) in props.vessel.additionalInfo"
          class="flex gap-x-4 items-start"
          :class="{ 'pt-4': index !== 0 }"
          :key="`${index}-${info.label}-${info.value}`"
        >
          <div class="w-full flex flex-col gap-y-4">
            <div class="flex items-center gap-x-4">
              <StandardInput
                type="search"
                class="w-full"
                placeholder="Enter Label"
                :value="info.label"
                :id="`vessel-info-${index}-${info.label}-${info.value}`"
                @update:input="updateAdditionalInfo(index, 'label', $event)"
              />
              <OutlineTrash
                class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
                @click.stop="removeFromArray(index, 'additionalInfo', false)"
              />
            </div>
            <textarea
              rows="2"
              placeholder="Enter the Description"
              class="block py-3 px-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :id="`vessel-info-val-${index}-${info.label}-${info.value}`"
              :value="info.value"
              @blur="updateAdditionalInfo(index, 'value', $event)"
            ></textarea>
          </div>
        </div>
      </div>

      <!-- Add another additional info -->
      <Button
        class="h-fit w-min mt-1"
        id="add-additional-info"
        position="prefix"
        :hasBorder="true"
        :isPrimary="false"
        :name="`Add ${props.vessel.additionalInfo?.length ? 'Another' : ''} Additional Info`"
        @click.stop="addAdditionalInfo()"
      >
        <OutlinePlus class="w-4 h-4" />
      </Button>
    </div>
  </div>
</template>
