<script setup lang="ts">
import debounce from 'lodash.debounce'
import { Ref, onMounted, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { CrewMember } from '@ankor-io/common/vessel/types'
import { OutlinePencil, OutlineTrash } from '@ankor-io/icons/outline'
import { SolidUser } from '@ankor-io/icons/solid'

import AvatarUploader from '@/components/asset-uploader/AvatarUploader.vue'
import InputWithTagsDropdown from '@/components/input/InputWithTagsDropdown.vue'
import StandardInput from '@/components/input/StandardInput.vue'

type Props = {
  uri: string
  crew: CrewMember
  crewIndex: number
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'delete:crew'): void
  (e: 'delete:avatar'): void
  (e: 'update:crew', value: CrewMember): void
  (e: 'update:avatar', value: string): void
}>()

const isUploading: Ref<boolean> = ref(false)
const isCrewInfoOpen: Ref<boolean> = ref(false)
const showCrewAvatarUploader: Ref<number> = ref(-1)

const roleOptions = [
  { label: 'Captain', value: 'Captain' },
  { label: 'Chief Officer', value: 'Chief Officer' },
  { label: 'Bosun', value: 'Bosun' },
  { label: 'Deckhand', value: 'Deckhand' },
  { label: 'Engineer', value: 'Engineer' },
  { label: 'Purser', value: 'Purser' },
  { label: 'Chief Stewardess', value: 'Chief Stewardess' },
  { label: 'Steward', value: 'Steward' },
  { label: 'Chef', value: 'Chef' },
  { label: 'Sous Chef', value: 'Sous Chef' },
  { label: 'Cook', value: 'Cook' },
  { label: 'Hostess', value: 'Hostess' },
  { label: 'Waiter', value: 'Waiter' },
  { label: 'Stewardess', value: 'Stewardess' },
  { label: 'Second Stewardess', value: 'Second Stewardess' },
  { label: 'Chief Steward', value: 'Chief Steward' },
  { label: 'Chief Engineer', value: 'Chief Engineer' },
  { label: 'First Officer', value: 'First Officer' },
  { label: 'Second Officer', value: 'Second Officer' },
  { label: 'First Mate', value: 'First Mate' },
  { label: 'Second Mate', value: 'Second Mate' },
  { label: 'Mate', value: 'Mate' },
  { label: 'Sailor', value: 'Sailor' },
  { label: 'Cruise Director', value: 'Cruise Director' },
]

const onMouseleave = debounce(() => {
  showCrewAvatarUploader.value = -1
}, 100)

/**
 * Update crew member
 * @param key Field from a Crew type
 * @param event Event from the html element
 */
const updateCrew = (key: string, event: Event): void => {
  const value: string = (event.target as HTMLInputElement).value
  const crew = ObjectUtil.deepCopy(props.crew)
  crew[key] = value
  emit('update:crew', crew)
}

/**
 * Add crew member role.
 * @param value Value from the dropdown selected item / input
 */
const addRole = (value: string): void => {
  const crew: CrewMember = ObjectUtil.deepCopy(props.crew)
  const roles = crew.roles || []
  if (!roles.includes(value)) {
    roles.push(value)
  }
  crew.roles = roles
  emit('update:crew', crew)
}

/**
 * Remove crew member role
 * @param index Index of the role to remove
 */
const removeRole = (index: number): void => {
  const crew: CrewMember = ObjectUtil.deepCopy(props.crew)
  const roles = crew.roles || []
  if (index > -1) {
    roles.splice(index, 1)
  }
  crew.roles = roles
  emit('update:crew', crew)
}

/**
 * Handle crew member avatar upload
 * @param path
 */
const handleAvatarUploadFile = (path: string): void => {
  isUploading.value = false
  showCrewAvatarUploader.value = -1
  emit('update:avatar', path)
}

onMounted(() => {
  if (!props.crew.roles || props.crew.roles.length === 0) {
    const crew: CrewMember = ObjectUtil.deepCopy(props.crew)
    crew.roles = crew.role ? [crew.role] : []
    emit('update:crew', crew)
  }
})
</script>
<template>
  <div
    class="flex flex-col gap-y-4 p-4 border rounded-lg shadow-md bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600"
  >
    <!-- Crew -->
    <div v-if="!isCrewInfoOpen" class="flex items-center justify-between">
      <div class="flex gap-x-4">
        <div v-if="props.crew.avatar" class="shrink-0 w-12 h-12 group relative flex justify-center items-center">
          <AssetViewerPlaceholder
            v-if="props.crew.avatar"
            class="object-cover rounded-full"
            :url="`/media/${replacePathToMediaUris(props.uri, props.crew.avatar || '')[0]}`"
          />
          <SolidUser v-else class="w-full bg-primary-100 fill-primary-400" />
        </div>
        <div class="flex flex-col text-gray-900 dark:text-white">
          <h5 class="font-bold">
            {{ props.crew.name }}
          </h5>
          <h6 v-if="props.crew.roles">
            {{ props.crew.roles.join(', ') }}
          </h6>
        </div>
      </div>

      <div class="flex gap-x-4">
        <OutlinePencil
          id="edit-crew"
          class="w-4 h-4 shrink-0 cursor-pointer transition-all text-gray-900 dark:text-white hover:text-gray-500 hover:dark:text-gray-300"
          @click.stop="isCrewInfoOpen = true"
        />
        <OutlineTrash
          id="delete-crew"
          class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
          @click.stop="emit('delete:crew')"
        />
      </div>
    </div>

    <template v-else>
      <div class="flex gap-x-4">
        <!-- avatar -->
        <div>
          <div
            v-show="!props.crew.avatar || isUploading || showCrewAvatarUploader === props.crewIndex"
            class="avatar-container w-48 h-48"
            @mouseleave="onMouseleave()"
          >
            <AvatarUploader
              :uri="props.uri"
              :image="props.crew.avatar"
              :id="`crew-avatar-${props.crew.name}-${props.crewIndex}`"
              @delete:image="emit('delete:avatar')"
              @file:uploaded="handleAvatarUploadFile($event)"
              @upload:started="isUploading = true"
              @upload:failed="isUploading = false"
              @mouseenter="showCrewAvatarUploader = props.crewIndex"
            />
          </div>
          <!-- v-show allows the image to not re-render every time it is moused over -->
          <div
            v-show="props.crew.avatar && showCrewAvatarUploader !== props.crewIndex && !isUploading"
            class="crew-viewer-container shrink-0 w-48 h-48 group relative flex justify-center items-center rounded-full bg-primary-50 dark:bg-gray-700"
            @mouseenter="showCrewAvatarUploader = props.crewIndex"
            @dragover="showCrewAvatarUploader = props.crewIndex"
            @dragend="showCrewAvatarUploader = -1"
          >
            <AssetViewerPlaceholder
              v-if="props.crew.avatar"
              class="object-cover rounded-full w-full h-full"
              :url="`/media/${replacePathToMediaUris(props.uri, props.crew.avatar || '')[0]}`"
            />
            <SolidUser v-else class="w-full bg-primary-100 fill-primary-400" />
          </div>
        </div>
        <!-- crew name -->
        <div class="w-full flex flex-col gap-y-3">
          <StandardInput
            type="text"
            class="w-full"
            label="Full Name"
            placeholder="Add crew name"
            :value="props.crew.name"
            :id="`vessel-crew-name-${props.crew.name}`"
            @update:input="updateCrew('name', $event)"
          />
          <div>
            <span class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Crew Job Title </span>
            <InputWithTagsDropdown
              :id="`crew-role-${props.crew.name}-${props.crewIndex}`"
              :tags="props.crew.roles"
              :options="roleOptions"
              @add:tag="addRole($event)"
              @remove:tag="removeRole($event)"
            />
          </div>
        </div>
        <!-- Finish button -->
        <div>
          <a
            type="button"
            class="flex h-min self-start w-min cursor-pointer items-center py-2 px-3 text-xs font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 focus:dark:ring-gray-500 dark:hover:text-white dark:hover:bg-gray-700"
            @click.once="isCrewInfoOpen = false"
          >
            Finish
          </a>
        </div>
      </div>
      <div class="flex flex-col gap-y-3">
        <h5 class="font-bold text-black dark:text-white">Crew Member Blurb</h5>
        <textarea
          rows="3"
          :id="`vessel-crew-description-${props.crew.bio}`"
          placeholder="Enter the crew bio"
          class="block py-3 px-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          :value="props.crew.bio"
          @blur="updateCrew('bio', $event)"
        ></textarea>
      </div>
    </template>
  </div>
</template>
