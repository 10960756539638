<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch/lite'
import { DateTime, Interval } from 'luxon'
import { Ref, computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import ImageCarouselModal from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModal.vue'
import SimpleModal from '@ankor-io/blocks/components/modals/SimpleModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import { showAwaitToast, showInfoToast } from '@ankor-io/blocks/components/toast'
import { BookingManagerVesselPairing } from '@ankor-io/common/booking-manager/types'
import { CalendarView, CalendarViewEnum } from '@ankor-io/common/calendar/types'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import { fromIntervalString } from '@ankor-io/common/date/ISO8601'
import { sha256 } from '@ankor-io/common/lang/digests'
import { useAppDispatcher } from '@ankor-io/common/lang/events'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { CalendarEvent, Vessel, VesselVisibility } from '@ankor-io/common/vessel/types'
import { OutlineCloudDownload, OutlineCloudUpload } from '@ankor-io/icons/outline'
import { OutlineDownload, OutlinePencil } from '@ankor-io/icons/outline'
import { SolidCalendar, SolidCheckMark, SolidChevronDown, SolidExclamationCircle } from '@ankor-io/icons/solid'

import AgendaEvent from '@/components/AgendaEvent.vue'
import Preview from '@/components/Preview.vue'
import Spinner from '@/components/Spinner.vue'
import DropZone from '@/components/asset-uploader/DropZone.vue'
import MonthCalendar from '@/components/calendar/MonthCalendar.vue'
import WeekCalendar from '@/components/calendar/WeekCalendar.vue'
import YearCalendar from '@/components/calendar/YearCalendar.vue'
import CalendarHeader from '@/components/calendar/calendar-header/CalendarHeader.vue'
import DeleteConfirmation, { DeleteConfirmationModalData } from '@/components/modal-content/DeleteConfirmation.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import EventModal from '@/components/modal-content/event-modal-content/EventModal.vue'
import { Config, ConfigKey } from '@/config/types'
import { useModal } from '@/hooks/modal/useModal'
import { AuthenticationContext } from '@/iam/types'
import { assetUploaderValidityStyling } from '@/utils/asset-uploader-validity-styling'
import { DispatcherEventEnum } from '@/utils/dispatcher-events'
import { getRouterQuery } from '@/utils/router-query'

interface EditVesselProps {
  uri: string
}

const props = defineProps<EditVesselProps>()

const router = useRouter()
const config: Config = inject(ConfigKey)!
const dispatcher = useAppDispatcher().get()
const { isOpen, updateModalState } = useModal()
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const vesselRef: Ref<Vessel | null> = ref(null)
const vesselEventsRef: Ref<CalendarEvent[]> = ref([])
const eventsInSelectedMonth: Ref<CalendarEvent[]> = ref([])
const eventYearMonthsQueried: Ref<{ month: number; year: number }[]> = ref([])

const loadingNewCalendarDate: Ref<boolean> = ref(false)
const eventsLoadingOnMounted: Ref<boolean> = ref(false)

const showCarouselModal: Ref<boolean> = ref(false)
const selectedCarouselImageIndex: Ref<number> = ref(0)
const carousel: Ref<typeof ImageCarouselModal | null> = ref(null)
const calendarEventToUpdate: Ref<CalendarEvent | undefined> = ref()

const isUpdatingVisibility: Ref<boolean> = ref(false)

const dateTime: Ref<DateTime> = ref(DateTime.now())
const currentCalendarView: Ref<CalendarView> = ref(CalendarViewEnum.MONTH)
const managedByCompaniesRef: Ref<string> = ref('')

const selectedModalType: Ref<'Update' | 'Delete' | 'Add' | 'Calendar' | null> = ref(null)
const deleteConfirmationModalData: Ref<DeleteConfirmationModalData | null> = ref(null)

const searchClient: SearchClient = inject('searchClient') as SearchClient
const index: SearchIndex = searchClient.initIndex('commodore')

const postSearchClient = algoliasearch(config.ALGOLIA.app_id, config.ALGOLIA.search_key)
const commodoreIndex = postSearchClient.initIndex('commodore')

const vesselPosts: Ref<any[]> = ref([])

const calendarSharingModalHelper = modalHelper()
const uploadSuccessful: Ref<boolean> = ref(false) // if the file upload is successful
const isFileTypeSupported: Ref<boolean> = ref(true) // if the file extension is valid or not
// Additional validation for styling
const isUploading: Ref<boolean> = ref(false)

const calendarLink = computed(() => {
  return window.location.href.replace(/calendars|commodore/, 'radar')
})

const calendarLinkCopied: Ref<boolean> = ref(false)
let calendarLinkCopiedTimeout: ReturnType<typeof setTimeout> | null = null

// MMK Integration
const searchTerm: Ref<string> = ref('')
const yachtsFromMMKPull: Ref<BookingManagerVesselPairing[]> = ref([])
const showMmkYachtsList: Ref<boolean> = ref(false)

const exportCalendarLink: Ref<string> = ref('Export calendar link')
const exportCalendarLinkCopied: Ref<boolean> = ref(false)

const loadingImportCalendarLink: Ref<boolean> = ref(false)
const calendarLinkExists: Ref<boolean> = ref(false)
const importCalendarLink: Ref<string> = ref('')

let exportCalendarLinkCopiedTimeout: ReturnType<typeof setTimeout> | null = null

const savingYachtPairing: Ref<boolean> = ref(false)
const pairedYacht: Ref<BookingManagerVesselPairing | null> = ref(null) // For loading in an already paired yacht
const selectedYachtForPairingRef: Ref<BookingManagerVesselPairing | null> = ref(null) // Selecting a paired yacht from the dropdown

/**
 * Open the update modal with the event to update
 * @param vesselEvent event to update
 */
const openUpdateModal = (vesselEvent: CalendarEvent) => {
  calendarEventToUpdate.value = vesselEvent
  selectedModalType.value = 'Update'
}

const openAddModal = () => {
  selectedModalType.value = 'Add'
}

const filterEventsInSelectedMonth = (events: CalendarEvent[], dateTime?: DateTime) => {
  const dt = dateTime || DateTime.now()
  const monthStart = dt.startOf('month').setZone('utc')
  const monthEnd = dt.endOf('month').setZone('utc')
  const monthInterval = Interval.fromDateTimes(monthStart, monthEnd)
  return events.filter((event) => {
    const { start } = fromIntervalString(event.interval)
    if (start) {
      return ISO8601.overlapsAny(event.interval, [monthInterval.toISO()])
    }
    return
  })
}

const sortEventsChronologically = (events: CalendarEvent[]) => {
  return events.sort((a, b) => {
    const { start: startA } = fromIntervalString(a.interval)
    const { start: startB } = fromIntervalString(b.interval)
    if (startA && startB) {
      return startA.startOf('day') > startB.startOf('day') ? 1 : -1
    }
    return 0
  })
}

const getEventsInSelectedMonth = (events: CalendarEvent[], dateTime?: DateTime) => {
  const futureEvents = filterEventsInSelectedMonth(events, dateTime)
  return sortEventsChronologically(futureEvents)
}

/**
 * Get the vessel data from the server, if not available default to vesselWithDefaults
 */
const getVesselData = async () => {
  const response = await fetch(`/api/vessel/${decodeURIComponent(props.uri)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    vesselRef.value = await response.json()
  } else if (response.status === 403) {
    router.push({ name: 'forbidden' })
  }
}

const crudEventWithMethod = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  vesselUri: string,
  event?: CalendarEvent | { id: string },
  searchParams?: string,
) => {
  const response = await fetch(
    `/api/vessel/calendar/events/${decodeURIComponent(vesselUri)}${searchParams ? `?${searchParams}` : ''}`,
    {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${await authenticationContext.getToken()}`,
      },
      body: event ? JSON.stringify(event) : undefined,
    },
  )

  return response
}

/**
 * Get the vessel data from the server, if not available default to vesselWithDefaults
 */
const getEvents = async (vesselUri: string, updatedDateTime?: DateTime) => {
  // Get events from the beginning of last month to the end of next month
  const now = updatedDateTime || DateTime.now()
  const startDate = now.minus({ month: 1 }).startOf('month')
  const endDate = now.plus({ month: 1 }).endOf('month')

  // If we have already queried these months, don't query again
  if (
    eventYearMonthsQueried.value.some((month) => month.month === now.month && month.year === now.year) &&
    eventYearMonthsQueried.value.some((month) => month.month === startDate.month && month.year === startDate.year) &&
    eventYearMonthsQueried.value.some((month) => month.month === endDate.month && month.year === endDate.year)
  ) {
    dateTime.value = updatedDateTime!
    return
  }

  if (updatedDateTime) {
    loadingNewCalendarDate.value = true
  } else {
    eventsLoadingOnMounted.value = true
  }

  // Add the months + years to the array if we haven't queried them yet
  if (!eventYearMonthsQueried.value.some((month) => month.month === now.month && month.year === now.year)) {
    eventYearMonthsQueried.value.push({ month: now.month, year: now.year })
  }

  if (!eventYearMonthsQueried.value.some((month) => month.month === startDate.month && month.year === startDate.year)) {
    eventYearMonthsQueried.value.push({ month: startDate.month, year: startDate.year })
  }

  if (!eventYearMonthsQueried.value.some((month) => month.month === endDate.month && month.year === endDate.year)) {
    eventYearMonthsQueried.value.push({ month: endDate.month, year: endDate.year })
  }

  const response = await crudEventWithMethod(
    'GET',
    vesselUri,
    undefined,
    `startDate=${startDate.toFormat('yyyy-MM-dd')}&endDate=${endDate.toFormat('yyyy-MM-dd')}`,
  )

  if (response.status === 200) {
    const responseFromServer: { events: CalendarEvent[] } = await response.json()
    if (responseFromServer?.events?.length) {
      responseFromServer.events.forEach((event) => {
        if (vesselEventsRef.value.find((existingEvents) => existingEvents.id === event.id)) {
          return
        }

        vesselEventsRef.value.push(event)
      })
    }

    eventsInSelectedMonth.value = getEventsInSelectedMonth(vesselEventsRef.value, updatedDateTime || dateTime.value)
  }

  if (updatedDateTime) {
    loadingNewCalendarDate.value = false
    // Delay updating the dateTime to not show the new date ahead of the loader
    dateTime.value = updatedDateTime
  } else {
    eventsLoadingOnMounted.value = false
  }
}

/**
 * Add calendar event to vessel
 * @param event event to add to vessel
 */
const addEvent = async (vesselUri: string, event: CalendarEvent) => {
  eventsLoadingOnMounted.value = true
  const response = await crudEventWithMethod('POST', vesselUri, event)

  if (response.status === 200) {
    vesselEventsRef.value = [...vesselEventsRef.value, event]
    eventsInSelectedMonth.value = getEventsInSelectedMonth(vesselEventsRef.value, dateTime.value)
  }

  eventsLoadingOnMounted.value = false
}

/**
 * Update calendar event of the vessel
 * @param event event to updade for the vessel
 */
const updateEvent = async (vesselUri: string, event: CalendarEvent) => {
  eventsLoadingOnMounted.value = true
  const response = await crudEventWithMethod('PUT', vesselUri, event)

  if (response.status === 200) {
    vesselEventsRef.value = vesselEventsRef.value.map((vesselEvent) => {
      if (vesselEvent.id === event.id) {
        return event
      }
      return vesselEvent
    })
    eventsInSelectedMonth.value = getEventsInSelectedMonth(vesselEventsRef.value, dateTime.value)
  }

  eventsLoadingOnMounted.value = false
}

// DELETE CONFIRMATION MODAL
const onActionDelete = (id: string) => {
  selectedModalType.value = 'Delete'
  deleteConfirmationModalData.value = {
    id,
    message: 'You are about to delete this event, are you sure you want to proceed?',
    labelCancel: 'No, cancel',
    labelConfirm: 'Yes, delete it',
  }
  updateModalState(true)
}

/**
 * Delete calendar event from vessel
 * @param id id of the event to delete
 */
const deleteEvent = async (id: string) => {
  updateModalState(false)
  eventsLoadingOnMounted.value = true
  const response = await crudEventWithMethod('DELETE', props.uri, { id })

  if (response.status === 200) {
    vesselEventsRef.value = vesselEventsRef.value.filter((vesselEvent) => vesselEvent.id !== id)
    eventsInSelectedMonth.value = getEventsInSelectedMonth(vesselEventsRef.value, dateTime.value)
  }

  eventsLoadingOnMounted.value = false
  deleteConfirmationModalData.value = null
}

const closeEventModal = () => {
  selectedModalType.value = null
}

const openCarousel = (index: number) => {
  selectedCarouselImageIndex.value = index
  showCarouselModal.value = true
  carousel.value?.focus()
}

/**
 * Reset each date value back to today equivalent - occurs when the calendar view changes
 * Also occurs when the user presses the "Today" button to go back to the current date
 */
const resetDateValues = (): void => {
  dateTime.value = DateTime.now()
  eventsInSelectedMonth.value = getEventsInSelectedMonth(vesselEventsRef.value, dateTime.value)
}

/**
 * Updates the calendar view to a new given view
 * @param event The new calendar view
 */
const updateCalendarView = (event: CalendarView): void => {
  currentCalendarView.value = event
  resetDateValues()
}

/**
 * Currently not used
 */
const updateYear = (year: number): void => {
  getEvents(props.uri, dateTime.value.plus({ year }))
}

/**
 * A function to update month.
 * @param month The number of months to add/subtract by
 */
const updateMonth = (month: number): void => {
  getEvents(props.uri, dateTime.value.plus({ month }))
}

/**
 * Currently not used
 */
const updateWeek = (week: number): void => {
  dateTime.value = dateTime.value.plus({ week })
}

/**
 * compute the vessel visibility
 */
const vesselVisibility = computed(() => {
  return vesselRef.value?.visibility || VesselVisibility.PUBLIC
})

/**
 * Update the vessel visibility status
 */
const updateVisibilityStatus = async () => {
  isUpdatingVisibility.value = true
  const response = await fetch(`/api/vessel/visibility/${decodeURIComponent(props.uri)}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify({
      ...vesselRef.value,
      visibility:
        vesselVisibility.value === VesselVisibility.PUBLIC ? VesselVisibility.PRIVATE : VesselVisibility.PUBLIC,
    }),
  })

  if (response.status === 200) {
    vesselRef.value = await response.json()
  }
  isUpdatingVisibility.value = false
}

const reloadEventsForVesselUri = async (vesselUri: string) => {
  if (props.uri === vesselUri) {
    getEvents(props.uri)
  }
}

const getCompaniesManagedBy = async () => {
  await index.search(`"${decodeURIComponent(props.uri)}"`, {}).then((response) => {
    if (response.hits.length) {
      const hit: any = response.hits[0] || {}
      const managedByCompanies = hit.line_7 || []
      if (managedByCompanies.length === 0) {
        managedByCompaniesRef.value = ''
        return
      }
      if (managedByCompanies.length === 1) {
        managedByCompaniesRef.value = `${managedByCompanies[0]}`
        return
      } else {
        managedByCompaniesRef.value = `${managedByCompanies.slice(0, -1).join(', ')} and ${managedByCompanies.slice(
          -1,
        )}`
        return
      }
    }
  })
}

const removeSubscription = async (subscriptionUrl: string) => {
  showAwaitToast(
    fetch(
      `/api/calendar/subscription/${props.uri}?` +
        new URLSearchParams({
          subscriptionUrl: encodeURIComponent(subscriptionUrl),
        }).toString(),
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await authenticationContext.getToken()}`,
        },
      },
    ).then((response) => {
      if (response.status === 200) {
        calendarLinkExists.value = false
        importCalendarLink.value = ''
      }
    }),
    `Removing subscription...`,
    `Removed subscription successfully.`,
    `Failed to remove subscription.`,
  )
}

const getSharedUrl = async () => {
  loadingImportCalendarLink.value = true
  await fetch(`/api/calendar/subscription/${props.uri}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json() as Promise<{ vesselUri: string; url: string }[]>
      } else {
        return []
      }
    })
    .then((data) => {
      if (data.length) {
        calendarLinkExists.value = true
        importCalendarLink.value = data[0].url
        return
      }
      console.info('No shared calendar found')
    })
    .catch((error) => {
      console.error(`Error fetching from /api/calendar/subscription/${props.uri}:`, error)
    })
  loadingImportCalendarLink.value = false
}

const showCalendarSharingModal = () => {
  if (!calendarLinkExists.value) {
    getSharedUrl()
  }
  if (!pairedYacht.value) {
    getListOfVesselsFromBookingManagerPull()
  }
  calendarSharingModalHelper.show({})
}

onMounted(async () => {
  const pairingQuery = decodeURIComponent(getRouterQuery('pairing'))
  const showPairingModal = pairingQuery === 'true' ? 'pair' : pairingQuery === 'false' ? 'unpair' : false
  if (showPairingModal === 'pair' || showPairingModal === 'unpair') {
    showInfoToast('Opening Yacht Calendar Sharing...')
  }

  dispatcher.addEventListener(DispatcherEventEnum.CALENDAR_EVENT_CREATED, reloadEventsForVesselUri)

  await Promise.all([
    getVesselData(),
    getEvents(props.uri),
    getCompaniesManagedBy(),
    commodoreIndex
      .search('', { facetFilters: '[["line_10:' + props.uri + '"],["type:post"]]' })
      .then((response: any) => {
        vesselPosts.value = response.hits
      }),
  ])

  const hash = await sha256(
    decodeURIComponent(props.uri) + '-' + decodeURIComponent(vesselRef.value?.blueprint.name || ''),
  ) // Create a hash so that it is not guessable
  exportCalendarLink.value = `${window.location.protocol}//${window.location.hostname}/calendars/${decodeURIComponent(
    props.uri,
  )}/${encodeURIComponent(vesselRef.value?.blueprint.name || '')}/${hash}`

  if (showPairingModal === 'pair' || showPairingModal === 'unpair') {
    showCalendarSharingModal()
  }
})

onUnmounted(() => {
  dispatcher.removeEventListener(DispatcherEventEnum.CALENDAR_EVENT_CREATED, reloadEventsForVesselUri)
})

watch(isOpen, (value) => {
  if (!value) {
    selectedModalType.value = null
    deleteConfirmationModalData.value = null
  }
})

const getCarouselImages = () => {
  if (vesselRef.value?.blueprint.images) {
    return replacePathToMediaUris(vesselRef.value.uri, ...vesselRef.value.blueprint.images)
  }

  return []
}

const handleSaveAndClose = async () => {
  // Add the subscription to the calendar
  if (!calendarLinkExists.value && importCalendarLink.value) {
    showAwaitToast(
      fetch('/api/calendar/subscription', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${await authenticationContext.getToken()}`,
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscriptionUrl: importCalendarLink.value,
          vesselUri: vesselRef.value?.uri,
        }),
      }).then((resp) => {
        if (resp.status === 200) {
          calendarLinkExists.value = true
        }
      }),
      `Adding subscription...`,
      `Added subscription successfully.`,
      `Failed to add subscription.`,
    )
  }

  // Pair the selected yacht with the current vessel
  if (selectedYachtForPairingRef.value) {
    savingYachtPairing.value = true
    pairedYacht.value = selectedYachtForPairingRef.value
    showAwaitToast(
      fetch(`/api/booking-manager/vessel/pairing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await authenticationContext.getToken()}`,
        },
        body: JSON.stringify({
          ...selectedYachtForPairingRef.value,
          paired: true,
          vesselUri: decodeURIComponent(props.uri),
        }),
      }),
      `Updating calendar sharing...`,
      `Updated calendar sharing successfully.`,
      `Failed to update calendar sharing.`,
    ).then(() => {
      calendarSharingModalHelper.hide()
      savingYachtPairing.value = false
      selectedYachtForPairingRef.value = null
    })
  }

  calendarSharingModalHelper.hide()
}

const copyCalendarLink = () => {
  calendarLinkCopied.value = false
  clearTimeout(calendarLinkCopiedTimeout!)

  navigator.clipboard.writeText(calendarLink.value)
  calendarLinkCopied.value = true
  // reset the calendar link copied value so the users know they can copy the link again
  calendarLinkCopiedTimeout = setTimeout(() => {
    calendarLinkCopied.value = false
  }, 3000)
}

const copyExportCalendarLink = () => {
  exportCalendarLinkCopied.value = false
  clearTimeout(exportCalendarLinkCopiedTimeout!)

  navigator.clipboard.writeText(exportCalendarLink.value)
  exportCalendarLinkCopied.value = true

  exportCalendarLinkCopiedTimeout = setTimeout(() => {
    exportCalendarLinkCopied.value = false
  }, 3000)
}

const getListOfVesselsFromBookingManagerPull = async () => {
  const resp = await fetch('/api/booking-manager/vessel/list', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (resp.ok) {
    const yachts: BookingManagerVesselPairing[] = await resp.json()
    // Get unpaired yachts first and sort them
    const unpairedYachts = yachts.filter((yacht) => !yacht.paired).sort((a, b) => a.name.localeCompare(b.name))
    // Get paired yachts next and sort them
    const pairedYachts = yachts.filter((yacht) => yacht.paired).sort((a, b) => a.name.localeCompare(b.name))
    // Bring the yachts together, sorted and grouped by paired status leaving paired ones at the bottom
    yachtsFromMMKPull.value = unpairedYachts.concat(pairedYachts)
    pairedYacht.value = pairedYachts.find((yacht) => decodeURIComponent(props.uri) === yacht.vesselUri)! || null
  }
}

const selectedYachtForPairing = (yachtId: string) => {
  searchTerm.value = ''
  showMmkYachtsList.value = false
  selectedYachtForPairingRef.value = yachtsFromMMKPull.value.find((yacht) => yacht.id.toString() === yachtId) || null
}

const addFileFromInput = (event: Event): void => {
  // TODO: File check for safety?
  // TODO: Restrict file types?
  const target = event.target as HTMLInputElement
  const file = target.files![0]
  uploadOneTimeImportFile(file)
}

const uploadOneTimeImportFile = async (file: File) => {
  if (vesselRef.value) {
    if (file.type !== 'text/calendar') {
      isFileTypeSupported.value = false
      setTimeout(() => {
        isFileTypeSupported.value = true
      }, 3000)
      return
    } else {
      isFileTypeSupported.value = true
    }
    uploadSuccessful.value = true

    const formData = new FormData()
    formData.set('file', file)
    formData.set('vesselUri', vesselRef.value.uri)

    try {
      await fetch('/api/calendar/import', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${await authenticationContext.getToken()}`,
        },
        body: formData,
      })
    } catch (e) {
      console.error(e)
    }

    uploadSuccessful.value = false
  }
}

const downloadCalendarEvents = () => {
  const a = document.createElement('a')
  a.href = exportCalendarLink.value
  a.download = `Calendar for ${vesselRef.value?.blueprint.name} - powered by Ankor.ics`

  // For older browsers, we need to append the anchor to the body and remove it after the click
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
</script>
<template>
  <div class="relative grid grid-cols-1 xl:grid-cols-2 gap-8 xl:gap-4 w-full mx-auto">
    <div>
      <!-- Header -->
      <div
        class="flex items-center min-h-[4.75rem] justify-end gap-x-4 p-4 border rounded-t-lg border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
      >
        <!-- Toggle active -->
        <div class="flex gap-x-3 items-center" :class="{ invisible: !vesselRef }">
          <Spinner v-if="isUpdatingVisibility" class="w-5 h-5" />
          <template v-else>
            <span v-if="vesselVisibility === VesselVisibility.PUBLIC" class="text-sm font-medium"> Active </span>
            <span
              v-else-if="vesselVisibility === VesselVisibility.PRIVATE"
              class="h-fit text-xs font-medium px-2.5 py-1 rounded bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300"
            >
              Inactive
            </span>
          </template>
          <div class="flex items-center">
            <label
              class="toggle relative inline-flex items-center cursor-pointer"
              @click.prevent="
                () => {
                  if (!isUpdatingVisibility) {
                    updateVisibilityStatus()
                  }
                }
              "
            >
              <input type="checkbox" class="sr-only peer" :checked="vesselVisibility === VesselVisibility.PUBLIC" />
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-600"
              ></div>
            </label>
          </div>
        </div>

        <!-- Edit yacht -->
        <RouterLink
          v-if="vesselRef"
          :class="{ invisible: !vesselRef }"
          :to="{ name: 'edit-vessel', params: { uri: encodeURIComponent(uri) } }"
        >
          <button
            type="button"
            class="flex gap-x-2 items-center py-2.5 px-5 text-sm font-medium transition-all focus:outline-none rounded-lg border focus:z-10 focus:ring-4 text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700"
          >
            <OutlinePencil class="w-4 h-4 shrink-0" />
            Edit
          </button>
        </RouterLink>
      </div>
      <!-- Preview -->
      <div
        class="flex flex-col bg-white border-x border-b border-gray-200 dark:border-gray-700 rounded-b-lg p-4 dark:bg-gray-800 xl:h-[calc(100vh-12.75rem)]"
      >
        <div v-if="vesselRef" class="flex flex-col overflow-auto scrollbar-hide">
          <Preview :vessel="vesselRef" :managed-by="managedByCompaniesRef" @open-carousel="openCarousel" />
        </div>
        <div v-else class="flex items-center justify-center h-full">
          <Spinner />
        </div>
      </div>
    </div>

    <!-- Calendar -->
    <div v-if="eventsLoadingOnMounted" class="flex items-center justify-center h-full">
      <Spinner />
    </div>
    <div
      v-else
      class="relative xl:h-[calc(100vh-8rem)] overflow-auto scrollbar-hide border rounded-lg border-gray-200 dark:border-gray-700"
    >
      <CalendarHeader
        :view="currentCalendarView"
        :date-value="{
          year: dateTime.year,
          month: dateTime.month,
          week: dateTime.weekNumber,
        }"
        :seasons="vesselRef?.seasons || []"
        :specials="vesselPosts.map((hit) => hit.post)"
        :loading="loadingNewCalendarDate"
        @add:event="openAddModal()"
        @update:view="updateCalendarView"
        @update:date:year="updateYear"
        @update:date:month="updateMonth"
        @update:date:week="updateWeek"
        @update:date:to:today="resetDateValues"
        @open:calendar:sharing="showCalendarSharingModal"
      />
      <YearCalendar
        v-if="currentCalendarView === CalendarViewEnum.YEAR"
        :year="dateTime.year"
        :vessel="vesselRef!"
        :events="vesselEventsRef"
      />
      <MonthCalendar
        v-else-if="currentCalendarView === CalendarViewEnum.MONTH"
        :year="dateTime.year"
        :month="dateTime.month"
        :vessel="vesselRef!"
        :events="vesselEventsRef"
        :specials="vesselPosts"
        @edit:event="openUpdateModal($event)"
        @delete:event="onActionDelete($event)"
      />
      <WeekCalendar
        v-else-if="currentCalendarView === CalendarViewEnum.WEEK"
        :year="dateTime.year"
        :month="dateTime.month"
        :week="dateTime.weekNumber"
        :vessel="vesselRef!"
        :events="vesselEventsRef"
      />

      <!-- Agenda -->
      <div
        class="p-4 md:p-8 border-t overflow-hidden overflow-y-auto scrollbar-hide bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-700"
      >
        <p v-if="!vesselEventsRef.length" class="font-bold">There are no events in for this yacht</p>
        <p v-else-if="!eventsInSelectedMonth.length" class="font-bold">
          There are no events in {{ dateTime.get('monthLong') }}, {{ dateTime.year }} for this yacht
        </p>
        <ul v-else class="flex flex-col">
          <p class="font-bold">Events in {{ dateTime.get('monthLong') }}, {{ dateTime.year }}</p>
          <li
            v-for="vesselEvent of eventsInSelectedMonth"
            class="flex items-center justify-between gap-x-2"
            :key="`yacht-event-${vesselEvent.id}`"
          >
            <AgendaEvent
              :event="vesselEvent"
              :vesselName="vesselRef?.blueprint.name"
              @open:update:modal="openUpdateModal($event)"
              @delete:event="onActionDelete($event)"
            />
          </li>
        </ul>
      </div>
    </div>

    <template v-if="vesselRef !== null">
      <!-- Add event modal -->
      <EventModal
        v-if="selectedModalType === 'Add'"
        :vessel-label-value="{ label: vesselRef.blueprint.name, uri: vesselRef.uri }"
        :vessel-variants="
          vesselRef.variants.map((variant) => ({
            label: variant.label,
            id: variant.id,
            turnaround: variant.turnaround,
          }))
        "
        @add:event="addEvent"
        @close:modal="closeEventModal"
      />
      <!-- Update event modal -->
      <EventModal
        v-if="selectedModalType === 'Update'"
        :vessel-label-value="{ label: vesselRef.blueprint.name, uri: vesselRef.uri }"
        :vessel-variants="
          vesselRef.variants.map((variant) => ({
            label: variant.label,
            id: variant.id,
            turnaround: variant.turnaround,
          }))
        "
        :calendar-event="calendarEventToUpdate"
        @update:event="updateEvent"
        @close:modal="closeEventModal"
      />
    </template>

    <Teleport v-if="showCarouselModal && vesselRef?.blueprint.images?.length" to="body">
      <ImageCarouselModal
        :slides="getCarouselImages()"
        :slideTo="selectedCarouselImageIndex"
        @close="showCarouselModal = false"
      />
    </Teleport>

    <ModalContentWrapper v-if="selectedModalType === 'Delete'">
      <DeleteConfirmation
        :message="deleteConfirmationModalData!.message"
        :label-cancel="deleteConfirmationModalData!.labelCancel"
        :label-confirm="deleteConfirmationModalData!.labelConfirm"
        @close:modal="updateModalState(false)"
        @confirm:modal="deleteEvent(deleteConfirmationModalData!.id)"
      />
    </ModalContentWrapper>

    <SimpleModal
      v-if="calendarSharingModalHelper.isVisible()"
      title="Yacht Calendar Sharing"
      :model="calendarSharingModalHelper.model"
      @dismiss="calendarSharingModalHelper.hide()"
    >
      <template #default>
        <div class="flex flex-col gap-y-10">
          <!-- Share Link -->
          <div class="flex flex-col gap-y-4">
            <label>Share with another Ankor Calendars user</label>
            <div class="grid grid-cols-[4fr_1fr] gap-4 w-full">
              <input
                disabled="true"
                class="grow border text-sm rounded-lg text-gray-900 dark:text-white py-2 px-4 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-700"
                :value="calendarLink"
              />
              <button
                type="button"
                class="justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border border-primary-600 hover:border-primary-800 text-sm px-5 py-2.5 text-center inline-flex items-center text-primary-600 hover:text-white focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300"
                @click="copyCalendarLink()"
              >
                {{ calendarLinkCopied ? 'Link Copied' : 'Copy Link' }}
              </button>
            </div>
          </div>

          <div class="flex flex-col gap-y-4">
            <div class="flex items-center gap-2">
              <OutlineCloudUpload class="w-8 text-gray-900 dark:text-white" />
              <h5>Pull calendar events in</h5>
            </div>

            <label for="calendar-events-import">Provide public link an external calendar</label>

            <div class="flex gap-x-4">
              <input
                class="grow border text-sm rounded-lg text-gray-900 dark:text-white py-2 px-4 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-700 disabled:cursor-not-allowed"
                id="calendar-events-import"
                placeholder="Paste public .ics link here"
                :disabled="calendarLinkExists || loadingImportCalendarLink"
                v-model="importCalendarLink"
              />
              <button
                v-if="importCalendarLink && calendarLinkExists"
                type="button"
                class="justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border border-primary-600 hover:border-primary-800 text-sm px-5 py-2.5 text-center inline-flex items-center text-primary-600 hover:text-white focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300"
                @click.stop="removeSubscription(importCalendarLink)"
              >
                Remove
              </button>
            </div>

            <template v-if="yachtsFromMMKPull?.length">
              <template v-if="pairedYacht">Paired Booking Manager Yacht: {{ pairedYacht?.name }}</template>
              <div v-else class="space-y-1">
                <span>Pair with Booking Manager Yacht</span>
                <OnClickOutside @trigger=";(showMmkYachtsList = false), (searchTerm = '')">
                  <div class="relative">
                    <div class="relative w-fit" @click="showMmkYachtsList = !showMmkYachtsList">
                      <input
                        readonly
                        class="border cursor-pointer rounded-md pl-4 pr-8 py-2 bg-transparent truncate text-gray-900 dark:text-white"
                        :value="selectedYachtForPairingRef?.name || 'Select yacht'"
                      />
                      <SolidChevronDown
                        class="size-5 cursor-pointer transition-all absolute top-1/2 right-2 -translate-y-1/2 text-gray-900 dark:text-white"
                        :class="{ 'rotate-180': showMmkYachtsList }"
                      />
                    </div>
                    <div
                      v-if="showMmkYachtsList"
                      class="w-full absolute mt-0.5 z-10 pt-2 border rounded-md bg-white dark:bg-gray-700 dark:border-white"
                    >
                      <div class="px-2">
                        <input
                          type="text"
                          placeholder="Search for a yacht..."
                          class="w-full rounded-md text-black"
                          @input="searchTerm = ($event.target as HTMLInputElement).value"
                        />
                      </div>
                      <ul class="h-40 mt-1 overflow-y-auto">
                        <template v-for="item in yachtsFromMMKPull" :key="item.name">
                          <li
                            v-if="
                              !searchTerm ||
                              item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              item.length?.toString().includes(searchTerm)
                            "
                            class="px-4 py-1.5 no-underline transition-all text-gray-900 dark:text-gray-50"
                            :value="item.id"
                            :disabled="item.paired"
                            :class="{
                              'opacity-25 cursor-default': item.paired,
                              'cursor-pointer dark:hover:bg-gray-600': !item.paired,
                              'font-bold': selectedYachtForPairingRef?.id === item.id,
                            }"
                            @click="selectedYachtForPairing(item.id.toString())"
                          >
                            {{ item.paired ? `${item.name} already paired` : `${item.name} (${item.length}m)` }}
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </OnClickOutside>
              </div>
            </template>

            <p>Import calendar events</p>
            <DropZone v-if="true" @file-dropped="uploadOneTimeImportFile" #default="{ dropZoneActive }">
              <div class="flex items-center justify-center w-full">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full h-32 transition-all border-2 border-dashed rounded-lg cursor-pointer"
                  :class="[
                    assetUploaderValidityStyling(uploadSuccessful, isFileTypeSupported),
                    dropZoneActive && 'bg-primary-50 dark:bg-gray-800 border-primary-600 border-dashed',
                  ]"
                >
                  <Spinner v-if="isUploading" class="w-10 h-10 shrink-0" />
                  <template v-else-if="dropZoneActive">
                    <SolidCalendar class="fill-primary-600 w-10 h-10" />
                    <p class="text-primary-600 font-semibold text-sm text-center">Drop ics file here</p>
                  </template>
                  <template v-else-if="!isFileTypeSupported">
                    <SolidExclamationCircle class="w-10 h-10 fill-red-600" />
                    <p class="text-sm font-bold text-red-600 mb-2 text-center">File type or size error</p>
                    <p class="text-xs font-semibold text-red-600 text-center">.ics supported.</p>
                  </template>
                  <template v-else-if="uploadSuccessful">
                    <SolidCheckMark class="w-10 h-10 fill-green-400 mb-3" />
                    <p class="text-sm font-semibold mb-4 text-center text-gray-500 dark:text-gray-400">
                      Upload complete!
                    </p>
                  </template>
                  <template v-else>
                    <OutlineCloudUpload class="w-8 stroke-gray-400 mt-6 first-letter:mb-2 shrink-0" />
                    <p class="mb-2 text-sm text-gray-500 text-center">
                      <span class="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p class="text-xs text-gray-500 mb-5 font-semibold text-center">(.ics)</p>
                    <input id="dropzone-file" type="file" class="hidden" @change="addFileFromInput" />
                  </template>
                </label>
              </div>
            </DropZone>
          </div>

          <div class="flex flex-col gap-y-4">
            <div class="flex items-center gap-2">
              <OutlineCloudDownload class="w-8 text-gray-900 dark:text-white" />
              <h5>Push calendar events out</h5>
            </div>

            <label for="export-calendar-link"> Get public link to share with external calendar </label>
            <div class="grid grid-cols-[4fr_1fr] gap-4 w-full">
              <input
                disabled="true"
                class="grow border text-sm rounded-lg text-gray-900 dark:text-white py-2 px-4 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-700"
                :value="exportCalendarLink"
              />
              <button
                type="button"
                class="justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border border-primary-600 hover:border-primary-800 text-sm px-5 py-2.5 text-center inline-flex items-center text-primary-600 hover:text-white focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300"
                @click="copyExportCalendarLink()"
              >
                {{ exportCalendarLinkCopied ? 'Link Copied' : 'Copy Link' }}
              </button>
            </div>

            <p>Export calendar events</p>
            <button
              type="button"
              class="inline-flex self-start transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border border-primary-600 hover:border-primary-800 text-sm px-5 py-2.5 text-center text-primary-600 hover:text-white focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300"
              @click="downloadCalendarEvents"
            >
              <OutlineDownload class="size-5 mr-2" />
              Download (.ics)
            </button>
          </div>
        </div>
        <hr class="my-6 border-gray-200 dark:border-gray-600" />
        <div class="w-full inline-flex justify-center gap-x-4">
          <button
            type="button"
            class="text-gray-500 dark:text-gray-200 bg-transparent transition-all hover:bg-gray-100 focus:bg-gray-100 dark:hover:bg-gray-600 dark:focus:bg-gray-600 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none dark:focus:ring-gray-600 border border-gray-200 dark:border-gray-500"
            @click="calendarSharingModalHelper.hide()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 transition-all focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 disabled:bg-primary-400 disabled:dark:bg-primary-500"
            :class="{ 'pointer-events-none': savingYachtPairing }"
            @click="handleSaveAndClose"
          >
            <template v-if="!savingYachtPairing">Save & Close</template>
            <Spinner v-else class="h-4 w-4" />
          </button>
        </div>
      </template>
    </SimpleModal>
  </div>
</template>
