<script setup lang="ts">
import { Ref, defineProps, nextTick, ref, watch } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { Discount, InputAmountsTaxed, LineItem, Pricing, TaxRate, Unit } from '@ankor-io/common/vessel/types'
import { OutlineTrash } from '@ankor-io/icons/outline'

import ButtonVue from '@/components/Button.vue'
import Dropdown from '@/components/Dropdown.vue'
import DiscountVue, { DiscountProps } from '@/components/modal-content/PricingDiscount.vue'
import QuantityVue, { QuantityProps } from '@/components/modal-content/PricingQuantity.vue'
import TaxRateVue, { TaxRateProps } from '@/components/modal-content/PricingTaxRate.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { useModal } from '@/hooks/modal/useModal'
import { restrictToNumbers } from '@/utils/restrictKeyPress'

type Props = {
  pricing: Pricing
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:pricing', value: Pricing): void
}>()

const { isOpen, updateModalState } = useModal()

const selectedModalType: Ref<'TaxRate' | 'Discount' | 'Quantity' | null> = ref(null)
const taxRateModalData: Ref<TaxRateProps | null> = ref(null)
const discountModalData: Ref<DiscountProps | null> = ref(null)
const quantityModalData: Ref<QuantityProps | null> = ref(null)

const selectedLineItemIndex: Ref<number> = ref(-1)
const inputRefs: Ref<HTMLInputElement[]> = ref([])

enum InputAmountsTaxedValues {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE',
  NONE = 'NONE',
}

enum DiscountTypeValues {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

const inputAmountTaxedOptions = [
  { label: 'Tax Inclusive', value: InputAmountsTaxedValues.INCLUSIVE },
  { label: 'Tax Exclusive', value: InputAmountsTaxedValues.EXCLUSIVE },
  { label: 'No Tax', value: InputAmountsTaxedValues.NONE },
]

const inputCurrencyOptions = [
  { label: ' ', value: null },
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'GBP', value: 'GBP' },
  { label: 'AUD', value: 'AUD' },
  { label: 'AED', value: 'AED' },
  { label: 'SGD', value: 'SGD' },
  { label: 'HKD', value: 'HKD' },
  { label: 'BTC', value: 'BTC' },
  { label: 'ETH', value: 'ETH' },
]

const unitOptions = [
  { label: 'Weekly', value: 'WEEK' },
  { label: 'Daily', value: 'DAY' },
  { label: 'Hourly', value: 'HOUR' },
]

const pricingLineColumns = [
  {
    key: 'item',
    label: 'Item',
  },
  {
    key: 'conditions',
    label: 'Conditions',
  },
  {
    key: 'quantity',
    label: 'Quantity',
  },
  {
    key: 'unitPrice',
    label: 'Price',
  },
  {
    key: 'discount',
    label: 'Discount',
  },
  {
    key: 'taxRate',
    label: 'Tax Rate',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
]

const updatePricingAttributes = (value: string) => {
  const updatedPricing: Pricing = ObjectUtil.deepCopy(props.pricing)
  updatedPricing.currency = value
  emit('update:pricing', updatedPricing)
}

const updatePricingNote = (event: Event) => {
  const updatedPricing: Pricing = ObjectUtil.deepCopy(props.pricing)
  updatedPricing.note = (event.target as HTMLTextAreaElement).value
  emit('update:pricing', updatedPricing)
}

const updatePricingUnit = (value: Unit) => {
  const updatedPricing: Pricing = ObjectUtil.deepCopy(props.pricing)
  updatedPricing.unit = value
  emit('update:pricing', updatedPricing)
}

const updateInputAmountTaxed = (value: InputAmountsTaxed) => {
  const updatedPricing: Pricing = ObjectUtil.deepCopy(props.pricing)
  updatedPricing.inputAmountTaxed = value
  reComputeTotals(updatedPricing)
}

/**
 * Compute totals for pricing based on Amounts being INCLUSIVE, EXCLUSIVE, NONE
 *
 * @param pricing
 */
const reComputeTotals = (pricing: Pricing): void => {
  const updatedPricing: Pricing = ObjectUtil.deepCopy(pricing)

  if (!pricing?.lineItems?.length) {
    updatedPricing.subTotal = 0
    updatedPricing.totalTax = 0
    updatedPricing.total = 0
    emit('update:pricing', updatedPricing)
    return
  }

  updatedPricing.subTotal = pricing.lineItems.reduce((acc, lineItem) => {
    return acc + lineItem.amount
  }, 0)

  if (updatedPricing.inputAmountTaxed === InputAmountsTaxedValues.INCLUSIVE || !props.pricing.inputAmountTaxed) {
    updatedPricing.totalTax = pricing.lineItems.reduce((acc, lineItem) => {
      return acc + lineItem.amount * (lineItem.taxRate?.value ?? 0)
    }, 0)

    updatedPricing.total = updatedPricing.subTotal
    emit('update:pricing', updatedPricing)
    return
  }

  if (updatedPricing.inputAmountTaxed === InputAmountsTaxedValues.EXCLUSIVE) {
    updatedPricing.totalTax = pricing.lineItems.reduce((acc, lineItem) => {
      return acc + lineItem.amount * (lineItem.taxRate?.value ?? 0)
    }, 0)
    updatedPricing.total = updatedPricing.subTotal + updatedPricing.totalTax
    emit('update:pricing', updatedPricing)
    return
  }

  updatedPricing.totalTax = 0
  updatedPricing.total = updatedPricing.subTotal
  emit('update:pricing', updatedPricing)
}

const getAmount = (lineItemPricing: LineItem): number => {
  const amount = lineItemPricing.quantity * lineItemPricing.unitPrice
  if (!lineItemPricing.discount) {
    return amount
  }

  if (lineItemPricing.discount.type === DiscountTypeValues.PERCENTAGE) {
    return amount - amount * lineItemPricing.discount.value
  }

  return amount - lineItemPricing.discount.value
}

const getLineItemAttribute = (lineItemPricing: LineItem, attribute: string): string => {
  if (attribute === 'quantity') {
    const suffix = lineItemPricing.quantity >= 1 ? '' : '%'
    const quantityToDisplay = suffix === '%' ? (lineItemPricing[attribute] || 0) * 100 : lineItemPricing[attribute]
    return lineItemPricing[attribute] ? `${Math.round(quantityToDisplay * 100) / 100}${suffix}` : '-'
  }

  if (attribute === 'taxRate') {
    return lineItemPricing[attribute]
      ? `${lineItemPricing[attribute]?.label} ${
          Math.round((lineItemPricing[attribute]?.value || 0) * 100 * 100) / 100
        }%`
      : '-'
  }

  if (attribute === 'discount') {
    const value =
      lineItemPricing[attribute]?.type === DiscountTypeValues.AMOUNT
        ? (lineItemPricing[attribute]?.value || 0) / 100
        : Math.round((lineItemPricing[attribute]?.value || 0) * 100 * 100) / 100
    return lineItemPricing[attribute]
      ? `${value}${lineItemPricing[attribute]?.type === DiscountTypeValues.PERCENTAGE ? '%' : ''}`
      : '-'
  }

  if (attribute === 'amount' || attribute === 'unitPrice') {
    return (Number(lineItemPricing[attribute]) / 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  if (attribute === 'item' || attribute === 'conditions') {
    return lineItemPricing[attribute]?.toString() ?? ''
  }

  return ''
}

const updateLineItemQuantity = (index: number, quantity: number) => {
  const lineItems: LineItem[] = ObjectUtil.deepCopy(props.pricing.lineItems)
  lineItems[index].quantity = quantity
  lineItems[index]['amount'] = getAmount(lineItems[index])
  reComputeTotals({ ...props.pricing, lineItems })
}

const updateUnitPrice = (index: number, $event: Event) => {
  const lineItems: LineItem[] = ObjectUtil.deepCopy(props.pricing.lineItems)

  const value = ($event.target as HTMLInputElement).value
  selectedLineItemIndex.value = -1
  // have to multiply by 100 as we always store cent values, return if nothing changed
  if (lineItems[index]['unitPrice'] === Number(value) * 100) {
    return
  }

  lineItems[index]['unitPrice'] = Number(value) * 100
  lineItems[index]['amount'] = getAmount(lineItems[index])
  reComputeTotals({ ...props.pricing, lineItems })
}

const updateItemConditions = (index: number, attribute: string, event: Event) => {
  if (!['item', 'conditions'].includes(attribute)) {
    return
  }
  const lineItems: LineItem[] = ObjectUtil.deepCopy(props.pricing.lineItems)
  lineItems[index][attribute === 'item' ? 'item' : 'conditions'] = (event.target as HTMLInputElement).value
  emit('update:pricing', { ...props.pricing, lineItems })
}

const updateLineItem = (index: number, attribute: string, value: string) => {
  const lineItems: LineItem[] = ObjectUtil.deepCopy(props.pricing.lineItems)

  if (attribute === 'taxRate') {
    lineItems[index][attribute] = JSON.parse(value)
    lineItems[index]['amount'] = getAmount(lineItems[index])
  }
  if (attribute === 'discount') {
    lineItems[index][attribute] = JSON.parse(value)
    lineItems[index]['amount'] = getAmount(lineItems[index])
  }
  reComputeTotals({ ...props.pricing, lineItems })
}

const addPricingLineItem = () => {
  const updatedPricing: Pricing = ObjectUtil.deepCopy(props.pricing)
  if (!updatedPricing.lineItems) {
    updatedPricing.lineItems = []
  }
  updatedPricing.lineItems.push({
    item: '',
    conditions: '',
    quantity: 1,
    unitPrice: 0,
    amount: 0,
  })
  emit('update:pricing', updatedPricing)
}

const deletePricingLineItem = (lineItemIndex: number) => {
  const updatedPricing: Pricing = ObjectUtil.deepCopy(props.pricing)
  updatedPricing.lineItems.splice(lineItemIndex, 1)
  reComputeTotals(updatedPricing)
}

// TAX RATE MODAL
const openTaxRatePopup = (lineItemIndex: number, taxRate: TaxRate) => {
  selectedModalType.value = 'TaxRate'
  selectedLineItemIndex.value = lineItemIndex
  taxRateModalData.value = { taxRateName: taxRate.label || '', taxRateValue: taxRate.value?.toString() || '' }
  updateModalState(true)
}

const submitTaxRate = ($event: TaxRateProps) => {
  const { taxRateName, taxRateValue } = $event
  updateLineItem(
    selectedLineItemIndex.value,
    'taxRate',
    JSON.stringify({ label: taxRateName, value: Number(taxRateValue) / 100 }),
  )
  updateModalState(false)
  taxRateModalData.value = null
}

// DISCOUNT MODAL
const openDiscountPopup = (lineItemIndex: number, discount: Discount) => {
  selectedModalType.value = 'Discount'
  selectedLineItemIndex.value = lineItemIndex
  discountModalData.value = {
    discountType: discount.type || 'PERCENTAGE',
    discountValue: discount.value?.toString() || '',
  }
  updateModalState(true)
}

const submitDiscount = ($event: DiscountProps) => {
  const { discountType, discountValue } = $event
  updateLineItem(
    selectedLineItemIndex.value,
    'discount',
    JSON.stringify({
      type: discountType,
      value: discountType === DiscountTypeValues.AMOUNT ? discountValue : Number(discountValue) / 100,
    }),
  )
  updateModalState(false)
  discountModalData.value = null
}

// QUANTITY MODAL
const openQuantityPopup = (
  lineItemIndex: number,
  quantity: { displayQuantityAsPercent: boolean; quantity: number },
) => {
  selectedModalType.value = 'Quantity'
  selectedLineItemIndex.value = lineItemIndex
  quantityModalData.value = {
    displayQuantityAsPercent: quantity.displayQuantityAsPercent,
    quantity: quantity.quantity?.toString() || '',
  }
  updateModalState(true)
}

const submitQuantity = ($event: QuantityProps) => {
  const { displayQuantityAsPercent, quantity } = $event
  const quantityPercent = displayQuantityAsPercent ? Number(quantity) / 100 : Number(quantity)
  updateLineItemQuantity(selectedLineItemIndex.value, quantityPercent)
  updateModalState(false)
  quantityModalData.value = null
}

const closeModal = () => {
  updateModalState(false)
  selectedModalType.value = null
}

/**
 * show editable unit price input and focus
 * @param index index of lineItem
 */
const showEditablePrice = (index: number) => {
  selectedLineItemIndex.value = index
  nextTick(() => inputRefs.value[index].focus())
}

watch(isOpen, (value) => {
  if (!value) {
    selectedModalType.value = null
  }
})
</script>
<template>
  <section>
    <div class="flex gap-2 w-full">
      <!-- Pricing note -->
      <div class="flex flex-col w-full">
        <label for="pricing-note" class="text-sm text-black dark:text-white">Note:</label>
        <input
          type="text"
          id="pricing-note"
          class="p-0 pb-2.5 block w-full text-sm border-0 border-b border-b-transparent bg-transparent !ring-0 focus:border-b-blue-500 dark:placeholder-gray-400 dark:text-white dark:focus:border-b-blue-500"
          placeholder="Enter note here"
          :value="props.pricing?.note || ''"
          @blur="updatePricingNote($event)"
        />
      </div>
      <!-- Prices -->
      <div class="flex flex-col gap-x-2">
        <span class="text-sm text-black dark:text-white">Prices are:</span>
        <div class="relative w-24 h-8 flex-none text-left">
          <Dropdown
            id="unit"
            :value="props.pricing.unit || 'WEEK'"
            :options="unitOptions"
            @select:value="updatePricingUnit($event as Unit)"
          />
        </div>
      </div>
      <!-- Currency -->
      <div class="flex flex-col gap-x-2">
        <span class="text-sm text-black dark:text-white">Currency:</span>
        <div class="relative w-24 h-8 flex-none text-left">
          <Dropdown
            id="currency"
            :value="props.pricing.currency"
            :options="inputCurrencyOptions"
            @select:value="updatePricingAttributes($event)"
          />
        </div>
      </div>
      <!-- Amounts -->
      <div class="flex flex-col gap-x-2">
        <span class="text-sm whitespace-nowrap text-black dark:text-white">Amounts are:</span>
        <div class="relative w-24 h-8 flex-none text-left">
          <Dropdown
            id="inputAmountTaxed"
            :value="props.pricing.inputAmountTaxed"
            :options="inputAmountTaxedOptions"
            @select:value="updateInputAmountTaxed($event as InputAmountsTaxed)"
          />
        </div>
      </div>
    </div>

    <!-- Pricing table -->
    <div class="relative mb-4">
      <div
        class="overflow-x-auto overflow-y-hidden drop-shadow-sm sm:rounded-lg mx-auto mt-4 border border-gray-200 dark:border-gray-500"
      >
        <table
          class="w-full text-sm text-gray-700 dark:text-gray-400 text-left whitespace-nowrap"
          aria-describedby="Pricing"
        >
          <thead class="text-xs font-semibold uppercase text-gray-500 dark:text-gray-200">
            <tr>
              <th
                v-for="(pricingLineColumn, index) of pricingLineColumns"
                scope="col"
                class="min-w-[7rem] px-2 py-4"
                :key="`${pricingLineColumn.key}-${index}`"
              >
                <span :id="`${pricingLineColumn.key}-${index}`">
                  {{ pricingLineColumn.label }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(lineItem, lineItemIndex) of props.pricing.lineItems"
              class="whitespace-nowrap border-t border-gray-200 dark:border-gray-500 h-12"
              :key="`${lineItem.item}-${lineItemIndex}`"
            >
              <td
                v-for="(pricingLineColumn, index) of pricingLineColumns"
                class="ml-3 p-2 min-w-[8rem] max-w-[8rem] text-sm text-gray-900 dark:text-white"
                :key="`${pricingLineColumn.key}-${lineItemIndex}-${index}`"
                :id="`${pricingLineColumn.key}-${lineItemIndex}-${index}`"
                :class="{
                  'cursor-not-allowed':
                    pricingLineColumn.key === 'taxRate' &&
                    props.pricing.inputAmountTaxed === InputAmountsTaxedValues.NONE,
                }"
              >
                <!-- Item & conditions -->
                <input
                  v-if="['item', 'conditions'].includes(pricingLineColumn.key)"
                  type="text"
                  class="text-sm py-2.5 px-0 w-full bg-transparent border-0 border-b-2 border-gray-200 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  :class="{ 'cursor-not-allowed': pricingLineColumn.key === 'item' && lineItemIndex === 0 }"
                  :disabled="pricingLineColumn.key === 'item' && lineItemIndex === 0"
                  :value="getLineItemAttribute(lineItem, pricingLineColumn.key)"
                  :placeholder="`Enter ${pricingLineColumn.label}`"
                  @blur="updateItemConditions(lineItemIndex, pricingLineColumn.key, $event)"
                />
                <!-- Qty -->
                <div
                  v-else-if="pricingLineColumn.key === 'quantity'"
                  class="cursor-default font-semibold"
                  @click.stop="
                    openQuantityPopup(lineItemIndex, {
                      displayQuantityAsPercent: lineItem.quantity < 1,
                      quantity: lineItem.quantity,
                    })
                  "
                >
                  {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                </div>
                <!-- Price -->
                <div v-else-if="pricingLineColumn.key === 'unitPrice'">
                  <input
                    v-if="selectedLineItemIndex === lineItemIndex"
                    type="number"
                    pattern="^\d+(?:\.\d{1,2})?$"
                    class="text-sm py-2.5 px-0 w-full bg-transparent border-0 border-b-2 border-gray-200 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    :placeholder="`Enter ${pricingLineColumn.label}`"
                    :value="Number(lineItem['unitPrice']) / 100"
                    :ref="(el) => (inputRefs[lineItemIndex] = el as HTMLInputElement)"
                    @keydown.up.prevent
                    @keydown.down.prevent
                    @keypress="restrictToNumbers($event)"
                    @blur="updateUnitPrice(lineItemIndex, $event)"
                  />
                  <span v-else class="font-semibold" @click.stop="showEditablePrice(lineItemIndex)">
                    {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                  </span>
                </div>
                <!-- Discount -->
                <div
                  v-else-if="pricingLineColumn.key === 'discount'"
                  class="cursor-default font-semibold"
                  @click.stop="openDiscountPopup(lineItemIndex, lineItem.discount || ({} as Discount))"
                >
                  {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                </div>
                <!-- Tax rate 1 -->
                <div
                  v-else-if="
                    pricingLineColumn.key === 'taxRate' &&
                    props.pricing.inputAmountTaxed === InputAmountsTaxedValues.NONE
                  "
                  class="font-semibold"
                >
                  -
                </div>
                <!-- Tax rate 2 -->
                <div
                  v-else-if="pricingLineColumn.key === 'taxRate'"
                  class="cursor-default font-semibold whitespace-pre-wrap"
                  @click.stop="openTaxRatePopup(lineItemIndex, lineItem.taxRate || ({} as TaxRate))"
                >
                  {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                </div>
                <!-- Amount -->
                <div v-else-if="pricingLineColumn.key === 'amount'" class="cursor-default pr-2 font-semibold">
                  {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                </div>
              </td>
              <!-- Trash -->
              <td v-if="lineItemIndex > 0" class="px-4 cursor-pointer" @click="deletePricingLineItem(lineItemIndex)">
                <OutlineTrash class="w-4 h-4 stroke-red-600 hover:stroke-red-500" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="flex justify-between">
      <!-- Add new line to pricing table -->
      <ButtonVue
        class="h-fit w-min"
        name="+ Add a line"
        :isPrimary="false"
        :has-border="true"
        @click.stop="addPricingLineItem"
      ></ButtonVue>

      <!-- Pricing total -->
      <div class="grid gap-x-4 gap-y-1 grid-cols-2 text-right text-sm">
        <template v-if="props.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
          <span class="text-gray-400">Subtotal:</span>
          <span class="font-semibold">
            {{
              props.pricing.subTotal
                ? (props.pricing.subTotal / 100).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0
            }}
            {{ props.pricing.currency }}
          </span>
        </template>

        <span v-if="props.pricing.inputAmountTaxed === InputAmountsTaxedValues.INCLUSIVE" class="text-gray-400">
          Includes Tax:
        </span>
        <span v-if="props.pricing.inputAmountTaxed === InputAmountsTaxedValues.EXCLUSIVE" class="text-gray-400">
          Total Tax:
        </span>
        <span class="font-semibold" v-if="props.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
          {{
            props.pricing.totalTax
              ? (props.pricing.totalTax / 100).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }}
          {{ props.pricing.currency }}
        </span>

        <span class="text-gray-400">Total:</span>
        <span
          class="font-semibold"
          :class="{ 'border-t': props.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE }"
        >
          {{
            props.pricing.total
              ? (props.pricing.total / 100).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }}
          {{ props.pricing.currency }}
        </span>
      </div>
    </div>

    <ModalContentWrapper v-if="selectedModalType">
      <TaxRateVue
        v-if="selectedModalType === 'TaxRate'"
        :tax-rate-name="taxRateModalData!.taxRateName"
        :tax-rate-value="taxRateModalData!.taxRateValue"
        @close:modal="closeModal()"
        @confirm:modal="submitTaxRate($event)"
      />
      <DiscountVue
        v-if="selectedModalType === 'Discount'"
        :discount-type="discountModalData!.discountType"
        :discount-value="discountModalData!.discountValue"
        @close:modal="closeModal()"
        @confirm:modal="submitDiscount($event)"
      />
      <QuantityVue
        v-if="selectedModalType === 'Quantity'"
        :display-quantity-as-percent="quantityModalData!.displayQuantityAsPercent"
        :quantity="quantityModalData!.quantity"
        @close:modal="closeModal()"
        @confirm:modal="submitQuantity($event)"
      />
    </ModalContentWrapper>
  </section>
</template>
