<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { initPopovers } from 'flowbite'
import { Ref, inject, onMounted, ref } from 'vue'

import AccordionComponent from '@ankor-io/blocks/components/Accordion/Accordion.vue'
import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import CrewCardComponent from '@ankor-io/blocks/components/CrewCard/CrewCard.vue'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { Trip } from '@ankor-io/common/trip/Trip'
import { isValidURL } from '@ankor-io/common/url/isValidURL'
import { formatLength } from '@ankor-io/common/vessel/Formatter'
import type { KVPair, Vessel } from '@ankor-io/common/vessel/types'
import { HiSolidUser } from '@ankor-io/icons/hi_solid'
import { LineArtSleeping } from '@ankor-io/icons/line_art'
import {
  OutlineCalendarThin,
  OutlineCaptainCrew,
  OutlineDocumentText,
  OutlinePencilAlt,
  OutlineShare,
} from '@ankor-io/icons/outline'
import { OutlineCheck, OutlineClipboardCopy } from '@ankor-io/icons/outline'
import { SolidBriefcase, SolidChevronDown, SolidLocationMarker, SolidYacht } from '@ankor-io/icons/solid'

import PreviewVariant from '@/components/PreviewVariant.vue'
import Spinner from '@/components/Spinner.vue'
import BrochureGeneratedModalContent from '@/components/modal-content/BrochureGeneratedModalContent.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { useModal } from '@/hooks/modal/useModal'
import { AuthenticationContext } from '@/iam/types'

type PreviewProps = {
  vessel: Vessel
  managedBy: string
}

const props = defineProps<PreviewProps>()
const emit = defineEmits<{
  (e: 'openCarousel', index: number): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const currentDownloadsInProgress: Ref<string[]> = ref([])

// Share calendar
const calendarLinkCopied: Ref<boolean> = ref(false)
let yachtLinkCopiedTimeout: ReturnType<typeof setTimeout> | null = null

// Share brochure
const isBrochureDropdownOpen: Ref<boolean> = ref(false)
const generatingBrochure: Ref<boolean> = ref(false)
const { updateModalState } = useModal()
const isModalOpen: Ref<boolean> = ref(false)
const trip: Ref<Trip | null> = ref(null)

/**
 * Enum for copy email status
 */
enum copyEmailEnums {
  COPYEMAIL = 'Copy Email',
  COPIED = 'Copied',
}

type copyEmailStatus = copyEmailEnums.COPYEMAIL | copyEmailEnums.COPIED
const copyEmailRefs: Ref<copyEmailStatus[]> = ref([])

const openCarousel = (index: number) => {
  emit('openCarousel', index)
}

const isAttachmentDownloading = (attachment: { label: string; value: string }): boolean => {
  return currentDownloadsInProgress.value.includes(`${attachment.label}-${attachment.value}`)
}

const downloadAttachment = async (attachment: KVPair) => {
  const { label, value } = attachment
  currentDownloadsInProgress.value = [...currentDownloadsInProgress.value, `${attachment.label}-${attachment.value}`]

  await fetch(`/api/asset/download/${props.vessel.uri}?filepath=${value}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${(await authenticationContext.getToken())!}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = label
      // we need to append the element to the dom -> otherwise it will not work in firefox
      document.body.appendChild(a)
      a.click()
      a.remove() //afterwards we remove the element again
    })

  currentDownloadsInProgress.value = [
    ...currentDownloadsInProgress.value.filter((download) => download !== `${attachment.label}-${attachment.value}`),
  ]
}

const validUrl = (link: string) => {
  if (!isValidURL(link)) {
    return 'http://' + link
  } else {
    return link
  }
}

const shareCalendar = () => {
  calendarLinkCopied.value = false
  clearTimeout(yachtLinkCopiedTimeout!)

  navigator.clipboard.writeText(window.location.href.replace(/calendars|commodore/, 'radar'))
  calendarLinkCopied.value = true
  // reset the calendar link copied value so the users know they can copy the link again
  yachtLinkCopiedTimeout = setTimeout(() => {
    calendarLinkCopied.value = false
  }, 3000)
}

const getBrochureLink = () => {
  if (window.location.host.includes('ankor.io')) {
    return `https://lite.ankor.io/${props.vessel.uri}/brochure`
  } else {
    return `https://lite.ankor.dev/${props.vessel.uri}/brochure`
  }
}

const openGeneratedBrochureModal = () => {
  isModalOpen.value = true
  updateModalState(true)
}

const generateBrochure = async () => {
  generatingBrochure.value = true
  const token = await authenticationContext.getToken()

  const res = await fetch(`/api/vessel/brochure/${props.vessel.uri}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    trip.value = await res.json()
    openGeneratedBrochureModal()
  }
  generatingBrochure.value = false
}

/**
 * Copy the email to the clipboard
 * @param email the email to be copied
 * @param index the email array index
 */
const copyToClipboard = (email: string, index: number) => {
  window.navigator.clipboard.writeText(email).then(() => {
    copyEmailRefs.value[index] = copyEmailEnums.COPIED
  })
}

onMounted(initPopovers)
</script>
<template>
  <div class="@container">
    <h4 class="text-center font-semibold text-xl text-gray-900 dark:text-white">
      {{ props.vessel.blueprint.name }}
    </h4>

    <p v-if="props.managedBy" class="text-center font-semibold text-gray-500 dark:text-gray-400">
      {{ props.managedBy }}
    </p>

    <div v-if="props.vessel.tags?.length" class="flex justify-center mt-2 gap-2">
      <span
        v-for="(tag, index) of props.vessel.tags"
        class="cursor-default flex items-center px-3 py-1 rounded-xl font-medium text-xs text-white blue-purple-gradient"
        :key="`${index}-${tag}`"
      >
        {{ tag }}
      </span>
    </div>

    <div class="flex justify-center gap-x-2 mt-8">
      <!-- Share brochure -->
      <div class="relative group">
        <button
          id="brochure-dropdown-btn"
          type="button"
          class="inline-flex items-center gap-x-2 font-medium rounded-lg text-sm px-3 py-2 text-center focus:ring-2 focus:outline-none transition-all border text-primary-600 dark:text-white dark:border-white bg-transparent hover:bg-primary-300 dark:hover:bg-gray-700 focus:bg-white dark:focus:bg-gray-500 border-primary-600 hover:border-primary-600 focus:border-transparent focus:ring-primary-300 dark:focus:ring-gray-300"
          @click.stop="isBrochureDropdownOpen = !isBrochureDropdownOpen"
        >
          <OutlineDocumentText class="w-4 h-4 shrink-0" />
          Brochure
          <SolidChevronDown
            class="w-4 h-4 shrink-0 transition-all group-hover:translate-y-2"
            :class="{ 'translate-y-2': isBrochureDropdownOpen }"
          />
        </button>
        <OnClickOutside @trigger="isBrochureDropdownOpen = false">
          <!-- Dropdown menu -->
          <div
            v-if="isBrochureDropdownOpen"
            id="brochure-dropdown"
            class="absolute -left-12 top-11 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700"
          >
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
              <li class="cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                <a class="flex items-center gap-x-2" :href="getBrochureLink()">
                  <OutlinePencilAlt class="w-4 h-4 shrink-0" />
                  Preview & Edit
                </a>
              </li>
              <li
                class="cursor-pointer flex items-center gap-x-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="generateBrochure()"
              >
                <div class="flex items-center gap-x-2">
                  <Spinner v-if="generatingBrochure" class="w-4 h-4"></Spinner>
                  <span v-else class="flex w-full gap-x-2">
                    <OutlineShare class="w-4 h-4 shrink-0" />
                    Share
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </OnClickOutside>
      </div>

      <!-- Share calendar -->
      <button
        name="button"
        type="button"
        class="group flex items-center gap-x-2 outline-none rounded-lg py-2 px-3 focus:ring-2 whitespace-nowrap border transition-all text-white bg-primary-600 hover:bg-primary-800 active:bg-primary-600 focus:bg-primary-600 focus:ring-primary-300 dark:focus:ring-blue-800 border-primary-600 hover:border-primary-800"
        @click="shareCalendar()"
      >
        <OutlineCalendarThin class="w-4 h-4 shrink-0 text-gray-50" />
        <span class="text-sm font-medium w-full">{{ calendarLinkCopied ? 'Link Copied!' : 'Share Calendar' }}</span>
      </button>
    </div>

    <!-- Vessel assets -->
    <div class="mt-4 relative">
      <div class="cursor-pointer" @click.stop="openCarousel(0)">
        <!-- Hero image -->
        <AssetViewer
          v-if="props.vessel.blueprint.hero"
          class="rounded-xl object-contain"
          :url="`/media/${replacePathToMediaUris(props.vessel.uri, props.vessel.blueprint.hero)[0]}`"
        />

        <!-- Vessel images -->
        <div v-if="props.vessel.blueprint.images?.length" class="grid grid-cols-2 @sm:grid-cols-4 my-4 gap-2 mx-auto">
          <div
            v-for="(img, index) in props.vessel.blueprint.images.slice(1, 9).filter((img) => !!img)"
            :key="img"
            :class="{ relative: index === 7 && props.vessel.blueprint.images.length - 9 > 0 }"
          >
            <div class="w-full h-28" @click.stop="openCarousel(index + 1)">
              <AssetViewer
                class="rounded-lg object-cover"
                :url="`/media/${replacePathToMediaUris(props.vessel.uri, img)[0]}`"
                :widthDescriptors="['320w']"
              />
            </div>
            <div
              v-if="index === 7 && props.vessel.blueprint.images.length - 9 > 0"
              class="absolute bottom-2.5 right-2.5 w-fit cursor-pointer rounded-md py-0.5 px-2.5 text-xs font-medium text-gray-900 bg-gray-50"
              @click.stop="openCarousel(0)"
            >
              +{{ props.vessel.blueprint.images.length - 9 }} more
            </div>
          </div>
        </div>
      </div>

      <!-- Vessel highlights -->
      <div
        class="p-5 rounded-lg bg-slate-100 dark:bg-slate-700 dark:bg-opacity-50 flex justify-around text-xl tracking-wider text-gray-900 dark:text-white"
      >
        <div class="flex flex-col items-center">
          <SolidYacht class="w-8 h-8" />
          {{ formatLength(props.vessel.blueprint.length) }}
        </div>
        <div class="flex flex-col items-center">
          <LineArtSleeping class="w-8 h-8" />
          {{ props.vessel.blueprint.sleeps }}
        </div>
        <div class="flex flex-col items-center">
          <OutlineCaptainCrew class="w-8 h-8" />
          {{ props.vessel.blueprint.maxCrew }}
        </div>
      </div>
    </div>

    <!-- CA contact info -->
    <div
      v-if="props.vessel.contacts?.length"
      class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <AccordionComponent data-accordion="open" id="accordion-ca-contact">
        <template #title>CA Contact Information</template>
        <template #content>
          <div class="grid @sm:grid-cols-2 gap-4">
            <div
              v-for="(contact, contactIndex) in props.vessel.contacts"
              class="flex flex-col gap-y-3"
              :key="`${contactIndex}-${contact.name}`"
            >
              <div class="flex flex-col">
                <div class="flex flex-wrap items-center gap-2">
                  <HiSolidUser class="w-4 h-4 shrink-0 fill-gray-700 dark:fill-gray-300" />
                  <span class="text-gray-500 dark:text-gray-400">{{ contact.name }}</span>
                </div>
                <div v-if="contact.companyName" class="flex flex-wrap items-center gap-2">
                  <SolidBriefcase class="w-4 h-4 shrink-0 fill-gray-700 dark:fill-gray-300" />
                  <span class="text-gray-500 dark:text-gray-400">{{ contact.companyName }}</span>
                </div>
                <div v-if="contact.country" class="flex flex-wrap items-center gap-2">
                  <SolidLocationMarker class="w-4 h-4 shrink-0 fill-gray-700 dark:fill-gray-300" />
                  <span class="text-gray-500 dark:text-gray-400">{{ contact.country }}</span>
                </div>
              </div>
              <div
                v-if="contact.email"
                class="select-none flex flex-col text-sm text-gray-500 dark:text-gray-400 cursor-pointer hover:text-primary-700 dark:hover:text-primary-300 hover:bg-gray-50 dark:hover:bg-gray-900 rounded-lg"
                :data-popover-target="`copy-popover-${contactIndex}`"
                @mouseenter="copyEmailRefs[contactIndex] = copyEmailEnums.COPYEMAIL"
                @click.stop="copyToClipboard(contact.email!, contactIndex)"
              >
                Email address
                <div class="flex justify-between">
                  <span class="font-semibold">{{ contact.email }}</span>
                  <OutlineCheck
                    v-show="copyEmailRefs[contactIndex] === copyEmailEnums.COPIED"
                    class="w-5 h-5 stroke-primary-700 dark:stroke-primary-300"
                  />
                  <OutlineClipboardCopy
                    v-show="copyEmailRefs[contactIndex] !== copyEmailEnums.COPIED"
                    class="w-5 h-5 stroke-gray-700 dark:stroke-gray-300"
                  />
                </div>
                <!-- margin to override injected inline styles -->
                <div
                  data-popover
                  role="tooltip"
                  class="absolute z-50 !mt-3 inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
                  :id="`copy-popover-${contactIndex}`"
                >
                  <div class="p-2">{{ copyEmailRefs[contactIndex] }}</div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <div v-if="contact.phoneNumbers?.length" class="flex flex-col text-sm text-gray-500 dark:text-gray-400">
                <div>Phone number</div>
                <p class="font-semibold">{{ contact.phoneNumbers?.join(' / ') }}</p>
              </div>
              <div v-if="contact.mobileNumber" class="flex flex-col text-sm text-gray-500 dark:text-gray-400">
                <div>Mobile number</div>
                <p class="font-semibold">{{ contact.mobileNumber }}</p>
              </div>
              <div v-if="contact.website" class="flex flex-col text-sm text-gray-500 dark:text-gray-400">
                <div>Website</div>
                <p class="font-semibold">{{ contact.website }}</p>
              </div>
            </div>
          </div>
        </template>
      </AccordionComponent>
    </div>

    <!-- Attachments -->
    <div
      v-if="props.vessel.attachments?.length"
      id="attachments"
      class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <h3 class="mb-3 font-bold tracking-wider text-gray-700 dark:text-gray-300">Attachments</h3>
      <div class="flex flex-wrap gap-3">
        <template
          v-for="(attachment, attachmentIndex) of props.vessel.attachments"
          :key="`${attachment.label}-${attachmentIndex}`"
        >
          <button
            v-if="attachment.label || attachment.value"
            class="flex items-center gap-x-2 py-2 px-3 transition-all border font-medium rounded-lg text-xs text-gray-900 focus:z-10 focus:ring-4 focus:outline-none dark:text-gray-400 bg-white dark:bg-gray-800 dark:focus:ring-gray-700"
            :class="
              isAttachmentDownloading(attachment)
                ? 'cursor-not-allowed flex items-center gap-x-2 bg-gray-200 dark:bg-gray-300'
                : 'border-gray-200 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 hover:bg-gray-100'
            "
            :id="`${attachmentIndex}-${attachment.label}-${attachment.value}`"
            :disabled="isAttachmentDownloading(attachment)"
            @click="downloadAttachment(attachment)"
          >
            {{ attachment.label || attachment.value }}
            <Spinner v-if="isAttachmentDownloading(attachment)" class="w-4 h-4 ml-1" />
          </button>
        </template>
      </div>
    </div>

    <!-- Links -->
    <div
      v-if="props.vessel.links?.length"
      class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <h3 class="mb-3 font-bold tracking-wider text-gray-700 dark:text-gray-300">Links</h3>
      <div class="flex flex-wrap gap-3">
        <template v-for="(link, linkIndex) of props.vessel.links" :key="linkIndex">
          <a
            v-if="link.value"
            class="flex items-center gap-x-2 py-2 px-3 transition-all border font-medium rounded-lg text-xs text-gray-900 focus:z-10 focus:ring-4 focus:outline-none dark:text-gray-400 bg-white dark:bg-gray-800 dark:focus:ring-gray-700 border-gray-200 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 hover:bg-gray-100"
            target="_blank"
            :key="linkIndex"
            :href="validUrl(link.value)"
          >
            {{ link.label || link.value }}
          </a>
        </template>
      </div>
    </div>

    <!-- Vessel description -->
    <div
      class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <AccordionComponent data-accordion="open" id="accordion-vessel-description">
        <template #title>Description</template>
        <template #content>
          <p class="text-sm text-gray-900 dark:text-gray-300 font-normal whitespace-pre-wrap">
            {{ props.vessel.description }}
          </p>
        </template>
      </AccordionComponent>
    </div>

    <!-- Vessel specifications -->
    <div
      class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <AccordionComponent data-accordion="open" id="accordion-specifications">
        <template #title>Specifications</template>
        <template #content>
          <!-- Yacht Type & Crewed / Bareboat -->
          <div class="my-4 grid @sm:grid-cols-2 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm">
            <div class="flex">
              <label>Type:</label>
              <div v-if="props.vessel.yachtType?.length">
                <span
                  v-for="yachtType in props.vessel.yachtType"
                  :key="yachtType"
                  class="mx-1 bg-gray-100 text-gray-900 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-white"
                >
                  {{ yachtType }}
                </span>
              </div>
              <span v-else>-</span>
            </div>
            <div class="flex">
              <label>Crewed/Bareboat:</label>
              <div v-if="props.vessel.charterType?.length">
                <span
                  v-for="charterType in props.vessel.charterType"
                  :key="charterType"
                  class="mx-1 bg-gray-100 text-gray-900 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-white"
                >
                  {{ charterType }}
                </span>
              </div>
              <span v-else>-</span>
            </div>
          </div>

          <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Capacity -->
          <h5 class="text-black dark:text-white font-bold">Capacity</h5>
          <div class="grid @sm:grid-cols-3 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm">
            <p>Sleeps: {{ props.vessel.blueprint.sleeps || '-' }}</p>
            <p>Crew: {{ props.vessel.blueprint.maxCrew || '-' }}</p>
            <p>Cruising Capacity: {{ props.vessel.blueprint.cruisingCapacity || '-' }}</p>
            <p>Static Capacity: {{ props.vessel.blueprint.staticCapacity || '-' }}</p>
          </div>

          <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Design & builder -->
          <h5 class="text-black dark:text-white font-bold">Design & Builder</h5>
          <div class="grid @sm:grid-cols-3 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm">
            <p>Builder: {{ props.vessel.blueprint.make || '-' }}</p>
            <p>Build Year: {{ props.vessel.blueprint.builtYear || '-' }}</p>
            <p>Refit Year: {{ props.vessel.blueprint.refitYear || '-' }}</p>
            <p>Model: {{ props.vessel.blueprint.model || '-' }}</p>
            <p>Architect: {{ props.vessel.blueprint.architect || '-' }}</p>
            <p>Interior Designer: {{ props.vessel.blueprint.interiorDesigner || '-' }}</p>
          </div>

          <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- License & registration -->
          <h5 class="text-black dark:text-white font-bold">License & Registration</h5>
          <div class="grid @sm:grid-cols-3 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm">
            <p>License: {{ props.vessel.blueprint.flag }}</p>
            <p>Registration: {{ props.vessel.blueprint.registryPort || '-' }}</p>
          </div>

          <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Performance -->
          <h5 class="text-black dark:text-white font-bold">Performance</h5>
          <div class="grid @sm:grid-cols-3 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm">
            <p>Top Speed (kn): {{ props.vessel.blueprint.topSpeed || '-' }}</p>
            <p>Cruising Speed (kn): {{ props.vessel.blueprint.cruiseSpeed || '-' }}</p>
            <p>Fuel Capacity (L): {{ props.vessel.blueprint.fuelCapacity || '-' }}</p>
            <p>Engines: {{ props.vessel.blueprint.engines || '-' }}</p>
            <p>Fuel per hour: {{ props.vessel.blueprint.fuelPerHour || '-' }}</p>
            <p>Stabilizers: {{ props.vessel.blueprint.stabilizers || '-' }}</p>
          </div>

          <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Cabin Layout -->
          <h5 class="text-black dark:text-white font-bold">Cabin Layout</h5>
          <div
            v-if="props.vessel.blueprint.cabinLayout?.length"
            class="flex flex-wrap gap-2.5 text-black dark:text-white font-medium text-xs"
          >
            <span
              v-for="(cabin, cabinIndex) of props.vessel.blueprint.cabinLayout"
              class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
              :key="`${cabinIndex}-${cabin.label}`"
            >
              {{ cabin.label }} x {{ cabin.value }}
            </span>
          </div>
          <span v-else class="text-black dark:text-white font-medium text-xs">—</span>

          <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Configuration -->
          <h5 class="text-black dark:text-white font-bold">Configuration</h5>
          <div class="grid @sm:grid-cols-3 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm">
            <p>Total no. of cabins: {{ props.vessel.blueprint.cabins || '-' }}</p>
            <p>Bathrooms: {{ props.vessel.blueprint.bathrooms || '-' }}</p>
            <p>Decks: {{ props.vessel.blueprint.decks || '-' }}</p>
          </div>

          <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Dimensions -->
          <h5 class="text-black dark:text-white font-bold">Dimensions</h5>
          <div class="grid @sm:grid-cols-3 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm">
            <p>Length: {{ `${props.vessel.blueprint.length}m` }}</p>
            <p>Beam: {{ `${props.vessel.blueprint.beam}m` || '-' }}</p>
            <p>Draft: {{ `${props.vessel.blueprint.draft}m` || '-' }}</p>
            <p>Hull Type: {{ props.vessel.blueprint.hullType || '-' }}</p>
            <p>Hull Construction: {{ props.vessel.blueprint.hullConstruction || '-' }}</p>
            <p>Tonnage (gt): {{ props.vessel.blueprint.tonnage || '-' }}</p>
            <p>
              Super Structure:
              {{ props.vessel.blueprint.superStructure?.join(', ') || '-' }}
            </p>
            <p>Main Sail Type: {{ props.vessel.blueprint.mainsailTypes?.[0] || '-' }}</p>
            <p>Genoa Types: {{ props.vessel.blueprint.genoaTypes?.[0] || '-' }}</p>
          </div>

          <!-- Additional info -->
          <template v-if="props.vessel.additionalInfo?.length">
            <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

            <h5 class="text-black dark:text-white font-bold">Additional Information</h5>
            <div class="flex flex-col gap-y-4 divide-y divide-dashed divide-gray-200 dark:divide-gray-600">
              <div
                v-for="(info, index) in props.vessel.additionalInfo"
                class="w-full flex flex-col gap-y-4 items-start"
                :class="{ 'pt-4': index !== 0 }"
                :key="`${index}-${info.label}-${info.value}`"
              >
                <div
                  v-if="info.label || info.value"
                  class="flex flex-col gap-y-2 text-gray-900 dark:text-gray-50 font-medium text-sm"
                >
                  <span v-if="info.label"> Label: {{ info.label }} </span>
                  <span v-if="info.value"> Description: {{ info.value }} </span>
                </div>
              </div>
            </div>
          </template>
        </template>
      </AccordionComponent>
    </div>

    <!-- Vessel crew -->
    <div
      v-if="props.vessel.crew?.length"
      class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <AccordionComponent data-accordion="collapse" id="accordion-crew">
        <template #title>Crew Members</template>
        <template #content>
          <div class="grid @sm:grid-cols-2 @md:grid-cols-3 @lg:grid-cols-4 gap-4">
            <CrewCardComponent
              v-for="(crew, index) of props.vessel.crew"
              :uri="props.vessel.uri"
              :crew-member="crew"
              :index="index"
              :key="`crew-card-${index}-${crew.name}`"
            />
          </div>
        </template>
      </AccordionComponent>
    </div>

    <!-- Vessel variants -->
    <PreviewVariant
      v-if="props.vessel.variants"
      :variants="props.vessel.variants"
      :blueprint="props.vessel.blueprint"
    />

    <ModalContentWrapper v-if="trip">
      <BrochureGeneratedModalContent :trip="trip"></BrochureGeneratedModalContent>
    </ModalContentWrapper>
  </div>
</template>
