import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M14.7516 6.35159C14.9767 6.12662 15.2818 6.00024 15.6 6.00024C15.9182 6.00024 16.2234 6.12662 16.4484 6.35159L21.2484 11.1516C21.4734 11.3766 21.5998 11.6818 21.5998 12C21.5998 12.3182 21.4734 12.6234 21.2484 12.8484L16.4484 17.6484C16.2221 17.867 15.919 17.9879 15.6043 17.9852C15.2897 17.9825 14.9887 17.8563 14.7662 17.6338C14.5438 17.4113 14.4175 17.1103 14.4148 16.7957C14.4121 16.481 14.533 16.1779 14.7516 15.9516L17.5032 13.2H3.60002C3.28176 13.2 2.97654 13.0736 2.7515 12.8485C2.52645 12.6235 2.40002 12.3182 2.40002 12C2.40002 11.6817 2.52645 11.3765 2.7515 11.1515C2.97654 10.9264 3.28176 10.8 3.60002 10.8H17.5032L14.7516 8.04839C14.5267 7.82335 14.4003 7.51818 14.4003 7.19999C14.4003 6.88179 14.5267 6.57662 14.7516 6.35159V6.35159Z",
      "clip-rule": "evenodd"
    })
  ]))
}