<script setup lang="ts">
import { ComponentOptionsBase, ComponentPublicInstance, FunctionalComponent, Ref, onMounted, ref } from 'vue'

type StronglyTypedHtmlButton =
  | Element
  | ComponentPublicInstance<
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      false,
      ComponentOptionsBase<any, any, any, any, any, any, any, any, any, {}, {}, string, {}>,
      {},
      {}
    >
  | null

export type Tab = {
  name: string
  active: boolean
  icon?: FunctionalComponent
}

type Props = {
  tabs: Tab[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'on:click', value: string): void
}>()

const tabRefs: Ref<StronglyTypedHtmlButton[]> = ref([])
const activeTabLine: Ref<{ left: number; width: number } | null> = ref(null)

onMounted(() => {
  const active = props.tabs.findIndex((tab) => tab.active)
  shiftTabLine(tabRefs.value[active])
})

const onTabClick = (event: StronglyTypedHtmlButton, tab: string) => {
  emit('on:click', tab)
  shiftTabLine(event!)
}

const shiftTabLine = (element: StronglyTypedHtmlButton) => {
  const button = element as HTMLElement
  activeTabLine.value = {
    left: button.offsetLeft,
    width: button.clientWidth,
  }
}
</script>
<template>
  <!-- Min height is to prevent the line at the bottom of the tab squishing -->
  <div class="relative flex items-center justify-center h-full min-h-11">
    <!-- Dynamic -->
    <button
      v-for="(tab, index) in props.tabs"
      class="flex items-center gap-x-1 px-2 py-2 sm:py-0 h-full transition-all"
      :key="`${index}:${tab.name}`"
      :ref="(el) => (tabRefs[index] = el)"
      :class="
        tab.active
          ? 'text-primary-600 dark:text-primary-500'
          : 'text-gray-500 hover:text-primary-600 hover:dark:text-primary-500'
      "
      @click.stop="onTabClick(tabRefs[index], tab.name)"
    >
      <component v-if="tab.icon" aria-hidden="true" class="cursor-pointer w-4 h-4" :is="tab.icon" />
      <p class="text-sm">{{ tab.name }}</p>
    </button>

    <!-- Line beneath tabs transition -->
    <div
      v-if="activeTabLine"
      id="tab-line"
      class="pointer-events-none absolute bottom-0 left-0 transition-all duration-500 h-0.5 bg-primary-600 dark:bg-primary-500"
      :style="{ left: `${activeTabLine.left}px`, width: `${activeTabLine.width}px` }"
    ></div>
  </div>
</template>
