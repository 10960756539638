import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M5.99998 3.6C5.36346 3.6 4.75301 3.85285 4.30292 4.30294C3.85283 4.75303 3.59998 5.36348 3.59998 6V8.4C3.59998 9.03652 3.85283 9.64697 4.30292 10.0971C4.75301 10.5471 5.36346 10.8 5.99998 10.8H8.39998C9.03649 10.8 9.64694 10.5471 10.097 10.0971C10.5471 9.64697 10.8 9.03652 10.8 8.4V6C10.8 5.36348 10.5471 4.75303 10.097 4.30294C9.64694 3.85285 9.03649 3.6 8.39998 3.6H5.99998ZM5.99998 13.2C5.36346 13.2 4.75301 13.4529 4.30292 13.9029C3.85283 14.353 3.59998 14.9635 3.59998 15.6V18C3.59998 18.6365 3.85283 19.247 4.30292 19.6971C4.75301 20.1471 5.36346 20.4 5.99998 20.4H8.39998C9.03649 20.4 9.64694 20.1471 10.097 19.6971C10.5471 19.247 10.8 18.6365 10.8 18V15.6C10.8 14.9635 10.5471 14.353 10.097 13.9029C9.64694 13.4529 9.03649 13.2 8.39998 13.2H5.99998ZM13.2 6C13.2 5.36348 13.4528 4.75303 13.9029 4.30294C14.353 3.85285 14.9635 3.6 15.6 3.6H18C18.6365 3.6 19.2469 3.85285 19.697 4.30294C20.1471 4.75303 20.4 5.36348 20.4 6V8.4C20.4 9.03652 20.1471 9.64697 19.697 10.0971C19.2469 10.5471 18.6365 10.8 18 10.8H15.6C14.9635 10.8 14.353 10.5471 13.9029 10.0971C13.4528 9.64697 13.2 9.03652 13.2 8.4V6ZM13.2 15.6C13.2 14.9635 13.4528 14.353 13.9029 13.9029C14.353 13.4529 14.9635 13.2 15.6 13.2H18C18.6365 13.2 19.2469 13.4529 19.697 13.9029C20.1471 14.353 20.4 14.9635 20.4 15.6V18C20.4 18.6365 20.1471 19.247 19.697 19.6971C19.2469 20.1471 18.6365 20.4 18 20.4H15.6C14.9635 20.4 14.353 20.1471 13.9029 19.6971C13.4528 19.247 13.2 18.6365 13.2 18V15.6Z" })
  ]))
}