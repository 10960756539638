<!-- Flowbite dropdown -->
<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { initDropdowns } from 'flowbite'
import { Ref, onMounted, ref } from 'vue'

import { SolidChevronDown } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'

const props = defineProps<{
  options: { label: string; value?: string }[]
  selected?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  validationError?: boolean
  loading?: boolean
}>()

const emit = defineEmits<{
  (e: 'select:item', option: { label: string; value?: string }): void
}>()

const showMenu: Ref<boolean> = ref(false)

const onSelectOption = (option: { label: string; value?: string }): void => {
  showMenu.value = false
  emit('select:item', option)
}

const getStylingBasedOnDisabledOrValidationError = (): string => {
  if (props.disabled)
    return 'bg-gray-200 dark:bg-gray-400 text-gray-400 dark:text-gray-600 border-gray-300 dark:border-gray-500'
  if (props.validationError)
    return 'bg-red-600 bg-opacity-10 text-red-500 dark:text-red-400 border-red-500 dark:border-red-400'
  return 'bg-gray-50 dark:bg-gray-600 text-gray-500 dark:text-gray-400 border-gray-300 dark:border-gray-500'
}

onMounted(() => {
  initDropdowns()
})
</script>
<template>
  <div>
    <p v-if="props.label" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ props.label }}
    </p>
    <div class="relative">
      <OnClickOutside @trigger="showMenu = false">
        <button
          type="button"
          class="border w-full justify-between focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm pl-3 pr-1 py-2.5 text-center inline-flex items-center"
          :class="getStylingBasedOnDisabledOrValidationError()"
          @click.stop="showMenu = !showMenu"
          :disabled="props.disabled"
        >
          <span class="overflow-hidden whitespace-nowrap text-ellipsis block">
            {{ props.selected || props.placeholder || 'Select an option' }}
          </span>
          <span class="w-5">
            <Spinner v-if="props.loading" class="w-4 h-4 text-gray-500 dark:text-gray-400" />
            <SolidChevronDown v-else class="w-5 text-gray-500 dark:text-gray-400" />
          </span>
        </button>

        <!-- Dropdown menu -->
        <div
          v-show="showMenu"
          class="absolute top-12 w-min-full cursor-pointer z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
        >
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200 max-h-60 overflow-auto">
            <li v-for="(option, index) in props.options" :key="`${index}-${option}`">
              <a
                class="whitespace-nowrap block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="onSelectOption(option)"
              >
                {{ option.label }}
              </a>
            </li>
          </ul>
        </div>
      </OnClickOutside>
    </div>
  </div>
</template>
