/**
 * Split the supplied string based on separator,
 *
 * Return the remaining items in the last index if shorter
 *
 * @param str the string to split
 * @param separator the seperator to pass to `Str.split()`
 * @param limit specify the number of parts to split out
 * @returns a split string with the remaining items in the last array index
 */
export const split = (str: string | undefined, separator: string, limit?: number): string[] => {
  if (!str) {
    return []
  }
  if (!separator) {
    return [str]
  }

  if (!limit || limit <= 0) {
    return str.split(separator)
  }

  // split the string; limit truncates remaining.
  const parts = str.split(separator, limit)

  // if the size of what was split is less than the asked limit
  // then there is nothing remaining.
  if (parts.length < limit) {
    return parts
  }

  // lets calculate the length of what was split out (math is better than reassembling the parts)
  const partsLen = parts.reduce(
    (acc, e) => {
      return acc + e.length
    },
    // start with a length that includes # elements and sep lengths
    parts.length * separator.length,
  )

  // include the remaining beyond the last
  return [...parts, str.substring(partsLen)]
}
