export const assetUploaderValidityStyling = (uploadSuccessful: boolean, isFileTypeSupported: boolean) => {
  if (uploadSuccessful) {
    return 'border-green-400'
  }

  if (!isFileTypeSupported) {
    return 'border-red-600 bg-red-100 dark:bg-red-300 hover:bg-red-400 dark:hover:bg-red-200'
  }

  return 'border-gray-300 bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
}
