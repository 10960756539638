import { CalendarEvent } from '@ankor-io/common/vessel/types'
import { Post } from '@ankor-io/feed-endpoint/src/types'

export type Calendar = {
  /**
   * Record of year string and CalendarYear
   */
  table: Record<number, CalendarYear>
}

/**
 * Record of string and Records of string and CalendarBlocks
 */
export type CalendarYear = Record<number, CalendarMonth>

export type CalendarMonth = Record<
  CalendarBlockType,
  Record<string, CalendarBlock | CalendarBlock[] | CalendarWeekBlock[] | CalendarEventBlock[]>
>

export type CalendarBlock = {
  type: CalendarBlockType
}

export enum CalendarBlockType {
  BLOCKS = 'blocks',
  DAY = 'day',
  WEEK = 'week',
}

export type CalendarDayBlock = CalendarBlock & {
  day: number
  dayOfWeek?: number | undefined
  /**
   * dd-MM-yyyy format
   */
  date: string
  isCurrentMonth: boolean
}

export interface CalendarEventBlock extends CalendarBlock, CalendarEvent {
  position: EventPosition
}

export interface CalendarSpecialBlock extends CalendarBlock, Post {
  position: EventPosition
}

export interface CalendarMixedBlock extends CalendarSpecialBlock, CalendarEventBlock {}

export type CalendarPrioritizedBlock = CalendarMixedBlock & {
  week: number
  days: string[]
}

export type CalendarWeekBlock = CalendarBlock & {
  days: CalendarDayBlock[]
}

export type EventPosition = {
  /**
   * The date this event is positioned to
   */
  startDate: string
  middleDates: string[]
  spanningDates: string[]
  endDate: string
  /**
   * The event span type
   */
  span?: EventSpanType
  /**
   * The order of this event within the cell
   */
  priority: number
}

/**
 * Describes the event span type within a single cell
 */
export enum EventSpanType {
  /**
   * The event starts in the cell
   */
  START,
  /**
   * The event starts and ends in this cell
   */
  START_END,
  /**
   * The event started in a previous cell and continues in this cell
   */
  MIDDLE,
  /**
   * The event started in a previous cell and ends in this cell
   */
  END,
}

export type CalendarDay = {
  /**
   * The day number of the month
   * Eg: 1-31
   * */
  day: number
  /**
   * The dd-MM-yyyy date
   * Eg: 01-01-2023
   */
  date: string
  /**
   * The day of the week as number
   * Define for the month showing calendar for, not the the dates before
   * Eg: 0 === Monday, 3 === Thursday, 6 === Sunday
   */
  dayOfWeek?: number
  /**
   * If the date is in the current month showing
   * Eg: In a calendar view, the days filled before and after are falsy for this value
   */
  isCurrentMonth: boolean
}

export type DateValue = {
  year: number
  month: number
  week: number
}

export enum CalendarViewEnum {
  YEAR = 'Year',
  MONTH = 'Month',
  WEEK = 'Week',
}

export type CalendarDateCell = {
  /**
   * The day number of the month
   * Eg: 1-31
   * */
  day: number
  /**
   * The dd-MM-yyyy date
   * Eg: 01-01-2023
   */
  date: string
  /**
   * The day of the week as number
   * Define for the month showing calendar for, not the the dates before
   * Eg: 0 === Monday, 3 === Thursday, 6 === Sunday
   */
  dayOfWeek?: number
  /**
   * If the date is in the current month showing
   * Eg: In a calendar view, the days filled before and after are falsy for this value
   */
  isCurrentMonth: boolean
}

export type CalendarView = CalendarViewEnum.YEAR | CalendarViewEnum.MONTH | CalendarViewEnum.WEEK
