import { DateTime } from 'luxon'

import { fromIntervalString } from '@ankor-io/common/date/ISO8601'

/**
 * Checks to see if an interval is a special
 * @param eventInterval The posts interval of luxon Interval
 * @param date Comparison DateTime
 */
export const isSpecialActive = (eventInterval: string, date: DateTime) => {
  if (eventInterval) {
    const { end } = fromIntervalString(eventInterval)
    if (end! >= date) {
      return true
    }
  }

  return false
}

/**
 * Checks to see if an interval is expired
 * @param eventInterval The posts interval of luxon Interval
 * @param date Comparison DateTime
 */
export const isSpecialExpired = (eventInterval: string, date: DateTime) => {
  if (eventInterval) {
    const { end } = fromIntervalString(eventInterval)
    if (end! < date) {
      return true
    }
  }

  return false
}

/**
 * To show the special for that month cell
 * @param cellDate The month cell (dd-MM-yyyy)
 * @param eventInterval The post interval time
 */
export const showSpecial = (cellDate: string, eventInterval: string) => {
  if (eventInterval) {
    const dateTime = DateTime.now()
    const splitDate = cellDate.split('-')
    const date = DateTime.fromISO(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`)
    const { start, end } = fromIntervalString(eventInterval)
    if (
      (isSpecialActive(eventInterval, dateTime) || isSpecialExpired(eventInterval, dateTime)) &&
      date >= start! &&
      date <= end!
    ) {
      return true
    }
  }

  return false
}

/**
 * Styles the cell according to it being an active special, expired special or not special
 * @param isCurrentMonth Is the date on the current calendar month
 * @param cellDate The month cell (dd-MM-yyyy)
 * @param eventInterval The post interval time
 */
export const styleCalendarCell = (isCurrentMonth: boolean, cellDate: string, eventInterval: string) => {
  if (eventInterval) {
    const dateTime = DateTime.now()
    const splitDate = cellDate.split('-')
    const date = DateTime.fromISO(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`)
    const { start, end } = fromIntervalString(eventInterval)
    if (end! >= dateTime && date >= start! && date <= end!) {
      // Active Special
      return isCurrentMonth
        ? 'bg-purple-200 dark:bg-purple-900 text-gray-900 dark:text-white hover:bg-purple-300 hover:dark:bg-purple-800'
        : 'bg-purple-100 dark:bg-purple-950 text-gray-400 dark:text-gray-200 hover:bg-purple-50 hover:dark:bg-purple-1000'
    } else if (end! < dateTime && date >= start! && date <= end!) {
      // Expired Special
      return isCurrentMonth
        ? 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-300 hover:dark:bg-gray-600'
        : 'bg-gray-50 dark:bg-gray-800 text-gray-400 dark:text-gray-200 hover:bg-white hover:dark:bg-gray-900'
    }
  }

  return
}
