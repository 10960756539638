<script setup lang="ts">
import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { formatLengthWithSpaceSeparator } from '@ankor-io/common/vessel/Formatter'
import { Variant } from '@ankor-io/common/vessel/types'
import { LineArtMeasuringtape } from '@ankor-io/icons/line_art'
import { OutlineCabinDoorIcon, OutlineCalendarThin, OutlineWrenchScrewdriver } from '@ankor-io/icons/outline'

import PricingInCard from './PricingInCard.vue'

export type Props = {
  /**
   * The uri of the entity the asset belongs to
   */
  uri: string
  /**
   * The image source
   */
  hero?: string
  /**
   * If the vessel is visible
   */
  isInactive?: boolean
  /**
   * The name of the vessel
   */
  line_1?: string
  /**
   * The length of the vessel
   */
  line_2?: string
  /**
   * The number of cabins
   */
  line_3?: string | string[]
  /**
   * The number of passengers that can sleep aboard
   */
  line_4?: string | string[]
  /**
   * The built year of the vessel
   */
  line_5?: string | string[]
  /**
   * The builder/make of the vessel
   */
  line_6?: string | string[]
  /**
   * Managed by company
   */
  line_7?: string[]
  /**
   * The variants of a vessel
   */
  variants?: Variant[]
  /**
   * The currency rates (live)
   */
  rates?: { [key: string]: string }
  /**
   * The type of card
   */
  cardType: 'view' | 'claim' | 'edit'
}

const props = defineProps<Props>()

defineEmits<{
  (e: 'vessel:edit'): void
  (e: 'vessel:claim'): void
}>()

const getStringLabel = (attribute: string | string[]): string => {
  if (Array.isArray(attribute)) {
    return attribute.join(', ')
  }
  return attribute
}

/**
 * Filter out variants that are invalid for pricing
 * @param variants
 */
const getValidVariants = (variants: Variant[]) => {
  return variants.filter(
    (variant: Variant) =>
      variant.active && variant.pricing?.total && variant.pricing.total > 0 && variant.pricing?.currency,
  )
}

/**
 * Format the managed by company string
 * @param companyNames
 */
const formatManagedBy = (companyNames: string[] = []) => {
  if (companyNames.length === 0) {
    return ''
  }

  if (companyNames.length === 1) {
    return `${companyNames[0]}`
  }

  return `${companyNames.slice(0, -1).join(', ')} and ${companyNames.slice(-1)}`
}
</script>
<template>
  <div
    class="h-full flex flex-col justify-between bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-auto w-full"
  >
    <div class="flex flex-col">
      <div class="h-48">
        <AssetViewer
          v-if="props.hero"
          class="rounded-t-lg object-cover"
          :url="`/media/${replacePathToMediaUris(props.uri, props.hero)[0]}`"
          :width-descriptors="['320w', '640w']"
        />
      </div>

      <div class="p-4 flex gap-y-2 flex-col flex-grow">
        <div class="flex flex-col">
          <div class="flex items-center gap-x-2">
            <!-- Name -->
            <h5
              v-if="props.line_1"
              class="uppercase text-2xl font-bold tracking-tight line-clamp-1 text-gray-900 dark:text-white"
            >
              {{ props.line_1 }}
            </h5>
            <!-- The requirements is to only show the badge for inactive vessels -->
            <span
              v-if="props.isInactive"
              class="h-fit text-xs font-medium px-2.5 py-0.5 rounded bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300"
            >
              Inactive
            </span>
          </div>

          <!-- Managed by -->
          <div v-if="props.line_7" class="line-clamp-1 font-normal text-gray-500 dark:text-gray-400">
            {{ formatManagedBy(props.line_7) }}
          </div>
        </div>

        <!-- Details -->
        <div
          v-if="props.line_2 || props.line_3 || props.line_4 || props.line_5 || props.line_6"
          class="flex flex-wrap gap-2 w-full font-normal text-gray-700 dark:text-gray-400 stroke-gray-500 dark:stroke-gray-400"
        >
          <!-- Length -->
          <p v-if="props.line_2" class="flex justify-start gap-x-2 items-center">
            <LineArtMeasuringtape class="h-5 w-5 shrink-0" /> {{ formatLengthWithSpaceSeparator(props.line_2!) }}
          </p>

          <!-- Built year -->
          <p v-if="props.line_5" class="flex justify-start gap-x-2 items-center">
            <OutlineCalendarThin class="h-5 w-5 shrink-0" /> {{ getStringLabel(props.line_5) }}
          </p>

          <!-- Cabins -->
          <p v-if="props.line_3?.length" class="flex justify-start gap-x-2 items-center">
            <OutlineCabinDoorIcon class="h-5 w-5 shrink-0" /> {{ getStringLabel(props.line_3) }}
          </p>

          <!-- Builder -->
          <p v-if="props.line_6?.length" class="flex justify-start gap-x-2 items-center">
            <OutlineWrenchScrewdriver class="h-5 w-5 shrink-0" />
            <span class="line-clamp-1">{{ getStringLabel(props.line_6) }} </span>
          </p>
        </div>

        <!-- Pricing -->
        <PricingInCard
          v-if="props.variants"
          :variants="getValidVariants(props.variants)"
          :rates="props.rates!"
          :isTimeline="false"
        />
      </div>
    </div>
    <div v-if="props.cardType === 'claim' || props.cardType === 'edit'" class="flex px-4 pb-4">
      <a
        v-if="props.cardType === 'claim'"
        class="w-fit inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 hover:cursor-pointer"
        @click="$emit('vessel:claim')"
      >
        Claim
      </a>
      <a
        v-else-if="props.cardType === 'edit'"
        class="w-fit inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 hover:cursor-pointer"
        @click="$emit('vessel:edit')"
      >
        Edit
      </a>
    </div>
  </div>
</template>
