import { inject } from 'vue'

import { Company } from '@ankor-io/iam/types'

import { AuthenticationContext } from '@/iam/types'

// inject the authentication context required to get the token
let authenticationContext: AuthenticationContext
export const getCompany = async (): Promise<Company | null> => {
  const token = await authenticationContext.getToken()
  const res: Response = await fetch('/api/account/me/company', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.status === 200) {
    const company: Company = await res.json()
    return Promise.resolve(company)
  }

  return Promise.resolve(null)
}

/**
 * Stowage service definition
 */
export type AccountService = {
  /**
   * Get the {@link Company} the authenticated user belongs to
   *
   * @returns a promise with either the company or null when not found
   */
  getCompany: () => Promise<Company | null>
}

/**
 * This is required since inject only works in vue components
 */
export const useAccountService = (): AccountService => {
  authenticationContext = inject('authenticationContext')!
  return {
    getCompany: getCompany,
  } as AccountService
}
