<script setup lang="ts">
import { Ref, onMounted, onUnmounted, onUpdated, ref } from 'vue'

import { CrewMember } from '@ankor-io/common/vessel/types'
import { SolidUser } from '@ankor-io/icons/solid'

import AssetViewer from '../AssetViewer/AssetViewer.vue'

type Props = {
  /**
   * The uri of the entity the asset belongs to
   */
  uri: string
  /**
   * The crew member object
   */
  crewMember: CrewMember
  /**
   * The index of the crew array
   */
  index: number
}

const props = defineProps<Props>()

const NUM_LINES_TO_CLAMP = 8
const bioRef: Ref<any> = ref(null)

const showClampButton: Ref<boolean> = ref(true)
const isClamped: Ref<boolean> = ref(true)

onMounted(() => {
  checkBioClampable()
  window.addEventListener('resize', checkBioClampable)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkBioClampable)
})

const checkBioClampable = () => {
  if (!props.crewMember.bio) {
    showClampButton.value = false
    return
  }

  const height = bioRef.value?.scrollHeight!
  const lineHeight = Number(bioRef.value?.style.lineHeight.replace('px', ''))!
  const lines = height / lineHeight
  if (lines > NUM_LINES_TO_CLAMP) {
    showClampButton.value = true
  } else {
    showClampButton.value = false
  }
}

onUpdated(() => {
  checkBioClampable()
})
</script>
<template>
  <div
    class="flex flex-col bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 w-full mx-auto"
  >
    <div class="h-72">
      <AssetViewer
        v-if="props.crewMember.avatar"
        data-test="crew-avatar"
        class="rounded-t-lg object-cover"
        :uri="props.uri"
        :url="props.crewMember.avatar"
      />
      <SolidUser v-else class="rounded-t-lg h-full w-full bg-primary-100 fill-primary-400" />
    </div>

    <div class="p-5 flex gap-y-2 flex-col">
      <h1 v-if="props.crewMember.name" class="text-2xl font-extrabold text-primary-600 dark:text-primary-700">
        {{ props.crewMember.name }}
      </h1>
      <h3 v-if="props.crewMember.roles?.length" class="font-extrabold text-gray-900 dark:text-white">
        {{ props.crewMember.roles.join(', ') }}
      </h3>
      <p
        v-show="props.crewMember.bio"
        class="text-gray-500 dark:text-gray-400 break-words"
        style="line-height: 24px"
        ref="bioRef"
        :id="`crew-bio-${props.index}`"
        :class="isClamped ? 'line-clamp-[8]' : 'line-clamp-none'"
      >
        {{ props.crewMember.bio }}
      </p>
      <span v-if="showClampButton" class="cursor-pointer text-primary-600" @click="isClamped = !isClamped">
        {{ isClamped ? 'Show more' : 'Show less' }}
      </span>
    </div>
  </div>
</template>
