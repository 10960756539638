<script setup lang="ts">
import debounce from 'lodash.debounce'

import { OutlineSearch } from '@ankor-io/icons/outline'

import AlgoliaCheckbox from './Checkbox.vue'

type Props = {
  items: any
  isShowingMore: any
  isFromSearch: any
  canToggleShowMore: any
  refine: any
  createURL: any
  toggleShowMore: any
  searchForItems: any
  placeholder?: string
}

const props = withDefaults(defineProps<Props>(), { placeholder: '' })

// Pass in from the search box, the ais slot function refine and value value for the sole purpose of debouncing the search
const debounceRefiner = debounce((refine: any, value: any) => {
  refine(value)
}, 400)
</script>
<template>
  <div class="relative">
    <div class="absolute inset-y-0 left-3 flex items-center pointer-events-none">
      <OutlineSearch class="w-3.5 h-3.5 text-primary-600" />
    </div>
    <input
      type="text"
      id="input-group-1"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 py-2.5 pr-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      :placeholder="props.placeholder"
      @input="debounceRefiner(props.searchForItems, ($event as any).currentTarget.value)"
    />
  </div>
  <p v-if="props.isFromSearch && !props.items.length" class="text-xs">No results.</p>
  <ul v-else class="flex flex-col gap-2">
    <li v-for="item in props.items" :key="item.value">
      <AlgoliaCheckbox :item="item" :refine="props.refine" :create-url="props.createURL" />
    </li>
  </ul>
  <button
    v-if="props.canToggleShowMore"
    class="text-xs text-blue-600 dark:text-blue-400 text-left"
    :disabled="!props.canToggleShowMore"
    @click="props.toggleShowMore"
  >
    {{ !props.isShowingMore ? 'Show more' : 'Show less' }}
  </button>
</template>
