import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M14.1084 2.44076C14.4408 2.52836 14.7192 2.75514 14.874 3.06354C15.4536 4.22394 15.8868 4.92838 16.3224 5.49958C16.764 6.07798 17.2296 6.55075 17.94 7.25995C19.5792 8.90035 20.4 11.052 20.4 13.2C20.4 15.348 19.5792 17.4996 17.94 19.14C14.6592 22.4196 9.34078 22.4196 6.05998 19.14C4.42078 17.4996 3.59998 15.348 3.59998 13.2C3.59998 11.052 4.42078 8.90035 6.05998 7.25995C6.40318 6.91675 6.91917 6.81475 7.36797 7.00075C7.81677 7.18555 8.10838 7.62359 8.10838 8.10839C8.10838 9.45239 8.19237 10.476 8.58597 11.2932C8.80197 11.742 9.13918 12.1812 9.71998 12.5736C9.85918 11.3004 10.1136 9.74878 10.4568 8.27758C10.7268 7.11838 11.0628 5.96157 11.4636 4.99677C11.664 4.51317 11.8908 4.05474 12.1476 3.65754C12.3972 3.27114 12.7188 2.8788 13.134 2.6016C13.4208 2.4108 13.776 2.35196 14.1084 2.44076ZM14.5452 18.1451C13.14 19.5515 10.86 19.5515 9.45477 18.1451C8.75157 17.4431 8.39998 16.5216 8.39998 15.6C8.39998 15.6 9.45478 16.2 11.4 16.2C11.4 15 12 11.4 12.9 10.8C13.5 12 13.8432 12.3516 14.5452 13.0548C15.2484 13.7568 15.6 14.6784 15.6 15.6C15.6 16.5216 15.2484 17.4431 14.5452 18.1451Z",
      "clip-rule": "evenodd"
    })
  ]))
}