<script setup lang="ts">
import * as turf from '@turf/turf'
import { useWindowSize } from '@vueuse/core'
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch/lite'
import { initDropdowns } from 'flowbite'
import debounce from 'lodash.debounce'
import { Ref, inject, onBeforeMount, onMounted, provide, ref, watch } from 'vue'

import DateRangePicker from '@ankor-io/blocks/components/DateRangePicker/DateRangePicker.vue'
import ProgressBarWithLabelOutside from '@ankor-io/blocks/components/ProgressBar/ProgressBarWithLabelOutside.vue'
import PostsEmptyCreate from '@ankor-io/blocks/components/feed/PostsEmptyCreate.vue'
import SearchEmpty from '@ankor-io/blocks/components/feed/SearchEmpty.vue'
import GeoPlaceSearchInput from '@ankor-io/commodore/src/components/GeoPlaceSearchInput.vue'
import { getEndDate, getStartDate, updateInterval } from '@ankor-io/common/date/helper'
import { VesselIndexItem } from '@ankor-io/common/index/VesselIndexItem'
import { Place as AnkorPlace } from '@ankor-io/common/itinerary/Itinerary'
import { formatLength } from '@ankor-io/common/vessel/Formatter'
import { Geo, GeoFilter, Place } from '@ankor-io/common/vessel/types'
import { Post } from '@ankor-io/feed-endpoint/src/types'
import { Company } from '@ankor-io/iam/types'
import { HiOutlineMagnifyingGlass } from '@ankor-io/icons/hi_outline'
import { OutlineBarsArrowLeft, OutlineBarsArrowRight, OutlineRefresh } from '@ankor-io/icons/outline'
import { SolidCheckCircle, SolidX } from '@ankor-io/icons/solid'
import { Config, ConfigKey } from '@ankor-io/radar/src/config/types'
import { AuthenticationContext } from '@ankor-io/radar/src/iam/types'

import FeedHits from '@/components/feed/FeedHits.vue'
import DeleteConfirmation, { DeleteConfirmationModalData } from '@/components/modal-content/DeleteConfirmation.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import PostModalSpecial from '@/components/modal-content/feed/PostModalSpecial.vue'
import SimpleToast from '@/components/toast/SimpleToast.vue'
import { useFacetPanel } from '@/facet-panel/useFacetPanel'
import { useModal } from '@/hooks/modal/useModal'

const facetPanelState = useFacetPanel()
const { isOpen, updateModalState } = useModal()

// use window width to determine if the facet panel should be open on default / mobile vs desktop
const { width: windowWidth } = useWindowSize()
const MOBILE_BREAKPOINT = 768
const isMobile = windowWidth.value <= MOBILE_BREAKPOINT

const company: Ref<Company | null> = ref(null)
const existingPost: Ref<Post | null> = ref(null)
const vesselList: Ref<{ name: string; uri: string }[]> = ref([])
const locationSearchResults: Ref<Place[]> = ref([])
const selectedModalType: Ref<'Post' | 'Delete' | null> = ref(null)
const deleteConfirmationModalData: Ref<DeleteConfirmationModalData | null> = ref(null)
const postSearchText: Ref<string> = ref('')
const eventInterval: Ref<string> = ref('')
const postedByText: Ref<string> = ref('')

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const searchClient: SearchClient = inject('searchClient') as SearchClient
const commodoreIndex: SearchIndex = searchClient.initIndex('commodore')
const facetFilters = ref('[["line_3:special"]]')
const loadingGeoData: Ref<boolean> = ref(false)
const geoPolygons: Ref<number[][]> = ref([])
const geoFilters: Ref<GeoFilter[]> = ref([])
const showResults: Ref<boolean> = ref(false)

onBeforeMount((): void => {
  const config: Config = inject(ConfigKey)!
  const geoSearchClient = algoliasearch(config.ALGOLIA.app_id, config.ALGOLIA.search_key)
  provide('geoSearchClient', geoSearchClient)
})

const isConfirmLoading: Ref<boolean> = ref(false)

const PROGRESS_MAX = 100
/** Progress bar percentage */
const progress: Ref<number> = ref(0)

/** 10 seconds * 10 * 2 - done to smoothen out progress bar */
const TIMER = 200
const postTimer: Ref<number> = ref(0)
const postCompleted: Ref<boolean> = ref(false)
const cudText: Ref<'created' | 'edited' | 'deleted' | 'failed' | null> = ref(null)

onMounted(async () => {
  initDropdowns()
  if (!isMobile) {
    facetPanelState.updateFacetPanelState(true)
  }

  vesselList.value = await getListOfVessels()
  company.value = await getCompany()
})

watch(isOpen, (value) => {
  if (!value) {
    selectedModalType.value = null
  }
})

/**
 * fetch list of vessels from the index
 * @returns list of vessels
 */
const getListOfVessels = async () => {
  const vessels = await commodoreIndex
    .search('', { hitsPerPage: 300 })
    .then(({ hits }) =>
      (hits as VesselIndexItem[]).map((hit) => {
        return { name: `${hit.line_1} (${formatLength(hit.line_2!)})`, uri: hit.uri }
      }),
    )
    .catch((err) => {
      console.error(err)
      return []
    })

  return vessels?.length > 0 ? vessels : []
}

/**
 * fetch company name from the server
 * @returns company
 */
const getCompany = async () => {
  const company: Company | null = await fetch('/api/account/me/company', {
    method: 'GET',
    headers: {
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })
    .then((res) => res.json() as Promise<Company>)
    .catch((err) => {
      console.error(err)
      return null as unknown as Promise<null>
    })

  return company
}

/**
 * Sets the beginning of the timer of when a post would have been indexed
 */
const startTimer = (): void => {
  progress.value = 0
  postTimer.value = TIMER
  postCompleted.value = false
}

/**
 * Timer ticks down every second while the timer is not at 0 seconds
 */
const tickTimer = (duration: number) => {
  if (duration > 0) {
    // The smaller the timer, the smoother the progress bar
    setTimeout(() => tickTimer(duration - 1), 50)
    progress.value += PROGRESS_MAX / TIMER
    postTimer.value -= 1
    if (postTimer.value === 0) {
      postCompleted.value = true
    }
  }
}

/**
 * create a new post
 * @param event
 */
const createPost = async (event: { post: Post; userName: string }) => {
  isConfirmLoading.value = true
  const response = await fetch('/api/feed/post', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify({ post: event.post, userName: event.userName }),
  })

  if (response.status === 200) {
    startTimer()
    cudText.value = 'created'
    tickTimer(postTimer.value)
  } else {
    cudText.value = 'failed'
  }

  closeModal()
  isConfirmLoading.value = false
}

/**
 * update an existing post
 * @param event
 */
const updatePost = async (event: { postUri: string; post: Post; userName: string }) => {
  isConfirmLoading.value = true
  const response = await fetch(`/api/feed/post/${event.postUri}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify({ post: event.post, userName: event.userName }),
  })

  if (response.status === 200) {
    startTimer()
    cudText.value = 'edited'
    tickTimer(postTimer.value)
  } else {
    cudText.value = 'failed'
  }

  closeModal()
  isConfirmLoading.value = false
}

/**
 * delete an existing post
 * @param uri
 */
const deletePost = async (uri: string) => {
  isConfirmLoading.value = true
  const response = await fetch(`/api/feed/post/${uri}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    startTimer()
    cudText.value = 'deleted'
    tickTimer(postTimer.value)
  } else {
    cudText.value = 'failed'
  }

  closeModal()
  isConfirmLoading.value = false
}

const openDeleteConfirmation = (uri: string) => {
  selectedModalType.value = 'Delete'
  deleteConfirmationModalData.value = {
    id: uri,
    message: 'Are you sure you want to delete this post? This cannot be undone.',
    labelCancel: 'No, cancel',
    labelConfirm: 'Yes, delete it',
  }
  updateModalState(true)
}

/**
 * fetch places from the server
 * @param searchText text to search for
 * @returns list of places
 */
const fetchPlaces = async (searchText: string): Promise<Place[]> => {
  if (!searchText) {
    return Promise.resolve([])
  }

  const response = await fetch('/api/geocode/forward', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify({ searchText }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error('Error fetching places', err)
      return Promise.resolve([])
    })

  return (response as { places: Place[] }).places
}

/**
 * open post special modal
 * @param post
 */
const openPostModal = (post: Post | null) => {
  existingPost.value = post
  selectedModalType.value = 'Post'
}

/**
 * close modal
 */
const closeModal = () => {
  existingPost.value = null
  selectedModalType.value = null
}

const fetchPlacesDebounced = debounce(async (searchText: string) => {
  if (!searchText) {
    locationSearchResults.value = []
    return
  }
  locationSearchResults.value = await fetchPlaces(searchText)
}, 400)

const clearFilters = () => {
  postSearchText.value = ''
  eventInterval.value = ''
  geoFilters.value = []
  geoPolygons.value = []
  postedByText.value = ''
}

/**
 * Update the tags for the geoFilters and query stowage for the new tag
 * @param geoFiltersEvent The geo filters tags on the autocomplete component
 */
const onGeoFilterChange = async (geoFiltersEvent: GeoFilter[]): Promise<void> => {
  geoFilters.value = geoFiltersEvent
  showResults.value = false
  const geoFiltersForPlace = geoFiltersEvent.filter((tag) => tag.uri.includes('place'))
  const geoFiltersForGeo = geoFiltersEvent.filter((tag) => !tag.uri.includes('place'))
  if (geoFiltersForPlace.length > 0) {
    await loadPlaceData(geoFiltersForPlace[geoFiltersForPlace.length - 1].uri)
  }
  if (geoFiltersForGeo.length > 0) {
    await loadGeoData(geoFiltersForGeo[geoFiltersForGeo.length - 1].uri)
  }
  showResults.value = true
}

/**
 * Convert the geo JSON to a number[][] that is compatible with algolia
 * @param geoJson
 */
const convertGeoJsonToPolygons = (geoJson: any) => {
  if (geoJson.type === 'FeatureCollection') {
    const poly = turf.polygon(geoJson.features[0].geometry.coordinates)
    const coords = turf.getCoords(poly)
    // geoJson has coordinates in [longitude, latitude] but algolia expects [latitude, longitude]
    const reversedPolygons = coords.map((coord) => coord.flat(1).reverse())
    return [...geoPolygons.value, reversedPolygons]
  } else {
    if (geoJson.geometry.type === 'Polygon') {
      const poly = turf.polygon(geoJson.geometry.coordinates)
      const coords = turf.getCoords(poly)
      // geoJson has coordinates in [longitude, latitude] but algolia expects [latitude, longitude]
      const reversedPolygons = coords.map((coord) => coord.flat(1).reverse())
      return [...geoPolygons.value, reversedPolygons]
    } else {
      const poly = turf.multiPolygon(geoJson.geometry.coordinates)
      const coords = turf.getCoords(poly)
      // geoJson has coordinates in [longitude, latitude] but algolia expects [latitude, longitude]
      const reversedPolygons = coords.flat(1).map((coord) => coord.flat(1).reverse())
      return [...geoPolygons.value, reversedPolygons]
    }
  }
}

/**
 * Removes a tag from the geoFilters and the geoPolygons - ais-configure is reactive to the change
 * @param geoUri The geo uri
 *
 */
const removeGeoTag = (geoUri: string): void => {
  const index = geoFilters.value.findIndex((tag) => tag.uri === geoUri)
  geoFilters.value.splice(index, 1)
  geoPolygons.value.splice(index, 1)

  onGeoFilterChange(geoFilters.value)
}

/**
 * Convert the coordinates to a polygon
 *
 * @param place
 */
const convertPlaceToPolygon = (place: AnkorPlace) => {
  const center = [place.location.coordinates.longitude, place.location.coordinates.latitude]
  const radius = 50 // FIXME: Check what the radius should be
  const options = {}
  const circle = turf.circle(center, radius, options)

  const poly = turf.multiPolygon([circle.geometry.coordinates])
  const coords = turf.getCoords(poly)
  const reversedPolygons = coords.flat(1).map((coord) => coord.flat(1).reverse())
  return [...geoPolygons.value, reversedPolygons]
}

/**
 * Gets the geodata from stowage with the geo uri then stores the polygon data in geoPolygons, passed into ais-configure
 * @param geoUri The geo uri
 */
const loadGeoData = async (geoUri: string) => {
  loadingGeoData.value = true

  const response = await fetch(`/api/vessel/${decodeURIComponent(geoUri)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    const resp = await response.json()
    geoPolygons.value = convertGeoJsonToPolygons((resp as Geo).geojson)
  }

  loadingGeoData.value = false
}

const loadPlaceData = async (placeUri: string) => {
  loadingGeoData.value = true

  const response = await fetch(`/api/place/${decodeURIComponent(placeUri)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    const place: AnkorPlace = await response.json()
    geoPolygons.value = convertPlaceToPolygon(place)
  }

  loadingGeoData.value = false
}
</script>
<template>
  <div
    class="relative flex flex-col sm:flex-row sm:justify-center gap-2 sm:gap-4 h-[calc(100vh-3.75rem)] overflow-hidden"
  >
    <div class="sm:basis-[17rem] px-4 sm:px-0 flex flex-col gap-y-2 sm:gap-y-4">
      <!-- heading -->
      <h4>Specials</h4>

      <!-- search-box -->
      <div class="flex w-full">
        <button
          v-show="true"
          id="show-filter-button"
          class="flex-shrink-0 inline-flex items-center py-2 px-5 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-0 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white dark:border-gray-600"
          type="button"
          @click="facetPanelState.updateFacetPanelState(!facetPanelState.isFacetPanelOpen.value)"
        >
          <OutlineBarsArrowLeft
            v-if="facetPanelState.isFacetPanelOpen.value"
            class="w-6 h-6 shrink-0 stroke-gray-500 dark:stroke-gray-400"
          />
          <OutlineBarsArrowRight v-else class="w-6 h-6 shrink-0 stroke-gray-500 dark:stroke-gray-400" />
        </button>

        <div class="relative w-full">
          <input
            type="search"
            id="search-posts"
            class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
            placeholder="Search keyword..."
            autocomplete="off"
            v-model="postSearchText"
          />
          <button
            class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <HiOutlineMagnifyingGlass class="w-4 h-4 stroke-white stroke-2" />
            <span class="sr-only">Search</span>
          </button>
        </div>
      </div>

      <!-- filters -->
      <div :class="facetPanelState.isFacetPanelOpen.value ? 'z-40 md:flex md:relative absolute inset-0' : ''">
        <div
          class="z-50 absolute top-4 right-4 cursor-pointer p-1 bg-gray-50 dark:bg-gray-900"
          :class="facetPanelState.isFacetPanelOpen.value ? 'flex md:hidden' : 'hidden'"
          @click="facetPanelState.updateFacetPanelState(false)"
        >
          <SolidX class="w-4 h-4 text-gray-900 dark:text-white" />
        </div>
        <div
          tabindex="-1"
          class="scrollbar-hide filters-panel transition-[opacity,width] duration-300 ease-in-out text-gray-900 dark:text-white whitespace-nowrap flex flex-col gap-y-4"
          :class="
            facetPanelState.isFacetPanelOpen.value
              ? 'px-4 py-12 md:px-0 md:py-0 w-full h-full bg-gray-50 dark:bg-gray-900 opacity-100'
              : 'h-0 min-w-0 w-0 md:max-w-full opacity-0 overflow-hidden'
          "
        >
          <!-- Clear Filter -->
          <div class="flex gap-x-4 items-center justify-between">
            <h6>Filters</h6>
            <div class="self-end flex items-center gap-x-1 cursor-pointer text-xs" @click.stop="clearFilters">
              <OutlineRefresh class="w-4 h-4 text-primary-600" />
              <span class="text-sm text-primary-600">Clear filters</span>
            </div>
          </div>

          <!-- Charter Period -->
          <div>
            <label class="block mb-2" for="startDate">Charter period</label>
            <DateRangePicker
              end-date-placeholder="End date"
              start-date-placeholder="Start date"
              :showToText="false"
              :end-date="getEndDate(eventInterval)"
              :start-date="getStartDate(eventInterval)"
              @update:start:and:end="eventInterval = updateInterval($event)!"
            />
          </div>

          <!-- location -->
          <div>
            <label class="block mb-2" for="location">Location</label>
            <GeoPlaceSearchInput
              operation-type="union"
              :geo-tags="geoFilters || []"
              :loading="loadingGeoData"
              @update:geo="(value:GeoFilter[]) => onGeoFilterChange(value)"
              @remove:geo="(value:string) => removeGeoTag(value)"
            />
          </div>

          <!-- Posted By -->
          <div>
            <label class="block mb-2" for="postedBy">Posted by</label>
            <div class="relative">
              <!-- postedByText input -->
              <div class="relative h-fit">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <HiOutlineMagnifyingGlass class="w-4 stroke-primary-600 dark:stroke-white" />
                </div>
                <input
                  type="text"
                  id="postedBy"
                  autocomplete="off"
                  class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-10 py-2.5 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 dark:border-gray-600 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-700"
                  placeholder="Search for a contributor"
                  v-model="postedByText"
                />
                <div
                  v-if="postedByText"
                  class="absolute inset-y-0 right-0 flex items-center pr-3.5 cursor-pointer"
                  @click="postedByText = ''"
                >
                  <SolidX class="w-4 text-gray-500 dark:text-gray-400 cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sm:flex-1 flex items-center flex-col gap-y-4">
      <!-- Open Post Creation Modal -->
      <div class="w-full flex justify-center px-4 sm:px-0">
        <div
          class="w-full max-w-2xl border cursor-pointer select-none text-sm rounded-lg p-2.5 border-gray-200 dark:border-gray-600 text-gray-400 bg-white dark:bg-gray-800"
          @click.stop="openPostModal(null)"
        >
          Write a post here...
        </div>
      </div>

      <!-- separator -->
      <hr class="hidden sm:block w-full max-w-2xl border-t border-gray-200 dark:border-gray-600" />

      <ProgressBarWithLabelOutside
        v-if="!isConfirmLoading && postTimer && !postCompleted"
        class="max-w-2xl px-4 sm:px-0"
        :progress="progress"
        :left-label="`${
          cudText === 'created' ? 'Uploading' : cudText === 'edited' ? 'Updating' : 'Deleting'
        } your post`"
      />

      <!-- FeedHits -->
      <div
        class="h-[calc(100vh-14.5rem)] sm:h-[calc(100vh-9.6rem)] w-full overflow-y-auto overflow-hidden flex justify-center"
      >
        <FeedHits
          class="w-full max-w-2xl sm:ml-4"
          :facetFilters="facetFilters"
          :postSearchText="postSearchText"
          :eventInterval="eventInterval"
          :geoPolygons="geoPolygons"
          :postedBy="postedByText"
          @open:post:modal="openPostModal"
          @delete:post="openDeleteConfirmation"
        >
          <template #no-results>
            <SearchEmpty v-if="postSearchText" />
            <PostsEmptyCreate v-else @on:make:post="openPostModal(null)" />
          </template>
        </FeedHits>
      </div>

      <PostModalSpecial
        v-if="selectedModalType === 'Post'"
        :userId="$principalIdentity.id || ''"
        :givenName="$principalIdentity.given_name || ''"
        :familyName="$principalIdentity.family_name || ''"
        :companyName="company?.name || ''"
        :vesselList="vesselList"
        :locationSearchResults="locationSearchResults"
        :post="existingPost"
        :isConfirmLoading="isConfirmLoading"
        @create:post="createPost"
        @update:post="updatePost"
        @fetch:places="fetchPlacesDebounced"
        @close:modal="closeModal"
      />
      <ModalContentWrapper v-if="selectedModalType === 'Delete'">
        <DeleteConfirmation
          :message="deleteConfirmationModalData!.message"
          :label-cancel="deleteConfirmationModalData!.labelCancel"
          :label-confirm="deleteConfirmationModalData!.labelConfirm"
          :is-delete-loading="isConfirmLoading"
          @close:modal="updateModalState(false)"
          @confirm:modal="deletePost(deleteConfirmationModalData!.id)"
        />
      </ModalContentWrapper>
    </div>

    <!-- Toast for CUD state -->
    <SimpleToast v-if="cudText === 'failed'" position="top-right">
      <template #icon>
        <SolidX class="w-6 h-6 shrink-0 rounded-lg text-red-600" />
      </template>
      <template #headingMessage>Failed to apply change.</template>
      <template #bodyMessage>Please try again later or request assistance if the issue persists.</template>
    </SimpleToast>
    <SimpleToast v-else-if="postCompleted && cudText && !postTimer" position="top-right">
      <template #icon>
        <SolidCheckCircle class="w-6 h-6 shrink-0 rounded-lg text-green-600" />
      </template>
      <template #headingMessage>Successfully {{ cudText }}.</template>
      <template #bodyMessage>
        Refresh to view your
        <template v-if="cudText === 'created'">new post!</template>
        <template v-else-if="cudText === 'edited'">edited post!</template>
        <template v-else-if="cudText === 'deleted'">updated board.</template>
      </template>
    </SimpleToast>
  </div>
</template>
