<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import Button from '@/components/Button.vue'

export type TaxRateProps = {
  taxRateName: string
  taxRateValue: string
}

const props = defineProps<TaxRateProps>()
const emit = defineEmits<{
  (e: 'close:modal'): void
  (e: 'confirm:modal', value: TaxRateProps): void
}>()

const refTaxRateName: Ref<string> = ref('')
const refTaxRateValue: Ref<string> = ref('')

onMounted(() => {
  refTaxRateName.value = props.taxRateName
  refTaxRateValue.value = (Math.round(Number(props.taxRateValue) * 100 * 100) / 100).toString()
})
</script>
<template>
  <div class="h-auto w-auto flex flex-col print:hidden dark:text-gray-200">
    <!-- Modal header -->
    <h3 class="text-lg text-left font-semibold">Configure Tax Rate:</h3>
    <!-- Modal body -->
    <div class="flex flex-col space-y-4 mt-4">
      <div class="w-80 relative">
        <input
          type="text"
          id="floating_outlined_tax_rate_name"
          class="block px-2.5 pb-2 pt-2.5 w-full text-sm bg-transparent rounded-lg border-1 appearance-none focus:outline-none focus:ring-0 peer"
          placeholder=" "
          v-model="refTaxRateName"
        />
        <label
          for="floating_outlined_tax_rate_name"
          class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-800 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
        >
          Tax Rate Name
        </label>
      </div>
      <div class="w-80 relative">
        <input
          type="text"
          id="floating_outlined_tax_rate_value"
          class="block px-2.5 pb-2 pt-2.5 w-full text-sm bg-transparent rounded-lg border-1 appearance-none focus:outline-none focus:ring-0 peer"
          placeholder=" "
          v-model="refTaxRateValue"
        />
        <label
          for="floating_outlined_tax_rate_value"
          class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-800 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
        >
          Tax Rate Value (%)
        </label>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="flex justify-end items-center space-x-2 rounded-b pt-5">
      <Button name="Cancel" :isPrimary="false" @click="emit('close:modal')"></Button>
      <Button
        name="Save"
        isPrimary
        :disabled="refTaxRateName === '' || refTaxRateValue === ''"
        @click="emit('confirm:modal', { taxRateName: refTaxRateName, taxRateValue: refTaxRateValue })"
      ></Button>
    </div>
  </div>
</template>
