import { Variant } from './types'

/**
 * The pricing item
 */
export type PricingItem = {
  currency: string
  price: number
  displayCurrency: string
  displayPrice: number
}

export type EffectiveDate = {
  from: string
  to: string
}

/**
 * Get the lowest pricing item from variants
 * @param variants The vessel variants
 * @param rates The exchange rates for the vessel
 */
export const getLowestPricingItem = (variants: Variant[], rates: { [key: string]: string }) => {
  const pricingItems: PricingItem[] = variants.map((variant) => {
    // ! because it is already ensured in validVariants
    // Use charterFee for filter and fallback to total if undefined
    const exchangeRate = Number(rates?.[variant.pricing!.currency]) || 1
    const convertedPriceInEuro = (variant.pricing!.charterFee || variant.pricing!.total!) * exchangeRate

    return {
      currency: 'EUR',
      price: convertedPriceInEuro / 100,
      displayCurrency: variant.pricing!.currency,
      displayPrice: (variant.pricing!.charterFee || variant.pricing!.total!) / 100,
    }
  })

  return pricingItems.sort((a, b) => a.price - b.price)[0]
}

/**
 * Format to international currency based on locale and currency
 * @param currency
 * @param price
 */
export const intlCurrencyFormatter = (currency: string, price: number) => {
  if (!currency || !price) {
    throw new Error('Currency or price was not supplied. Unable to format currency.')
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price)
}
