import { ref } from 'vue'

export const useTypewriterEffect = () => {
  const typeValue = ref('')
  const typeStatus = ref(false)
  const displayTextArray = ref<string[]>([])

  const typingSpeed = 100
  const erasingSpeed = 100
  const newTextDelay = 1000
  const displayTextArrayIndex = ref(0)
  const charIndex = ref(0)

  const typeText = () => {
    if (charIndex.value < displayTextArray.value[displayTextArrayIndex.value].length) {
      if (!typeStatus.value) {
        typeStatus.value = true
      }
      typeValue.value += displayTextArray.value[displayTextArrayIndex.value].charAt(charIndex.value)
      charIndex.value += 1
      setTimeout(typeText, typingSpeed)
    } else {
      typeStatus.value = false
      setTimeout(eraseText, newTextDelay)
    }
  }

  const eraseText = () => {
    if (charIndex.value > 0) {
      if (!typeStatus.value) {
        typeStatus.value = true
      }
      typeValue.value = displayTextArray.value[displayTextArrayIndex.value].substring(0, charIndex.value - 1)
      charIndex.value -= 1
      setTimeout(eraseText, erasingSpeed)
    } else {
      typeStatus.value = false
      displayTextArrayIndex.value += 1
      if (displayTextArrayIndex.value >= displayTextArray.value.length) displayTextArrayIndex.value = 0
      setTimeout(typeText, typingSpeed + 1000)
    }
  }

  const initTypewriter = (textArr: string[]) => {
    displayTextArray.value = textArr
    setTimeout(typeText, newTextDelay + 200)

    return typeText
  }

  return {
    initTypewriter,
    typeValue,
  }
}
