import { DateTime, Interval } from 'luxon'

import {
  getDatesInMonth,
  getPartialDaysOfMonthBefore,
  getPartialNumDaysOfMonthAfter,
} from '@ankor-io/common/calendar/calendar-view'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { CalendarEvent } from '@ankor-io/common/vessel/types'
import { Post } from '@ankor-io/feed-endpoint/src/types'

import {
  Calendar,
  CalendarBlock,
  CalendarBlockType,
  CalendarDay,
  CalendarDayBlock,
  CalendarEventBlock,
  CalendarMixedBlock,
  CalendarPrioritizedBlock,
  CalendarSpecialBlock,
  CalendarWeekBlock,
} from './types'

/**
 *
 * @param year The calendar year
 * @param events The calendar events
 * @param specials The calendar specials
 * @param timezone The time zone to adjust to (required for unit tests specifically - issues with github timing)
 * @returns
 */
export const buildCalendar = (year: number, events: CalendarEvent[], specials: any[], timezone?: string): Calendar => {
  // First build an empty calendar as a queryable table
  const calendar: Calendar = {
    table: {
      [year]: {
        1: {
          [CalendarBlockType.DAY]: {},
          [CalendarBlockType.WEEK]: {},
          [CalendarBlockType.BLOCKS]: {},
        },
      },
    },
  }

  // Map all the events
  const calendarEventBlocks: CalendarEventBlock[] = events.map((event: CalendarEvent) => {
    // Build the event interval object
    const interval: Interval = ISO8601.fromIntervalString(event.interval, { setZone: true })

    /*
      The event bars on the timeline / monthly calendar should be in absolute dates and does not change depending on the timezone of event or user browser location
      For example an event on 4th of Sept in New York should appear as
        - 4th for user in New York
        - 4th for user in London
        - 4th for user in Sydney

      Therefore when we try to compute the event bar gap, spread, days etc, we:
            - SHOULD NOT use embarkation / disembarkation timezone
            - SHOULD NOT use user's browser time
            - SHOULD NOT convert any of above into UTC (because 4th Sept at 11pm is 5th Sept UTC)
      
      We SHOULD generate a new UTC datetime using absolute numbers eg 4th of Sept, 2024
       eg DateTime.utc(2024, 9, 4)

      This ensures events appear on the same days regardless of embarkation / disembarkation / browser timezone
  */

    const intervalAbsolute = Interval.fromDateTimes(
      DateTime.utc(interval.start!.year, interval.start!.month, interval.start!.day),
      DateTime.utc(interval.end!.year, interval.end!.month, interval.end!.day),
    )

    // Get the number of days for this interval duration
    const days = intervalAbsolute.toDuration('days').days

    // Divide equally into intervals
    const intervals: Interval[] = days ? intervalAbsolute.divideEqually(days) : []

    // Create a calendar event block
    const block: CalendarEventBlock = {
      type: CalendarBlockType.BLOCKS,
      ...event,
      position: {
        startDate: intervalAbsolute.start!.toFormat('dd-MM-yyyy'),
        endDate: intervalAbsolute.end!.toFormat('dd-MM-yyyy'),
        middleDates: intervals
          .map((interval: Interval) => interval.end!.toFormat('dd-MM-yyyy'))
          .filter((date: string) => date && date !== intervalAbsolute.end!.toFormat('dd-MM-yyyy')),
        spanningDates: [], // per week specific, the dates the event spans across
        priority: 0,
      },
    }

    return block
  })

  /**
   * Map all the specials
   * And at the end remove all those with no eventIntervals since those are returned early
   */
  const calendarSpecialBlocks: CalendarSpecialBlock[] = specials
    .map((special: any) => {
      const post: Post = special.post
      if (!post.eventInterval) {
        return {} as CalendarSpecialBlock
      }

      // Build the event interval object
      const interval: Interval = ISO8601.fromIntervalString(post.eventInterval!, { setZone: true })

      /*
          The event bars on the timeline / monthly calendar should be in absolute dates and does not change depending on the timezone of event or user browser location
          For example an event on 4th of Sept in New York should appear as
            - 4th for user in New York
            - 4th for user in London
            - 4th for user in Sydney

          Therefore when we try to compute the event bar gap, spread, days etc, we:
                - SHOULD NOT use embarkation / disembarkation timezone
                - SHOULD NOT use user's browser time
                - SHOULD NOT convert any of above into UTC (because 4th Sept at 11pm is 5th Sept UTC)
          
          We SHOULD generate a new UTC datetime using absolute numbers eg 4th of Sept, 2024
          eg DateTime.utc(2024, 9, 4)

          This ensures events appear on the same days regardless of embarkation / disembarkation / browser timezone
      */

      const intervalAbsolute = Interval.fromDateTimes(
        DateTime.utc(interval.start!.year, interval.start!.month, interval.start!.day),
        DateTime.utc(interval.end!.year, interval.end!.month, interval.end!.day),
      )

      // Get the number of days for this interval duration
      const days = intervalAbsolute.toDuration('days').days

      // Divide equally into intervals
      const intervals: Interval[] = days ? intervalAbsolute.divideEqually(days) : []

      // Create a calendar event block
      const block: CalendarSpecialBlock = {
        type: CalendarBlockType.BLOCKS,
        ...post,
        position: {
          startDate: intervalAbsolute.start!.toFormat('dd-MM-yyyy'),
          endDate: intervalAbsolute.end!.toFormat('dd-MM-yyyy'),
          middleDates: intervals
            .map((interval: Interval) => interval.end!.toFormat('dd-MM-yyyy'))
            .filter((date: string) => date && date !== intervalAbsolute.end!.toFormat('dd-MM-yyyy')),
          spanningDates: [], // per week specific, the dates the event spans across
          priority: 0,
        },
      }

      return block
    })
    .filter((special: CalendarSpecialBlock) => Object.keys(special).length !== 0)

  // Sort all the event intervals
  const sortedEvents: CalendarEventBlock[] = calendarEventBlocks.sort(function (a, b) {
    const dateSort = ISO8601.compareStartDates(a.interval, b.interval)
    const durationSort = ISO8601.compareDurations(a.interval, b.interval)
    if (dateSort === 0) {
      return durationSort
    }

    return dateSort
  })

  // Sort all the specials intervals
  const sortedSpecials: CalendarSpecialBlock[] = calendarSpecialBlocks.sort(function (a, b) {
    const dateSort = ISO8601.compareStartDates(a.eventInterval!, b.eventInterval!)
    const durationSort = ISO8601.compareDurations(a.eventInterval!, b.eventInterval!)
    if (dateSort === 0) {
      return durationSort
    }

    return dateSort
  })

  // Combine both the sorted specials and sorted events and having the specials concatted first for priority
  const sortedEventsAndSpecials: CalendarMixedBlock[] = ([] as any).concat(sortedSpecials, sortedEvents)

  // For every month of the current year
  for (let month = 1; month < 13; month++) {
    calendar.table[year][month] = {
      [CalendarBlockType.DAY]: {},
      [CalendarBlockType.WEEK]: {},
      [CalendarBlockType.BLOCKS]: {},
    }

    const calendarDays = getDaysOfCalendarMonth(year, month)

    // Then add all the days into the day table
    calendar.table[year][month].day = calendarDays
      .map((calendarDay: CalendarDay) => {
        const calendarDayBlock: CalendarDayBlock = {
          type: CalendarBlockType.DAY,
          ...calendarDay,
        }

        const record: Record<string, CalendarBlock> = {}
        record[calendarDay.date] = calendarDayBlock
        return record
      })
      .reduce((acc: Record<string, CalendarBlock>, comb: Record<string, CalendarBlock>) => {
        return { ...acc, ...comb }
      })

    // Add all the weeks into the week table
    const numberOfWeeks = calendarDays.length / 7
    for (let i = 0; i < numberOfWeeks; i++) {
      const daysOfAWeek: CalendarDay[] = calendarDays.slice(i * 7, (i + 1) * 7)
      const days: CalendarDayBlock[] = []
      daysOfAWeek.forEach((day: CalendarDay) => {
        days.push(calendar.table[year][month].day[day.date] as CalendarDayBlock)
      })
      calendar.table[year][month].week[i] = {
        type: CalendarBlockType.WEEK,
        days: days,
      } as CalendarWeekBlock
    }

    // ------------------------- //
    // Events & Specials = Block //
    // ------------------------- //

    // Split intervals into weeks
    const weeklyMixedBlock = Object.entries(calendar.table[year][month].week).map((week) => {
      const calendarWeek = week[1] as CalendarWeekBlock
      const compStart = timezone
        ? DateTime.fromFormat(calendarWeek.days[0].date, 'dd-MM-yyyy', { zone: 'utc' })
        : DateTime.fromFormat(calendarWeek.days[0].date, 'dd-MM-yyyy')
      const compEnd = timezone
        ? DateTime.fromFormat(calendarWeek.days[6].date, 'dd-MM-yyyy', { zone: 'utc' }).endOf('day')
        : DateTime.fromFormat(calendarWeek.days[6].date, 'dd-MM-yyyy').endOf('day')

      const weekIntervalString = ISO8601.toIntervalString(compStart, compEnd)

      const mixedBlocks = sortedEventsAndSpecials.filter((mixedBlock: CalendarMixedBlock) => {
        if (ISO8601.overlapsAny(mixedBlock.eventInterval! || mixedBlock.interval, [weekIntervalString])) {
          return true
        }

        return false
      })

      return mixedBlocks
    })

    // Priority list to see what blocks and positions have been prioritized
    const comparedMixedBlocks: CalendarPrioritizedBlock[] = []

    // Add a priority to the blocks and push the spanning dates for each block for that week
    const prioritizedBlocks = weeklyMixedBlock.map((week: CalendarMixedBlock[], weekIndex) => {
      week.map((mixedBlock: CalendarMixedBlock, mixedBlockIndex) => {
        const days = (calendar.table[year][month].week[weekIndex] as CalendarWeekBlock).days
        if (
          !days.find(
            (day: CalendarDayBlock) =>
              day.date === mixedBlock.position.startDate ||
              mixedBlock.position.middleDates.includes(day.date) ||
              day.date === mixedBlock.position.endDate,
          )
        ) {
          return
        }

        const calendarWeek = calendar.table[year][month].week[weekIndex] as CalendarWeekBlock

        const compStart = DateTime.fromFormat(calendarWeek.days[0].date, 'dd-MM-yyyy')
        const compEnd = DateTime.fromFormat(calendarWeek.days[6].date, 'dd-MM-yyyy').endOf('day')

        const weekInterval = Interval.fromDateTimes(compStart, compEnd)
        const weekIntervalString = ISO8601.toIntervalString(compStart, compEnd)
        mixedBlock.position.spanningDates = [] // Reset spanningDates
        if (ISO8601.overlapsAny(mixedBlock.eventInterval! || mixedBlock.interval, [weekIntervalString])) {
          // Find the dates that do overlap and push them into the spanningDates
          // startDate
          if (weekInterval.contains(DateTime.fromFormat(mixedBlock.position.startDate, 'dd-MM-yyyy'))) {
            mixedBlock.position.spanningDates.push(mixedBlock.position.startDate)
          }

          // middleDates
          mixedBlock.position.middleDates.map((date: string) => {
            if (weekInterval.contains(DateTime.fromFormat(date, 'dd-MM-yyyy'))) {
              mixedBlock.position.spanningDates.push(date)
            }
          })

          // endDate
          if (
            mixedBlock.position.endDate !== mixedBlock.position.startDate &&
            !mixedBlock.position.spanningDates.includes(mixedBlock.position.endDate) &&
            weekInterval.contains(DateTime.fromFormat(mixedBlock.position.endDate, 'dd-MM-yyyy'))
          ) {
            mixedBlock.position.spanningDates.push(mixedBlock.position.endDate)
          }
        }

        if (mixedBlockIndex === 0) {
          // Already sorted, so first block of each week will always have a priority of 1
          mixedBlock.position.priority = 1

          const blockPosition = mixedBlock.position
          comparedMixedBlocks.push({
            ...mixedBlock,
            week: weekIndex,
            days: blockPosition.spanningDates,
            position: { ...blockPosition, priority: 1 },
          })
          return
        }

        for (const day of days) {
          if (
            day.date === mixedBlock.position.startDate ||
            day.date === mixedBlock.position.endDate ||
            mixedBlock.position.middleDates.includes(day.date)
          ) {
            const sameDayComparedMixedBlocks = blocksPrioritizedInSameDay(comparedMixedBlocks, weekIndex, day.date)

            // Trivial - order is simply 1 when no blocks exist in that cell
            if (!sameDayComparedMixedBlocks.length) {
              mixedBlock.position.priority = 1

              const blockPosition = mixedBlock.position
              comparedMixedBlocks.push({
                ...mixedBlock,
                week: weekIndex,
                days: blockPosition.spanningDates,
                position: { ...blockPosition, priority: 1 },
              })
              return
            }

            if (sameDayComparedMixedBlocks.length === 1) {
              // Slightly trivial - order is simply 1 or 2, when an even is on one already, the new one will take the other
              const otherBlockOrder = sameDayComparedMixedBlocks[0]?.position.priority
              const priority = otherBlockOrder === 1 ? 2 : 1
              mixedBlock.position.priority = priority

              const blockPosition = mixedBlock.position
              comparedMixedBlocks.push({
                ...mixedBlock,
                week: weekIndex,
                days: blockPosition.spanningDates,
                position: { ...blockPosition, priority },
              })
              return
            }

            // Not so trivial - when 2 or more blocks in a single cell a linear search is needed to find the next available order position
            const priority = findFirstMissingPriority(sameDayComparedMixedBlocks)
            mixedBlock.position.priority = priority

            const blockPosition = mixedBlock.position
            comparedMixedBlocks.push({
              ...mixedBlock,
              week: weekIndex,
              days: blockPosition.spanningDates,
              position: { ...blockPosition, priority },
            })
            return
          }
        }

        return mixedBlock
      })

      return ObjectUtil.deepCopy(week)
    })

    calendar.table[year][month].blocks = prioritizedBlocks
      .map((mixedBlock: CalendarMixedBlock[], index) => {
        const record: Record<string, CalendarMixedBlock[]> = {}
        record[index] = mixedBlock
        return record
      })
      .reduce((acc: Record<string, CalendarMixedBlock[]>, comb: Record<string, CalendarMixedBlock[]>) => {
        return { ...acc, ...comb }
      })
  }

  return calendar
}

const getDaysOfCalendarMonth = (year: number, month: number): CalendarDay[] => {
  const daysBefore = getPartialDaysOfMonthBefore(year, month)
  const daysDuring = getDatesInMonth(year, month)
  const daysOfCalendar = daysBefore.concat(daysDuring)
  const daysAfter = getPartialNumDaysOfMonthAfter(year, month)
  const daysAfterFill = daysAfter >= 7 ? daysAfter - 7 : daysAfter

  const daysAfterMonth = month === 12 ? 1 : month + 1
  const daysAfterYear = month === 12 ? year + 1 : year
  for (let i = 1; i <= daysAfterFill; i++) {
    const day = i < 10 ? `0${i}` : i
    const formattedMonth = daysAfterMonth < 10 ? `0${daysAfterMonth}` : daysAfterMonth
    daysOfCalendar.push({ day: i, date: `${day}-${formattedMonth}-${daysAfterYear}`, isCurrentMonth: false })
  }

  return daysOfCalendar
}

/**
 * Checks how many block in the same day of the same week are already prioritized, so it can be prioritized below it
 * @param prioritizedBlocks The temporary list holding all the blocks that have been prioritized
 * @param week A given week of the calendar
 * @param day A given day of the calendar
 */
export const blocksPrioritizedInSameDay = (
  prioritizedBlocks: CalendarPrioritizedBlock[] | Partial<CalendarPrioritizedBlock[]>,
  week: number,
  day: string,
) => {
  // Filter out the blocks in the same day of the same week
  return prioritizedBlocks.filter((block) => week === block!.week && block!.days.includes(day))
}

/**
 * Linear search sorting to find the lowest number (starting from 1) that is not ordered in that cell
 * @param sameDayBlocks
 */
export const findFirstMissingPriority = (
  sameDayBlocks: CalendarMixedBlock[] | Partial<CalendarMixedBlock[]>,
): number => {
  // Get the priority for each block into an array
  const arr = new Array(sameDayBlocks.length)
  for (let i = 0; i < sameDayBlocks.length; i++) {
    arr[i] = sameDayBlocks[i]?.position.priority
  }

  // Now sort these blocks on its priority
  const sortedArr = [
    ...new Set(
      arr.sort(function (a, b) {
        return a - b
      }),
    ),
  ]

  // Perform linear search
  for (let i = 0; i < sortedArr.length; i++) {
    if (sortedArr[i] !== i + 1) {
      return i + 1
    }
  }

  return sortedArr.length + 1
}

/**
 * Assert that the returned day is todays date
 * @param day The day
 * @param month The month
 * @param year The year
 */
export const isToday = (day: number, month: number, year: number): boolean => {
  const today = DateTime.now()
  return day === today.day && month === today.month && year === today.year
}
