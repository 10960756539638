import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2",
      "stroke-width": "2",
      d: "M17 15V4.26087C17 3.01174 18.1187 2 19.5 2H21M4 15V4.26087C4 3.01174 4.895 2 6 2H8M4 6H17M4 11H17M2 17L3.25 17.6757C4.05 18.1081 4.95 18.1081 5.75 17.6757C6.55 17.2432 7.45 17.2432 8.25 17.6757C9.05 18.1081 9.95 18.1081 10.75 17.6757C11.55 17.2432 12.45 17.2432 13.25 17.6757C14.05 18.1081 14.95 18.1081 15.75 17.6757C16.55 17.2432 17.45 17.2432 18.25 17.6757C19.05 18.1081 19.95 18.1081 20.75 17.6757L22 17M2 21L3.25 21.6757C4.05 22.1081 4.95 22.1081 5.75 21.6757C6.55 21.2432 7.45 21.2432 8.25 21.6757C9.05 22.1081 9.95 22.1081 10.75 21.6757C11.55 21.2432 12.45 21.2432 13.25 21.6757C14.05 22.1081 14.95 22.1081 15.75 21.6757C16.55 21.2432 17.45 21.2432 18.25 21.6757C19.05 22.1081 19.95 22.1081 20.75 21.6757L22 21"
    })
  ]))
}