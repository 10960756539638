<script setup lang="ts">
type Position = 'prefix' | 'suffix' | 'none'

interface ButtonProps {
  id?: string
  name: string
  isPrimary: boolean
  onClick?: () => void
  position?: Position
  hasBorder?: boolean
  disabled?: boolean
}

withDefaults(defineProps<ButtonProps>(), {
  isPrimary: true,
  position: 'none',
  hasBorder: false,
  disabled: false,
})

const setIsPrimaryStyles = (isPrimary: boolean, hasBorder: boolean, disabled: boolean): string => {
  if (isPrimary) {
    return disabled
      ? 'text-white bg-gray-200 cursor-not-allowed'
      : 'text-white bg-primary-600 hover:bg-primary-800 active:bg-primary-600 focus:bg-primary-600 border border-primary-600 hover:border-primary-800'
  }

  const defaultClasses = disabled
    ? 'text-gray-200 bg-white cursor-not-allowed'
    : 'text-primary-600 dark:text-white dark:border-white bg-transparent hover:bg-primary-300 dark:hover:bg-gray-700 focus:bg-white dark:focus:bg-gray-500'

  if (hasBorder) {
    return `${defaultClasses} border border-primary-600 hover:border-primary-600 focus:border-transparent`
  }

  return defaultClasses
}
</script>

<template>
  <button
    name="button"
    type="button"
    class="group flex items-center outline-none font-medium rounded-lg text-xs py-2 px-3 text-center focus:ring-2 focus:ring-primary-300 whitespace-nowrap"
    :id="id"
    :class="setIsPrimaryStyles(isPrimary, hasBorder, disabled)"
    :disabled="disabled"
    @click="onClick"
  >
    <slot v-if="position === 'prefix'"></slot>
    <span class="text-xs font-medium w-full">{{ name }}</span>
    <slot v-if="position === 'suffix'"></slot>
  </button>
</template>
