import { DateTime, Interval } from 'luxon'

import * as ISO8601 from '@ankor-io/common/date/ISO8601'

/**
 * Get the current epoch seconds
 *
 * @returns The current epoch seconds
 */
export const epochSeconds = () => {
  return Math.floor(new Date().getTime() / 1000)
}

/**
 * Get the start date from an interval string
 * @param intervalString
 */
export const getStartDate = (intervalString?: string) => {
  if (!intervalString) {
    return ''
  }

  try {
    const interval: Interval = ISO8601.fromIntervalString(intervalString)
    return interval.start?.toUTC().toFormat('yyyy-MM-dd') || ''
  } catch (e) {
    return ''
  }
}

/**
 * Get the end date from an interval string
 * @param intervalString
 */
export const getEndDate = (intervalString?: string) => {
  if (!intervalString) {
    return ''
  }

  try {
    const interval: Interval = ISO8601.fromIntervalString(intervalString)
    return interval.end?.toUTC().toFormat('yyyy-MM-dd') || ''
  } catch (e) {
    return ''
  }
}

/**
 * Update the event interval from start and end date
 */
export const updateInterval = (event: { startDate: string; endDate: string }) => {
  const { startDate, endDate } = event
  if (!startDate || !endDate) {
    return
  }

  return ISO8601.toIntervalString(
    DateTime.fromISO(startDate, { zone: 'utc' }).startOf('day'),
    DateTime.fromISO(endDate, { zone: 'utc' }).endOf('day'),
    'utc',
  )
}

/**
 * Reverse a given date. eg: yyyy-mm-dd to dd-mm-yyyy
 * @param date A given date
 * @param expectedLengthInPos0 The length of characters in position 0 of the split array, to check if the index is yyyy or else
 */
export const reverseDateFormat = (date: string | null, expectedLengthInPos0: number): string | null => {
  if (date) {
    const splitDash = date.split('-')
    const splitSlash = date.split('/')
    if (splitDash[0].length === expectedLengthInPos0) {
      return splitDash.reverse().join('-')
    } else if (splitSlash[0].length === expectedLengthInPos0) {
      return splitSlash.reverse().join('-')
    } else {
      return date
    }
  }

  return date
}
