<script setup lang="ts">
import { Ref, inject, ref } from 'vue'

import { getMimeTypeFromArrayBuffer, isValidMimeType } from '@ankor-io/common/assets/valid'
import { OutlineCamera } from '@ankor-io/icons/outline'
import { SolidCheckMark, SolidExclamationCircle } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import { AuthenticationContext } from '@/iam/types'
import { _requestSignedUrl, uploadFile } from '@/services/assets/upload'
import { assetUploaderValidityStyling } from '@/utils/asset-uploader-validity-styling'

import DropZone from './DropZone.vue'

type Props = {
  uri: string
  id?: string
  image?: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'delete:image'): void
  (e: 'upload:failed'): void
  (e: 'upload:started'): void
  (e: 'file:uploaded', value: string): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const isUploading: Ref<boolean> = ref(false)
const uploadedAvatarFile: Ref<boolean> = ref(false)
const uploadedAvatarFileSrc: Ref<string> = ref('')
// Additional validation for styling
const uploadSuccessful: Ref<boolean> = ref(false) // if the file upload is successful
const isFileTypeSupported: Ref<boolean> = ref(true) // if the file extension is valid or not

const addFileWhenDropped = (file: File) => {
  handleAvatarUploadFile(file)
}

const addFileFromInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  handleAvatarUploadFile(target.files!.item(0)!)
}

/**
 * Handle crew member avatar upload
 * @param file The file being uploaded
 */
const handleAvatarUploadFile = async (file: File) => {
  emit('upload:started')

  // Valid file check
  const parser = new DOMParser()
  if (isValidMimeType(file.type) && isValidMimeType(getMimeTypeFromArrayBuffer(await file.arrayBuffer(), parser))) {
    isFileTypeSupported.value = true
  } else {
    isUploading.value = false
    isFileTypeSupported.value = false
    setTimeout(() => {
      isFileTypeSupported.value = true
      emit('upload:failed')
      return
    }, 3000)
  }

  isUploading.value = true
  const token: string | undefined = await authenticationContext.getToken()
  const filesToUpload = Array.isArray(file) ? file : [file]

  filesToUpload.map(async (fileToUpload: File) => {
    const { mediaUri, signedUrl } = await _requestSignedUrl(props.uri, token!)
    const uploaded: boolean = await uploadFile(signedUrl, fileToUpload)

    const fileReader = new FileReader()
    fileReader.onload = () => {
      uploadedAvatarFile.value = true
      uploadedAvatarFileSrc.value = fileReader.result as string
    }

    fileReader.readAsDataURL(fileToUpload)
    if (uploaded) {
      isUploading.value = false
      uploadSuccessful.value = true
      setTimeout(() => {
        uploadSuccessful.value = false
        emit('file:uploaded', mediaUri)
      }, 3000)
    } else {
      isUploading.value = false
      isFileTypeSupported.value = false
      setTimeout(() => {
        isFileTypeSupported.value = true
        emit('upload:failed')
      }, 3000)
    }
  })
}
</script>

<template>
  <DropZone class="drop-area" @file-dropped="addFileWhenDropped">
    <div class="flex items-center justify-center">
      <label
        class="flex flex-col items-center justify-center w-48 h-48 rounded-full border-2 transition-all duration-100 border-dashed cursor-pointer"
        :for="props.id || 'avatar-dropzone-file'"
        :class="assetUploaderValidityStyling(uploadSuccessful, isFileTypeSupported)"
      >
        <Spinner v-if="isUploading" class="w-24 h-24" />
        <template v-else-if="!isFileTypeSupported">
          <SolidExclamationCircle class="w-10 h-10 fill-red-600" />
          <p class="text-sm font-bold text-red-600 mb-2 text-center">File type or size error</p>
          <p class="text-xs font-semibold text-red-600 text-center">
            JPG, PNG, SVG and
            <br />
            GIF supported.
          </p>
        </template>
        <template v-else-if="uploadSuccessful">
          <SolidCheckMark class="w-10 h-10 fill-green-400 mb-3" />
          <p class="text-sm font-semibold mb-4 text-center text-gray-500 dark:text-gray-400">Upload complete!</p>
        </template>
        <div v-else class="flex flex-col items-center justify-center gap-y-2">
          <OutlineCamera class="w-6 h-6 shrink-0 text-gray-500 dark:text-gray-400" />
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
            Click to upload
            <span class="font-semibold">Avatar</span>
            <br />
            <span v-if="!props.image"> or Drag and drop</span>
          </p>
        </div>
        <input :id="props.id || 'dropzone-file'" type="file" class="hidden" @change="addFileFromInput" />
      </label>
    </div>
  </DropZone>
</template>
