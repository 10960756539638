<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import { isEmailValid } from '@ankor-io/common/input-validation/validation'
import { OutlineXMark } from '@ankor-io/icons/outline'

type Props = {
  tags: string[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'add:tag', value: string): void
  (e: 'remove:tag', index: number): void
}>()

onMounted(() => {
  inputRef.value?.focus()
})

const inputRef: any = ref(null)
const newTag: Ref<string> = ref('')
const highlightedTagIndex: Ref<number> = ref(props.tags?.length || 0)

/**
 * Highlight previous tag
 */
const highlightPreviousTag = () => {
  if (highlightedTagIndex.value > 0) {
    highlightedTagIndex.value--
  }
}

/**
 * Highlight next tag
 */
const highlightNextTag = () => {
  if (!props.tags || highlightedTagIndex.value === props.tags.length) {
    return
  }

  highlightedTagIndex.value++
}

/**
 * On escape, the user can delete a tag
 * @param event KeyboardEvent escape key press
 */
const adjustTagByBackspace = (event: KeyboardEvent) => {
  const target = event.currentTarget as HTMLInputElement
  if (target.value) {
    return
  }

  if (highlightedTagIndex.value <= (props.tags?.length || 0) - 1) {
    removeTag(highlightedTagIndex.value)
    highlightedTagIndex.value = (props.tags?.length || 0) - 1
  } else {
    highlightPreviousTag()
  }
}

/**
 * Updating the newTag var and resetting highlightedTagIndex
 * @param event Event
 */
const updateNewTag = (event: Event): void => {
  const target = event.currentTarget as HTMLInputElement
  newTag.value = target.value

  // Reset highlighting if user started to highlight existing tags
  highlightedTagIndex.value = (props.tags?.length || 0) + 1
}

/**
 * Emit a tag to add
 */
const addTag = (): void => {
  // Don't submit nothing
  if (!newTag.value) {
    return
  }

  emit('add:tag', newTag.value)

  // Reset the tag
  newTag.value = ''
  highlightedTagIndex.value = (props.tags?.length || 0) + 1
}

/**
 * Emit a tag to remove
 * @param index The position in the tags array
 */
const removeTag = (index: number): void | boolean => {
  if (index < 0) {
    return false
  }
  emit('remove:tag', index)
}
</script>
<template>
  <div class="flex flex-wrap items-center gap-2">
    <span
      v-for="(tag, index) in props.tags"
      class="flex items-center px-1.5 py-0.5 rounded-md text-xs h-6 text-gray-500 dark:text-gray-400"
      :class="[
        highlightedTagIndex === index ? 'bg-gray-200 dark:bg-gray-600' : 'bg-gray-100 dark:bg-gray-700',
        {
          'text-red-500 dark:text-red-400': !isEmailValid(tag),
        },
      ]"
      :key="`${tag}-${index}`"
      :id="`${index}-${tag}-badge-dismiss-default`"
    >
      {{ tag }}
      <button
        v-if="index"
        type="button"
        class="flex items-center"
        aria-label="Remove"
        :data-dismiss-target="`#${index}-${tag}-badge-dismiss-default`"
        @click.stop="removeTag(index)"
      >
        <OutlineXMark class="w-2 h-2 ml-2" />
        <p class="sr-only">{{ `Remove ${tag}` }}</p>
      </button>
    </span>
    <input
      :ref="(el) => (inputRef = el)"
      type="text"
      class="flex-grow text-gray-500 dark:text-gray-400 border-none text-xs rounded-lg focus:border-none focus:outline-none block py-1 px-1 focus:shadow-none focus:ring-0 bg-transparent"
      :value="newTag"
      @click="highlightedTagIndex = props.tags?.length || 0"
      @input="updateNewTag($event)"
      @keyup.enter.prevent="addTag()"
      @keyup.right.prevent="highlightNextTag()"
      @keyup.left.prevent="highlightPreviousTag()"
      @keyup.backspace.prevent="highlightedTagIndex !== 0 && adjustTagByBackspace($event)"
      @blur="addTag()"
    />
  </div>
</template>
