import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M2.40356 7.06079L12 11.8584L21.5964 7.06079C21.5608 6.44932 21.2929 5.87457 20.8473 5.45426C20.4018 5.03395 19.8125 4.79988 19.2 4.79999H4.79996C4.18747 4.79988 3.5981 5.03395 3.15258 5.45426C2.70705 5.87457 2.43909 6.44932 2.40356 7.06079V7.06079Z" }),
    _createElementVNode("path", { d: "M21.6 9.74161L12 14.5416L2.40002 9.74161V16.8C2.40002 17.4365 2.65288 18.047 3.10297 18.4971C3.55306 18.9472 4.1635 19.2 4.80002 19.2H19.2C19.8365 19.2 20.447 18.9472 20.8971 18.4971C21.3472 18.047 21.6 17.4365 21.6 16.8V9.74161Z" })
  ]))
}