<script setup lang="ts">
import { initPopovers } from 'flowbite'
import { DateTime, Duration, Interval } from 'luxon'
import { ComputedRef, Ref, computed, onMounted, ref } from 'vue'

import DateRangePicker from '@ankor-io/blocks/components/DateRangePicker/DateRangePicker.vue'
import { fromIntervalString, toIntervalString, toRecurringIntervalString } from '@ankor-io/common/date/ISO8601'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import {
  Blueprint,
  GeoFilter,
  Pricing,
  Variant,
  /*, Unit*/
} from '@ankor-io/common/vessel/types'
import { OutlinePencil, OutlineTrash } from '@ankor-io/icons/outline'
import { SolidPlus } from '@ankor-io/icons/solid'
import { SolidInformationCircle } from '@ankor-io/icons/solid'

import OperatingAreaImage from '@/assets/OperatingAreaPopoverImage.png'
import ButtonVue from '@/components/Button.vue'
import GeoSearchInput from '@/components/edit-vessel/GeoSearchInput.vue'
import PricingVue from '@/components/edit-vessel/Pricing.vue'
import FloatingInput from '@/components/input/FloatingInput.vue'
import StandardInput from '@/components/input/StandardInput.vue'

import { ToysAmenitiesFields } from './ToysAmenitiesForm.vue'

// import Dropdown from '@/components/modal-content/event-modal-content/Dropdown.vue'

type Props = {
  variant: Variant
  blueprint: Blueprint
  index: number
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'delete:variant'): void
  (e: 'update:variant', value: Variant): void
}>()

onMounted(() => {
  initPopovers()
})

const isVariantInfoOpen: Ref<boolean> = ref(false)
// Used to default to true if not undefined
const availableToysAmenities: ComputedRef<boolean> = computed(() => {
  return props.variant.isUsingDefaultToysAmenities === undefined ? true : props.variant.isUsingDefaultToysAmenities
})

/**
 * Update a vessel variant
 * @param key The key of the variant
 * @param event The new value for the key of a variant
 * @returns {void}
 */
const updateVariant = (key: string, event: Event): void => {
  const targetValue: string = (event.target as HTMLInputElement).value
  const value: string | number = isNaN(Number(targetValue)) || targetValue === '' ? targetValue : Number(targetValue)
  const variant = ObjectUtil.deepCopy(props.variant)
  variant[key] = value
  emit('update:variant', variant)
}

/**
 * Update petsAllowed property of the vessel variant
 * @param event The new value for the key of a variant
 * @returns {void}
 */
const updateVariantPetsAllowed = (event: Event): void => {
  const value: boolean = (event.target as HTMLInputElement).checked
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  variant.petsAllowed = value
  emit('update:variant', variant)
}

/**
 * Update active status of the vessel variant
 * @param event The new value for the key of a variant
 * @returns {void}
 */
const updateVariantActive = (event: Event): void => {
  const value: boolean = (event.target as HTMLInputElement).checked
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  variant.active = value
  emit('update:variant', variant)
}

/**
 * Sets the selected charter type in the dropdown
 * @param charterType The pricing unit charter type
 */
// const displayCharterType = (charterType?: Unit): string => {
//   switch (charterType) {
//     case 'DAY':
//     case 'HOUR':
//       return 'Day Charter'
//     default: // WEEK || OTHER
//       return 'Term Charter'
//   }
// }

/**
 * Updates the pricing unit charter type
 * @param event The dropdown label value pair
 */
// const updateCharterType = (event: { label: string; value?: string }) => {
//   const variant: Variant = ObjectUtil.deepCopy(props.variant)
//   variant.pricing!.unit = event.value as Unit
//   emit('update:variant', variant)
// }

/**
 * Update vessel variant pricing
 * @param pricing The new pricing to update to
 * @returns {void}
 */
const updateVariantPricing = (pricing: Pricing): void => {
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  variant.pricing = pricing
  emit('update:variant', variant)
}

/**
 * Updates the geoFilters of a variant at the specified index.
 * @param {GeoFilter[]} geoFilters The new geoFilters to assign to the variant
 * @returns {void}
 */
const updateVariantGeo = (geoFilters: GeoFilter[]): void => {
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  variant.geoFilters = geoFilters
  emit('update:variant', variant)
}

/**
 * Add a new interval to the intervals array
 * @returns {void}
 */
const addInterval = (): void => {
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  if (!variant.intervals) {
    variant.intervals = ['']
  } else {
    variant.intervals!.push('')
  }

  emit('update:variant', variant)
}

/**
 * Delete an interval from the intervals array
 * @param intervalIndex
 * @returns {void}
 */
const deleteInterval = (intervalIndex: number): void => {
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  variant?.intervals?.splice(intervalIndex, 1)
  emit('update:variant', variant)
}

/**
 * Check if the checkbox should be disabled
 * @param intervalIndex
 * @returns {boolean}
 */
const isCheckboxDisabled = (intervalIndex: number): boolean => {
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  if (variant.intervals && variant.intervals!.length > 0) {
    try {
      const interval: Interval = fromIntervalString(variant.intervals![intervalIndex])
      return !interval.start || !interval.end
    } catch (e) {
      return true
    }
  }

  return true
}

/**
 * Check if the checkbox should be checked
 * @param interval
 * @returns {boolean}
 */
const isChecked = (interval: string): boolean => {
  if (!interval || interval === '') {
    return false
  }

  try {
    fromIntervalString(interval)
    return interval.startsWith('R')
  } catch (e) {
    return false
  }
}

/**
 * Update the annual recurrence of an interval
 * @param intervalIndex The interval index
 * @param event Checkbox event
 * @returns {void}
 */
const updateAnnualRecurrence = (intervalIndex: number, event: Event): void => {
  const checked: boolean = (event.target as HTMLInputElement).checked
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  try {
    const intervalString: string = variant.intervals![intervalIndex]
    const interval: Interval = fromIntervalString(intervalString)
    variant.intervals![intervalIndex] = checked
      ? toRecurringIntervalString(
          interval.start!.toUTC().startOf('day'),
          interval.end!.toUTC().endOf('day'),
          Duration.fromISO('P1Y'),
          Infinity,
          'utc',
        )
      : toIntervalString(interval.start!.toUTC().startOf('day'), interval.end!.toUTC().endOf('day'), 'utc')

    emit('update:variant', variant)
  } catch (e) {
    return
  }
}

/**
 * Get the start date from an interval string
 * @param intervalString The interval string
 * @returns {string}
 */
const getStartDate = (intervalString: string | undefined): string => {
  if (!intervalString || intervalString === '') {
    return ''
  }

  try {
    const interval: Interval = fromIntervalString(intervalString)
    return interval.start?.toUTC().toFormat('yyyy-MM-dd') || ''
  } catch (e) {
    return ''
  }
}

/**
 * Get the end date from an interval string
 * @param intervalString The interval string
 * @returns {string}
 */
const getEndDate = (intervalString: string): string => {
  if (!intervalString || intervalString === '') {
    return ''
  }

  try {
    const interval: Interval = fromIntervalString(intervalString)
    return interval.end?.toUTC().toFormat('yyyy-MM-dd') || ''
  } catch (e) {
    return ''
  }
}

/**
 * Update the variant interval from start and end date
 * @param intervalIndex
 * @param event
 * @returns {void}
 */
const updateVariantInterval = (intervalIndex: number, event: { startDate: string; endDate: string }): void => {
  const { startDate, endDate } = event
  if (!startDate || !endDate) {
    return
  }

  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  const intervalString = toIntervalString(
    DateTime.fromISO(startDate, { zone: 'utc' }).startOf('day'),
    DateTime.fromISO(endDate, { zone: 'utc' }).endOf('day'),
    'utc',
  )

  if (!variant.intervals?.length) {
    variant.intervals = [intervalString]
  } else {
    variant.intervals![intervalIndex] = intervalString
  }

  emit('update:variant', variant)
}

/**
 * Updates the isUsingDefaultToysAmenities boolean field, to display manual or tethered toys, entertainment, amenities and tenders
 */
const updateDefaultToysAmenities = (): void => {
  const variant: Variant = ObjectUtil.deepCopy(props.variant)
  variant.isUsingDefaultToysAmenities = !availableToysAmenities.value

  emit('update:variant', variant)
}

/**
 * Update a toy, entertainment, amenity or tender
 * @param index The position of the field array
 * @param field The Field
 * @param key The key of the field
 * @param event The update event
 */
const updateItem = (index: number, field: ToysAmenitiesFields, key: 'label' | 'quantity' | null, event: Event) => {
  const value: string = (event.target as HTMLInputElement).value
  const variant = ObjectUtil.deepCopy(props.variant)
  if (key) {
    variant[field][index][key] = value
  } else {
    variant[field][index] = value
  }

  emit('update:variant', variant)
}

/**
 * Remove an item from toys, entertainment, amenities or tenders
 * @param index
 * @param field The Field
 */
const removeItem = (index: number, field: ToysAmenitiesFields) => {
  const variant = ObjectUtil.deepCopy(props.variant)
  variant[field].splice(index, 1)
  emit('update:variant', variant)
}

/**
 * Add a new item to toys, entertainment, amenities or tenders
 */
const addNewItem = (field: ToysAmenitiesFields) => {
  const variant = ObjectUtil.deepCopy(props.variant)
  if (variant[field] === undefined) {
    variant[field] = []
  }
  if (field === 'tenders' || field === 'entertainment') {
    variant[field].push('')
  } else {
    variant[field].push({ label: '' })
  }

  emit('update:variant', variant)
}
</script>
<template>
  <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
    <!-- Variant -->
    <div v-show="!isVariantInfoOpen" class="flex items-center justify-between">
      <h5 class="font-bold text-black dark:text-white">
        {{ props.variant.label || props.index + 1 }}
      </h5>

      <div class="flex gap-x-4">
        <OutlinePencil
          id="edit-variant"
          class="w-4 h-4 shrink-0 cursor-pointer transition-all text-gray-900 dark:text-white hover:text-gray-500 hover:dark:text-gray-300"
          @click="isVariantInfoOpen = true"
        />
        <OutlineTrash
          id="delete-variant"
          class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
          @click="emit('delete:variant')"
        />
      </div>
    </div>

    <div v-show="isVariantInfoOpen">
      <div class="flex flex-col gap-y-8">
        <!-- Variant name -->
        <div class="flex gap-4">
          <!-- Charter type -->
          <!-- <div class="flex flex-col gap-y-2">
            <span class="w-max text-sm font-medium text-gray-900 dark:text-white">Pricing Charter Type</span>
            <Dropdown
              :options="[
                { label: 'Term Charter', value: 'WEEK' },
                { label: 'Day Charter', value: 'DAY' },
              ]"
              :selected="displayCharterType(props.variant.pricing?.unit)"
              @select:item="updateCharterType($event)"
            />
          </div> -->
          <!-- Label -->
          <FloatingInput
            id="label"
            type="text"
            name="label"
            class="self-end"
            placeholder=" "
            label="Display Pricing Name"
            :value="variant.label"
            @update:input="updateVariant('label', $event)"
          />
          <!-- Finish button -->
          <a
            type="button"
            class="flex h-min cursor-pointer items-center py-2 px-3 text-xs font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 focus:dark:ring-gray-500 dark:hover:text-white dark:hover:bg-gray-700"
            @click.stop="isVariantInfoOpen = false"
          >
            Finish
          </a>
        </div>

        <!-- Variant active status -->
        <div class="flex items-center">
          <label class="toggle relative inline-flex gap-x-2 items-center cursor-pointer">
            <input type="checkbox" class="sr-only peer" :checked="variant.active" @click.stop="updateVariantActive" />
            <div
              class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
            ></div>
            <span class="text-sm font-medium">{{ variant.active ? 'Active' : 'Inactive' }}</span>
          </label>
        </div>

        <!-- Variant Description -->
        <div class="flex flex-col gap-y-3">
          <h5 class="font-bold text-black dark:text-white">Description</h5>
          <textarea
            rows="4"
            id="variant-description"
            placeholder="Enter the Description"
            class="block py-3 px-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :value="props.variant.description"
            @blur="updateVariant('description', $event)"
          ></textarea>
        </div>

        <!-- Variant Pets Allowed -->
        <div class="flex items-center gap-x-2">
          <input
            type="checkbox"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            :checked="variant.petsAllowed"
            :id="`pets-allowed-${variant.id}-${variant.label}`"
            @click.stop="updateVariantPetsAllowed"
          />
          <label class="text-sm text-black dark:text-white" :for="`pets-allowed-${variant.id}-${variant.label}`">
            Pets allowed
          </label>
        </div>

        <!-- Pricing -->
        <div class="flex flex-col gap-y-3">
          <h5 class="font-bold text-black dark:text-white">Pricing</h5>
          <PricingVue :pricing="(variant.pricing || {} as Pricing)" @update:pricing="updateVariantPricing($event)" />
        </div>
        <!-- Pricing ends -->
        <!-- Pricing period -->
        <div class="flex flex-col gap-y-3">
          <h4 class="block font-semibold text-gray-900 dark:text-white">Pricing Period</h4>
          <template
            v-for="(interval, intervalIndex) of variant.intervals"
            :key="`${props.index}-interval-${intervalIndex}`"
          >
            <!-- Period date range -->
            <div class="flex items-center gap-x-4">
              <DateRangePicker
                :show-to-text="true"
                :end-date="getEndDate(interval)"
                :start-date="getStartDate(interval)"
                @update:start:and:end="updateVariantInterval(intervalIndex, $event)"
              />
              <OutlineTrash
                class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
                @click.stop="deleteInterval(intervalIndex)"
              />
            </div>
            <!-- Checkbox to repeat annually -->
            <div class="flex items-center">
              <input
                type="checkbox"
                :id="`pricing-available-checkbox-${intervalIndex}`"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                :checked="isChecked(interval)"
                :disabled="isCheckboxDisabled(intervalIndex)"
                :class="{ 'opacity-50 cursor-not-allowed': isCheckboxDisabled(intervalIndex) }"
                @click.stop="updateAnnualRecurrence(intervalIndex, $event)"
              />
              <label
                :for="`pricing-available-checkbox-${intervalIndex}`"
                class="ml-2 text-xs text-gray-600 dark:text-gray-400"
              >
                Repeats every year
              </label>
            </div>
          </template>
          <!-- Add another interval -->
          <ButtonVue
            class="h-fit w-min"
            name="+ Add another period"
            :isPrimary="false"
            :has-border="true"
            @click.stop="addInterval()"
          ></ButtonVue>
        </div>

        <!-- Operating areas -->
        <div class="flex flex-col gap-y-3">
          <div class="flex gap-x-2.5 items-center">
            <h5 class="font-bold text-black dark:text-white">Operating Area</h5>
            <button
              type="button"
              data-popover-placement="right"
              :data-popover-target="`operating-area-popover-${props.index}`"
            >
              <SolidInformationCircle class="fill-gray-400 h-5 w-5 shrink-0" />
            </button>
            <div
              data-popover
              role="tooltip"
              :id="`operating-area-popover-${props.index}`"
              class="absolute w-[27rem] z-50 invisible inline-block whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
            >
              <div class="rounded-lg overflow-hidden">
                <div class="flex">
                  <div class="p-3 gap-y-1 w-3/5">
                    <h5 class="font-bold text-black dark:text-white">Operating Area</h5>
                    <p class="text-sm text-gray-500 dark:text-gray-400">
                      This field is strongly recommended. Inclusion zones specify where geographically, your pricing
                      applies. Choose a general region and use exclusion zones to refine it. Add multiple zones to
                      highlight where your yacht appears in search results.
                    </p>
                  </div>
                  <div class="w-2/5">
                    <img class="object-cover h-60" alt="Map image" :src="OperatingAreaImage" />
                  </div>
                </div>
              </div>
              <div data-popper-arrow></div>
            </div>
          </div>

          <!-- Geo Filters -->
          <h6 class="text-xs font-medium text-gray-black dark:text-white">Inclusion Zone</h6>
          <div class="md:col-span-3">
            <GeoSearchInput
              operation-type="union"
              :geo-tags="variant.geoFilters || []"
              @update:geo="updateVariantGeo($event)"
            />
          </div>

          <h6 class="text-xs font-medium text-gray-black dark:text-white">Exclusion Zone</h6>
          <div class="md:col-span-3">
            <GeoSearchInput
              operation-type="intersection"
              :geo-tags="variant.geoFilters || []"
              @update:geo="updateVariantGeo($event)"
            />
          </div>
        </div>

        <div class="flex flex-col gap-y-3">
          <h5 class="text-sm font-bold text-black dark:text-white">Available Toys & Amenities</h5>
          <div class="flex items-center">
            <label
              class="toggle relative inline-flex gap-x-2 items-center cursor-pointer"
              @click.prevent="updateDefaultToysAmenities()"
            >
              <input type="checkbox" class="sr-only peer" :checked="availableToysAmenities" />
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
              ></div>
              <span class="text-sm font-medium">Same as default</span>
            </label>
          </div>
        </div>

        <template v-if="!availableToysAmenities">
          <!-- Toys -->
          <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
            <h5 class="font-bold text-black dark:text-white">Toys</h5>
            <div class="flex">
              <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Toy name</p>
              <p class="w-28 block text-sm font-medium text-gray-900 dark:text-white">Quantity</p>
            </div>
            <div class="flex flex-col gap-y-4">
              <div
                v-for="(toy, index) in props.variant.toys"
                class="flex gap-x-4 items-center"
                :key="`${index}-${toy.label}-${toy.quantity}`"
              >
                <StandardInput
                  type="search"
                  class="w-full"
                  :value="toy.label"
                  :id="`vessel-toy-${index}-${toy.label}-${toy.quantity}`"
                  @update:input="updateItem(index, 'toys', 'label', $event)"
                />
                <StandardInput
                  class="w-20"
                  type="number"
                  :step="1"
                  :value="Number(toy.quantity)"
                  :id="`vessel-toy-qty-${index}-${toy.label}-${toy.quantity}`"
                  @update:input="updateItem(index, 'toys', 'quantity', $event)"
                />
                <OutlineTrash
                  class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
                  @click.stop="removeItem(index, 'toys')"
                />
              </div>
              <ButtonVue
                class="w-min"
                position="prefix"
                :is-primary="false"
                :has-border="true"
                :name="`Add ${props.variant.toys?.length ? 'Another' : ''} Toy`"
                @click.stop="addNewItem('toys')"
              >
                <SolidPlus class="w-4 h-4 fill-primary-600 dark:fill-primary-500" />
              </ButtonVue>
            </div>
          </div>

          <!-- Amenities -->
          <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
            <h5 class="font-bold text-black dark:text-white">Amenities</h5>
            <div class="flex">
              <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Amenity name</p>
              <p class="w-28 block text-sm font-medium text-gray-900 dark:text-white">Quantity</p>
            </div>
            <div class="flex flex-col gap-y-4">
              <div
                v-for="(amenity, index) in props.variant.amenities"
                class="flex gap-x-4 items-center"
                :key="`${index}-${amenity.label}-${amenity.quantity}`"
              >
                <StandardInput
                  type="search"
                  class="w-full"
                  :value="amenity.label"
                  :id="`vessel-amenity-${index}-${amenity.label}-${amenity.quantity}`"
                  @update:input="updateItem(index, 'amenities', 'label', $event)"
                />
                <StandardInput
                  class="w-20"
                  type="number"
                  :step="1"
                  :value="Number(amenity.quantity)"
                  :id="`vessel-amenity-qty-${index}-${amenity.label}-${amenity.quantity}`"
                  @update:input="updateItem(index, 'amenities', 'quantity', $event)"
                />
                <OutlineTrash
                  class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
                  @click.stop="removeItem(index, 'amenities')"
                />
              </div>
              <ButtonVue
                class="w-min"
                position="prefix"
                :is-primary="false"
                :has-border="true"
                :name="`Add ${props.variant.amenities?.length ? 'Another' : ''} Amenity`"
                @click.stop="addNewItem('amenities')"
              >
                <SolidPlus class="w-4 h-4 fill-primary-600 dark:fill-primary-500" />
              </ButtonVue>
            </div>
          </div>

          <!-- Tenders -->
          <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
            <h5 class="font-bold text-black dark:text-white">Tenders</h5>
            <div class="flex">
              <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Tender name</p>
            </div>
            <div class="flex flex-col gap-y-4">
              <div
                v-for="(tender, index) in props.variant.tenders"
                class="flex gap-x-4 items-center"
                :key="`${index}-${tender}`"
              >
                <StandardInput
                  type="search"
                  class="w-full"
                  :value="tender"
                  :id="`vessel-tender-${index}-${tender}`"
                  @update:input="updateItem(index, 'tenders', null, $event)"
                />
                <OutlineTrash
                  class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
                  @click.stop="removeItem(index, 'tenders')"
                />
              </div>
              <ButtonVue
                class="w-min"
                position="prefix"
                :is-primary="false"
                :has-border="true"
                :name="`Add ${props.variant.tenders?.length ? 'Another' : ''} Tender`"
                @click.stop="addNewItem('tenders')"
              >
                <SolidPlus class="w-4 h-4 fill-primary-600 dark:fill-primary-500" />
              </ButtonVue>
            </div>
          </div>

          <!-- Entertainment -->
          <div class="flex flex-col gap-y-2.5 p-4 border rounded-lg shadow-md border-gray-200 dark:border-gray-600">
            <h5 class="font-bold text-black dark:text-white">Entertainment</h5>
            <div class="flex">
              <p class="w-full block text-sm font-medium text-gray-900 dark:text-white">Entertainment name</p>
            </div>
            <div class="flex flex-col gap-y-4">
              <div
                v-for="(entertainment, index) in props.variant.entertainment"
                class="flex gap-x-4 items-center"
                :key="`${index}-${entertainment}`"
              >
                <StandardInput
                  type="search"
                  class="w-full"
                  :value="entertainment"
                  :id="`vessel-entertainment-${index}-${entertainment}`"
                  @update:input="updateItem(index, 'entertainment', null, $event)"
                />
                <OutlineTrash
                  class="w-4 h-4 shrink-0 cursor-pointer transition-all text-red-600 dark:text-red-500 hover:text-red-700 hover:dark:text-red-400"
                  @click.stop="removeItem(index, 'entertainment')"
                />
              </div>
              <ButtonVue
                class="w-min"
                position="prefix"
                :is-primary="false"
                :has-border="true"
                :name="`Add ${props.variant.entertainment?.length ? 'Another' : ''} Entertainment`"
                @click.stop="addNewItem('entertainment')"
              >
                <SolidPlus class="w-4 h-4 fill-primary-600 dark:fill-primary-500" />
              </ButtonVue>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
