import { DateTime } from 'luxon'

import { DateValue } from '@ankor-io/common/calendar/types'

// TODO: Unit test
export const getWeekRangeText = (dateValue: DateValue): string => {
  const weekStartDate = DateTime.fromObject({
    weekNumber: dateValue.week,
    weekYear: dateValue.year,
  })

  const weekEndDate = weekStartDate.plus({ days: 6 })

  const spansYears = weekEndDate.year !== dateValue.year

  if (!spansYears) {
    return `${weekStartDate.day} ${weekStartDate.monthShort} - ${weekEndDate.day} ${weekEndDate.monthShort} ${weekEndDate.year}`
  } else {
    return `${weekStartDate.day} ${weekStartDate.monthShort} ${weekStartDate.year} - ${weekEndDate.day} ${weekEndDate.monthShort} ${weekEndDate.year}`
  }
}
