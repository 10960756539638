import { VesselIndexItem } from '../index/VesselIndexItem'
import { ObjectUtil } from '../lang/objectUtil'
import { getLowestPricingItem } from './lowest-price-variant'
import { Blueprint, Unit, Variant, Vessel } from './types'

export type SortableFields = keyof Blueprint | keyof VesselIndexItem | keyof Vessel | Unit | ''

export const sortVessels = (
  arrayToSort: VesselIndexItem[],
  sortBy: { field: SortableFields; order: number },
  rates: { [key: string]: string },
) => {
  const COMPANY = 'line_7'
  const LENGTH = 'line_2'
  const arr = ObjectUtil.deepCopy(arrayToSort)
  switch (sortBy.field) {
    // vessel blueprint
    case 'name':
    case 'cabins':
    case 'cruiseSpeed':
    case 'bathrooms':
      const blueprintField = sortBy.field as keyof Blueprint
      arr.sort((a: VesselIndexItem, b: VesselIndexItem) => {
        const aBlueprint: any = a.vessel.blueprint[blueprintField]
        const bBlueprint = b.vessel.blueprint[blueprintField]

        let result
        if (aBlueprint! < bBlueprint!) {
          result = -1
        } else if (aBlueprint! > bBlueprint!) {
          result = 1
        } else {
          result = 0
        }

        return result * sortBy.order
      })

      break
    // vessel index item
    case COMPANY:
    case LENGTH:
      const vesselIndexField = sortBy.field as keyof VesselIndexItem
      arr.sort((a: VesselIndexItem, b: VesselIndexItem) => {
        const aIndexItem = a[vesselIndexField]
        const bIndexItem = b[vesselIndexField]

        let result
        if (aIndexItem! < bIndexItem!) {
          result = -1
        } else if (aIndexItem! > bIndexItem!) {
          result = 1
        } else {
          result = 0
        }

        return result * sortBy.order
      })
      break
    // vessel field
    case 'category':
      const vesselField = sortBy.field as keyof Vessel
      arr.sort((a: VesselIndexItem, b: VesselIndexItem) => {
        const aVesselField = a.vessel[vesselField]
        const bVesselField = b.vessel[vesselField]

        let result
        if (aVesselField! < bVesselField!) {
          result = -1
        } else if (aVesselField! > bVesselField!) {
          result = 1
        } else {
          result = 0
        }

        return result * sortBy.order
      })
      break
    // vessel variant unit
    case 'DAY':
    case 'WEEK':
    case 'HOUR':
      arr.sort((a: VesselIndexItem, b: VesselIndexItem) => {
        const aValidVariants = a.vessel.variants.filter(
          (variant: Variant) => variant.active && variant.pricing?.total! > 0 && variant.pricing?.currency,
        )
        const bValidVariants = b.vessel.variants.filter(
          (variant: Variant) => variant.active && variant.pricing?.total! > 0 && variant.pricing?.currency,
        )

        let result, lowestPriceA, lowestPriceB
        if (sortBy.field === 'DAY') {
          const aDayVariants = aValidVariants.filter((variant: Variant) => variant.pricing?.unit === 'DAY')
          const bDayVariants = bValidVariants.filter((variant: Variant) => variant.pricing?.unit === 'DAY')
          lowestPriceA = getLowestPricingItem(aDayVariants, rates)
          lowestPriceB = getLowestPricingItem(bDayVariants, rates)
        } else if (sortBy.field === 'WEEK') {
          const aWeekVariants = aValidVariants.filter((variant: Variant) => variant.pricing?.unit === 'WEEK')
          const bWeekVariants = bValidVariants.filter((variant: Variant) => variant.pricing?.unit === 'WEEK')
          lowestPriceA = getLowestPricingItem(aWeekVariants, rates)
          lowestPriceB = getLowestPricingItem(bWeekVariants, rates)
        } else if (sortBy.field === 'HOUR') {
          const aHourVariants = aValidVariants.filter((variant: Variant) => variant.pricing?.unit === 'HOUR')
          const bHourVariants = bValidVariants.filter((variant: Variant) => variant.pricing?.unit === 'HOUR')
          lowestPriceA = getLowestPricingItem(aHourVariants, rates)
          lowestPriceB = getLowestPricingItem(bHourVariants, rates)
        }

        if (lowestPriceA?.price! < lowestPriceB?.price!) {
          result = -1
        } else if (lowestPriceA?.price! > lowestPriceB?.price!) {
          result = 1
        } else {
          result = 0
        }

        return result * sortBy.order
      })
      break
    default:
      throw new Error(`Not configured to sort for field: ${sortBy.field}`)
  }

  return arr
}
