<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import { QuantityType, QuantityTypeValues } from '@ankor-io/common/vessel/types'

import Button from '@/components/Button.vue'

export type QuantityProps = {
  displayQuantityAsPercent: boolean
  quantity: string
}

const props = defineProps<QuantityProps>()
const emit = defineEmits<{
  (e: 'close:modal'): void
  (e: 'confirm:modal', value: QuantityProps): void
}>()

const refQuantityType: Ref<QuantityType> = ref(QuantityTypeValues.PERCENTAGE)
const refQuantity: Ref<string> = ref('')

const getQuantityType = (displayQuantityAsPercent: boolean, quantity: string): QuantityType => {
  if (displayQuantityAsPercent) {
    return QuantityTypeValues.PERCENTAGE
  }
  return Number(quantity) >= 1 ? QuantityTypeValues.AMOUNT : QuantityTypeValues.PERCENTAGE
}

const onConfirm = () => {
  emit('confirm:modal', {
    displayQuantityAsPercent: refQuantityType.value === QuantityTypeValues.PERCENTAGE,
    quantity: Number(refQuantity.value).toFixed(2),
  })
}

onMounted(() => {
  refQuantityType.value = getQuantityType(props.displayQuantityAsPercent, props.quantity)
  refQuantity.value = (
    refQuantityType.value === QuantityTypeValues.PERCENTAGE
      ? Math.round(Number(props.quantity) * 100 * 100) / 100
      : props.quantity
  ).toString()
})
</script>
<template>
  <div class="h-auto w-auto flex flex-col print:hidden dark:text-gray-200">
    <!-- Modal header -->
    <h3 class="text-lg text-left font-semibold">Update Quantity:</h3>
    <!-- Modal body -->
    <div class="flex flex-col space-y-4 mt-4">
      <ul class="flex items-center text-xs font-medium space-x-4">
        <li>Quantity in:</li>
        <li>
          <input
            type="radio"
            id="percentage"
            name="quantityType"
            v-model="refQuantityType"
            class="w-4 h-4 focus:ring-blue-500 focus:ring-2"
            value="percentage"
          />
          <label for="percentage" class="py-3 ml-2">PERCENTAGE</label>
        </li>
        <li>
          <input
            type="radio"
            id="amount"
            name="quantityType"
            v-model="refQuantityType"
            class="w-4 h-4 focus:ring-blue-500 focus:ring-2"
            value="amount"
          />
          <label for="amount" class="py-3 ml-2">UNITS</label>
        </li>
      </ul>

      <div class="w-80 relative">
        <input
          type="number"
          id="floating_outlined_quantity"
          class="block px-2.5 pb-2 pt-2.5 w-full text-sm bg-transparent rounded-lg border-1 appearance-none focus:outline-none focus:ring-0 peer"
          placeholder=" "
          v-model="refQuantity"
        />
        <label
          for="floating_outlined_quantity"
          class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-800 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
          @click=";($el.querySelector('#floating_outlined_quantity') as HTMLInputElement).focus()"
        >
          Quantity {{ refQuantityType === QuantityTypeValues.PERCENTAGE ? '(%)' : '' }}
        </label>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="flex justify-end items-center gap-x-2 rounded-b pt-5">
      <Button name="Cancel" :isPrimary="false" @click="emit('close:modal')"></Button>
      <Button name="Save" isPrimary @click="onConfirm" :disabled="refQuantity === ''"></Button>
    </div>
  </div>
</template>
