import { EventType, EventTypeEnums } from '@ankor-io/common/vessel/types'

type BlockType = EventType | 'Active special' | 'Expired special'

/**
 *
 * @param blockType The event type
 * @returns A darker version of the background colour. This is intended to represent an event solely based on color.
 */
export const eventRepresentationalBackgroundColour = (blockType: BlockType): string => {
  const eventColours = {
    [EventTypeEnums.BOOKED]: 'bg-primary-500 dark:bg-primary-600',
    [EventTypeEnums.TENTATIVE]: 'bg-pink-500 dark:bg-pink-600',
    [EventTypeEnums.MAINTENANCE]: 'bg-red-500 dark:bg-red-600',
    [EventTypeEnums.IN_TRANSIT]: 'bg-green-500 dark:bg-green-600',
    [EventTypeEnums.FLEXIBLE_USE]: 'bg-yellow-300 dark:bg-yellow-400',
    [EventTypeEnums.BOAT_SHOW]: 'bg-teal-500 dark:bg-teal-600',
    [EventTypeEnums.OTHER]: 'bg-indigo-500 dark:bg-indigo-600',
    'Active special': 'bg-purple-100 dark:bg-gray-700',
    'Expired special': 'bg-gray-100 dark:bg-gray-600',
    Default: 'bg-red-500 dark:bg-red-600',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered: 'bg-primary-500 dark:bg-primary-600',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break': 'bg-teal-500 dark:bg-teal-600',
  }

  if (
    blockType === EventTypeEnums.BOOKED ||
    blockType === EventTypeEnums.TENTATIVE ||
    blockType === EventTypeEnums.MAINTENANCE ||
    blockType === EventTypeEnums.IN_TRANSIT ||
    blockType === EventTypeEnums.FLEXIBLE_USE ||
    blockType === EventTypeEnums.BOAT_SHOW ||
    blockType === EventTypeEnums.OTHER ||
    blockType === 'Active special' ||
    blockType === 'Expired special' ||
    // @deprecated - only for backward compatibility of 'Chartered'
    blockType === ('Chartered' as EventTypeEnums) ||
    // @deprecated - only for backward compatibility of 'Crew break'
    blockType === ('Crew break' as EventTypeEnums)
  )
    return eventColours[blockType]

  return eventColours['Default']
}

/**
 *
 * @param blockType The event type
 * @param isInteractable If the event can be interacted with (hover/focus/active etc)
 * @returns The background colour for an blockType. normal bg, hover, focus, active, group-hover, group-focus, group-active, dark
 */
export const eventBackgroundColour = (blockType: BlockType, isInteractable: boolean): string => {
  const eventColours = {
    [EventTypeEnums.BOOKED]: 'bg-primary-100 dark:bg-primary-200',
    [EventTypeEnums.TENTATIVE]: 'bg-pink-100 dark:bg-pink-200',
    [EventTypeEnums.MAINTENANCE]: 'bg-red-100 dark:bg-red-200',
    [EventTypeEnums.IN_TRANSIT]: 'bg-green-100 dark:bg-green-200',
    [EventTypeEnums.FLEXIBLE_USE]: 'bg-yellow-100 dark:bg-yellow-200',
    [EventTypeEnums.BOAT_SHOW]: 'bg-teal-100 dark:bg-teal-200',
    [EventTypeEnums.OTHER]: 'bg-indigo-100 dark:bg-indigo-200',
    'Active special': 'bg-purple-100 dark:bg-gray-700',
    'Expired special': 'bg-gray-100 dark:bg-gray-600',
    Default: 'bg-red-100 dark:bg-red-200',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered: 'bg-primary-100 dark:bg-primary-200',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break': 'bg-teal-100 dark:bg-teal-200',
  }

  const interactableEventColours = {
    [EventTypeEnums.BOOKED]:
      'hover:bg-primary-200 focus:bg-primary-300 active:bg-primary-300 group-hover:bg-primary-200 group-focus:bg-primary-300 group-active:bg-primary-300 dark:hover:bg-primary-300 dark:focus:bg-primary-400 dark:active:bg-primary-400 dark:group-hover:bg-primary-300 dark:group-focus:bg-primary-400 dark:group-active:bg-primary-400',
    [EventTypeEnums.TENTATIVE]:
      'hover:bg-pink-200 focus:bg-pink-300 active:bg-pink-300 group-hover:bg-pink-200 group-focus:bg-pink-300 group-active:bg-pink-300 dark:hover:bg-pink-300 dark:focus:bg-pink-400 dark:active:bg-pink-400 dark:group-hover:bg-pink-300 dark:group-focus:bg-pink-400 dark:group-active:bg-pink-400',
    [EventTypeEnums.MAINTENANCE]:
      'hover:bg-red-200 focus:bg-red-300 active:bg-red-300 group-hover:bg-red-200 group-focus:bg-red-300 group-active:bg-red-300 dark:hover:bg-red-300 dark:focus:bg-red-400 dark:active:bg-red-400 dark:group-hover:bg-red-300 dark:group-focus:bg-red-400 dark:group-active:bg-red-400',
    [EventTypeEnums.IN_TRANSIT]:
      'hover:bg-green-200 focus:bg-green-300 active:bg-green-300 group-hover:bg-green-200 group-focus:bg-green-300 group-active:bg-green-300 dark:hover:bg-green-300 dark:focus:bg-green-400 dark:active:bg-green-400 dark:group-hover:bg-green-300 dark:group-focus:bg-green-400 dark:group-active:bg-green-400',
    [EventTypeEnums.FLEXIBLE_USE]:
      'hover:bg-yellow-200 focus:bg-yellow-300 active:bg-yellow-300 group-hover:bg-yellow-200 group-focus:bg-yellow-300 group-active:bg-yellow-300 dark:hover:bg-yellow-300 dark:focus:bg-yellow-400 dark:active:bg-yellow-400 dark:group-hover:bg-yellow-300 dark:group-focus:bg-yellow-400 dark:group-active:bg-yellow-400',
    [EventTypeEnums.BOAT_SHOW]:
      'hover:bg-teal-200 focus:bg-teal-300 active:bg-teal-300 group-hover:bg-teal-200 group-focus:bg-teal-300 group-active:bg-teal-300 dark:hover:bg-teal-300 dark:focus:bg-teal-400 dark:active:bg-teal-400 dark:group-hover:bg-teal-300 dark:group-focus:bg-teal-400 dark:group-active:bg-teal-400',
    [EventTypeEnums.OTHER]:
      'hover:bg-indigo-200 focus:bg-indigo-300 active:bg-indigo-300 group-hover:bg-indigo-200 group-focus:bg-indigo-300 group-active:bg-indigo-300 dark:hover:bg-indigo-300 dark:focus:bg-indigo-400 dark:active:bg-indigo-400 dark:group-hover:bg-indigo-300 dark:group-focus:bg-indigo-400 dark:group-active:bg-indigo-400',
    'Active special':
      'hover:bg-purple-200 focus:bg-purple-300 active:bg-purple-300 group-hover:bg-purple-200 group-focus:bg-purple-300 group-active:bg-purple-300 dark:hover:bg-gray-800 dark:focus:bg-gray-900 dark:active:bg-gray-900 dark:group-hover:bg-gray-800 dark:group-active:bg-gray-900 dark:group-focus:bg-gray-900',
    'Expired special':
      'hover:bg-gray-200 focus:bg-gray-300 active:bg-gray-300 group-hover:bg-gray-200 group-focus:bg-gray-300 group-active:bg-gray-300 dark:hover:bg-gray-700 dark:active:bg-gray-800 dark:focus:bg-gray-800 dark:group-hover:bg-gray-700 dark:group-active:bg-gray-800 dark:group-focus:bg-gray-800',
    Default:
      'hover:bg-red-200 focus:bg-red-300 active:bg-red-300 group-hover:bg-red-200 group-focus:bg-red-300 group-active:bg-red-300 dark:hover:bg-red-300 dark:focus:bg-red-400 dark:active:bg-red-400 dark:group-hover:bg-red-300 dark:group-focus:bg-red-400 dark:group-active:bg-red-400',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered:
      'hover:bg-primary-200 focus:bg-primary-300 active:bg-primary-300 group-hover:bg-primary-200 group-focus:bg-primary-300 group-active:bg-primary-300 dark:hover:bg-primary-300 dark:focus:bg-primary-400 dark:active:bg-primary-400 dark:group-hover:bg-primary-300 dark:group-focus:bg-primary-400 dark:group-active:bg-primary-400',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break':
      'hover:bg-teal-200 focus:bg-teal-300 active:bg-teal-300 group-hover:bg-teal-200 group-focus:bg-teal-300 group-active:bg-teal-300 dark:hover:bg-teal-300 dark:focus:bg-teal-400 dark:active:bg-teal-400 dark:group-hover:bg-teal-300 dark:group-focus:bg-teal-400 dark:group-active:bg-teal-400',
  }

  if (
    blockType === EventTypeEnums.BOOKED ||
    blockType === EventTypeEnums.TENTATIVE ||
    blockType === EventTypeEnums.MAINTENANCE ||
    blockType === EventTypeEnums.IN_TRANSIT ||
    blockType === EventTypeEnums.FLEXIBLE_USE ||
    blockType === EventTypeEnums.BOAT_SHOW ||
    blockType === EventTypeEnums.OTHER ||
    blockType === 'Active special' ||
    blockType === 'Expired special' ||
    // @deprecated - only for backward compatibility of 'Chartered'
    blockType === ('Chartered' as EventTypeEnums) ||
    // @deprecated - only for backward compatibility of 'Crew break'
    blockType === ('Crew break' as EventTypeEnums)
  ) {
    return isInteractable
      ? `${eventColours[blockType]} ${interactableEventColours[blockType]}`
      : eventColours[blockType]
  }

  return isInteractable ? `${eventColours['Default']} ${interactableEventColours['Default']}` : eventColours['Default']
}

/**
 *
 * @param blockType The event type
 * @param isInteractable If the event can be interacted with (hover/focus/active etc)
 * @returns The text colour for an blockType. normal text, hover, focus, active, group-hover, group-focus, group-active
 */
export const eventTextColour = (blockType: BlockType, isInteractable: boolean): string => {
  const eventColours = {
    [EventTypeEnums.BOOKED]: 'text-primary-700 dark:text-primary-800',
    [EventTypeEnums.TENTATIVE]: 'text-pink-700 dark:text-pink-800',
    [EventTypeEnums.MAINTENANCE]: 'text-red-700 dark:text-red-800',
    [EventTypeEnums.IN_TRANSIT]: 'text-green-700 dark:text-green-800',
    [EventTypeEnums.FLEXIBLE_USE]: 'text-yellow-700 dark:text-yellow-800',
    [EventTypeEnums.BOAT_SHOW]: 'text-teal-700 dark:text-teal-800',
    [EventTypeEnums.OTHER]: 'text-indigo-700 dark:text-indigo-800',
    'Active special': 'text-purple-700 dark:text-purple-400',
    'Expired special': 'text-gray-800 dark:text-gray-300',
    Default: 'text-red-700 dark:text-red-800',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered: 'text-primary-700 dark:text-primary-800',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break': 'text-teal-700 dark:text-teal-800',
  }

  const interactableEventColours = {
    [EventTypeEnums.BOOKED]:
      'hover:text-primary-800 focus:text-primary-800 active:text-primary-800 group-hover:text-primary-800 group-focus:text-primary-800 group-active:text-primary-800 dark:hover:text-primary-900 dark:focus:text-primary-900 dark:active:text-primary-900 dark:group-hover:text-primary-900 dark:group-focus:text-primary-900 dark:group-active:text-primary-900',
    [EventTypeEnums.TENTATIVE]:
      'hover:text-pink-800 focus:text-pink-800 active:text-pink-800 group-hover:text-pink-800 group-focus:text-pink-800 group-active:text-pink-800 dark:hover:text-pink-900 dark:focus:text-pink-900 dark:active:text-pink-900 dark:group-hover:text-pink-900 dark:group-focus:text-pink-900 dark:group-active:text-pink-900',
    [EventTypeEnums.MAINTENANCE]:
      'hover:text-red-800 focus:text-red-800 active:text-red-800 group-hover:text-red-800 group-focus:text-red-800 group-active:text-red-800 dark:hover:text-red-900 dark:focus:text-red-900 dark:active:text-red-900 dark:group-hover:text-red-900 dark:group-focus:text-red-900 dark:group-active:text-red-900',
    [EventTypeEnums.IN_TRANSIT]:
      'hover:text-green-800 focus:text-green-800 active:text-green-800 group-hover:text-green-800 group-focus:text-green-800 group-active:text-green-800 dark:hover:text-green-900 dark:focus:text-green-900 dark:active:text-green-900 dark:group-hover:text-green-900 dark:group-focus:text-green-900 dark:group-active:text-green-900',
    [EventTypeEnums.FLEXIBLE_USE]:
      'hover:text-yellow-800 focus:text-yellow-800 active:text-yellow-800 group-hover:text-yellow-800 group-focus:text-yellow-800 group-active:text-yellow-800 dark:hover:text-yellow-900 dark:focus:text-yellow-900 dark:active:text-yellow-900 dark:group-hover:text-yellow-900 dark:group-focus:text-yellow-900 dark:group-active:text-yellow-900',
    [EventTypeEnums.BOAT_SHOW]:
      'hover:text-teal-800 focus:text-teal-800 active:text-teal-800 group-hover:text-teal-800 group-focus:text-teal-800 group-active:text-teal-800 dark:hover:text-teal-900 dark:focus:text-teal-900 dark:active:text-teal-900 dark:group-hover:text-teal-900 dark:group-focus:text-teal-900 dark:group-active:text-teal-900',
    [EventTypeEnums.OTHER]:
      'hover:text-indigo-800 focus:text-indigo-800 active:text-indigo-800 group-hover:text-indigo-800 group-focus:text-indigo-800 group-active:text-indigo-800 dark:hover:text-indigo-900 dark:focus:text-indigo-900 dark:active:text-indigo-900 dark:group-hover:text-indigo-900 dark:group-focus:text-indigo-900 dark:group-active:text-indigo-900',
    'Active special':
      'hover:text-purple-800 focus:text-purple-800 active:text-purple-800 group-hover:text-purple-800 group-focus:text-purple-800 group-active:text-purple-800 dark:hover:text-purple-500 dark:focus:text-purple-500 dark:active:text-purple-500 dark:group-hover:text-purple-500 dark:group-focus:text-purple-500 dark:group-active:text-purple-500',
    'Expired special':
      'hover:text-gray-900 focus:text-gray-900 active:text-gray-900 group-hover:text-gray-900 group-focus:text-gray-900 group-active:text-gray-900 dark:hover:text-gray-200 dark:focus:text-gray-200 dark:active:text-gray-200 dark:group-hover:text-gray-200 dark:group-focus:text-gray-200 dark:group-active:text-gray-200',
    Default:
      'hover:text-red-800 focus:text-red-800 active:text-red-800 group-hover:text-red-800 group-focus:text-red-800 group-active:text-red-800 dark:hover:text-red-900 dark:focus:text-red-900 dark:active:text-red-900 dark:group-hover:text-red-900 dark:group-focus:text-red-900 dark:group-active:text-red-900',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered:
      'hover:text-primary-800 focus:text-primary-800 active:text-primary-800 group-hover:text-primary-800 group-focus:text-primary-800 group-active:text-primary-800 dark:hover:text-primary-900 dark:focus:text-primary-900 dark:active:text-primary-900 dark:group-hover:text-primary-900 dark:group-focus:text-primary-900 dark:group-active:text-primary-900',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break':
      'hover:text-teal-800 focus:text-teal-800 active:text-teal-800 group-hover:text-teal-800 group-focus:text-teal-800 group-active:text-teal-800 dark:hover:text-teal-900 dark:focus:text-teal-900 dark:active:text-teal-900 dark:group-hover:text-teal-900 dark:group-focus:text-teal-900 dark:group-active:text-teal-900',
  }

  if (
    blockType === EventTypeEnums.BOOKED ||
    blockType === EventTypeEnums.TENTATIVE ||
    blockType === EventTypeEnums.MAINTENANCE ||
    blockType === EventTypeEnums.IN_TRANSIT ||
    blockType === EventTypeEnums.FLEXIBLE_USE ||
    blockType === EventTypeEnums.BOAT_SHOW ||
    blockType === EventTypeEnums.OTHER ||
    blockType === 'Active special' ||
    blockType === 'Expired special' ||
    // @deprecated - only for backward compatibility of 'Chartered'
    blockType === ('Chartered' as EventTypeEnums) ||
    // @deprecated - only for backward compatibility of 'Crew break'
    blockType === ('Crew break' as EventTypeEnums)
  ) {
    return isInteractable
      ? `${eventColours[blockType]} ${interactableEventColours[blockType]}`
      : eventColours[blockType]
  }

  return isInteractable ? `${eventColours['Default']} ${interactableEventColours['Default']}` : eventColours['Default']
}

/**
 *
 * @param eventType The event type
 * @param isInteractable If the event can be interacted with (hover/focus/active etc)
 * @returns The edge colour (sides of an event pill on the calendar) for an eventType. normal edge, hover, focus, active, group-hover, group-focus, group-active
 */
export const eventEdgeColour = (eventType: EventType, isInteractable: boolean): string => {
  const eventColours = {
    [EventTypeEnums.BOOKED]: 'bg-primary-300 dark:bg-primary-400',
    [EventTypeEnums.TENTATIVE]: 'bg-pink-300 dark:bg-pink-400',
    [EventTypeEnums.MAINTENANCE]: 'bg-red-300 dark:bg-red-400',
    [EventTypeEnums.IN_TRANSIT]: 'bg-green-300 dark:bg-green-400',
    [EventTypeEnums.FLEXIBLE_USE]: 'bg-yellow-300 dark:bg-yellow-400',
    [EventTypeEnums.BOAT_SHOW]: 'bg-teal-300 dark:bg-teal-400',
    [EventTypeEnums.OTHER]: 'bg-indigo-300 dark:bg-indigo-400',
    Default: 'bg-red-300 dark:bg-red-400',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered: 'bg-primary-300 dark:bg-primary-400',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break': 'bg-teal-300 dark:bg-teal-400',
  }

  const interactableEventColours = {
    [EventTypeEnums.BOOKED]:
      'hover:bg-primary-400 active:bg-primary-500 focus:bg-primary-500 group-hover:bg-primary-400 group-active:bg-primary-500 group-focus:bg-primary-500 dark:hover:bg-primary-500 dark:active:bg-primary-600 dark:focus:bg-primary-600 dark:group-hover:bg-primary-500 dark:group-active:bg-primary-600 dark:group-focus:bg-primary-600',
    [EventTypeEnums.TENTATIVE]:
      'hover:bg-pink-400 active:bg-pink-500 focus:bg-pink-500 group-hover:bg-pink-400 group-active:bg-pink-500 group-focus:bg-pink-500 dark:hover:bg-pink-500 dark:active:bg-pink-600 dark:focus:bg-pink-600 dark:group-hover:bg-pink-500 dark:group-active:bg-pink-600 dark:group-focus:bg-pink-600',
    [EventTypeEnums.MAINTENANCE]:
      'hover:bg-red-400 active:bg-red-500 focus:bg-red-500 group-hover:bg-red-400 group-active:bg-red-500 group-focus:bg-red-500 dark:hover:bg-red-500 dark:active:bg-red-600 dark:focus:bg-red-600 dark:group-hover:bg-red-500 dark:group-active:bg-red-600 dark:group-focus:bg-red-600',
    [EventTypeEnums.IN_TRANSIT]:
      'hover:bg-green-400 active:bg-green-500 focus:bg-green-500 group-hover:bg-green-400 group-active:bg-green-500 group-focus:bg-green-500 dark:hover:bg-green-500 dark:active:bg-green-600 dark:focus:bg-green-600 dark:group-hover:bg-green-500 dark:group-active:bg-green-600 dark:group-focus:bg-green-600',
    [EventTypeEnums.FLEXIBLE_USE]:
      'hover:bg-yellow-400 active:bg-yellow-500 focus:bg-yellow-500 group-hover:bg-yellow-400 group-active:bg-yellow-500 group-focus:bg-yellow-500 dark:hover:bg-yellow-500 dark:active:bg-yellow-600 dark:focus:bg-yellow-600 dark:group-hover:bg-yellow-500 dark:group-active:bg-yellow-600 dark:group-focus:bg-yellow-600',
    [EventTypeEnums.BOAT_SHOW]:
      'hover:bg-teal-400 active:bg-teal-500 focus:bg-teal-500 group-hover:bg-teal-400 group-active:bg-teal-500 group-focus:bg-teal-500 dark:hover:bg-teal-500 dark:active:bg-teal-600 dark:focus:bg-teal-600 dark:group-hover:bg-teal-500 dark:group-active:bg-teal-600 dark:group-focus:bg-teal-600',
    [EventTypeEnums.OTHER]:
      'hover:bg-indigo-400 active:bg-indigo-500 focus:bg-indigo-500 group-hover:bg-indigo-400 group-active:bg-indigo-500 group-focus:bg-indigo-500 dark:hover:bg-indigo-500 dark:active:bg-indigo-600 dark:focus:bg-indigo-600 dark:group-hover:bg-indigo-500 dark:group-active:bg-indigo-600 dark:group-focus:bg-indigo-600',
    Default:
      'hover:bg-red-400 active:bg-red-500 focus:bg-red-500 group-hover:bg-red-400 group-active:bg-red-500 group-focus:bg-red-500 dark:hover:bg-red-500 dark:active:bg-red-600 dark:focus:bg-red-600 dark:group-hover:bg-red-500 dark:group-active:bg-red-600 dark:group-focus:bg-red-600',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered:
      'hover:bg-primary-400 active:bg-primary-500 focus:bg-primary-500 group-hover:bg-primary-400 group-active:bg-primary-500 group-focus:bg-primary-500 dark:hover:bg-primary-500 dark:active:bg-primary-600 dark:focus:bg-primary-600 dark:group-hover:bg-primary-500 dark:group-active:bg-primary-600 dark:group-focus:bg-primary-600',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break':
      'hover:bg-teal-400 active:bg-teal-500 focus:bg-teal-500 group-hover:bg-teal-400 group-active:bg-teal-500 group-focus:bg-teal-500 dark:hover:bg-teal-500 dark:active:bg-teal-600 dark:focus:bg-teal-600 dark:group-hover:bg-teal-500 dark:group-active:bg-teal-600 dark:group-focus:bg-teal-600',
  }

  if (
    eventType === EventTypeEnums.BOOKED ||
    eventType === EventTypeEnums.TENTATIVE ||
    eventType === EventTypeEnums.MAINTENANCE ||
    eventType === EventTypeEnums.IN_TRANSIT ||
    eventType === EventTypeEnums.FLEXIBLE_USE ||
    eventType === EventTypeEnums.BOAT_SHOW ||
    eventType === EventTypeEnums.OTHER ||
    // @deprecated - only for backward compatibility of 'Chartered'
    eventType === ('Chartered' as EventTypeEnums) ||
    // @deprecated - only for backward compatibility of 'Crew break'
    eventType === ('Crew break' as EventTypeEnums)
  ) {
    return isInteractable
      ? `${eventColours[eventType]} ${interactableEventColours[eventType]}`
      : eventColours[eventType]
  }

  return isInteractable ? `${eventColours['Default']} ${interactableEventColours['Default']}` : eventColours['Default']
}

/**
 *
 * @param eventType The event type
 * @param isInteractable If the event can be interacted with (hover/focus/active etc)
 * @returns The edge colour (sides of an event pill on the timeline) for an eventType. normal edge, hover, focus, active, group-hover, group-focus, group-active with before and after
 */
export const eventEdgeColourWithBeforeAfter = (eventType: EventType, isInteractable: boolean): string => {
  const eventColours = {
    [EventTypeEnums.BOOKED]:
      'before:bg-primary-300 before:dark:bg-primary-400 after:bg-primary-300 after:dark:bg-primary-400',
    [EventTypeEnums.TENTATIVE]: 'before:bg-pink-300 before:dark:bg-pink-400 after:bg-pink-300 after:dark:bg-pink-400',
    [EventTypeEnums.MAINTENANCE]: 'before:bg-red-300 before:dark:bg-red-400 after:bg-red-300 after:dark:bg-red-400',
    [EventTypeEnums.IN_TRANSIT]:
      'before:bg-green-300 before:dark:bg-green-400 after:bg-green-300 after:dark:bg-green-400',
    [EventTypeEnums.FLEXIBLE_USE]:
      'before:bg-yellow-300 before:dark:bg-yellow-400 after:bg-yellow-300 after:dark:bg-yellow-400',
    [EventTypeEnums.BOAT_SHOW]: 'before:bg-teal-300 before:dark:bg-teal-400 after:bg-teal-300 after:dark:bg-teal-400',
    [EventTypeEnums.OTHER]:
      'before:bg-indigo-300 before:dark:bg-indigo-400 after:bg-indigo-300 after:dark:bg-indigo-400',
    Default: 'before:bg-red-300 before:dark:bg-red-400 after:bg-red-300 after:dark:bg-red-400',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered: 'before:bg-primary-300 before:dark:bg-primary-400 after:bg-primary-300 after:dark:bg-primary-400',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break': 'before:bg-teal-300 before:dark:bg-teal-400 after:bg-teal-300 after:dark:bg-teal-400',
  }

  const interactableEventColours = {
    [EventTypeEnums.BOOKED]:
      'before:hover:bg-primary-400 before:active:bg-primary-500 before:focus:bg-primary-500 before:group-hover:bg-primary-400 before:group-active:bg-primary-500 before:group-focus:bg-primary-500 before:dark:hover:bg-primary-500 before:dark:active:bg-primary-600 before:dark:focus:bg-primary-600 before:dark:group-hover:bg-primary-500 before:dark:group-active:bg-primary-600 before:dark:group-focus:bg-primary-600 after:hover:bg-primary-400 after:active:bg-primary-500 after:focus:bg-primary-500 after:group-hover:bg-primary-400 after:group-active:bg-primary-500 after:group-focus:bg-primary-500 after:dark:hover:bg-primary-500 after:dark:active:bg-primary-600 after:dark:focus:bg-primary-600 after:dark:group-hover:bg-primary-500 after:dark:group-active:bg-primary-600 after:dark:group-focus:bg-primary-600',
    [EventTypeEnums.TENTATIVE]:
      'before:hover:bg-pink-400 before:active:bg-pink-500 before:focus:bg-pink-500 before:group-hover:bg-pink-400 before:group-active:bg-pink-500 before:group-focus:bg-pink-500 before:dark:hover:bg-pink-500 before:dark:active:bg-pink-600 before:dark:focus:bg-pink-600 before:dark:group-hover:bg-pink-500 before:dark:group-active:bg-pink-600 before:dark:group-focus:bg-pink-600 after:hover:bg-pink-400 after:active:bg-pink-500 after:focus:bg-pink-500 after:group-hover:bg-pink-400 after:group-active:bg-pink-500 after:group-focus:bg-pink-500 after:dark:hover:bg-pink-500 after:dark:active:bg-pink-600 after:dark:focus:bg-pink-600 after:dark:group-hover:bg-pink-500 after:dark:group-active:bg-pink-600 after:dark:group-focus:bg-pink-600',
    [EventTypeEnums.MAINTENANCE]:
      'before:hover:bg-red-400 before:active:bg-red-500 before:focus:bg-red-500 before:group-hover:bg-red-400 before:group-active:bg-red-500 before:group-focus:bg-red-500 before:dark:hover:bg-red-500 before:dark:active:bg-red-600 before:dark:focus:bg-red-600 before:dark:group-hover:bg-red-500 before:dark:group-active:bg-red-600 before:dark:group-focus:bg-red-600 after:hover:bg-red-400 after:active:bg-red-500 after:focus:bg-red-500 after:group-hover:bg-red-400 after:group-active:bg-red-500 after:group-focus:bg-red-500 after:dark:hover:bg-red-500 after:dark:active:bg-red-600 after:dark:focus:bg-red-600 after:dark:group-hover:bg-red-500 after:dark:group-active:bg-red-600 after:dark:group-focus:bg-red-600',
    [EventTypeEnums.IN_TRANSIT]:
      'before:hover:bg-green-400 before:active:bg-green-500 before:focus:bg-green-500 before:group-hover:bg-green-400 before:group-active:bg-green-500 before:group-focus:bg-green-500 before:dark:hover:bg-green-500 before:dark:active:bg-green-600 before:dark:focus:bg-green-600 before:dark:group-hover:bg-green-500 before:dark:group-active:bg-green-600 before:dark:group-focus:bg-green-600 after:hover:bg-green-400 after:active:bg-green-500 after:focus:bg-green-500 after:group-hover:bg-green-400 after:group-active:bg-green-500 after:group-focus:bg-green-500 after:dark:hover:bg-green-500 after:dark:active:bg-green-600 after:dark:focus:bg-green-600 after:dark:group-hover:bg-green-500 after:dark:group-active:bg-green-600 after:dark:group-focus:bg-green-600',
    [EventTypeEnums.FLEXIBLE_USE]:
      'before:hover:bg-yellow-400 before:active:bg-yellow-500 before:focus:bg-yellow-500 before:group-hover:bg-yellow-400 before:group-active:bg-yellow-500 before:group-focus:bg-yellow-500 before:dark:hover:bg-yellow-500 before:dark:active:bg-yellow-600 before:dark:focus:bg-yellow-600 before:dark:group-hover:bg-yellow-500 before:dark:group-active:bg-yellow-600 before:dark:group-focus:bg-yellow-600 after:hover:bg-yellow-400 after:active:bg-yellow-500 after:focus:bg-yellow-500 after:group-hover:bg-yellow-400 after:group-active:bg-yellow-500 after:group-focus:bg-yellow-500 after:dark:hover:bg-yellow-500 after:dark:active:bg-yellow-600 after:dark:focus:bg-yellow-600 after:dark:group-hover:bg-yellow-500 after:dark:group-active:bg-yellow-600 after:dark:group-focus:bg-yellow-600',
    [EventTypeEnums.BOAT_SHOW]:
      'before:hover:bg-teal-400 before:active:bg-teal-500 before:focus:bg-teal-500 before:group-hover:bg-teal-400 before:group-active:bg-teal-500 before:group-focus:bg-teal-500 before:dark:hover:bg-teal-500 before:dark:active:bg-teal-600 before:dark:focus:bg-teal-600 before:dark:group-hover:bg-teal-500 before:dark:group-active:bg-teal-600 before:dark:group-focus:bg-teal-600 after:hover:bg-teal-400 after:active:bg-teal-500 after:focus:bg-teal-500 after:group-hover:bg-teal-400 after:group-active:bg-teal-500 after:group-focus:bg-teal-500 after:dark:hover:bg-teal-500 after:dark:active:bg-teal-600 after:dark:focus:bg-teal-600 after:dark:group-hover:bg-teal-500 after:dark:group-active:bg-teal-600 after:dark:group-focus:bg-teal-600',
    [EventTypeEnums.OTHER]:
      'before:hover:bg-indigo-400 before:active:bg-indigo-500 before:focus:bg-indigo-500 before:group-hover:bg-indigo-400 before:group-active:bg-indigo-500 before:group-focus:bg-indigo-500 before:dark:hover:bg-indigo-500 before:dark:active:bg-indigo-600 before:dark:focus:bg-indigo-600 before:dark:group-hover:bg-indigo-500 before:dark:group-active:bg-indigo-600 before:dark:group-focus:bg-indigo-600 after:hover:bg-indigo-400 after:active:bg-indigo-500 after:focus:bg-indigo-500 after:group-hover:bg-indigo-400 after:group-active:bg-indigo-500 after:group-focus:bg-indigo-500 after:dark:hover:bg-indigo-500 after:dark:active:bg-indigo-600 after:dark:focus:bg-indigo-600 after:dark:group-hover:bg-indigo-500 after:dark:group-active:bg-indigo-600 after:dark:group-focus:bg-indigo-600',
    Default:
      'before:hover:bg-red-400 before:active:bg-red-500 before:focus:bg-red-500 before:group-hover:bg-red-400 before:group-active:bg-red-500 before:group-focus:bg-red-500 before:dark:hover:bg-red-500 before:dark:active:bg-red-600 before:dark:focus:bg-red-600 before:dark:group-hover:bg-red-500 before:dark:group-active:bg-red-600 before:dark:group-focus:bg-red-600 after:hover:bg-red-400 after:active:bg-red-500 after:focus:bg-red-500 after:group-hover:bg-red-400 after:group-active:bg-red-500 after:group-focus:bg-red-500 after:dark:hover:bg-red-500 after:dark:active:bg-red-600 after:dark:focus:bg-red-600 after:dark:group-hover:bg-red-500 after:dark:group-active:bg-red-600 after:dark:group-focus:bg-red-600',
    // @deprecated - only for backward compatibility of 'Chartered'
    Chartered:
      'before:hover:bg-primary-400 before:active:bg-primary-500 before:focus:bg-primary-500 before:group-hover:bg-primary-400 before:group-active:bg-primary-500 before:group-focus:bg-primary-500 before:dark:hover:bg-primary-500 before:dark:active:bg-primary-600 before:dark:focus:bg-primary-600 before:dark:group-hover:bg-primary-500 before:dark:group-active:bg-primary-600 before:dark:group-focus:bg-primary-600 after:hover:bg-primary-400 after:active:bg-primary-500 after:focus:bg-primary-500 after:group-hover:bg-primary-400 after:group-active:bg-primary-500 after:group-focus:bg-primary-500 after:dark:hover:bg-primary-500 after:dark:active:bg-primary-600 after:dark:focus:bg-primary-600 after:dark:group-hover:bg-primary-500 after:dark:group-active:bg-primary-600 after:dark:group-focus:bg-primary-600',
    // @deprecated - only for backward compatibility of 'Crew break'
    'Crew break':
      'before:hover:bg-teal-400 before:active:bg-teal-500 before:focus:bg-teal-500 before:group-hover:bg-teal-400 before:group-active:bg-teal-500 before:group-focus:bg-teal-500 before:dark:hover:bg-teal-500 before:dark:active:bg-teal-600 before:dark:focus:bg-teal-600 before:dark:group-hover:bg-teal-500 before:dark:group-active:bg-teal-600 before:dark:group-focus:bg-teal-600 after:hover:bg-teal-400 after:active:bg-teal-500 after:focus:bg-teal-500 after:group-hover:bg-teal-400 after:group-active:bg-teal-500 after:group-focus:bg-teal-500 after:dark:hover:bg-teal-500 after:dark:active:bg-teal-600 after:dark:focus:bg-teal-600 after:dark:group-hover:bg-teal-500 after:dark:group-active:bg-teal-600 after:dark:group-focus:bg-teal-600',
  }

  if (
    eventType === EventTypeEnums.BOOKED ||
    eventType === EventTypeEnums.TENTATIVE ||
    eventType === EventTypeEnums.MAINTENANCE ||
    eventType === EventTypeEnums.IN_TRANSIT ||
    eventType === EventTypeEnums.FLEXIBLE_USE ||
    eventType === EventTypeEnums.BOAT_SHOW ||
    eventType === EventTypeEnums.OTHER ||
    // @deprecated - only for backward compatibility of 'Chartered'
    eventType === ('Chartered' as EventTypeEnums) ||
    // @deprecated - only for backward compatibility of 'Crew break'
    eventType === ('Crew break' as EventTypeEnums)
  ) {
    return isInteractable
      ? `${eventColours[eventType]} ${interactableEventColours[eventType]}`
      : eventColours[eventType]
  }

  return isInteractable ? `${eventColours['Default']} ${interactableEventColours['Default']}` : eventColours['Default']
}

/**
 *
 * @param isActive If the special is active or expired
 * @param isInteractable If the event can be interacted with (hover/focus/active etc)
 * @returns The border colour
 */
export const specialBorderColour = (isActive: boolean, isInteractable: boolean): string => {
  const activeStyle = 'dark:border dark:border-purple-400'
  const expiredStyle = 'dark:border dark:border-gray-300'

  const interactableActiveStyle =
    'dark:hover:border-purple-500 dark:focus:border-purple-500 dark:active:border-purple-500 dark:group-hover:border-purple-500 dark:group-focus:border-purple-500 dark:group-active:border-purple-500'
  const interactableExpiredStyle =
    'dark:hover:border-gray-400 dark:focus:border-gray-400 dark:active:border-gray-400 dark:group-hover:border-gray-400 dark:group-focus:border-gray-400 dark:group-active:border-gray-400'

  if (isInteractable) {
    return isActive ? `${activeStyle} ${interactableActiveStyle}` : `${expiredStyle} ${interactableExpiredStyle}`
  }

  return isActive ? activeStyle : expiredStyle
}
