import { Ref, readonly, ref } from 'vue'

const tableColumnOptions: Ref<{ key: string; label: string; value: boolean }[]> = ref([
  { key: 'line_7', label: 'Central Agent', value: true },
  { key: 'termPrice', label: 'Term Price', value: true },
  { key: 'dayPrice', label: 'Day Price', value: true },
  { key: 'hourlyPrice', label: 'Hourly Price', value: true },
  { key: 'line_2', label: 'Length', value: true },
  { key: 'category', label: 'Category', value: true },
  { key: 'cabins', label: 'Cabins', value: true },
  { key: 'cruiseSpeed', label: 'Cruising Speed', value: true },
  { key: 'bathrooms', label: 'Bathrooms', value: true },
  { key: 'brochure', label: 'Brochure', value: true },
])

export const useTable = () => {
  const setTableColumnOptions = (options: { key: string; label: string; value: boolean }[]) => {
    tableColumnOptions.value = options
  }

  return {
    tableColumnOptions: readonly(tableColumnOptions), // avoid the state to be mutated from a component
    setTableColumnOptions,
  }
}
