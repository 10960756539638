/**
 * Ref: https://en.wikipedia.org/wiki/ISO_8601#Durations
 * ISO 8601 provides decent standards for
 *  - Date Time
 *  - Durations (1 year, 3 months, etc.)
 *  - Intervals (Time t1 to t2)
 *
 *
 * JS Date is always improving, however we will primarly back this library with:
 * "luxon" (https://moment.github.io/luxon/)
 *
 * The ISO 8601 standard is extended to provide support for recurring intervals with a duration:
 *  - Take a standard interval:
 *      2022-01-01T13:00:00Z/2022-03-31T23:59:59Z
 *  - Now add a Rn (where n is the number of times, omitted = forever):
 *       R/2022-01-01T13:00:00Z/2022-03-31T23:59:59Z
 *  - But it is not clear on the space between the repeats, so add a duration:
 *       R/P1Y/2022-01-01T00:00:00Z/2022-03-31T23:59:59Z
 *  => Repeat every year forever, starting in 2022, on 01-JAN - 31-MAR!
 */
import { DateTime, Duration, Interval } from 'luxon'

import { affirmArgument } from '@ankor-io/common/lang/faults'
import { split } from '@ankor-io/common/lang/strings'

// Reusable constants.
export const RECUR_ANNUALY = 'P1Y'
export const RECUR_MONTHLY = 'P1M'

/**
 * Create an ISO8601 string representation of an interval:
 *   START/END
 *
 * @param start the start date time or an ISO formatted date string
 * @param end the end date time or an ISO formatted date string
 * @param toZone can be any IANA zone supported by the host environment, or a fixed-offset name of the form 'UTC+3', or the strings 'local' or 'utc'. Set to a falsey to not convert.
 *
 * @returns a string representation of an interval
 */
export const toIntervalString = (
  start: DateTime | string,
  end: DateTime | string,
  toZone: string = 'Etc/UTC',
): string => {
  //
  // marshall
  let _start: DateTime = typeof start === 'string' ? DateTime.fromISO(start) : start
  let _end: DateTime = typeof end === 'string' ? DateTime.fromISO(end) : end

  // convert to a different zone?
  if (toZone) {
    _start = _start.setZone(toZone)
    _end = _end.setZone(toZone)
  }

  // convert
  const interval = Interval.fromDateTimes(_start, _end)
  if (!interval.isValid) {
    throw new Error(interval.invalidExplanation ?? `Invalid arguments start=${start} end=${end}`)
  }

  //
  // START/END
  const intervalStr = interval.toISO()
  //console.debug(intervalStr)
  return intervalStr
}

/**
 * Convert an ISO8601 duration to a number of days.
 * 
 * @param pxd The input, e.g. 'P8D'
 * @returns The number of days in this ISO duration
 */
export const durationToDays = (pxd: string): number => {
  return Duration.fromISO(pxd).days
}

/**
 * Extract the relevant intervals from a list that overlaps with the supplied start/end.
 *
 * @param interval the interval of time to test against
 * @param intervals the intervals
 */
export const filterOverlap = (interval: string, intervals: string[]): string[] => {
  affirmArgument(() => !!interval, 'interval argument is required')
  affirmArgument(() => !!intervals, 'intervals argument is required')
  //
  // marshall
  const targetInterval: Interval = parseIntervalString(interval).interval

  affirmArgument(() => targetInterval.isValid, 'Invalid target interval')

  return intervals.filter((intervalStr) => {
    return overlapsAny(intervalStr, [targetInterval.toISO()])
  })
}

/**
 * Create an Ankor ISO8601 string representation of a recurring interval:
 *   R[n]/DURATION/START/END
 *
 * @param start the start date time
 * @param end the end date time
 * @param recurrenceDuration delay between the start date and the next start date
 * @param recurrences repeats number of repetitions; 0 would be none, negatative and default is Infinity
 * @param toZone can be any IANA zone supported by the host environment, or a fixed-offset name of the form 'UTC+3', or the strings 'local' or 'utc'. Set to a falsey to not convert.
 * @returns a string representation of a recurring interval
 */
export const toRecurringIntervalString = (
  start: DateTime | string,
  end: DateTime | string,
  recurrenceDuration: Duration | string,
  recurrences: number = Infinity,
  toZone: string = 'Etc/UTC',
): string => {
  //
  // when recurrences is 0, use the non-recurring method.
  const nonRecurringInterval = toIntervalString(start, end, toZone)
  if (recurrences === 0) {
    return nonRecurringInterval
  }

  //
  // Now take the built interval and prefix with `R[n]/DURATION/`

  //
  // determine the n part of R[n]
  let n = `${recurrences}`
  if (recurrences < 0 || recurrences === Infinity) {
    n = ''
  }

  //
  // Build the DURATION
  const _duration: Duration =
    typeof recurrenceDuration === 'string' ? Duration.fromISO(recurrenceDuration) : recurrenceDuration
  if (!_duration.isValid) {
    throw new Error(`${_duration.invalidExplanation}` ?? `Invalid duration ${recurrenceDuration}`)
  }

  // R[n]/DURATION/START/END
  return `R${n}/${_duration.toISO()}/${nonRecurringInterval}`
}

/**
 * Test if the supplied interval overlaps (end/start, start/end, within, or engulfs) with any of the supplied other intervals.
 *
 * @param interval the interval; non-recurring interval.
 * @param withThese invervals to compare against; can be recurring expressions!
 */
export const overlapsAny = (interval: string, withThese: string[]): boolean => {
  if (!interval) {
    throw new Error(`Invalid argument interval:${interval}`)
  }
  if (!withThese) {
    throw new Error(`Invalid argument withThese:${withThese}`)
  }
  // shortcut, nothing supplied to test against.
  if (!withThese.length) {
    return false
  }

  // more validation
  const _interval: Interval = parseIntervalString(interval).interval
  if (!_interval.isValid) {
    throw new Error(_interval.invalidExplanation ?? `Invalid supplied interval:${interval}`)
  }

  // calc the max date to reduce the recurring projections
  const _maxDate: DateTime = _interval.end!
  // for each target, project the intervals and then check if they align.
  for (const target of withThese) {
    const projectedIntervals = projectInterval(target, _maxDate)
    // for any of these, test for overlap.
    const match = projectedIntervals.find((projectedInterval) => _interval.overlaps(projectedInterval))
    // did one of them?
    if (match) {
      // great, done. no need to check more.
      return true
    }
  }

  // sorry, none match!
  return false
}

/**
 * For the provided interval, project all the dates it may recur to.
 *
 * @param interval the interval to project
 * @param notBeyond do not extend past this DateTime, regardless of the specified 'R' value
 * @param maxRecurrences Safety first! max number of recurrences to prevent infinite loops
 * @returns
 */
export const projectInterval = (interval: string, notBeyond?: DateTime, maxRecurrences?: number): Interval[] => {
  //
  // parse the provided interval
  const _parsedInterval = parseIntervalString(interval)
  const _interval: Interval = _parsedInterval.interval

  //
  if (!_parsedInterval.interval.isValid) {
    throw new Error(_parsedInterval.interval.invalidExplanation ?? `Invalid argument interval:${_parsedInterval._str}`)
  }
  //
  // shortcut if interval is later than notBeyond
  if (notBeyond && _interval.isAfter(notBeyond)) {
    return []
  }
  // shortcut if not recurring, only a single interval.
  if (!_parsedInterval.recurrences) {
    return [_parsedInterval.interval]
  }

  //
  // build the vector timeline
  const timeline: Interval[] = []
  // safety to not get stuck in a forever loop.
  const projectionLength = Math.min(_parsedInterval.recurrences, maxRecurrences ?? 2048)

  let dot: Interval = _interval
  do {
    // error check, log and return.
    if (!dot.start || !dot.end || !_parsedInterval.recurrenceDuration) {
      console.warn('Invalid start/end/recur duration. returning partial results.')
      return timeline
    }
    // if interval is later than notBeyond, return what we have
    if (notBeyond && dot.isAfter(notBeyond)) {
      return timeline
    }

    // add the current dot
    timeline.push(dot)

    // jump forward on the timeline.
    const nextStart = dot.start.plus(_parsedInterval.recurrenceDuration)
    const nextEnd = dot.end.plus(_parsedInterval.recurrenceDuration)
    dot = Interval.fromDateTimes(nextStart, nextEnd)
  } while (timeline.length < projectionLength)

  return timeline
}

/**
 * Parse the supplied string to field parts
 *
 * @param intervalStr the string to parse
 * @returns a deconstructed interval string in object form (sorry not typed)
 */
const parseIntervalString = (
  intervalStr: string,
  options?: { setZone?: boolean },
): {
  _str: string
  interval: Interval
  start?: DateTime
  end?: DateTime
  recurrenceDuration?: Duration
  recurrences?: number
} => {
  //
  let _interval: Interval
  let _recurrenceDuration: Duration | null = null
  let _recurrences: number | null = null

  // parameter variations:
  //  START/END
  //  Rn/DURATION/START/END
  //  R/DURATION/START/END

  if (intervalStr.startsWith('R')) {
    const parts = split(intervalStr, '/', 2)
    if (parts[0].length > 1) {
      // it is Rn.
      _recurrences = Number(parts[0].substring(1))
      // warn on negative recurring intervals
      if (_recurrences < 0) {
        console.error(`Negative recurring interval, treating as non-recurring: ${intervalStr}`)
      }
    } else {
      // to INFINITY!
      _recurrences = Infinity
    }
    _recurrenceDuration = Duration.fromISO(parts[1])
    _interval = Interval.fromISO(parts[2], { setZone: options?.setZone })
  } else {
    _interval = Interval.fromISO(intervalStr, { setZone: options?.setZone })
  }

  return {
    //
    _str: intervalStr,
    interval: _interval,
    ...(_interval.start && { start: _interval.start }),
    ...(_interval.end && { end: _interval.end }),
    //
    ...(_recurrenceDuration && { recurrenceDuration: _recurrenceDuration }),
    ...(_recurrences && { recurrences: _recurrences }),
  }
}

/**
 * Compare two durations
 * @returns -1 if left is greater than right, 0 if equal, 1 if right is greater than left
 * @param left
 * @param right
 */
export const compareDurations = (left: string, right: string): number => {
  if (!left) {
    throw new Error(`Invalid argument left:${left}`)
  }

  if (!right) {
    throw new Error(`Invalid argument right:${right}`)
  }

  const _left = parseIntervalString(left)
  const _right = parseIntervalString(right)
  const leftDuration = _left.interval.toDuration()
  const rightDuration = _right.interval.toDuration()
  if (leftDuration.milliseconds > rightDuration.milliseconds) {
    return 1
  }

  if (leftDuration.milliseconds === rightDuration.milliseconds) {
    return 0
  }

  return -1
}

/**
 * Compare two start dates
 * @returns -1 if left is greater than right, 0 if equal, 1 if right is greater than left
 * @param left
 * @param right
 */
export const compareStartDates = (left: string, right: string): number => {
  if (!left) {
    throw new Error(`Invalid argument left:${left}`)
  }

  if (!right) {
    throw new Error(`Invalid argument right:${right}`)
  }

  const _left = parseIntervalString(left)
  const _right = parseIntervalString(right)
  if (_left.interval.start?.toMillis()! > _right.interval.start?.toMillis()!) {
    return 1
  }

  if (_left.interval.start?.toMillis()! === _right.interval.start?.toMillis()!) {
    return 0
  }

  return -1
}

/**
 * Parse the supplied string to an Interval
 * @param intervalStr
 * @param options
 */
export const fromIntervalString = (intervalStr: string, options?: { setZone?: boolean }): Interval => {
  const parsed = parseIntervalString(intervalStr, options)
  if (!parsed.interval.isValid) {
    throw new Error(parsed.interval.invalidExplanation ?? `Invalid interval string: ${intervalStr}`)
  }
  return parsed.interval
}

/**
 * Calculates the overlap between two time intervals in milliseconds.
 *
 * @param {string} left - The string representation of the left interval.
 * @param {string} right - The string representation of the right interval.
 * @returns {number} The overlap between the two intervals in milliseconds, 0 if there's no overlap
 */
export const measureOverlap = (left: string, right: string): number => {
  affirmArgument(() => !!left, `Invalid argument left: ${left}`)
  affirmArgument(() => !!right, `Invalid argument right: ${right}`)

  const _left = fromIntervalString(left)
  const _right = fromIntervalString(right)

  const leftStartMillis = _left.start!.toMillis()
  const leftEndMillis = _left.end!.toMillis()
  const rightStartMillis = _right.start!.toMillis()
  const rightEndMillis = _right.end!.toMillis()

  // Positive value with how much they overlap
  // Zero if they just touch or don't overlap
  return Math.max(0, Math.min(leftEndMillis, rightEndMillis) - Math.max(leftStartMillis, rightStartMillis))
}
