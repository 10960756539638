<script setup lang="ts">
import { useModal } from '@/hooks/modal/useModal'

const { isOpen } = useModal()
</script>
<template>
  <!-- Wraps modal content with auto-logic handling teleporter -->
  <Teleport v-if="isOpen" to="#modal-container">
    <slot></slot>
  </Teleport>
</template>
