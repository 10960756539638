<script setup lang="ts">
import { Duration } from 'luxon'

import { eventBackgroundColour, eventTextColour } from '@ankor-io/common/calendar/event-colours'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import { CalendarEvent, EventSourceEnums } from '@ankor-io/common/vessel/types'
import { HiSolidCube } from '@ankor-io/icons/hi_solid'
import { OutlinePencil, OutlineTrash } from '@ankor-io/icons/outline'
import { SolidCalendar, SolidLocationMarker, SolidShip, SolidX } from '@ankor-io/icons/solid'

export type EventDetailsProps = {
  calendarEvent: CalendarEvent
  vesselName: string
}

const props = defineProps<EventDetailsProps>()
const emit = defineEmits<{
  (e: 'close:modal'): void
  (e: 'edit:event'): void
  (e: 'delete:event'): void
}>()

const interval = ISO8601.fromIntervalString(props.calendarEvent.interval, { setZone: true })

const getEventStartEndDateTime = (): { start: string; end: string } => {
  const { start, end } = interval
  return {
    start: `${start!.toFormat('dd-MM-yyyy')}${props.calendarEvent.allDay ? '' : ` ${start!.toFormat('hh:mm a')}`} (${
      start?.zoneName
    })`,
    end: `${end!.toFormat('dd-MM-yyyy')}${props.calendarEvent.allDay ? '' : ` ${end!.toFormat('hh:mm a')}`} (${
      end?.zoneName
    })`,
  }
}

const getDurationInHumanReadable = (turnaroundDuration: string) => {
  if (turnaroundDuration.endsWith('D')) {
    const durationInDays = Duration.fromISO(turnaroundDuration).days
    return `${durationInDays} day${durationInDays > 1 ? 's' : ''} turnaround`
  } else if (turnaroundDuration.endsWith('H')) {
    const durationInHours = Duration.fromISO(turnaroundDuration).hours
    return `${durationInHours} hour${durationInHours > 1 ? 's' : ''} turnaround`
  }
  return ''
}
</script>
<template>
  <div class="flex flex-col gap-y-2 md:min-w-[30.125rem] md:max-w-3xl">
    <!-- Modal header -->
    <div class="flex items-center justify-between">
      <h3 v-if="interval.start" class="font-bold text-base text-gray-900 dark:text-white">
        {{ interval.start.toFormat('ccc, d MMM') }}
        <span class="font-normal"> {{ props.calendarEvent?.title }}</span>
      </h3>
      <div class="flex gap-x-2">
        <template v-if="props.calendarEvent?.source === EventSourceEnums.CALENDARS">
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 p-1 rounded-lg text-sm ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="emit('edit:event')"
          >
            <OutlinePencil class="w-4 h-4" />
            <span class="sr-only">Edit Event</span>
          </button>
          <button
            type="button"
            class="text-red-500 bg-transparent hover:bg-gray-200 hover:text-red-700 p-1 rounded-lg text-sm ms-auto inline-flex justify-center items-center hover:dark:text-red-400 dark:hover:bg-gray-600 dark:hover:text-white"
            @click="emit('delete:event')"
          >
            <OutlineTrash class="w-4 h-4" />
            <span class="sr-only">Delete Event</span>
          </button>
        </template>
        <button
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 p-1 rounded-lg text-sm ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          @click="emit('close:modal')"
        >
          <SolidX class="w-4 h-4" />
          <span class="sr-only">Close modal</span>
        </button>
      </div>
    </div>

    <!-- Modal body -->
    <div class="grid grid-cols-2 gap-x-4 text-sm">
      <div class="flex flex-col gap-y-2 leading-relaxed text-gray-500 dark:text-gray-400">
        <div
          class="w-fit py-0.5 px-3 rounded-md uppercase"
          :class="[eventBackgroundColour(props.calendarEvent?.eventType!, false), eventTextColour(props.calendarEvent?.eventType!, false)]"
        >
          {{ props.calendarEvent?.eventType }}
        </div>
        <div class="flex flex-col gap-y-4">
          <div class="flex flex-col gap-y-1">
            <p class="font-semibold text-black dark:text-white">Embarkation</p>
            <div v-if="props.calendarEvent?.embark?.name" class="flex items-center gap-x-3">
              <SolidLocationMarker class="w-4 h-4 shrink-0" />
              <p>
                {{ props.calendarEvent?.embark?.name }}
              </p>
            </div>

            <div v-if="interval.start" class="flex items-center gap-x-3">
              <SolidCalendar class="w-4 h-4 shrink-0" />
              <p>
                {{ getEventStartEndDateTime().start }}
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-y-1">
            <p class="font-semibold text-black dark:text-white">Disembarkation</p>
            <div v-if="props.calendarEvent?.disembark?.name" class="flex items-center gap-x-3">
              <SolidLocationMarker class="w-4 h-4 shrink-0" />
              <p>
                {{ props.calendarEvent?.disembark?.name }}
              </p>
            </div>
            <div v-if="interval.end" class="flex items-center gap-x-3">
              <SolidCalendar class="w-4 h-4 shrink-0" />
              <p>
                {{ getEventStartEndDateTime().end }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4 leading-relaxed text-gray-500 dark:text-gray-400">
        <div class="flex flex-col gap-y-1">
          <p class="font-semibold text-black dark:text-white">Details</p>
          <div v-if="props.vesselName" class="flex items-center gap-x-2">
            <SolidShip class="w-4 h-4 shrink-0" />
            <p>{{ props.vesselName }}</p>
          </div>
          <div v-if="props.calendarEvent?.variantLabel" class="flex items-center gap-x-2">
            <HiSolidCube class="w-4 h-4 fill-current shrink-0" />
            <p>{{ props.calendarEvent?.variantLabel }}</p>
          </div>
          <p>{{ props.calendarEvent?.reference }}</p>
          <p
            v-if="
              props.calendarEvent?.turnaroundDuration &&
              (props.calendarEvent.turnaroundDuration.endsWith('D') ||
                props.calendarEvent.turnaroundDuration.endsWith('H'))
            "
          >
            {{ getDurationInHumanReadable(props.calendarEvent.turnaroundDuration) }}
          </p>
        </div>
        <div class="flex flex-col gap-y-1">
          <p v-if="props.calendarEvent?.notes" class="font-semibold text-black dark:text-white">Notes</p>
          <p v-if="props.calendarEvent?.notes">{{ props.calendarEvent?.notes }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
