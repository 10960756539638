<script setup lang="ts">
type ToastPosition = 'top' | 'left' | 'right' | 'bottom' | 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'

type Props = {
  position: ToastPosition
}

const props = defineProps<Props>()

const getToastPosition = (position: ToastPosition) => {
  switch (position) {
    case 'top':
      return 'top-5'
    case 'left':
      return 'left-5'
    case 'right':
      return 'right-5'
    case 'bottom':
      return 'bottom-5'
    case 'top-right':
      return 'top-5 right-5'
    case 'top-left':
      return 'top-5 left-5'
    case 'bottom-right':
      return 'bottom-5 right-5'
    case 'bottom-left':
      return 'bottom-5 left-5'
    default:
      return 'top-5 right-5'
  }
}
</script>
<template>
  <div
    class="z-10 absolute flex gap-x-4 items-center w-full max-w-xs p-4 rounded-lg shadow text-gray-500 bg-white divide-gray-200 dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800"
    :class="getToastPosition(props.position)"
  >
    <slot name="icon"></slot>
    <div class="text-sm">
      <p><slot name="headingMessage"></slot></p>
      <p><slot name="bodyMessage"></slot></p>
    </div>
  </div>
</template>
