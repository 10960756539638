<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import { SearchClient, SearchIndex } from 'algoliasearch/lite'
import { Pane, Splitpanes } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import 'splitpanes/dist/splitpanes.css'
import { Ref, computed, inject, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import ImageCarouselModalEditor from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModalEditor.vue'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { CrewMember, KVPair, LineItem, Person, Season, Variant, Vessel } from '@ankor-io/common/vessel/types'
import { HiOutlineBanknotes } from '@ankor-io/icons/hi_outline'
import {
  OutlineArrowLeft,
  OutlineArrowRight,
  OutlineCrew,
  OutlinePhone,
  OutlinePhotograph,
  OutlinePool,
  OutlineShip,
  OutlineSun,
} from '@ankor-io/icons/outline'
import { SolidChevronLeft } from '@ankor-io/icons/solid'

import ButtonVue from '@/components/Button.vue'
import Preview from '@/components/Preview.vue'
import Spinner from '@/components/Spinner.vue'
import ContactInfoForm from '@/components/edit-vessel/ContactInfoForm.vue'
// Edit vessel tabs
import CrewForm from '@/components/edit-vessel/CrewForm.vue'
import MediaManagerForm from '@/components/edit-vessel/MediaManagerForm.vue'
import SeasonForm from '@/components/edit-vessel/SeasonForm.vue'
import SpecificationsForm from '@/components/edit-vessel/SpecificationsForm.vue'
import ToysAmenitiesForm from '@/components/edit-vessel/ToysAmenitiesForm.vue'
import VariantForm from '@/components/edit-vessel/VariantForm.vue'
import Toast from '@/components/toast/Toast.vue'
import { AuthenticationContext } from '@/iam/types'
import { navToError } from '@/utils/nav-to-error'

interface EditVesselProps {
  uri: string
}

const props = defineProps<EditVesselProps>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const { width: windowWidth } = useWindowSize()
const MOBILE_BREAKPOINT = 768
const isMobile = computed(() => windowWidth.value <= MOBILE_BREAKPOINT)

const isSaving: Ref<boolean> = ref(false)
const saveAndReturnInProgress: Ref<boolean> = ref(false)
const saveAndReturnPlaceholderRendered: Ref<boolean> = ref(false)

const vesselRef: Ref<Vessel | null> = ref(null)
const levelRef: Ref<'info' | 'danger' | 'success' | 'warning' | 'dark' | null> = ref(null)
const messageRef: Ref<string | null> = ref(null)
const toastRef = ref()
const showCarouselModal: Ref<boolean> = ref(false)
const selectedCarouselImageIndex: Ref<number> = ref(0)
const carousel: Ref<typeof ImageCarouselModalEditor | null> = ref(null)

const route = useRoute()
const router = useRouter()
const step: Ref<number> = ref(0)

const managedByCompaniesRef: Ref<string> = ref('')

const searchClient: SearchClient = inject('searchClient') as SearchClient
const index: SearchIndex = searchClient.initIndex('commodore')

onMounted(() => {
  if (document.getElementById('placeholder_save_return')) {
    saveAndReturnPlaceholderRendered.value = true
  }

  getVesselData()

  getCompaniesManagedBy()
})

const getCompaniesManagedBy = async () => {
  await index.search(`"${props.uri}"`, {}).then((response) => {
    if (response.hits.length) {
      const hit: any = response.hits[0] || {}
      const managedByCompanies = hit.line_7 || []
      if (managedByCompanies.length === 0) {
        managedByCompaniesRef.value = ''
        return
      }
      if (managedByCompanies.length === 1) {
        managedByCompaniesRef.value = `${managedByCompanies[0]}`
        return
      } else {
        managedByCompaniesRef.value = `${managedByCompanies.slice(0, -1).join(', ')} and ${managedByCompanies.slice(
          -1,
        )}`
        return
      }
    }
  })
}

/**
 * Get the vessel data from the server, if not available default to vesselWithDefaults
 */
const getVesselData = async () => {
  try {
    const response = await fetch(`/api/vessel/${decodeURIComponent(props.uri)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${await authenticationContext.getToken()}`,
      },
    })

    if (response.status === 200) {
      vesselRef.value = await response.json()
    } else {
      navToError(router)
    }
  } catch (e) {
    navToError(router)
  }
}

/**
 * Close the toast message
 */
const closeToast = () => {
  if (toastRef.value) {
    levelRef.value = null
    messageRef.value = null
    toastRef.value.hide()
  }
}

/**
 * Get level and message for toast
 * @param status
 */
const getToastAttributes = (
  status: number,
): { level: 'info' | 'danger' | 'success' | 'warning' | 'dark'; message: string } => {
  // Success status
  if (status === 200) {
    return {
      level: 'success',
      message: 'Saved successfully. All done!',
    }
  } else {
    return {
      level: 'danger',
      message: `${status} Unable to save data`,
    }
  }
}

/**
 * Save the vessel data
 */
const saveVesselData = async (showLoader = true) => {
  if (showLoader) {
    isSaving.value = true
  }

  const response = await fetch(`/api/vessel/${props.uri}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify(vesselRef.value),
  })

  const { level, message } = getToastAttributes(response.status)
  levelRef.value = level
  messageRef.value = message
  isSaving.value = false

  return response
}

/**
 * Wizard completed step
 */
const saveForm = async () => {
  await saveVesselData()
  if (step.value !== 6) {
    step.value += 1
  } else {
    router.push({
      name: 'view-vessel',
      params: { uri: decodeURIComponent(route.params.uri.toString()) },
    })
  }
}

/**
 * Update the vessel
 * @param value - Updated vessel object
 */
const updateVessel = (value: Vessel) => {
  vesselRef.value = value
}

/**
 * Update the variant field
 * @param value - Updated variant array
 */
const updateVariants = (value: Variant[]) => {
  // Update the charterFee value for each of the variants
  const updatedVariants = value.map((variant: Variant) => {
    if (!variant || !variant.pricing) {
      return variant
    }

    const firstItem: LineItem = variant.pricing?.lineItems?.[0] || {}
    variant.pricing.charterFee = firstItem.amount || variant.pricing.total || 0
    return variant
  })
  vesselRef.value = { ...vesselRef.value!, variants: [...updatedVariants] }
}

/**
 * Update the crew field
 * @param value - Updated crew array
 */
const updateCrew = (value: CrewMember[]) => {
  vesselRef.value = { ...vesselRef.value!, crew: [...value] }
}

/**
 * Update the seasons
 * @param value - Updated season array
 */
const updateSeasons = (value: Season[]) => {
  vesselRef.value = { ...vesselRef.value!, seasons: [...value] }
}

/**
 * Update the vessel contacts
 * @param contacts
 */
const updateContacts = (contacts: Person[]) => {
  vesselRef.value = { ...vesselRef.value!, contacts: contacts }
}

const deleteHero = (): void => {
  const vessel: Vessel = ObjectUtil.deepCopy(vesselRef.value!)
  if (vessel.blueprint.images?.length) {
    const heroIndex = vessel.blueprint.images?.findIndex((image) => image === vessel.blueprint.hero)
    vessel.blueprint.images.splice(heroIndex, 1)
    vessel.blueprint.hero = vessel.blueprint.images?.[0]
    vesselRef.value = { ...vesselRef.value!, blueprint: vessel.blueprint }
  }
}

const updateHero = (imageIndex: number): void => {
  const vessel: Vessel = ObjectUtil.deepCopy(vesselRef.value!)
  const newHero = vessel.blueprint.images![imageIndex]
  vessel.blueprint.images?.splice(imageIndex, 1)
  vessel.blueprint.hero = newHero
  vessel.blueprint.images?.unshift(newHero)
  vesselRef.value = { ...vesselRef.value!, blueprint: vessel.blueprint }
}

/**
 * Update the vessel assets
 * The first value always becomes the hero
 * @param assets
 */
const updateVesselAssets = (assets: string[]) => {
  const { blueprint } = ObjectUtil.deepCopy(vesselRef.value!)
  blueprint.hero = assets[0]
  blueprint.images = assets || []
  vesselRef.value = { ...vesselRef.value!, blueprint }
}

const updateVesselAttachments = (attachments: KVPair[]) => {
  vesselRef.value = { ...vesselRef.value!, attachments }
}

const updateVesselLinks = (links: KVPair[]) => {
  vesselRef.value = { ...vesselRef.value!, links }
}

const openCarousel = (index: number) => {
  selectedCarouselImageIndex.value = index
  showCarouselModal.value = true
  carousel.value?.focus()
}

const onSaveAndReturn = async () => {
  // TODO: Use the main page loader for this use case too?
  saveAndReturnInProgress.value = true
  const response = await saveVesselData(false)
  if (response.status === 200) {
    router.push({
      name: 'view-vessel',
      params: { uri: decodeURIComponent(route.params.uri.toString()) },
    })
  } else if (response.status === 403) {
    router.push({ name: 'forbidden' })
  }

  saveAndReturnInProgress.value = false
}

watch(levelRef, (level, prevLevel) => {
  if (level !== prevLevel) {
    setTimeout(() => closeToast(), 3000)
  }
})

const getCarouselImages = () => {
  if (vesselRef.value?.blueprint.images) {
    return replacePathToMediaUris(vesselRef.value.uri, ...vesselRef.value.blueprint.images)
  }

  return []
}
</script>
<template>
  <div class="flex flex-col overflow-hidden">
    <Splitpanes class="default-theme">
      <Pane min-size="55" :max-size="isMobile ? 100 : 75">
        <div class="bg-white shadow py-4 px-2 sm:p-6 xl:p-8 dark:bg-gray-800 mb-4 xl:mb-0">
          <div v-if="!vesselRef" class="flex items-center justify-center h-full">
            <Spinner />
          </div>
          <div v-else class="flex flex-col gap-y-8">
            <!-- Stepper -->
            <div class="w-full overflow-x-auto">
              <ol class="flex items-center min-w-[48rem] w-full px-5 mb-8">
                <li
                  class="relative cursor-pointer flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-4 after:inline-block"
                  :class="
                    step === 0
                      ? 'after:border-gray-100 dark:after:border-gray-700'
                      : 'after:border-primary-100 dark:after:border-primary-300'
                  "
                  @click="step = 0"
                >
                  <span
                    class="flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0 bg-primary-100 dark:bg-primary-300"
                  >
                    <OutlineShip class="w-6 h-6 stroke-2 text-primary-600" />
                  </span>
                  <span class="absolute -bottom-7 -left-5 text-sm font-medium text-primary-600 dark:text-primary-500">
                    Specifications
                  </span>
                </li>
                <li
                  class="relative cursor-pointer flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-4 after:inline-block"
                  :class="
                    step <= 1
                      ? 'after:border-gray-100 dark:after:border-gray-700'
                      : 'after:border-primary-100 dark:after:border-primary-300'
                  "
                  @click="step = 1"
                >
                  <span
                    class="flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0"
                    :class="step >= 1 ? 'bg-primary-100 dark:bg-primary-300' : 'bg-gray-100 dark:bg-gray-700'"
                  >
                    <OutlinePool class="w-6 h-6 stroke-2" :class="step >= 1 ? 'text-primary-600' : 'text-gray-500'" />
                  </span>
                  <span
                    class="absolute -bottom-7 -left-8 text-sm font-medium"
                    :class="step >= 1 ? 'text-primary-600 dark:text-primary-500' : 'text-gray-500'"
                  >
                    Toys & Amenities
                  </span>
                </li>
                <li
                  class="relative cursor-pointer flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700"
                  :class="
                    step <= 2
                      ? 'after:border-gray-100 dark:after:border-gray-700'
                      : 'after:border-primary-100 dark:after:border-primary-300'
                  "
                  @click="step = 2"
                >
                  <span
                    class="flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0"
                    :class="step >= 2 ? 'bg-primary-100 dark:bg-primary-300' : 'bg-gray-100 dark:bg-gray-700'"
                  >
                    <HiOutlineBanknotes
                      class="w-6 h-6 stroke-2"
                      :class="step >= 2 ? 'stroke-primary-600' : 'stroke-gray-500'"
                    />
                  </span>
                  <span
                    class="absolute -bottom-7 text-sm font-medium"
                    :class="step >= 2 ? 'text-primary-600 dark:text-primary-500' : 'text-gray-500'"
                  >
                    Pricing
                  </span>
                </li>
                <li
                  class="relative cursor-pointer flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700"
                  :class="
                    step <= 3
                      ? 'after:border-gray-100 dark:after:border-gray-700'
                      : 'after:border-primary-100 dark:after:border-primary-300'
                  "
                  @click="step = 3"
                >
                  <span
                    class="flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0"
                    :class="step >= 3 ? 'bg-primary-100 dark:bg-primary-300' : 'bg-gray-100 dark:bg-gray-700'"
                  >
                    <OutlineSun
                      class="w-6 h-6 stroke-2"
                      :class="step >= 3 ? 'stroke-primary-600' : 'stroke-gray-500'"
                    />
                  </span>
                  <span
                    class="absolute -bottom-7 -left-1 text-sm font-medium"
                    :class="step >= 3 ? 'text-primary-600 dark:text-primary-500' : 'text-gray-500'"
                  >
                    Seasons
                  </span>
                </li>
                <li
                  class="relative cursor-pointer flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700"
                  :class="
                    step <= 4
                      ? 'after:border-gray-100 dark:after:border-gray-700'
                      : 'after:border-primary-100 dark:after:border-primary-300'
                  "
                  @click="step = 4"
                >
                  <span
                    class="flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0"
                    :class="step >= 4 ? 'bg-primary-100 dark:bg-primary-300' : 'bg-gray-100 dark:bg-gray-700'"
                  >
                    <OutlineCrew
                      class="w-6 h-6 stroke-2"
                      :class="step >= 4 ? 'stroke-primary-600' : 'stroke-gray-500'"
                    />
                  </span>
                  <span
                    class="absolute -bottom-7 left-1.5 text-sm font-medium"
                    :class="step >= 4 ? 'text-primary-600 dark:text-primary-500' : 'text-gray-500'"
                  >
                    Crew
                  </span>
                </li>
                <li
                  class="relative cursor-pointer flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700"
                  :class="
                    step <= 5
                      ? 'after:border-gray-100 dark:after:border-gray-700'
                      : 'after:border-primary-100 dark:after:border-primary-300'
                  "
                  @click="step = 5"
                >
                  <span
                    class="flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0"
                    :class="step >= 5 ? 'bg-primary-100 dark:bg-primary-300' : 'bg-gray-100 dark:bg-gray-700'"
                  >
                    <OutlinePhotograph
                      class="w-6 h-6 stroke-2"
                      :class="step >= 5 ? 'stroke-primary-600' : 'stroke-gray-500'"
                    />
                  </span>
                  <span
                    class="absolute -bottom-7 left-1 text-sm font-medium"
                    :class="step >= 5 ? 'text-primary-600 dark:text-primary-500' : 'text-gray-500'"
                  >
                    Media
                  </span>
                </li>
                <li class="relative cursor-pointer flex items-center" @click="step = 6">
                  <span
                    class="flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0"
                    :class="step >= 6 ? 'bg-primary-100 dark:bg-primary-300' : 'bg-gray-100 dark:bg-gray-700'"
                  >
                    <OutlinePhone
                      class="w-6 h-6 stroke-2"
                      :class="step >= 6 ? 'stroke-primary-600' : 'stroke-gray-500'"
                    />
                  </span>
                  <span
                    class="absolute -bottom-7 -left-3 text-sm font-medium w-max"
                    :class="step >= 6 ? 'text-primary-600 dark:text-primary-500' : 'text-gray-500'"
                  >
                    CA Contact
                  </span>
                </li>
              </ol>
            </div>

            <!-- Forms -->
            <SpecificationsForm v-if="step === 0" :vessel="vesselRef" @update:vessel="updateVessel" />
            <ToysAmenitiesForm v-if="step === 1" :vessel="vesselRef" @update:vessel="updateVessel" />
            <VariantForm
              v-if="step === 2 && vesselRef.variants"
              :variants="vesselRef.variants"
              :blueprint="vesselRef.blueprint"
              @update:variants="updateVariants"
            />
            <SeasonForm v-if="step === 3" :seasons="vesselRef.seasons || []" @update:seasons="updateSeasons" />
            <CrewForm v-if="step === 4" :uri="props.uri" :crew="vesselRef.crew || []" @update:crew="updateCrew" />
            <MediaManagerForm
              v-if="step === 5"
              :uri="props.uri"
              :links="vesselRef.links || []"
              :images="vesselRef.blueprint.images || []"
              :attachments="vesselRef.attachments || []"
              :hero="vesselRef.blueprint.hero"
              @delete:hero="deleteHero()"
              @update:hero="updateHero($event)"
              @update:vessel:links="updateVesselLinks"
              @update:vessel:assets="updateVesselAssets"
              @update:vessel:attachments="updateVesselAttachments"
            />
            <ContactInfoForm v-if="step === 6" :contacts="vesselRef.contacts || []" @update:contacts="updateContacts" />

            <!-- Save -->
            <div class="flex gap-x-2 justify-end">
              <button
                v-if="step !== 0"
                type="button"
                class="flex items-center gap-x-1 py-2 px-3 text-xs font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 focus:dark:ring-gray-500 dark:hover:text-white dark:hover:bg-gray-700"
                @click="step -= 1"
              >
                <OutlineArrowLeft class="w-4 shrink-0" />
                Back
              </button>
              <ButtonVue
                position="suffix"
                :is-primary="true"
                :disabled="isSaving"
                :name="step === 6 ? 'Save & Finish' : 'Save & Proceed'"
                :on-click="() => saveForm()"
              >
                <Spinner v-if="isSaving" class="w-4 h-4 shrink-0 ml-1" />
                <OutlineArrowRight v-else class="w-4 shrink-0 ml-1" />
              </ButtonVue>
            </div>
          </div>
        </div>
      </Pane>
      <Pane v-if="!isMobile" id="preview-pane">
        <div class="bg-white shadow p-4 sm:p-6 xl:p-8 dark:bg-gray-800 mb-4 xl:mb-0">
          <Preview
            v-if="vesselRef"
            :vessel="vesselRef"
            :managed-by="managedByCompaniesRef"
            @open-carousel="openCarousel"
          ></Preview>
          <div v-else class="flex items-center justify-center h-full">
            <Spinner />
          </div>
        </div>
      </Pane>
    </Splitpanes>

    <!-- Success toast -->
    <Toast
      v-if="messageRef && levelRef"
      class="w-fit top-20 left-1/2 -translate-x-1/2"
      ref="toastRef"
      trigger-element-id="edit-yacht-submit"
      target-element-id="yacht-submit"
      :duration="2000"
      :level="levelRef"
      :message="messageRef"
      @click="closeToast"
    />

    <!-- Carousel Modal -->
    <Teleport v-if="showCarouselModal && vesselRef && vesselRef.blueprint.images" to="body">
      <ImageCarouselModalEditor
        :slides="getCarouselImages()"
        :slideTo="selectedCarouselImageIndex"
        @close="showCarouselModal = false"
      />
    </Teleport>

    <!-- Save & return -->
    <Teleport v-if="saveAndReturnPlaceholderRendered" to="#placeholder_save_return">
      <a
        type="button"
        class="z-50 flex items-center gap-x-2 py-2 px-3 text-xs font-medium cursor-pointer transition-all border text-gray-900 rounded-lg hover:bg-gray-100 hover:text-blue-700 border-gray-200 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        @click.once="onSaveAndReturn"
      >
        <SolidChevronLeft v-if="!saveAndReturnInProgress" class="h-4 w-4" aria-hidden="true" />
        <Spinner v-else class="w-5 h-5 mr-1" />
        Save & return
      </a>
    </Teleport>
  </div>
</template>

<style lang="scss">
.splitpanes.default-theme .splitpanes__splitter {
  background: lightgray;
  opacity: 10%;
  margin: 0 0.25rem;
  border-left: none;
}

.splitpanes.default-theme .splitpanes__pane {
  background: transparent;
}

.splitpanes__pane {
  height: calc(100vh - 4.5rem);
  overflow-y: auto;
}
</style>
