import { DateTime } from 'luxon'

export const getListOfTime = (seperatedByMin: number) => {
  const times = []
  const startTime = DateTime.fromObject({}).startOf('day')
  const endTime = DateTime.fromObject({}).endOf('day')
  let currentTime = startTime
  while (currentTime <= endTime) {
    const time = currentTime.toFormat('hh:mm a')
    times.push({ label: time, value: time })
    currentTime = currentTime.plus({ minutes: seperatedByMin })
  }
  return times
}
