<script lang="ts" setup>
import ls from 'localstorage-slim'
import { Ref, computed, inject, onMounted, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import SimpleModal from '@ankor-io/blocks/components/modals/SimpleModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import ListGrid from '@ankor-io/blocks/layouts/ListGrid.vue'
import ListTable from '@ankor-io/blocks/layouts/ListTable.vue'
import ListTableAccordion from '@ankor-io/blocks/layouts/ListTableAccordion.vue'
import { ProposalItemType } from '@ankor-io/common/proposal/Proposal'
import { Trip } from '@ankor-io/common/trip/Trip'
import { URIContext } from '@ankor-io/common/uri/Uri'
import { MediaUriBuilder } from '@ankor-io/common/uri/uri.builder'
import { formatLengthWithSpaceSeparator } from '@ankor-io/common/vessel/Formatter'
import { getLowestPricingItem, intlCurrencyFormatter } from '@ankor-io/common/vessel/lowest-price-variant'
import { Variant } from '@ankor-io/common/vessel/types'
import { VesselVisibility } from '@ankor-io/common/vessel/types'
import { LineArtMeasuringtape } from '@ankor-io/icons/line_art'
import {
  OutlineAddYacht,
  OutlineCabinDoorIcon,
  OutlineCalendarThin,
  OutlineClipboardCopy,
  OutlineFilter,
  OutlineLink,
  OutlinePlus,
  OutlineUserGroupThin,
  OutlineWrenchScrewdriver,
} from '@ankor-io/icons/outline'
import { SolidCollection, SolidSearch, SolidTable } from '@ankor-io/icons/solid'
// import SearchNoResultsMoon from '@/assets/search-no-results-moon.svg'
import Loader from '@ankor-io/wheelhouse/src/components/Loader.vue'

import ButtonVue from '@/components/Button.vue'
import PricingInCard from '@/components/PricingInCard.vue'
import Spinner from '@/components/Spinner.vue'
import VisibleObserver from '@/components/VisibleObserver.vue'
import AlgoliaCheckbox from '@/components/ais/Checkbox.vue'
import AlgoliaNumberRangeInput from '@/components/ais/NumberRangeInput.vue'
import AlgoliaRefinementList from '@/components/ais/RefinementList.vue'
import BrochureGeneratedModalContent from '@/components/modal-content/BrochureGeneratedModalContent.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { useModal } from '@/hooks/modal/useModal'
import { useCompany } from '@/hooks/useCompany'
import { AuthenticationContext } from '@/iam/types'
import { usePreferredViewStore } from '@/stores/preferredView/preferredView.store'
import Timeline from '@/views/Timeline.vue'

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
// import YachtsActionsKebab from './YachtsActionsKebab.vue'

const UNNAMED_YACHT = 'Unnamed Yacht'

// the data in the table -- the dirty one has "null" values for tombstoned items.
let dirtyRiver: Ref<any[]> = ref([])

const river = computed(() => dirtyRiver.value.filter((item) => !!item))
const preferredViewStore = usePreferredViewStore()

const sortBy = [
  { value: 'global', label: 'Most Relevant' },
  { value: 'global_line_1_asc', label: 'Name (A-Z)' },
  { value: 'global_line_1_desc', label: 'Name (Z-A)' },
  { value: 'global_last_modified_desc', label: 'Recently Edited' },
]

const { company, getCompany } = useCompany()
const companyLoaded: Ref<boolean> = ref(false)
const calendarsLinkCopied: Ref<boolean> = ref(false)
let linkCopiedTimeout: ReturnType<typeof setTimeout> | null = null

onMounted(async () => {
  getRates()

  getCompany((await authenticationContext.getToken())!).then(() => {
    companyLoaded.value = true
  })
})

// During the "transform items" step of the Aloglia search API,
// accumulate the results in the river array.
const accumulateSearchResults = (items: any[], { results }: any): object => {
  //  ** IMPORTANT **
  //  There is no guarantee that this function will be called only once
  //    for each call to refineNext() or via an Algolia HTTP call. See:
  //   - https://github.com/algolia/vue-instantsearch/issues/707#issuecomment-1361526922
  //

  // reset river to empty array if it's the first page
  if (results.page === 0) {
    dirtyRiver.value = []
  }

  items.forEach((hit, i) => {
    // allow only vessels into the dirty river
    if (hit.type !== ProposalItemType.VESSEL) {
      return
    }

    let offset = results.page * results.hitsPerPage + i

    if (ls.get<string>(`tombstone::${hit.uri}`) !== null && ls.get<string>(`tombstone::${hit.uri}`) !== undefined) {
      dirtyRiver.value[offset] = null
      return
    }

    // work out the hero image url
    let _hero
    if (hit.hero && hit.hero.startsWith(`${URIContext.MEDIA}::`)) {
      _hero = `/media/${hit.hero}`
    } else {
      _hero = `/media/${new MediaUriBuilder().build(hit.uri, hit.hero)}`
    }

    // Work out lowest prices and convert it to a string (for display in table)
    const validVariants = hit.vessel.variants.filter(
      (variant: Variant) =>
        variant.active && variant.pricing?.total && variant.pricing.total > 0 && variant.pricing?.currency,
    )

    const termVariants = validVariants.filter((variant: Variant) => variant.pricing?.unit === 'WEEK')
    const dayVariants = validVariants.filter((variant: Variant) => variant.pricing?.unit === 'DAY')
    const hourVariants = validVariants.filter((variant: Variant) => variant.pricing?.unit === 'HOUR')

    const lowestTermPrice = getLowestPricingItem(termVariants, ratesRef.value)
    const termPriceLabel = lowestTermPrice
      ? intlCurrencyFormatter(lowestTermPrice.displayCurrency, lowestTermPrice.displayPrice)
      : ''

    const lowestDayPrice = getLowestPricingItem(dayVariants, ratesRef.value)
    const dayPriceLabel = lowestDayPrice
      ? intlCurrencyFormatter(lowestDayPrice.displayCurrency, lowestDayPrice.displayPrice)
      : ''

    const lowestHourPrice = getLowestPricingItem(hourVariants, ratesRef.value)
    const hourPriceLabel = lowestHourPrice
      ? intlCurrencyFormatter(lowestHourPrice.displayCurrency, lowestHourPrice.displayPrice)
      : ''

    // set the hit to the expected row position aligning to the position of the hit.
    dirtyRiver.value[offset] = {
      ...hit,
      uri: hit.objectID,
      hero: _hero,
      name: hit.line_1,
      length: hit.line_2,
      numCabins: hit.line_3,
      numSleeps: hit.line_4,
      buildYear: hit.line_5,
      builder: hit.line_6,
      companies: Array.isArray(hit.line_7) ? hit.line_7.join(', ') : hit.line_7 || '-',
      tags: hit.tags,
      category: hit.vessel.category,
      cruisingSpeed: hit.vessel.blueprint.cruiseSpeed,
      numBathrooms: hit.vessel.blueprint.bathrooms,
      priceTerm: termPriceLabel,
      priceDay: dayPriceLabel,
      priceHour: hourPriceLabel,
    }
  })

  // return the clean river (used by algolia lib)
  return river
}

// Timeline related

const hitsElementScrollPosition: Ref<number> = ref(0)
const timelineRows: Ref<Record<string, number>> = ref({})
const hitsElementRef: Ref<any> = ref(null)
const ratesRef: Ref<{ [key: string]: string }> = ref({})
const mapItemsToVesselProps = (items: any) =>
  items.map((hit: any) => {
    return {
      uri: hit.uri,
      label: hit.name,
      variants: hit.vessel.variants.map((variant: Variant) => {
        return { label: variant.label, id: variant.id, turnaround: variant.turnaround }
      }),
    }
  })

const onScrollTimeline = (scrollEvent: Event) => {
  if (hitsElementRef.value) {
    hitsElementRef.value.scrollTop = (scrollEvent.target as HTMLElement).scrollTop
  }
}

const onScrollHits = (scrollEvent: Event) => {
  hitsElementScrollPosition.value = (scrollEvent.target as HTMLElement).scrollTop
}

/**
 * Format the managed by company string
 * @param companyNames
 */
const formatManagedBy = (companyNames: string[] = []) => {
  if (companyNames.length === 0) {
    return ''
  }
  if (companyNames.length === 1) {
    return `${companyNames[0]}`
  }
  return `${companyNames.slice(0, -1).join(', ')} and ${companyNames.slice(-1)}`
}

const getStringLabel = (attribute: string | string[]): string => {
  if (Array.isArray(attribute)) {
    return attribute.join(', ')
  }
  return attribute
}

/**
 * Filter out variants that are invalid for pricing
 * @param variants
 */
const getValidVariants = (variants: Variant[]) => {
  return variants.filter(
    (variant: Variant) =>
      variant.active && variant.pricing?.total && variant.pricing.total > 0 && variant.pricing?.currency,
  )
}

/**
 * Fetches the exchange rates from the backend
 */
const getRates = async () => {
  const response = await fetch('/currency/fx?reciprocal=1', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    response
      .json()
      .then((data: any) => {
        ratesRef.value = data.rates
      })
      .catch((error: any) => {
        console.error('Unable to fetch exchange rates', error)
      })
  }
}

const generatingBrochureForUri = ref('')
const trip: Ref<Trip | null> = ref(null)
const { updateModalState } = useModal()
const isModalOpen: Ref<boolean> = ref(false)

const openGeneratedBrochureModal = () => {
  isModalOpen.value = true
  updateModalState(true)
}

const generateBrochure = async (vesselUri: string) => {
  generatingBrochureForUri.value = vesselUri
  const token = await authenticationContext.getToken()

  const res = await fetch(`/api/vessel/brochure/${vesselUri}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    trip.value = await res.json()
    openGeneratedBrochureModal()
  }
  generatingBrochureForUri.value = ''
}

const shareCalendars = () => {
  const url = `${window.location.origin.replace(/calendars|commodore/, 'radar')}?line_7=${encodeURIComponent(
    company.value?.name || '',
  )}`
  calendarsLinkCopied.value = false
  clearTimeout(linkCopiedTimeout!)
  navigator.clipboard.writeText(url)
  calendarsLinkCopied.value = true
  // reset the calendar link copied value so the users know they can copy the link again
  linkCopiedTimeout = setTimeout(() => {
    calendarsLinkCopied.value = false
  }, 3000)
}

// Modal helpers
const filtersModalHelper = modalHelper()
</script>
<template>
  <div class="h-screen max-w-[calc(100vw_-_4rem)] xl:max-w-[calc(100vw_-_23rem)]">
    <ais-configure filters="NOT tags:tombstone" hitsPerPage="20" />

    <div class="grid grid-cols-1 gap-4">
      <div class="flex justify-between items-center">
        <h3 class="mb-1" data-testId="page-title">My Fleet</h3>
        <OutlineFilter
          class="block md:hidden shrink-0 cursor-pointer size-4 me-2 transition-all hover:text-gray-400 dark:hover:text-gray-500"
          @click="filtersModalHelper.show({})"
        />
      </div>
      <div class="w-full flex flex-col md:flex-row justify-between items-center gap-2 pb-4 min-h-11">
        <OutlineFilter
          class="hidden md:block shrink-0 cursor-pointer size-4 me-2 transition-all hover:text-gray-400 dark:hover:text-gray-500"
          @click="filtersModalHelper.show({})"
        />
        <div class="w-full flex flex-col sm:flex-row items-start sm:items-center gap-2">
          <div class="w-full sm:w-auto flex flex-col sm:flex-row gap-2 items-center">
            <!-- nunununununununun -->
            <!--   Category Box    -->
            <!-- nunununununununun -->
            <!-- <ais-menu-select
                attribute="tags"
                :class-names="{
                  'ais-MenuSelect': 'tags-selector w-full sm:w-32 md:w-48',
                }"
              >
                <template v-slot="{ refine }">
                  <select
                    class="w-full sm:w-32 md:w-48 h-11 z-20 border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
                    @change=";[refine(($event.currentTarget! as HTMLInputElement).value)]"
                  >
                    <option value="">All Yachts</option>
                  </select>
                </template>
              </ais-menu-select> -->

            <!-- nunununununununun -->
            <!--    Search Box     -->
            <!-- nunununununununun -->
            <ais-search-box
              placeholder="Search for a Yacht..."
              submit-title="Submit"
              reset-title="clear"
              :autofocus="true"
              :show-loading-indicator="false"
              :class-names="{
                'ais-SearchBox': 'h-11 w-full',
                'ais-SearchBox-form':
                  'p-3 h-full flex items-center gap-x-2 relative rounded-lg border bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500',
                'ais-SearchBox-input':
                  'bg-transparent order-2 h-full w-full z-20 outline-none border-none focus:ring-0 focus:border-0 focus:outline-0 placeholder-gray-500 text-black dark:text-white p-0',
                'ais-SearchBox-reset': 'hidden',
                'ais-SearchBox-loadingIcon': 'hidden',
              }"
            >
              <template v-slot:submit-icon>
                <SolidSearch
                  class="order-first solid-search z-20 pointer-events-none w-5 h-5 stroke-2 fill-general-text-body"
                />
              </template>
            </ais-search-box>

            <!--  nunununununununun  -->
            <!--         Sort        -->
            <!--  nunununununununun  -->
            <ais-sort-by
              :items="sortBy"
              :class-names="{
                'ais-SortBy': 'w-full sm:w-32 md:w-48',
              }"
            >
              <template v-slot="{ currentRefinement, items, refine }">
                <select
                  class="w-full sm:w-32 md:w-48 h-11 z-20 border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
                  @change=";[refine(($event.currentTarget! as HTMLInputElement).value)]"
                >
                  <option
                    v-for="item in items"
                    :key="item.value"
                    :value="item.value"
                    :selected="item.value === currentRefinement"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </template>
            </ais-sort-by>
            <!--  nunununununununun  -->
            <!--     Share Fleet     -->
            <!--  nunununununununun  -->
            <button
              type="button"
              class="transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white bg-primary-600 dark:bg-primary-500 disabled:bg-gray-200 dark:disabled:bg-gray-600 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 focus:bg-primary-600 dark:focus:bg-primary-500"
              :disabled="!company"
              :class="{
                'cursor-not-allowed': !company,
              }"
              @click.stop="shareCalendars"
            >
              <Spinner v-if="!companyLoaded" class="w-5 h-5 mr-1"></Spinner>
              <span v-else class="flex justify-center items-center w-max">
                <OutlineLink class="size-5 mr-1"></OutlineLink>
                <span v-if="!calendarsLinkCopied">Share Fleet</span>
                <span v-else>Link Copied</span>
              </span>
            </button>
          </div>

          <!-- nununununununununu -->
          <!--  Views on desktop  -->
          <!-- nununununununununu -->
          <div class="hidden md:block border-b border-gray-200 dark:border-gray-700">
            <ul class="flex flex-nowrap -mb-px text-center text-gray-500 dark:text-gray-400">
              <li>
                <button
                  class="transition-colors inline-flex items-center justify-center p-2 border-b-2 rounded-t-lg group"
                  :class="[
                    preferredViewStore.preferredView === 'timeline'
                      ? 'active text-primary-600 dark:text-primary-500 border-primary-600 dark:border-primary-500'
                      : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-400 dark:hover:border-gray-400 dark:text-gray-500',
                  ]"
                  @click.stop="preferredViewStore.preferredView = 'timeline'"
                >
                  <SolidTable class="size-4 me-2" />Timeline
                </button>
              </li>
              <li>
                <button
                  class="transition-colors inline-flex items-center justify-center p-2 border-b-2 rounded-t-lg group"
                  :class="[
                    preferredViewStore.preferredView === 'card'
                      ? 'active text-primary-600 dark:text-primary-500 border-primary-600 dark:border-primary-500'
                      : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-400 dark:hover:border-gray-400 dark:text-gray-500',
                  ]"
                  @click.stop="preferredViewStore.preferredView = 'card'"
                >
                  <SolidCollection class="size-4 me-2" />Cards
                </button>
              </li>
              <li>
                <button
                  class="transition-colors inline-flex items-center justify-center p-2 border-b-2 rounded-t-lg group"
                  :class="[
                    preferredViewStore.preferredView === 'table'
                      ? 'active text-primary-600 dark:text-primary-500 border-primary-600 dark:border-primary-500'
                      : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-400 dark:hover:border-gray-400 dark:text-gray-500',
                  ]"
                  @click.stop="preferredViewStore.preferredView = 'table'"
                >
                  <SolidTable class="size-4 me-2" />Table
                </button>
              </li>
            </ul>
          </div>
        </div>

        <!--  nunununununununun  -->
        <!--        Stats        -->
        <!--  nunununununununun  -->
        <ais-stats class="hidden xl:block text-sm min-w-36 text-right text-gray-500 dark:text-gray-400">
          <template v-slot="{ nbHits }">
            <template v-if="nbHits - dirtyRiver.filter((item) => item === null).length === 0">
              <span>No Yachts</span>
            </template>
            <template v-else-if="nbHits - dirtyRiver.filter((item) => item === null).length === 1"> 1 Yacht </template>
            <template v-else> {{ nbHits - dirtyRiver.filter((item) => item === null).length }} Yachts </template>
          </template>
        </ais-stats>
      </div>
    </div>

    <!-- nunununununununun -->
    <!--  Search Results   -->
    <!-- nunununununununun -->
    <ais-infinite-hits :transform-items="accumulateSearchResults">
      <template v-slot="{ refineNext, isLastPage, results }">
        <div id="searchResults-top"></div>

        <!-- Getting Started and No Results -->
        <!-- add '?showGetStarted=1' to show that screen manually -->
        <template v-if="!results.nbHits || $route.query.showGetStarted">
          <slot name="no-results">
            <!-- No results. -->
            <div class="grid grid-cols-1 gap-4 justify-items-center pt-16">
              <!-- <div class="h-[33vh]"><img :src="SearchNoResultsMoon" alt="No results found" class="h-full" /></div> -->
              <h4>No results.</h4>
              <div>Sorry! We could not find anything to match your query.</div>
            </div>
          </slot>
        </template>

        <!-- Show results -->
        <template v-else>
          <!-- ... in a table -->
          <template v-if="preferredViewStore.preferredView === 'table'">
            <ListTable
              rowKey="uri"
              class="hidden md:table w-full table-fixed"
              :columns="[
                { label: 'Yacht Name', field: 'name', type: 'string', classes: { th: 'w-1/5', td: 'truncate' } },
                {
                  label: 'Central Agent',
                  field: 'companies',
                  type: 'string',
                  classes: { th: 'w-32' },
                },
                {
                  label: 'Term Price',
                  field: 'priceTerm',
                  type: 'string',
                  classes: { th: 'w-24', td: 'whitespace-nowrap' },
                },
                {
                  label: 'Day Price',
                  field: 'priceDay',
                  type: 'string',
                  classes: { th: 'w-24', td: 'whitespace-nowrap' },
                },
                {
                  label: 'Hourly Price',
                  field: 'priceHour',
                  type: 'string',
                  classes: { th: 'w-24', td: 'whitespace-nowrap' },
                },
                {
                  label: 'Length',
                  field: 'length',
                  type: 'number',
                  classes: { th: 'w-24' },
                },
                {
                  label: 'Category',
                  field: 'category',
                  type: 'string',
                  classes: { th: 'hidden 2xl:table-cell w-24', td: 'hidden 2xl:table-cell whitespace-nowrap' },
                },
                {
                  label: 'Cabins',
                  field: 'numCabins',
                  type: 'number',
                  classes: { th: 'hidden 2xl:table-cell w-24', td: 'hidden 2xl:table-cell whitespace-nowrap' },
                },
                {
                  label: 'Cruising Speed',
                  field: 'cruisingSpeed',
                  type: 'string',
                  classes: { th: 'hidden 2xl:table-cell w-24', td: 'hidden 2xl:table-cell whitespace-nowrap' },
                },
                {
                  label: 'Bathrooms',
                  field: 'numBathrooms',
                  type: 'number',
                  classes: { th: 'hidden 2xl:table-cell w-24', td: 'hidden 2xl:table-cell whitespace-nowrap' },
                },
                {
                  label: 'Brochure Link',
                  field: 'brochureLink',
                  classes: { th: 'w-24', td: 'whitespace-nowrap' },
                },
              ]"
              :rows="river"
              :classes="{ thead: 'top-16', tr: 'cursor-pointer' }"
              @rowClick="
                ({ row, field }) => {
                  if (field === 'brochureLink') {
                    generateBrochure(row.uri)
                  } else {
                    $router.push({ name: 'view-vessel', params: { uri: encodeURIComponent(row.uri) } })
                  }
                }
              "
            >
              <!-- column: IMAGE/NAME/TAGS -->
              <template #name="{ row }">
                <div class="flex items-center gap-x-2">
                  <div class="min-w-20 max-w-20 h-12">
                    <AssetViewerPlaceholder
                      class="object-cover w-full h-full rounded"
                      :url="row.hero"
                      :blur="row.blur"
                      :widthDescriptors="['320w']"
                    />
                  </div>
                  <div class="w-full flex justify-between self-center">
                    <span class="font-semibold truncate">{{ row.name || UNNAMED_YACHT }}</span>
                  </div>
                </div>
              </template>
              <!-- column: LENGTH -->
              <template #length="{ row }">
                <span v-if="row.length" class="text-sm">
                  {{ row.length }}m ({{ (row.length * 3.2808).toFixed(1) }}ft)
                </span>
              </template>
              <!-- column: Brochure Link -->
              <template #brochureLink="{ row }">
                <div role="cell">
                  <div
                    v-if="generatingBrochureForUri === row.uri"
                    role="status"
                    class="w-4 h-4 shrink-0 mx-auto"
                    @click="console.log('clicked')"
                  >
                    <svg
                      aria-hidden="true"
                      class="animate-spin text-gray-200 fill-primary-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div
                    v-else
                    :data-popover-target="`copy-popover-${row.uri}`"
                    data-popover-placement="left"
                    class="w-min mx-auto p-1 hover:bg-gray-200 hover:dark:bg-gray-800 rounded-md"
                  >
                    <OutlineClipboardCopy
                      class="w-4 h-4 shrink-0"
                      :class="
                        !generatingBrochureForUri
                          ? 'text-gray-500 dark:text-gray-400 cursor-pointer'
                          : 'text-gray-300 dark:text-gray-600 pointer-events-none'
                      "
                    />
                  </div>
                  <div
                    data-popover
                    :id="`copy-popover-${row.uri}`"
                    role="tooltip"
                    class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
                  >
                    <div class="px-3 py-2">
                      <p>Copy Link</p>
                    </div>
                    <div data-popper-arrow></div>
                  </div>
                </div>
              </template>
              <template #after>
                <div class="hidden md:block">
                  <template v-if="!isLastPage && !dirtyRiver.length"><Loader /></template>
                  <template v-else-if="!isLastPage">
                    <VisibleObserver @visible="refineNext" />
                  </template>
                  <template v-else>
                    <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                  </template>
                </div>
              </template>
            </ListTable>
            <ListTableAccordion
              rowKey="uri"
              class="block md:hidden"
              :columns="[
                {
                  label: 'Name',
                  field: 'name',
                  type: 'string',
                  classes: {
                    th: 'w-full',
                    td: 'w-[calc(100vw-13.8rem)] sm:w-[calc(100vw-14.8rem)] font-semibold truncate',
                  },
                },
                {
                  label: 'Length',
                  field: 'length',
                  type: 'number',
                  classes: { th: 'w-24', td: 'w-20 whitespace-nowrap' },
                },
                { label: '', field: 'actions', type: 'number', classes: { th: 'min-w-14 w-14', td: 'w-14' } },
              ]"
              :rows="river"
              :classes="{ thead: 'top-16', tr: 'cursor-pointer' }"
              @rowClick="
                ({ row, field }) =>
                  'brochureLink' !== field &&
                  $router.push({ name: 'view-vessel', params: { uri: encodeURIComponent(row.uri) } })
              "
            >
              <template #length="{ row }">
                <span>{{ row.length }}m</span>
              </template>
              <!-- Accordion content -->
              <template #accordion-content="{ row }">
                <div class="col-span-2 min-w-64 max-w-64 h-48">
                  <AssetViewerPlaceholder
                    class="object-cover w-full h-full rounded"
                    :url="row.hero"
                    :blur="row.blur"
                    :widthDescriptors="['320w']"
                  />
                </div>
                <template
                  v-for="col of [
                    {
                      label: 'Central Agent',
                      field: 'companies',
                      type: 'string',
                    },
                    {
                      label: 'Term Price',
                      field: 'priceTerm',
                      type: 'string',
                    },
                    {
                      label: 'Day Price',
                      field: 'priceDay',
                      type: 'string',
                    },
                    {
                      label: 'Hourly Price',
                      field: 'priceHour',
                      type: 'string',
                    },
                    {
                      label: 'Length',
                      field: 'length',
                      type: 'number',
                    },
                    {
                      label: 'Category',
                      field: 'category',
                      type: 'string',
                    },
                    {
                      label: 'Cabins',
                      field: 'numCabins',
                      type: 'number',
                    },
                    {
                      label: 'Cruising Speed',
                      field: 'cruisingSpeed',
                      type: 'string',
                    },
                    {
                      label: 'Bathrooms',
                      field: 'numBathrooms',
                      type: 'number',
                    },
                  ]"
                  :key="col.label"
                >
                  <div v-if="row[col.field]" class="flex flex-col gap-y-1">
                    <span class="text-xs font-semibold text-gray-700 dark:text-gray-300">
                      {{ col.label }}
                    </span>
                    <span class="text-sm text-gray-500 dark:text-gray-400">
                      {{ row[col.field] }}
                    </span>
                  </div>
                </template>
              </template>
              <!-- Observer -->
              <template #after>
                <div class="block md:hidden">
                  <template v-if="!isLastPage && !dirtyRiver.length"><Loader /></template>
                  <template v-else-if="!isLastPage">
                    <VisibleObserver @visible="refineNext" />
                  </template>
                  <template v-else>
                    <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                  </template>
                </div>
              </template>
            </ListTableAccordion>
          </template>
          <!-- ... in grid cards -->
          <template v-else-if="preferredViewStore.preferredView === 'card'">
            <ListGrid :keyField="'uri'" :items="river">
              <template #item="{ item }">
                <!-- Yacht Card -->
                <div>
                  <RouterLink
                    class="inline-block w-full aspect-[1.44] relative hover:scale-105 focus:ring-4 focus:outline-none focus:ring-primary-300 cursor-pointer rounded-lg transition-transform duration-500 ease-in-out"
                    :to="{ name: 'view-vessel', params: { uri: encodeURIComponent(item.uri) } }"
                  >
                    <AssetViewerPlaceholder
                      class="w-full object-cover shadow-md rounded-lg"
                      :url="item.hero"
                      :blur="item.blur"
                      :widthDescriptors="['320w']"
                    />
                  </RouterLink>

                  <!-- Yacht Card Text Section -->
                  <div class="mt-2 flex gap-x-1">
                    <h6 class="w-full self-center line-clamp">
                      {{ item.name || UNNAMED_YACHT }}
                    </h6>
                    <!-- <YachtsActionsKebab :item="{ uri: item.uri, type: ProposalItemType.VESSEL, line_1: item.line_1 }" /> -->
                  </div>
                  <p v-if="item.companies" class="text-sm text-gray-500 dark:text-gray-400">
                    {{ item.companies }}
                  </p>
                  <div class="flex flex-row items-center gap-x-2.5 pt-2 pb-4 text-sm flex-wrap">
                    <div v-if="item.length" class="flex items-center self-start gap-x-1.5">
                      <LineArtMeasuringtape class="size-4" />
                      <span>{{ item.length }}m ({{ (item.length * 3.2808).toFixed(1) }}ft)</span>
                    </div>
                    <div v-if="item.buildYear" class="flex items-center self-start gap-x-1.5">
                      <OutlineCalendarThin class="size-4" />
                      <span>{{ item.buildYear }}</span>
                    </div>

                    <div v-if="item.numCabins" class="flex items-center self-start gap-x-1.5">
                      <OutlineCabinDoorIcon class="size-4" />
                      <span>{{ item.numCabins }}</span>
                    </div>
                    <div v-if="item.builder" class="flex items-center self-start gap-x-1.5">
                      <OutlineWrenchScrewdriver class="size-4" />
                      <span>{{ item.builder }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template #after>
                <template v-if="!isLastPage && !dirtyRiver.length"><Loader /></template>
                <template v-else-if="!isLastPage">
                  <VisibleObserver @visible="refineNext" />
                </template>
                <template v-else>
                  <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                </template>
              </template>
            </ListGrid>
          </template>

          <!-- ... in timeline -->
          <template v-else>
            <!-- This empty state also accounts for the when no results are found from search -->
            <div v-if="!river.length" class="flex flex-col items-center mt-7 gap-y-2.5">
              <OutlineAddYacht class="w-12 h-12 text-gray-500 dark:text-gray-400" />
              <h4 class="font-bold text-black dark:text-white">No Yachts</h4>
              <p class="w-64 text-center">Get started by adding any yacht in your CA fleet.</p>
              <!-- claim  -->
              <RouterLink :to="{ name: 'claim-vessel', params: { step: 'find' } }">
                <ButtonVue id="claim-a-yacht-empty-state" name="Claim a yacht" position="prefix" isPrimary>
                  <OutlinePlus class="w-4 h-4" />
                </ButtonVue>
              </RouterLink>
            </div>

            <div v-else class="relative flex overflow-hidden h-full border-t border-gray-200 dark:border-gray-700">
              <!-- hits and actions -->
              <div
                class="sticky left-0 pb-3.5 z-20 h-[calc(100vh-10.4rem)] overflow-y-auto overflow-x-hidden scrollbar-hide bg-white dark:bg-gray-900 border-l border-gray-200 dark:border-gray-700 min-w-[11rem] md:min-w-[32rem]"
                id="vessel-list"
                :ref="(el) => (hitsElementRef = el)"
                @scroll="onScrollHits"
              >
                <!-- Placeholder for actions -->
                <div
                  class="h-28 sticky snap-y top-0 grid border-b border-r z-10 border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900"
                ></div>

                <!-- hits -->
                <ul>
                  <li
                    v-for="hit in river"
                    class="group relative w-full items-center p-4 min-h-[15rem] md:min-h-[11.25rem] transition-all duration-300 border-b border-r border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900"
                    :key="hit.objectID"
                    :style="{ height: `${timelineRows[hit.uri]}px` }"
                  >
                    <RouterLink
                      class="relative w-full flex flex-col md:flex-row gap-y-4 md:gap-y-0 flex-nowrap group-hover:scale-105 duration-300 cursor-pointer"
                      :to="{ name: 'view-vessel', params: { uri: encodeURIComponent(hit.uri) } }"
                    >
                      <div class="flex flex-col w-full md:w-52 md:min-w-[13rem] h-[5.75rem] md:h-[9.25rem]">
                        <AssetViewerPlaceholder
                          class="w-full object-cover shadow-md rounded-lg"
                          :url="hit.hero"
                          :blur="hit.blur"
                          :widthDescriptors="['320w']"
                        />
                      </div>
                      <div class="flex flex-col items-start md:pl-4 leading-normal overflow-hidden">
                        <div class="w-full flex justify-between gap-x-2">
                          <h6 class="uppercase leading-tight line-clamp-1 font-bold">{{ hit.line_1 }}</h6>
                          <!-- The requirements is to only show the badge for inactive vessels -->
                          <span
                            v-if="hit.vessel.visibility === VesselVisibility.PRIVATE"
                            class="h-fit text-xs font-medium px-2.5 py-0.5 rounded bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300"
                          >
                            Inactive
                          </span>
                        </div>
                        <div class="line-clamp-1 text-[0.625rem] text-gray-500 dark:text-gray-400 sm:text-xs">
                          {{ formatManagedBy(hit.line_7) }}
                        </div>
                        <div
                          v-if="hit.line_2 || hit.line_3 || hit.line_4 || hit.line_5"
                          class="flex flex-wrap gap-x-2 text-[0.625rem] sm:text-xs mt-2 text-gray-500 dark:text-gray-400 stroke-gray-500 dark:stroke-gray-400"
                        >
                          <div v-if="hit.line_2" class="flex items-center gap-x-2">
                            <LineArtMeasuringtape class="h-4 w-4 shrink-0" />
                            {{ formatLengthWithSpaceSeparator(hit.line_2) }}
                          </div>
                          <div v-if="hit.line_3" class="flex items-center gap-x-2">
                            <OutlineCabinDoorIcon class="h-4 w-4 shrink-0" />
                            {{ getStringLabel(hit.line_3) }}
                          </div>
                          <div v-if="hit.line_5" class="flex items-center gap-x-2">
                            <OutlineCalendarThin class="h-4 w-4 shrink-0" />
                            {{ getStringLabel(String(hit.line_5)) }}
                          </div>
                          <div v-if="hit.line_4" class="flex items-center gap-x-2">
                            <OutlineUserGroupThin class="h-4 w-4 shrink-0 stroke-1" />
                            {{ getStringLabel(hit.line_4) }}
                          </div>
                        </div>

                        <PricingInCard
                          v-if="hit.vessel.variants?.length"
                          class="max-w-[11rem] flex-grow mt-2 md:mt-4"
                          :rates="ratesRef"
                          :isTimeline="true"
                          :variants="getValidVariants(hit.vessel.variants)"
                        />
                      </div>
                    </RouterLink>
                  </li>
                </ul>

                <!-- claim  -->
                <div
                  class="h-20 flex justify-center items-center border-b border-r border-gray-200 dark:border-gray-600"
                >
                  <RouterLink
                    class="py-2.5 px-5 me-2 inline-flex items-center gap-x-1 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    :to="{ name: 'claim-vessel', params: { step: 'find' } }"
                  >
                    <OutlinePlus class="w-4 h-4" />
                    Claim Yacht
                  </RouterLink>
                </div>

                <template v-if="!isLastPage">
                  <VisibleObserver @visible="refineNext" />
                </template>
                <div v-else class="mt-8 text-center text-sm">You have reached the end of the results!</div>
              </div>

              <!-- event timeline -->
              <Timeline
                :vessels="mapItemsToVesselProps(river)"
                :scrollPosition="hitsElementScrollPosition"
                @scroll="onScrollTimeline"
                @update:timeline:rows:height="(ev: Record<string, number>) => timelineRows = ev"
              />
            </div>
          </template>
        </template>
      </template>
    </ais-infinite-hits>

    <ModalContentWrapper v-if="trip">
      <BrochureGeneratedModalContent :trip="trip!"></BrochureGeneratedModalContent>
    </ModalContentWrapper>

    <!-- Filters modal -->
    <Teleport to="body">
      <SimpleModal
        v-show="filtersModalHelper.isVisible()"
        title="Advanced filters"
        :class="{ hidden: !filtersModalHelper.isVisible() }"
        @dismiss="filtersModalHelper.hide()"
      >
        <!-- Facet filters -->
        <ais-dynamic-widgets class="grid grid-cols-1 sm:grid-cols-2 gap-4" :facets="['*']">
          <!-- Charter type -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Charter type</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.variants.pricing.unit"
                searchable
                show-more
                :limit="3"
              >
                <template v-slot="{ items, isFromSearch, refine, createURL }">
                  <p v-if="isFromSearch && !items.length" class="text-xs">No results.</p>
                  <ul v-else class="flex flex-col flex-wrap gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox
                        :item="item"
                        :refine="refine"
                        :create-url="createURL"
                        :text="item.value === 'HOUR' ? 'Day Charter' : 'Term Charter'"
                      />
                    </li>
                  </ul>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Yacht Type -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Yacht Type</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.yachtType"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{ items, isShowingMore, isFromSearch, canToggleShowMore, refine, createURL, toggleShowMore }"
                >
                  <p v-if="isFromSearch && !items.length" class="text-xs">No results.</p>
                  <ul v-else class="flex flex-col gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox :item="item" :refine="refine" :create-url="createURL" />
                    </li>
                  </ul>
                  <button
                    v-if="canToggleShowMore"
                    class="text-xs text-blue-600 dark:text-blue-400 text-left"
                    :disabled="!canToggleShowMore"
                    @click="toggleShowMore"
                  >
                    {{ !isShowingMore ? 'Show more' : 'Show less' }}
                  </button>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Charter Type -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Crewed / Bareboat</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.charterType"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{ items, isShowingMore, isFromSearch, canToggleShowMore, refine, createURL, toggleShowMore }"
                >
                  <p v-if="isFromSearch && !items.length" class="text-xs">No results.</p>
                  <ul v-else class="flex flex-col gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox :item="item" :refine="refine" :create-url="createURL" />
                    </li>
                  </ul>
                  <button
                    v-if="canToggleShowMore"
                    class="text-xs text-blue-600 dark:text-blue-400 text-left"
                    :disabled="!canToggleShowMore"
                    @click="toggleShowMore"
                  >
                    {{ !isShowingMore ? 'Show more' : 'Show less' }}
                  </button>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Category -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Category</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.category"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{ items, isShowingMore, isFromSearch, canToggleShowMore, refine, createURL, toggleShowMore }"
                >
                  <p v-if="isFromSearch && !items.length" class="text-xs">No results.</p>
                  <ul v-else class="flex flex-col gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox :item="item" :refine="refine" :create-url="createURL" />
                    </li>
                  </ul>
                  <button
                    v-if="canToggleShowMore"
                    class="text-xs text-blue-600 dark:text-blue-400 text-left"
                    :disabled="!canToggleShowMore"
                    @click="toggleShowMore"
                  >
                    {{ !isShowingMore ? 'Show more' : 'Show less' }}
                  </button>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Length range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Length (m)</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.length">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Cabins range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Cabins</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.cabins">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Bathrooms range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Bathrooms</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.bathrooms">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Decks range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Decks</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.decks">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Guests/sleep range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Sleeps</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.sleeps">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Cruising Capacity -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Cruising Capacity</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.cruisingCapacity">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Static Capacity -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Static Capacity</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.staticCapacity">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Draft range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Draft</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.draft">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Beam range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Beam</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.beam">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Cruising Speed range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Cruising speed</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.cruiseSpeed">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Crew range -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Crew</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.maxCrew">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Build year -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Build Year</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.builtYear">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Refit year -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Refit Year</h6>
            </template>
            <template #default>
              <ais-range-input class="mb-4" attribute="vessel.blueprint.refitYear">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <AlgoliaNumberRangeInput
                    :current-refinement="currentRefinement"
                    :range="range"
                    :can-refine="canRefine"
                    :refine="refine"
                  />
                </template>
              </ais-range-input>
            </template>
          </ais-panel>

          <!-- Allows pets -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Pets</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.variants.petsAllowed"
                searchable
                show-more
                :limit="3"
              >
                <template v-slot="{ items, refine, createURL }">
                  <ul class="flex flex-wrap gap-2">
                    <li v-for="item in items" :key="item.value">
                      <AlgoliaCheckbox
                        :item="item"
                        :refine="refine"
                        :create-url="createURL"
                        :text="item.value === 'true' ? 'Allows Pets' : 'Does not allow Pets'"
                      />
                    </li>
                  </ul>
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Toys -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Toys</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.variants.toys.label"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{
                    items,
                    isShowingMore,
                    isFromSearch,
                    canToggleShowMore,
                    refine,
                    createURL,
                    toggleShowMore,
                    searchForItems,
                  }"
                >
                  <AlgoliaRefinementList
                    placeholder="Search for Toys"
                    :items="items"
                    :is-showing-more="isShowingMore"
                    :is-from-search="isFromSearch"
                    :can-toggle-show-more="canToggleShowMore"
                    :refine="refine"
                    :create-u-r-l="createURL"
                    :toggle-show-more="toggleShowMore"
                    :search-for-items="searchForItems"
                  />
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Amenities -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Amenities</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.variants.amenities.label"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{
                    items,
                    isShowingMore,
                    isFromSearch,
                    canToggleShowMore,
                    refine,
                    createURL,
                    toggleShowMore,
                    searchForItems,
                  }"
                >
                  <AlgoliaRefinementList
                    placeholder="Search for Amenities"
                    :items="items"
                    :is-showing-more="isShowingMore"
                    :is-from-search="isFromSearch"
                    :can-toggle-show-more="canToggleShowMore"
                    :refine="refine"
                    :create-u-r-l="createURL"
                    :toggle-show-more="toggleShowMore"
                    :search-for-items="searchForItems"
                  />
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Entertainment -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Entertainment</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.variants.entertainment"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{
                    items,
                    isShowingMore,
                    isFromSearch,
                    canToggleShowMore,
                    refine,
                    createURL,
                    toggleShowMore,
                    searchForItems,
                  }"
                >
                  <AlgoliaRefinementList
                    placeholder="Search for Entertainment"
                    :items="items"
                    :is-showing-more="isShowingMore"
                    :is-from-search="isFromSearch"
                    :can-toggle-show-more="canToggleShowMore"
                    :refine="refine"
                    :create-u-r-l="createURL"
                    :toggle-show-more="toggleShowMore"
                    :search-for-items="searchForItems"
                  />
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>

          <!-- Builder -->
          <ais-panel>
            <template #header>
              <h6 class="mb-2 text-black dark:text-white">Builder</h6>
            </template>
            <template #default>
              <ais-refinement-list
                class="flex flex-col gap-y-2 mb-4"
                attribute="vessel.blueprint.make"
                searchable
                show-more
                :limit="3"
                :class-names="{
                  'ais-SearchBox-input': 'text-black rounded-md',
                  'ais-SearchBox-submit': 'hidden',
                  'ais-SearchBox-reset': 'hidden',
                }"
              >
                <template
                  v-slot="{
                    items,
                    isShowingMore,
                    isFromSearch,
                    canToggleShowMore,
                    refine,
                    createURL,
                    toggleShowMore,
                    searchForItems,
                  }"
                >
                  <AlgoliaRefinementList
                    placeholder="Search for Builder"
                    :items="items"
                    :is-showing-more="isShowingMore"
                    :is-from-search="isFromSearch"
                    :can-toggle-show-more="canToggleShowMore"
                    :refine="refine"
                    :create-u-r-l="createURL"
                    :toggle-show-more="toggleShowMore"
                    :search-for-items="searchForItems"
                  />
                </template>
              </ais-refinement-list>
            </template>
          </ais-panel>
        </ais-dynamic-widgets>
      </SimpleModal>
    </Teleport>
  </div>
</template>
