<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { DateTime, Interval } from 'luxon'
import { Ref, onMounted, ref, watch } from 'vue'

import { CalendarView, CalendarViewEnum, DateValue } from '@ankor-io/common/calendar/types'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import { Season } from '@ankor-io/common/vessel/types'
import { Post } from '@ankor-io/feed-endpoint/src/types'
import { OutlineCalendar, OutlineShare } from '@ankor-io/icons/outline'
import { SolidChevronLeft, SolidChevronRight, SolidFire } from '@ankor-io/icons/solid'

import ButtonVue from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import { getWeekRangeText } from '@/components/calendar/util/week'
import { COMMODORE_20250218_MMK_INTEGRATION } from '@/utils/growthbook/constants'

const props = defineProps<{
  view: CalendarView
  dateValue: DateValue
  seasons: Season[]
  specials: Post[]
  loading: boolean
}>()

const isActiveSpecial: Ref<boolean> = ref(false)

const showDateSelector: Ref<boolean> = ref(false)
const dateSelectorPage: Ref<number> = ref(1)

const emit = defineEmits<{
  (e: 'add:event'): void
  (e: 'update:date:to:today'): void
  (e: 'update:view', view: CalendarView): void
  (e: 'update:date:year', value: number): void
  (e: 'update:date:month', value: number): void
  (e: 'update:date:week', value: number): void
  (e: 'open:calendar:sharing'): void
}>()

onMounted(() => {
  const interval = getCalendarMonthInterval(props.dateValue.year, props.dateValue.month)
  isActiveSpecial.value = isSpecialActive(props.specials, interval)
})

watch(
  () => props.dateValue,
  (newValue, oldValue) => {
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
      return
    }

    const interval = getCalendarMonthInterval(newValue.year, newValue.month)
    isActiveSpecial.value = isSpecialActive(props.specials, interval)
  },
)

/**
 * Get the interval for a full calendar month
 * @param year The year
 * @param month The month
 */
const getCalendarMonthInterval = (year: number, month: number): Interval => {
  const dateTime = DateTime.now().set({
    year: year,
    month: month,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

  return Interval.fromDateTimes(
    dateTime.set({ day: 1 }),
    dateTime.set({ month: dateTime.month + 1, day: 1 }).minus({ days: 1 }),
  )
}

// The special needs to end in the future and overlap in the current calendar month
const isSpecialActive = (posts: Post[], interval: Interval) => {
  if (!posts.length) {
    return false
  }

  for (const post of posts) {
    if (post.eventInterval) {
      const dateTime = DateTime.now()
      const date = ISO8601.fromIntervalString(post.eventInterval)
      const intervalString = ISO8601.toIntervalString(interval.start!, interval.end!)
      if (date.end! >= dateTime && ISO8601.overlapsAny(intervalString, [date.toISO()])) {
        return true
      }
    }
  }

  return false
}

/**
 * Page back for date picker
 */
const handleDatePrevious = (): void => {
  switch (props.view) {
    case CalendarViewEnum.YEAR: {
      if (showDateSelector.value) {
        dateSelectorPage.value -= dateSelectorPage.value === 1 ? 2 : 1
        return
      }
      emit('update:date:year', -1)
      return
    }
    case CalendarViewEnum.MONTH: {
      if (showDateSelector.value) {
        // Overflow to previous year
        emit('update:date:year', -1)
        return
      }
      // Go back by 1 month
      emit('update:date:month', -1)
      return
    }
    case CalendarViewEnum.WEEK: {
      if (showDateSelector.value) {
        // Overflow to previous year
        emit('update:date:year', -1)
        return
      }
      // Go back by 1 week
      emit('update:date:week', -1)
      return
    }
  }
}

/**
 * Page forward for date picker
 */
const handleDateNext = (): void => {
  switch (props.view) {
    case CalendarViewEnum.YEAR: {
      if (showDateSelector.value) {
        dateSelectorPage.value += dateSelectorPage.value === -1 ? 2 : 1
        return
      }
      emit('update:date:year', 1)
      return
    }
    case CalendarViewEnum.MONTH: {
      if (showDateSelector.value) {
        // Overflow to next year
        emit('update:date:year', 1)
        return
      }
      // Go forward by 1 month
      emit('update:date:month', 1)
      return
    }
    case CalendarViewEnum.WEEK: {
      if (showDateSelector.value) {
        // Overflow to next year
        emit('update:date:year', 1)
        return
      }
      // Go forward by 1 week
      emit('update:date:week', 1)
      return
    }
  }
}

/**
 * Returns an array of 9 dates in the future or in the past
 */
const getYearSelectorPageDates = (): number[] => {
  const arr = []
  if (dateSelectorPage.value >= 1) {
    // Will show the next 9 years in asc based on which page they're on - goes to positive page numbers
    for (let i = dateSelectorPage.value * 9 - 9; i < dateSelectorPage.value * 9; i++) {
      arr.push(props.dateValue.year + i)
    }
  } else {
    // Will show the previous 9 years in desc based on which page they're on - goes to negative page numbers
    for (let i = dateSelectorPage.value * 9; i < dateSelectorPage.value * 9 + 9; i++) {
      arr.push(props.dateValue.year + i)
    }
  }

  return arr
}

/**
 * Checks if the value provided is equal to the prop value for boolean comparison
 * @param value A given date value to compare with
 */
const doesValueMatchSelectedDateValue = (value: number): boolean => {
  if (props.view === CalendarViewEnum.YEAR && props.dateValue.year === value) {
    return true
  }

  if (props.view === CalendarViewEnum.MONTH && props.dateValue.month === value) {
    return true
  }

  if (props.view === CalendarViewEnum.WEEK && props.dateValue.month === value) {
    return true
  }

  return false
}

/**
 * Sets the new date value from the date picker
 * @param item An item from the date picker
 */
const selectDateFromSelector = (item: number) => {
  showDateSelector.value = false
  // Early return on same value
  if (doesValueMatchSelectedDateValue(item)) {
    return
  }

  if (props.view === CalendarViewEnum.YEAR) {
    emit('update:date:year', item - props.dateValue.year)
  }

  if (props.view === CalendarViewEnum.MONTH) {
    emit('update:date:month', item - props.dateValue.month)
  }

  if (props.view === CalendarViewEnum.WEEK) {
    const firstFullWeekOfMonth = DateTime.fromObject({
      year: props.dateValue.year,
      month: item,
      day: 7,
    })
    emit('update:date:week', firstFullWeekOfMonth.weekNumber - props.dateValue.week)
  }
  dateSelectorPage.value = 1
}

/**
 * Closes the date selector from OnClickOutside trigger
 */
const closeDateSelector = (): void => {
  showDateSelector.value = false
  dateSelectorPage.value = 1
}

/**
 * Toggles the date selector open/close from the date selector button itself
 */
const toggleDateSelector = (): void => {
  showDateSelector.value = !showDateSelector.value
  dateSelectorPage.value = 1
}

/**
 * Returns an array of seasons that match the current view
 * @param seasons An array of seasons to filter
 */
const getMatchingSeasons = (seasons: Season[]): Season[] => {
  switch (props.view) {
    // Year view will show all seasons that overlap with the year
    case CalendarViewEnum.YEAR: {
      const calendarStart = DateTime.fromObject({
        year: props.dateValue.year,
        month: 1,
        day: 1,
        hour: 0,
        minute: 0,
        second: 0,
      })
      const calendarEnd = DateTime.fromObject({
        year: props.dateValue.year,
        month: 12,
        day: 31,
        hour: 23,
        minute: 59,
        second: 59,
      })
      // Generate the interval string for the year
      const intervalString = `${calendarStart}/${calendarEnd}`

      return seasons.filter((season) => {
        return season.intervals?.some((interval) => {
          if (!interval) {
            return false
          }

          return ISO8601.overlapsAny(intervalString, [interval])
        })
      })
    }

    // Month view will show all seasons that overlap with the month
    case CalendarViewEnum.MONTH: {
      const calendarStart = DateTime.fromObject(
        {
          year: props.dateValue.year,
          month: props.dateValue.month,
          day: 1,
          hour: 0,
          minute: 0,
          second: 0,
        },
        { zone: 'utc' },
      )
      const calendarEnd = DateTime.fromObject(
        {
          year: props.dateValue.year,
          month: props.dateValue.month,
          day: DateTime.fromObject({
            year: props.dateValue.year,
            month: props.dateValue.month,
          }).daysInMonth,
          hour: 23,
          minute: 59,
          second: 59,
        },
        { zone: 'utc' },
      )

      // Generate the interval string for the month
      const intervalString = `${calendarStart}/${calendarEnd}`

      return seasons.filter((season) => {
        return season.intervals?.some((interval) => {
          if (!interval) {
            return false
          }

          return ISO8601.overlapsAny(intervalString, [interval])
        })
      })
    }

    // Week view will show all seasons that overlap with the week
    case CalendarViewEnum.WEEK: {
      const calendarStart = DateTime.fromObject({
        year: props.dateValue.year,
        month: props.dateValue.month,
        day: props.dateValue.week * 7 - 6,
        hour: 0,
        minute: 0,
        second: 0,
      })
      const calendarEnd = DateTime.fromObject({
        year: props.dateValue.year,
        month: props.dateValue.month,
        day: props.dateValue.week * 7,
        hour: 23,
        minute: 59,
        second: 59,
      })

      // Generate the interval string for the week
      const intervalString = `${calendarStart}/${calendarEnd}`

      return seasons.filter((season) => {
        return season.intervals?.some((interval) => {
          if (!interval) {
            return false
          }

          return ISO8601.overlapsAny(intervalString, [interval])
        })
      })
    }

    // Default to empty array
    default: {
      return []
    }
  }
}
</script>
<template>
  <header
    class="flex flex-row flex-wrap gap-4 items-center justify-between border-b px-6 py-4 border-gray-200 dark:border-gray-600"
  >
    <div class="w-full md:w-auto flex flex-wrap gap-4 items-center">
      <OnClickOutside class="w-min flex gap-4 items-center mx-auto md:ml-0" @trigger="closeDateSelector">
        <div class="flex gap-x-2">
          <button
            type="button"
            class="w-fit inline-flex items-center py-2.5 px-7 text-sm font-medium transition-all rounded-[0.25rem] border focus:z-10 focus:ring-4 focus:outline-none text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            :class="{
              'opacity-50 cursor-not-allowed': props.loading,
            }"
            :disabled="props.loading"
            @click="emit('update:date:to:today')"
          >
            Today
          </button>

          <!-- Header for YEAR -->
          <div
            v-if="props.view === CalendarViewEnum.YEAR"
            class="relative flex rounded-[0.25rem] shadow-sm items-stretch bg-white dark:bg-gray-900"
          >
            <button
              type="button"
              class="flex h-10 w-12 items-center justify-center rounded-l-[0.25rem] border-y border-l focus:relative transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="handleDatePrevious"
            >
              <span class="sr-only">Previous {{ showDateSelector ? '9 years' : 'year' }}</span>
              <SolidChevronLeft class="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              class="w-[4.5rem] border-y px-3.5 text-sm font-semibold focus:relative transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="toggleDateSelector"
            >
              {{ dateValue.year }}
            </button>
            <button
              type="button"
              class="flex h-10 w-12 pl-1 md:pl-0 items-center justify-center rounded-r-[0.25rem] border-y border-r focus:relative transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="handleDateNext"
            >
              <span class="sr-only">Next {{ showDateSelector ? '9 years' : 'year' }}</span>
              <SolidChevronRight class="h-5 w-5" aria-hidden="true" />
            </button>

            <!-- Dropdown menu for year selector -->
            <div
              v-show="showDateSelector"
              class="absolute top-11 w-52 cursor-pointer z-10 divide-y rounded-lg shadow border border-gray-200 dark:border-gray-600 bg-white divide-gray-100 dark:bg-gray-700"
            >
              <ul class="grid grid-cols-3 text-sm text-gray-700 dark:text-gray-300">
                <li v-for="(item, index) in getYearSelectorPageDates()" :key="`${index}-${item}`">
                  <a
                    class="block text-center p-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    :class="{
                      'rounded-tl-lg': index === 0,
                      'rounded-tr-lg': index === 2,
                      'rounded-bl-lg': index === 6,
                      'rounded-br-lg': index === 8,
                      'bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-white':
                        doesValueMatchSelectedDateValue(item),
                      '!text-primary-400': item === DateTime.now().year,
                    }"
                    @click="selectDateFromSelector(item)"
                  >
                    {{ item }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Header for MONTH -->
          <div
            v-if="props.view === CalendarViewEnum.MONTH"
            class="relative flex rounded-[0.25rem] shadow-sm items-stretch bg-white dark:bg-gray-900"
          >
            <button
              type="button"
              class="flex h-[2.625rem] w-12 items-center justify-center rounded-l-[0.25rem] border-y border-l focus:relative transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="handleDatePrevious"
            >
              <span class="sr-only">Previous {{ showDateSelector ? 'year' : 'month' }}</span>
              <SolidChevronLeft class="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              class="w-[7rem] border-y px-3.5 text-sm font-semibold focus:relative transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="toggleDateSelector"
            >
              {{
                `${DateTime.fromObject({ month: dateValue.month }).toLocaleString({ month: 'short' })} ${
                  props.dateValue.year
                }`
              }}
            </button>
            <button
              type="button"
              class="flex h-[2.625rem] w-12 pl-1 md:pl-0 items-center justify-center rounded-r-[0.25rem] border-y border-r focus:relative transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="handleDateNext"
            >
              <span class="sr-only">Next {{ showDateSelector ? 'year' : 'month' }}</span>
              <SolidChevronRight class="h-5 w-5" aria-hidden="true" />
            </button>

            <!-- Dropdown menu for month selector -->
            <div
              v-show="showDateSelector"
              class="absolute top-11 w-52 cursor-pointer z-10 divide-y rounded-lg shadow border border-gray-200 dark:border-gray-600 bg-white divide-gray-100 dark:bg-gray-700"
            >
              <ul class="grid grid-cols-3 text-sm text-gray-700 dark:text-gray-300">
                <li v-for="index in 12" :key="`month:${index}`">
                  <a
                    class="block text-center p-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    :class="{
                      'rounded-tl-lg': index === 1,
                      'rounded-tr-lg': index === 3,
                      'rounded-bl-lg': index === 10,
                      'rounded-br-lg': index === 12,
                      'bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-white':
                        doesValueMatchSelectedDateValue(index),
                      '!text-primary-600 dark:!text-primary-400':
                        index === DateTime.now().month && props.dateValue.year === DateTime.now().year,
                    }"
                    @click="selectDateFromSelector(index)"
                  >
                    {{ DateTime.fromObject({ year: props.dateValue.year, month: index }).get('monthShort') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Header for WEEK -->
          <div
            v-if="props.view === CalendarViewEnum.WEEK"
            class="relative bg-white flex items-center rounded-[0.25rem] shadow-sm md:items-stretch"
          >
            <button
              type="button"
              class="flex h-9 w-1 md:w-92 items-center justify-center rounded-l-md border-y border-l pr-1 md:pr-0 focus:relative transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="handleDatePrevious"
            >
              <span class="sr-only">Previous {{ showDateSelector ? 'year' : 'week' }}</span>
              <SolidChevronLeft class="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              class="hidden md:block w-[14rem] border-y px-3.5 text-sm font-semibold focus:relative transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="toggleDateSelector"
            >
              {{ getWeekRangeText(dateValue) }}
            </button>
            <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              class="flex h-9 w-12 md:w-9 items-center justify-center focus:relative rounded-r-md border-y border-r pl-1 md:pl-0 transition-all text-gray-900 bg-white border-gray-200 hover:bg-gray-100 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              :class="{
                'opacity-50 cursor-not-allowed': props.loading,
              }"
              :disabled="props.loading"
              @click="handleDateNext"
            >
              <span class="sr-only">Next {{ showDateSelector ? 'year' : 'week' }}</span>
              <SolidChevronRight class="h-5 w-5" aria-hidden="true" />
            </button>

            <!-- Dropdown menu for week selector -->
            <div
              v-show="showDateSelector"
              class="absolute top-11 w-52 cursor-pointer z-10 divide-y rounded-lg shadow border border-gray-200 dark:border-gray-600 bg-white divide-gray-100 dark:bg-gray-700"
            >
              <h1 class="cursor-default text-center text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">
                {{ props.dateValue.year }}
              </h1>
              <ul class="grid grid-cols-3 text-sm text-gray-700 dark:text-gray-300">
                <li v-for="index in 12" :key="`month:${index}`">
                  <a
                    class="block text-center p-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    :class="{
                      'rounded-tl-lg': index === 1,
                      'rounded-tr-lg': index === 3,
                      'rounded-bl-lg': index === 10,
                      'rounded-br-lg': index === 12,
                      'bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-white':
                        doesValueMatchSelectedDateValue(index),
                      '!text-indigo-400':
                        index === DateTime.now().month && props.dateValue.year === DateTime.now().year,
                    }"
                    @click="selectDateFromSelector(index)"
                  >
                    {{ DateTime.fromObject({ year: props.dateValue.year, month: index }).get('monthShort') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </OnClickOutside>

      <Spinner v-if="props.loading" class="w-6 h-6" />

      <div class="w-full md:w-fit flex justify-center gap-4">
        <!-- Seasons Badge(s) -->
        <div
          v-for="(season, seasonIndex) of getMatchingSeasons(props.seasons)"
          class="flex"
          :key="`season-${seasonIndex}-${season.label}`"
        >
          <span
            class="text-sm font-medium px-3 py-0.5 rounded bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300"
            :id="`season-${seasonIndex}-label-${season.label}`"
          >
            {{ season.label }}
          </span>
        </div>

        <!-- Active Specials Badge -->
        <span
          v-if="isActiveSpecial"
          class="text-sm font-medium flex items-center gap-x-1 px-3 py-0.5 rounded-md transition-all border border-transparent dark:border-purple-400 text-purple-900 dark:text-purple-400 bg-purple-100 dark:bg-gray-700"
        >
          <SolidFire class="w-4 h-4 shrink-0 fill-purple-800 dark:fill-purple-400" />
          Active Special
        </span>
      </div>
    </div>

    <div class="relative flex gap-x-4 items-center mx-auto md:ml-0 md:mr-0">
      <!-- TODO: Introduce when other calendar views are added -->
      <!-- <Dropdown
        :default="props.view"
        :list="[CalendarViewEnum.YEAR, CalendarViewEnum.MONTH, CalendarViewEnum.WEEK]"
        @select:item="emit('update:view', $event)"
      />
      <div class="h-6 w-px bg-gray-300" /> -->
      <ButtonVue
        :is-primary="false"
        has-border
        class="transition-all"
        name="Add Event"
        position="prefix"
        :on-click="() => emit('add:event')"
      >
        <OutlineCalendar class="w-5 mr-1" />
      </ButtonVue>

      <ButtonVue
        v-if="$growthbook.isOn(COMMODORE_20250218_MMK_INTEGRATION)"
        :is-primary="true"
        has-border
        class="transition-all"
        name="Calendar Sharing"
        position="prefix"
        :on-click="() => emit('open:calendar:sharing')"
      >
        <OutlineShare class="w-5 mr-1" />
      </ButtonVue>
    </div>
  </header>
</template>
