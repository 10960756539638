<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { SearchClient } from 'algoliasearch'
import debounce from 'lodash.debounce'
import { ComputedRef, Ref, computed, inject, ref } from 'vue'

import { GeoFilter } from '@ankor-io/common/vessel/types'
import { OutlineXMark } from '@ankor-io/icons/outline'
import { SolidCircleX } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'

const props = defineProps<{
  geoTags: GeoFilter[]
  operationType: string
  loading?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:geo', value: GeoFilter[]): void
  (e: 'remove:geo', uri: string): void
}>()

enum Source {
  GEO = 'geo',
  PLACE = 'place',
}
type SearchHit = {
  uri: string
  label: string
  categoryPageId: string[]
  source: Source.GEO | Source.PLACE
}

const geoPlaceSearchClient: SearchClient = inject('geoSearchClient')!

const searchText: Ref<string> = ref('')
const showResults: Ref<boolean> = ref(false)
const searchHits: Ref<SearchHit[]> = ref([])
const highlightedResultIndex: Ref<number> = ref(-1)
/**
 * Description: Variable that contains the filtered geoTags based on the operationType.
 * @type {ComputedRef<GeoFilter[]>}
 */
const filteredGeoTags: ComputedRef<GeoFilter[]> = computed(() => {
  return props.geoTags.filter((tag) => tag.operation === props.operationType)
})
const highlightedTagIndex: Ref<number> = ref(filteredGeoTags.value?.length || 0)

/**
 * Highlight the search text in the result
 * @param text
 * @param search
 */
const highlightText = (text: string, search: string) => {
  return text.replace(new RegExp(search, 'gi'), (match) => `<mark>${match}</mark>`)
}

/**
 * Sort and remove duplicates based on the label name.
 * TODO: Look at the coordinates instead of label names perhaps
 * @param results
 */
const processResults = (results: SearchHit[]) => {
  return results.filter((result, index, self) => self.findIndex((t) => t.label === result.label) === index)
}

const sortArrayByLikenessToKeyword = (array: SearchHit[]) => {
  // Sort by the index of the keyword in the label
  return array.sort((a, b) => {
    const aIndex = a.label.toLowerCase().indexOf(searchText.value.toLowerCase())
    const bIndex = b.label.toLowerCase().indexOf(searchText.value.toLowerCase())
    if (aIndex === -1) return 1
    if (bIndex === -1) return -1
    return aIndex - bIndex
  })
}

/**
 * Trigger the search - cumulating the search results from Ankor places and GEO index
 * @param value - the search text
 */
const triggerSearch = debounce(async (value: string) => {
  if (value === '') {
    searchHits.value = []
    return
  }

  await geoPlaceSearchClient
    .multipleQueries([
      {
        indexName: Source.GEO,
        query: searchText.value,
        params: { restrictSearchableAttributes: ['label'] },
      },
      {
        indexName: Source.PLACE,
        query: searchText.value,
        params: { filters: `visible_by:all`, restrictSearchableAttributes: ['name'] },
      },
    ])
    .then((responses) => {
      const geoSearchResponse = responses.results[0].hits.map((hit: any) => ({
        uri: hit.objectID,
        label: hit.label,
        categoryPageId: hit.categoryPageId,
        source: Source.GEO,
      }))
      const placeSearchResponse = responses.results[1].hits.map((hit: any) => ({
        uri: hit.objectID,
        label: `${hit.name}, ${hit.country}`,
        categoryPageId: hit.tags,
        source: Source.PLACE,
      }))
      const processedGeoResults = processResults(geoSearchResponse)
      const processedPlaceResults = processResults(placeSearchResponse)
      searchHits.value = sortArrayByLikenessToKeyword([...processedGeoResults, ...processedPlaceResults])
    })
    .catch((err) => {
      console.debug(err)
      searchHits.value = []
    })
}, 500)

/**
 * Reset the search text
 */
const resetSearch = () => {
  searchText.value = ''
  searchHits.value = []
}

/**
 * Add geo tag
 */
const addGeoTag = () => {
  const hit = searchHits.value[highlightedResultIndex.value]
  const updatedGeoTags = [
    ...props.geoTags,
    {
      uri: hit.uri,
      label: hit.label,
      operation: props.operationType,
    },
  ]
  const uniqueGeoTags = [...new Set(updatedGeoTags.map((obj: GeoFilter) => JSON.stringify(obj)))].map(
    (str: string): GeoFilter => JSON.parse(str),
  )

  emit('update:geo', uniqueGeoTags)

  // update geo tags and resetUI
  showResults.value = false
  searchText.value = ''
  searchHits.value = []
  highlightedResultIndex.value = -1
  highlightedTagIndex.value += 1
}

/**
 * Remove geo tag
 * @param geoUri - geo uri
 */
const removeGeoTag = (geoUri: string) => {
  emit('remove:geo', geoUri)
}

/** KEYBOARD NAVIGATION START */
/** Highlight previous result on up arrow key press */
const highlightPreviousResult = () => {
  if (highlightedResultIndex.value > 0) {
    highlightedResultIndex.value--
  }
}

/**
 * Highlight next result on down arrow key press
 * @param resultsCount - number of results
 */
const highlightNextResult = (resultsCount: number) => {
  if (highlightedResultIndex.value < resultsCount - 1) {
    highlightedResultIndex.value++
  }
}

/** Highlight previous tag on left arrow key press */
const highlightPreviousTag = () => {
  if (highlightedTagIndex.value > 0) {
    highlightedTagIndex.value--
  }
}

/** Highlight next tag on right arrow key press */
const highlightNextTag = () => {
  if (!filteredGeoTags.value || highlightedTagIndex.value === filteredGeoTags.value.length) {
    return
  }

  highlightedTagIndex.value++
}

/**
 * On escape, the user can delete a tag
 * @param event KeyboardEvent escape key press
 */
const adjustTagByBackspace = (event: KeyboardEvent) => {
  const target = event.currentTarget as HTMLInputElement
  if (target.value) {
    return
  }

  if (highlightedTagIndex.value <= (filteredGeoTags.value?.length || 0) - 1) {
    removeGeoTag(filteredGeoTags.value[highlightedTagIndex.value].uri)
    highlightedTagIndex.value = filteredGeoTags.value?.length || 0
  } else {
    highlightPreviousTag()
  }
}
/** KEYBOARD NAVIGATION END */
</script>
<template>
  <OnClickOutside @trigger="showResults = false" class="relative">
    <div
      class="flex flex-row flex-grow gap-2 flex-wrap items-center border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 focus:shadow-none focus:ring-0"
      :class="props.loading ? 'bg-gray-100 dark:bg-gray-500' : 'bg-gray-50 dark:bg-gray-700'"
    >
      <span
        v-for="(geoTag, index) in filteredGeoTags"
        class="flex items-center px-3 py-1 rounded-md font-medium text-xs text-black dark:text-white h-6"
        :key="geoTag.uri"
        :id="`${geoTag.uri}-badge-dismiss-default`"
        :class="
          highlightedTagIndex === index
            ? 'border border-gray-400 dark:border-primary-400 bg-gray-300 dark:bg-primary-700'
            : 'bg-gray-200 dark:bg-primary-600'
        "
      >
        {{ geoTag.label }}
        <button
          type="button"
          class="flex items-center gap-x-2"
          data-dismiss-target="#badge-dismiss-default"
          aria-label="Remove"
          :class="props.loading ? 'cursor-not-allowed' : 'cursor-pointer'"
          :disabled="props.loading"
          @click.stop="removeGeoTag(geoTag.uri)"
        >
          <OutlineXMark class="w-2 h-2 ml-2" />
          <span class="sr-only">{{ `Remove ${geoTag.label}` }}</span>
        </button>
      </span>
      <input
        type="text"
        autocomplete="off"
        class="flex-grow text-gray-900 border-none text-sm rounded-lg focus:border-none focus:outline-none block py-1 px-1 dark:placeholder-gray-400 dark:text-white focus:shadow-none focus:ring-0"
        :class="props.loading ? 'bg-gray-100 dark:bg-gray-500' : 'bg-gray-50 dark:bg-gray-700'"
        :placeholder="operationType === 'union' ? 'Show in search for region(s)' : 'Exclude from search for region(s)'"
        :disabled="props.loading"
        v-model="searchText"
        @input="triggerSearch(($event.currentTarget as HTMLInputElement).value)"
        @focus="showResults = true"
        @click="highlightedTagIndex = filteredGeoTags?.length || 0"
        @keyup.up.prevent="highlightPreviousResult"
        @keyup.down.prevent="highlightNextResult(searchHits.length)"
        @keyup.enter.prevent="addGeoTag"
        @keyup.right.prevent="highlightNextTag"
        @keyup.left.prevent="highlightPreviousTag"
        @keyup.backspace.prevent="adjustTagByBackspace($event)"
      />
      <Spinner v-if="props.loading" class="w-4 h-4" />
      <button
        v-if="searchText"
        type="reset"
        class="relative right-1"
        title="Clear the query"
        aria-label="Clear the query"
        @click.stop="resetSearch"
      >
        <SolidCircleX class="w-6 h-6 fill-gray-300" />
      </button>
    </div>

    <!-- search results -->
    <div
      v-if="showResults && searchHits.length"
      class="absolute top-14 z-10 list-none bg-white rounded-lg shadow w-full dark:bg-gray-700 max-h-60 overflow-auto"
    >
      <ul class="divide-y divide-gray-100 dark:divide-gray-600">
        <li
          v-for="(searchHit, searchHitIndex) of searchHits"
          class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white transition ease-in-out duration-150"
          :class="{ 'bg-gray-100 dark:bg-gray-600': highlightedResultIndex === searchHitIndex }"
          :key="searchHit.uri"
          @mouseover="highlightedResultIndex = searchHitIndex"
          @click="addGeoTag"
          v-html="highlightText(searchHit.label, searchText)"
        />
      </ul>
    </div>
  </OnClickOutside>
</template>
