<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { initDropdowns } from 'flowbite'
import { Ref, onMounted, ref } from 'vue'

import { VesselIndexItem } from '@ankor-io/common/index/VesselIndexItem'
import { SortableFields } from '@ankor-io/common/vessel/sort'
// import { HiSolidChevronUpDown } from '@ankor-io/icons/hi_solid'
import { LineArtDotsVertical } from '@ankor-io/icons/line_art'

import VesselTableViewRow from './VesselTableViewRow.vue'

type Props = {
  vessels: VesselIndexItem[]
  rates: { [key: string]: string }
  categoryOptions: readonly { key: string; label: string; value: boolean }[]
  generatingBrochureForUri: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'sort:by', field: SortableFields): void
  (e: 'update:category:show', index: number): void
  (e: 'copy:brochure', value: string): void
}>()

const showCategoriesMenu: Ref<boolean> = ref(false)

// TODO: Add for shortlisting/comparison functionality
// const shortlistAll: Ref<boolean> = ref(false)
// const shortlistedVessels: Ref<string[]> = ref([]) // Array of vessel uri's

onMounted(() => initDropdowns())

/**
 * Shortlists (checks) all the vessels in the table
 */
// const shortlistAllVessels = () => {
//   shortlistedVessels.value = []
//   shortlistAll.value = !shortlistAll.value
//   if (shortlistAll.value) {
//     // Short list all vessel uri's
//     props.vessels.forEach((vessel) => {
//       shortlistedVessels.value.push(vessel.uri)
//     })
//   }
// }

/**
 * Shortlists a single vessel uri
 * @param isShortlisted Checked value of the row
 */
// const updateIsVesselShortlisted = (uri: string) => {
//   const index = shortlistedVessels.value.findIndex((vesselUri) => vesselUri === uri)
//   if (index === -1) {
//     shortlistedVessels.value.push(uri)
//     if (shortlistedVessels.value.length === props.vessels.length) {
//       shortlistAll.value = true
//     }
//   } else {
//     shortlistedVessels.value.splice(index, 1)
//   }
// }
</script>
<template>
  <!-- Vessel name is left hand column -->
  <!-- Rest of the details overflowed and scrolls on x in the normal table -->
  <section
    class="relative overflow-auto border-l w-full h-[calc(100vh-13rem)] md:h-[calc(100vh-12rem)] flex bg-gray-50 dark:bg-gray-900 border-gray-200 dark:border-gray-600 pr-8"
  >
    <div role="table" class="h-fit border-t border-r bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600">
      <!-- "TABLE" header row -->
      <div
        role="row"
        class="z-20 w-full sticky top-0 text-xs font-semibold uppercase text-left flex items-center border-b bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200 border-gray-200 dark:border-gray-600 fill-gray-500 dark:fill-gray-400"
      >
        <!-- Shortlist all -->
        <!-- Vessel name -->
        <!-- Select categories -->
        <div
          role="column"
          class="w-[23rem] min-w-[23rem] md:sticky md:left-0 top-0 flex items-center justify-between border-r bg-gray-100 dark:bg-gray-700 border-gray-200 dark:border-gray-600"
        >
          <!-- Checkbox to shortlist all vessels -->
          <div scope="col" class="px-4 py-4">
            <!-- <div class="flex items-center gap-x-2">
              <input
                type="checkbox"
                class="w-4 h-4 cursor-pointer rounded text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                id="shortlist-all"
                :checked="shortlistAll"
                @change="shortlistAllVessels()"
              />
            </div> -->
          </div>
          <!-- TODO: Remove below pl-4 when the above checkbox is added back in -->
          <div
            scope="col"
            class="name-heading pl-4 flex gap-x-1 select-none pr-3 py-4 w-full"
            @click="emit('sort:by', 'name')"
          >
            Yacht Name
            <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
          </div>

          <!-- Category selection -->
          <OnClickOutside @trigger="showCategoriesMenu = false">
            <button
              type="button"
              class="cursor-pointer rounded-full p-1 mr-1 focus:ring-2 text-black dark:text-gray-50 focus:ring-gray-300 dark:focus:ring-gray-500"
              @click="showCategoriesMenu = !showCategoriesMenu"
            >
              <LineArtDotsVertical class="w-4 h-4 shrink-0" />
            </button>
            <!-- Dropdown menu -->
            <div
              v-show="showCategoriesMenu"
              class="absolute normal-case right-1 top-10 pt-4 pl-3 cursor-pointer z-10 rounded-lg shadow border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700"
            >
              <span class="pl-1 text-sm font-semibold text-gray-900 dark:text-gray-50">Categories</span>
              <ul
                class="flex flex-col gap-y-3 pr-4 mt-3 pt-1 text-sm max-h-60 overflow-y-auto text-gray-700 dark:text-gray-200"
              >
                <li v-for="(option, index) in props.categoryOptions" :key="option.label" class="pl-1 last:pb-3">
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="w-4 h-4 cursor-pointer rounded text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      :id="`${index}-${option.key}`"
                      :checked="option.value"
                      @change="emit('update:category:show', index)"
                    />
                    <label
                      class="cursor-pointer text-xs whitespace-nowrap block font-normal text-gray-600 dark:text-gray-400"
                      :for="`${index}-${option.key}`"
                    >
                      {{ option.label }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </OnClickOutside>
        </div>
        <!-- Company name -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'line_7' && category.value)"
          role="column"
          scope="col"
          class="w-[16.5rem] min-w-[16.5rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'line_7')"
        >
          <span class="float-left mr-1">Central Agent</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Term price min -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'termPrice' && category.value)"
          role="column"
          scope="col"
          class="w-40 min-w-[10rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'WEEK')"
        >
          <span class="float-left mr-1">Term Price</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Day price min -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'dayPrice' && category.value)"
          role="column"
          scope="col"
          class="w-40 min-w-[10rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'DAY')"
        >
          <span class="float-left mr-1">Day Price</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Hourly price min -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'hourlyPrice' && category.value)"
          role="column"
          scope="col"
          class="w-40 min-w-[10rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'HOUR')"
        >
          <span class="float-left mr-1">Hourly Price</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Vessel length -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'line_2' && category.value)"
          role="column"
          scope="col"
          class="w-32 min-w-[8rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'line_2')"
        >
          <span class="float-left mr-1">Length</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Vessel category -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'category' && category.value)"
          role="column"
          scope="col"
          class="w-32 min-w-[8rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'category')"
        >
          <span class="float-left mr-1">Category</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Vessel num cabins -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'cabins' && category.value)"
          role="column"
          scope="col"
          class="w-32 min-w-[8rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'cabins')"
        >
          <span class="float-left mr-1">Cabins</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Vessel cruising speed -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'cruiseSpeed' && category.value)"
          role="column"
          scope="col"
          class="w-44 min-w-[11rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'cruiseSpeed')"
        >
          <span class="float-left mr-1">Cruising Speed</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Vessel num bathrooms -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'bathrooms' && category.value)"
          role="column"
          scope="col"
          class="w-36 min-w-[9rem] px-4 py-4 select-none"
          @click="emit('sort:by', 'bathrooms')"
        >
          <span class="float-left mr-1">Bathrooms</span>
          <!-- <HiSolidChevronUpDown class="h-4 w-4 shrink-0" /> -->
        </div>
        <!-- Vessel brochure link -->
        <div
          v-if="props.categoryOptions.find((category) => category.key === 'brochure' && category.value)"
          role="column"
          scope="col"
          class="min-w-[8.5rem] px-4 py-4 cursor-text text-center select-text"
        >
          Brochure Link
        </div>
      </div>

      <!-- "TABLE" rows -->
      <!-- 
        :shortlist-all="shortlistAll" 
        :is-shortlisted="shortlistedVessels.includes(item.vessel.uri)"
        @shortlist:vessel="updateIsVesselShortlisted($event)"
      -->
      <VesselTableViewRow
        v-for="(item, index) of props.vessels"
        :index="index"
        :key="item.objectID"
        :rates="props.rates"
        :vessel="item.vessel"
        :company="item.line_7"
        :category-options="props.categoryOptions"
        :generating-brochure-for-uri="props.generatingBrochureForUri"
        @copy:brochure="emit('copy:brochure', $event)"
      />

      <!-- "TABLE" left body -->

      <slot name="visibility-observer"></slot>
    </div>
  </section>
</template>
