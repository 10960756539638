<script setup lang="ts">
import { Datepicker } from 'flowbite-datepicker'
import { Ref, onMounted, onUpdated, ref } from 'vue'

import { reverseDateFormat } from '@ankor-io/common/date/helper'
import { SolidCalendar } from '@ankor-io/icons/solid'

const props = defineProps<{
  id: string
  placeholder?: string
  disabled?: boolean
  date?: string
  validationError?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:date', value: string): void
}>()

const datePickerElementRef: Ref<HTMLInputElement | null> = ref(null)
const datePickerInstance = ref()

onMounted(() => {
  if (datePickerElementRef.value) {
    datePickerInstance.value = new Datepicker(datePickerElementRef.value, {
      format: 'dd-mm-yyyy',
      orientation: 'bottom',
    })
  }
})

onUpdated(() => {
  const date = reverseDateFormat(props.date!, 4)
  // Update date via setDate when the date prop changes
  if (datePickerElementRef.value && date) {
    datePickerInstance.value.setDate(date)
  }
})
</script>
<template>
  <div class="relative h-fit">
    <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
      <SolidCalendar
        class="w-4"
        data-test="icon"
        :class="props.validationError ? 'text-red-500 dark:text-red-400' : 'text-gray-500 dark:text-gray-400'"
      />
    </div>
    <input
      datepicker
      autocomplete="off"
      type="text"
      class="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
      :ref="(el) => (datePickerElementRef = el as HTMLInputElement)"
      :id="props.id"
      :class="[
        props.disabled
          ? 'bg-gray-200 dark:bg-gray-400 placeholder-gray-300 text-gray-300 dark:text-gray-600'
          : 'bg-gray-50 dark:bg-gray-600 text-gray-900 dark:text-white',
        props.validationError
          ? 'border-red-500 dark:border-red-400 placeholder-red-500 dark:placeholder-red-400 bg-red-600 dark:bg-red-600 bg-opacity-10 dark:bg-opacity-10'
          : 'border-gray-300 dark:border-gray-500 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-600',
      ]"
      :placeholder="props.placeholder || 'Select date'"
      :disabled="props.disabled"
      @focusout="emit('update:date', reverseDateFormat(datePickerElementRef?.value || '', 2) || '')"
    />
  </div>
</template>

<style lang="scss">
.datepicker {
  z-index: 50 !important;

  .datepicker-picker {
    border: 1px solid #d1d5db;
  }
}
</style>
