<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import algoliasearch from 'algoliasearch/lite'
import { initPopovers } from 'flowbite'
import { DateTime, DateTimeOptions, Interval } from 'luxon'
import { Ref, inject, onMounted, onUnmounted, ref, watch } from 'vue'

import PostCardSpecial from '@ankor-io/blocks/components/feed/PostCardSpecial.vue'
import {
  eventBackgroundColour,
  eventEdgeColourWithBeforeAfter,
  eventTextColour,
  specialBorderColour,
} from '@ankor-io/common/calendar/event-colours'
import { isSpecialActive, isSpecialExpired } from '@ankor-io/common/calendar/specials'
import * as ISO8601 from '@ankor-io/common/date/ISO8601'
import { useAppDispatcher } from '@ankor-io/common/lang/events'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { CalendarEvent, EventType, EventTypeEnums, Person, Vessel } from '@ankor-io/common/vessel/types'
import { Post } from '@ankor-io/feed-endpoint/src/types'
import { OutlineBell, OutlineCalendar, OutlineXMark } from '@ankor-io/icons/outline/index'
import { SolidChevronLeft, SolidChevronRight, SolidFire, SolidPaperAirplane, SolidX } from '@ankor-io/icons/solid'

import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import DeleteConfirmation, { DeleteConfirmationModalData } from '@/components/modal-content/DeleteConfirmation.vue'
import EventDetails, { EventDetailsProps } from '@/components/modal-content/EventDetails.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import EnquiryModal, {
  ContactPersonsType,
  EnquiryType,
} from '@/components/modal-content/enquiry-modal/EnquiryModal.vue'
import { ToastEnquiryEnums } from '@/components/modal-content/enquiry-modal/toastTypes'
import EventModal, { VesselVariantLabelId } from '@/components/modal-content/event-modal-content/EventModal.vue'
import { Config, ConfigKey } from '@/config/types'
import { useFacetPanel } from '@/facet-panel/useFacetPanel'
import { useModal } from '@/hooks/modal/useModal'
import { useCompany } from '@/hooks/useCompany'
import { AuthenticationContext } from '@/iam/types'
import { DispatcherEventEnum } from '@/utils/dispatcher-events'
import { COMMODORE_20240717_CA_SPECIALS_ENQUIRY_MODAL } from '@/utils/growthbook/constants'

type TimelineVesselDetails = {
  label: string
  uri: string
  variants: VesselVariantLabelId[]
}

type GridColSpan = {
  gridColSpan: string
}

type PostAndEvent = CalendarEvent & Post & GridColSpan

const props = defineProps<{
  vessels: TimelineVesselDetails[]
  scrollPosition: number
}>()

const emit = defineEmits<{
  (e: 'update:timeline:rows:height', value: Record<string, number>): void
}>()

const config: Config = inject(ConfigKey)!
const postSearchClient = algoliasearch(config.ALGOLIA.app_id, config.ALGOLIA.search_key)
const commodoreIndex = postSearchClient.initIndex('commodore')

const { company, getCompany } = useCompany()

const facetPanelState = useFacetPanel()
const dispatcher = useAppDispatcher().get()
const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const { isOpen, updateModalState, updateCloseButtonState, updateCanCloseOnBackdropState } = useModal()

const gridRowHeight: Ref<string> = ref('')
const gridRowMinHeight: Ref<string> = ref('')

const columnWidth = 2.25 // rem
const timelineElementRef: Ref<any> = ref(null)
const timelineRows: Ref<Record<string, number>> = ref({})
const highlightedDates: Ref<(DateTime | null)[]> = ref([])
const selectedVessel: Ref<TimelineVesselDetails | null> = ref(null)
const eventsLoading: Ref<Set<string>> = ref(new Set<string>())
const vesselsLoading: Ref<Set<string>> = ref(new Set<string>())
const selectedCalendar: Ref<CalendarEvent | null> = ref(null)

const selectedModalType: Ref<'Details' | 'Update' | 'Delete' | 'Add' | null> = ref(null)
const eventDetailsModalData: Ref<EventDetailsProps | null> = ref(null)
const deleteConfirmationModalData: Ref<DeleteConfirmationModalData | null> = ref(null)

const eventTypes: Ref<{ label: EventType; value: boolean }[]> = ref([
  { label: EventTypeEnums.BOOKED, value: true },
  { label: EventTypeEnums.TENTATIVE, value: true },
  { label: EventTypeEnums.MAINTENANCE, value: true },
  { label: EventTypeEnums.IN_TRANSIT, value: true },
  { label: EventTypeEnums.FLEXIBLE_USE, value: true },
  { label: EventTypeEnums.BOAT_SHOW, value: true },
  { label: EventTypeEnums.OTHER, value: true },
])
const showSpecials: Ref<boolean> = ref(true)

// Record of calendar events for each vessel
// so we dont have to fetch them again upon filter/search mods
const eventsRecordRef: Ref<Record<string, CalendarEvent[]>> = ref({})
const vesselsWithNoEvents: Ref<Set<string>> = ref(new Set<string>())

const vesselPosts: Ref<Record<string, any[]>> = ref({})
const specialsModalContent: Ref<any | null> = ref(null)

const eventsAndPosts: Ref<Record<string, PostAndEvent[]>> = ref({})

const enquiryData: Ref<{
  specialsUri: string
  vesselUri: string
  vesselName: string
} | null> = ref(null)
const showEnquireModal: Ref<boolean> = ref(false)
const isLoadingCompany: Ref<boolean> = ref(false)
const isLoadingContacts: Ref<boolean> = ref(false)
const isSendingEnquiry: Ref<boolean> = ref(false)
const vessel: Ref<Vessel | null> = ref(null)
const toContactPersons: Ref<ContactPersonsType[]> = ref([])
const toastEnquiryMessage: Ref<ToastEnquiryEnums | null> = ref(null)

// create a list of months and days to display
const now = DateTime.now()
const months = [now.setZone('utc').startOf('month')]
for (let i = 1; i < 12; i++) {
  months.push(months[months.length - 1].plus({ months: 1 }))
}

const numDays = now.plus({ year: 1 }).diff(now).as('days')

onMounted(() => {
  initPopovers()
  dispatcher.addEventListener(DispatcherEventEnum.CALENDAR_EVENT_CREATED, reloadEventsForVesselUri)
  handleResize()
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  dispatcher.removeEventListener(DispatcherEventEnum.CALENDAR_EVENT_CREATED, reloadEventsForVesselUri)
  window.removeEventListener('resize', handleResize)
})

/**
 * Uses the zone from the interval to compute the diff when options.setZone = true
 *
 * @param intervalStr the interval to turn into a col span
 * @param options allow to apply timezone on all date time object when computing the diff
 */
const intervalToSpan = (intervalStr: string, options?: DateTimeOptions): string => {
  if (!intervalStr) {
    return ''
  }

  /*
      The event bars on the timeline / monthly calendar should be in absolute dates and does not change depending on the timezone of event or user browser location
      For example an event on 4th of Sept in New York should appear as
        - 4th for user in New York
        - 4th for user in London
        - 4th for user in Sydney

      Therefore when we try to compute the event bar gap, spread, days etc, we:
            - SHOULD NOT use embarkation / disembarkation timezone
            - SHOULD NOT use user's browser time
            - SHOULD NOT convert any of above into UTC (because 4th Sept at 11pm is 5th Sept UTC)
      
      We SHOULD generate a new UTC datetime using absolute numbers eg 4th of Sept, 2024
       eg DateTime.utc(2024, 9, 4)

      This ensures events appear on the same days regardless of embarkation / disembarkation / browser timezone
  */

  const interval = Interval.fromISO(intervalStr, options)

  const startOfMonthUtc = DateTime.now().setZone('utc').startOf('month')
  const startUtc = DateTime.utc(interval.start!.year, interval.start!.month, interval.start!.day)
  const endUtc = DateTime.utc(interval.end!.year, interval.end!.month, interval.end!.day)

  let gap = startUtc.diff(startOfMonthUtc, 'days').days + 1
  let spread = endUtc.diff(startUtc, 'days').days + 1

  if (gap < 0) {
    // gap < 0 means the event started before the start of the current month
    // The map only shows the current month onwards, therefore we plot the event bar from start of month to the event end date
    gap = 1
    spread = endUtc.diff(startOfMonthUtc, 'days').days + 1
  }

  return `${gap}/${gap + spread}`
}

/**
 * Filter out events that are not in the range of the months displayed
 * and sort the events by start date
 * @param events events to clean and sort
 */
const cleanAndSortEvents = (events: CalendarEvent[]): CalendarEvent[] => {
  if (!events.length) {
    return []
  }

  // filter out events not in the date range.
  const visibleEvents = events.filter((e) => {
    return Interval.fromISO(e.interval, { setZone: true }).overlaps(
      Interval.fromDateTimes(months[0], months[months.length - 1]),
    )
  })

  // order the items by row; the objective is to create as few overlaps as possible!
  //  1. first, sort by date asc.
  //  2. then... for each event, add to the first row in which it does not overlap
  //  3. join together the rows to a single vector.

  //
  // 1. first, sort by date asc.
  visibleEvents.sort((left: CalendarEvent, right: CalendarEvent) => {
    const leftStart = Interval.fromISO(left.interval).start!
    const rightStart = Interval.fromISO(right.interval).start!
    return leftStart.diff(rightStart).toMillis()
  })

  //
  // 2. then... for each event, add to the first row in which it does not overlap
  var rows = []
  eventLoop: for (const event of visibleEvents) {
    for (const row of rows) {
      // grab the last event.
      const tailEvent = row[row.length - 1]
      if (!ISO8601.overlapsAny(tailEvent.interval, [event.interval])) {
        row.push(event)
        continue eventLoop
      }
    }

    // does not fit, add new row.
    const newRow: CalendarEvent[] = []
    newRow.push(event)
    rows.push(newRow)
  }

  //
  // 3. join together the rows to a single vector.
  const flatRows = rows.flat()
  return flatRows
}

/**
 * Filter out posts that are not in the range of the months displayed
 * and sort the posts by start date
 * @param posts posts to clean and sort, which comes as an index item
 */
const cleanAndSortPosts = (posts: any[]) => {
  if (!posts.length) {
    return []
  }

  // filter out posts not in the date range.
  const visiblePosts = posts.filter((post) => {
    return Interval.fromISO(post.post.eventInterval!, { setZone: true }).overlaps(
      Interval.fromDateTimes(months[0], months[months.length - 1]),
    )
  })

  // order the items by row; the objective is to create as few overlaps as possible!
  //  1. first, sort by date asc.
  //  2. then... for each post, add to the first row in which it does not overlap
  //  3. join together the rows to a single vector.

  //
  // 1. first, sort by date asc.
  visiblePosts.sort((l, r) => {
    const left: Post = l.post
    const right: Post = r.post

    const leftStart = Interval.fromISO(left.eventInterval!).start!
    const rightStart = Interval.fromISO(right.eventInterval!).start!
    return leftStart.diff(rightStart).toMillis()
  })

  //
  // 2. then... for each post, add to the first row in which it does not overlap
  var rows = []
  postLoop: for (const p of visiblePosts) {
    for (const row of rows) {
      // grab the last event.
      const tailEvent = row[row.length - 1]
      if (!ISO8601.overlapsAny(tailEvent.post.eventInterval!, [p.post.eventInterval!])) {
        row.push(p)
        continue postLoop
      }
    }

    // does not fit, add new row.
    const newRow: any[] = [] // Index containing Post
    newRow.push(p)
    rows.push(newRow)
  }

  //
  // 3. join together the rows to a single vector.
  const flatRows = rows.flat()
  return flatRows
}

/**
 * Flatten posts - take it out from the index item
 * @returns Record<string, Post[]>
 */
const flattenPostsIndexItem = (): Record<string, Post[]> => {
  const posts: Record<string, Post[]> = {}
  for (let i = 0; i < Object.keys(vesselPosts.value).length; i++) {
    const uri = Object.keys(vesselPosts.value)[i]
    posts[uri] = vesselPosts.value[uri].map((post) => post.post)
  }

  return posts
}

/**
 * Calculate the left position of the first day of the month
 * from the start of the timeline
 * @param month DateTime of the month
 */
const leftPositionOfFirstDay = (month: DateTime): number => {
  const refDate = months[0]
  // spaces between the timeline start and the month
  const gap = month.startOf('month').diff(refDate, 'days').as('days')
  return gap * columnWidth
}

// Event details modal
const showEventDetails = (calendarEvent: CalendarEvent, vessel: TimelineVesselDetails) => {
  selectedModalType.value = 'Details'
  eventDetailsModalData.value = {
    calendarEvent,
    vesselName: selectedVessel.value?.label || vessel.label,
  }
  setNewSelectedVessel(vessel)
  updateCloseButtonState(false)
  updateModalState(true)
}

// Post details modal
const showPostDetails = (post: Post, vessel: TimelineVesselDetails) => {
  specialsModalContent.value = post
  setNewSelectedVessel(vessel)
}

// DELETE CONFIRMATION MODAL
const onActionDelete = (id: string) => {
  updateCloseButtonState(true)
  selectedModalType.value = 'Delete'
  deleteConfirmationModalData.value = {
    id,
    message: 'You are about to delete this event, are you sure you want to proceed?',
    labelCancel: 'No, cancel',
    labelConfirm: 'Yes, delete it',
  }
}

/**
 * Delete calendar event from vessel
 * @param id id of the event to delete
 */
const deleteEvent = async (id: string) => {
  updateModalState(false)
  eventsLoading.value.add(selectedVessel.value?.uri!)
  const response = await fetch(`/api/vessel/calendar/events/${decodeURIComponent(selectedVessel.value?.uri!)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify({ id }),
  })

  if (response.status === 200) {
    await reloadEventsForVesselUri(selectedVessel.value?.uri!)
  }

  eventsLoading.value.delete(selectedVessel.value?.uri!)
  selectedVessel.value = null
  selectedCalendar.value = null
  deleteConfirmationModalData.value = null
}

/**
 * Open the update modal with the event to update
 * @param vesselEvent event to update
 */
const openUpdateModal = (vesselEvent: CalendarEvent) => {
  updateCanCloseOnBackdropState(false)
  updateCloseButtonState(true)
  selectedModalType.value = 'Update'
  selectedCalendar.value = vesselEvent
}

const closeModal = () => {
  updateModalState(false)
  selectedVessel.value = null
  selectedCalendar.value = null
  selectedModalType.value = null
}

const closeEventModal = () => {
  selectedModalType.value = null
  updateModalState(false)
}

/**
 * Fetch events for a vessel
 * @param uri uri of the vessel
 */
const fetchEvent = async (uri: string): Promise<CalendarEvent[]> => {
  const startDate = DateTime.now().startOf('month').toFormat('yyyy-MM-dd')
  const endDate = DateTime.now().plus({ month: 11 }).endOf('month').toFormat('yyyy-MM-dd')
  const response = await fetch(
    `/api/vessel/calendar/events/${decodeURIComponent(uri)}?startDate=${startDate}&endDate=${endDate}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${await authenticationContext.getToken()}`,
      },
    },
  )

  if (response.status === 200) {
    const responseFromServer: { events: CalendarEvent[] } = await response.json()
    await fetchPosts(uri)

    return responseFromServer?.events ?? []
  }

  await fetchPosts(uri)
  return []
}

const fetchPosts = async (uri: string) => {
  return commodoreIndex
    .search('', { facetFilters: '[["line_10:' + uri + '"],["type:post"]]' })
    .then((response: any) => {
      vesselPosts.value[uri] = cleanAndSortPosts(response.hits)
    })
}

const getEventTypePopoverDescription = (eventType: EventType) => {
  switch (eventType) {
    case EventTypeEnums.BOOKED:
      return 'The yacht is booked for private use, and reservations during this time are not possible.'
    case EventTypeEnums.TENTATIVE:
      return "While it's not confirmed, someone is considering chartering the yacht, and approval is pending."
    case EventTypeEnums.MAINTENANCE:
      return 'The yacht is temporarily out of service for necessary maintenance or upgrades.'
    case EventTypeEnums.IN_TRANSIT:
      return 'The yacht is not available at its usual location as it is being moved to a different destination.'
    case EventTypeEnums.FLEXIBLE_USE:
      return 'The yacht is currently in use, but the owner may consider making it available for booking.'
    case EventTypeEnums.BOAT_SHOW:
      return 'The yacht will be on display at the boat show listed in the event information.'
    case EventTypeEnums.OTHER:
      return 'There may be various reasons for unavailability, and specific details are not provided.'
  }
}

/**
 * Add calendar event to vessel
 * @param event event to add to vessel
 */
const addEvent = async (vesselUri: string, event: CalendarEvent) => {
  vesselsLoading.value.add(vesselUri)
  const response = await fetch(`/api/vessel/calendar/events/${decodeURIComponent(vesselUri)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify(event),
  })

  if (response.status === 200) {
    // TODO: make an app level state to maintain claimed vessels and their calendar events.
    // In the meantime, dispatch events to let other listening components know that a new event has been added (ie: calendar and timeline)
    dispatcher.dispatchEvent(DispatcherEventEnum.CALENDAR_EVENT_CREATED, vesselUri)
  }

  await reloadEventsForVesselUri(vesselUri)
  vesselsLoading.value.delete(vesselUri)
}

/**
 * Update calendar event of the vessel
 * @param event event to updade for the vessel
 */
const updateEvent = async (vesselUri: string, event: CalendarEvent) => {
  eventsLoading.value.add(vesselUri)
  await fetch(`/api/vessel/calendar/events/${decodeURIComponent(vesselUri)}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
    body: JSON.stringify(event),
  })

  await reloadEventsForVesselUri(vesselUri)
  eventsLoading.value.delete(vesselUri)
}

const setNewSelectedVessel = (vessel?: TimelineVesselDetails) => {
  if (vessel) {
    selectedVessel.value = {
      label: vessel.label,
      uri: vessel.uri,
      variants: vessel.variants,
    }
  } else {
    selectedVessel.value = null
  }
}

const onAddEventButtonClick = (vessel?: TimelineVesselDetails) => {
  setNewSelectedVessel(vessel)
  selectedModalType.value = 'Add'
}

const reloadEventsForVesselUri = async (vesselUri: string) => {
  const eventsForVessel = await fetchEvent(vesselUri)
  eventsRecordRef.value[vesselUri] = cleanAndSortEvents(eventsForVessel)

  const posts = flattenPostsIndexItem()
  eventsAndPosts.value[vesselUri] = ([] as any).concat(posts[vesselUri], eventsRecordRef.value[vesselUri])
  for (let i = 0; i < eventsAndPosts.value[vesselUri].length; i++) {
    eventsAndPosts.value[vesselUri][i].gridColSpan = intervalToSpan(
      eventsAndPosts.value[vesselUri][i].eventInterval! || eventsAndPosts.value[vesselUri][i].interval,
      { setZone: true },
    )
  }
}

const handleResize = () => {
  getGridRowHeight()
  getRowMinHeight()
}

const scrollMonth = (direction: 'left' | 'right', month: DateTime) => {
  const SINGLE_CELL_WIDTH = 36 // 2.25rem cell width
  const startOfMonth = now.startOf('month')
  const startOfViewingMonth = direction === 'left' ? month.minus({ month: 1 }) : month.plus({ month: 1 })
  const daysDiff = startOfViewingMonth.diff(startOfMonth, ['days']).days
  document.getElementById('timeline')?.scrollTo({
    left: daysDiff * SINGLE_CELL_WIDTH,
    behavior: 'smooth',
  })
}

/**
 * Gets all the dates in between an intervals start and end date and adds them to highlightedDates
 * @param intervalStr The event interval string
 */
const highlightDates = (intervalStr: string, options?: DateTimeOptions): void => {
  const interval = ISO8601.fromIntervalString(intervalStr, options)
  const startUtc = DateTime.utc(interval.start!.year, interval.start!.month, interval.start!.day)
  const endUtc = DateTime.utc(interval.end!.year, interval.end!.month, interval.end!.day)
  const absoluteIntervalStr = ISO8601.toIntervalString(startUtc, endUtc)
  const absoluteInterval = ISO8601.fromIntervalString(absoluteIntervalStr)

  // Get all the start dates of an interval
  highlightedDates.value = absoluteInterval.splitBy({ days: 1 }).map((day) => day.start)
  if (highlightedDates.value[highlightedDates.value.length - 1]?.day !== interval.end?.day) {
    // May have a timezone difference which makes the end date the same as the last positions start date
    highlightedDates.value.push(interval.end)
  }
}

const shouldDateBeHighlighted = (day: number, month: number, year: number) => {
  for (let i = 0; i < highlightedDates.value.length; i++) {
    if (
      highlightedDates.value[i]?.day === day &&
      highlightedDates.value[i]?.month === month &&
      highlightedDates.value[i]?.year === year
    ) {
      return true
    }
  }

  return false
}

/**
 * Retrieves the dynamic grid row height
 */
const getGridRowHeight = () => {
  if (facetPanelState.isFacetPanelOpen.value ? window.innerWidth < 1230 : window.innerWidth < 768) {
    gridRowHeight.value = 'row-height-mobile'
  } else {
    gridRowHeight.value = 'row-height-desktop'
  }
}

/**
 * Retrieves the dynamic row min height
 */
const getRowMinHeight = () => {
  if (facetPanelState.isFacetPanelOpen.value ? window.innerWidth < 1230 : window.innerWidth < 768) {
    gridRowMinHeight.value = 'row-min-height-mobile'
  } else {
    gridRowMinHeight.value = 'row-min-height-desktop'
  }
}

const generateMediaUri = (): string => {
  if (specialsModalContent.value?.hero && specialsModalContent.value?.vesselUri) {
    return replacePathToMediaUris(specialsModalContent.value.vesselUri, specialsModalContent.value.hero)[0]
  }

  return ''
}

/**
 * Watch for changes in the vessel uris and fetch events for each vessel
 */
watch(
  () => props.vessels,
  (newValue, oldValue) => {
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
      return
    }

    newValue.forEach(async (vesselLabelUriVariant) => {
      try {
        const vesselUri = vesselLabelUriVariant.uri
        if (eventsRecordRef.value[vesselUri]) {
          return
        }

        const events = await fetchEvent(vesselUri)
        eventsRecordRef.value[vesselUri] = cleanAndSortEvents(events)
        if (eventsRecordRef.value[vesselUri].length === 0) {
          vesselsWithNoEvents.value.add(vesselUri)
        } else {
          vesselsWithNoEvents.value.delete(vesselUri)
        }

        const posts = flattenPostsIndexItem()
        eventsAndPosts.value[vesselUri] = ([] as any).concat(posts[vesselUri], eventsRecordRef.value[vesselUri])
        for (let i = 0; i < eventsAndPosts.value[vesselUri].length; i++) {
          eventsAndPosts.value[vesselUri][i].gridColSpan = intervalToSpan(
            eventsAndPosts.value[vesselUri][i].eventInterval! || eventsAndPosts.value[vesselUri][i].interval,
            { setZone: true },
          )
        }
      } catch (e) {
        console.log(e)
      }
    })
  },
  { immediate: true },
)

// Watch for changes in the vessel uris and fetch events for each vessel
watch(
  () => props.scrollPosition,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      timelineElementRef.value.scrollTop = newValue
    }
  },
)

// Height of timeline rows
watch(
  timelineRows,
  (newTimelineRows) => {
    emit('update:timeline:rows:height', newTimelineRows)
  },
  { deep: true },
)

// Modal state
watch(isOpen, (value) => {
  if (!value) {
    selectedModalType.value = null
  }
})

// Filters panel
watch(
  () => facetPanelState.isFacetPanelOpen.value,
  () => {
    handleResize()
  },
)

const setToastMessage = (message: ToastEnquiryEnums | null) => {
  if (message !== null) {
    setTimeout(() => {
      toastEnquiryMessage.value = null
    }, 15 * 1000 /* 15secs */)
  }
  toastEnquiryMessage.value = message
}

const loadContacts = async (vesselUri: string, correspondenceEmail: string) => {
  isLoadingContacts.value = true
  const response = await fetch(`/api/vessel/${decodeURIComponent(vesselUri)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (response.status === 200) {
    vessel.value = (await response.json()) as Vessel
  } else {
    vessel.value = null
  }

  if (!vessel.value) {
    toContactPersons.value = [{ email: correspondenceEmail }]
  } else if (
    vessel.value.contacts &&
    isCorrespondenceEmailInVesselContacts(correspondenceEmail, vessel.value.contacts)
  ) {
    toContactPersons.value = vessel.value.contacts.map((contact) => ({
      email: contact.email,
      name: contact.name,
      companyName: contact.companyName,
    }))
  } else {
    toContactPersons.value = [{ email: correspondenceEmail }, ...(vessel.value.contacts || [])]
  }

  isLoadingContacts.value = false
}

const isCorrespondenceEmailInVesselContacts = (email: string, vesselContacts?: Person[]): boolean => {
  if (vesselContacts?.length) {
    return vesselContacts.some((contact) => contact.email?.toLowerCase() === email.toLowerCase())
  }
  return false
}

const openEnquiryModal = async (
  specialsUri: string,
  vesselUri: string,
  vesselName: string,
  correspondenceEmail: string,
) => {
  if (!specialsUri || !vesselUri || !vesselName || !correspondenceEmail) {
    return
  }

  if (vessel.value?.uri !== vesselUri) {
    loadContacts(vesselUri, correspondenceEmail)
  }
  if (company.value === null) {
    isLoadingCompany.value = true
    getCompany((await authenticationContext.getToken())!).then(() => {
      isLoadingCompany.value = false
    })
  }

  setToastMessage(null)
  enquiryData.value = { specialsUri, vesselUri, vesselName }
  showEnquireModal.value = true
}

const submitEnquiry = async (enquiry: EnquiryType, vesselUri: string, specialsUri: string) => {
  isSendingEnquiry.value = true

  const resp = await fetch('/api/enquiry/specials', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await authenticationContext.getToken())!}`,
    },
    body: JSON.stringify({ ...enquiry, vesselUri, specialsUri }),
  })
  isSendingEnquiry.value = false
  if (resp.status === 200) {
    setToastMessage(ToastEnquiryEnums.ENQUIRY_SUCCESSFUL)
  } else {
    setToastMessage(ToastEnquiryEnums.ENQUIRY_FAILED)
  }
  showEnquireModal.value = false
}

const closeEnquiryModal = () => {
  showEnquireModal.value = false
}
</script>
<template>
  <div id="timeline" class="overflow-auto h-[calc(100vh-10.4rem)]" :ref="(el) => (timelineElementRef = el)">
    <!-- month and days of the month -->
    <div
      class="snap-x sticky top-0 grid z-30 h-28"
      :style="{ 'grid-template-columns': `repeat(${numDays},${columnWidth}rem)` }"
    >
      <!-- checkboxes for eventType -->
      <span
        v-for="(month, $index) in months"
        class="flex items-center pl-3 text-xs text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-900"
        :key="month.toFormat('MMM-yyyy')"
        :style="{ 'grid-column': `span ${month.daysInMonth}` }"
      >
        <span v-if="$index === 0" class="flex items-center text-xs text-gray-500 dark:text-gray-400 left-1">
          <span
            v-for="eventType in eventTypes"
            class="flex items-center me-4"
            :key="eventType.label"
            :data-popover-target="`popover-${eventType.label}`"
          >
            <input
              checked
              type="checkbox"
              :id="`${eventType.label}_checkbox`"
              :value="eventType.value"
              :class="[
                eventTextColour(eventType.label, eventType.value),
                eventEdgeColourWithBeforeAfter(eventType.label, eventType.value),
                'w-4 h-4 rounded',
              ]"
              @change="eventType.value = !eventType.value"
            />
            <label
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              :for="`${eventType.label}_checkbox`"
            >
              {{ eventType.label }}
            </label>
            <div
              data-popover
              role="tooltip"
              class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 border border-gray-200 rounded-lg shadow-sm opacity-0 bg-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600"
              :id="`popover-${eventType.label}`"
            >
              <div class="px-3 py-2">
                <b>{{ eventType.label }}</b>
                <p>{{ getEventTypePopoverDescription(eventType.label) }}</p>
              </div>
              <div data-popper-arrow></div>
            </div>
          </span>

          <!-- Checkbox for showing specials -->
          <span class="flex items-center me-4" data-popover-target="popover-special">
            <input
              checked
              type="checkbox"
              id="special_checkbox"
              :value="showSpecials"
              :class="['w-4 h-4 rounded', eventTextColour('Active special', showSpecials)]"
              @change="showSpecials = !showSpecials"
            />
            <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="special_checkbox">
              Special
            </label>
            <div
              data-popover
              role="tooltip"
              class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 border border-gray-200 rounded-lg shadow-sm opacity-0 bg-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600"
              id="popover-special"
            >
              <div class="px-3 py-2">
                <b>Specials</b>
                <p>Show or hide specials posted to our notice board</p>
              </div>
              <div data-popper-arrow></div>
            </div>
          </span>
        </span>
      </span>

      <!-- months -->
      <div
        v-for="(month, monthIndex) in months"
        class="flex items-center justify-between px-4 text-xs font-bold text-black dark:text-gray-50 bg-gray-50 dark:bg-gray-900"
        :key="month.toFormat('MMM-yyyy')"
        :style="{ 'grid-column': `span ${month.daysInMonth}` }"
      >
        <div class="flex gap-x-2.5">
          <SolidChevronLeft
            v-if="monthIndex !== 0"
            class="w-4 h-4 shrink-0 cursor-pointer"
            @click="scrollMonth('left', month)"
          />
          <span>{{ month.toFormat('yyyy-MMM') }}</span>
        </div>
        <SolidChevronRight
          v-if="monthIndex !== months.length - 1"
          class="w-4 h-4 shrink-0 cursor-pointer"
          @click="scrollMonth('right', month)"
        />
      </div>

      <!-- days in the month -->
      <template v-for="(month, monthIndex) in months" :key="`month-${month.toFormat('MMM-yyyy')}`">
        <span
          v-for="day in month.daysInMonth"
          class="flex justify-center items-center text-xs transition-all border-b border-gray-200 dark:border-gray-700"
          :key="`${day}-${month.toFormat('MMM-yyyy')}`"
          :class="[
            shouldDateBeHighlighted(day, month.month, month.year)
              ? 'text-white bg-primary-600 dark:bg-primary-500'
              : 'text-gray-400 bg-gray-50 dark:bg-gray-900',
          ]"
        >
          {{ day }}
          <span
            v-if="monthIndex === 0 && day === now.day"
            class="absolute -bottom-1 rounded-full bg-primary-600 w-2 h-2"
          ></span>
        </span>
      </template>
    </div>

    <!-- nunununununu -->
    <!-- the timeline -->
    <!-- nunununununu -->
    <div class="relative h-fit grid" :class="gridRowHeight">
      <!-- first day of the month -->
      <template v-for="month in months" :key="`firstday-${month.toFormat('MMM-yyyy')}`">
        <div
          class="absolute top-0 w-9 border-b border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-700 h-full"
          :style="{
            left: `${leftPositionOfFirstDay(month)}rem`,
          }"
        ></div>
      </template>

      <!-- today in timeline -->
      <div
        class="absolute z-10 top-0 bottom-0 px-2 border-b border-gray-200 dark:border-gray-700 h-full"
        :style="{
          left: `calc(${columnWidth * (now.day - 1) + columnWidth / 2}rem - .5rem)`,
        }"
        data-popover-target="popover-type"
        data-popover-placement="top"
        id="today-line-hover-area"
      >
        <div class="absolute top-0 bottom-0 left-[0.438rem] border-l-2 border-primary-600"></div>
      </div>
      <div
        data-popover
        id="popover-type"
        role="tooltip"
        class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-fit dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400"
      >
        <div class="p-3">
          <h3 class="font-semibold text-gray-900 dark:text-white">Today ({{ now.offsetNameShort }})</h3>
        </div>
        <div data-popper-arrow></div>
      </div>

      <!-- nunununununun -->
      <!-- timeline data -->
      <!-- nunununununun -->
      <div
        v-for="vessel in props.vessels"
        class="relative h-fit grid gap-y-1 items-center border-b border-gray-200 dark:border-gray-400 dark:bg-gray-600"
        :class="gridRowMinHeight"
        :id="`${vessel.uri}-timeline`"
        :key="`${vessel.uri}-timeline`"
        :ref="(el: any) => { timelineRows[vessel.uri] = el?.offsetHeight }"
        :style="{ 'grid-template-columns': `repeat(${numDays},${columnWidth}rem)` }"
      >
        <!-- Events -->
        <div v-if="eventsLoading.has(vessel.uri)" class="m-5">
          <Spinner />
        </div>
        <template v-else-if="eventsAndPosts[vessel.uri] && eventsAndPosts[vessel.uri].length !== 0">
          <div
            v-for="item in eventsAndPosts[vessel.uri].filter((i) => {
              if (i.uri) {
                return showSpecials ? i : undefined
              }

              return eventTypes.find((e) => e.label === i.eventType)?.value === true
            })"
            class="h-5 w-full z-20"
            :key="`${item.eventInterval! || item.interval}-${item.eventType}`"
            :style="[{ 'grid-column': item.gridColSpan }]"
          >
            <div
              class="relative flex items-center gap-x-1 h-5 leading-5 text-sm px-2.5 rounded-md cursor-pointer before:content-[''] before:absolute before:left-0 before:h-full before:z-[1] before:w-2 before:rounded-l-md after:rounded-r-md after:content-[''] after:absolute after:right-0 after:h-full after:z-[1] after:w-2"
              :class="[
                eventTextColour(item.uri 
                  ? isSpecialActive(item.eventInterval!, now) 
                    ? 'Active special' 
                    : 'Expired special' 
                  : item.eventType, true),
                eventBackgroundColour(item.uri 
                  ? isSpecialActive(item.eventInterval!, now) 
                    ? 'Active special' 
                    : 'Expired special' 
                  : item.eventType, true),
                !item.uri ? eventEdgeColourWithBeforeAfter(item.eventType, true) : '', // A Post does not have edges
                item.uri ? specialBorderColour(isSpecialActive(item.eventInterval!, now), true) : '', // A post has a border
                {'hidden': eventTypes.find((e) => e.label === item.eventType)?.value === false }
              ]"
              @mouseenter="highlightDates(item.eventInterval! || item.interval, { setZone: true })"
              @mouseleave="highlightedDates = []"
              @click.stop="item.uri ? showPostDetails(item, vessel) : showEventDetails(item, vessel)"
            >
              <div class="sticky left-1 flex items-center truncate gap-x-1">
                <span
                  v-if="item.eventInterval && isSpecialActive(item.eventInterval, now)"
                  class="flex items-center gap-x-1"
                >
                  <SolidFire
                    class="w-3 h-3 shrink-0 fill-purple-800 dark:fill-purple-400 dark:group-hover:fill-purple-500 dark:group-focus:fill-purple-500 dark:group-active:fill-purple-500"
                  />
                  Active Special -
                </span>
                <span v-if="item.eventInterval && isSpecialExpired(item.eventInterval, now)"> Expired Special - </span>
                <div class="truncate">
                  <span class="font-semibold">{{ item.title || '' }}</span>
                  <span v-if="item?.embark?.name && item?.disembark?.name">
                    {{ item.title ? ' - ' : '' }}
                    {{ item.embark.name }}
                    &#8594;
                    {{ item.disembark.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-else-if="vesselsWithNoEvents.has(vessel.uri)"
          class="absolute top-1/2 -translate-y-1/2 ml-12 flex gap-x-3"
        >
          <p class="w-28 text-center text-gray-400 text-xs">No events yet for this yacht</p>
          <div class="mt-auto mb-auto">
            <Button
              has-border
              name="Add Event"
              position="prefix"
              :is-primary="true"
              :disabled="vesselsLoading.has(vessel.uri)"
              :on-click="() => onAddEventButtonClick(vessels.find((v) => v.uri === vessel.uri))"
            >
              <OutlineCalendar v-if="!vesselsLoading.has(vessel.uri)" class="w-5 mr-1" />
              <Spinner v-else class="w-5 h-5 mr-1" />
            </Button>
          </div>
        </div>
        <div v-else class="m-5">
          <Spinner />
        </div>
      </div>
      <div class="h-20 border-b border-gray-200 dark:border-gray-700 w-full"></div>
    </div>

    <Teleport v-if="selectedModalType === 'Add' || selectedModalType === 'Update'" to="body">
      <EventModal
        v-if="selectedModalType === 'Update'"
        :vessel-label-value="{ label: selectedVessel!.label, uri: selectedVessel!.uri }"
        :vessel-variants="
        selectedVessel!.variants.map((variant) => ({
          label: variant.label,
          id: variant.id,
          turnaround: variant.turnaround,
        }))
      "
        :calendar-event="selectedCalendar!"
        @update:event="updateEvent"
        @close:modal="closeEventModal()"
      />
      <template v-if="selectedModalType === 'Add'">
        <EventModal
          v-if="selectedVessel"
          :vessel-label-value="{ label: selectedVessel.label, uri: selectedVessel.uri }"
          :vessel-variants="selectedVessel.variants"
          @add:event="addEvent"
          @close:modal="closeEventModal()"
        />
        <EventModal
          v-else
          :vessel-label-values="props.vessels.map((vessel) => ({ label: vessel.label, uri: vessel.uri }))"
          @add:event="addEvent"
          @close:modal="closeEventModal()"
        />
      </template>
    </Teleport>

    <ModalContentWrapper v-if="selectedModalType === 'Details' || selectedModalType === 'Delete'">
      <EventDetails
        v-if="selectedModalType === 'Details'"
        :vessel-name="eventDetailsModalData!.vesselName"
        :calendar-event="eventDetailsModalData!.calendarEvent"
        @close:modal="closeModal()"
        @edit:event="openUpdateModal(eventDetailsModalData!.calendarEvent)"
        @delete:event="onActionDelete(eventDetailsModalData!.calendarEvent.id)"
      />
      <DeleteConfirmation
        v-if="selectedModalType === 'Delete'"
        :message="deleteConfirmationModalData!.message"
        :label-cancel="deleteConfirmationModalData!.labelCancel"
        :label-confirm="deleteConfirmationModalData!.labelConfirm"
        @close:modal="updateModalState(false)"
        @confirm:modal="deleteEvent(deleteConfirmationModalData!.id)"
      />
    </ModalContentWrapper>

    <!-- TODO: Update when refactoring: https://app.asana.com/0/1204484386903582/1207119688432643/f -->
    <Teleport v-if="specialsModalContent" to="body">
      <div class="fixed z-50 inset-0 backdrop-blur-[0.125rem] flex justify-center items-center p-2">
        <OnClickOutside
          class="relative w-full max-w-2xl max-h-screen overflow-auto border sm:border-0 border-gray-200 dark:border-gray-600 rounded-lg shadow-md"
          tabindex="-1"
          aria-hidden="true"
          @trigger="specialsModalContent = null"
        >
          <div class="absolute right-4 top-4 flex justify-end">
            <OutlineXMark
              class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400"
              @click="specialsModalContent = null"
            />
          </div>
          <!-- Modal content -->
          <PostCardSpecial
            class="pt-8"
            :uri="specialsModalContent.uri"
            :title="specialsModalContent.title"
            :description="specialsModalContent.description"
            :event-interval="specialsModalContent.eventInterval!"
            :vessel-name="specialsModalContent.vesselName!"
            :location="specialsModalContent.location"
            :hero="generateMediaUri()"
            :created-at="specialsModalContent.createdAt"
            :updated-at="specialsModalContent.updatedAt"
            :user-name="vesselPosts[selectedVessel!.uri].filter((post) => post.uri === specialsModalContent!.uri)[0].line_1"
            :company-name="vesselPosts[selectedVessel!.uri].filter((post) => post.uri === specialsModalContent!.uri)[0].line_2"
            :correspondence-email="specialsModalContent.correspondenceEmail"
            :enquiryEmailEnabled="$growthbook.isOn(COMMODORE_20240717_CA_SPECIALS_ENQUIRY_MODAL)"
            @on:enquire="
              openEnquiryModal(
                specialsModalContent.uri || '',
                selectedVessel!.uri || '',
                specialsModalContent.vesselName || '',
                specialsModalContent.correspondenceEmail || '',
              )
            "
          />
        </OnClickOutside>
      </div>
    </Teleport>

    <Teleport v-if="showEnquireModal && enquiryData" to="body">
      <EnquiryModal
        :vessel-name="enquiryData.vesselName"
        :vessel-contact-persons="toContactPersons"
        :company-name="company?.name"
        :is-loading-contacts="isLoadingContacts"
        :is-loading-company="isLoadingCompany"
        :is-sending-enquiry="isSendingEnquiry"
        @on:close="closeEnquiryModal"
        @on:submit="submitEnquiry($event, enquiryData.vesselUri, enquiryData.specialsUri)"
      />
    </Teleport>

    <!-- Toast message for enquiry -->
    <Teleport v-if="toastEnquiryMessage" to="body">
      <div
        class="z-50 absolute top-20 right-5 flex w-full max-w-xs p-4 rounded-lg shadow text-gray-500 bg-white divide-gray-200 dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800"
      >
        <div class="flex justify-between w-full">
          <div class="flex items-center gap-x-4">
            <SolidPaperAirplane
              v-if="toastEnquiryMessage === ToastEnquiryEnums.ENQUIRY_SUCCESSFUL"
              class="w-6 h-6 shrink-0 rounded-lg text-primary-600 rotate-[24deg]"
            />
            <div
              v-else-if="toastEnquiryMessage === ToastEnquiryEnums.ENQUIRY_FAILED"
              class="bg-red-300 dark:bg-red-400 rounded-md p-1"
            >
              <OutlineBell class="w-6 h-6 shrink-0 rounded-lg text-red-600" />
            </div>
            <div class="text-sm">
              <p>{{ toastEnquiryMessage }}</p>
            </div>
          </div>
          <SolidX
            class="w-4 text-gray-500 dark:text-gray-400 cursor-pointer shrink-0"
            @click.stop="toastEnquiryMessage = null"
          />
        </div>
      </div>
    </Teleport>
  </div>
</template>
<style scoped>
/* Requires a style on the main scrollbar to even allow applying display none on horizontal */
#timeline::-webkit-scrollbar {
  height: 8px;
}

/* To hide the vertical scrollbar */
#timeline::-webkit-scrollbar:vertical {
  display: none !important;
}

/* Cannot reset the scroll bar styles, so below 2 are required to show the horizontal scrollbar */
#timeline::-webkit-scrollbar-thumb:horizontal {
  background-color: #d1d5db;
}

#timeline::-webkit-scrollbar-thumb:horizontal {
  border-radius: 8px;
}

.row-height-desktop {
  grid-template-rows: 11.25rem, repeat(11, minmax(5rem, max-content));
}

.row-height-mobile {
  grid-template-rows: 15rem, repeat(11, minmax(5rem, max-content));
}

.row-min-height-desktop {
  min-height: 11.25rem;
}

.row-min-height-mobile {
  min-height: 15rem;
}
</style>
